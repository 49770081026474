import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../../components/indicator/Indicator";
import BasicTable from "../../../components/tables/basic/BasicTable";
import ListToolBar from '.././components/list/ListToolBar';
import { MARKETING_ACTIONS_ROUTE } from '../../../constants/routes';
import routerHistory from "../../../constants/settings/routerHistory";

const showFiles = (files) => (
  <Button style={{ padding: 0, minWidth: 'auto' }} disabled={!files?.length}>
    Pliki {files?.length || 0}
  </Button>
);

const MarketingScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);

  const fetchMarketingActions = async (url = MARKETING_ACTIONS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        id: item?.id,
        name: item?.name,
        dateStart: moment(item?.dateStart?.date).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: moment(item?.dateEnd?.date).format('YYYY-MM-DD HH:mm:ss'),
        shortCRM: item?.shortCRM,
        files: showFiles(item?.files),
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  };

  useEffect(() => {
    fetchMarketingActions();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${MARKETING_ACTIONS_ROUTE}?query=${v}`);
      const r = data.items?.map((item) => ({
        id: item?.id,
        name: item?.name,
        shortCRM: item?.shortCRM,
        dateStart: moment(item?.dateStart?.date).format('YYYY-MM-DD HH:mm:ss'),
        dateEnd: moment(item?.dateStart?.dateEnd).format('YYYY-MM-DD HH:mm:ss'),
        files: showFiles(item?.files),
      }));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleDelete = async (numSelected) => {
    const { data } = await deleteCall(
      `${MARKETING_ACTIONS_ROUTE}?ids=${numSelected.join()}`,
    );
    const [status] = data;
    if (status?.success) {
      await fetchMarketingActions();
      toast.success('Pomyślnie usunięto wybrane akcje marketingowe.');
    } else {
      toast.error('Wystąpił błąd podczas usuwania akcji marketingowych.');
    }
  };

  return (
    <>
      <ListToolBar handleSearch={handleSearch} />
      <BasicTable
        onDelete={handleDelete}
        useDialogOnDelete
        headCells={[{ id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
        {
          id: 'dateStart',
          numeric: false,
          disablePadding: false,
          label: 'data rozpoczęcia',
        },
        {
          id: 'dateEnd',
          numeric: false,
          disablePadding: false,
          label: 'data zakończenia',
        },
        { id: 'shortCRM', numeric: false, disablePadding: false, label: 'skrót' },
        { id: 'files', numeric: false, disablePadding: false, label: 'pliki' }]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchMarketingActions(`${MARKETING_ACTIONS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) =>
          routerHistory.push(`/marketing/email-list/edit/${id}`)
        }
      />
    </>
  );
};

export default MarketingScreen;
