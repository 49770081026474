import { Typography, useTheme } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import PropTypes from 'prop-types';
import React from 'react';
import routerHistory from '../../constants/settings/routerHistory';
import RowWrapper from '../wrappers/RowWrapper';
import NoteLabel from './NoteLabel';

const CompanyLabel = ({ companyName, id = null, small = false }) => {
  const theme = useTheme();

  const redirectToCompany = e => {
    e.stopPropagation();
    if(id) {
      routerHistory.push(`/companies/edit/${id}`);
    }
  };

  return (
    <NoteLabel color={theme.palette.primary.light} style={small ? {padding: '2px', fontSize: '10px', display: 'inline-block', width: 'max-content', minWidth: '55px', cursor: 'pointer'} : {}}>
      <RowWrapper autoWidth onClick={redirectToCompany}>
        <WorkIcon style={{ fontSize: small ? 13 : 14 }} />
        <Typography
          style={{ marginLeft: '6px', fontSize: small ? '10px' : '0.75rem' }}
          color="inherit"
          variant="body2">
          {companyName}
        </Typography>
      </RowWrapper>
    </NoteLabel>
  );
};

CompanyLabel.propTypes = {
  companyName: PropTypes.string,
};

CompanyLabel.defaultProps = {
  companyName: 'Firma',
};

export default CompanyLabel;
