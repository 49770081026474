import React, {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {API_PRODUCTS_ROUTE} from '../../../../../constants/routes';
import {getCall} from "../../../../../components/indicator/Indicator";
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import Loader from "../../../../../components/loader/Loader";
import DevicesGroupModal from "../../shared/DevicesGroupModal";
import {UseActionZoneStyles} from "./ActionZoneStyles";
import {translateGroup, WITHOUT_GROUP} from "../../../../../constants/RepairDeviceGroup";
import {groupBy} from 'lodash'
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from '@material-ui/icons/History';
import routerHistory from "../../../../../constants/settings/routerHistory";
import {useParams} from "react-router-dom";

const RepairDevicesZone = ({devices = [], onUpdate}) => {
    const emptyRow = () => ({
        product: null,
        identity: '',
        serial_no: '',
        group_name: null,
        uuid: Math.random().toString(36).substring(7)
    });

    const createRow = device => ({...emptyRow(), ...device});

    const classes = UseActionZoneStyles();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const rows = useMemo(() => devices.map(createRow), [devices]);
    const groupedRows = useMemo(() => {
            const setEmptyGroup = rows => rows.map(row => ({...row, group_name: row?.group_name || WITHOUT_GROUP}));
            const grouped = groupBy(setEmptyGroup(rows), ({group_name, group_serial_no}) => group_name ? `${group_name}${group_serial_no ? ' - ' + group_serial_no : ''}` : null);
            return Object.entries(grouped);
        }, [rows]);
    const duplicateSerials = useMemo(() => {
        const allSerials = rows.map(({serial_no}) => serial_no || null);
        return allSerials.reduce((all, searchesSerial) => {
            const duplicates = allSerials.filter(serial => serial === searchesSerial);
            return duplicates.length > 1 ? [...all, searchesSerial] : [];
        }, [])
    }, [rows]);

    const addNewRow = (row = {}) => onUpdate([...devices, createRow(row)]);

    const editRow = (name, v, index) => {
        const localRows = [...rows];
        const i = findRow(index);
        if (~i) {
            localRows[i][name] = v;
        }
        onUpdate(localRows);
    };

    const removeRow = index => {
        const localRows = [...rows];
        const i = findRow(index);
        if (~i) {
            localRows.splice(i, 1);
            onUpdate(localRows);
        }
    };

    const removeMultiple = ids => {
        const localRows = [...rows];
        const indexes = [];
        for (const id of ids) {
            const i = findRow(id);
            if (~i) {
                indexes.push(i);
            }
        }
        onUpdate(localRows.filter((row, index) => !indexes.includes(index)))
    };

    const findRow = key => rows.findIndex(item => item.uuid === key || item.id === key);

    const addGroup = group => onUpdate([...devices, ...group]);

    const fetchDevices = async () => {
        try {
            const {data: {items = []} = {}} = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
            setProducts(items);
        } catch {
            toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
        }
    };

    useEffect(() => {
        fetchDevices().then(() => setLoading(false));
    }, []);

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell>Lp</TableCell>
                <TableCell>Nr seryjny</TableCell>
                <TableCell>Urządzenie</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Akcje</TableCell>
            </TableRow>
        </TableHead>
    );

    return (
        <>
            <a id="devices" />
            <ExpansionContainer title="Urządzenia">
                <ColumnWrapper fullWidth>
                    {loading
                        ? <Loader/>
                        : <>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="small"
                                aria-label="enhanced table">
                                {renderHeader()}
                                <TableBody>
                                    {groupedRows.map(([name, items]) =>
                                        <>
                                            <div style={{marginTop: 15, display: "flex", alignItems: "center"}}  key={name}>
                                                <h3>{translateGroup(name)}</h3>
                                                <IconButton
                                                    onClick={() => removeMultiple(items.map(item => item.uuid || item.id))}
                                                    aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            {items.map((item, index) =>
                                                <RepairDevicesZoneRow
                                                    i={item}
                                                    index={item.uuid || item.id}
                                                    key={item.uuid || item.id}
                                                    editRow={editRow}
                                                    products={products}
                                                    thisSerialIsBusy={item.serial_no && duplicateSerials.includes(item.serial_no)}
                                                    rowIndex={index + 1}
                                                    removeRow={removeRow}
                                                />)}
                                        </>
                                    )}
                                </TableBody>
                                <RowWrapper>
                                    <ColumnWrapper width="auto">
                                        <PrimaryButton onClick={() => setOpenGroupModal(true)}>Dodaj
                                            wiele</PrimaryButton>
                                    </ColumnWrapper>
                                    <ColumnWrapper width="auto">
                                        <PrimaryButton onClick={() => addNewRow()}>Dodaj jedno</PrimaryButton>
                                    </ColumnWrapper>
                                </RowWrapper>
                            </Table>
                        </>}
                </ColumnWrapper>
            </ExpansionContainer>
            <DevicesGroupModal
                open={openGroupModal}
                products={products}
                onClose={() => setOpenGroupModal(false)}
                onSubmit={addGroup}
            />
        </>
    );
};

const RepairDevicesZoneRow = ({i, index, editRow, removeRow, products, thisSerialIsBusy, rowIndex}) => {

    const findProduct = searchesId => products.find(({id}) => id === searchesId);
    const {id} = useParams();

    return <>
        <TableRow key={i.uuid + 'row'}>
            <TableCell>
                {rowIndex}
            </TableCell>
            <TableCell>
                {returnSpecificField(
                    'serial',
                    {
                        attrs: {
                            error: !!thisSerialIsBusy,
                            helperText: thisSerialIsBusy && 'Ten nr seryjny jest zajęty'
                        }
                    },
                    'serial_no',
                    i.serial_no,
                    (e, v) => editRow(e, v, index)
                )}
            </TableCell>
            <TableCell>
                {returnSpecificField('dictionary', {data: products}, 'product', i.product?.id, (e, v) => {
                    editRow(e, findProduct(v), index)
                })}
            </TableCell>
            <TableCell>
                {returnSpecificField('identity', {}, 'identity', i.identity, (e, v) => editRow(e, v, index))}
            </TableCell>
            <TableCell>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                        disabled={!id || !i.id}
                        variant="contained"
                        onClick={() => routerHistory.push(`/service/repairs/${id}/devices/${i?.id}/history`)}
                    >
                        <HistoryIcon/>
                    </Button>
                    <Button style={{marginLeft: 15}} variant="contained" onClick={() => removeRow(index)}>
                        <DeleteIcon/>
                    </Button>
                </div>
            </TableCell>
        </TableRow>
    </>
};

export default RepairDevicesZone;
