import React from 'react';

const repairsFieldsWarrantyCard = [ {
    "id": 9,
    "name": "warrantyCard",
    "type": "rowsGroup",
    "label": "",
    "value": "[]",
    "options": {
        "id": 3,
        "data": [],
        "fields": [
            {
                "id": 10,
                "name": "opis",
                "type": "text",
                "label": "Opis",
                "value": null,
                "options": null
            },
            {
                "id": 11,
                "name": "plik",
                "type": "attachment",
                "label": "Plik",
                "value": null,
                "options": null
            },
        ],
        "name": "Kontakty"
    }
}
];

export default repairsFieldsWarrantyCard;