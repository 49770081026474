import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles, Paper, TextField } from '@material-ui/core';
import React, {useEffect, useMemo, useState} from 'react';
import Select from '../../../../components/dynamic-forms/form-components/Select';

import plLocale from 'date-fns/locale/pl';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import commonValues from '../../../../tools/commonValues';
import { useLocation } from 'react-router-dom';
import {automationFormStatuses} from "../automation/automationForm";
import {getCall} from "../../../../components/indicator/Indicator";
import {API_COMPANIES_ROUTE, API_USERS_ROUTE} from "../../../../constants/routes";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

const ProjectsSearchFilters = (props) => {
    const location = useLocation();

    const isAutomation = useMemo(() => location?.pathname?.includes('/automation'), []);
    const [users, setUsers] = useState([]);
    const [projectKr, setProjectKr] = useState([]);
    const [voivodeship, setVoivodeship] = useState([]);

    const classes = useStyles();
    const onChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            newFilters[name] = val;
        } else {
            delete newFilters[name];
        }
        setFilters(newFilters);
    }
    const onQueryFieldsChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            filters.queryFields[name] = val;
        } else {
            delete filters.queryFields[name];
        }
        setFilters(newFilters);
    }
    const onStatusChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            filters.statuses[name] = name;
        } else {
            delete filters.statuses[name];
        }
        setFilters(newFilters);
    }

    const [filters, setFilters] = useState({ 
        queryFields: {},
        statuses: []
    });

    const [statusRange, setStatusRange] = useState({
        from: null,
        to: null
    });

    useEffect(() => {

            const fetchUsers = async () => {
                const r = await getCall(API_USERS_ROUTE);
                if (r?.data?.items) {
                    setUsers(
                        r?.data?.items?.map((el) => ({
                            id: el?.id,
                            name: el?.email,
                        })),
                    );
                }
            };

            fetchUsers();
        }, []);


    return (
        <>
        <Paper className={classes.paper}>

            <TextField
                fullWidth
                variant="outlined"
                size="small"
                label={'Nazwa projektu'}
                margin="dense"
                onChange={({ target }) => onChange('query', target.value)}
                />

            <TextField
                fullWidth
                variant="outlined"
                label={'Miasto'}
                margin="dense"
                onChange={({ target }) => onQueryFieldsChange('city_proj', target.value)}
                />

            <TextField
                fullWidth
                variant="outlined"
                label={'Projektant'}
                margin="dense"
                onChange={({ target }) => onQueryFieldsChange('projektant', target.value)}
                />

            <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Notatka"
                margin="dense"
                onChange={({ target }) => onChange('note', target.value)}
            />
                
            <Select
                fullWidth
                size="small"
                variant="outlined"
                label={"Województwo"}
                multiple={true}
                value={voivodeship}
                data={[{id: null, name: '-'}, ...commonValues.voivodeships]}
                onSelect={(option) => {
                    setVoivodeship(option)
                    onChange('voivodeship', option)
                }}
                />

            <Select
                fullWidth
                size="small"
                variant="outlined"
                label={"Projekt KR"}
                data={users}
                multiple={true}
                value={projectKr}
                onSelect={(option) => {
                    setProjectKr(option)
                    onChange('projectKr', option)
                }}
            />

            {isAutomation &&
            <Select
                fullWidth
                size="small"
                variant="outlined"
                label="Status automatyka"
                data={automationFormStatuses}
                onSelect={(option) => {
                    onChange('automationStatus', option)
                }}
            />
            }

            <Divider style={{margin: '1em 0px'}} />
            Status:

            <FormGroup>
            {commonValues.projectStatuses.map(item => (
                <FormControlLabel
                key={item.id+'_psfcl'}
                    control={<Checkbox name={item.id.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onStatusChange(item.name, e.target.checked)} />}
                    label={item.name}
                />
            ))}
        </FormGroup>
         <Divider style={{margin: '1em 0px'}} />
      

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                label="Zmiana statusu od"
                name={'status_od'}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
                value={statusRange.from || null}
                onChange={(val) => {
                    setStatusRange({...statusRange, from: val});
                    onChange('status_od', val ? val.getTime() : null)
                }}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                value={statusRange.to || null}
                onChange={(val) => {
                    setStatusRange({...statusRange, to: val});
                    onChange('status_do', val ? val.getTime() : null)
                }}
                label="Zmiana statusu do"
                name={'status_do'}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
            />
        </MuiPickersUtilsProvider>
        
        <br />

        <PrimaryButton
            style={{ marginLeft: 0, marginTop: 10 }}
            color="secondary"
            onClick={() => props?.handleSearch(filters)}>
            Szukaj
        </PrimaryButton>
        </Paper>
        </>
    );
};

export default ProjectsSearchFilters;
