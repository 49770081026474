import React, { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/pl';

import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import NoDataPanel from "../../../../components/panels/NoDataPanel";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { API_CALENDARS_MULTIPLE_EVENTS_ROUTE } from '../../../../constants/routes';
import routerHistory from '../../../../constants/settings/routerHistory';
import CalendarEvent from '../../../calendar/components/CalendarEvent';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { getCall } from '../../../../components/indicator/Indicator';


moment.locale('pl');
const localizer = momentLocalizer(moment);

const EventsBoard = () => {

  const [range, setRange] = useState({
    start: moment().startOf('day').format('X'),
    end: moment().endOf('day').format('X'),
  });
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
        const { data } = await getCall(
          `${API_CALENDARS_MULTIPLE_EVENTS_ROUTE}?ids=tasks,shared&startts=${range.start}&endts=${range.end}`,
        );
        if (data?.length) {
          const evs = data.map((e) => ({
            ...e,
            allDay: e.allDay,
            start: moment(parseFloat(`${e.start}000`)).toDate(),
            end: moment(parseFloat(`${e.stop}000`)).toDate(),
            title: e.title || 'Wydarzenie',
          }));
          setEvents(evs);
        }
    };
    fetchEvents();
  }, [range]);

  const onRangeChange = (r) => {
    if (r?.start && r?.end) {
      setRange({
        start: moment(r?.start).format('X'),
        end: moment(r?.end).format('X'),
      });
    } else {
      setRange({
        start: moment(r[0]).format('X'),
        end: moment(r[r.length - 1]).format('X'),
      });
    }
  };

  const eventPicked = (event) => {
    if(event?.calendar) {
      routerHistory.push('/calendar/edit-slot/' + event?.id);
    } else {
      if(event?.type === 'task') {
        routerHistory.push('/tasks/edit/' + event?.referId);
      }
    }
  }

  return (
    <>
      <RowWrapper fullWidth style={{ padding: '0 10px' }} className="main-calendar">
        <ExpansionContainer title="Dzisiejsze wydarzenia">
          <Calendar
            onRangeChange={(r) => onRangeChange(r)}
            style={{ height: '500px' }}
            localizer={localizer}
            events={events}
            messages={{
              next: '>',
              previous: '<',
              today: 'Dzisiaj',
              week: 'Tydzień',
              month: 'Miesiąc',
              agenda: 'Nadchodzące',
              day: 'Dzisiaj',
              date: 'Dzień',
              time: 'Godziny',
              allDay: 'cały dzień',
              event: 'Wydarzenie',
              more: 'więcej',
              noEventsInRange: 'Brak wydarzeń w zaznaczonym terminie',
            }}
            defaultView={"day"}
            views={['day', 'agenda']}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={(event) => eventPicked(event)}
            components={{
              eventWrapper: CalendarEvent,
            }}
          />
        </ExpansionContainer>
      </RowWrapper>
    </>
  );
};

export default EventsBoard;
