import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import RowWrapper from "../wrappers/RowWrapper";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  margin-top: 10px;
`;

const StyledDetails = styled(AccordionDetails)`
  flex-wrap: wrap;
  padding: 8px;
`;

const ExpansionContainer = ({
  children,
  title,
  childWrapperStyle,
  style,
  disabled,
}) => {
  return (
    <>
      <Wrapper style={style}>
        <Accordion
          disabled={disabled}
          defaultExpanded
          style={{ width: '100%' }}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <RowWrapper>
              <ExpandMoreIcon />
              <Typography
                style={{ marginLeft: '10px', fontWeight: 'bold' }}
                color="secondary">
                {title}
              </Typography>
            </RowWrapper>
          </AccordionSummary>
          <StyledDetails style={childWrapperStyle}>{children}</StyledDetails>
        </Accordion>
      </Wrapper>
    </>
  );
};

ExpansionContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  childWrapperStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

ExpansionContainer.defaultProps = {
  disabled: false,
};

export default ExpansionContainer;
