import { Button, makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import returnSpecificField from '../../../components/dynamic-forms/returnSpecificField';
import { postCall } from '../../../components/indicator/Indicator';
import ColumnWrapper from '../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../components/wrappers/RowWrapper';
import { MARKETING_TAGS_ROUTE } from '../../../constants/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '60%',
      minWidth: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const MarketingTagModal = ({tag, onClose, ...props}) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [fieldText, setFieldText] = React.useState(tag?.name || '');

    React.useEffect(() => {
        if(tag) {
            setFieldText(tag.name);
            setOpen(true);
        }
    }, [tag]);

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    const edit = (e, v) => {
        setFieldText(v);
    }

    const save = async (url = MARKETING_TAGS_ROUTE + '/name') => {
            const r = await postCall(url, {
                id: tag?.id,
                name: fieldText
            });

            if (r) {
                handleClose();
            }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="tag-modal"
        >
        <div className={classes.paper}>
            <h2 id="tag-modal">Nadaj nazwę</h2>
            <RowWrapper style={{flexWrap: 'wrap'}}>
                <ColumnWrapper width="100%">
                    {returnSpecificField('text', {}, 'displayName', fieldText, (e, v) => {edit(e, v)}, "Nazwa wyświetlana" )}
                </ColumnWrapper>
            </RowWrapper>
            <RowWrapper style={{flexWrap: 'wrap'}}>
                <ColumnWrapper width="100%">
                    <PrimaryButton onClick={() => save()}>Zapisz</PrimaryButton>
                </ColumnWrapper>
            </RowWrapper>
        </div>
        </Modal>
    )
}

export default MarketingTagModal;