import React, {useEffect, useMemo, useRef} from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpansionContainer from "../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../components/dynamic-forms/DynamicForm";
import {getCall, putCall} from "../../../components/indicator/Indicator";
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../components/wrappers/RowWrapper";
import VariantToolBar from "../components/variants/tool-bar/VariantToolBar";
import OfferPeripheralDevicesZone from "../components/variants/variant-screens/peripheral/OfferPeripheralDevicesZone";
import OfferDevicesDevicesZone from "../components/variants/variant-screens/devices/OfferDevicesDevicesZone";
import OfferDevicesStartPriceCalculator from "../components/variants/variant-screens/devices/OfferDevicesStartPriceCalculator";
import ProjectVariantDetailPreview from "./ProjectVariantDetailPreview";
import { autoOffer, initialFormSectionDrivers } from '.././components/variants/variant-screens/offer-automatic';
import { devicesOffer } from '.././components/variants/variant-screens/offer-devices';
import { peripheralOffer } from '.././components/variants/variant-screens/offer-peripheral';
import FloatingSaveBar from "../../../components/FloatingSaveBar";
import {API_PROJECT_VARIANTS_ROUTE, API_PROJECT_VARIANTS_PREVIEW_ROUTE} from '../../../constants/routes';
import Loader from "../../../components/loader/Loader";
import routerHistory from "../../../constants/settings/routerHistory";
import {responseToForm, formToRequest} from "../../../components/dynamic-forms/field-transformer";
import { useStoreState } from 'easy-peasy';
import { Modal } from '@material-ui/core';

const ProjectVariantDetailScreen = ((props) => {
  const [loading, setLoading] = React.useState(true);
  const [variant, setVariant] = React.useState({});
  const [addProps, setAddProps] = React.useState({ 'Automatyka dedykowana': { style: { display: 'none'}}});
  const [initialValues, setInitialValues] = React.useState({});
  const [projectName, setProjectName] = React.useState('');
  const [isPreview, setIsPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({});
  const [formSectionDrivers, setFormSectionDrivers] = React.useState(initialFormSectionDrivers);
  const formRef = useRef();
  const { id, vid, type } = useParams();

  const [launchCost, setLaunchCost] = React.useState(0);

  useEffect(( ) => {
    fetchVariant().then(() => setLoading(false));
    fetchListPreview();
  }, [vid]);

  const localState = useStoreState((state) => state.auth?.loggedInUser);

  const fetchVariant = async () => {
    try {
      const {data: {item: variant, projectName} = {}} = await getCall(`${API_PROJECT_VARIANTS_ROUTE}/${vid}`);
      setFormSectionDrivers(responseToForm(variant?.automation?.fields));
      setInitialValues(responseToForm(variant?.automation?.fields || [{ name: 'Temat', value: projectName}, { name: 'Przygotował', value: localState?.username}]));
      setProjectName(projectName);
      setVariant(() => variant);
      setLaunchCost(variant?.launchCost);
    } catch {
      toast.error('Wystąpił błąd podczas pobierania wariantu.');
    }
  };

  const fetchListPreview = async () => {
    const previewData = await getCall(API_PROJECT_VARIANTS_PREVIEW_ROUTE);
    setPreviewData(previewData.data);
  }

  const save = async () => {
    const body = type === 'aut'
        ? {...variant, automation: {...variant.automation, fields: formToRequest(formRef.current.getFormValues())}}
        : variant;

      if(type === 'dev') {
        variant.launchCost = launchCost;
      }

    try {
      await putCall(`${API_PROJECT_VARIANTS_ROUTE}/${vid}`, body);
      toast.success('Zaktualizowano wariant.');
      if(type === 'aut') {
        window.location.reload();
      } else  {
        routerHistory.push(`/projects/edit/${id}`);
      }
    } catch {
      toast.error('Wystąpił błąd podczas zapisu wariantu.');
    }
  };

  const hideField = (f, hide = true) => {
    const newAddProps = {...addProps};
    const found = newAddProps[f];
    if(!found) {
      newAddProps[f] = {};
    }
    newAddProps[f] = { ...newAddProps[f], style: { display: hide ? 'none' : 'block' } }
    setAddProps(newAddProps);
  }

  const preview = async () => {
    setIsPreview(true);
  }

  const checkFormSectionValues = (form) => {
    form.forEach(item => {
      item.options.fields.forEach(({name, type}) => {
        if(type === 'none' && name) {
          formRef.current.setFormValue(name, "");
          setFormSectionDrivers((prev) => ({
            ...prev,
            [name]: '',
          }))
        }
      })
    })
  }

  const form = useMemo(() => autoOffer(formSectionDrivers), [formSectionDrivers]);

  const onChangeMiddlewareAuto = (f, v) => {
    if(f in initialFormSectionDrivers) {
      setFormSectionDrivers((prev) => ({
        ...prev,
        [f]: v,
      }))
    }
    
    const values = formRef?.current.getFormValues();
    let currPrice = 0;
    // console.log(f, v)

    switch(f) {
      case 'Wariant automatyki':
        if(v === 'Automatyka dedykowana') {
          hideField('Automatyka dedykowana', false);

        } else {
          hideField('Automatyka dedykowana');
        }
      break;
      case 'Gwarancja 3 letnia - mnożnik':
        currPrice = values['Cena katalogowa z 2 letnią gwarancją - cena'] || 0;
          formRef.current.setFormValue('Gwarancja 3 letnia - cena', (currPrice * (v.replace(/,/, '.') || 0)));
          break;
      case 'Gwarancja 4 letnia - mnożnik':
        currPrice = values['Cena katalogowa z 2 letnią gwarancją - cena'] || 0;
        formRef.current.setFormValue('Gwarancja 4 letnia - cena', (currPrice * (v.replace(/,/, '.') || 0)));
        break;
      case 'Gwarancja 5 letnia - mnożnik':
        currPrice = values['Cena katalogowa z 2 letnią gwarancją - cena'] || 0;
        formRef.current.setFormValue('Gwarancja 5 letnia - cena', (currPrice * (v.replace(/,/, '.') || 0)));
        break;
      case 'Cena katalogowa z 2 letnią gwarancją - cena':
        currPrice = v.replace(/,/, '.') || 0;
        let year_3 = values['Gwarancja 3 letnia - mnożnik']?.toString();
        let year_4 = values['Gwarancja 4 letnia - mnożnik']?.toString();
        let year_5 = values['Gwarancja 5 letnia - mnożnik']?.toString();

        formRef.current.setFormValue('Gwarancja 3 letnia - cena', (currPrice * ( year_3?.replace(/,/, '.') || 0)));
        formRef.current.setFormValue('Gwarancja 4 letnia - cena', (currPrice * ( year_4?.replace(/,/, '.') || 0)));
        formRef.current.setFormValue('Gwarancja 5 letnia - cena', (currPrice * ( year_5?.replace(/,/, '.') || 0)));
        break;
      default:

      break;
    }
  }

  return (
    <>
      <ColumnWrapper>
        <VariantToolBar />
        <RowWrapper>
            {loading
                ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}><Loader/></div>
                :
                <ColumnWrapper>
                  <ExpansionContainer title={`Wariant${variant?.name ? (' - ' + variant?.name) : ''}`}>
                    {type == 'aut' && <DynamicForm
                        initialValues={initialValues}
                        fields={form}
                        addProps={addProps}
                        onChangeMiddleware={(f, v) => {
                            onChangeMiddlewareAuto(f, v);
                        }}
                        ref={formRef}
                        small={true}
                        avoidTabs={true} />}
                    {type == 'per' && <>
                      <OfferPeripheralDevicesZone initRows={variant.peripherals} setRootRows={peripherals => {
                        setVariant(variant =>
                            ({...variant, peripherals})
                        )
                      }} />
                      <DynamicForm fields={peripheralOffer} ref={formRef} small={true} avoidTabs={true} />
                    </>}
                    {type == 'dev' && <>
                      <OfferDevicesDevicesZone initRows={variant.devices} setRootRows={devices => {
                        setVariant(variant =>
                            ({...variant, devices})
                        )
                      }} />
                      <OfferDevicesStartPriceCalculator ref={formRef} initRows={variant.devices} initialLaunchCost={variant?.launchCost} onLaunchCost={(v) => setLaunchCost(v)} />
                      <DynamicForm fields={devicesOffer} ref={formRef} small={true} avoidTabs={true} />
                    </>}
                  </ExpansionContainer>
                </ColumnWrapper>
            }
        </RowWrapper>
      </ColumnWrapper>
      <FloatingSaveBar handlePreview={preview} onClick={async () => {
        checkFormSectionValues(form);
        await Promise.resolve();
        save();
      }} />
      <Modal
        open={isPreview}
        onClose={() => setIsPreview(false)}
        style={{ overflow: 'auto' }}
      >
        <ProjectVariantDetailPreview fields={formToRequest(formRef?.current?.getFormValues())} previewData={previewData} />
      </Modal>
    </>
  );
});

export default ProjectVariantDetailScreen;
