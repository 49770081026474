import React from 'react';
import { Typography } from '@material-ui/core';
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../components/wrappers/RowWrapper";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import BasicTable from "../../../components/tables/basic/BasicTable";

const ImportContactListScreen = () => (
  <>
    <ColumnWrapper>
      <RowWrapper style={{ justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography variant="body2">
          Odnaleźliśmy następujące kontakty. Potwierdź import lub wyiberz kontakty do ignorowania.
        </Typography>
        <PrimaryButton>Importuj</PrimaryButton>
      </RowWrapper>
      <BasicTable headCells={[]} rows={[]} />
    </ColumnWrapper>
  </>
);

export default ImportContactListScreen;
