import React, {useEffect, useState} from 'react';
import { API_PROJECTS_ROUTE, API_REPAIRS_ROUTE} from '../../../constants/routes';
import { getCall } from '../../../components/indicator/Indicator';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import {useParams} from "react-router-dom";
import _ from "lodash";

const DynamicWonOffer = ({ name, label, onChange, getOptionLabel, renderInput, value, ...props }) => {
    const [selected, setSelected] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [options, setOptions] = useState(['']);
    const [open, setOpen] = useState(false);
    const loading = open && options.length === 0;
    const { id } = useParams();

    const handleSelect = (newInputValue) => {
        setSelected(newInputValue);
        onChange(newInputValue);
    }

    const fetchData = async () => {
        const { data } = await getCall(`${API_REPAIRS_ROUTE}/${id}`);
        if (data) {
            setProjectId(data?.object?.project?.id)
        }
    };

    const fetchProject = async () => {
        if(projectId) {
            const { data } = await getCall(`${API_PROJECTS_ROUTE}/${projectId}/full`);
            if (data) {
                const order = data.project.fields.find((field) => field.name === 'zam_zams')
                const offers = order?.value.map((offer) => offer.zam_zams_oferta).flat().filter((item) => item.name.includes('urzadzenia'));
                const uniqOffers = _.uniqBy(offers, (e) => e.name);
                if(uniqOffers.length) {
                    setOptions(uniqOffers);
                }
            }
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])

    useEffect(() => {
        fetchProject();
    }, [projectId])

    useEffect(() => {
        if(value){
            setSelected(() => value)
        }
    }, [value])

    return (
        <Autocomplete
            multiple={true}
            name={name}
            options={options}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                handleSelect(newInputValue);
            }}
            clearOnBlur={false}
            onChange={(event, newValue) => handleSelect(newValue)}
            value={selected || []}
            getOptionLabel={(option) => option.name || ''}
            loadingText={'Wczytuję...'}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    value={selected || ''}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )
            }
            {...props}
        />
    )
}

export default DynamicWonOffer;