const service = [
  {
    type: 'multipleFields',
    name: 'Informacje',
    value: [],
    options: { fields: [
        { label: 'Nazwa projektu', name: 'Nazwa projektu', value: '', },
        { label: 'Adres', name: 'Adres', value: '', },
        { label: 'Os. Kontakt', name: 'Os. Kontakt', type: 'date', value: '', },
        { label: 'Telefon', name: 'Telefon', type: 'text', value: '', },
        { label: 'E-mail', name: 'E-mail', type: 'text', value: '', },
        { label: 'Serwisant', name: 'Serwisant', type: 'text', value: '', },
        { label: 'KR', name: 'KR', type: 'text', value: '', },
        { label: 'Inwestor', name: 'Inwestor', type: 'text', value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Daty',
    value: [],
    options: { fields: [
        { label: 'Data uruchomienia', name: 'Data uruchomienia', type: 'date', value: '', },
        { label: 'Data instalacji', name: 'Data instalacji', type: 'date', value: '', },
        { label: 'Data startu gwarancji', name: 'Data startu gwarancji', type: 'date', value: '', },
        { label: 'Data ost. przeglądu', name: 'Data ost. przeglądu', type: 'date', value: '', },
        { label: 'Data nast. przeglądu', name: 'Data nast. przeglądu', type: 'date', value: '', },
        { label: 'Gwarancja', name: 'Gwarancja', type: 'text', value: '', },
        { label: 'Przegląd po [w latach]', name: 'Przegląd po', type: 'text', value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Karta gwarancyjna',
    value: [],
    options: { fields: [
        { label: 'Notatka KG', name: 'Notatka KG', type: 'text', value: '', },
        { label: 'Karta gwarancyjna', name: 'Karta gwarancyjna', type: 'attachment', value: '', },
      ]
    },
  },
];

const serviceOffer = [
  {
    type: 'multipleFields',
    name: 'Informacje',
    value: [],
    options: { fields: [
        { name: 'Dane podstawowe', type: 'header', fullWidth: true, value: null },
        { label: 'Data', name: 'Data', type: 'dateonly', value: (new Date()), },
        { label: 'Wybór serwisanta', name: 'Wybór serwisanta', value: null, type: 'entity', options: { entityClass: 'repairer' }},
        { label: 'Przedstawiciel', name: 'Przedstawiciel', type: 'text', value: '', value: null, type: 'entity', options: { entityClass: 'user' } },
        { label: 'Telefon', name: 'Telefon', type: 'text', value: '', },
        { label: 'Wariant przeglądu', name: 'Wariant przeglądu', type: 'dictionary', value: null, options: { data: [
          { id: '1', name: 'Przegląd 1-roczny' },
          { id: '2', name: 'Przegląd 2-letni' },
          { id: '3', name: 'Przegląd 5-letni' }
        ]} },
        { label: 'Uwagi do oferty', name: 'Uwagi do oferty', type: 'multilineInput', fullWidth: true, value: '', },
        { label: 'Średni kurs €', name: 'Średni kurs €', type: 'text', value: '',  fullWidth: true, options: { attrs: { pattern: '{\d.,}*'}} },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Zastosowane urządzenia',
    value: [],
    options: { fields: [
      { name: 'Zastosowane urządzenia', type: 'header', value: null, options: { width: 8} },
      { name: 'Pobierz urządzenia', type: 'button', value: null, options: { attrs: { color: 'secondary' }, text: 'Pobierz urządzenia', width: 4} },
      { name: 'devices', type: 'rowsGroup', value: [], options: { attrs: { disableAdd: true },
        fields: [
          { label: 'Producent', name: 'producent', type: 'text', value: '', options: { attrs: { disabled: true }}},
          { label: 'Rodzaj', name: 'rodzaj', type: 'text', value: '', options: { attrs: { disabled: true }}},
          { label: 'Urządzenie', name: 'urzadzenie', type: 'text', value: '', options: { attrs: { disabled: true }}},
          { label: 'Ilość', name: 'ilosc', type: 'text', value: '', options: { attrs: { disabled: true }}},
          { label: 'Koszt', name: 'koszt', type: 'text', value: ''},
        ]}},
        { label: 'Ilość urządzeń', name: 'Ilość urządzeń', type: 'text', value: null, options: { attrs: { type: 'number', disabled: true }}},
        { label: 'Koszt czynności serwisowych', name: 'Koszt czynności serwisowych', type: 'text', value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Dojazd',
    value: [],
    options: { fields: [
      { name: 'Dojazd', type: 'header', fullWidth: true, value: null },
        { label: 'ilość km', name: 'ilość km', type: 'text', value: '', },
        { label: 'w jedną/dwie strony', name: 'w jedną/dwie strony', type: 'text', value: '', },
        { label: 'cena za 1km', name: 'cena za 1km', type: 'text', value: '', },
        { label: 'koszt dojazdu', name: 'koszt dojazdu', type: 'text', value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Zestawienie kosztów',
    value: [],
    options: { fields: [
      { name: 'Zestawienie kosztów', type: 'header', fullWidth: true, value: null },
        { label: 'Koszty czynności przeglądowych:', name: 'Koszty czynności przeglądowych', type: 'text', value: '', options: { width: 3} },
        { label: 'Koszt dojazdu:', name: 'koszt dojazdu', type: 'text', value: '', options: { width: 3} },
        { label: 'Koszty wymiany elementów eksploatacyjnych*:', name: 'Koszty wymiany elementów eksploatacyjnych', type: 'text', value: '', options: { width: 3} },
        { label: 'Koszty dodatkowe**:', name: 'Koszty dodatkowe', type: 'text', value: '', options: { width: 3} },
        { label: 'Koszt całościowy', fullWidth: true, name: 'Koszt całościowy', type: 'text', value: 0 },
        { name: 'Dodatek:', type: 'header', value: '', options: { width: 3} },
        { label: '1)', name: 'dodatek1', type: 'text', value: 800, options: { width: 3}  },
        { label: '2)', name: 'dodatek2', type: 'text', value: 1200, options: { width: 3}  },
        { label: '3)', name: 'dodatek3', type: 'text', value: 2800, options: { width: 3}  },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Dane dla odbiorcy',
    value: [],
    options: { fields: [
      { name: 'Dane dla odbiorcy', type: 'header', fullWidth: true, value: null },
        { label: 'Klient lub Inwestor:', name: 'Klient lub Inwestor - firma', type: 'none', value: '', fullWidth: true },
        { label: 'Firma', name: 'firma', type: 'entity', value: '', options: { width: 4, entityClass: 'company' } },
        { label: 'Osoba kontaktowa', name: 'Klient lub Inwestor - osoba kontaktowa', type: 'entity', value: '', options: { width: 4, attrs: { forceParamId: 'firma'}, entityClass: 'company_people' } },
        { label: 'Adres', name: 'Klient lub Inwestor - adres', type: 'text', value: '', options: { width: 4 } },
        
        { type: 'none', value: '', fullWidth: true },
        { label: 'Lokalizacja instalacji', name: 'Lokalizacja instalacji', type: 'multilineInput', fullWidth: true, value: '', },
        { label: 'Rodzaj urządzeń', name: 'Rodzaj urządzeń', type: 'multilineInput', fullWidth: true,  value: '', },
        { label: 'Serwisant', name: 'Serwisant', type: 'multilineInput', fullWidth: true,  value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Informacje na ofercie',
    value: [],
    options: { fields: [
      { name: 'Lista czynności', type: 'header', fullWidth: true, value: null },
        { label: 'Lista czynności po 1 roku', name: 'Lista czynności po 1 roku', type: 'multilineInput', 
        value: '1. Ocenę ogólnego stanu urządzenia.	\r\n2. Ocenę wymiennika lamelowego.*	\r\n3. Sprawdzenie stanu pasków napędowych i kół pompy olejowej.*	\r\n4. Sprawdzenie przepływomierza lub czujnika przepływu.	\r\n5. Sprawdzenie przepływu w instalacji.	\r\n6. Sprawdzenie stanu komory spalania (jeżeli konieczne).	\r\n7. Sprawdzenie stanu elektrod (jeżeli konieczne).	\r\n8. Sprawdzenie szczelności instalacji gazowej urządzenia.	\r\n9. Sprawdzenie ciśnienia statycznego i dynamicznego gazu w instalacji.	\r\n10. Kontrolę parametrów spalania.	\r\n11. Ocenę stanu i czyszczenie wylotu spalin.	\r\n12. Sprawdzenie drożności odprowadzenia kondensatu.	\r\n13. Sprawdzenie czy urządzenia osiągają zadaną temperaturę.	\r\n14. Sprawdzenie czasu pracy poszczególnych urządzeń.	\r\n15. Sprawdzenie i ściągnięcie danych historycznych urządzeń.	\r\n* dotyczy pomp ciepła i wytwornic wody lodowej\r\n', 
        options: { width: 4 }},
        { label: 'Lista czynności po 2 latach', name: 'Lista czynności po 2 latach', type: 'multilineInput', 
        value: '1. Ocenę ogólnego stanu urządzenia.				\r\n 2. Ocenę wymiennika lamelowego (czyszczenie wymiennika).*				\r\n 3. Sprawdzenie stanu pasków napędowych i kół pompy olejowej.*				\r\n 4. Sprawdzenie stanu oleju w pompie.*				\r\n 5. Sprawdzenie przepływomierza lub czujnika przepływu.				\r\n 6. Sprawdzenie przepływu w instalacji.				\r\n 7. Sprawdzenie stanu komory spalania (czyszczenie).				\r\n 8. Sprawdzenie stanu elektrod (czyszczenie).				\r\n 9. Sprawdzenie szczelności instalacji gazowej urządzenia.				\r\n 10. Sprawdzenie ciśnienia statycznego i dynamicznego gazu w instalacji.				\r\n 11. Kontrolę parametrów spalania.				\r\n 12. Ocenę stanu i czyszczenie wylotu spalin.				\r\n 13. Sprawdzenie drożności odprowadzenia kondensatu.				\r\n 14. Sprawdzenie czy urządzenia osiągają zadaną temperaturę.				\r\n 15. Sprawdzenie czasu pracy poszczególnych urządzeń.				\r\n 16. Sprawdzenie i ściągnięcie danych historycznych urządzeń.				\r\n * dotyczy pomp ciepła i wytwornic wody lodowej				\r\n ', 
        options: { width: 4 }},
        { label: 'Lista czynności po 5 latach', name: 'Lista czynności po 5 latach', type: 'multilineInput', 
        value: '1. Ocenę ogólnego stanu urządzenia.\r\n 2. Ocenę wymiennika lamelowego (czyszczenie wymiennika).*\r\n 3. Wymiana pasków napędowych pompy olejowej.*\r\n 4. Wymiana oleju w pompie olejowej.*\r\n 5. Sprawdzenie przepływomierza lub czujnika przepływu.\r\n 6. Sprawdzenie przepływu w instalacji.\r\n 7. Sprawdzenie stanu komory spalania.\r\n 8. Sprawdzenie stanu elektrod.\r\n 9. Sprawdzenie szczelności instalacji gazowej urządzenia.\r\n 10. Sprawdzenie ciśnienia statycznego i dynamicznego gazu w instalacji.\r\n 11. Kontrolę parametrów spalania.\r\n 12. Ocenę stanu i czyszczenie wylotu spalin.\r\n 13. Sprawdzenie drożności odprowadzenia kondensatu.\r\n 14. Sprawdzenie czy urządzenia osiągają zadaną temperaturę.\r\n 15. Sprawdzenie czasu pracy poszczególnych urządzeń.\r\n 16. Sprawdzenie i ściągnięcie danych historycznych urządzeń.\r\n * dotyczy pomp ciepła i wytwornic wody lodowej\r\n ', 
        options: { width: 4 }},
        { label: 'Koszty wymiany elementów eksploatacyjnych*:', name: 'Koszty wymiany elementów eksploatacyjnych opis', type: 'multilineInput', 
        value: '* W ofercie uwzględniono koszty wymiany podstawowych elementów eksploatacyjnych według zaleceń producenta dla określonych interwałów przeglądowych (np. uszczelki, itp.)\r\nW przypadku konieczności wymiany części eksploatacyjnych, których zużycie nie zostało przewidziane przez producenta dla określonych interwałów przeglądowych, serwisant zobowiązany jest poinformować o tym fakcie klienta i przedstawić szacunkowy koszt wymiany elementów nieuwzględnionych w ofercie.\r\n* W ofercie nie uwzględniono kosztów wymiany elementów eksploatacyjnych (np. uszczelki, elektrody, pasy napędowe i koła pasowe, itp.)\r\nW przypadku konieczności wymiany części eksploatacyjnych, których zużycie nie zostało przewidziane przez producenta dla określonych interwałów przeglądowych, serwisant zobowiązany jest poinformować o tym fakcie klienta i przedstawić szacunkowy koszt wymiany elementów nieuwzględnionych w ofercie.', 
        options: { width: 6 }},
        { label: 'Koszty dodatkowe**:', name: 'Koszty dodatkowe opis', type: 'multilineInput', 
        value: '** Koszty dodatkowe wynikają z konieczności oddelegowania kolejnych serwisantów w ramach realizacji przeglądu dużej liczby urządzeń. \r\n ** Koszty dodatkowe nie zostały naliczone. \r\n ', 
        options: { width: 6 }},
        { label: 'Dodatkowe punkty:', name: 'Dodatkowe punkty', type: 'multilineInput', 
        value: '1. Do ceny należy doliczyć podatek VAT.\r\n 2. Ważność oferty: 30 dni.\r\n 3. Płatność na podstawie wystawionej faktury VAT w ciągu 7 dni.\r\n ', 
        options: { width: 6 }},
      ]
    },
  },
];


export { service, serviceOffer };