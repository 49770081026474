const commonValues = {
    voivodeships: [
        {
            "id": "dolnoslaskie",
            "name": "dolnośląskie"
        },
        {
            "id": "kujawsko-pomorskie",
            "name": "kujawsko-pomorskie"
        },
        {
            "id": "lubelskie",
            "name": "lubelskie"
        },
        {
            "id": "lubuskie",
            "name": "lubuskie"
        },
        {
            "id": "łodzkie",
            "name": "łódzkie"
        },
        {
            "id": "małopolskie",
            "name": "małopolskie"
        },
        {
            "id": "mazowieckie",
            "name": "mazowieckie"
        },
        {
            "id": "opolskie",
            "name": "opolskie"
        },
        {
            "id": "podkarpackie",
            "name": "podkarpackie"
        },
        {
            "id": "podlaskie",
            "name": "podlaskie"
        },
        {
            "id": "pomorskie",
            "name": "pomorskie"
        },
        {
            "id": "sląskie",
            "name": "śląskie"
        },
        {
            "id": "swietokrzyskie",
            "name": "świętokrzyskie"
        },
        {
            "id": "warminsko-mazurskie",
            "name": "warmińsko-mazurskie"
        },
        {
            "id": "wielkopolskie",
            "name": "wielkopolskie"
        },
        {
            "id": "zachodniopomorskie",
            "name": "zachodniopomorskie"
        },
    ],
    projectStatuses: [
        { name: 'Temat', id: 'temat' },
        { name: 'Audyt', id: 'audyt' },
        { name: 'Projekt', id: 'projekt' },
        { name: 'Inwestycja', id: 'inwestycja' },
        { name: 'Zamówienie', id: 'zamowienie' },
        { name: 'Dostarczony', id: 'dostarczony' },
        { name: 'Uruchomiony', id: 'uruchomiony' },
        { name: 'Anulowany', id: 'anulowany' },
        { name: 'Zamrożony', id: 'zamrozony' },
    ],
    taskPriorities: [{id: 3, name: 'pilne'}, {id: 2, name: 'średni'}, {id: 1, name: 'niski'}]
}

export default commonValues;