import React, {useMemo} from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {UseActionZoneStyles} from "./ActionZoneStyles";
import {translateGroup, WITHOUT_GROUP} from "../../../../../constants/RepairDeviceGroup";
import {groupBy} from "lodash";

const ActionLaunchDeviceZonePreview = ({repairGroups, devices}) => {
    const classes = UseActionZoneStyles();

    const groupedRows = useMemo(() => {
            const setEmptyGroup = devices => devices.map(row => ({...row, group_name: row?.group_name || WITHOUT_GROUP}));
            const grouped = groupBy(setEmptyGroup(devices), ({group_name, group_serial_no}) => group_name ? `${group_name}${group_serial_no ? ' - ' + group_serial_no : ''}` : null);
            return Object.entries(grouped);
        }
        , [devices]);

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell>Lp</TableCell>
                <TableCell>Nr seryjny</TableCell>
                <TableCell>Urządzenie</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Części na gwarancji</TableCell>
                <TableCell>Części nie na gwarancji</TableCell>
            </TableRow>
        </TableHead>
    );

    return (
        <>
            <ExpansionContainer title="Urządzenia">
                <ColumnWrapper fullWidth>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table">
                        {renderHeader()}
                        <TableBody>
                            {groupedRows.map(([name, items], i) =>
                                <>
                                    <div style={{marginTop: 15, display: "flex", alignItems: "center"}} key={name}>
                                        <h3>{translateGroup(name)}</h3>
                                    </div>
                                    {name !== 'WITHOUT_GROUP' && (
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <b>{repairGroups[i]?.group_serial_no}</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Ilość urządzeń: {items?.length}</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>{repairGroups[i]?.id}</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>
                                                    {repairGroups[i]?.parts_under_warranty?.map((part, i, row) => {
                                                        if (i + 1 === row.length ) {
                                                            return part.name;
                                                        } else {
                                                            return `${part.name}, `;
                                                        }
                                                    })}
                                                </b>
                                            </TableCell>
                                            <TableCell>
                                                <b>
                                                    {repairGroups[i]?.parts_not_under_warranty?.map((part, i, row) => {
                                                        if (i + 1 === row.length ) {
                                                            return part.name;
                                                        } else {
                                                            return `${part.name}, `;
                                                        }
                                                    })}
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {items.map((item, index) =>
                                        <RepairDevicesZoneRow i={item} rowIndex={index+1} key={item.uuid || item.id}/>)
                                    }
                                </>
                            )}
                        </TableBody>
                    </Table>
                </ColumnWrapper>
            </ExpansionContainer>
        </>
    );
};

const RepairDevicesZoneRow = ({i, rowIndex}) => {
    return <>
        <TableRow key={i.id + 'row'}>
            <TableCell>
                {rowIndex}
            </TableCell>
            <TableCell>
                {i?.serial_no || '-'}
            </TableCell>
            <TableCell>
                {i?.product?.name || '-'}
            </TableCell>
            <TableCell>
                {i?.identity || '-'}
            </TableCell>
            <TableCell>
                {i?.parts_under_warranty?.map(({name}) => name).join(', ') || '-'}
            </TableCell>
            <TableCell>
                {i?.parts_not_under_warranty?.map(({name}) => name).join(', ') || '-'}
            </TableCell>
        </TableRow>
    </>
};

export default ActionLaunchDeviceZonePreview;
