import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import { API_PROJECT_VARIANTS_ROUTE } from '../../../../constants/routes';
import { getCall } from '../../../../components/indicator/Indicator';
import DynamicForm from "../../../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { autoOffer } from './offer-automatic';
import { toast } from 'react-toastify';

const AdvancedOfferAuto = forwardRef((props, ref) => {
  
  const formRef = useRef();
  const [variant, setVariant] = useState(null);
  const [addProps, setAddProps] = useState({});

  const onChangeMiddleware = (f, v) => {
    switch(v) {
      case '2 lata':
        ref.current.setFormValue("Cena",
          variant?.automation?.fields.find((field) => field.name === 'Cena katalogowa z 2 letnią gwarancją - cena').value);

        break;
      case '3 lata':
        ref.current.setFormValue("Cena",
          variant?.automation?.fields.find((field) => field.name === 'Gwarancja 3 letnia - cena').value);

        break;
      case '4 lata':
        ref.current.setFormValue("Cena",
          variant?.automation?.fields.find((field) => field.name === 'Gwarancja 4 letnia - cena').value);

        break;
      case '5 lat':
        ref.current.setFormValue("Cena",
          variant?.automation?.fields.find((field) => field.name === 'Gwarancja 5 letnia - cena').value);

        break;
    }
  }

  useEffect(() => {
    const fetchVariant = async () => {
      try {
        const {data: {item: variant} = {}} = await getCall(`${API_PROJECT_VARIANTS_ROUTE}/${props.projectVariant}`);
        setVariant(() => variant);
      } catch {
        toast.error('Wystąpił błąd podczas pobierania wariantu.');
      }
    };

    fetchVariant();
  }, [])

  return (
    <>
      <RowWrapper fullWidth>
        <ColumnWrapper fullWidth>
          <DynamicForm initialValues={props?.initialValues} fields={autoOffer} ref={ref} small={true} avoidTabs={true} onChangeMiddleware={onChangeMiddleware} addProps={addProps} />
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper fullWidth>
        <ColumnWrapper width="20%">
          <Divider />
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
});

AdvancedOfferAuto.propTypes = {
};

export default AdvancedOfferAuto;
