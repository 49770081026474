import styled from 'styled-components';

const NoteLabel = styled.div`
  background: ${(props) => props.color};
  color: #fff;
  max-width: 140px;
  overflow-x: hidden;
  border-radius: 6px;
  padding: 4px 8px;
  margin: 0 5px;
  width: 100%;
  ${props => props.style ? props.style : null}
`;

export default NoteLabel;
