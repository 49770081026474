import { LinearProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStoreState, useStoreActions, useStoreDispatch, useStore } from 'easy-peasy';
import { API_USER_PROFILE_ROUTE } from '../../constants/routes';
import apiCall from "../../tools/api/apiCall";
import checkTokenValidation from "../../tools/checkTokenValidation";
import Portal from '../portal/Portal';

let dispatch = null;
let actions = null;

const Indicator = (props) => {
  const indicatorVisible = useStoreState((state) => state.ui.indicatorVisible);
  const store = useStore();
  const { setLoggedInUser, setIsAuthenticated } = useStoreActions((actions) => ({
    setLoggedInUser: actions.auth.setLoggedInUser, 
    setIsAuthenticated: actions.auth.setIsAuthenticated
  }));

  dispatch = useStoreDispatch();
  actions = store.getActions();

  useEffect(() => {
    async function init() {
      const r = await checkTokenValidation();

      if (r) {
        const userData = await getCall(API_USER_PROFILE_ROUTE);
        if (userData.data?.id) {
          setLoggedInUser(userData.data);
        }
      }

      setIsAuthenticated(r);
      if (!r) localStorage.removeItem('loginToken');
    }

    init();
  }, []);

  return (
    <>
      {indicatorVisible ? (
        <Portal>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              zIndex: 999,
            }}>
            <LinearProgress />
          </div>
        </Portal>
      ) : null}
    </>
  );
};

Indicator.propTypes = {};

const getCall = async (url, headers, jsonResponse) =>
  await apiCall.getCall(dispatch, actions, url, headers, jsonResponse);

const postCall = async (url, data, headers, jsonResponse) =>
  await apiCall.postCall(dispatch, actions, url, data, headers, jsonResponse);

const putCall = async (url, data, headers, jsonResponse) =>
  await apiCall.putCall(dispatch, actions, url, data, headers, jsonResponse);

const deleteCall = async (url, data, headers, jsonResponse) =>
  await apiCall.deleteCall(dispatch, actions, url, data, headers, jsonResponse);

const postFileCall = async (url, data, headers, jsonResponse, formData = true) =>
  await apiCall.postFileCall(dispatch, actions, url, data, headers, jsonResponse, formData);

const putFileCall = async (url, data, headers, jsonResponse, formData = true) =>
  await apiCall.putFileCall(dispatch, actions, url, data, headers, jsonResponse, formData);

export { Indicator, getCall, postCall, putCall, deleteCall, postFileCall, putFileCall };
