import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import BackButton from "../../components/buttons/back-button/BackButton";
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import DynamicForm from "../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import {serviceActionLaunch} from "./components/repairs/details/service-action-launch";
import {serviceActionReview, serviceActionReviewCd} from "./components/repairs/details/service-action-review";
import {serviceActionService} from "./components/repairs/details/service-action-service";
import routerHistory from "../../constants/settings/routerHistory";
import {getCall, postCall} from "../../components/indicator/Indicator";
import {API_REPAIRERS_ROUTE, API_REPAIRS_ROUTE} from "../../constants/routes";
import {formToRequest, responseToForm} from "../../components/dynamic-forms/field-transformer";
import Loader from "../../components/loader/Loader";
import {Bar} from "../../components/FloatingSaveBar";
import {toast} from "react-toastify";
import {LAUNCH, REVIEW, SERVICE} from "../../constants/RepairActionType";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ActionLaunchDeviceZone from "./components/repairs/details/ActionLaunchDevicesZone";
import ActionServiceDeviceZone from "./components/repairs/details/ActionServiceDevicesZone";
import ActionReviewDeviceZone from "./components/repairs/details/ActionReviewDevicesZone";

const RepairsActionTypeScreen = () => {
    const formRef = useRef();
    const formRef2 = useRef();
    const [loading, setLoading] = useState(true);
    const [repair, setRepair] = useState(() => {
    });
    const [loadLastAction, setLoadLastAction] = useState(false);
    const [dynamicFormFields, setDynamicFormFields] = useState(() => []);
    const [devices, setDevices] = useState(() => []);
    const [groups, setGroups] = useState({});
    const {id, type = ''} = useParams();
    const actualType = useMemo(() => type.toUpperCase(), [type]);
    const [repairsObject, setRepairsObject] = useState();
    
    useEffect(() => {
        const loadForm = async () => {
            await Promise.all([
                fetchData(),
                loadDynamicFormFields()
            ]);
            setLoading(false);
        };
        loadForm()
    }, [id]);

    const fetchData = async () => {
        try {
            const {data: {object = {}} = {}} = await getCall(`${API_REPAIRS_ROUTE}/${id}`);
            setRepair(() => object);
        } catch {
            toast.error('Wystąpił błąd podczas pobierania danych.');
        }
    };

    useEffect(() => {
        if(repair){
            let repairsData = repair.fields.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
            setRepairsObject(repairsData)
        }
    }, [repair])

    const loadDynamicFormFields = async () => {
        if (actualType === LAUNCH) {
            const options = {repairersOptions: await fetchRepairs() || []};
            setDynamicFormFields(() => serviceActionLaunch(options))
        } else if (actualType === SERVICE) {
            const options = {repairersOptions: await fetchRepairs() || []};
            setDynamicFormFields(() => serviceActionService(options))
        }
    };

    const fetchRepairs = async () => {
        try {
            const {data: {items = []} = {}} = await getCall(API_REPAIRERS_ROUTE);
            return items
        } catch {
            toast.error('Wystąpił błąd podczas pobierania serwisantów.');
        }
    };

    const lastAction = useMemo(() => {
        switch (actualType) {
            case LAUNCH: {
                return repair?.lastLaunchAction
            }
            case SERVICE: {
                return repair?.lastServiceAction
            }
            case REVIEW: {
                return repair?.lastReviewAction
            }
            default: {
                return null
            }
        }
    }, [repair, actualType]);

    const formInitValues = useMemo(() =>
            responseToForm(loadLastAction ? lastAction?.fields || [] : []),
        [lastAction, loadLastAction]);

    const devicesInitValues = useMemo(() =>
            ((loadLastAction ? lastAction?.devices : repair?.devices || [])).map(device => ({
                ...device,
                fields: responseToForm(device?.fields || [])
            })),
        [lastAction, loadLastAction, repair]);

    const lastActionExist = useMemo(() =>
            !!lastAction,
        [lastAction]);

    const DevicesZone = useMemo(() => {
        switch (actualType) {
            case LAUNCH: {
                return ActionLaunchDeviceZone
            }
            case SERVICE: {
                return ActionServiceDeviceZone
            }
            case REVIEW: {
                return ActionReviewDeviceZone
            }
            default: {
                return null
            }
        }
    }, [ActionLaunchDeviceZone, ActionServiceDeviceZone, ActionReviewDeviceZone, actualType]);

    const findFieldValue = (searchesName, fields) => fields.find(({name}) => name?.toLocaleLowerCase() === searchesName)?.value;

    const save = async () => {
        const fields = [...formToRequest(formRef?.current?.getFormValues() || []), ...formToRequest(formRef2?.current?.getFormValues() || []) ];
        const devicesBody = (devices || []).map(device => ({
            ...device,
            fields: Array.isArray(device?.fields) ? [...device.fields,
                {
                    name: 'szablon',
                    value: (device?.template?.fields?.[0]?.value || [])
                },
                {
                    name: 'szablon_name',
                    value: (device?.template?.name || [])
                },
                {
                    name: 'szablon_id',
                    value: (device?.template?.id || [])
                }
            ] : device?.fields,
            repairer_id: findFieldValue('serwisant', fields) || null,
            description: actualType === LAUNCH
                ? findFieldValue('notatka', fields)
                : device?.description
        }));

        try {
            await postCall(`${API_REPAIRS_ROUTE}/${id}/actions`,
                {
                    devices: devicesBody,
                    type,
                    fields,
                    groups: groups
                });
            toast.success('Zapisano akcję.');
            routerHistory.push('/service/repairs/edit/' + id)
        } catch {
            toast.error('Wystąpił błąd podczas zapisu.');
        }
    };

      useEffect(() => {
        if(!loading && repairsObject){
            // console.log('formularz',formRef.current.getFormValues())
            // console.log('otrzymany objekt', repairsObject)
            const {serwis_inwestor, serwis_city, serwis_kod, serwis_ul, serwis_last_contact, serwis_phone, serwis_mail, value_project: {name}} = repairsObject
            serwis_inwestor && formRef.current.setFormValue('Klient/inwestor', serwis_inwestor)
            serwis_city && formRef.current.setFormValue('Miasto', serwis_city)
            serwis_kod && formRef.current.setFormValue('Kod', serwis_kod)
            serwis_ul && formRef.current.setFormValue('Ulica', serwis_ul)
            serwis_last_contact && formRef.current.setFormValue('serwis_last_contact', serwis_last_contact)
            serwis_phone && formRef.current.setFormValue('serwis_phone', serwis_phone)
            serwis_mail && formRef.current.setFormValue('serwis_mail', serwis_mail)
            name && formRef.current.setFormValue('value_project', {name}) 
        }
    }, [repairsObject, loading])

    return (
        <>
            {loading
                ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}><Loader/></div>
                : <>
                    <ColumnWrapper>
                        <RowWrapper>
                            <BackButton
                                text="Wróć do karty serwisowej"
                                onClick={() => routerHistory.push('/service/repairs/edit/' + id)}
                            />
                        </RowWrapper>
                        <RowWrapper>
                            <ColumnWrapper>
                                <ExpansionContainer title="Protokół przeglądowy">
                                    {
                                        actualType === REVIEW
                                            ? <>
                                                <DynamicForm fields={serviceActionReview} ref={formRef} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                                <DevicesZone devices={devicesInitValues || []}
                                                             onUpdate={newDevices => setDevices(() => newDevices)}
                                                />
                                                <DynamicForm fields={serviceActionReviewCd} ref={formRef2} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                            </> :
                                            <>
                                                <DynamicForm fields={dynamicFormFields} ref={formRef} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                                <DevicesZone devices={devicesInitValues || []}
                                                             onUpdate={newDevices => setDevices(() => newDevices)}
                                                             setGroups={setGroups} groups={groups}
                                                />
                                            </>
                                    }
                                </ExpansionContainer>
                            </ColumnWrapper>
                        </RowWrapper>
                    </ColumnWrapper>
                    <Bar>
                        {lastActionExist
                        && <PrimaryButton
                            style={{marginRight: 15}}
                            onClick={
                                () => {
                                    setLoading(true);
                                    setLoadLastAction(isLoaded => !isLoaded);
                                    process.nextTick(() => setLoading(false))
                                }
                            }>
                            {loadLastAction ? 'Wyczyść formularz' : 'Wczytaj ostatnią akcję'}
                        </PrimaryButton>}
                        <PrimaryButton onClick={save}>
                            Zapisz
                        </PrimaryButton>
                    </Bar>
                </>
            }
        </>
    );
};

export default RepairsActionTypeScreen;
