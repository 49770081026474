import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useRef } from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../components/inputs/search-field/SearchField";
import MyCalendars from "../my-calendars/MyCalendars";
import { InputWrapper, Wrapper } from "../../../../components/ListToolBarComponents";
import Add from '@material-ui/icons/Add';
import { CalendarToday, CalendarViewDay } from '@material-ui/icons';

const CalendarToolBar = ({ onCalendarChange, onDrawerShow, style }) => {
  const ref = useRef();

  const show = () => {

    onDrawerShow(true);

    if(ref.current) {
      ref.current.show()
    }
  }

  return (
    <>
      <Wrapper style={style}>
        <PrimaryButton onClick={() => routerHistory.push('/calendar/add-slot')}>
          <Add />
        </PrimaryButton>
      </Wrapper>
      <MyCalendars onCalendarChange={onCalendarChange} ref={ref} onHide={() => onDrawerShow(false)} />
    </>
  );
};

export default CalendarToolBar;
