export default (rows) => [
    {header: 'Lp', key: 'index'},
    {header: 'Model', key: 'device'},
    {header: 'ID', key: 'identity'},
    {header: 'Numer seryjny', key: 'serial_no'},
    ...rows,
    {header: 'Części dodatkowe na gwarancji', key: 'parts_under_warranty'},
    {header: 'Części dodatkowe nie na gwarancji', key: 'parts_not_under_warranty'},
    {header: 'Notatka', key: 'description'},
];
