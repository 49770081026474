import { Paper, Table, TableCell, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ColumnWrapper from '../../wrappers/ColumnWrapper';

const StyledTable = styled(Table)`
  td,
  th {
    border: 2px solid #fff;
    border-top: none;
  }
  thead,
  tfoot {
    background-color: #e6e6e6;
  }
`;

const MultiTable = ({ tables }) => {
  const returnHeaders = (headers) => {
    return (
      <>
        {headers.map((el) => (
          <tr key={el.key}>
            {el?.rowItems ? (
              el?.rowItems?.map((row) => (
                <TableCell align="center" colSpan={row.colSpan} key={row.key}>
                  {row.label}
                </TableCell>
              ))
            ) : (
              <TableCell align="center" colSpan={el?.colSpan}>
                {el?.label}
              </TableCell>
            )}
          </tr>
        ))}
      </>
    );
  };

  const getCells = (row) =>
    Object.keys(row).map((key) =>
      key !== 'id' ? (
        <TableCell align="center" key={key}>
          {row[key] ? row[key] : '-'}
        </TableCell>
      ) : null,
    );

  return (
    <ColumnWrapper>
      {tables.map(({ id, content, headers, name }) => (
        <ColumnWrapper style={{ marginBottom: 50 }} key={id}>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            {name}
          </Typography>
          <Paper style={{ width: '100%' }}>
            <StyledTable>
              <thead>{returnHeaders(headers)}</thead>
              <tbody>
                {content.map((row, index) => {
                  return <tr key={index}>{getCells(row)}</tr>;
                })}
              </tbody>
            </StyledTable>
          </Paper>
        </ColumnWrapper>
      ))}
    </ColumnWrapper>
  );
};

MultiTable.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      headers: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          colSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
          rowItems: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
              colSpan: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
              ]),
              label: PropTypes.string,
            }),
          ),
        }),
      ),
      content: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    }),
  ),
};

export default MultiTable;
