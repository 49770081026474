import { Avatar, Box, Chip, Paper, Typography } from '@material-ui/core';
import { FileCopy, Mouse } from '@material-ui/icons';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import ExpansionContainer from '../../../../../components/containers/ExpansionContainer';
import PaperContainer from '../../../../../components/containers/PaperContainer';
import ColumnWrapper from '../../../../../components/wrappers/ColumnWrapper';
import MarketingChip from '../../../components/MarketingChip';


const TagsTimeline = ({tags, item}) => {

return (
    <>
        <ExpansionContainer title="Tagi">
            <ColumnWrapper fullWidth>
                <Box>
                    {tags.map(item => <MarketingChip tag={item} />)}
                </Box>
            </ColumnWrapper>
        </ExpansionContainer>
        <ExpansionContainer title="Aktywność">
            <ColumnWrapper fullWidth>

                    <Timeline align="left">
                        <TimelineItem>
                            <TimelineOppositeContent style={{flex: 0}} />
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <FileCopy />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                Zapis do formularza
                                <Typography variant="body2" color="textSecondary">
                                    {moment(item?.createdOn?.date).format('D.M.Y')}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>


            </ColumnWrapper>
        </ExpansionContainer>
        </>
    );
};

export default TagsTimeline;
