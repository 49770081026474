const templateFields = [
    {
        "id": 9,
        "name": "rows",
        "type": "rowsGroup",
        "value": "[]",
        "options": {
            "fields": [
                {
                    "id": 42,
                    "name": "szablon_field",
                    "type": "text",
                    "label": "Pola szablonu",
                    "value": null,
                },
            ]
        }
    }
];

export default templateFields;