import { Button } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import React, { useRef } from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import AddTaskPopup from "../../../../components/popups/AddTaskPopup";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import styled from "styled-components";
import {ButtonWrapper} from "../../../../components/DetailsToolBarComponents";

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
  position: sticky;
  padding-top: 10px;
  background: #fafafa;
  top: 0px;
  z-index: 10;
`;

const DetailsToolBar = ({ id, toggleDrawer }) => {
  const taskPopup = useRef();
  return (
    <>
      <AddTaskPopup ref={taskPopup} companyId={id} />
      <Wrapper>
        <BackButton
          text="Wróć do listy"
          onClick={() => routerHistory.push('/companies')}
        />
        <RowWrapper>
          <ButtonWrapper>
            <Button
              onClick={toggleDrawer}
              startIcon={<Message />}
              style={{ color: 'white' }}
              variant="contained"
              color="secondary">
              Notatki
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
