import React, { useEffect, useState } from 'react';
import ListToolBar from './components/list/ListToolBar';
import BasicTable from "../../../components/tables/basic/BasicTable";
import routerHistory from "../../../constants/settings/routerHistory";
import { MARKETING_CONTACT_ROUTE } from '../../../constants/routes';
import { getCall } from '../../../components/indicator/Indicator';
import moment from 'moment';
import MarketingChip from '../components/MarketingChip';

const MarketingContactsScreen = () => {

  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);

  const fetchMarketingActions = async (url = MARKETING_CONTACT_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        id: item?.id,
        name: item?.name,
        contactId: item?.contactId,
        email: item?.email,
        tags: item?.tags?.map((tag) => (<MarketingChip tag={tag} />)),
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  }; 

  useEffect(() => {
    fetchMarketingActions();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${MARKETING_CONTACT_ROUTE}?query=${v}`);
      const r = data.items?.map((item) => ({
        id: item?.id,
        name: item?.name,
        contactId: item?.contactId,
        email: item?.email,
        tags: item?.tags?.map((tag) => (<MarketingChip tag={tag} />)),
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleImport = async (url = MARKETING_CONTACT_ROUTE + '/update') => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        id: item?.id,
        name: item?.name,
        contactId: item?.contactId,
        email: item?.email,
        tags: item?.tags?.map((tag) => (<MarketingChip tag={tag} />)),
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  };

  
  return (
      <>
        <ListToolBar
          buttonText="Dodaj"
          handleImport={handleImport}
          handleSearch={handleSearch}
          onClick={() => routerHistory.push('/marketing/companies/add')}
        />
        <BasicTable 
          headCells={[{ id: 'name', numeric: false, disablePadding: false, label: 'Nazwa' },
            { id: 'contactId', numeric: false, disablePadding: false, label: 'Identyfikator' },
            { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
            { id: 'tags', numeric: false, disablePadding: false, label: 'Zainteresowania'},
            { id: 'createdOn', numeric: false, disablePadding: false, label: 'Dodano' }]} 
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchMarketingActions(`${MARKETING_CONTACT_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) =>
          routerHistory.push(`/marketing/contacts/edit/${id}`)
        } />
      </>
    );
  }

export default MarketingContactsScreen;
