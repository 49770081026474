import {
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';

const Select = ({
  disabled,
  data,
  onSelect,
  name,
  value,
  label,
  style = {},
  wrapperStyle = {},
  error,
  helperText,
  multiple = false,
  placeholder = 'Wybierz',
  readOnly,
}) => {
  const [selected, setSelected] = useState(() => multiple ? [] : '');

  useEffect(() => {
    setSelected(() => value);
  }, [value]);

  const handleSelect = (event) => {
    event.stopPropagation();
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  const displayedValue = useMemo(() => data?.find(option => option.id == selected)?.name || selected, [selected, data]);

  return (
    <ColumnWrapper fullWidth style={{...wrapperStyle}}>
      {label && <InputLabel style={{ margin: '10px 0' }} shrink htmlFor={name}>
        {label}
      </InputLabel>}
      <MaterialSelect
        multiple={multiple}
        error={error}
        readOnly={readOnly}
        displayEmpty
        name={name}
        label={label}
        placeholder={placeholder}
        renderValue={(selected) => {
          if (selected.length === 0 && placeholder) {
            return placeholder
          }

          return displayedValue;
        }}
        disabled={disabled || data?.length === 0}
        style={{ backgroundColor: '#f2f2f3', width: '100%', ...style }}
        variant="outlined"
        onChange={(event) => handleSelect(event)}
        value={selected || ''}>
        {data?.map((dataItem) => (
          <MenuItem key={dataItem.id} value={dataItem.id}>
            {dataItem.name}
          </MenuItem>
        ))}
      </MaterialSelect>
    </ColumnWrapper>
  );
};

Select.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ),
        PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          ),
        ),
      ]),
    ),
  ),
  onSelect: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  data: [],
  onSelect: () => {},
  value: '',
  name: '',
  disabled: false,
};

export default Select;
