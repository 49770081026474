import commonValues from '../../../../tools/commonValues';

const projectsDetailsForm = {

    parametry: [{
        "name": "Parametry",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "name": "Moc c.o.",
                    "type": "header",
                    "fullWidth": true,
                    value: '',
                },
                {
                    "id": 75,
                    "name": "co_moc_grzewcza",
                    "type": "text",
                    "label": "Moc grzewcza [kW]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 76,
                    "name": "co_pam_zas",
                    "type": "text",
                    "label": "Parametr zasilanie [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 77,
                    "name": "co_pam_pow",
                    "type": "text",
                    "label": "Parametr powrót [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 78,
                    "name": "co_moc_chlod",
                    "type": "text",
                    "label": "Moc chłodnicza [kW]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 79,
                    "name": "co_pam_zas_mc",
                    "type": "text",
                    "label": "Parametr zasilanie [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 80,
                    "name": "co_pam_pow_mc",
                    "type": "text",
                    "label": "Parametr powrót [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "name": "Moc c.t.",
                    "type": "header",
                    "options": {
                        "width": 6
                    },
                    value: '',
                },
                {
                    "name": "Moc c.w.u.",
                    "type": "header",
                    "options": {
                        "width": 6
                    },
                    value: '',
                },
                {
                    "id": 81,
                    "name": "ct_moc",
                    "type": "text",
                    "label": "Moc [kW]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 82,
                    "name": "ct_pam_zas",
                    "type": "text",
                    "label": "Parametr zasilanie [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 83,
                    "name": "ct_pam_pow",
                    "type": "text",
                    "label": "Parametr powrót [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 84,
                    "name": "cwu_moc",
                    "type": "text",
                    "label": "Moc [kW]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 85,
                    "name": "cwu_pam",
                    "type": "text",
                    "label": "Parametr [oC]",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                },
                {
                    "id": 86,
                    "name": "cwu_zdob",
                    "type": "text",
                    "label": "Zużycie dobowe",
                    "value": null,
                    "options": {
                        "width": 2
                    }
                }
            ]
        }
    }],
    dostawa: [{
        "id": 23,
        "name": "Dostawa",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 99,
                    "name": "dost_osoby",
                    "type": "rowsGroup",
                    "label": "Osoby do kontaktu",
                    "value": "[]",
                    "options": {
                        "id": 25,
                        "data": [],
                        "fields": [
                            {
                                "id": 100,
                                "name": "dost_osoby_osoba",
                                "type": "text",
                                "label": "Osoba do kontaktu",
                                "value": null,
                                "options": null
                            }
                        ],
                        "name": "Tabela dostawców"
                    }
                },
                {
                    "id": 101,
                    "name": "dost_adres",
                    "type": "text",
                    "label": "Adres dostawy",
                    "value": null,
                    "options": null
                },
                {
                    "id": 102,
                    "name": "dost_uwagi",
                    "type": "text",
                    "label": "Uwagi",
                    "value": null,
                    "options": null
                }
            ]
        }
    }],
    ogolne: [{
        "id": 6,
        "name": "Informacje ogólne",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 21,
                    "name": "wojewodztwo_proj",
                    "type": "dictionary",
                    "label": "Województwo",
                    "value": null,
                    "options": {
                        "id": 1,
                        "data": commonValues.voivodeships,
                        "fields": [],
                        "name": "województwa",
                        "width": 3
                    }
                },
                {
                    "id": 22,
                    "name": "kod_pocztowy",
                    "type": "text",
                    "label": "Kod pocztowy",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 23,
                    "name": "city_proj",
                    "type": "text",
                    "label": "Miejscowość",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 24,
                    "name": "adres_proj",
                    "type": "text",
                    "label": "Adres",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 25,
                    "name": "audytor",
                    "type": "company",
                    "label": "Audytor",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 24,
                    "name": "architekt",
                    "type": "company",
                    "label": "Architekt",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 25,
                    "name": "projektant",
                    "type": "company",
                    "label": "Projektant",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 26,
                    "name": "dystrybutor",
                    "type": "company",
                    "label": "Dystrybutor",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 27,
                    "name": "zamawiajacy",
                    "type": "company",
                    "label": "Zamawiający",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 28,
                    "name": "wykonawca",
                    "type": "company",
                    "label": "Wykonawca",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 29,
                    "name": "gwykonawca",
                    "type": "company",
                    "label": "Generalny Wykonawca",
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 30,
                    "name": "inwestor",
                    "type": "company",
                    "label": "Inwestor",
                    "options": {
                        "width": 3
                    }
                },
            ]
        }
    }],
    marketing: [{
        "id": 30,
        "name": "Marketing",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 35,
                    "name": "Zdjęcia referencyjne",
                    "label": "Zdjęcia referencyjne",
                    "type": "checkbox",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Zdjęcia referencyjne - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Zdjęcia referencyjne - notatka",
                    "label": "Notatka",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 35,
                    "name": "Wywiad referencyjny",
                    "label": "Wywiad referencyjny",
                    "type": "checkbox",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Wywiad referencyjny - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Wywiad referencyjny - notatka",
                    "label": "Notatka",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 35,
                    "name": "Materiały marketingowe z inwestycją",
                    "label": "Materiały marketingowe z inwestycją",
                    "type": "checkbox",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Materiały marketingowe z inwestycją - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Materiały marketingowe z inwestycją - notatka",
                    "label": "Notatka",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 35,
                    "name": "wykorzystanie_na_potrzeby",
                    "label": "zgoda na wykorzystanie firmy/obiektu jako referencji na potrzeby marketingowe",
                    "type": "checkbox",
                    "fullWidth": true,
                    "value": null,
                    "options": null
                }
            ]
        }
    }],
    plany: [{
        "id": 8,
        "name": "Plany",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 25,
                    "name": "plans_proj",
                    "type": "rowsGroup",
                    "label": "Plany",
                    "value": "[]",
                    "options": {
                        "id": 7,
                        "data": [],
                        "fields": [
                            {
                                "id": 26,
                                "name": "rok_plan_proj",
                                "type": "text",
                                "label": "Rok",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 27,
                                "name": "kwart_plan_proj",
                                "type": "text",
                                "label": "Kwartał",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 28,
                                "name": "mc_plan_proj",
                                "type": "text",
                                "label": "Miesiąc",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 29,
                                "name": "prob_plan_proj",
                                "type": "number",
                                "label": "Prawdopodobieństwo [%]",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "max_rabat_plan_proj",
                                "type": "number",
                                "label": "Max rabat urządzenia [%]",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 31,
                                "name": "max_rabat_a_plan_proj",
                                "type": "number",
                                "label": "Max rabat automatyka [%]",
                                "value": null,
                                "options": null
                            }
                        ],
                        "name": "Plany"
                    }
                }
            ]
        }
    }],
    platnosci: [{
        "id": 8,
        "name": "Płatności i transport",
        "type": "multipleFields",
        "options": {
            width: 12,
            "fields": [
                {
                    "id": 25,
                    "name": "plans_proj",
                    "type": "projectTransportRowsGroup",
                    "label": "Płatności i transport",
                    "value": "[]",
                    "options": {
                        width: 12,
                        "id": 7,
                        "data": [],
                        "fields": [
                            {
                                "id": 31,
                                "name": "pt_nazwapliku",
                                "type": "text",
                                "label": "nr faktury proforma",
                                "value": null,
                                "options": {
                                    attrs: {
                                        readOnly: true,
                                        translate: true,
                                    }
                                },
                            },
                            {
                                "id": 26,
                                "name": "pt_nrfaktury",
                                "type": "attachment",
                                "label": "",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 27,
                                "name": "pt_odbiorca",
                                "type": "text",
                                "label": "odbiorca faktury",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 28,
                                "name": "pt_przedmiot",
                                "type": "dictionary",
                                "label": "przedmiot faktury",
                                "value": null,
                                "options": {
                                    "data": [
                                        {"id": '', "name": ''},
                                        {"id": 'urządzenia', "name": 'urządzenia'},
                                        {"id": 'peryferia', "name": 'peryferia'},
                                        {"id": 'automatyka', "name": 'automatyka'},
                                    ]
                                }
                            },
                            {
                                "id": 29,
                                "name": "pt_oferta",
                                "type": "entity",
                                "label": "na podstawie oferty",
                                "value": null,
                                "options": {
                                    "entityClass": "offer"
                                }
                            },
                            {
                                "id": 30,
                                "name": "pt_cena_katalogowa_2",
                                "type": "text",
                                "label": "cena katalogowa 2 lata [netto]",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 31,
                                "name": "pt_cena_katalogowa_2_waluta",
                                "type": "dictionary",
                                "label": "waluta",
                                "value": null,
                                "options": {
                                    "data": [
                                        {"id": "PLN", "name": "PLN"},
                                        {"id": "EUR", "name": "EUR"},
                                    ]
                                }
                            },
                            {
                                "id": 30,
                                "name": "pt_gwarancja",
                                "type": "dictionary",
                                "label": "gwarancja",
                                "value": null,
                                "options": {
                                    "data": []
                                }
                            },
                            {
                                "id": 30,
                                "name": "pt_mnoznik",
                                "type": "text",
                                "label": "mnożnik gwarancyjny",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_katalog_dla_gwarancji",
                                "type": "text",
                                "label": "cena katalogowa dla danej gwarancji [netto]",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_brutto",
                                "type": "text",
                                "label": "cała kwota brutto",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_flowair",
                                "type": "text",
                                "label": "cena dla Flowair",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_warunki",
                                "type": "text",
                                "label": "warunki płatności",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_1",
                                "type": "text",
                                "label": "kwota do zapłacenia I transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_1_date",
                                "type": "dateonly",
                                "label": "powinni zapłacić I transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_1_real",
                                "type": "dateonly",
                                "label": "zapłacili I transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_2",
                                "type": "text",
                                "label": "kwota do zapłacenia II transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_2_date",
                                "type": "dateonly",
                                "label": "powinni zapłacić II transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_kwota_2_real",
                                "type": "dateonly",
                                "label": "zapłacili II transza",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_left",
                                "type": "text",
                                "label": "pozostało do zapłacenia",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_wysylka",
                                "type": "dateonly",
                                "label": "data wysyłki",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_dostarczenie",
                                "type": "dateonly",
                                "label": "data dostarczenia",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 30,
                                "name": "pt_transport",
                                "type": "dictionary",
                                "label": "transport",
                                "value": null,
                                "options": {
                                    "data": [
                                        {"id": "Do zorganizowania", "name": "Do zorganizowania"},
                                        {"id": "Zorganizowany", "name": "Zorganizowany"},
                                        {"id": "Dostarczony", "name": "Dostarczony"},
                                    ]
                                }
                            },
                        ],
                        "name": "Płatnosci i transport"
                    }
                }
            ]
        }
    }],
    prowizja: [{
        "id": 28,
        "name": "Prowizja",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 29,
                    "name": "provision_amount",
                    "type": "text",
                    "label": "Wartość do prowizji netto",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 29,
                    "name": "provision_exchange",
                    "type": "text",
                    "label": "Kurs z faktury proforma",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 29,
                    "name": "provision_exchangedate",
                    "type": "dateonly",
                    "label": "Data kursu",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 34,
                    "name": "provision_people",
                    "type": "rowsGroup",
                    "label": "Tabela prowizji",
                    "value": "[]",
                    "options": {
                        "id": 12,
                        "data": [],
                        "fields": [
                            {
                                "id": 35,
                                "name": "provision_people_type",
                                "type": "dictionary",
                                "label": "Rodzaj prowizji",
                                "value": null,
                                "options": {data: [
                                    {id: 'audytowa', name: 'audytowa'},
                                    {id: 'projektowa', name: 'projektowa'},
                                    {id: 'inna', name: 'inna'},
                                ]}
                            },
                            {
                                "id": 36,
                                "name": "provision_people_company",
                                "type": "entity",
                                "label": "Firma",
                                "value": null,
                                "options": {
                                    "entityClass": "company",
                                    "freeSolo": true,
                                    "attrs": {
                                    }
                                }
                            },
                            {
                                "id": 36,
                                "name": "provision_people_user",
                                "type": "user",
                                "label": "Osoba",
                                "value": null,
                                "options": {
                                    dependency: ['provision_people', 'provision_people_company', 'id'],
                                }
                            },
                            {
                                "id": 38,
                                "name": "provision_percentage",
                                "type": "text",
                                "label": "Wysokość prowizji %",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 39,
                                "name": "provision_b2btype",
                                "type": "dictionary",
                                "label": "Rodzaj działalności",
                                "value": null,
                                "options": {"data": [
                                    {'id': 'VAT', 'name': 'VAT'},
                                    {'id': 'zw. VAT', 'name': 'bez VAT'},
                                ]}
                            },
                            {
                                "id": 40,
                                "name": "provision_netto",
                                "type": "text",
                                "label": "Wysokość prowizji netto",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "provision_brutto",
                                "type": "text",
                                "label": "Wysokość prowizji brutto",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "provision_person_amount",
                                "type": "text",
                                "label": "Kwota do przelewu",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "provision_generate",
                                "label": "Notatka",
                                "type": "button",
                                "value": null,
                                "options": {
                                    "text": "Generuj notatkę",
                                    "attrs": {
                                        "color": "secondary"
                                    }
                                }
                            },
                            {
                                "id": 40,
                                "name": "provision_sent_date",
                                "type": "dateonly",
                                "label": "Data wysłania do klienta",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "provision_invoice_date",
                                "type": "dateonly",
                                "label": "Data otrzymania faktury",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "provision_bank_date",
                                "type": "dateonly",
                                "label": "Data wprowadzenia do banku",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 29,
                                "name": "provision_calculation",
                                "type": "checkbox",
                                "label": "Wylicz prowizję automatycznie",
                                "value": true,
                                "options": {
                                    "width": 4
                                }
                            },
                            {
                                "id": 40,
                                "name": "provision_finalised",
                                "type": "checkbox",
                                "label": "Rozliczona",
                                "value": null,
                                "options": null
                            },
                        ],
                        "name": "Prowizje - tabela"
                    }
                }
            ]
        }
    }],
    przetargi: [{
        "id": 11,
        "name": "Przetargi",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 34,
                    "name": "project_przetargi",
                    "type": "rowsGroup",
                    "label": "Tabela przetargów",
                    "value": "[]",
                    "options": {
                        "id": 12,
                        "data": [],
                        "fields": [
                            {
                                "id": 35,
                                "name": "przetargi_link",
                                "type": "text",
                                "label": "Link do przetargu",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 36,
                                "name": "przetargi_data",
                                "type": "dateonly",
                                "label": "Data ogłoszenia",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 37,
                                "name": "przetargi_end",
                                "type": "dateonly",
                                "label": "Data zakończenia",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 38,
                                "name": "przetargi_endinvest",
                                "type": "dateonly",
                                "label": "Data zakończenia inwestycji",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 39,
                                "name": "przetargi_doku",
                                "type": "attachment",
                                "label": "Dokumentacja przetargowa",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 40,
                                "name": "przetargi_firma",
                                "type": "text",
                                "label": "Firma wygrywająca przetarg",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 41,
                                "name": "przetargi_status",
                                "type": "dictionary",
                                "label": "Status przetargu",
                                "value": null,
                                "options": {
                                    "id": 3,
                                    "data": [
                                        {
                                            "id": 13,
                                            "name": "ogłoszony"
                                        },
                                        {
                                            "id": 14,
                                            "name": "anulowany"
                                        },
                                        {
                                            "id": 15,
                                            "name": "rozstrzygnięty"
                                        }
                                    ],
                                    "fields": [],
                                    "name": "Status przetargu"
                                }
                            }
                        ],
                        "name": "Przetargi - tabela"
                    }
                }
            ]
        }
    }],
    wazne: [{
        "id": 31,
        "name": "Ważne",
        "type": "multipleFields",
        "options": {
            "fields": []
        }
    }],
    pliki: [{
        "id": 31,
        "name": "Pliki",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 106,
                    "name": "proj_files",
                    "type": "rowsGroup",
                    "value": "[]",
                    "options": {
                        "id": 105,
                        "data": [],
                        "fields": [
                            {
                                "id": 107,
                                "name": "proj_file_desc",
                                "type": "text",
                                "label": "Opis",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 108,
                                "name": "proj_file",
                                "type": "attachment",
                                "label": "Plik",
                                "value": null,
                                "options": null
                            }
                        ],
                        "name": "Pliki"
                    }
                }
            ]
        }
    }],
    zamowienie: [{
        "id": 22,
        "name": "Zamówienie",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 88,
                    "name": "zam_z_plan",
                    "type": "checkbox",
                    "label": "Zamówienie z planu",
                    "value": null,
                    "options": {
                        "width": 12,
                    }
                },
                {
                    "id": 90,
                    "name": "zam_zams",
                    "type": "projectOrderRowsGroup",
                    "label": "Zamówienia",
                    "value": "[]",
                    "options": {
                        "width": 12,
                        "id": 24,
                        "data": [],
                        "fields": [
                            {
                                "id": 92,
                                "name": "zam_zams_gwarancja",
                                "type": "text",
                                "label": "Gwarancja",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 93,
                                "name": "zam_zams_urzadzenia",
                                "type": "text",
                                "label": "Urządzenia",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 94,
                                "name": "zam_zams_schema",
                                "type": "attachment",
                                "label": "Schemat / Projekt",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 95,
                                "name": "zam_zams_zam",
                                "type": "attachment",
                                "label": "Zamówienie",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 96,
                                "name": "zam_zams_rodzaj",
                                "type": "text",
                                "label": "Rodzaj gazu",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 97,
                                "name": "zam_zams_zam_producent",
                                "type": "attachment",
                                "label": "Zamówienie u producenta",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 98,
                                "name": "zam_zams_zestawienie",
                                "type": "attachment",
                                "label": "Zestawienie elementów",
                                "value": null,
                                "options": null
                            }
                        ],
                        "name": "Tabela zamówień"
                    }
                }
            ]
        }
    }],
    wymienniki: [{
        "id": 880,
        "name": "Wymienniki",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 881,
                    "name": "wym_data",
                    "type": "dateonly",
                    "label": "Data wysłania zapytania",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 882,
                    "name": "wym_aktual",
                    "type": "attachment",
                    "label": "Aktualna oferta",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 883,
                    "name": "wym_zal",
                    "type": "attachment",
                    "label": "Otrzymane pliki",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 881,
                    "name": "wym_proj_zakup",
                    "type": "checkbox",
                    "label": "Zakupiono",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
            ]
        }
    }]
}


export { projectsDetailsForm };
