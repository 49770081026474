import returnSpecificField from "../../../../components/dynamic-forms/returnSpecificField";
import React, {useEffect, useMemo, useState} from "react";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import {DialogActions, Divider} from "@material-ui/core";
import {getCall} from "../../../../components/indicator/Indicator";
import {API_TEMPLATES_ROUTE} from "../../../../constants/routes";
import Loader from "../../../../components/loader/Loader";
import {responseToForm} from "../../../../components/dynamic-forms/field-transformer";

export default ({open, products = [], onClose, onSubmit}) => {

    const [loading, setLoading] = useState(true);
    const [template, setTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        setTemplate(() => null);
        setSelectedProducts(() => []);
    }, [open]);

    useEffect(() => {
        getCall(API_TEMPLATES_ROUTE).then(({data}) => setTemplates(data?.items || [])).then(() => setLoading(false));
    }, []);

    const selectedTemplate = useMemo(() => {
        if(template) {
            const currentTemplate = templates.find(({id}) => id === template);

            return currentTemplate
        }
        return null
    }, [template, templates]);

    return <>
        <Dialog open={open} onClose={onClose}>

            {loading
                ? <div><Loader/></div>
                : <>
                    <DialogContent>
                        <Container>
                            <h2 id="calc-launch-price-modal-title">Wstaw szablon</h2>
                            <RowWrapper style={{flexWrap: 'wrap'}}>
                                {returnSpecificField(
                                    'dictionary',
                                    {data: templates},
                                    'template',
                                    template,
                                    (e, v) => {
                                        setTemplate(v)
                                    },
                                    'Nazwa szablonu'
                                )}
                                {returnSpecificField(
                                    'dictionary_multiple',
                                    {
                                        data: products.filter(device => device.hasOwnProperty('template') ? Boolean(template) : true).map(product => ({
                                                ...product,
                                                name: `${product?.identity} - ${product?.serial_no} - ${product?.product?.name}`
                                            })
                                        )
                                    },
                                    'product',
                                    selectedProducts,
                                    (e, v) => {
                                        setSelectedProducts(v)
                                    },
                                    'Urządzenia'
                                )}
                            </RowWrapper>
                        </Container>
                    </DialogContent>
                    <Divider/>
                    <DialogActions style={{padding: 20}}>
                        <PrimaryButton variant="raised" onClick={onClose} color="secondary">
                            Anuluj
                        </PrimaryButton>
                        <PrimaryButton disabled={!template || !selectedProducts?.length}
                                       onClick={() => {
                                           onClose();
                                           onSubmit({
                                               products: selectedProducts,
                                               template: selectedTemplate
                                           });
                                       }}>
                            Zapisz
                        </PrimaryButton>
                    </DialogActions>
                </>}
        </Dialog>
    </>
}