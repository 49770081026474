import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Indicator } from "./components/indicator/Indicator";
import SideMenu from "./components/navigation/SideMenu";
import ProtectedRouteWrapper from "./components/wrappers/ProtectedRouteWrapper";
import Administration from "./screens/administration/components/Administration";
import CalendarScreen from './screens/calendar/CalendarScreen';
import SlotScreen from './screens/calendar/SlotScreen';
import CompaniesDetailsScreen from './screens/companies/CompaniesDetailsScreen';
import CompaniesScreen from './screens/companies/CompaniesScreen';
import MapScreen from './screens/companies/MapScreen';
import DirectoriesScreen from './screens/documents/DirectoriesScreen';
import LoginScreen from './screens/login/LoginScreen';
import MainScreen from "./screens/main/MainScreen";
import ImportContactListScreen from './screens/marketing/emails/ImportContactsScreen';
import ImportMailListScreen from './screens/marketing/emails/ImportMailListScreen';
import MarketingActionDetailsScreen from './screens/marketing/emails/MarketingActionDetailsScreen';
import MarketingScreen from './screens/marketing/emails/MarketingScreen';
import OffersFormsScreen from './screens/offers/OffersFormsScreen';
import OffersScreen from './screens/offers/OffersScreen';
import ProductProducerScreen from './screens/products/ProductProducerScreen';
import ProductsCategoryScreen from './screens/products/ProductsCategoryScreen';
import ProductsScreen from './screens/products/ProductsScreen';
import ProjectDetailsScreen from "./screens/projects/details/ProjectDetailsScreen";
import ProjectDevicesScreen from "./screens/projects/details/ProjectDevicesScreen";
import ProjectVariantDetailScreen from "./screens/projects/details/ProjectVariantDetailScreen";
import ProjectsScreen from './screens/projects/ProjectsScreen';
import ReportsScreen from './screens/reports/ReportsScreen';
import ReportTableScreen from './screens/reports/ReportTableScreen';
import RepairersDetailsScreen from './screens/service/RepairersDetailsScreen';
import RepairersScreen from './screens/service/RepairersScreen';
import RepairsDetailsScreen from './screens/service/RepairsDetailsScreen';
import RepairsScreen from './screens/service/RepairsScreen';
import TaskDetailsScreen from './screens/tasks/TaskDetailsScreen';
import TasksScreen from './screens/tasks/TasksScreen';
import history from "./constants/settings/routerHistory";
import theme from './constants/settings/theme';
import HeatExchangerDetailsScreen from "./screens/projects/details/heat-exchangers/HeatExchangerDetailsScreen";
import HeatExchangerNewScreen from "./screens/projects/details/heat-exchangers/HeatExchangerNewScreen";
import RepairsActionTypeScreen from './screens/service/RepairsActionTypeScreen';
import { StoreProvider } from 'easy-peasy';
import store from './store/store';
import RepairsActionPreviewScreen from "./screens/service/RepairsActionPreviewScreen";
import RepairsActionHistoryScreen from "./screens/service/RepairsActionHistoryScreen";
import RepairsActionDeviceHistoryScreen from "./screens/service/RepairsActionDeviceHistoryScreen";
import ProjectAutomationScreen from './screens/projects/details/ProjectAutomationScreen';
import MarketingTagsScreen from './screens/marketing/tags/MarketingTagsScreen';
import MarketingContactsScreen from './screens/marketing/contacts/MarketingContactsScreen';
import MarketingContactInfoScreen from './screens/marketing/contacts/MarketingContactInfoScreen';
import TemplatesReviewDetailsScreen from "./screens/service/TemplatesReviewDetailsScreen";
import TemplatesScreen from "./screens/service/TemplatesScreen";
import ProjectsAutomationScreen from "./screens/projects/ProjectsAutomationScreen";
import ProductsTreeScreen from "./screens/products/ProductsTreeScreen";
import TemplatesReviews from './screens/service/TemplatesReviews';
import TemplatesLaunchDetailsScreen from './screens/service/TemplatesLaunchDetailsScreen';
import TemplatesReviewCostsDetailsScreen from './screens/service/TemplatesReviewCostsDetailsScreen';

const App = () => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <StoreProvider store={store}>
            <ToastContainer 
              position="bottom-left"
              autoClose={4000}
              hideProgressBar
              newestOnTop
            />
            <Indicator />
            <Router history={history}>
              <Switch>
                <SideMenu>
                  <ProtectedRouteWrapper>
                    <Route exact path="/projects">
                      <ProjectsScreen />
                    </Route>
                    <Route exact path="/projects-automation">
                      <ProjectsAutomationScreen />
                    </Route>
                    <Route exact path="/projects/edit/:id">
                      <ProjectDetailsScreen />
                    </Route>
                    <Route exact path="/projects/new">
                      <ProjectDetailsScreen />
                    </Route>
                    <Route path="/projects/:id/devices">
                      <ProjectDevicesScreen />
                    </Route>
                    <Route path="/projects/:id/automation">
                      <ProjectAutomationScreen />
                    </Route>
                    <Route path="/projects/:id/variant/:vid/:type">
                      <ProjectVariantDetailScreen />
                    </Route>
                    <Route
                      exact
                      path="/projects/:projectId/heat-exchangers/edit/:id">
                      <HeatExchangerDetailsScreen />
                    </Route>
                    <Route
                      exact
                      path="/projects/:projectId/heat-exchangers/new">
                      <HeatExchangerNewScreen />
                    </Route>
                    <Route exact path="/companies">
                      <CompaniesScreen />
                    </Route>
                    <Route exact path="/map">
                      <MapScreen />
                    </Route>
                    <Route path="/companies/new">
                      <CompaniesDetailsScreen />
                    </Route>
                    <Route path="/companies/edit/:id">
                      <CompaniesDetailsScreen />
                    </Route>
                    <Route exact path="/tasks">
                      <TasksScreen />
                    </Route>
                    <Route exact path="/tasks/new">
                      <TaskDetailsScreen />
                    </Route>
                    <Route exact path="/tasks/edit/:id">
                      <TaskDetailsScreen />
                    </Route>
                    <Route exact path="/offers">
                      <OffersScreen />
                    </Route>
                    <Route exact path="/offers/new">
                      <OffersFormsScreen />
                    </Route>
                    <Route exact path="/offers/edit/:id">
                      <OffersFormsScreen />
                    </Route>
                    <Route exact path="/producers">
                      <ProductProducerScreen />
                    </Route>
                    <Route exact path="/categories">
                      <ProductsCategoryScreen />
                    </Route>
                    <Route path="/products">
                      <ProductsScreen />
                    </Route>
                    <Route path="/products-tree">
                      <ProductsTreeScreen />
                    </Route>
                    {/* <Route path="/documents">
                      <DocumentsScreen />
                    </Route> */}
                    <Route exact path="/calendar">
                      <CalendarScreen />
                    </Route>
                    <Route exact path="/calendar/add-slot">
                      <SlotScreen />
                    </Route>
                    <Route exact path="/calendar/edit-slot/:eventId">
                      <SlotScreen />
                    </Route>
                    <Route exact path="/marketing/email-list">
                      <MarketingScreen />
                    </Route>
                    <Route exact path="/marketing/email-list/new">
                      <MarketingActionDetailsScreen />
                    </Route>
                    <Route exact path="/marketing/email-list/edit/:id">
                      <MarketingActionDetailsScreen />
                    </Route>
                    <Route exact path="/marketing/email-list/import">
                      <ImportMailListScreen />
                    </Route>
                    <Route exact path="/marketing/email-list/import/list">
                      <ImportContactListScreen />
                    </Route>
                    <Route exact path="/marketing/contacts">
                      <MarketingContactsScreen />
                    </Route>
                    <Route exact path="/marketing/contacts/add">
                      <MarketingContactInfoScreen />
                    </Route>
                    <Route exact path="/marketing/contacts/edit/:id">
                      <MarketingContactInfoScreen />
                    </Route>
                    <Route exact path="/marketing/tags">
                      <MarketingTagsScreen />
                    </Route>
                    <Route exact path="/reports">
                      <ReportsScreen />
                    </Route>
                    <Route exact path="/reports/table">
                      <ReportTableScreen />
                    </Route>
                    <Route exact path="/service/templates">
                      <TemplatesScreen />
                    </Route>
                    <Route exact path="/service/templates/reviews">
                      <TemplatesReviews />
                    </Route>
                    <Route exact path="/service/templates/reviews/edit/:id">
                      <TemplatesReviewDetailsScreen />
                    </Route>
                    <Route exact path="/service/templates/reviews/new">
                      <TemplatesReviewDetailsScreen />
                    </Route>
                    <Route exact path="/service/templates/launch">
                      <TemplatesLaunchDetailsScreen />
                    </Route>
                    <Route exact path="/service/templates/reviewCost">
                      <TemplatesReviewCostsDetailsScreen />
                    </Route>
                    <Route exact path="/service/repairers">
                      <RepairersScreen />
                    </Route>
                    <Route exact path="/service/repairers/edit/:id">
                      <RepairersDetailsScreen />
                    </Route>
                    <Route exact path="/service/repairers/new">
                      <RepairersDetailsScreen />
                    </Route>
                    <Route exact path="/service/repairs">
                      <RepairsScreen />
                    </Route>
                    <Route exact path="/service/repairs/edit/:id">
                      <RepairsDetailsScreen />
                    </Route>
                    <Route exact path="/service/repairs/:id/history">
                      <RepairsActionHistoryScreen />
                    </Route>
                    <Route exact path="/service/repairs/:id/devices/:deviceId/history">
                      <RepairsActionDeviceHistoryScreen />
                    </Route>
                    <Route exact path="/service/repairs/:id/actions/:type">
                      <RepairsActionTypeScreen />
                    </Route>
                    <Route exact path="/service/repairs-actions/:id/preview">
                      <RepairsActionPreviewScreen />
                    </Route>
                    <Route exact path="/service/repairs/new">
                      <RepairsDetailsScreen />
                    </Route>
                    <Route exact path="/dir">
                      <DirectoriesScreen />
                    </Route>
                    <Route exact path="/dir/:id">
                      <DirectoriesScreen />
                    </Route>
                    <Administration />
                    <Route exact path="/main">
                      <MainScreen />
                    </Route>
                  </ProtectedRouteWrapper>
                  <Route exact path="/">
                    <LoginScreen />
                  </Route>
                </SideMenu>
              </Switch>
            </Router>
          </StoreProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default App;
