import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { getCall } from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import AddEditPopup from './components/dictionaries/desc-fields/AddEditPopup';
import ListToolBar from './components/dictionaries/list/ListToolBar';
import descFieldsCells from '../../constants/headCells/descFieldsCells';
import { API_DESC_FIELDS_ROUTE } from '../../constants/routes';
import FIELD_TYPES from '../../constants/types/json/fieldTypes.json';

const DynamicFieldsScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const [fieldName, setFieldName] = useState('');

  const popupRef = useRef();

  const getHeadCellsPattern = (item) => ({
    id: item.id,
    name: item?.name,
    required: item?.required ? 'tak' : 'nie',
    type: FIELD_TYPES[item?.type]?.label,
    allowOther: item?.allowOther ? 'tak' : 'nie',
  });

  const fetchFields = async (
    url = API_DESC_FIELDS_ROUTE,
    needToSetFieldId = false,
  ) => {
    const { data } = await getCall(url);
    if (data?.items) {
      setCount(data?.total);
      const r = data?.items.map((item) => getHeadCellsPattern(item));
      setRows({ initialState: r, state: r });
      if (needToSetFieldId) {
        setFieldId(r[0]?.id);
        setFieldName(r[0]?.name);
      }
    }
  };

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${API_DESC_FIELDS_ROUTE}?query=${v}`);
      const r = data?.items.map((item) => getHeadCellsPattern(item));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  useEffect(() => {
    fetchFields();
    // eslint-disable-next-line
  }, []);

  const handleAddField = () => {
    setFieldId(null);
    popupRef.current.open();
  };

  const handleEditField = (id, name) => {
    setFieldId(id);
    setFieldName(name);
    popupRef.current.open();
  };

  return (
    <>
      <ListToolBar handleSearch={handleSearch} onAdd={handleAddField} />
      <Alert style={{ marginBottom: 10 }} severity="warning">
        Edycja pól może wywołać niepożądane skutki i naruszyć integralność
        danych w systemie.
      </Alert>
      <BasicTable
        headCells={descFieldsCells}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchFields(`${API_DESC_FIELDS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id, name }) => handleEditField(id, name)}
      />
      <AddEditPopup
        fieldName={fieldName}
        fieldId={fieldId}
        ref={popupRef}
        onSave={() => fetchFields(undefined, true)}
      />
    </>
  );
};

export default DynamicFieldsScreen;
