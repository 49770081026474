import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_COMPANIES_ROUTE,
  API_PROJECTS_ROUTE,
  API_TASKS_ROUTE
} from '../../constants/routes';
import PrimaryButton from '../buttons/PrimaryButton';
import Select from '../dynamic-forms/form-components/Select';
import { getCall, postCall } from '../indicator/Indicator';
import ColumnWrapper from '../wrappers/ColumnWrapper';

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddTaskPopup = forwardRef((props, ref) => {
  const { companyId, projectId } = props;
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [project, setProject] = useState(projectId || null);
  const [company, setCompany] = useState(companyId || null);
  const [companies, setCompanies] = useState([]);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = async () => {
    try {
      const r = await postCall(API_TASKS_ROUTE, {
        type: type,
        project_id: project,
        description,
        company_id: company,
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla zadania.`);
        setOpen(false);
      }
    } catch (error) {
      toast.error('Wystąpił problem podczas zapisywania zadania.');
    }
  };

  useEffect(() => {
    const fetchProjectsAndCompanies = async () => {
      const c = await getCall(API_COMPANIES_ROUTE);
      if (c.data) {
        const d = c.data.items?.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setCompanies(d);
      }
      const p = await getCall(API_PROJECTS_ROUTE);
      if (p.data) {
        const d = p.data.items?.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setProjects(d);
      }
    };
    fetchProjectsAndCompanies();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <ColumnWrapper fullWidth>
            <TextField
              label="Typ"
              variant="outlined"
              value={type}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) => setType(value)}
            />
            <TextField
              multiline
              rows={4}
              label="Opis"
              variant="outlined"
              value={description}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) => setDescription(value)}
            />
            <Select
              label="Powiązana firma"
              name="company"
              data={companies}
              onSelect={(c) => setCompany(c)}
              value={company}
            />
            <div style={{ height: 20, width: 1 }}></div>
            <Select
              label="Powiązany projekt"
              name="project"
              data={projects}
              onSelect={(p) => setProject(p)}
              value={project}
            />
          </ColumnWrapper>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton onClick={submitForm}>Zapisz</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

AddTaskPopup.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AddTaskPopup.defaultProps = {
  projectId: '',
  companyId: '',
};

export default AddTaskPopup;
