import React, {useCallback, useEffect, useMemo} from "react";
import {Table, TableRow, TableHead, TableBody, TableCell, TableSortLabel} from "@material-ui/core";
import {getComparator, stableSort} from "../helpers";

export default ({headerStyles = {}, columns = [], rows = [], children, onClickRow, isLoading, showColumns = true, showEmptyInfo = true}) => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const sortedRows = useMemo(() =>
        stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows]);

    const print = useCallback((index, column) => {
        const row = sortedRows[index] || {},
            {key} = column,
            child = children ? children[key] : null;

        if (typeof child === 'function') {
            return child(row, column)
        } else if (child) {
            return child
        } else if (key === 'index') {
            return index + 1
        }
        return (row[key] || '-') + ''
    }, [sortedRows, columns, children]);

    const key = row => row?.uuid || row?.id;

    const createSortHandler = property => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return <>
        <Table>
            {showColumns &&
            <TableHead>
                <TableRow>
                    {columns.map(({header, key, scope, padding, sort = true}) =>
                        <TableCell scope={scope} padding={padding} key={header} style={headerStyles}>
                            {
                                sort
                                ? <TableSortLabel
                                        active={orderBy === key}
                                        direction={orderBy === key ? order : 'asc'}
                                        onClick={() => createSortHandler(key)}
                                    >
                                        {header}
                                </TableSortLabel>
                                    : <div>{header}</div>
                            }
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>}
            <TableBody>
                {rows.length
                    ? sortedRows.map((row, index) => <>
                            <TableRow tabIndex={-1} key={key(row)} style={{cursor: "pointer"}}>
                                {columns.map(column =>
                                    <TableCell key={`${column.key}_${key(row)}`} scope={column?.scope} padding={column?.padding}
                                               onClick={() => {onClickRow && onClickRow(row, column)}}
                                    >
                                        {print(index, column)}
                                    </TableCell>
                                )}
                            </TableRow>
                            {row.attachedData && <TableRow key={`attached_${key(row)}`}>{row.attachedData}</TableRow>}
                        </>
                    )
                    : (showEmptyInfo &&
                        <TableRow>
                            <TableCell colSpan={columns.length}>{isLoading ? 'Wczytywanie' : 'Brak danych'}</TableCell>
                        </TableRow>)
                }
            </TableBody>
        </Table>
    </>
}