import React, {useEffect, useState} from "react";
import DynamicTable from "../../../../../components/tables/basic/dynamic-table/DynamicTable";
import {getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCT_PRODUCER_ROUTE} from "../../../../../constants/routes";
import Loader from "../../../../../components/loader/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ProducerTable from "./ProducerTable";
import {Collapse, TableCell} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export default () => {

    const headerStyles = {color: 'white', backgroundColor: 'rgb(63, 71, 78)'};
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState([]);

    const columns = [
        {header: 'Grupa', key: 'name'},
        {header: 'Akcje', key: 'actions'},
    ];

    const findTypeIndex = type => types.findIndex(({id}) => id === type?.id);

    const updateTypes = (newType, index) => {
        const newTypes = types.map(type => ({...type}));
        newTypes.splice(index, 1, newType);
        setTypes(() => newTypes);
    };

    const fetchTypes = () =>
        getCall(`${API_PRODUCT_PRODUCER_ROUTE}/types`)
            .then(({data: {items = []} = {}}) => setTypes(items));

    const appendProducers = type => {
        const index = findTypeIndex(type);
        if (~index) {
            const isOpen = !type?.isOpen;

            updateTypes(
                {
                    ...type,
                    isOpen,
                    attachedData:
                        <TableCell style={{padding: 0}} colSpan={2}>
                            <Collapse in={isOpen} timeout="auto" style={{marginRight: 0}}>
                                <Box margin={1} style={{marginRight: 0}}>
                                    <ProducerTable headerStyles={headerStyles} additionalFilters={{type: type.id} } groupId={type.id}/>
                                </Box>
                            </Collapse>
                        </TableCell>
                },
                index
            )
        }
    };

    useEffect(() => {
        fetchTypes().then(() => setLoading(false))
    }, []);

    const displayName = type =>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {type?.isOpen
                ? <ArrowUpwardIcon fontSize="small"/>
                : <ArrowDownwardIcon fontSize="small"/>
            }
            {type.name}
        </div>;

    const displayActions = type => <>
        <Tooltip title={`Podgląd ${type.name}`} placement="top">
            <IconButton onClick={() => appendProducers()}> 
                <VisibilityIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    </>;

    return <>
        {
            loading
                ? <Loader/>
                : <DynamicTable headerStyles={headerStyles} columns={columns} rows={types} onClickRow={appendProducers}>
                    {{
                        name: displayName,
                        actions: displayActions,
                    }}
                </DynamicTable>
        }
    </>
};