import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { API_CALENDAR_ROUTE } from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { postCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";

const Container = styled(ColumnWrapper)`
  min-width: 400px;
`;

const AddCalendarPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = async () => {
    if (name) {
      await postCall(API_CALENDAR_ROUTE, {
        name,
        description: description || '',
        isPublic: isPublic || false,
        isReadOnly: isPublic ? isReadOnly : false,
      });
      handleClose();
      props.onSave();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Container>
          <ColumnWrapper fullWidth>
            <TextField
              label="Nazwa"
              variant="outlined"
              value={name}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) => setName(value)}
            />
            <TextField
              multiline
              rows={4}
              label="Opis"
              variant="outlined"
              value={description}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) => setDescription(value)}
            />
            <FormControlLabel
            control={
              <Checkbox
                name={'isPublic'}
                checked={isPublic ? true : false}
                value={isPublic ? 'true' : ''}
                style={{width: 'max-content'}}
                onChange={({ target }) => setIsPublic(target.checked)}
              />
            }
            label={'Kalendarz publiczny'}
          />
          {isPublic && <FormControlLabel
            control={
              <Checkbox
                name={'isReadOnly'}
                checked={isReadOnly ? true : false}
                value={isReadOnly ? 'true' : ''}
                style={{width: 'max-content'}}
                onChange={({ target }) => setIsReadOnly(target.checked)}
              />
            }
            label={'Tylko do odczytu'}
          />}
          </ColumnWrapper>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton disabled={!name} onClick={onSave}>
          Zapisz
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

AddCalendarPopup.propTypes = {};

export default AddCalendarPopup;
