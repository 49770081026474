const headCells = [
  { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nazwa',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Notatka',
  },
];

export default headCells;
