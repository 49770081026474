import DateFnsUtils from '@date-io/date-fns';
import { InputLabel, TextField } from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {
  API_PROJECTS_ROUTE,
  API_PROJECT_VARIANTS_ROUTE,
  API_USERS_ROUTE,
} from '../../../../constants/routes';
import {
  OFFER_TYPES,
  STATUS_TYPES,
} from '../../../../constants/types/offerTypes';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import Select from '../../../../components/dynamic-forms/form-components/Select';
import { getCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import DynamicForm from '../../../../components/dynamic-forms/DynamicForm';
import { commonOffer } from './offer-common';

const CommonOffersForm = forwardRef(({ onTypeChange, initialValues, ...props }, ref) => {
  const [projects, setProjects] = useState([]);
  const [variants, setVariants] = useState([]);
  const [users, setUsers] = useState([]);

  const onFormChange = (f,v) => {
    if(f === 'type') {
      onTypeChange(v);
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.projectId && projects.length) {
      ref.current.setFormValue('project', location.state.projectId);
    }
    // eslint-disable-next-line
  }, [location?.state?.projectId, projects]);

  return (
    <ExpansionContainer title="Dane ogólne">
      <RowWrapper style={{ flexWrap: 'wrap' }} fullWidth>
        
        <ColumnWrapper width="100%">
          <DynamicForm avoidTabs={true} fields={commonOffer} initialValues={initialValues} small={true} ref={ref} onChangeMiddleware={(f,v) => onFormChange(f,v)} />
        </ColumnWrapper>
      </RowWrapper>
    </ExpansionContainer>
  );
});

CommonOffersForm.propTypes = {
  onTypeChange: PropTypes.func.isRequired,
};

export default CommonOffersForm;
