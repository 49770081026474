import {InputLabel, TextField, Typography} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React, {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {
    API_REPAIRERS_ROUTE,
    API_USERS_ROUTE
} from '../../../../../constants/routes';
import routerHistory from "../../../../../constants/settings/routerHistory";
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
    getCall,
    postCall,
    putCall
} from "../../../../../components/indicator/Indicator";
import BasicTable from "../../../../../components/tables/basic/BasicTable";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import FloatingSaveBar from "../../../../../components/FloatingSaveBar";
import repairsHeadCells from './repairsHeadCell';
import repairersFields from '../../repairs/details/repairersFields';
import {formToRequest} from "../../../../../components/dynamic-forms/field-transformer";
import ShipmentParts from "./ShipmentParts";
import Loader from "../../../../../components/loader/Loader";
import moment from 'moment';


const BasicData = ({data, edit, preview = false, onClose}) => {
    const formRef = useRef();
    const [repairs, setRepairs] = useState([]);
    const [users, setUsers] = useState([]);
    const [isValid, setValid] = useState(true);
    const [name, setName] = useState('');
    const [user, setUser] = useState('');
    const [shortcut, setShortcut] = useState('');
    const [shipmentParts, setShipmentParts] = useState([]);
    const [loading, setLoading] = useState(true);

    const submitForm = async () => {
        const callRepairer = async (fn) => {
            const eObj = {};
            if (edit) eObj.id = data.id;
            const formVals = formRef.current.getFormValues();
            const r = await fn(API_REPAIRERS_ROUTE, {
                ...eObj,
                name: formVals?.serwisant_name || name || '-',
                shortcut,
                krUser: user,
                shipment_parts: shipmentParts.filter(part => !shipmentPartIsEmpty(part)),
                fields: formToRequest(formVals),
            });
            if (r.data && !r.data.status) {
                toast.success(name ? `Zapisano wartości dla serwisanta - ${name}` : 'Zapisano wartości dla serwisanta');
                routerHistory.push('/service/repairers')
            }
        };

        if(validate()) {
            if (edit) {
                await callRepairer(putCall);
            } else {
                await callRepairer(postCall);
            }
        }
    };

    const shipmentPartIsEmpty = ({date, purpose, note, serial_no, producer, product, status, investment}) =>
        !date && !purpose && !note && !serial_no && !producer && !product && !status && !investment;

    const validate = () => {
        setValid(true);
        const isValid = !(shipmentParts.filter(part => {
            if(shipmentPartIsEmpty(part)) {
                return false
            }
            return !part.date || !part.producer || !part.product || !part.status || !part.investment;
        }).length);

        setValid(isValid);
        !isValid && toast.error('Formularz został źle wypełniony');
        return isValid
    };

    useEffect(() => {
        if (data?.repairs) {
            setRepairs(
                data.repairs.map((r) => ({
                    id: r?.id,
                    identy: r?.id,
                    projectName: r?.project?.name,
                    city: r?.projectCity,
                    address: r?.projectAddress,
                    datePlan: moment(r?.projectDate).format('DD.MM.yyyy')
                })),
            );
        }
        setName(data?.name);
        setShortcut(data?.shortcut);
        setUser(data?.krUser?.id);
        setShipmentParts(oldParts =>
            data?.shipment_parts.length ? data?.shipment_parts : oldParts
        );
    }, [data]);

    useEffect(() => {
        const fetchUsers = async () => {
            const {data} = await getCall(API_USERS_ROUTE);
            if (data?.items) {
                setUsers(
                    data?.items.map((i) => ({
                        ...i,
                        name: i.email,
                    })),
                );
            }
        };
        fetchUsers().then(() => setLoading(false));
    }, []);

    const onChangeMiddleware = (f, v) => {
        if(f === 'serwisant_adres_wy_cz_copy') {
            
            const formVals = formRef.current.getFormValues();
            navigator.clipboard.writeText(formVals.serwisant_adres_wy_cz || '-');
            toast.info('Skopiowano do schowka');
        }
    }

    return (
        <>
            {loading
                ? <div style={{height: '80vh'}}><Loader/></div>
                : <>
                    {preview && (
                        <IconButton
                        onClick={onClose}
                        style={{ position: 'absolute', top: '20px', right: '20px', background: '#fff' }}
                        >
                            <CloseOutlinedIcon fontSize='large' />
                        </IconButton>
                    )}
                    <ExpansionContainer title="Serwisant">
                        <RowWrapper style={{width: '100%'}}>
                            <ColumnWrapper fullwidth>
                                <DynamicForm ref={formRef} fields={repairersFields} initialValues={data?.fields}
                                            onChangeMiddleware={onChangeMiddleware} avoidTabs={true} readOnly={preview} />
                            </ColumnWrapper>
                        </RowWrapper>
                        
                        <RowWrapper style={{width: '100%', transform: 'translateY(-25px)', pointerEvents: preview ? 'none' : 'auto'}}>
                            <ColumnWrapper width="20%" style={{padding: '10px'}}>
                                <InputLabel style={{margin: '6px 0'}} shrink htmlFor={"shortcut"}>
                                    Skrót
                                </InputLabel>
                                <TextField
                                    value={shortcut}
                                    name="shortcut"
                                    variant="outlined"
                                    onChange={({target}) => setShortcut(target.value)}
                                />
                            </ColumnWrapper>
                            <ColumnWrapper width="20%">
                                <Select
                                    label="KR"
                                    data={users}
                                    onSelect={(u) => setUser(u)}
                                    value={user}
                                />
                            </ColumnWrapper>
                        </RowWrapper>
                        
                        <RowWrapper style={{width: '100%', overflowX: 'auto'}}>
                            <ColumnWrapper fullwidth>
                                <Typography style={{margin: '0 10px'}} variant="subtitle2">
                                    Wysłane części:
                                </Typography>
                            </ColumnWrapper>
                        </RowWrapper>
                        <RowWrapper style={{width: '100%', overflowX: 'auto'}}>
                            <ColumnWrapper fullwidth style={{pointerEvents: preview ? 'none' : 'auto'}}>
                                <ShipmentParts archived={false} parts={shipmentParts} isValid={isValid} onUpdate={parts => setShipmentParts(() => parts)}/>
                            </ColumnWrapper>
                        </RowWrapper>
                        
                        <RowWrapper style={{width: '100%', overflowX: 'auto'}}>
                            <ColumnWrapper fullwidth>
                                <Typography style={{margin: '0 10px'}} variant="subtitle2">
                                    Archiwizowane części:
                                </Typography>
                            </ColumnWrapper>
                        </RowWrapper>
                        <RowWrapper style={{width: '100%', overflowX: 'auto'}}>
                            <ColumnWrapper fullwidth style={{pointerEvents: preview ? 'none' : 'auto'}}>
                                <ShipmentParts archived={true} parts={shipmentParts} isValid={isValid} onUpdate={parts => setShipmentParts(() => parts)}/>
                            </ColumnWrapper>
                        </RowWrapper>
                    </ExpansionContainer>
                    {!preview && (
                    <ExpansionContainer title="Lista obsługiwanych inwestycji">
                        <BasicTable
                            headCells={repairsHeadCells}
                            rows={repairs}
                            onRowClick={(t) =>
                                routerHistory.push(`/service/repairs/edit/${t?.id}`)
                            }
                            onChangePage={() => {
                            }}
                            recordsCount={data?.repairs?.length || 0}
                        />
                    </ExpansionContainer>
                    )}
                </>}
            {!preview && <FloatingSaveBar onClick={submitForm}/>}
        </>
    );
};

export default BasicData;
