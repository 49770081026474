export const offerPreview = [
  {
    type: 'multipleFields',
    name: 'Informacje na ofercie',
    value: [],
    options: { fields: [
      { name: 'Informacje na ofercie', type:'header', fullWidth: true, value: '', },
      { label: 'Funkcjonalność układu automatyki', name: 'Funkcjonalność układu automatyki', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Obiekty sterowane', name: 'Obiekty sterowane', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Właściwości elektryczne elementów wykonawczych przyjęte do wyceny', name: 'Właściwości elektryczne elementów wykonawczych przyjęte do wyceny', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Układ automatyki węzła zawiera', name: 'Układ automatyki węzła zawiera', type: 'multilineInput', fullWidth: true, value: '- wymagane komponenty automatyki:\n\n- opracowanie i wykonanie projektu elektrycznego szafy;\n- montaż elementów w szafie;\n- opracowanie instrukcji obsługi układu automatyki;\n- integracja i parametryzacja urządzeń;\n- uruchomienie układu automatyki (pierwszy rozruch);', },
      { label: 'Wymagane prace do wykonania przez instalatorów na obiekcie przed uruchomieniem', name: 'Wymagane prace do wykonania przez instalatorów na obiekcie przed uruchomieniem', type: 'multilineInput', fullWidth: true, value: '- instalacja szafy automatyki w miejscu przeznaczenia (w budynku);\r\n- montaż dostarczonych czujników oraz osłon;\r\n- doprowadzenie przewodów miedzianych pomiędzy szafą automatyki, a miejscami instalacji poszczególnych czujników oraz elementów sterujących (pompy obiegowe, siłownik) zgodnie z zestawieniem; \r\n- doprowadzenie magistrali CAN pomiędzy zestawem urządzeń Robur a szafą automatyki;\r\n- doprowadzenie napięcia zasilania do szafy automatyki;\r\n- gotowość układu hydraulicznego do rozruchu – wypełnienie instalacji glikolem i wodą, zainstalowanie odbiorów ciepła;\r\n- doprowadzenie do szafy sterującej przewodu sieci komputerowej, zakończonego obustronnie wtykiem RJ45, zapewniającego dostęp do Internetu.', },
    ]}
  }
]