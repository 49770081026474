import {
  Avatar,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  API_USERS_ROUTE,
  API_USER_PROFILE_ROUTE,
} from '../../../../constants/routes';
import routerHistory from "../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import Select from '../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall,
} from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import UserImage from '../../../../components/user-image/UserImage';

const verticalInputMarign = { margin: '10px 0', alignItems: 'center' };
const labelMarign = { marginBottom: '5px' };
const HorizontalMarign = { margin: '0 50px' };

const userRoles = [
  {
    id: 1,
    name: 'Zwykły użytkownik',
    value: 'ROLE_USER',
  },
  {
    id: 2,
    name: 'Administrator',
    value: 'ROLE_ADMIN',
  },
];

const UserDetails = () => {
  const [details, setDetails] = useState();
  const { id } = useParams();

  const submitForm = async () => {
    const callUser = async (fn) => {
      const eObj = {};
      if (id) eObj.id = details.id;
      const r = await fn(
        id ? `${API_USER_PROFILE_ROUTE}/${id}` : `${API_USERS_ROUTE}/`,
        {
          ...eObj,
          username: formik.values.name,
          email: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          phone: formik.values.phone,
          password: formik.values.password || null,
          isKr: formik.values.isKr,
          roles: [formik.values.role.value],
        },
      );
      if (r?.data && !r?.data?.status && id) {
        toast.success(`Zapisano wartości dla użytkownika ${details?.username}`);
        routerHistory.push('/administration/users');
      } else if (r?.data && !r?.data?.status) {
        toast.success(
          `Zapisano wartości dla użytkownika ${formik.values.name}`,
        );
        routerHistory.push('/administration/users');
      } else {
        toast.error('Błąd podczas zapisywania danych dla użytkownika.');
      }
    };
    await callUser(id ? putCall : postCall);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      role: '',
      isKr: false,
    },
  });

  const fetchUserDetails = useCallback(async () => {
    const { data } = await getCall(`${API_USER_PROFILE_ROUTE}/${id}`);
    if (data) {
      setDetails(data);
      formik.setFieldValue('email', data?.email);
      formik.setFieldValue('name', data?.username);
      formik.setFieldValue('firstName', data?.firstName);
      formik.setFieldValue('lastName', data?.lastName);
      formik.setFieldValue('phone', data?.phone);
      formik.setFieldValue('phone', data?.phone);
      formik.setFieldValue('isKr', data?.isKr);

      const isAdmin = data?.roles?.filter((role) => role).find(
        (role) => role?.toString() === 'ROLE_ADMIN',
      );

      if (isAdmin) formik.setFieldValue('role', userRoles[1].id);
      else formik.setFieldValue('role', userRoles[0].id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchUserDetails();
    }
  }, [fetchUserDetails, id]);

  return (
    <>
      <ExpansionContainer title="Dane użytkownika">
        <ColumnWrapper style={HorizontalMarign}>
          <TextField
            style={verticalInputMarign}
            variant="outlined"
            placeholder="Imię"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          <TextField
            style={verticalInputMarign}
            variant="outlined"
            placeholder="Nazwisko"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          <TextField
            style={verticalInputMarign}
            variant="outlined"
            placeholder="Telefon"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          <TextField
            style={verticalInputMarign}
            variant="outlined"
            placeholder="E-mail"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <TextField
            style={verticalInputMarign}
            variant="outlined"
            placeholder="Hasło"
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <Select
            data={userRoles}
            value={formik.values.role}
            name="role"
            onSelect={(v) => formik.setFieldValue('role', v)}
          />
          <FormControlLabel
            style={{ margin: '10px 0 0 -10px' }}
            control={
              <Checkbox
                name="isKr"
                checked={formik.values.isKr}
                onChange={({ target }) =>
                  formik.setFieldValue('isKr', target.checked)
                }
              />
            }
            label="Oznacz jako KR"
          />
          <PrimaryButton
            disabled={
              !(
                formik.values.email &&
                (!id ? formik.values.password : true) &&
                formik.values.role
              )
            }
            onClick={submitForm}
            style={{ marginTop: 20 }}>
            Zapisz
          </PrimaryButton>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default UserDetails;
