import moment from 'moment';
import 'moment/locale/pl';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { toast } from 'react-toastify';
import { getCall } from "../../components/indicator/Indicator";
import CalendarToolBar from "./components/tool-bar/CalendarToolBar";
import CalendarCustomToolbar from './CalendarToolbar';
import { API_CALENDARS_MULTIPLE_EVENTS_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import CalendarEvent from './components/CalendarEvent';

moment.locale('pl');
const localizer = momentLocalizer(moment);

const CalendarScreen = () => {
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [events, setEvents] = useState([]);
  const [marginRight, setMarginRight] = useState('240px');
  const [projectIds, setProjectIds] = useState('');
  const [companyIds, setCompanyIds] = useState('');
  const [currentView, setCurrentView] = useState(localStorage.getItem('cal_view') || 'month');
  const [currentDate, setCurrentDate] = useState(localStorage.getItem('cal_date') ? new Date(localStorage.getItem('cal_date')) : new Date());
  const [range, setRange] = useState({
    start: localStorage.getItem('cal_start') ? localStorage.getItem('cal_start')  : moment().startOf('month').format('X'),
    end: localStorage.getItem('cal_end') ? localStorage.getItem('cal_end') : moment().endOf('month').format('X'),
  });

  const handleSelect = ({ start, end, action }) => {
    if(action === 'select') {
      routerHistory.push('/calendar/add-slot', { start, end });
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedCalendars.length) {
        const ids = selectedCalendars?.filter((c) => c?.id).map((c) => c?.id).join() || [];
        const { data } = await getCall(
          `${API_CALENDARS_MULTIPLE_EVENTS_ROUTE}?ids=${ids}&startts=${range.start}&endts=${range.end}&project=${projectIds}&company=${companyIds}`,
        );
        if (data?.length) {
          const evs = data.map((e) => ({
            ...e,
            allDay: e.allDay,
            start: moment(parseFloat(`${e.start}000`)).toDate(),
            end: moment(parseFloat(`${e.stop}000`)).toDate(),
            title: e.title || 'Wydarzenie',
          }));
          setEvents(evs);
        } else {
          toast.warn('Brak wydarzeń dla wybranego okresu.');
        }
      } else {
        setEvents([]);
      }
    };
    fetchEvents();
  }, [selectedCalendars, range.start, range.end]);

  const onCalendarChange = (calendars, p, c) => {
    if (calendars) {
      setSelectedCalendars(calendars);
    }
    setProjectIds(p?.join(',') || '');
    setCompanyIds(c?.join(',') || '');
  };

  const onRangeChange = (r) => {
    if (r?.start && r?.end) {
      setRange({
        start: moment(r?.start).format('X'),
        end: moment(r?.end).format('X'),
      });
      localStorage.setItem('cal_start', moment(r?.start).format('X'));
      localStorage.setItem('cal_end', moment(r?.end).format('X'));
    } else {
      setRange({
        start: moment(r[0]).format('X'),
        end: moment(r[r.length - 1]).format('X'),
      });
      localStorage.setItem('cal_start', moment(r[0]).format('X'));
      localStorage.setItem('cal_end', moment(r[r.length - 1]).format('X'));
    }
  };

  const eventPicked = (event) => {
    if(event?.calendar) {
      routerHistory.push('/calendar/edit-slot/' + event?.id);
    } else {
      if(event?.type === 'task') {
        routerHistory.push('/tasks/edit/' + event?.referId);
      }
    }
  }

  const viewChanged = (newView) => {
    setCurrentView(newView);
    localStorage.setItem('cal_view', newView);
  }

  const dateChanged = (newDate) => {
    localStorage.setItem('cal_date', newDate);
    setCurrentDate(newDate);
  }

  const onDrawerShow = (isOpen = true) => {
    setMarginRight(isOpen ? '240px' : '0px')
  }

  const titleAccessor = (item) => item.allDay ? item.title 
    : ((moment(item.start).format('HH:mm') === moment(item.end).format('HH:mm')) ? `[${moment(item.start).format('HH:mm')}] ${item.title}`
    : `[${moment(item.start).format('HH:mm')} - ${moment(item.end).format('HH:mm')}] ${item.title}`)

  const [toolbarStyles, setToolbarStyles] = useState({paddingRight: marginRight})
  const [mainStyles, setMainStyles] = useState({ height: '80vh', marginRight })

  useEffect(() => {
    setToolbarStyles({paddingRight: marginRight});
    setMainStyles({ height: '80vh', marginRight });
  }, [marginRight])

  return (
    <>
      <CalendarToolBar onCalendarChange={onCalendarChange} onDrawerShow={onDrawerShow} style={toolbarStyles} />
      <Calendar
        onRangeChange={(r) => onRangeChange(r)}
        style={mainStyles}
        selectable
        localizer={localizer}
        events={events}
        titleAccessor={titleAccessor}
        messages={{
          next: 'Następny',
          previous: 'Poprzedni',
          today: 'Dzisiaj',
          week: 'Tydzień',
          month: 'Miesiąc',
          year: 'Rok',
          day: 'Dzień',
          more: 'więcej',
          noEventsInRange: 'Brak wydarzeń w zaznaczonym terminie',
        }}
        views={{
          day: true,
          week: true,
          month: true,
        }}
        scrollToTime={new Date(1970, 1, 1, 6)}
        onNavigate={dateChanged}
        date={currentDate}
        onSelectEvent={(event) => eventPicked(event)}
        onSelectSlot={handleSelect}
        onView={viewChanged}
        view={currentView}
        components={{
          toolbar: CalendarCustomToolbar,
          eventWrapper: CalendarEvent,
        }}
      />
    </>
  );
};

export default CalendarScreen;
