import React, { useEffect, useState } from 'react';
import { getCall } from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import ListToolBar from './components/list/ListToolBar';
import { API_USERS_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import UserImage from '../../components/user-image/UserImage';
import RowWrapper from '../../components/wrappers/RowWrapper';

const AdministrationScreen = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(null);

  const fetchUsers = async (url = API_USERS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      setRows(
        data?.items.map(({ username, email, id, image = null }) => 
        ({ 
          id,
          username: (<RowWrapper style={{alignItems: 'center'}}><UserImage name={username} src={image} size={21} /><span style={{marginLeft: '10px'}}>{username}</span></RowWrapper>), 
          email
        })),
      );
      setCount(data.total);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <ListToolBar />
      <BasicTable
        headCells={[
          { id: 'username', numeric: false, disablePadding: false, label: 'nazwa' },
          { id: 'email', numeric: false, disablePadding: false, label: 'e-mail' }
        ]}
        rows={rows}
        onChangePage={async (p) =>
          await fetchUsers(`${API_USERS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) =>
          routerHistory.push(`/administration/users/edit/${id}`)
        }
      />
    </>
  );
};

export default AdministrationScreen;
