import React, { useEffect } from 'react';
import ColumnWrapper from '../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../components/wrappers/RowWrapper';
import { TextField } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { offerPreview } from './offer-preview.js';
import _ from 'lodash';

const ProjectVariantDetailPreview = ({ fields, previewData }) => {
  const [functionality, setFunctionality] = React.useState('');
  const [controlled, setControlled] = React.useState('');
  const [electrical, setElectrical] = React.useState('');

  console.log("fields", fields);
  console.log("previewData", previewData);

  useEffect(() => {
    const functionalityFields = previewData['Funkcjonalność układu automatyki'].fields;
    const controlledObjects = previewData['Obiekty sterowane'].fields;
    const electricalProperties = previewData['Właściwości elektryczne elementów wykonawczych przyjęte do wyceny']['fields-as-second-text'];

    const checkedFunctionalityFields = fields.filter(field => functionalityFields.includes(field.name) && field.value !== '').filter(item => item.value !== null);
    const checkedControlledObjectss = fields.filter(field => controlledObjects.includes(field.name) && field.value !== '').filter(item => item.value !== null);
    const checkedElectricalProperties = fields.filter(field => electricalProperties.includes(field.name) && field.value !== '').filter(item => item.value !== null);
    
    if(checkedFunctionalityFields.length > 0) {
      const text = checkedFunctionalityFields.map((item) => item ? `- ${item?.value?.text}\n` : '');
      setFunctionality(text.join().replaceAll(',', ''));
    }

    if(checkedControlledObjectss.length > 0) {
      const text = checkedControlledObjectss.map((item) => item ? `- ${item?.value?.text}\n` : '');
      setControlled(text.join().replaceAll(',', ''));
    }

    if(checkedElectricalProperties.length > 0) {
      const text = checkedElectricalProperties.map((item) => item?.value?.secondText ? `- ${item?.value?.secondText}\n` : `- ${item?.value?.text}\n`);
      setElectrical(text.join().replaceAll(',', ''));
    }
  }, [])
  return (
    <RowWrapper style={{ width: '70%', backgroundColor: 'white', margin: '50px auto', padding: '20px', borderRadius: '10px' }}>
      <ColumnWrapper fullWidth>
        <h3>Informacje na ofercie</h3>
        <InputLabel style={{ marginTop: 10 }} shrink>
          Funkcjonalność układu automatyki
        </InputLabel>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={functionality}
        />
        <InputLabel style={{ marginTop: 10 }} shrink>
          Obiekty sterowane
        </InputLabel>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={controlled}
        />
        <InputLabel style={{ marginTop: 10 }} shrink>
          Właściwości elektryczne elementów wykonawczych przyjęte do wyceny
        </InputLabel>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={electrical}
        />
        <InputLabel style={{ marginTop: 10 }} shrink>
          Układ automatyki węzła zawiera
        </InputLabel>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={offerPreview[0].options.fields[4].value}
        />
        <InputLabel style={{ marginTop: 10 }} shrink>
          Wymagane prace do wykonania przez instalatorów na obiekcie przed uruchomieniem
        </InputLabel>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={offerPreview[0].options.fields[5].value}
        />
      </ColumnWrapper>
    </RowWrapper>
  )
}

export default ProjectVariantDetailPreview;