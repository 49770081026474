import React, { useRef } from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from '../../../../../components/dynamic-forms/DynamicForm';
import { postCall } from '../../../../../components/indicator/Indicator';
import BasicTable from "../../../../../components/tables/basic/BasicTable";
import commonOfferHeadCells from '../../../../../constants/headCells/commonOfferHeadCells.js';
import { API_PROJECTS_ROUTE } from '../../../../../constants/routes';
import { offerGeneratorFields } from './offerGeneratorFields';
import { headCells, rows } from './tableData';
import routerHistory from '../../../../../constants/settings/routerHistory';

const OffersData = ({offers, projectId, onRowClick, refreshOffers}) => {

  const offerGeneratorFormRef = useRef();

  const changed = async (f,v) => {
    if([
      'offer_generate_devices', 'offer_generate_per', 'offer_generate_autom', 'offer_generate_merged'
    ].includes(f)) {
      const variant = offerGeneratorFormRef.current?.getFormValues()?.offer_variant?.id;
      const company = offerGeneratorFormRef.current?.getFormValues()?.offer_company?.id;
      const person = offerGeneratorFormRef.current?.getFormValues()?.company_person;

      let type = '';
      
      switch(f) {
        case 'offer_generate_devices': type = 'urzadzenia'; break;
        case 'offer_generate_per': type = 'peryferia'; break;
        case 'offer_generate_autom': type = 'automatyka'; break;
        case 'offer_generate_merged': type = 'scalona'; break;
        default: break;
      }

      if(variant && company && type && person) {
        const response = await postCall(`${API_PROJECTS_ROUTE}/${projectId}/prepare-offer/${variant}/${company}/${type}`, {
          representative: person,
        });
        if(response.data.id) {
          routerHistory.push(`/offers/edit/${response.data.id}`)
          refreshOffers();
        }

      }
    }
  }

  return (
    <>
      <div>
        <DynamicForm avoidTabs small ref={offerGeneratorFormRef} fields={offerGeneratorFields} onChangeMiddleware={changed} />
      </div>
      <div style={{ overflowX: 'auto' }}>
        <BasicTable heightAuto={false} headCells={[
            { id: 'offerNumber', numeric: false, disablePadding: false, label: 'Numer' },
            { id: 'company', numeric: false, disablePadding: false, label: 'Firma' },
            { id: 'representative', numeric: false, disablePadding: false, label: 'Przedstawiciel firmy' },
            { id: 'owner', numeric: false, disablePadding: false, label: 'Sporządził ofertę' },
            { id: 'variant', numeric: false, disablePadding: false, label: 'Wariant' },
            { id: 'type', numeric: false, disablePadding: false, label: 'Typ' },
            { id: 'warranty', numeric: false, disablePadding: false, label: 'Gwarancja' },
            { id: 'discount', numeric: false, disablePadding: false, label: 'Rabat' },
            { id: 'dateAdded', numeric: false, disablePadding: false, label: 'Dodano' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        ]} rows={offers || []} onRowClick={onRowClick} />
      </div>
    </>
  );
};

export default OffersData;
