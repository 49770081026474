import {
  Button, CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField,
  Typography,
} from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import returnSpecificField from '../returnSpecificField';
import {convertArrayToObject} from '../field-transformer';
import {getCall, postFileCall, deleteCall} from "../../indicator/Indicator";
import {API_OFFERS_ROUTE, API_DOCUMENTS_ROUTE ,API_PRODUCT_FINAL_VARIANTS_ROUTE, API_PROJECTS_ROUTE, SERVER_ROUTE} from "../../../constants/routes";
import { useParams } from 'react-router';
import Select from "./Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../loader/Loader";
import RowWrapper from "../../wrappers/RowWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '10px'
  }
}));

const ProjectTransportRowsGroup = ({ fields, value, onChange, name, onChangeInner = () => {}, ...props }) => {
  const classes = useStyles();
  const { id } = useParams();

  const [rows, setRows] = useState([]);
  const [variantCosts, setVariantCosts] = useState({launch: 0, devices: 0, automation: 0});
  const [automationPrices, setAutomationPrices] = useState({guarantee3: 0, guarantee4: 0, guarantee5: 0});
  const [project, setProject] = useState({});
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState('');

  //todo - remove this function or edit because it's causing problems
  // React.useEffect(() => {
  //   onChange(name, JSON.stringify(rows));
  //   // eslint-disable-next-line
  // }, [rows]);

  const localOnChange = (i) => (field, value) => {

    if(field === 'pt_nrfaktury') {
      onChangeInner('pt_nazwapliku', value.name);
      setRows((prev) => {
        let localRows = prev.map(item => convertArrayToObject(item));
        localRows[i]['pt_nazwapliku'] = value.name;
        onChange(name, JSON.stringify(localRows));
        return localRows;
      });
    }
    
    if(onChangeInner) {
      onChangeInner(field, value);
    }

    setRows((prev) => {
      let localRows = prev.map(item => convertArrayToObject(item));
      localRows[i][field] = value;
      onChange(name, JSON.stringify(localRows));
      return localRows;
    });
    //onChange(localRows);
  };

  const removeRow = (i) => {    
    setRows((prev) => {
      let localRows = prev.map(item => convertArrayToObject(item));
      localRows.splice(i, 1);
      onChange(name, JSON.stringify(localRows));
      return localRows;
    });
  };

  const openAttachment = (attachmentPath) => {
    window.open(`${SERVER_ROUTE}/${attachmentPath}`);
  };

  const postAttachment = async (attachment) => {
    try {
      const formData = new FormData();
      formData.append('name', attachment.name);
      formData.append('description', '');
      formData.append('file', attachment);
  
      const { data } = await postFileCall(
        API_DOCUMENTS_ROUTE,
        formData,
        undefined,
        undefined,
        true,
      );
      if (data?.file?.id) {
        return {
          id: data?.file?.id,
          path: data?.file?.path,
          name: data?.file?.name,
        };
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const removeAttachment = async (attachmentId, callback) => {
    try {
      deleteCall(`${API_DOCUMENTS_ROUTE}/${attachmentId}`);
      callback();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      if(value) {
        setRows(() => Array.isArray(value) ? value : JSON.parse(value || []));
      } else {
        setRows([]);
      }
    } catch (e) {
      setRows([]);
    }
  }, [value]);

  const fetchFinalVariant = async () => {
    const r = await getCall(API_PRODUCT_FINAL_VARIANTS_ROUTE(id));
    const variant = r?.data?.item
    if(variant) {
      setVariantCosts({
        devices: variant?.launchCost || 0,
        peripherals: variant?.peripherals?.reduce((total, device) => total + (Number(device?.amount) * Number((device?.product?.net_list_price))), 0) || 0,
        automation: variant?.automation?.fields?.find(field => field?.name === 'Cena katalogowa z 2 letnią gwarancją - cena')?.value || 0,
      })

      setAutomationPrices({
        guarantee3: +variant?.automation?.fields?.find(field => field?.name === 'Gwarancja 3 letnia - cena')?.value,
        guarantee4: +variant?.automation?.fields?.find(field => field?.name === 'Gwarancja 4 letnia - cena')?.value,
        guarantee5: +variant?.automation?.fields?.find(field => field?.name === 'Gwarancja 5 letnia - cena')?.value,
      })
    }
  };

  const fetchProject = async () => {
    const r = await getCall(`${API_PROJECTS_ROUTE}/${id}`);
    const project = r?.data?.object
    if(project) {
     setProject(project)
    }
  };

  const fetchOffers = async () => {
    const r = await getCall(`${API_OFFERS_ROUTE}?projects[]=${id}`);
    const offers = r?.data?.items
    if(offers?.length) {
      setOffers(offers)
    }
  };

  const guaranteeOptions = searchesOffer => {
    const orders = project?.fields?.find(field => field?.name == 'zam_zams')?.value || [];
    const selectedOrders = orders?.filter(order => !!order?.zam_zams_oferta?.find(offer => offer.id == searchesOffer?.id));
    return selectedOrders?.map(offer => ({id: offer?.zam_zams_gwarancja, name: offer?.zam_zams_gwarancja}))
  };

  useEffect(() => {
    if(id) {
      fetchFinalVariant()
      fetchOffers()
      fetchProject().then(() => setLoading(false))
    }
  },[]);

  const countCatalogPrice = values => {
    return ['urządzenia', 'peryferia'].includes(values?.pt_przedmiot) ? (Number(values?.pt_mnoznik) || 1) * Number(values?.pt_cena_katalogowa_2) : Number(values?.pt_cena_katalogowa_2)
  }
  const renderHeader = () => (
    <TableHead>
      <TableRow>
        {fields.map((item, i) => (
          <TableCell padding="none"  key={`item_${i}`} style={{
            textAlign: 'center',
            transform: item.options?.attrs?.translate && rows.length > 0 ? 'translateX(90px)' : 'unset'
            }}
          >
            <Fragment>
              {item.label ? (
                <Typography style={{ padding: '3px' }} variant="caption">
                  {item.label}
                </Typography>
              ) : null}
            </Fragment>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderRow = (values, y) => (

    <TableRow>
      {fields.map((item, i) => (
        <TableCell key={`item_${y}_${i}`} style={{minWidth: '180px', padding: '15px', borderRight: '1px solid lightgray'}}>
          {item.name === 'pt_cena_katalogowa_2' ?
              <Autocomplete
                  freeSolo
                  value={values[item.name] || ''}
                  options={
                    {
                      'urządzenia': [variantCosts?.devices],
                      'peryferia': [variantCosts?.peripherals],
                      'automatyka': [variantCosts?.automation]
                    }[values?.pt_przedmiot] || []
                  }
                  getOptionLabel={(option) => option ? String(option) : '0'}
                  onInputChange={(e, v) =>
                  {
                    localOnChange(y)('pt_cena_katalogowa_2', v)
                    localOnChange(y)('pt_katalog_dla_gwarancji', countCatalogPrice(values))
                    localOnChange(y)('pt_left', (Number(values.pt_katalog_dla_gwarancji) - Number(values.pt_kwota_1) - Number(values.pt_kwota_2)) || Number(v))
                  }}
                  variant="outlined"
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          style={{ backgroundColor: '#f2f2f3', width: '100%', fontSize: '10px' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                          }}
                      />
                  )}
              />
              :
              item.name === 'pt_przedmiot' ?
                    <Autocomplete
                        freeSolo
                        value={values[item.name] || ''}
                        options={['urządzenia', 'peryferia', 'automatyka']}
                        getOptionLabel={(option) => option ? String(option) : ''}
                        onInputChange={(e, v) => localOnChange(y)('pt_przedmiot', v)}
                        variant="outlined"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                style={{ backgroundColor: '#f2f2f3', width: '100%', fontSize: '10px' }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                  ),
                                }}
                            />
                        )}
                    />
              :
              item.name === 'pt_mnoznik' ?
                    <Autocomplete
                        freeSolo
                        value={values[item.name] || ''}
                        options={
                          (() => {
                            const guarantee = () => {
                              const value = String(values?.pt_gwarancja).match(/\d+/g)?.[0]
                              switch (+value) {
                                case 2: {
                                  return [1]
                                }
                                case 3: {
                                  return [1.03]
                                }
                                case 4: {
                                  return [1.04]
                                }
                                case 5: {
                                  return [1.05]
                                }
                                default:
                                    return []
                              }
                            };
                            return ['urządzenia', 'peryferia'].includes(values?.pt_przedmiot) ? guarantee() : []
                          })()
                        }
                        getOptionLabel={(option) => option ? String(option) : '0'}
                        onInputChange={(e, v) =>
                        {
                          localOnChange(y)('pt_mnoznik', v)
                          localOnChange(y)('pt_katalog_dla_gwarancji', countCatalogPrice(values))
                          localOnChange(y)('pt_left', (Number(values.pt_katalog_dla_gwarancji) - Number(values.pt_kwota_1) - Number(values.pt_kwota_2)) || Number(values?.pt_cena_katalogowa_2))
                        }}
                        variant="outlined"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                style={{ backgroundColor: '#f2f2f3', width: '100%', fontSize: '10px' }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                  ),
                                }}
                            />
                        )}
                    />
                  : item.name === 'pt_katalog_dla_gwarancji'
                  ? <Autocomplete
                      freeSolo
                      value={values[item.name] || '0'}
                      options={
                        (() => {
                          const value = String(values?.pt_gwarancja).match(/\d+/g)?.[0];
                          const price = automationPrices[`guarantee${value}`]
                          return values?.pt_przedmiot === 'automatyka' && price ? [price] : []
                        })()

                      }
                      getOptionLabel={(option) => option ? String(option) : '0'}
                      onChange={(e, v) =>
                      {
                        localOnChange(y)('pt_katalog_dla_gwarancji', v)
                        localOnChange(y)('pt_left', (Number(values.pt_katalog_dla_gwarancji) - Number(values.pt_kwota_1) - Number(values.pt_kwota_2)) || Number(values?.pt_cena_katalogowa_2))
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              style={{ backgroundColor: '#f2f2f3', width: '100%', fontSize: '10px' }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                              }}
                          />
                      )}
                  />
                  : returnSpecificField(
                      item.type,
                      (() => {
                        if(item.name === 'pt_kwota_1') {
                          item.options = {...item.options, attrs: {...(item?.options?.attrs || {}), disabled: !values?.pt_kwota_1_real}}
                        }

                        if(item.name === 'pt_kwota_2') {
                          item.options = {...item.options, attrs: {...(item?.options?.attrs || {}), disabled: !values?.pt_kwota_2_real}}
                        }

                        return {...item.options, data: item?.name === 'pt_gwarancja' ? guaranteeOptions(values?.pt_oferta) : item?.options?.data, index: y, attrs: { style: { padding: '0px'}, multipleIndex: y, ...(item.options?.attrs || {})}}
                      })(),
            item.name,
            values[item.name] || '',
              (f, v) => {
                localOnChange(y)(f,v);
                if(f === 'pt_oferta') {
                  localOnChange(y)('pt_warunki', offers?.find(offer => +offer?.id === +v?.id)?.fields?.find(field => field?.name === 'Warunki płatności')?.value || '')
                }
                if(f === 'pt_przedmiot') {
                  localOnChange(y)('pt_katalog_dla_gwarancji', countCatalogPrice(values))
                }
                localOnChange(y)('pt_left', (Number(values.pt_katalog_dla_gwarancji) - Number(values.pt_kwota_1) - Number(values.pt_kwota_2)) || Number(values?.pt_cena_katalogowa_2))
              },
            false,
            null,
            false,
            onChangeInner,
            props?.values,
            props?.addProps
          )
              }
        </TableCell>
      ))}
      <TableCell padding="none" style={{ paddingLeft: '10px' }}>
        <Button variant="contained" size="small" onClick={(e) => removeRow(y)}>
          <DeleteIcon fontSize="small" />
        </Button>
      </TableCell>
    </TableRow>
  );

  return (
    <ColumnWrapper fullWidth style={{ overflowX: 'auto' }}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
        aria-label="enhanced table">
        {renderHeader()}
        <TableBody>
          {loading ? <Loader/> : rows.map((item, i) => (
            <Fragment key={`item_${i}`}>{renderRow(item, i)}</Fragment>
          ))}
          {!props.disableAdd && <TableRow>
            <TableCell padding="none" colSpan="100%">
              <Button className={classes.add} size="small" variant="contained" onClick={(e) => setRows([...rows, {}])}>
                <AddIcon fontSize="small" />
              </Button>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </ColumnWrapper>
  );
};

export default ProjectTransportRowsGroup;
