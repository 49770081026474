const mergedOffer = [
  {
    type: 'multipleFields',
    name: 'Ustawienia oferty scalonej',
    value: [],
    options: { fields: [
        { label: 'Podział na grupy', name: 'Podział na grupy', type: 'dictionary', 
        options: { data: [
          { id: 1, name: 'Podział na grupy' },
          { id: 2, name: 'Podział na urządzenia/peryferia/automatykę' }
        ] }, 
        value: '', fullWidth: true },
        { label: 'Oferta na urządzenia', name: 'Oferta na urządzenia', type: 'entity', options: { entityClass: 'offer' , attrs: { forceParamId: 'urzadzenia' }}, value: '', },
        { label: 'Oferta na peryferia', name: 'Oferta na peryferia', type: 'entity', options: { entityClass: 'offer', attrs: { forceParamId: 'peryferia' }}, value: '', },
        { label: 'Oferta na automatykę', name: 'Oferta na automatykę', type: 'entity', options: { entityClass: 'offer', attrs: { forceParamId: 'automatyka' }}, value: '', },
        
      ]
    },
  }
];

const mergedOfferGroups = [
  {
    type: 'multipleFields',
    name: 'Grupy',
    value: [],
    options: { fields: [
      {
        name: "device_groups",
        label: "Grupy urządzeń",
        type: "rowsGroup",
        value: "[]",
        options: {
            "id": 105,
            "data": [],
            "fields": [
                {
                  "id": 107,
                  "name": "device_group_number",
                  "type": "number",
                  "label": "Numer grupy",
                  "value": null,
                  "options": {
                    attrs: {
                      type: 'number'
                    }
                  }
                },
                {
                    "id": 107,
                    "name": "device_group_name",
                    "type": "text",
                    "label": "Nazwa grupy",
                    "value": null,
                    "options": null
                },
                {
                    "id": 108,
                    "name": "device_group_color",
                    "type": "color",
                    "label": "Kolor",
                    "value": null,
                    "options": null
                }
            ]
        }
    }
      ]
    },
  }
]

export { mergedOffer, mergedOfferGroups };