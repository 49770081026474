const repairsHeadCells = [
  { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'nazwa',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'miejscowość',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'adres',
  },
  {
    id: 'datePlan',
    numeric: false,
    disablePadding: false,
    label: 'data planowanego przeglądu',
  },
];

export default repairsHeadCells;
