import React, {useEffect, useRef} from 'react';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../../../../components/buttons/PrimaryButton';
import DynamicForm from '../../../../../../components/dynamic-forms/DynamicForm';
import { formToRequest, responseToForm } from '../../../../../../components/dynamic-forms/field-transformer';
import { getCall, putCall } from '../../../../../../components/indicator/Indicator';
import Loader from '../../../../../../components/loader/Loader';
import ColumnWrapper from '../../../../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../../../../components/wrappers/RowWrapper';
import { API_SETTINGS_CONTAINER_ROUTE } from '../../../../../../constants/routes';

const StartPriceTableForm = (({ displayed = true }) => {
    const [loading, setLoading] = React.useState(true);
    const [initialValues, setInitialValues] = React.useState({});
    const formRef = useRef();

    useEffect(( ) => {
        if(displayed) {
            fetchSettings(). then(() => setLoading(false))
        }
    }, [displayed]);

    const fetchSettings = async () => {
        try {
        const {data: {item} = {}} = await getCall(`${API_SETTINGS_CONTAINER_ROUTE}/startprices`);
        setInitialValues(responseToForm(item?.fields|| []));
        } catch {
        toast.error('Wystąpił błąd podczas pobierania ustawień.');
        }
    };

    const save = async () => {
        const body = {
            fields: formToRequest(formRef.current.getFormValues())
        };

        try {
            await putCall(`${API_SETTINGS_CONTAINER_ROUTE}/startprices`, body);
            toast.success('Zaktualizowano tabelę!');
        } catch {
            toast.error('Wystąpił błąd podczas zapisu tabeli kosztów uruchomienia.');
        }
    };

    const localFields = [
        {
            type: 'multipleFields',
            name: 'Tabela uruchomień - kontener',
            value: [],
            options: { fields: [
                {
                    "id": 76,
                    "name": "startprice_table",
                    "label": "Tabela uruchomień",
                    "type": "rowsGroup",
                    "value": [],
                    "options": {
                        "width": 12,
                        "data": [],
                        "fields": [
                            {
                                "id": 110,
                                "name": "startprice_count",
                                "type": "text",
                                "label": "Ilość urządzeń",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 111,
                                "name": "startprice_addition",
                                "type": "text",
                                "label": "Dodatek",
                                "value": null,
                                "options": null
                            }
                        ],
                    },
                },
            ]
            }
        },
    ]

    return (
        <>
        <ColumnWrapper>
            <RowWrapper>
                {loading
                    ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}><Loader/></div>
                    :
                    <ColumnWrapper>
                        <DynamicForm
                            initialValues={initialValues}
                            fields={localFields}
                            ref={formRef}
                            small={true}
                            avoidTabs={true} />
                    </ColumnWrapper>
                }
            </RowWrapper>
            <RowWrapper>
                <ColumnWrapper>
                    <PrimaryButton onClick={save}>Zapisz</PrimaryButton>
                </ColumnWrapper>
            </RowWrapper>
        </ColumnWrapper>
        </>
    );
});

export default StartPriceTableForm;
