import React from 'react';
import { useSelector } from 'react-redux';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Route } from 'react-router-dom';
import AdministrationUserDetailsScreen from '../AdministartionUsersDetailsScreen';
import AdministrationConfigurationScreeen from '../AdministrationConfigurationScreen';
import AdministrationScreen from '../AdministrationScreen';
import DictionariesScreen from '../DictionariesScreen';
import DynamicFieldsScreen from '../DynamicFieldsScreen';
import DynamicGroupsScreen from '../DynamicGroupsScreen';

const Administration = (props) => {
  const state = useStoreState((state) => state.auth?.loggedInUser);

  const isAdmin = () =>
    state?.roles.filter((role) => role).find((role) => role.toString() === 'ROLE_ADMIN');

  return (
    <>
      {isAdmin() ? (
        <>
          <Route exact path="/administration/users">
            <AdministrationScreen />
          </Route>
          <Route exact path="/administration/configuration">
            <AdministrationConfigurationScreeen />
          </Route>
          <Route exact path="/administration/configuration/dictionaries">
            <DictionariesScreen />
          </Route>
          <Route exact path="/administration/configuration/dynamic-fields">
            <DynamicFieldsScreen />
          </Route>
          <Route exact path="/administration/configuration/dynamic-groups">
            <DynamicGroupsScreen />
          </Route>
          <Route exact path="/administration/users/edit">
            <AdministrationUserDetailsScreen />
          </Route>
          <Route path="/administration/users/edit/:id">
            <AdministrationUserDetailsScreen />
          </Route>
        </>
      ) : null}
    </>
  );
};

Administration.propTypes = {};

export default Administration;
