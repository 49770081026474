import VerticalTable from "../../../../../components/tables/vertical/VerticalTable";
import React, {useCallback, useEffect, useMemo} from "react";
import TextField from "@material-ui/core/TextField";
import Select from "../../../../../components/dynamic-forms/form-components/Select";
import ActionReviewDeviceVerticalTableColumns from "./ActionReviewDeviceVerticalTableColumns";
import {responseToForm} from "../../../../../components/dynamic-forms/field-transformer";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

export default ({template, rows = [], onUpdate}) => {

    const templateHeaders = useMemo(() =>
            responseToForm(template?.fields || [])?.rows?.map(field => {
                const key = Object.keys(field)?.[0];

                return key.includes('szablon_field') ? {
                    header: field?.[key],
                    key: key + '_' + String(field?.[key]).replace(' ', '_').toLocaleLowerCase()
                } : null
            }).filter(Boolean)
        , [template]
        )
    ;

    const columns = ActionReviewDeviceVerticalTableColumns(templateHeaders);

    const TextRow = useCallback((options = {}) => (row, {key, header}) =>
            <TextField placeholder={header}
                       variant="outlined"
                       value={row?.fields?.[key] || ''}
                       onChange={({target: {value}}) => onUpdate('fields', {...row?.fields, [key]: value}, row.id)}
                       {...options}
            />
        , []);

    const SelectRow = useCallback((options = {}) => (row, {key, header}) =>
        <Select placeholder={header}
                data={options.data}
                variant="outlined"
                value={row?.fields?.[key] || ''}
                onSelect={value => onUpdate('fields', {...row?.fields, [key]: value}, row.id)}
                {...options}
        />, []);

    const PartsSelectRow = useCallback((row, column) =>
            SelectRow({
                multiple: true,
                data: row?.product?.products,
                value: (row?.fields?.[column.key] || []),
                onSelect: ids => onUpdate('fields', {
                    ...row?.fields,
                    [column.key]: ids
                }, row.id)
            })(row, column)
        , []);

    const DeleteButton = useCallback((row) =>
            <>
                {row?.product?.name || '-'}
                <IconButton
                    aria-label="delete"
                    onClick={() => onUpdate('template', null, row.id)}
                >
                    <DeleteIcon/>
                </IconButton>
            </>
        , []);


    return <>
        <VerticalTable rows={rows} columns={columns}>
            {{
                device: DeleteButton,
                ...templateHeaders?.reduce((row, {key}) => ({...row, [key]: TextRow()}), {}),
                parts_under_warranty: PartsSelectRow,
                parts_not_under_warranty: PartsSelectRow,
                description: TextRow({multiline: true, rows: 3}),
            }}
        </VerticalTable>
    </>
}