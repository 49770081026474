import React, { useEffect, useRef, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import AddEditPopup from './components/add-edit/products/AddEditPopup';
import ListToolBar from './components/list/products/ListToolBar';
import { API_PRODUCTS_ROUTE } from '../../constants/routes';

const ProductsScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const state = useStoreState((state) => state.auth?.loggedInUser);
  const [category, setCategory] = useState(null);
  const [producer, setProdcuer] = useState(null);
  const [selectedProducer, setSelectedProducer] = useState(null);
  const [newBareProduct, setNewBareProduct] = useState(false);

  const [productId, setProductId] = useState(null);

  const popupRef = useRef();

  const fetchProducts = async (url = API_PRODUCTS_ROUTE) => {
    const { data } = await getCall(url);
    if (data) {
      setCount(data?.total);
      setRows({
        initialState: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          group: i?.product_producer?.type?.name || '',
          producer: i?.product_producer?.name || '',
          producerId: i?.product_producer?.id || '',
          category: i?.product_category?.name || '',
          name: (<strong>{i?.name}</strong>),
        })),
        state: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          group: i?.product_producer?.type?.name || '',
          producer: i?.product_producer?.name || '',
          producerId: i?.product_producer?.id || '',
          category: i?.product_category?.name || '',
          name: (<strong>{i?.name}</strong>),
        })),
      });
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      let urlAddress = `${API_PRODUCTS_ROUTE}?query=${v}&order_key=name`;
      if (category && producer)
        urlAddress = `${API_PRODUCTS_ROUTE}?query=${v}&order_key=name&product_category=${category}&product_producer=${producer}`;
      else if (category)
        urlAddress = `${API_PRODUCTS_ROUTE}?query=${v}&order_key=name&product_category=${category}`;
      else if (producer)
        urlAddress = `${API_PRODUCTS_ROUTE}?query=${v}&order_key=name&product_producer=${producer}`;
      const { data } = await getCall(urlAddress);
      if (data) {
        setRows({
          ...rows,
          state: data?.items?.map((i) => ({
            identity: i?.id,
            id: i?.id,
            group: i?.product_producer?.type?.name || '',
            producer: i?.product_producer?.name || '',
            producerId: i?.product_producer?.id || '',
            category: i?.product_category?.name || '',
            name: (<strong>{i?.name}</strong>),
          })),
        });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleAddProduct = () => {
    setProductId(null);
    setNewBareProduct(true);
    popupRef.current.open();
  };

  const handleEditProduct = (id, producerId) => {
    setSelectedProducer(producerId);
    setProductId(id);
    popupRef.current.open();
  };

  const handleDelete = async (numSelected) => {
    const isAdmin = state?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (isAdmin) {
      const query = numSelected.map((item) => `products[]=${item}&`)
      const data = await deleteCall(`${API_PRODUCTS_ROUTE}?${query.join('')}`);

      if (data) {
        toast.success('Pomyślnie usunięto wybrane produkty.');
        fetchProducts();
      } else {
        toast.error('Wystąpił błąd podczas usuwania wybranych produktów.');
      }
    } else {
      toast.warn(
        'Wybrany użytkownik nie posiada uprawnień do usuwania produktów.',
      );
    }
  };

  const handleFilterAction = async (f, name) => {
    if (name === 'categories') {
      setCategory(f);
    } else if (name === 'producers') {
      setProdcuer(f);
    } else {
      fetchProducts();
    }
  };

  useEffect(() => {
    const fetchBoth = async () => {
      await fetchProducts(
        `${API_PRODUCTS_ROUTE}?product_category=${category}&product_producer=${producer}`,
      );
    };
    const fetchProducers = async () => {
      await fetchProducts(`${API_PRODUCTS_ROUTE}?product_producer=${producer}`);
    };
    const fetchCategories = async () => {
      await fetchProducts(`${API_PRODUCTS_ROUTE}?product_category=${category}`);
    };
    if (category && producer) {
      fetchBoth();
    } else if (producer) {
      fetchProducers();
    } else if (category) {
      fetchCategories();
    }
  }, [category, producer]);

  return (
    <>
      <ListToolBar
        handleSearch={handleSearch}
        onAdd={handleAddProduct}
        onFilter={handleFilterAction}
      />
      <BasicTable
        onDelete={handleDelete}
        useDialogOnDelete
        headCells={[
          { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
          { id: 'group', numeric: false, disablePadding: false, label: 'grupa' },
          { id: 'producer', numeric: false, disablePadding: false, label: 'producent' },
          { id: 'category', numeric: false, disablePadding: false, label: 'kategoria' },
          { id: 'name', numeric: false, disablePadding: false, label: 'urządzenie' },
        ]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchProducts(`${API_PRODUCTS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id, producerId }) => {
          handleEditProduct(id, producerId);
        }}
      />
      <AddEditPopup
        productId={productId}
        producer={{ id: selectedProducer }}
        ref={popupRef}
        onSave={async () => await fetchProducts(API_PRODUCTS_ROUTE)}
        newBareProduct={newBareProduct}
      />
    </>
  );
};

export default ProductsScreen;
