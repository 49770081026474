import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import { API_PRODUCTS_ROUTE, API_PROJECT_AUTOMATION_ROUTE } from '../../../../../constants/routes';
import { getCall, putCall } from "../../../../../components/indicator/Indicator";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import { formToRequest, responseToForm } from '../../../../../components/dynamic-forms/field-transformer';
import {automationForm, automationFormStatuses} from '../automationForm';
import DynamicForm from '../../../../../components/dynamic-forms/DynamicForm';
import FloatingSaveBar from '../../../../../components/FloatingSaveBar';
import NotesPanel from '../../../../../components/notes/NotePanel';
import AutomationVariants from './AutomationVariants';
import RowWrapper from '../../../../../components/wrappers/RowWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em'
  }
}));

const AutomationZone = ({automation, projectId, variants, fetchNotes, notes, open}) => {
  const classes = useStyles();
  const formRef = useRef();
  const variantsRef = useRef();
  const [initialValues, setInitialValues] = useState([]);
  const [status, setStatus] = useState('');

  const save = async () => {
    const formValues = formRef?.current?.getFormValues();

    const r = await putCall(`${API_PROJECT_AUTOMATION_ROUTE}/${projectId}`, {
      fields: formToRequest({...formValues, automation_status: status})
    });

    
    if (r && r.data) {
      toast.success(`Zapisano automatykę dla projektu ${projectId}`);
    }
  }

  React.useEffect(() => {
    const fields = responseToForm(automation?.fields || [])
    setInitialValues(fields);
    setStatus(fields?.automation_status || '')
  }, [automation]);


  const onChange = (f, v) => {
    switch (f) {
      case 'a_att_przewody': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      case 'a_att_instrkonf': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      case 'a_att_zdjszafy': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      case 'a_att_instszafy': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      case 'a_att_wizual': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      case 'a_att_projektster': return formRef?.current?.setFormValue(f+'_date', new Date()); break;
      default: break;
    }
  }
  

  return (
    <>
    <RowWrapper style={{ alignItems: 'flex-start'}}>
      <ColumnWrapper width={open ? '50%' : '100%'}>
        <div style={{maxWidth: 250}}>
          {returnSpecificField('dictionary', {data: automationFormStatuses}, 'automation_status', status, (e, v) => setStatus(v), 'Status')}
        </div>
        <ExpansionContainer title="Etap ofertowanie">
        <ColumnWrapper fullWidth style={{ overflowX: 'auto' }}>
          <AutomationVariants variants={variants} projectId={projectId} ref={variantsRef} />
        </ColumnWrapper>
      </ExpansionContainer>

      <ExpansionContainer title="Etap zamówienie">
        <ColumnWrapper fullWidth>
          <DynamicForm avoidTabs small ref={formRef} fields={automationForm} initialValues={initialValues}  onChangeMiddleware={onChange} />
        </ColumnWrapper>
      </ExpansionContainer>

      </ColumnWrapper>
      
      <ColumnWrapper width={open ? '50%' : '0%'} style={{ overflowX: (open ? 'auto' : 'hidden' ) }}>
        <NotesPanel fetchNotes={fetchNotes} data={notes} hideAdditional={true} automation_id={automation?.id} />
      </ColumnWrapper>
    </RowWrapper>
      
      <FloatingSaveBar onClick={save} />
    </>
  );
};

export default AutomationZone;
