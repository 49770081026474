import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getCall, deleteCall } from '../indicator/Indicator';
import { useStoreState } from 'easy-peasy';
import { API_NOTES_ROUTE, API_USER_PROFILE_ROUTE } from '../../constants/routes';
import { Typography, Avatar, makeStyles, TextField, IconButton, Popover } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {Business} from '@material-ui/icons';
import RowWrapper from "../wrappers/RowWrapper";
import ProjectLabel from "./ProjectLabel";
import CompanyLabel from "./CompanyLabel";
import dateFormat from "../../tools/dateFormat";
import PrimaryButton from '../buttons/PrimaryButton';
import { toast } from 'react-toastify';
import moment from 'moment';
import routerHistory from "../../constants/settings/routerHistory";

const NoteWrapper = styled(RowWrapper)`
  margin: 10px 0;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 10px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const currentDate = new Date();

const NoteItem = ({ user, id, note, projectLabel, companyLabel, date, editNote, projectId, companyId, fetchNotes, companies, ...props }) => {
  const [edit, setEdit] = useState(false);
  const [noteText, setNoteText] = useState(note)
  const [isAdmin, setIsAdmin] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const classes = useStyles();

  const state = useStoreState((state) => state.auth?.loggedInUser);

  const dateTime = moment(date).locale('pl').add(1, 'hours').format('HH:mm');

  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleEdit = (e) => {
    setNoteText(e.target.value);
  }

  const handleClick = () => {
    if(state?.username === user?.username) {
      setEdit(true);
    }
  }

  const handleDelete = async (id) => {
    if(isAdmin) {
      await deleteCall(`${API_NOTES_ROUTE}/${id}`);
      fetchNotes();
    } else {
      toast.error("Tylko Administrator może usuwać notatki");
    }
  }

  useEffect(() => {
    if(state?.roles?.includes('ROLE_ADMIN')) {
      setIsAdmin(true);
    }
  }, [state])
  
  const redirectToProject = (e, id) => {
    e.stopPropagation();
    if(id) {
      routerHistory.push(`/companies/edit/${id}`);
      setAnchor(null)
    }
  };
  
  return (
    <>
      <NoteWrapper>
          <Avatar alt="User image" src={user?.image} className={classes.small} />
          <Typography style={{ margin: '0 10px', fontSize: '11px', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          {(new Date(date)).toLocaleDateString()} {dateTime} - {user?.username}
          {companies && 
          <IconButton onClick={openPopover} style={{cursor: 'pointer'}}>
            <Business />
          </IconButton>}
          {projectLabel
            ? <ProjectLabel key={`project_l_0`} projectName={projectLabel} id={projectId} style={{ fontSize: '11px', display: 'inline-block' }} small={true} />
            : null}       
          {companyLabel
            ? <CompanyLabel key={`company_l_0`} companyName={companyLabel} id={companyId} style={{ margin: '0 10px', fontSize: '11px' , display: 'inline-block'}}  small={true} />
            : null}
          </Typography>
          <Popover
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}>
              {companies?.map((company) =>
                <RowWrapper onClick={( e) => redirectToProject(e, company.id)} style={{cursor: 'pointer'}}>
                <Typography  style={{ padding: 10}} variant="h6">{company?.name}</Typography>
                </RowWrapper>
            )}
          </Popover>
      </NoteWrapper>
      <NoteWrapper>
      <Typography style={{ fontSize: '11px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'  }}>            
        {!edit ? (
              <>
                <div onClick={handleClick} style={{ marginLeft:'10px', whiteSpace: 'pre-wrap'}}>{noteText}</div>
                <IconButton
                  onClick={() => handleDelete(id)}
                  style={{ marginLeft: '30px' }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                <TextField 
                  fullWidth 
                  multiline 
                  variant="outlined"
                  value={noteText} 
                  onChange={(e) => handleEdit(e)}
                />
                <PrimaryButton 
                  onClick={() => {
                    editNote(id, noteText);
                    setEdit(false);
                  }} 
                  style={{ marginLeft: 20 }}>
                  Zapisz
                </PrimaryButton>
              </>
            )}
            </Typography>
            </NoteWrapper>
    </>
  );
};

NoteItem.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.any]).isRequired,
  ),
  id: PropTypes.number,
  note: PropTypes.string,
  projectLabel: PropTypes.string,
  comapnyLabel: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editNote: PropTypes.func,
};

NoteItem.defaultProps = {
  note: '',
  projectLabels: [],
  comapnyLabels: [],
  date: dateFormat.formatToDDMMYYYY(currentDate),
};

export default NoteItem;
