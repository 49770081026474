import React, {useCallback, useEffect, useState} from "react";
import DetailsToolBar from "./components/templates/review/details/DetailsToolBar";
import {useParams} from "react-router-dom";
import {getCall} from "../../components/indicator/Indicator";
import {API_TEMPLATES_ROUTE} from "../../constants/routes";
import {responseToForm} from "../../components/dynamic-forms/field-transformer";
import RowWrapper from "../../components/wrappers/RowWrapper";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import BasicData from "./components/templates/review/details/BasicData";
import Loader from "../../components/loader/Loader";

export default () => {
    const {id} = useParams();
    const [editData, setEditData] = useState();
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = React.useState(!!id);

    const fetchEditData = useCallback(async () => {
        const {data: {object} = {}} = await getCall(`${API_TEMPLATES_ROUTE}/${id}`);
        return {
            ...object,
            fields: object && responseToForm(object.fields) || []
        } || [];
    }, [id]);

    useEffect(() => {
        async function fetchFields() {
            if (id && id !== 'new') {
                const editData = await fetchEditData();
                if (editData) {
                    setEditData(editData);
                    setFields(editData.fields);
                }
            }
        }

        fetchFields().then(() => setLoading(false));
    }, [id, fetchEditData]);

    return (
        <>
            {loading
                ? <div style={{height: '80vh'}}><Loader/></div>
                : <>
                    <DetailsToolBar serviceManName={editData?.name}/>
                    <RowWrapper style={{alignItems: 'flex-start'}}>
                        <ColumnWrapper width={'100%'}>
                            <BasicData
                                loading={loading}
                                data={editData}
                                fields={fields}
                            />
                        </ColumnWrapper>
                    </RowWrapper>
                </>}
        </>
    );
}