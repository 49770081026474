import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import BackButton from "../../components/buttons/back-button/BackButton";
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import DynamicForm from "../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import {serviceActionLaunch} from "./components/repairs/details/service-action-launch";
import {serviceActionReview, serviceActionReviewCd} from "./components/repairs/details/service-action-review";
import {serviceActionService} from "./components/repairs/details/service-action-service";
import {getCall} from "../../components/indicator/Indicator";
import {API_REPAIRERS_ROUTE, API_REPAIRS_ACTIONS_ROUTE} from "../../constants/routes";
import Loader from "../../components/loader/Loader";
import {toast} from "react-toastify";
import {LAUNCH, REVIEW, SERVICE} from "../../constants/RepairActionType";
import {useHistory} from "react-router-dom";
import {responseToForm} from "../../components/dynamic-forms/field-transformer";
import ActionLaunchDeviceZonePreview from "./components/repairs/details/ActionLaunchDevicesZonePreview";
import ActionServiceDevicesZonePreview from "./components/repairs/details/ActionServiceDevicesZonePreview";
import ActionReviewDevicesZonePreview from "./components/repairs/details/ActionReviewDevicesZonePreview";

export default () => {

    const [loading, setLoading] = useState(true);
    const [action, setAction] = useState({});
    const [actualType, setActualType] = useState({});
    const [dynamicFormFields, setDynamicFormFields] = useState(() => []);
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        const loadForm = async () => {
            const {type} = await fetchData();
            setActualType(type);
            await loadDynamicFormFields(type);
            setLoading(false);
            disableFields();
        };
        loadForm()
    }, [id]);

    const fetchData = async () => {
        try {
            const {data: {object = {}} = {}} = await getCall(`${API_REPAIRS_ACTIONS_ROUTE}/${id}`);
            setAction(() => object);
            return object
        } catch {
            toast.error('Wystąpił błąd podczas pobierania danych.');
        }
    };

    const fetchRepairs = async () => {
        try {
            const {data: {items = []} = {}} = await getCall(API_REPAIRERS_ROUTE);
            return items
        } catch {
            toast.error('Wystąpił błąd podczas pobierania serwisantów.');
        }
    };

    const loadDynamicFormFields = async (type) => {
        if (type === LAUNCH) {
            const options = {
                repairersOptions: await fetchRepairs() || []
            };
            setDynamicFormFields(() => serviceActionLaunch(options))
        } else if (type === SERVICE) {
            const options = {
                repairersOptions: await fetchRepairs() || []
            };
            setDynamicFormFields(() => serviceActionService(options))
        } else if (type === REVIEW) {
            setDynamicFormFields(() => [...serviceActionReview, ...serviceActionReviewCd])
        }
    };
    console.log(action)
    const disableFields = () => {
        const inputs = document.getElementsByTagName('input') || [];
        [...inputs].map(input => input.readOnly = true)
    };

    const formInitValues = useMemo(() =>
            responseToForm(action?.fields || []),
        [action?.fields]);

    const DevicesZone = useMemo(() => {
        switch (action.type) {
            case LAUNCH: {
                return ActionLaunchDeviceZonePreview
            }
            case SERVICE: {
                return ActionServiceDevicesZonePreview
            }
            case REVIEW: {
                return ActionReviewDevicesZonePreview
            }
            default: {
                return null
            }
        }
    }, [ActionLaunchDeviceZonePreview, ActionServiceDevicesZonePreview, ActionReviewDevicesZonePreview, action.type]);

    return (
        <>
            {loading
                ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}>
                    <Loader/>
                </div>
                : <>
                    <ColumnWrapper>
                        <RowWrapper>
                            <BackButton
                                text="Wróć do karty serwisowej"
                                onClick={() => history.goBack()}
                            />
                        </RowWrapper>
                        <RowWrapper>
                            <ColumnWrapper>
                                <ExpansionContainer title="Protokół przeglądowy">
                                    {
                                        actualType === REVIEW
                                            ? <>
                                                <DynamicForm fields={serviceActionReview} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                                <DevicesZone repairGroups={action.repairGroups} devices={action?.devices.map(device => ({...device, fields: responseToForm(device?.fields || [])})) || []}
                                                />
                                                <DynamicForm fields={serviceActionReviewCd} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                            </> :
                                            <>
                                                <DynamicForm fields={dynamicFormFields} small={true}
                                                             avoidTabs={true}
                                                             initialValues={formInitValues}/>
                                                <DevicesZone repairGroups={action.repairGroups} devices={action?.devices.map(device => ({...device, fields: responseToForm(device?.fields || [])})) || []}
                                                />
                                            </>
                                    }
                                </ExpansionContainer>
                            </ColumnWrapper>
                        </RowWrapper>
                    </ColumnWrapper>
                </>
            }
        </>
    );
};