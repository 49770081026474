import React from 'react';
import PropTypes from 'prop-types';
import NoteLabel from './NoteLabel';
import RowWrapper from "../wrappers/RowWrapper";
import { Typography, useTheme } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import routerHistory from "../../constants/settings/routerHistory";

const ProjectLabel = ({ projectName, id, small = false }) => {
  const theme = useTheme();

  const redirectToProject = e => {
    e.stopPropagation();
    if(id) {
      routerHistory.push(`/projects/edit/${id}`);
    }
  };
  return (
    <NoteLabel color={theme.palette.primary.dark} style={small ? {padding: '2px', fontSize: '10px', display: 'inline-block', width: 'max-content', minWidth: '60px', cursor: 'pointer'} : {}}>
      <RowWrapper onClick={redirectToProject}>
        <AssignmentIcon style={{ fontSize: small ? 13 : 14 }} />
        <Typography style={{ marginLeft: '6px', fontSize: small ? '10px' : '0.75rem' }} color="inherit" variant="body2">
          {projectName}
        </Typography>
      </RowWrapper>
    </NoteLabel>
  );
};

ProjectLabel.propTypes = {
  projectName: PropTypes.string,
  id: PropTypes.number
};

ProjectLabel.defaultProps = {
  projectName: 'Projekt',
  id: null
};

export default ProjectLabel;
