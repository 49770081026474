import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import SearchField from "../../../../components/inputs/search-field/SearchField";
import { InputWrapper, Wrapper } from "../../../../components/ListToolBarComponents";

const ListToolBar = () => {
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <SearchField />
        </InputWrapper>
        <Button
          style={{ color: 'white' }}
          variant="contained"
          onClick={() => routerHistory.push('/administration/users/edit')}
          color="primary">
          Dodaj
        </Button>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
