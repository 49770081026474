import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {API_PRODUCT_PRODUCER_ROUTE, SERVER_ROUTE} from '../../../../../constants/routes';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall
} from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import producerFields from './producerFields';

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddEditPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  const formRef = useRef();

  const { producerId, onSave } = props;

  useEffect(() => {
    if (producerId && open) {
      const fetchDetails = async () => {
        const {
          data: { item },
        } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}/${producerId}`);
        if (item) {
          setName(item?.name);
          setType(item?.type);
          setInitialValues(item?.values);
        }
      };
      fetchDetails();
    } else {
      setName('');
      setType('');
    }
  }, [producerId, open]);

  useEffect(() => {
    const fetchProducersTypes = async () => {
      const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}/types`);
      if (data) {
        setTypes(
          data?.items?.map((i) => ({
            id: i?.id,
            name: i?.name,
          })),
        );
      }
    };
    fetchProducersTypes();
  }, []);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const generatePriceList = () => {
    window.open(`${SERVER_ROUTE}`, '_blank');
  };

  const handleSave = async () => {
    try {
      const formValues = formRef.current.getFormValues();
      const fArr = Object.entries(formValues).map(([key, value]) => ({
        key,
        value,
      }));

      const addProducer = async () => {
        const r = await postCall(API_PRODUCT_PRODUCER_ROUTE, {
          name,
          type,
          fields: [...fArr],
        });
        if (r?.data && !r?.data?.error)
          toast.success('Dodano nowego producenta.');
        else toast.error('Wystąpił błąd.');
      };
      const editProducer = async () => {
        const r = await putCall(`${API_PRODUCT_PRODUCER_ROUTE}/${producerId}`, {
          id: producerId,
          name,
          type,
          fields: [...fArr],
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Pomyślnie zapisano wartości producenta - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      if (producerId) {
        await editProducer();
      } else {
        await addProducer();
      }
      await onSave();
      setOpen(false);
    } catch (error) {
      toast.error('Wystąpił błąd przy dodawaniu/edycji producenta.');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"xl"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <ColumnWrapper fullWidth>
            <Typography
              style={{ fontWeight: 'bold', margin: '0 0 20px' }}
              variant="body1">
              {producerId ? 'Edycja' : 'Dodawanie'} producenta
            </Typography>
            <TextField
              variant="outlined"
              label="Nazwa"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Select
              label="Grupa"
              data={types}
              onSelect={(f) => setType(f)}
              value={types?.find(option => option?.id === type)?.name || null}
              name="producers"
            />
          </ColumnWrapper>
        </Container>
        <Container>
          <ColumnWrapper fullWidth>
            <DynamicForm ref={formRef} fields={producerFields} initialValues={initialValues} avoidTabs={true} />
          </ColumnWrapper>
          {
            producerId !== null &&
                <PrimaryButton onClick={generatePriceList}>
                  Wygeneruj cennik
                </PrimaryButton>
          }
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton disabled={!(name && type)} onClick={handleSave}>
          Zapisz
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

AddEditPopup.propTypes = {
  fetchDocuments: PropTypes.func,
};

export default AddEditPopup;
