import React, {useEffect, useMemo, useRef, useState} from "react";
import DynamicTable from "../../../../../components/tables/basic/dynamic-table/DynamicTable";
import {deleteCall, getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCTS_ROUTE} from "../../../../../constants/routes";
import Loader from "../../../../../components/loader/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import serializeQuery from "../../../../../tools/serializeQuery";
import AddEditPopup from "../../add-edit/products/AddEditPopup";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Delete } from "@material-ui/icons";
import { toast } from "react-toastify";

export default ({additionalFilters, headerStyles, groupId, producerId}) => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({});
    const [editId, setEditId] = useState(null);
    const [producer, setProducer] = useState({});
    const popupRef = useRef();

    const handleAdd = () => {
        setEditId(null);
        popupRef.current.open();
    };

    const handleEdit = (id, producer) => {
        setEditId(id);
        setProducer(producer);
        popupRef.current.open();
    };

    const deleteProduct = async (id) => {
        const query = `products[]=${id}`
        const data = await deleteCall(`${API_PRODUCTS_ROUTE}?${query}`);

        if (data) {
            toast.success("Pomyślnie usunięto wybrany produkt.");
            fetchProducts();
        } else {
            toast.error('Wystąpił błąd podczas usuwania wybranego produktu.');
        }
    }

    const columns = [
        {header: 'Produkt', key: 'name'},
        {
            header: <div style={{display: 'flex', alignItems: 'center'}} onClick={handleAdd}>
                Akcje
                <Tooltip title="Dodaj produkt">
                    <AddIcon style={{cursor: 'pointer'}} fontSize="small"/>
                </Tooltip>
            </div>,
            key: 'actions',
            sort: false
        },
    ];

    const stringParams = useMemo(
        () => serializeQuery({...additionalFilters, ...filters}),
        [filters, additionalFilters, serializeQuery]
    );

    const fetchProducts = () =>
        getCall(`${API_PRODUCTS_ROUTE}?${stringParams}`)
            .then(({data: {items = []} = {}}) => setProducts(items));

    useEffect(() => {
        fetchProducts().then(() => setLoading(false))
    }, []);

    const reload = async () => {
        setLoading(true);
        await fetchProducts();
        setLoading(false)
    };

    const displayActions = product => <>
        <Tooltip title={`Edycja ${product.name}`} placement="top">
            <IconButton onClick={() => handleEdit(product?.id, product.product_producer)}>
                <EditIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
        <Tooltip title={`Usuń ${product.name}`} placement="top">
            <IconButton>
                <Delete onClick={() => deleteProduct(product?.id)} />
            </IconButton>
        </Tooltip>
    </>;

    return <>
        {
            loading
                ? <Loader/>
                : <>
                    <DynamicTable
                        headerStyles={headerStyles}
                        columns={columns}
                        rows={products}
                        showEmptyInfo={!products?.length}
                    >
                        {{
                            actions: displayActions,
                        }}
                    </DynamicTable>
                    <AddEditPopup
                        productId={editId}
                        producer={producer}
                        ref={popupRef}
                        categoryId={additionalFilters.category}
                        reload={reload}
                        groupId={groupId}
                        producerId={producerId}
                    />
                </>
        }
    </>
};