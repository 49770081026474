import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment, useEffect, useState } from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import returnSpecificField from '../returnSpecificField';
import {convertArrayToObject} from '../field-transformer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '10px'
  }
}));

const ProjectOrderRowsGroup = ({ fields, value, onChange, name, onChangeInner = () => {}, ...props }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);

  //todo - remove this function or edit because it's causing problems
  // React.useEffect(() => {
  //   onChange(name, JSON.stringify(rows));
  //   // eslint-disable-next-line
  // }, [rows]);

  const localOnChange = (i) => (field, value) => {
    
    if(onChangeInner) {
      onChangeInner(field, value);
    }

    setRows((prev) => {
      let localRows = prev.map(item => convertArrayToObject(item));
      localRows[i][field] = value;
      onChange(name, JSON.stringify(localRows));
      return localRows;
    });
    //onChange(localRows);
  };

  const removeRow = (i) => {    
    setRows((prev) => {
      let localRows = prev.map(item => convertArrayToObject(item));
      localRows.splice(i, 1);
      onChange(name, JSON.stringify(localRows));
      return localRows;
    });
  };

  useEffect(() => {
    try {
      if(value) {
        setRows(() => Array.isArray(value) ? value : JSON.parse(value || []));
      } else {
        setRows([]);
      }
    } catch (e) {
      setRows([]);
    }
  }, [value]);

  const variantFields = [
    {
      "id": 35,
      "name": "zam_zams_date_zamowienia",
      "type": "dateonly",
      "label": "Data zamówienia",
      "value": null,
      "options": null
    },
    {
      "id": 89,
      "name": "zam_zams_wariant",
      "type": "entity",
      "label": "Z wariantu",
      "value": [],
      "options": {
        "entityClass": "variant",
        "multiple": true
      }
    },
    {
      "id": 91,
      "name": "zam_zams_oferta",
      "type": "entity",
      "label": "Na podstawie ofert",
      "value": [],
      "options": {
        "entityClass": "offer",
        "multiple": true
      }
    },
  ];


  const renderHeader = () => (
    <TableHead>
      <TableRow>
        {[...variantFields, ...fields].map((item, i) => (
          <TableCell padding="none"  key={`item_${i}`}>
            <Fragment>
              {item.label ? (
                <Typography style={{ padding: '3px' }} variant="caption">
                  {item.label}
                </Typography>
              ) : null}
            </Fragment>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const [SelectedVariants, setSelectedVariants] = useState([]);

  const renderRow = (values, y) => (

    <TableRow>
      <TableCell style={{minWidth: '150px', padding: '15px', borderRight: '1px solid lightgray'}}>
        {returnSpecificField(
            variantFields[0].type,
            {...variantFields[0].options, index: y, attrs: { style: { padding: '0px'}, multipleIndex: y, ...(variantFields[0].options?.attrs || {})}},
            variantFields[0].name,
            values[variantFields[0].name] || '',
            localOnChange(y),
            false,
            null,
            false,
            onChangeInner,
            props?.values,
            props?.addProps
        )}
      </TableCell>
      <TableCell style={{minWidth: '150px', padding: '15px', borderRight: '1px solid lightgray'}}>
        {returnSpecificField(
            variantFields[1].type,
            {...variantFields[1].options, index: y, attrs: { style: { padding: '0px'}, multipleIndex: y, ...(variantFields[1].options?.attrs || {})}},
            variantFields[1].name,
            values[variantFields[1].name] || '',
            (f, v) => {
              localOnChange(y)(variantFields[2].name,null);
              localOnChange(y)(f,v.length ? v : null); setSelectedVariants(current => {current.splice(y, 0, v.length ? v : null); return current})
            },
            false,
            null,
            false,
            onChangeInner,
            props?.values,
            props?.addProps
        )}
      </TableCell>
      <TableCell style={{minWidth: '150px', padding: '15px', borderRight: '1px solid lightgray'}}>
      {returnSpecificField(
            variantFields[2].type,
            {disabled: !SelectedVariants[y], additionalFilters: {variant: SelectedVariants[y]}, ...variantFields[2].options, index: y, attrs: { style: { padding: '0px'}, multipleIndex: y, ...(variantFields[2].options?.attrs || {})}},
            variantFields[2].name,
            values[variantFields[2].name] || '',
            localOnChange(y),
            false,
            null,
            false,
            onChangeInner,
            props?.values,
            props?.addProps
        )}
      </TableCell>

      {fields.map((item, i) => (
        <TableCell key={`item_${y}_${i}`} style={{minWidth: '150px', padding: '15px', borderRight: '1px solid lightgray'}}>
          {returnSpecificField(
            item.type,
            {...item.options, index: y, attrs: { style: { padding: '0px'}, multipleIndex: y, ...(item.options?.attrs || {})}},
            item.name,
            values[item.name] || '',
            localOnChange(y),
            false,
            null,
            false,
            onChangeInner,
            props?.values,
            props?.addProps
          )}
        </TableCell>
      ))}
      <TableCell padding="none" style={{ paddingLeft: '10px'}}>
        <Button variant="contained" size="small" onClick={(e) => removeRow(y)}>
          <DeleteIcon fontSize="small" />
        </Button>
      </TableCell>
    </TableRow>
  );

  return (
    <ColumnWrapper fullWidth style={{ overflowX: 'auto' }}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
        aria-label="enhanced table">
        {renderHeader()}
        <TableBody>
          {rows.map((item, i) => (
            <Fragment key={`item_${i}`}>{renderRow(item, i)}</Fragment>
          ))}
          {!props.disableAdd && <TableRow>
            <TableCell padding="none" colSpan="100%">
              <Button className={classes.add} size="small" variant="contained" onClick={(e) => setRows([...rows, {}])}>
                <AddIcon fontSize="small" />
              </Button>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </ColumnWrapper>
  );
};

export default ProjectOrderRowsGroup;
