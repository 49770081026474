import fetchIntercept from 'fetch-intercept';
import routerHistory from "../../constants/settings/routerHistory";
import authStore from '../../store/auth.store';
import uiStore from '../../store/ui.store';
import store from '../../store/store';
const { toast } = require('react-toastify');


const initialSettings = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const call = async (
  dispatch,
  actions,
  url,
  method,
  headers,
  data,
  jsonResponse = true,
  formData = false,
) => {
  const token = localStorage.getItem('loginToken');

  const { setIsAuthenticated } = actions.auth;
  const { setIndicatorVisible } = actions.ui;

  const unregister = fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      dispatch(setIndicatorVisible(true));
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      dispatch(setIndicatorVisible(true));
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      dispatch(setIndicatorVisible(false));
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      dispatch(setIndicatorVisible(false));
      return Promise.reject(error);
    },
  });
  try {
    let res = null;
    if (method.toUpperCase() !== 'GET') {
      res = await fetch(url, {
        method: method,
        headers: {
          ...headers,
          'X-Authorization': `Bearer ${token}`,
        },
        body: formData
          ? data
          : JSON.stringify({
            ...data,
          }),
      });
    } else {
      res = await fetch(url, {
        method,
        headers: {
          ...headers,
          'X-Authorization': `Bearer ${token}`,
        },
      });
    }
    if (jsonResponse) {
      const receivedData = await res.json();
      if (receivedData?.status === 500) {
        throw Error(receivedData?.detail);
      }
      if (
        receivedData.message === 'Expired JWT Token' &&
        receivedData.code === 401 &&
        routerHistory.location.pathname !== '/'
      ) {
        toast.warn('Sesja została zakończona, token autoryzujący wygasł.');
        dispatch(setIsAuthenticated(false));
        localStorage.removeItem('loginToken');
        routerHistory.push('/');
      }
      if (receivedData.code === 403) {
        toast.warn('Użtykownik nie jest autoryzowany do wybranej akcji.');
      }
      unregister();
      return { data: receivedData, status: 'ok' };
    }
    const receivedData = await res.blob();
    unregister();
    return {
      status: 'ok',
      data: receivedData,
    };
  } catch (error) {
    console.error(error);
    toast.error(`Błąd podczas łączenia się z API ${error}`);
    unregister();
    return { data: null, status: 'error' };
  }
};

const getCall = async (
  dispatch,
  actions,
  url,
  headers = initialSettings,
  jsonResponse,
) => await call(dispatch, actions, url, 'GET', headers, undefined, jsonResponse);

const postCall = async (
  dispatch,
  actions,
  url,
  data,
  headers = initialSettings,
  jsonResponse,
) => await call(dispatch, actions, url, 'POST', headers, data, jsonResponse);

const putCall = async (
  dispatch,
  actions,
  url,
  data,
  headers = initialSettings,
  jsonResponse,
) => await call(dispatch, actions, url, 'PUT', headers, data, jsonResponse);

const deleteCall = async (
  dispatch,
  actions,
  url,
  data,
  headers = initialSettings,
  jsonResponse,
) => await call(dispatch, actions, url, 'DELETE', headers, data, jsonResponse);

const postFileCall = async (
  dispatch,
  actions,
  url,
  data,
  headers = initialSettings,
  jsonResponse,
  formData = true,
) => await call(dispatch, actions, url, 'POST', headers, data, jsonResponse, formData);

const putFileCall = async (
  dispatch,
  actions,
  url,
  data,
  headers = initialSettings,
  jsonResponse,
  formData = true,
) => await call(dispatch, actions, url, 'PUT', headers, data, jsonResponse, formData);

const apiCall = {
  getCall,
  postCall,
  putCall,
  deleteCall,
  postFileCall,
  putFileCall,
};

export default apiCall;
