import { OFFER_TYPES, STATUS_TYPES } from '../../../../constants/types/offerTypes';

const commonOffer = [
{
    type: 'multipleFields',
    name: 'Ustawienia oferty',
    value: [],
    options: { fields: [
        { name: 'offerNumber', label: "Numer oferty", type:'text', value: '', options: { width: 4}},
        { name: 'type', label: "Typ", type:'dictionary', value: '', options: { data: OFFER_TYPES, width: 4 } },
        { name: 'owner', label: "Sporządził ofertę", type:'entity', value: '',  options: { entityClass: "user", width: 4} },
        { name: 'company', label: "Firma", type:'entity', value: '',  options: { entityClass: "company", width: 4} },
        { name: 'project', type:'entity', label: "Projekt", options: { entityClass: "project", width: 4}, value: '', },
        { name: 'projectVariant', type:'entity', label: "Wariant", options: { entityClass: "variant", width: 4, attrs: { forceParamId: "project"}}, value: '', }
    ]}
},
];


export { commonOffer };