import {
    Button, InputLabel,
    makeStyles,
    TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import returnSpecificField from '../returnSpecificField';
import {convertArrayToObject} from '../field-transformer';
import {InputWrapper} from "../../FormComponents";
import DynamicThermalFieldsAddModal from "./DynamicThermalFieldsAddModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    rowObject: {
        cursor: 'pointer',
    },
    add: {
        marginTop: '10px'
    }
}));

const DynamicThermalFields = ({ fields, value, onChange, name, onChangeInner = () => {}, ...props }) => {
    const classes = useStyles();

    const [isOpenModal, setOpenModal] = useState(false);
    const [rows, setRows] = useState([]);


    const isPreview = useMemo(() => window.location.pathname?.includes('/preview'), [window.location.pathname])

    const localOnChange = (i) => (field, value) => {

        if(onChangeInner) {
            onChangeInner(field, value);
        }

        setRows((prev) => {
            let localRows = prev.map(item => convertArrayToObject(item));
            localRows[i][field] = value;
            onChange(name, JSON.stringify(localRows));
            return localRows;
        });
        //onChange(localRows);
    };

    const removeRow = (i) => {
        setRows((prev) => {
            let localRows = prev.map(item => convertArrayToObject(item));
            localRows.splice(i, 1);
            onChange(name, JSON.stringify(localRows));
            return localRows;
        });
    };

    useEffect(() => {
        try {
            if(value) {
                setRows(() => Array.isArray(value) ? value : JSON.parse(value || []));
            } else {
                setRows([]);
            }
        } catch (e) {
            setRows([]);
        }
    }, [value]);

    const renderRow = (values, y) => (
        <div>
            <div style={{display: "flex", alignItems: "center"}}>
                {fields.map((item, i) => (
                    <InputWrapper key={`item_${y}_${i}`} style={{width: "20%", marginRight: 10, padding: 0, display: "block"}}>
                        {(item?.label || i === 0 ? (
                            <InputLabel style={{ marginTop: 10 }} shrink htmlFor={item?.name}>
                                { i === 0 ? values?.thermal_name : item?.label}
                            </InputLabel>
                        ) : null)}
                        {returnSpecificField(
                            item.type,
                            {...item.options, index: y, attrs: item.options?.attrs || {}},
                            item.name,
                            values[item.name] || '',
                            localOnChange(y),
                            false,
                            null,
                            false,
                            onChangeInner,
                            props?.values,
                            props?.addProps
                        )}
                    </InputWrapper>

                ))}
                {!isPreview &&
                <Button variant="contained" size="small" style={{height: "fit-content", marginTop: 25}}
                        onClick={(e) => removeRow(y)}>
                    <DeleteIcon fontSize="small"/>
                </Button>
                }
            </div>
        </div>
    );

    return (
        <ColumnWrapper style={{ overflowX: 'auto' }}>
            <div
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table">
                <div>
                    {rows.map((item, i) => (
                        <Fragment key={`item_${i}`}>{renderRow(item, i)}</Fragment>
                    ))}
                    {!isPreview &&
                            <Button className={classes.add} size="small" variant="contained" onClick={(e) => setOpenModal(true)}>
                                <AddIcon fontSize="small" />
                            </Button>
                        }
                </div>
            </div>
            <DynamicThermalFieldsAddModal open={isOpenModal} onClose={() => setOpenModal(false)} onSubmit={thermal_name => {setRows([...rows, {thermal_name}]); setOpenModal(false)}} />
        </ColumnWrapper>
    );
};

export default DynamicThermalFields;
