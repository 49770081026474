import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  max-height: 50px;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  margin-right: 20px;
  display: flex;
`;

export { Wrapper, InputWrapper };