import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import routerHistory from "../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../components/buttons/back-button/BackButton";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { InputLabel, InputWrapper } from "../../../../../components/FormComponents";
import ActiveStatus from '../../list/ActiveStatus';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const VariantToolBar = () => {
  const { id, vid, type } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper>
          <BackButton
            text={type ==='aut' ? 'Wróć do automatyki projektu' : "Wróć do projektu"}
            onClick={() => routerHistory.push(type ==='aut' ? `/projects/${id}/automation` : `/projects/edit/${id}`)}
          />
          <ColumnWrapper>
            <Typography>Projekt: {id}</Typography>
            <ActiveStatus />
          </ColumnWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default VariantToolBar;
