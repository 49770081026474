import React, {useEffect, useMemo, useState, useRef} from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { getCall } from "../../../../../../components/indicator/Indicator";
import { API_PRODUCTS_ROUTE, API_PROJECT_VARIANTS_ROUTE } from '../../../../../../constants/routes';
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import returnSpecificField from "../../../../../../components/dynamic-forms/returnSpecificField";
import truncate from "../../../../../../tools/truncate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em',
    marginBottom: '1em'
  }
}));

const OfferDevicesDetailedDevicesZone = ({vid, offer, setRootRows, rounded, setOfferCurrency}) => {
  const classes = useStyles();
  
  const [loading, setLoading] = React.useState(true);
  const [variant, setVariant] = React.useState({});
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [rows, setRows] = useState([]);
  const prevGuaranteeRatio = useRef()
  const prevCurrencyRatio = useRef()
  const changedRow = useRef()
  const changedCurrencyRatioRow = useRef()

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };

  const getUniqueCategories = (data) => {
    const categories = data.map(item => item.product_category)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setCategories(categories);
  }

  const getUniqueProducers = (data) => {
    const producers = data.map(item => item.product_producer)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setProducers(producers);
  }

  const selectedProducts = useMemo(() =>
          rows.map(({product} = {}) => product || {})
      ,[rows]);

  const availableProducts = useMemo(() =>
          products.filter(({id}) => !selectedProducts.find(({id: searchesId}) => searchesId === id))
      ,[products, selectedProducts]);

  const relatedProducts = useMemo(() => {
      const allRelated = selectedProducts
          .reduce((all, {products: parts = []}) =>
                  [...all || [], ...parts]
              , [])
          .filter(({id}) =>
              ![...selectedProducts].find(({id: searchesId}) => searchesId === id)
          );

      return [...new Set(allRelated.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
  }, [selectedProducts]);



  const fetchVariant = async () => {
    try {
      const {data: {item: variant} = {}} = await getCall(`${API_PROJECT_VARIANTS_ROUTE}/${vid}`);
      setVariant(() => variant);
    } catch {
      toast.error('Wystąpił błąd podczas pobierania wariantu.');
    }
  };

  const fetchDevices = async () => {

    try {
      const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
      if (data) {
        setProducts(data.items);
        getUniqueCategories(data.items);
        getUniqueProducers(data.items);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
    }

  };

  useEffect(() => {
    fetchDevices().then(() => setLoading(false));
    
    setRows(offer.devices?.map(row => ({
      ...row,
      uuid: row?.id,
      producer: row?.product?.product_producer,
      category: row?.product?.product_category,
      currentBasePrice: row?.currentBasePrice ? row?.currentBasePrice : Number(row?.product?.net_list_price).toFixed(2),
      currentBasePriceCurrency: row?.currentBasePriceCurrency ? row?.currentBasePriceCurrency : row?.product.currency ? row?.product.currency : null

        })) || []);
  }, []);

  useEffect(() => {
    setRootRows(rows)
  }, [rows]);

  useEffect(() => {
    if (rows.length > 0) {
      const newRows = []
      rows.map(item => {
        const priceWithGuarantee = parseFloat(item.guaranteeRatio * item.currentBasePrice).toFixed(rounded ? 2 : 0);
        const priceAfterCurrencyMath = parseFloat(priceWithGuarantee * item.currencyRatio).toFixed(rounded ? 2 : 0);
        const shopPriceSingle =  item.currencyRatio == 0 
        ? parseFloat(priceWithGuarantee * (1- item.discount / 100)).toFixed(rounded ? 2 : 0)
        : parseFloat(priceAfterCurrencyMath * (1- item.discount / 100)).toFixed(rounded ? 2 : 0);
        const shopPrice = parseFloat(shopPriceSingle * item.amount).toFixed(rounded ? 2 : 0);

        newRows.push(
          {...item, 
          priceWithGuarantee,
          priceAfterCurrencyMath,
          shopPriceSingle,
          shopPrice,
        })
      })
      setRows(newRows)
    }
  }, [rounded, loading])

  const calculateRow = (rows, i) => {
    let localRows = [...rows];

    if(localRows[i]['currentBasePriceCurrency'] == localRows[i]['resultCurrency'] ){
      localRows[i]['currencyRatio'] = 1
    }
    if(localRows[i]['currentBasePriceCurrency'] != localRows[i]['resultCurrency'] && localRows[i]['currencyRatio'] === 1){
      const displayedCurrencyValue = prevCurrencyRatio.current ? prevCurrencyRatio.current : null
      localRows[i]['currencyRatio'] = displayedCurrencyValue
    }
    localRows[i]['priceWithGuarantee'] =  localRows[i]['guaranteeRatio'] * localRows[i]['currentBasePrice'];
    localRows[i]['priceWithGuarantee'] = localRows[i]['priceWithGuarantee'].toFixed(rounded ? 2 : 0)

    localRows[i]['priceAfterCurrencyMath'] = localRows[i]['currencyRatio'] * localRows[i]['priceWithGuarantee'];
    localRows[i]['priceAfterCurrencyMath'] = localRows[i]['priceAfterCurrencyMath'].toFixed(rounded ? 2 : 0)

    localRows[i]['shopPriceSingle'] = (localRows[i]['currencyRatio'] == 0)
        ? localRows[i]['priceWithGuarantee'] * (1 - localRows[i]['discount'] / 100)
        : localRows[i]['priceAfterCurrencyMath'] * (1 - localRows[i]['discount'] / 100);
    localRows[i]['shopPriceSingle'] = localRows[i]['shopPriceSingle'].toFixed(rounded ? 2 : 0)   
      
    localRows[i]['shopPrice'] = (localRows[i]['shopPriceSingle']) * (localRows[i]['amount'])
    localRows[i]['shopPrice'] = localRows[i]['shopPrice'].toFixed(rounded ? 2 : 0)

    return localRows
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    v = v.replace(/,/g, '.');
    if(i >= 0) {
      localRows[i][name] = v;
    }

    if(['guaranteeRatio'].includes(name)) {
      localRows.map((localRow, localIndex) =>{        
        if (!localRow['guaranteeRatio'] || (localRow['guaranteeRatio'] === prevGuaranteeRatio.current && changedRow.current === localIndex))
        {
          localRow['guaranteeRatio'] = localRows[i]['guaranteeRatio']
          prevGuaranteeRatio.current = localRows[i]['guaranteeRatio']
          localRows = calculateRow(localRows, localIndex)

          if (changedRow.current === null || changedRow.current === undefined )
            changedRow.current = localIndex
        }
        
      })
    }
    if(['currencyRatio'].includes(name)) {
      localRows.map((localRow, localIndex) =>{        
        if (!localRow['currencyRatio'] || (localRow['currencyRatio'] === prevCurrencyRatio.current && changedCurrencyRatioRow.current === localIndex))
        {
          localRow['currencyRatio'] = localRows[i]['currencyRatio']
          prevCurrencyRatio.current = localRows[i]['currencyRatio']
          localRows = calculateRow(localRows, localIndex)

          if (changedCurrencyRatioRow.current === null || changedCurrencyRatioRow.current === undefined )
          changedCurrencyRatioRow.current = localIndex
        }
        
      })
    }

    if(['resultCurrency'].includes(name)) {
      localRows.map((localRow, localIndex) =>{
          localRow['resultCurrency'] = localRows[i]['resultCurrency']
          localRows = calculateRow(localRows, localIndex)
            
          if (localRows[i]['resultCurrency'] === 'pln')
            setOfferCurrency(" zł")

          if (localRows[i]['resultCurrency'] === 'eur')
            setOfferCurrency(' €')
        })
    }

    if(['discount', 'currencyRatio', 'guaranteeRatio', 'currentBasePriceCurrency', 'resultCurrency'].includes(name)) {
      localRows = calculateRow(localRows, i)
    }
    setRows(localRows);
  };

  const calculateRowTotalPrice = ({product: {net_list_price = 0} = {}, amount}) => {
    if(net_list_price) {
      const price = Number(net_list_price) * Number(amount);
      return Number((price).toFixed(2)) || 0;
    }
    return 0;
  }

  const renderHeader = () => (
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2}>Producent</TableCell>
          <TableCell rowSpan={2}>Kategoria</TableCell>
          <TableCell rowSpan={2}>Urządzenie</TableCell>
          <TableCell rowSpan={2}>Ilość</TableCell>
          <TableCell rowSpan={2}>Indeks</TableCell>
          <TableCell rowSpan={2}>Opis</TableCell>
          <TableCell colSpan={8}>Cena katalogowa</TableCell>
          <TableCell rowSpan={2}>Rabat %</TableCell>
          <TableCell rowSpan={2}>Cena zakupowa jednostkowa</TableCell>
          <TableCell rowSpan={2}>Cena zakupowa</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cena poodstawowa</TableCell>
          <TableCell>Waluta</TableCell>
          <TableCell>Mnożnik gwarancyjny</TableCell>
          <TableCell>Cena po uwzg. gwarancji</TableCell>
          <TableCell>Waluta</TableCell>
          <TableCell>Kurs przewalutowania</TableCell>
          <TableCell>Waluta oferty</TableCell>
          <TableCell>Cena po przewalutowaniu</TableCell>
        </TableRow>
      </TableHead>
  );

  return (
      <>
        <ColumnWrapper fullWidth style={{overflowX: 'auto'}}>
          <h3 style={{marginBottom: '30px'}}>Urządzenia</h3>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table">      
            {renderHeader()}
            <TableBody>
              {!loading && rows.map(item =>
                  <OfferDevicesDetailedDevicesZoneRow
                      i={item}
                      index={item.uuid}
                      data={{
                          producers,
                          categories,
                          products: item?.product?.id ? [...availableProducts, item.product] : availableProducts
                      }}
                      key={item.uuid}
                      editRow={editRow}
                      removeRow={removeRow} />)}
              <TableRow>
                <TableCell colSpan="16"/>
                <TableCell colSpan="1">
                  Suma: {rows.reduce((n, {shopPrice}) => n + Number(shopPrice), 0).toFixed(rounded ? 2 : 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ColumnWrapper>
      </>
  );
};

const OfferDevicesDetailedDevicesZoneRow = ({i, index, data, editRow, removeRow, ...props}) => {
  const [localProducer, setLocalProducer] = useState(i.producer || null);
  const [localCat, setLocalCat] = useState(i.category || null);
  const [localProducts, setLocalProducts] = useState([]);

  const adjustProducts = () => {
    setLocalProducts(data.products.filter(item => item.product_category.id == localCat && item.product_producer.id == localProducer));
  };

  const findProduct = searchesId => localProducts
      ? localProducts.find(({id}) => id === searchesId)
      : null;

  useEffect(() => {
    adjustProducts()
  }, [localProducer, localCat, i]);

  return (
      <TableRow key={i.uuid+'row'}>
        <TableCell>
          {i.producer?.name}
        </TableCell>
        <TableCell>
          {i.category?.name}
        </TableCell>
        <TableCell>
          {i.product?.name}
        </TableCell>
        <TableCell>
          {i.amount || '-'}
        </TableCell>
        <TableCell>
            {(i.product?.fields || []).find(({name})=> name === 'index')?.value || '-'}
        </TableCell>
        <TableCell>
          {i.product?.description || '-'}
        </TableCell>
        <TableCell>
          {returnSpecificField('inputWithComas', {}, 'currentBasePrice', i.currentBasePrice, (e, v) => {editRow(e, v, index)} )}
        </TableCell>
        <TableCell>
          
        {returnSpecificField('dictionary', { data: [{id: 'pln', name: 'pln'}, {id:'eur', name: 'eur'}], disabled: i.currentBasePriceCurrency}, 'currentBasePriceCurrency', i.currentBasePriceCurrency, (e, v) => {editRow(e, v, index)} )}
        </TableCell>
        <TableCell>
        {returnSpecificField('inputWithComas', {}, 'guaranteeRatio', i.guaranteeRatio, (e, v) => {editRow(e, v, index)} )}
        </TableCell>
        <TableCell>
        {i.priceWithGuarantee.toString().replace('.' , ',')}
        </TableCell>
        <TableCell>
        {returnSpecificField('dictionary', { data: [{id: 'pln', name: 'pln'}, {id:'eur', name: 'eur'}], disabled: i.currentBasePriceCurrency}, 'currentBasePriceCurrency', i.currentBasePriceCurrency, (e, v) => {editRow(e, v, index)} )} 
        </TableCell>
        <TableCell>
        {returnSpecificField('inputWithComas', {disabled: i.currentBasePriceCurrency === i.resultCurrency}, 'currencyRatio', i.currencyRatio, (e, v) => {editRow(e, v, index)} )} 
        </TableCell>
        <TableCell>
        {returnSpecificField('dictionary', { data: [{id: 'pln', name: 'pln'}, {id:'eur', name: 'eur'}]}, 'resultCurrency', i.resultCurrency, (e, v) => {editRow(e, v, index)} )} 
        </TableCell>
        <TableCell>
        {i.priceAfterCurrencyMath.toString().replace('.' , ',')}
        </TableCell>
        <TableCell>
          
        {returnSpecificField('inputWithComas', {}, 'discount', i.discount, (e, v) => {editRow(e, v, index)} )} 
        </TableCell>
        <TableCell>
        {i.shopPriceSingle.toString().replace('.' , ',')}</TableCell>
        <TableCell>
        {i.shopPrice.toString().replace('.' , ',')}</TableCell>
      </TableRow>
  );
}

export default OfferDevicesDetailedDevicesZone;
