
const devicesOffer = [
  {
    type: 'multipleFields',
    name: 'Informacje do oferty',
    value: [],
    options: { fields: [
      
      { label: 'Uwzględnij wizualizację w ofercie', name: 'uwzględnij wizualizację w ofercie', type: 'checkbox', value: '', },
      
      { label: 'Uwzględnij rozszerzony opis', name: 'Uwzględnij rozszerzony opis', type: 'checkbox', value: '', },

      { label: 'Zaokrąglij do 2 miejsca po przecinku', name: 'roundTo2DecimalPlaces', type: 'checkbox', value: '', },

        { name: 'Informacje do oferty', type: 'header', fullWidth: true, value: '', },
                
        { label: 'Waluta', name: 'Waluta', type: 'dictionary', options: { 
          disabled: true,
          data: [
            {id: ' zł', name: 'PLN'},
            {id: ' €', name: 'EUR'},
          ]
        }, value: ' zł', },
        
        { label: 'Ważność oferty', name: 'Ważność oferty', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Ważność oferty: 30 dni.' }, value: true, },
        
      { label: 'Czas realizacji zamówienia', name: 'Czas realizacji zamówienia', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: '45 dni roboczych' }, value: true, },
      /*{ label: 'Warunki płatności - wybór', name: 'Warunki płatności - wybór', type: 'dictionary', options: { data: [
        {id: 0, name: 'Płatność: 100% przedpłaty w dniu złożenia zamówienia.'},
        {id: 1, name: 'Płatność zgodnie z zawartą umową handlową: 30 dni dostawie.'},
        {id: 2, name: 'Płatność zgodnie z zawartą umową handlową: 100% przedpłaty w dniu złożenia zamówienia.'},
        {id: 3, name: 'Płatność zgodnie z zawartą umową handlową: 50% przedpłaty w dniu złożenia zamówienia, pozostałe 50% 30 dni po dostawie.'},
        {id: 4, name: 'Własne warunki'},
      ] }, value: '', },*/
      { label: 'Warunki płatności', name: 'Warunki płatności', type: 'autocompleteWithOptions', fullWidth: true, options: { data: [
        'Płatność: 100% przedpłaty w dniu złożenia zamówienia.',
        'Płatność zgodnie z zawartą umową handlową: 30 dni po dostawie.',
        'Płatność zgodnie z zawartą umową handlową: 100% przedpłaty w dniu złożenia zamówienia.',
        'Płatność zgodnie z zawartą umową handlową: 50% przedpłaty w dniu złożenia zamówienia, pozostałe 50% 30 dni po dostawie.',
        'Warunki płatności do ustalenia.',
      ] }, value: '', },

      /*{ label: 'Warunki płatności', name: 'Warunki płatności', type: 'multilineInput', fullWidth: true, value: 'Warunki płatności:\r\n1. Przedpłata w wysokości 100 % łącznej ceny automatyki na podstawie faktury proforma w dniu złożenia zamówienia. Po zaksięgowaniu wpłaty zostanie wystawiona faktura zaliczkowa cząstkowa odnosząca się do danej płatności.\r\n2. Po dostarczeniu szafy automatyki Zleceniobiorca zobowiązuje się do wystawienia i przesłania Zamawiającemu faktury zaliczkowej końcowej uwzględniającej rozliczenie płatności wymienionej w punkcie powyżej.', }, */
      /*{ label: 'Dostawa - wybór', name: 'Dostawa - wybór', type: 'dictionary', options: { data: [
        {id: 0, name: 'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie urządzenia.'},
        {id: 1, name: 'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie (to możliwość wpisania)'},
        {id: 2, name: 'Własne warunki'},
      ] }, value: '', },*/
      // { label: 'Dostawa', name: 'Dostawa', type: 'multilineInput', fullWidth: true, value: 'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie (to możliwość wpisania)', },
      { label: 'Dostawa', name: 'Dostawa', type: 'autocompleteWithOptions', fullWidth: true, options: { data: [
        'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie urządzenia.',
        'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie (tu możliwość wpisania).',
        'Dostawa bez rozładunku bezpośrednio od producenta na terenie Polski w cenie urządzenia dla minimum logistycznego : 7000 zł netto.',
      ] }, value: '', },
      
      { label: 'Okres gwarancji (ilość miesięcy)', name: 'gwarancjaLiczba', type: 'warranty', fullWidth: true },
      { name: 'Okres gwarancji - tekst', type: 'multilineInput', fullWidth: true, value: '', },

      { label: 'Uruchomienie przez autoryzowany serwis', name: 'Uruchomienie przez autoryzowany serwis', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Obowiązkowe uruchomienie urządzenia przez autoryzowany serwis' }, value: true, },
      
      { label: 'Rozruch urządzeń przez ASR', name: 'Rozruch urządzeń przez ASR', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Rozruch urządzeń przez ASR (Autoryzowany Serwis Regionalny): zł netto + dojazd (1,2 zł/km netto liczone w obie strony)' }, value: true, },
      { label: 'Szczegóły w załączonej dokumentacji', name: 'Szczegóły w załączonej dokumentacji', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Szczegóły w załączonej dokumentacji' }, value: true, },
      { label: 'Wycena nie obejmuje automatyki sterującej', name: 'Wycena nie obejmuje automatyki sterującej', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Wycena nie obejmuje automatyki sterującej, czujników temperatury, okablowania, sterowników.' }, value: true, },
      { label: 'Wycena nie obejmuje kosztów okablowania', name: 'Wycena nie obejmuje kosztów okablowania', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Wycena nie obejmuje kosztów okablowania' }, value: true, },
      { label: 'Wymagany przewód komunikacyjny', name: 'Wymagany przewód komunikacyjny', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Przy zastosowaniu trzech lub więcej urządzeń Robur wymagany jest przewód komunikacyjny ROBUR NETBUS, do położenia instalacji między zestawami urządzeń a miejscem instalacji szafy automatyki (cena netto: 7 EUR za mb).' }, value: true, },

      
      { label: 'Dodatkowe punkty (automatyczna numeracja - rozpocznij punkt od nowej linii)', name: 'Dodatkowe punkty', type: 'multilineInput', fullWidth: true, value: '', },

      
      ]
    },
  },
];


export { devicesOffer };