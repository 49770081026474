import {
  AppBar,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import RowWrapper from '../wrappers/RowWrapper';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Wrapper = styled.div`
  max-width: ${(props) =>
    props?.small ? 'calc(50vw - 100px - 18px)' : 'calc(100vw - 200px - 36px)'};
  padding: 0 10px;
`;

const MultiTabs = ({ tabs, loadngLabel, small }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <>
      {tabs?.length ? (
        <Wrapper>
          <div
            style={{
              background: theme.palette.background.paper,
            }}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              style={{background: '#dbdbdb'}}
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="Tabs">
              {tabs.map(({ label, tabId }, i) => (
                <Tab
                  key={label}
                  label={label}
                  id={tabId || { ...a11yProps(i) }}
                />
              ))}
            </Tabs>
            {tabs.map(({ children, label }, i) => (
              <TabPanel key={label} value={value} index={i}>
                {children}
              </TabPanel>
            ))}
          </div>
        </Wrapper>
      ) : (
        <RowWrapper style={{ justifyContent: 'center' }} fullWidth>
          <CircularProgress size={24} />
          <Typography style={{ marginLeft: 10 }} variant="body2">
            {loadngLabel ? loadngLabel : 'Wczytywanie danych ...'}
          </Typography>
        </RowWrapper>
      )}
    </>
  );
};

MultiTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node,
      label: PropTypes.string,
      tabId: PropTypes.string,
    }),
  ).isRequired,
  loadngLabel: PropTypes.string,
  small: PropTypes.bool,
};

export default MultiTabs;
