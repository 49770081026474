import commonValues from "../../../../../tools/commonValues";

const repairersFields = [
    {
        "id": 9,
        "name": "Karta serwisanta",
        "type": "multipleFields",
        "options": {
            "fields": [
                
                {
                    "id": 42,
                    "name": "serwisant_name",
                    "type": "text",
                    "label": "Nazwa serwisu",
                    "value": null,
                    "options": { width: 4 }
                },
                {
                    "id": 42,
                    "name": "serwisant_nip",
                    "type": "text",
                    "label": "NIP",
                    "value": null,
                    "options": { width: 8 }
                },
                {
                    "id": 32,
                    "name": "serwisant_adres",
                    "type": "text",
                    "label": "Adres",
                    "value": null,
                    "options": { width: 4 }
                },
                {
                    "id": 33,
                    "name": "serwisant_city",
                    "type": "text",
                    "label": "Miasto",
                    "value": null,
                    "options": { width: 4 }
                },
                {
                    "id": 34,
                    "name": "serwisant_voivodeship",
                    "type": "dictionary",
                    "label": "Województwo",
                    "value": null,
                    "options": {
                        "id": 1,
                        "width": 4,
                        "data": commonValues.voivodeships,
                        "fields": [],
                        "name": "województwo"
                    },
                },
                {
                    "id": 43,
                    "name": "serwisant_tel",
                    "type": "text",
                    "label": "Telefon",
                    "value": null,
                    "options": { width: 4 }
                },
                {
                    "id": 44,
                    "name": "serwisant_mail",
                    "type": "text",
                    "label": "E-mail",
                    "value": null,
                    "options": { width: 4 }
                },
                {
                    "id": 46,
                    "name": "serwisant_adres_wy_cz",
                    "type": "text",
                    "label": "Adres wysyłki części",
                    "value": null,
                    "options": { width: 7 }
                },
                {
                    "id": 46,
                    "name": "serwisant_adres_wy_cz_copy",
                    "type": "button",
                    "value": null,
                    "options": { text: "Kopiuj", width: 1 }
                },
            ]
        }
    },
    {
        "id": 4,
        "name": "Kontakt",
        "type": "multipleFields",
        "options": {
          "fields": [
            {
              "id": 9,
              "name": "serwisant_kontakt",
              "type": "rowsGroup",
              "label": "Lista kontaktów",
              "value": "[]",
              "options": {
                "id": 3,
                "data": [],
                "fields": [
                  {
                    "id": 10,
                    "name": "osoba",
                    "type": "text",
                    "label": "Osoba",
                    "value": null,
                    "options": null
                  },
                  {
                    "id": 11,
                    "name": "stanowisko",
                    "type": "text",
                    "label": "Stanowisko",
                    "value": null,
                    "options": null
                  },
                  {
                    "id": 12,
                    "name": "telefon",
                    "type": "text",
                    "label": "Telefon",
                    "value": null,
                    "options": null
                  },
                  {
                    "id": 13,
                    "name": "email",
                    "type": "text",
                    "label": "E-mail",
                    "value": null,
                    "options": null
                  },
                  {
                    "id": 14,
                    "name": "mailing",
                    "type": "checkbox",
                    "label": "Mailing",
                    "value": null,
                    "options": null
                  }
                ],
                "name": "Kontakt"
              }
            }
          ]
        }
      },

];

export default repairersFields;