import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Wrapper } from '../../components/FormComponents';
import BackButton from '../../components/buttons/back-button/BackButton';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button, Modal, TextField, InputLabel, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import RowWrapper from "../../components/wrappers/RowWrapper";
import routerHistory from '../../constants/settings/routerHistory';
import ExpansionContainer from '../../components/containers/ExpansionContainer';
import { getCall, putCall, postCall } from '../../components/indicator/Indicator';
import { API_PRODUCT_PRODUCER_ROUTE, API_TEMPLATES_REVIEW_CREATE_ROUTE, API_TEMPLATES_REVIEW_ROUTE, API_TEMPLATES_REVIEW_UPDATE_ROUTE } from '../../constants/routes';
import { toast } from 'react-toastify';

const TemplatesReviewCostsDetailsScreen = () => {
  const [producers, setProducers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [rowsCount, setRowsCount] = useState(1);
  const [serviceGroup, setServiceGroup] = useState('');
  const [reviewGroups, setReviewGroups] = useState({ tables: [] });
  const [editColumnIndex, setEditColumnIndex] = useState(null);
  const [editTableName, setEditTableName] = useState('');
  const [newColumnName, setNewColumnName] = useState('');
  const [newTableName, setNewTableName] = useState('');
  const [isTemplateCreated, setIsTemplateCreated] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTableNameModal, setOpenTableNameModal] = useState(false);

  const handleOpen = (id) => {
    setOpen(true);
    setSelected(producers.find(item => item.id === id));
  }

  const fetchProducers = async () => {
    const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}?type=1`);
    setProducers(data?.items);
  }

  const fetchReviewTemplates = async () => {
    const { data: { item } } = await getCall(API_TEMPLATES_REVIEW_ROUTE);
    setTemplates(item);
  }

  const fetchServiceGroups = async () => {
    const { data: { item }} = await getCall(`${API_TEMPLATES_REVIEW_ROUTE}?product_producer=${selected.id}`);
    
    if (!Array.isArray(item)) {
      const convertedTables = item?.tables?.map((item) => ({
        name: item.name,
        columns: item?.columns?.map((item) => ({ 
          name: item.name, 
          rows: item?.values?.map((row) => row.value)
        }))
      }))
      setReviewGroups({ tables: convertedTables });
      setIsTemplateCreated(true);
      setTemplateId(item?.id);
    } else {
      setIsTemplateCreated(false);
    }
  }

  const addReviewGroupTable = () => {
    const newTable = [...reviewGroups.tables];

    if (newTable.some((item) => item.name === serviceGroup)) {
      toast.error(`Grupa serwisowa ${serviceGroup} już istnieje.`);
      return;
    }

    if(!serviceGroup.length) {
      toast.error("Brak nazwy grupy serwisowej.");
      return;
    }

    newTable.push({ 
      name: serviceGroup,
      columns: []
    });
    setReviewGroups({tables: newTable});
  }

  const addNewColumn = (name) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === name);
    newTable[tableIndex].columns.push({ name: '', rows: Array(rowsCount).fill('') });
    setReviewGroups({ tables: newTable });
  }

  const addNewRow = (name) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === name); 
    newTable[tableIndex].columns.map((item) => item.rows.push(''));
    setReviewGroups({ tables: newTable });
  }

  const onColumnNameChange = (name, index) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === name);
    newTable[tableIndex].columns[index].name = newColumnName;
    setReviewGroups({ tables: newTable });
    setEditColumnIndex(null);
  }

  const onRowChange = (e, name, index, tableName) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === tableName);
    const columnIndex = newTable[tableIndex].columns.findIndex((item) => item.name === name);
    newTable[tableIndex].columns[columnIndex].rows[index] = e.target.value;
    setReviewGroups({ tables: newTable });
  }

  const onTableNameChange = () => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === editTableName);
    newTable[tableIndex].name = newTableName;
    setReviewGroups({ tables: newTable });
  }

  const deleteColumn = (name, tableName) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === tableName);
    const columnIndex = newTable[tableIndex].columns.findIndex((item) => item.name === name);
    newTable[tableIndex].columns.splice(columnIndex, 1);
    setReviewGroups({ tables: newTable });
  }

  const deleteTable = (name) => {
    const newTable = [...reviewGroups.tables];
    const tableIndex = newTable.findIndex((item) => item.name === name);
    newTable.splice(tableIndex, 1);
    setReviewGroups({ tables: newTable });
  }

  const onSubmit = () => {
    const body = {
      product_producer: selected.id,
      overview_template: templateId,
      tables: {
        ...reviewGroups.tables
      }
    }

    if (isTemplateCreated) {
      putCall(API_TEMPLATES_REVIEW_UPDATE_ROUTE, body)
        .then((result) => {
          if (result.status === 'ok') {
            toast.success("Szablon został zaktualizowany");
            fetchReviewTemplates();
          }
        })
        .catch(() => {
          toast.error("Wystąpił problem z aktualizacją szablonu");
        });
    
    } else {
      postCall(API_TEMPLATES_REVIEW_CREATE_ROUTE, body)
        .then((result) => {
          if (result.status === 'ok') {
            toast.success("Szablon został utworzony");
            fetchReviewTemplates();
          }
        })
        .catch(() => {
          toast.error("Wystąpił problem z utworzeniem szablonu");
        })
    }
  }

  useEffect(() => {
    if (!isTemplateCreated) {
      setReviewGroups({ tables: [] });
    }
  }, [isTemplateCreated])

  useEffect(() => {
    fetchProducers();
    fetchReviewTemplates();
  }, [])

  useEffect(() => {
    if (selected && open) {
      fetchServiceGroups();
    }
  }, [selected, open])

  return <>
    <Wrapper>
      <RowWrapper style={{alignItems: 'center'}}>
        <BackButton
            text="Wróć"
            onClick={() => routerHistory.goBack()}
        />
        <ColumnWrapper>
          <Typography>Koszty przeglądu</Typography>
        </ColumnWrapper>
      </RowWrapper>
    </Wrapper>
    <RowWrapper>
      <ColumnWrapper width='100%'>
        <ExpansionContainer title="Koszty przeglądu">
          <RowWrapper>
            <ColumnWrapper>
              <h3>Producent</h3>
              {producers.map(({name}) => (
                <RowWrapper
                  width='100%'
                  style={{ height: '60px', fontSize: '14px', alignItems: 'center' }}
                >
                  {name}
                </RowWrapper>
              ))}
            </ColumnWrapper>
            <ColumnWrapper style={{ marginLeft: '50px' }}>
              <h3>Szablon</h3>
              {producers.map(({id}) => (
                <RowWrapper width='100%' style={{ height: '60px', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleOpen(id)}
                    style={{ color: 'white' }}
                    variant="contained"
                    color="primary"
                  >
                  +
                  </Button>
                </RowWrapper>
              ))}
            </ColumnWrapper>
            <ColumnWrapper style={{ marginLeft: '50px', height: '100%' }}>
              <h3>Data utworzenia/aktualizacji</h3>
              {producers.map(({id}) => (
                templates.find((item) => item?.productProducer?.id === id) ? (
                  <RowWrapper style={{
                      width: 'fit-content',
                      height: '40px', 
                      alignItems: 'center', 
                      border: '1px solid', 
                      margin: '10px', 
                      padding: '10px',
                      fontWeight: 'bold'
                    }}
                  >
                    {moment(templates.find((item) => item?.productProducer?.id === id)?.updatedAt || 
                        templates.find((item) => item?.productProducer?.id === id)?.createdAt)
                        .format("DD-MM-YY - HH:mm")}
                  </RowWrapper>
                ) : (
                  <RowWrapper style={{ height: '40px', margin: '10px', padding: '10px' }}/>
                )
              ))}
            </ColumnWrapper>
          </RowWrapper>
        </ExpansionContainer>
      </ColumnWrapper>
    </RowWrapper>
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setEditColumnIndex(null);
        setNewColumnName('');
        setEditTableName('');
      }}
      style={{ overflow: 'auto' }}
    >
      <ColumnWrapper
        width='80%'
        style={{
          position: 'absolute',
          maxHeight: '80%',
          overflow: 'auto',
          padding: '25px',
          margin: '0 auto',
          background: '#fff',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <RowWrapper align='flex-end'>
          <ColumnWrapper>
            <InputLabel style={{margin: '6px 0'}} shrink htmlFor='producer'>Producent</InputLabel>
            <TextField
              name='producer'
              value={selected?.name}
              variant='outlined'
              disabled
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <InputLabel style={{margin: '6px 0'}} shrink htmlFor='serviceGroup'>Grupa serwisowa</InputLabel>
            <TextField
              name='serviceGroup'
              value={serviceGroup}
              onChange={(e) => setServiceGroup(e.target.value)}
              variant='outlined'
            />
          </ColumnWrapper>
          <ColumnWrapper style={{ marginLeft: '20px' }}>
            <Button
              onClick={addReviewGroupTable}
              style={{ color: 'white', margin: '5px', padding: '5px 10px' }}
              variant="contained"
              color="primary"
            >
              +
            </Button>
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper style={{ flexDirection: 'column' }}>
          {reviewGroups?.tables?.map((table) => (
            <>
              <RowWrapper align='center'>
                <TableContainer style={{ margin: '20px 0', border: '1px solid lightgray' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: '200px' }}>
                          <RowWrapper style={{ alignItems: 'center' }}>
                            <ColumnWrapper>{`Grupa serwisowa ${table.name}`}</ColumnWrapper>
                            <ColumnWrapper>
                              <IconButton
                                onClick={() => {
                                  setEditTableName(table.name);
                                  setOpenTableNameModal(true);
                                }}
                                style={{ padding: 0 }}
                              >
                                <EditIcon fontSize='small'/>
                              </IconButton>
                            </ColumnWrapper>
                          </RowWrapper>
                        </TableCell>
                        {table?.columns.map(({name}) => (
                          <TableCell style={{ minWidth: '200px' }}>
                            <Button onClick={() => deleteColumn(name, table.name)}>
                              Usuń grupę
                            </Button>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Lp.</TableCell>
                        {table?.columns.map(({name}, index) => (
                          <TableCell>
                            <RowWrapper style={{ alignItems: 'center' }}>
                              {editColumnIndex === index && editTableName === table.name ? (
                                <>
                                  <ColumnWrapper>
                                    <TextField
                                      name={name}
                                      value={newColumnName}
                                      onChange={(e) => setNewColumnName(e.target.value)}
                                    />
                                  </ColumnWrapper>
                                  <ColumnWrapper style={{ flexDirection: 'row' }}>
                                    <IconButton onClick={() => onColumnNameChange(table.name, index)} style={{ padding: 0 }}>
                                      <DoneIcon fontSize='small'/>
                                    </IconButton>
                                    <IconButton
                                      onClick={() => setEditColumnIndex(null)}
                                      style={{ padding: 0 }}
                                    >
                                      <CloseIcon fontSize='small'/>
                                    </IconButton>
                                  </ColumnWrapper>
                                </>
                              ): (
                                <>
                                  <ColumnWrapper>{name}</ColumnWrapper>
                                  <ColumnWrapper>
                                    <IconButton
                                      onClick={() => {
                                        setEditColumnIndex(index);
                                        setNewColumnName(name);
                                        setEditTableName(table.name);
                                      }}
                                      style={{ padding: 0 }}
                                    >
                                      <EditIcon fontSize='small'/>
                                    </IconButton>
                                  </ColumnWrapper>
                                </>
                              )} 
                            </RowWrapper>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table?.columns[0]?.rows?.map((row, index) => (
                        <TableRow>
                          <TableCell>{index + 1}</TableCell>
                          {table?.columns?.map(({name, rows}) => (
                            <TableCell>
                              <TextField
                                name={name}
                                value={rows[index]}
                                onChange={(e) => onRowChange(e, name, index, table.name)}
                                variant='outlined'
                              />
                            </TableCell>
                        ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <ColumnWrapper style={{ alignItems: 'center', gap: '10px' }}>
                  <Button
                    onClick={() => addNewColumn(table.name)}
                    style={{ color: 'white', width: '50px' }}
                    variant="contained"
                    color="primary"
                  >
                    +
                  </Button>
                  <Button onClick={() => deleteTable(table.name)} style={{ width: '110px' }}>Usuń tabelę</Button>
                </ColumnWrapper>
              </RowWrapper>
              <Button
                onClick={() => addNewRow(table.name)}
                style={{ color: 'white', marginTop: '10px' }}
                variant="contained"
                color="primary"
                >
                +
              </Button>
          </>
          ))}
        </RowWrapper>
        <RowWrapper style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={onSubmit}
            style={{ color: 'white' }}
            variant="contained"
            color="primary"
          >
              Zapisz
          </Button>
        </RowWrapper>
      </ColumnWrapper>
    </Modal>
    <Modal
      open={openTableNameModal}
      onClose={() => setOpenTableNameModal(false)}
    >
      <ColumnWrapper
        style={{
          position: 'absolute',
          maxHeight: '80%',
          overflow: 'auto',
          padding: '25px',
          margin: '0 auto',
          background: '#fff',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <RowWrapper style={{ flexDirection: 'column' }}>
          Nowa nazwa grupy:
          <TextField
            name="newTableName"
            variant='outlined'
            onChange={(e) => setNewTableName(e.target.value)}
          />
        </RowWrapper>
        <RowWrapper style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
          <Button
            onClick={() => {
              onTableNameChange();
              setOpenTableNameModal(false);
            }}
            style={{ color: 'white' }}
            variant="contained"
            color="primary"
          >
              Zapisz
          </Button>
        </RowWrapper>
      </ColumnWrapper>
    </Modal>
  </>
}

export default TemplatesReviewCostsDetailsScreen;