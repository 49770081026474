import React, {useMemo} from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {UseActionZoneStyles} from "./ActionZoneStyles";
import {translateGroup, WITHOUT_GROUP} from "../../../../../constants/RepairDeviceGroup";
import {groupBy} from "lodash";
import ActionReviewDevicesVerticalTablePreview from "../tables/ActionReviewDevicesVerticalTablePreview";

const ActionReviewDevicesZonePreview = ({devices}) => {
    const classes = UseActionZoneStyles();

    const groupedRows = useMemo(() => {
            const grouped = groupBy(devices,  (row) => row?.fields?.szablon_id);
            return Object.entries(grouped).filter(([id]) => !!id && id != 'undefined');
        }
        , [devices]);

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell>Lp</TableCell>
                <TableCell>Nr seryjny</TableCell>
                <TableCell>Urządzenie</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Części na gwarancji</TableCell>
                <TableCell>Części nie na gwarancji</TableCell>
                <TableCell>Notatki\opis awarii</TableCell>
            </TableRow>
        </TableHead>
    );

    return (
        <>
                <ColumnWrapper fullWidth>
                    {groupedRows.map(([name, items]) =>
                        <div style={{marginTop: 15}} key={name}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h3>{translateGroup(items?.[0]?.fields?.szablon_name || '')}</h3>
                            </div>
                            <ActionReviewDevicesVerticalTablePreview templateFields={items?.[0]?.fields?.szablon || []} rows={items}/>
                            {/*<Table*/}
                            {/*    className={classes.table}*/}
                            {/*    style={{marginTop: 30, marginBottom: 50}}*/}
                            {/*    aria-labelledby="tableTitle"*/}
                            {/*    size="small"*/}
                            {/*    aria-label="enhanced table">*/}
                            {/*    {renderHeader()}*/}
                            {/*    <TableBody>*/}
                            {/*        {items.map((item, index) =>*/}
                            {/*            <RepairDevicesZoneRow i={item} rowIndex={index+1} key={item.uuid || item.id}/>)*/}
                            {/*        }*/}
                            {/*    </TableBody>*/}
                            {/*</Table>*/}
                        </div>
                    )}
                </ColumnWrapper>
        </>
    );
};

const RepairDevicesZoneRow = ({i, rowIndex}) => {
    return <>
        <TableRow key={i.id + 'row'}>
            <TableCell>
                {rowIndex}
            </TableCell>
            <TableCell>
                {i?.serial_no || '-'}
            </TableCell>
            <TableCell>
                {i?.product?.name || '-'}
            </TableCell>
            <TableCell>
                {i?.identity || '-'}
            </TableCell>
            <TableCell>
                {i?.parts_under_warranty?.map(({name}) => name).join(', ') || '-'}
            </TableCell>
            <TableCell>
                {i?.parts_not_under_warranty?.map(({name}) => name).join(', ') || '-'}
            </TableCell>
            <TableCell>
                {i?.description || '-'}
            </TableCell>
        </TableRow>
    </>
};

export default ActionReviewDevicesZonePreview;
