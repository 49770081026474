import { InputAdornment, TextField } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TextsmsIcon from '@material-ui/icons/Textsms';
import WorkIcon from '@material-ui/icons/Work';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import React, {useEffect, useMemo, useState} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_COMPANIES_ROUTE,
  API_NOTES_ROUTE,
  API_PROJECTS_ROUTE,
  API_REPAIRS_ROUTE
} from '../../constants/routes';
import PrimaryButton from '../buttons/PrimaryButton';
import PaperContainer from '../containers/PaperContainer';
import { getCall, postCall, putCall } from '../indicator/Indicator';
import ColumnWrapper from '../wrappers/ColumnWrapper';
import RowWrapper from '../wrappers/RowWrapper';
import NoteItem from './NoteItem';

const ButtonWrapper = styled(ColumnWrapper)`
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 10px;
  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }
`;

const TextFieldsWrapper = styled(RowWrapper)`
  margin-top: ${(props) => (props.small ? '10px' : '25px')};
  @media (max-width: 1400px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

const schemaMock = [
  { label: 'notatka 1', value: 'Przykładowa notatka zawierająca tekst' },
  {
    label: 'notatka 2',
    value: 'Przykładowa notatka zawierająca tekst 2',
  },
];

const NotesPanel = ({ data, fetchNotes , ...props}) => {
  const [notesData, setNotesData] = useState([]);
  const [note, setNote] = useState('');
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setNotesData(data);
  }, [data]);

  const [companies, setCompanies] = useState({
    selectedCompany: [],
    companies: [],
    data: [],
  });

  const [projects, setProjects] = useState({
    selectedProject: props?.selectedProject,
    projects: [],
    data: [],
  });

  const [repairs, setRepairs] = useState({
    selectedRepair: props?.selectedRepair,
    repairs: [],
    data: [],
  });

  useEffect(() => {
    const fetchAPIData = async () => {
      const c = await getCall(API_COMPANIES_ROUTE);
      if (c.data) {
        const d = c.data.items?.map((item, index) => ({
          key: index,
          value: item.name,
          id: item.id,
        }));
        const selectedCompany = d?.find(({id}) => Number(props?.selectedCompany?.id) === id);
        setCompanies({ ...companies, data: d, selectedCompany: selectedCompany ? [selectedCompany] : [] });
      }
      const p = await getCall(API_PROJECTS_ROUTE);
      if (p.data) {
        const d = p.data.items?.map((item, index) => ({
          key: index,
          value: item.name,
          id: item.id,
        }));
        setProjects({ ...projects, data: d });
      }
      const r = await getCall(API_REPAIRS_ROUTE);
      if (r.data) {
        const d = r.data.items?.map((item, index) => ({
          key: index,
          value: item.name,
          id: item.id,
        }));
        setRepairs({ ...repairs, data: d });
      }
    };
    if(!props?.hideAdditional) {
      fetchAPIData();
    }
    // eslint-disable-next-line
  }, []);

  const addNote = async () => {
    // const { data } = await postCall(API_NOTES_ROUTE, { body: note, companyId: TODO COMPANY ID });
    const { data } = await postCall(API_NOTES_ROUTE, {
      body: note,
      project_id: projects?.selectedProject?.id,
      company_id: (companies?.selectedCompany || [])?.map(({id}) => id) || [],
      ...(props?.automation_id ? {automation_id: props?.automation_id} : {}),
      ...(props?.calendar_event_id ? {calendar_event_id: props?.calendar_event_id} : {}),
      repair_id: repairs?.selectedRepair?.id,
    });
    setCompanies({...companies, selectedCompany: []})
    if (data) {
      if (fetchNotes) {
        const newNotes = await fetchNotes();
        setNotesData(newNotes);
      }
      setNote('');
      toast.success('Dodano notatkę.');
    }
  };

  const editNote = async (id, note) => {
    const r = await putCall(`${API_NOTES_ROUTE}/${id}`, {
      body: note,
    })
  }

  useEffect(() => {
    if (schema) {
      setNote(schema);
    }
  }, [schema]);

  const notes = useMemo(() => {
    if(notesData && notesData.notes && notesData.notes.length) {
      return notesData?.notes?.sort((a, b) => b?.id - a?.id)
    }
    return  []
  }, [notesData])

  return (
    <>
      <PaperContainer style={{position: "fixed", width: '35%' }} title="Notatki">
        <ColumnWrapper style={{ alignItems: 'flex-end' }} fullWidth>
          <ColumnWrapper fullWidth style={{maxHeight: '45vh', overflowY: 'auto'}}>
            {notesData && notesData.notes && notesData.notes.length
              ? notes.map((i, x) => (
                  <NoteItem
                    key={`${i.body}[${x}]`}
                    id={i.id}
                    user={i.creator}
                    note={i.body}
                    projectLabel={i.project?.name}
                    companyLabel={i.company?.name}
                    companies={i.company}
                    projectId={i.project?.id}
                    companyId={i.company?.id}
                    date={i.created?.date}
                    editNote={editNote}
                    fetchNotes={fetchNotes}
                  />
                ))
              : null}
          </ColumnWrapper>
          <TextFieldsWrapper fullWidth>
            <TextField
              value={note}
              onChange={({ target }) => setNote(target.value)}
              style={{ margin: '8px 0', maxHeight: 130, overflowY: 'scroll' }}
              placeholder="Wpisz notatkę"
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </TextFieldsWrapper>
          {!props?.hideAdditional && <TextFieldsWrapper small>
            <ButtonWrapper style={{ marginLeft: 0 }}>
              <Autocomplete
                id="schema-a"
                options={schemaMock}
                getOptionLabel={(option) => option.label || ''}
                style={{ marginBottom: 20, width: '100%' }}
                onChange={(e, v) => setSchema(v?.value || '')}
                variant="outlined"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Szablony"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <TextsmsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Autocomplete
                id="projects-a"
                options={projects.data}
                variant="outlined"
                disabled={props?.selectedProject ? true : false}
                getOptionLabel={(option) => option.value || ''}
                style={{ marginBottom: 20, width: '100%' }}
                onChange={(e, v) =>
                  setProjects({
                    ...projects,
                    selectedProject: v,
                  })
                }
                value={projects.selectedProject || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Projekty"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </ButtonWrapper>
            <ButtonWrapper style={{ marginRight: 0, width: 280 }}>
              <Autocomplete
                multiple
                id="companies-a"
                options={companies.data}
                variant="outlined"
                getOptionLabel={(option) => option.value || option?.name || ''}
                renderOption={(option) => option.value || option?.name}
                style={{ marginBottom: 20, width: '100%' }}
                onChange={(e, selectedCompany) =>
                  setCompanies({
                    ...companies,
                    selectedCompany,
                  })
                }
                defaultValue={companies.selectedCompany || []}
                value={companies.selectedCompany || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Firmy"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                        <InputAdornment position="start">
                          <BusinessCenterIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </ButtonWrapper>
          </TextFieldsWrapper>}
          <PrimaryButton
            disabled={!note}
            style={{ alignSelf: 'flex-end', marginTop: 20 }}
            onClick={addNote}>
            Dodaj notatkę
          </PrimaryButton>
        </ColumnWrapper>
      </PaperContainer>
    </>
  );
};

export default NotesPanel;
