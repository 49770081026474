import { Box, Typography } from '@material-ui/core';
import React from 'react';
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import ActionContainer from "../../components/action-container/ActionContainer";

const ReportsScreen = () => (
  <>
    <ColumnWrapper>
      <Box my={5}>
        <Typography variant="subtitle1">Raporty handlowe</Typography>
        <ActionContainer label="Zamówienia" />
        <ActionContainer label="Wymienniki" />
        <ActionContainer label="Przetargi" />
        <ActionContainer label="Plan" />
      </Box>

      <Box my={5}>
        <Typography variant="subtitle1">Administracja</Typography>
        <ActionContainer label="Prowizje" />
        <ActionContainer label="Podsumowanie zamówień" />
      </Box>

      <Box my={5}>
        <Typography variant="subtitle1">Automatyka</Typography>
        <ActionContainer label="Zamówienia" />
        <ActionContainer label="Podsumowanie" />
      </Box>

      <Box my={5}>
        <Typography variant="subtitle1">Statystyki</Typography>
        <ActionContainer label="Statystyki użytkownika" />
        <ActionContainer label="Statystyki projektu" />
        <ActionContainer label="Urządzenia" />
      </Box>

      <Box my={5}>
        <Typography variant="subtitle1">KR</Typography>
        <ActionContainer label="Plan tygodnia" />
      </Box>

      <Box my={5}>
        <Typography variant="subtitle1">Raporty automatyczne</Typography>
        <ActionContainer label="Generuj raporty automatyczne" />
      </Box>
    </ColumnWrapper>
  </>
);

export default ReportsScreen;
