import React, { useState } from 'react';
import routerHistory from "../../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { InputWrapper, Wrapper } from "../../../../../components/ListToolBarComponents";

const ListToolBar = ({ handleSearch }) => {
  const [searchVal, setSearchVal] = useState('');
  return (
    <>
      <Wrapper>
        <InputWrapper>
          <RowWrapper>
            <SearchField
              value={searchVal}
              onChange={({ target }) => setSearchVal(target.value)}
            />
            <PrimaryButton
              style={{ marginLeft: 10 }}
              color="secondary"
              onClick={() => handleSearch(searchVal)}>
              Szukaj
            </PrimaryButton>
          </RowWrapper>
        </InputWrapper>
        <InputWrapper></InputWrapper>
        <InputWrapper></InputWrapper>
        <PrimaryButton
          onClick={() => routerHistory.push('/service/repairs/new')}>
          Dodaj
        </PrimaryButton>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
