import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles, Paper, TextField } from '@material-ui/core';
import { Mail, MeetingRoom, People, Phone, SettingsPhone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import plLocale from 'date-fns/locale/pl';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import { companyStatuses } from '../../details/basic-data/companyFields';
import commonValues from '../../../../../tools/commonValues';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

const CompaniesSearchFilters = (props) => {
    const classes = useStyles();
    const onChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            newFilters[name] = val;
        } else {
            delete newFilters[name];
        }
        setFilters(newFilters);
    }
    const onQueryFieldsChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            filters.queryFields[name] = val;
        } else {
            delete filters.queryFields[name];
        }
        setFilters(newFilters);
    }

    const [filters, setFilters] = useState({ 
        queryFields: {}
    });

    const [ostatniOd, setOstatniOd] = useState(null);
    const [ostatniDo, setOstatniDo] = useState(null);

    const statuses = companyStatuses.map((item) => item.name);
    const statusTranslation = companyStatuses.reduce((arr, {name, label}) => Object.assign(arr, {[name]: label }), {});


    return (
        <>
        <Paper className={classes.paper}>

        <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={'Nazwa firmy'}
            margin="dense"
            onChange={({ target }) => onChange('query', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'Osoba'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('contactslist', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'Miasto'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('miasto', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'Adres'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('adres', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'Tel/Fax'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('telefon', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'E-mail'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('contactslist', target.value)}
            />

        <TextField
            fullWidth
            variant="outlined"
            label={'Notatka'}
            margin="dense"
            onChange={({ target }) => onQueryFieldsChange('notatka', target.value)}
            />

        <Select
            fullWidth
            size="small"
            variant="outlined"
            label={"Województwo"}
            data={[{id: null, name: '-'}, ...commonValues.voivodeships]}
            onSelect={(option) => {
                onQueryFieldsChange('wojewodztwo', option)
            }}
            />

        <Select
            fullWidth
            size="small"
            variant="outlined"
            label={"Użytkownik"}
            data={[]}
            />

        <Select
            fullWidth
            size="small"
            variant="outlined"
            label={"Zgoda na marketing"}
            data={[{name: 'NIE', id: null}, {id: 'true', name: 'TAK'}]}
            onSelect={(option) => {
                onQueryFieldsChange('zgoda_marketing', option)
            }}
            />

            <Divider style={{margin: '1em 0px'}} />

        Status:

        <FormGroup>
            {statuses.map(item => (
                <FormControlLabel
                key={item+'_sfcl'}
                    control={<Checkbox name={item.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onQueryFieldsChange(item, e.target.checked ? 'true' : null)} />}
                    label={statusTranslation[item]}
                />
            ))}
        </FormGroup>

        Ostatni kontakt:

        <FormGroup>
            {[{name: 'Telefon', id:'ostatni_tel', icon: <SettingsPhone />},
            {name: 'Mail', id:'ostatni_mail', icon: <Mail />},
            {name: 'Spotkanie', id:'ostatni_spotkanie', icon: <People />}].map(item => (
                <FormControlLabel
                key={item.id+'_fcl'}
                    control={<Checkbox name={item.name.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onQueryFieldsChange(item?.id.toLowerCase(), e.target.checked ? '@exists@' : null)} />} 
                    label={item.icon}
                />
            ))}
        </FormGroup>        

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                label="Ostatni kontakt od"
                name={'ostatni_od'}
                format="dd/MM/yyyy"
                value={ostatniOd || null}
                inputVariant="outlined"
                ampm={false}
                onChange={(val) => {
                    setOstatniOd(val);
                    onQueryFieldsChange('ostatni_od', val ? val.getTime() : null)
                }}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                onChange={(val) => {
                    setOstatniDo(val);
                    onQueryFieldsChange('ostatni_do', val ? val.getTime() : null)
                }}
                label="Ostatni kontakt do"
                name={'ostatni_do'}
                value={ostatniDo || null}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
            />
        </MuiPickersUtilsProvider>

        <PrimaryButton
            style={{ marginLeft: 0, marginTop: 10}}
            color="secondary"
            onClick={() => props?.handleSearch(filters)}>
            Szukaj
        </PrimaryButton>
        </Paper>
        </>
    );
};

export default CompaniesSearchFilters;
