import React, {useEffect, useMemo, useState} from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import TextField from "@material-ui/core/TextField";
import {UseActionZoneStyles} from "./ActionZoneStyles";
import {groupBy} from "lodash";
import TemplateModal from "../../shared/TemplateModal";
import Button from "@material-ui/core/Button";
import TemplateVerticalTable from "../tables/TemplateVerticalTable";
import {formToRequest} from "../../../../../components/dynamic-forms/field-transformer";

const ActionReviewDeviceZone = ({devices, onUpdate}) => {
    const classes = UseActionZoneStyles();

    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [rows, setRows] = useState(() => devices.map(device =>
        ({
            ...device,
            repair_device_id: device.id,
            template: device?.template || null,
            fields: device?.fields || [],
        })
    ));

    const groupedRows = useMemo(() => {
            const grouped = groupBy(rows, (row) => row?.template?.id);
            return Object.entries(grouped).filter(([id]) => !!id && id != 'undefined');
        }
        , [rows]);

    const editRow = (name, v, index) => {
        const localRows = [...rows];
        const i = findRow(index);
        if (~i) {
            if (name === 'template' && v === null) {
                localRows[i]['fields'] = []
            }
            localRows[i][name] = v;
        }
        setRows(localRows);
    };

    const findRow = key => rows.findIndex(item => item.id === key);

    const assignTemplateToProducts = ({products, template}) => products.forEach(id => editRow('template', template, id));

    useEffect(() => {
        onUpdate && onUpdate(rows.map(row => ({...row, fields: formToRequest(row?.fields || {})})))
    }, [rows]);

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell>Lp</TableCell>
                <TableCell>Nr seryjny</TableCell>
                <TableCell>Urządzenie</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Części dodatkowe linku na gwarancji</TableCell>
                <TableCell>Części dodatkowe linku nie na gwarancji</TableCell>
                <TableCell>Notatki\opis awarii</TableCell>
            </TableRow>
        </TableHead>
    );

    return (
        <>
            <ColumnWrapper fullWidth>
                <Button style={{width: 200, margin: "auto"}} variant="contained"
                        onClick={() => setOpenTemplateModal(true)}>Wstaw szablon</Button>
                {groupedRows.map(([name, items]) =>
                    <div style={{marginTop: 15}} key={name}>
                        <div
                            style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10}}>
                            <h3>Szablon: {items?.[0]?.template?.name || ''}</h3>
                        </div>
                        <TemplateVerticalTable template={items?.[0]?.template} rows={items} onUpdate={editRow}/>
                    </div>
                )}
                <TemplateModal
                    products={devices}
                    open={openTemplateModal}
                    onClose={() => setOpenTemplateModal(false)}
                    onSubmit={({products, template}) => {
                        assignTemplateToProducts({products, template});
                        setOpenTemplateModal(false)
                    }}
                />
            </ColumnWrapper>
        </>
    );
};

const RepairDevicesZoneRow = ({i, index, rowIndex, editRow}) => {

    const products = useMemo(() => i?.product?.products || [], [i]);
    const findProduct = searchesId => products.find(({id}) => id === searchesId);

    return <>
        <TableRow key={i.id + 'row'}>
            <TableCell>
                {rowIndex}
            </TableCell>
            <TableCell>
                {i?.serial_no || '-'}
            </TableCell>
            <TableCell>
                {i?.product?.name || '-'}
            </TableCell>
            <TableCell>
                {i?.identity || '-'}
            </TableCell>
            <TableCell>
                {returnSpecificField(
                    'dictionary_multiple',
                    {data: products || []},
                    'parts_under_warranty_link',
                    (i?.parts_under_warranty_link || []).map(({id}) => id),
                    (e, v) => {
                        editRow(e, (v || []).map(id => findProduct(id)), index)
                    }
                )}
            </TableCell>
            <TableCell>
                {returnSpecificField(
                    'dictionary_multiple',
                    {data: products || []},
                    'parts_not_under_warranty_link',
                    (i?.parts_not_under_warranty_link || []).map(({id}) => id),
                    (e, v) => {
                        editRow(e, (v || []).map(id => findProduct(id)), index)
                    }
                )}
            </TableCell>
            <TableCell>
                <TextField
                    multiline
                    variant="outlined"
                    rows={3}
                    placeholder="Notatka\opis awarii"
                    value={i?.description}
                    onChange={({target: {value}}) => editRow('description', value, index)}
                />
            </TableCell>
        </TableRow>
    </>
};

export default ActionReviewDeviceZone;
