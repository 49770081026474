import {DIR_TYPE, FILE_TYPE} from "../../../constants/directories/types";
import uniqueId from "../../../tools/uniqueId";
import {getCall} from "../../../components/indicator/Indicator";
import {API_DIRECTORY_EXPLORER_ROUTE} from "../../../constants/routes";
import {toast} from "react-toastify";

export const transformDocument = (additionalProps) => (row) => ({...row, ...additionalProps});

export default async (id = 0) => {

    try {
        const {data: {files, subdirs, parent, name = ''} = {}} = await getCall(`${API_DIRECTORY_EXPLORER_ROUTE}/${id}`);
        return {
            name,
            parent,
            rows: [
                ...files.map(file => transformDocument({type: FILE_TYPE, uuid: uniqueId()})(file)),
                ...subdirs.map(dir => transformDocument({type: DIR_TYPE, uuid: uniqueId()})(dir))
            ]
        };
    } catch {
        toast.error('Wystąpił błąd podczas pobierania listy katalogów.');
    }
};