import {makeStyles, Paper, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {DIR_TYPE, FILE_TYPE} from "../../../../constants/directories/types";
import Select from "../../../../components/dynamic-forms/form-components/Select";
import EntityField from "../../../../components/dynamic-forms/form-components/EntityField";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

export default (props) => {
    const classes = useStyles();
    const onChange = (name, val) => {
        const newFilters = filters;

        if (val) {
            newFilters[name] = val;
        } else {
            delete newFilters[name];
        }
        setFilters(newFilters);
    }

    const [filters, setFilters] = useState({
        queryFields: {},
        type: FILE_TYPE,
    });

    const [type, setType] = useState(FILE_TYPE);
    const [user, setUser] = useState(null);
    const [parent, setParent] = useState(null);

    return (
        <>
            <Paper className={classes.paper}>

                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label={'Nazwa'}
                    margin="dense"
                    onChange={({target}) => onChange('query', target.value)}
                />
                <Select
                    fullWidth
                    size="small"
                    variant="outlined"
                    label={"Typ"}
                    data={[{id: FILE_TYPE, name: 'Plik'}, {id: DIR_TYPE, name: 'Katalog'}]}
                    value={type}
                    onSelect={(option) => {
                        onChange('type', option || null)
                    }}
                />
                <EntityField
                    entityClass={'user'}
                    placeholder={'Autor'}
                    label={"Autor"}
                    value={user ? user : null}
                    onSelect={(v) => onChange('author', v?.id || null)}
                />
                <EntityField
                    entityClass={'directory'}
                    placeholder={'Katalog nadrzędny'}
                    label={"Katalog nadrzędny"}
                    value={parent ? parent : null}
                    onSelect={(v) => onChange('directory', v?.id || null)}
                />
                <br/>
                <PrimaryButton
                    style={{marginLeft: 0, marginTop: 10}}
                    color="secondary"
                    onClick={() => props?.handleSearch(filters)}>
                    Szukaj
                </PrimaryButton>
            </Paper>
        </>
    );
};
