import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField
} from '@material-ui/core';
import React, {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@material-ui/core/colors';

export default ({initialDescription, onAccept, onCancel, isOpen, onClose}) => {

    const [description, setDescription] = useState(initialDescription);

    const handleAccept = () => {
        if (onAccept) onAccept(description);
        onClose();
    };

    const handleCancel = () => {
        if (onCancel) onCancel(description);
        onClose();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Feedback</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        rows={5}
                        label="Komentarz *"
                        variant="outlined"
                        value={description}
                        onChange={({target: {value}}) => setDescription(value)}
                    />
                </DialogContent>
                <DialogActions>
                    <IconButton
                        disabled={!description?.length}
                        onClick={e => {
                            handleCancel();
                            return e.stopPropagation()
                        }}>
                        <ClearIcon style={{ color: red[500] }} />
                    </IconButton>
                    <IconButton
                        disabled={!description?.length}
                        onClick={e => {
                            handleAccept();
                            return e.stopPropagation()
                        }}>
                        <CheckIcon style={{ color: green[500] }} />
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

