import React, { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
  API_PRODUCT_PRODUCER_ROUTE,
  API_PRODUCTS_ROUTE,
  API_PROJECT_DEVICES_ROUTE
} from '../../../../../constants/routes';
import { getCall } from "../../../../../components/indicator/Indicator";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em'
  }
}));

const DevicesZone = ({setRootRows}) => {
  const classes = useStyles();

  const { id } = useParams();

  const [devices, setDevices] = useState([]);
  const [rows, setRows] = useState([]);
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };

  const turnToRows = (data) => {
    setRows(data.map(item => {

        const findProduct = products.find(prod => item.product == prod.id);
        return ({
          producer: findProduct.product_producer.id,
          category: findProduct.product_category.id,
          product: findProduct.id,
          amount: item.amount,
          uuid: Math.random().toString(36).substring(7)
        });
      }
    ))
  }

  const getUniqueCategories = (data) => {
    const categories = data.map(item => item.product_category)
    .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setCategories(categories);
  }

  const getUniqueProducers = (data) => {
    // const producers = data.map(item => item.product_producer)
    // .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);
    const producers = data.filter((item) => item.type === 1);

    setProducers(producers);
  }

  const fetchProjectDevices = async () => {
    try {
      const { data } = await getCall(`${API_PROJECT_DEVICES_ROUTE(id)}`);
      if (data) {
        const rows = [];
        turnToRows(data);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania listy urządzeń w projekcie.');
    }
  }

  React.useEffect(() => {
    if(products.length) {
      fetchProjectDevices();
    }
  }, [products]);

  React.useEffect(() => {
    console.log(producers)
  }, [producers]);

  const fetchDevices = async () => {
    
    try {
      const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
      const result = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}?results_on_page=999`);
      console.log(result.data)
      if (data) {
        setProducts(data.items);
        getUniqueCategories(data.items);
        // getUniqueProducers(data.items);
        getUniqueProducers(result.data?.items);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
    }


    
  };

  React.useEffect(() => {
    fetchDevices();
  }, []);
  
  React.useEffect(() => {
    setRootRows(rows);
  }, [rows]);

  const addNewRow = () => {
    setRows([...rows, {
      producer: null, 
      category: null, 
      product: null, 
      amount: 0,
      uuid: Math.random().toString(36).substring(7)
    }]);
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    if(i >= 0) {
      localRows[i][name] = v;
    }
    setRows(localRows);
  }

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Producent</TableCell>
        <TableCell>Kategoria</TableCell>
        <TableCell>Urządzenie</TableCell>
        <TableCell>Ilość</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <ExpansionContainer title="Urządzenia">
        <ColumnWrapper fullWidth>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table">
            {renderHeader()}
            <TableBody>{rows.map((item, i) => <DevicesZoneRow i={item} index={item.uuid} data={{producers, categories, products}} key={item.uuid} editRow={editRow} removeRow={removeRow} />)}</TableBody>
            <Button className={classes.add} variant="contained" onClick={(e) => addNewRow()}>
              <AddIcon /> Dodaj kolejne urządzenie
            </Button>
          </Table>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

const DevicesZoneRow = ({i, index, data, editRow, removeRow, ...props}) => {
  const [localProducer, setLocalProducer] = useState(i.producer || null);
    const [localCat, setLocalCat] = useState(i.category || null);
    const [localProducts, setLocalProducts] = useState([]);

    const adjustProducts = () => {
      setLocalProducts(data.products.filter(item => item.product_category.id == localCat && item.product_producer.id == localProducer));
    }

    React.useEffect(() => {
      adjustProducts();
    }, [localProducer, localCat, i]);

    return (
    <TableRow key={i.uuid+'row'}>
      <TableCell>
        {returnSpecificField('dictionary', {data: data.producers}, 'producer', i.producer, (e, v) => {editRow(e, v, index); setLocalProducer(v);} )}
      </TableCell>
      <TableCell>
        {returnSpecificField('dictionary', {data: data.categories}, 'category', i.category, (e, v) => {editRow(e, v, index); setLocalCat(v); } )}
      </TableCell>
      <TableCell>
        {returnSpecificField('dictionary', {data: localProducts}, 'product', i.product, (e, v) => {editRow(e, v, index);} )}
      </TableCell>
      <TableCell>
        {returnSpecificField('text', {}, 'amount', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell>
        <Button variant="contained" onClick={(e) => removeRow(index)}>
          <DeleteIcon />
        </Button>
      </TableCell>
    </TableRow>
    );
}

export default DevicesZone;
