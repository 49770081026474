import React, {useMemo, Fragment, useEffect, useState} from "react";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import uniqueId from "../../../../../tools/uniqueId";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import {getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCTS_ROUTE, API_PROJECTS_ROUTE} from "../../../../../constants/routes";
import {toast} from "react-toastify";
import Select from "../../../../../components/dynamic-forms/form-components/Select";
import {InputLabel, InputWrapper} from "../../../../../components/FormComponents";
import {Button, Typography} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';

export default ({parts: initParts = [], isValid = true, onUpdate, archived = false}) => {

    const [products, setProducts] = useState([]);
    const [projects, setProjects] = useState([]);

    const emptyPart = () => ({
        uuid: uniqueId(),
        date: (initParts[initParts.length - 1] || {}).date || null,
        purpose: '',
        note: '',
        serial_no: '',
        status: '',
        producer: null,
        product: null,
        investment: null,
        repairer: null,
    });

    const statuses = [
        {id: 'USED', name: 'Wykorzystane'},
        {id: 'ON_WAREHOUSE', name: 'Na magazynie'},
    ];

    const parts = useMemo(() =>
            initParts.map((part) => ({...part, uuid: part.uuid || uniqueId()})),
        [initParts]);

    const producers = useMemo(() =>
            products.reduce((producers, {product_producer} = {}) => {
                    !producers.find(({id}) => product_producer.id === id) && producers.push(product_producer);
                    return producers
                },
                []),
        [products]);

    useEffect(() => {
        fetchProducts();
        fetchProjects();
        !parts?.length && addPart(emptyPart());
    }, []);

    const addPart = newPart => onUpdate([...initParts, newPart]);

    const updatePart = newPart => {
        const index = findPartIndex(newPart);
        if (index > -1) {
            const newParts = [...initParts];
            newParts.splice(index, 1, {...newParts[index], ...newPart});
            onUpdate(newParts)
        }
    };

    const deletePart = partToDelete => {
        const index = findPartIndex(partToDelete);
        if (index > -1) {
            const newParts = [...initParts];
            newParts.splice(index, 1);
            onUpdate(newParts)
        }
    };

    const findPartIndex = ({uuid}) =>
        parts.findIndex(({uuid: searchesUuid}) => uuid === searchesUuid);

    const findProducer = searchesId =>
        producers.find(({id}) => id === searchesId);

    const findProduct = searchesId =>
        products.find(({id}) => id === searchesId);

    const findProject = searchesId =>
        projects.find(({id}) => id === searchesId);

    const findProducerProducts = productId =>
        products.filter(({product_producer}) => product_producer.id === productId);

    const findProductIndex = productId =>
        products.find((item) => item.id === productId);

    const fetchProducts = async () => {
        try {
            const {data: {items} = {}} = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
            setProducts(items);
        } catch {
            toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
        }
    };

    const fetchProjects = async () => {
        try {
            const {data: {items} = {}} = await getCall(`${API_PROJECTS_ROUTE}?limit=-1`);
            setProjects(items);
        } catch {
            toast.error('Wystąpił błąd podczas pobierania projektów.');
        }
    };

    return (
        <>
            <RowWrapper>
                <ColumnWrapper>
                    {parts.filter(item => archived ? item.archived === true : !item.archived).map(({uuid, date, purpose, note, serial_no, producer, product, status, investment}) => (
                        <Fragment key={uuid}>
                            <RowWrapper style={{marginTop: 20}} fullWidth>
                                <InputWrapper style={{display: 'block'}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Data
                                    </InputLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                                        <KeyboardDateTimePicker
                                            error={!isValid && !date}
                                            helperText={!isValid && 'Niepoprawana wartość'}
                                            label="Data"
                                            placeholder="dd/MM/yyyy HH:mm"
                                            autoOk={true}
                                            variant="inline"
                                            disabled={archived}
                                            format="dd/MM/yyyy HH:mm"
                                            inputVariant="outlined"
                                            value={date}
                                            onChange={date => updatePart({uuid, date})}
                                            ampm={false}
                                        />
                                    </MuiPickersUtilsProvider>
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Producent
                                    </InputLabel>
                                    <Select
                                        error={!isValid && !producer}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        style={{marginTop: 3}}
                                        placeholder="Producent"
                                        disabled={archived}
                                        data={producers}
                                        value={producer ? producer.id : ''}
                                        onSelect={id => updatePart({uuid, producer: findProducer(id)})}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Kod części
                                    </InputLabel>
                                    <TextField
                                        error={!isValid && !product}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        variant="outlined"
                                        disabled={archived}
                                        placeholder="Kod części"
                                        value={findProductIndex(product?.id)?.fields?.find(item => item.name === 'index')?.value || ''}
                                        readOnly
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Produkt
                                    </InputLabel>
                                    <Select
                                        error={!isValid && !product}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        style={{marginTop: 3}}
                                        placeholder="Produkt"
                                        disabled={archived}
                                        data={producer ? findProducerProducts(producer.id) : []}
                                        value={product ? product.id : ''}
                                        onSelect={id => updatePart({uuid, product: findProduct(id)})}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Przeznaczenie
                                    </InputLabel>
                                    <Autocomplete
                                        options={['', 'gwarancyjnie', 'pogwarancyjnie', 'magazyn zewnętrzny']}
                                        freeSolo
                                        getOptionLabel={(option) => option || ''}
                                        disabled={archived}
                                        onInputChange={(e, value) => updatePart({uuid, purpose: value })}
                                        value={purpose || ''}
                                        style={{ width: 150 }}
                                        renderInput={(params) => <TextField {...params} value={purpose || ''} placeholder="Przeznaczenie" variant="outlined" />}
                                        onChange={(e, value) => updatePart({uuid, purpose: value })}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Notatka
                                    </InputLabel>
                                    <TextField
                                        multiline
                                        variant="outlined"
                                        rows={3}
                                        placeholder="Notatka"
                                        value={note}
                                        onChange={({target: {value: note}}) => updatePart({uuid, note})}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Numer seryjny
                                    </InputLabel>
                                    <TextField
                                        error={!isValid && !serial_no}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        variant="outlined"
                                        placeholder="Numer seryjny"
                                        disabled={archived}
                                        value={serial_no}
                                        onChange={({target: {value: serial_no}}) => updatePart({uuid, serial_no})}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Inwestycja
                                    </InputLabel>
                                    <Select
                                        error={!isValid && !investment}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        style={{marginTop: 3}}
                                        placeholder="Inwestycja"
                                        data={projects}
                                        disabled={archived}
                                        value={investment ? investment.id : ''}
                                        onSelect={id => updatePart({uuid, investment: findProject(id)})}
                                    />
                                </InputWrapper>
                                <InputWrapper style={{display: 'block', marginLeft: 10}}>
                                    <InputLabel style={{whiteSpace: 'nowrap'}}>
                                        Status
                                    </InputLabel>
                                    <Select
                                        error={!isValid && !status}
                                        helperText={!isValid && 'Niepoprawana wartość'}
                                        style={{marginTop: 3}}
                                        placeholder="Status"
                                        data={statuses}
                                        disabled={archived}
                                        value={status}
                                        onSelect={status => updatePart({uuid, status})}
                                    />
                                </InputWrapper>
                                {!archived && <InputWrapper style={{marginLeft: 10, marginTop: 50}}>
                                    <Button
                                        style={{marginRight: 5}}
                                        onClick={() => updatePart({uuid, archived: true})}
                                    >
                                        Archiwizuj
                                    </Button>
                                </InputWrapper>}
                                <InputWrapper style={{marginLeft: 10, marginTop: 50}}>
                                    <Button
                                        style={{marginRight: 5}}
                                        onClick={() => deletePart({uuid})}
                                    >
                                        - Usuń część
                                    </Button>
                                </InputWrapper>
                            </RowWrapper>
                        </Fragment>
                    ))}
                    {!archived && <Button
                        variant="contained"
                        style={{marginTop: 45, alignSelf: 'flex-end'}}
                        onClick={() => addPart(emptyPart())}>
                        + Dodaj część
                    </Button>}
                </ColumnWrapper>
            </RowWrapper>
        </>
    )
}