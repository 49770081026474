const descFieldsCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'required', numeric: false, disablePadding: false, label: 'wymagane' },
  { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
  {
    id: 'allowOther',
    numeric: false,
    disablePadding: false,
    label: 'Zezwalaj na inne wartości',
  },
];

export default descFieldsCells;
