import { AppBar, Button, InputLabel, makeStyles, TextField } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  API_COMPANIES_ROUTE,
  API_REPAIRS_ROUTE,
  API_NOTES_ROUTE,
  API_PROJECTS_ROUTE,
  API_PROJECT_VARIANTS_ROUTE,
  API_USERS_ROUTE,
  API_OFFERS_ROUTE,
} from '../../../../../constants/routes';
import routerHistory from "../../../../../constants/settings/routerHistory";
import PaperContainer from "../../../../../components/containers/PaperContainer.jsx";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall,
} from "../../../../../components/indicator/Indicator";
import AddTaskPopup from "../../../../../components/popups/AddTaskPopup.jsx";
import BasicTable from "../../../../../components/tables/basic/BasicTable";
import ExchangersTable from "../../../../../components/tables/basic/ExchangersTable";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper.js";
import { tasksHeadCells } from '../../../../../screens/companies/components/details/basic-data/headCells';
import FloatingSaveBar from "../../../../../components/FloatingSaveBar";
import ProjectKrParticipation from '../../kr-participation/ProjectKrParticipation';
import ProjectProvision from '../../provision/ProjectProvision.jsx';
import ProjectVariants from '../../variants/ProjectVariants';
import { projectsDetailsForm } from '../projectsDetailsForm.js';
import { formToRequest } from '../../../../../components/dynamic-forms/field-transformer.js';
import commonValues from '../../../../../tools/commonValues.js';
import OffersData from '../offers-data/OffersData.jsx';
import { STATUS_TYPES } from '../../../../../constants/types/offerTypes.js';
import {debounce} from "lodash";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  sticky: {
    background: '#fafafa',
    boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    display: 'flex',
    top: '54px'
  }
}));

const BasicData = ({ data, edit, initialValues, info, loading, fetchNotes, refreshOffers }) => {
  const projectId = useParams()?.id;

  const classes = useStyles();

  const formRef = useRef();
  const variantsRef = useRef();
  const krRef = useRef();
  const taskPopup = useRef();
  const paymentTransportRef = useRef();
  const provisionRef = useRef();

  const [elRefs, setElRefs] = React.useState([]);

  const [project, setProject] = useState({ name: '', status: '' });
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedProjectKr, setSelectedProjectKr] = useState(null);
  const [selectedProjectCoord, setSelectedProjectCoord] = useState(null);
  const [status, setStatus] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [heatExchangers, setHeatExchangers] = useState(info?.he?.item || []);
  const [replacementInfo, setReplacementInfo] = useState(info.ri);
  const [offers, setOffers] = useState([]);
  const [fromWho, setFromWho] = useState(initialValues?.fromWho);
  const [provisionPeopleFinalized] = useState(initialValues?.provision_people);
  const [repair, setRepair] = useState(null);
  const [offersList, setOffersList] = useState([]);
  const statuses = commonValues.projectStatuses;

  React.useEffect(() => {
    setHeatExchangers(info?.he?.items || []);
    setReplacementInfo(info?.ri || []);
  }, [info]);


  const fetchOffers = async (url = API_OFFERS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {     
      setOffersList(data.items);
    }
  };

  useEffect(() => {
    fetchOffers()
  }, [])

  const submitForm = async () => {
    const callProject = async (fn) => {
      const eObj = {};
      if (edit) eObj.id = data.id;
      const formValues = formRef?.current?.getFormValues();
      const {data : {project: responseProjectId}} = {} = await fn(API_PROJECTS_ROUTE, {
        ...eObj,
        company: selectedCompany,
        projectCoord: selectedProjectCoord,
        projectKr: selectedProjectKr,
        name: project.name,
        status: project.status,
        fields: formToRequest({...formValues, fromWho})
      });
      const v = variantsRef?.current?.getFormValues();
      const p = krRef?.current?.getFormValues();

      if (v?.variants && v?.variants.length && !projectId)
        await postCall(`${API_PROJECT_VARIANTS_ROUTE}/${projectId || responseProjectId}/bulk`, {
          project_variants: v.variants.map((el) => ({
            ...el,
            is_final: el.isFinal,
          })),
        });

      if (p?.participations)
        await postCall(
          `${API_PROJECTS_ROUTE}/${projectId || responseProjectId}/krs`,
          p.participations
          ?.filter((el) => {
              if (el.userEntity && el.type) return el;
            })
          ?.map((el) => ({
            ...el,
            project: projectId,
            percent: el.percent.replace('%', ''),
          })),
        );

      const people = formValues['provision_people'];

      if(people) {
        const peopleEncoded = Array.isArray(people) ? people : JSON.parse(people);
        const provisionDifference = _.differenceWith(peopleEncoded, provisionPeopleFinalized, _.isEqual)

        provisionDifference.map(async (item) => {
          if(item?.provision_finalised) {
            await postCall(API_NOTES_ROUTE, {
              body: `Rozliczono prowizję: Rodzaj prowizji: ${item.provision_people_type} Kwota: ${item.provision_person_amount}`,
              project_id: projectId,
              company_id: item?.provision_people_company?.id || null,
            });
          }
        })
      }

      if (project.status === 'zamowienie' && !repair) {

        await postCall(API_REPAIRS_ROUTE, {
          devices: [],
          project: responseProjectId || Number(projectId),
          fields: [
            {
              name: "value_project",
              value: {
                id: responseProjectId || Number(projectId),
                class: "App\Entity\Project",
                name: project.name,
              },
            },
            {
              name: "serwis_inwestor",
              value: formValues?.inwestor || "",
            }
          ]
        }).then(() => {
          toast.success("Karta serwisowa została utworzona");
        });
      }

      if (data && !status) {
        toast.success(`Zapisano wartości dla projektu ${project.name}`);
        if(responseProjectId) {
          routerHistory.push('/projects/edit/' + responseProjectId)
        }
      }
    };

    if (edit) {
      await callProject(putCall);
      setTimeout(() => window.location.reload(false), 700); 
    } else {
      await callProject(postCall).then(() => {
        routerHistory.push('/projects');
      });
    }


  };

  const [inited, setInited] = useState(false);

  useEffect(() => {

    if(!inited) {

    const fetchUsers = async () => {
      const r = await getCall(API_USERS_ROUTE);
      if (r?.data?.items) {
        setUsers(
          r?.data?.items?.map((el) => ({
            id: el?.id,
            name: el?.email,
          })),
        );
      }

      if (data?.projectKr?.id) {
        setSelectedProjectKr(data?.projectKr?.id);
      }

      if (data?.projectCoord?.id) {
        setSelectedProjectCoord(data?.projectCoord?.id);
      }
    };

    const fetchCompanies = async () => {
      const r = await getCall(API_COMPANIES_ROUTE);
      if (r?.data) {
        setCompanies(
          r?.data?.items.map((i) => ({
            id: i.id,
            name: i.name,
          })),
        );
      }
      if (data?.company?.id) {
        setSelectedCompany(data?.company.id);
      }
    };

    fetchCompanies();
    fetchUsers();
    setProject({
      status: data?.status,
      name: data?.name,
      dateAdded: data?.dateAdded
    });
    
    if(data?.repair) {
      setRepair({
        id: data.id,
        projectName: data.name,
      })
    }

    setInited(true);
  }

  if (data?.tasks) {
    setTasks(
      data?.tasks?.map((i) => ({
        id: i.id,
        project: i?.project?.name,
        created: moment(i?.created?.date).format('YYYY-MM-DD'),
        creator: i?.creator?.username,
        for: i?.for?.username,
        type: i?.type,
      })),
    );
    setTasksCount(data?.tasks?.length);
  }


  }, [data]);

  const returnStatusSelect = (status, id, item) => {
    return (
    <Select
      name="status"
      data={STATUS_TYPES}
      onSelect={(selectedStatus) => updateStatus(id, selectedStatus, item)}
      value={status || 'Nowa'}
    />
    )
  }

const updateStatus = async (id, selectedStatus, item, url = API_OFFERS_ROUTE + `/${id}`) => {
  const data= {
    ...item,
    company: item.company.id,
    status: selectedStatus,
    projectVariant: item.projectVariant.id,
    project: item.project.id,
    owner: item.owner.id,
  }
  const re = await putCall(url, data)
}


const singleOfferDiscountText = (item) => {
  const discounts = []
  const fields = item?.fields?.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
  if (item?.type==='urzadzenia' || item?.type==='peryferia'){
    item.devices.map(device => device.discount ? discounts.push(`${device.discount}%`) : discounts.push(`0%`))
    return [...new Set(discounts)].join(', ')
  }
  if(item?.type==="automatyka"){
    return fields.Rabat ? `${fields.Rabat}%` : null;
  }
}

const discountFormater = (item, offersList) => {
  let discountText
  if (item.type==='scalona'){
    const discountMessageArray = [];
    item.fields.map((field) => {
      const selectedOfferFromMergedOffer = offersList.find((offer) => field?.value?.id===offer.id)
      console.log(offersList)
      console.log('field scalona', selectedOfferFromMergedOffer)
      const returnedMessage = singleOfferDiscountText(selectedOfferFromMergedOffer)
      if(selectedOfferFromMergedOffer && returnedMessage){
        discountMessageArray.push(`${selectedOfferFromMergedOffer?.type.substring(0,3)}: ${returnedMessage}`)   
      }
    })
    discountText = discountMessageArray.join('\n')
  }else{
    discountText = singleOfferDiscountText(item)
  }
  return discountText
}

  React.useEffect(() => {

    setOffers(info?.offers?.map(item => {
      const fields = item?.fields?.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
      const discount = discountFormater(item, offersList)
      return({
      offerNumber: item?.offerNumber,
      company: item?.company?.name,
      representative: fields?.representative?.name,
      owner: item?.owner?.email,
      id: item?.id,
      projectVariant: item.projectVariant?.name || item.projectVariant?.id || '-',
      type: item?.type,
      warranty: fields.gwarancjaLiczba ? fields.gwarancjaLiczba : fields.okresGwarancji ? fields.okresGwarancji : '-',
      discount,
      createdAt: moment(item?.createdAt?.date).format('DD.MM.YYYY'),
      status: returnStatusSelect(item.status, item.id, item),
    })}));

  }, [info?.offers, offersList])

  React.useEffect(() => {
    setStatus(statuses.find((item) => item.id == project.status));
  }, [project.status]);

  const setProjectStatus = (c) => {
    setProject({ ...project, status: c });
    setStatus(c);
  };

  const recountProvisionValues = () => {
    const formValues = formRef?.current?.getFormValues();

    const overallAmount = Number(formValues['provision_amount']);
    const overallExchange = Number(formValues['provision_exchange']?.toString().replace(/,/g, '.'));
    const people = formValues['provision_people'];

    if(people) {
      const peopleEncoded = Array.isArray(people) ? people : JSON.parse(people);

      if(Array.isArray(peopleEncoded)) {
        for(var i = 0; i < peopleEncoded.length; i++) {
          if (!peopleEncoded[i]['provision_calculation']) continue;

          const amount = peopleEncoded[i]['provision_percentage'];
          const vat = peopleEncoded[i]['provision_b2btype'];
          if(amount) {
            peopleEncoded[i]['provision_netto'] = (amount / 100 * (overallAmount * overallExchange)).toFixed(2);
            peopleEncoded[i]['provision_brutto'] = (amount / 100 * ((overallAmount * overallExchange) + ((overallAmount * overallExchange) * 0.23))).toFixed(2);
            if(vat && vat === 'VAT') {
              peopleEncoded[i]['provision_person_amount'] = peopleEncoded[i]['provision_brutto'];
            } else {
              peopleEncoded[i]['provision_person_amount'] = peopleEncoded[i]['provision_netto'];
            }
          }
        }

        const newPeople = JSON.stringify(peopleEncoded);
        if(formRef?.current && formRef?.current?.setFormValue) {
          formRef.current.setFormValue('provision_people', newPeople);
        }
      }

    }
  }

  const generateProvisionNote = async (i) => {
    const formValues = formRef?.current?.getFormValues();

    const people = formValues['provision_people'];

    if(people) {
      const peopleEncoded = Array.isArray(people) ? people : JSON.parse(people);

      if(Array.isArray(peopleEncoded) && peopleEncoded[i]) {

          const b2bType = peopleEncoded[i]['provision_b2btype'];
          const amount = b2bType === 'zw. VAT' ? peopleEncoded[i]['provision_netto'] || 0 : peopleEncoded[i]['provision_brutto'];
          const type = peopleEncoded[i]['provision_people_type'];
          const companyId = peopleEncoded[i]['provision_people_company']?.id || null;

          const { data } = await postCall(API_NOTES_ROUTE, {
            body: `Wyliczono prowizje: Rodzaj prowizji: ${type} Kwota: ${amount}`,
            project_id: projectId,
            company_id: companyId,
          });
          if (data) {
            fetchNotes();
            toast.success('Dodano notatkę.');
          }
      }
    }
  }

  const onChangeProvision = (f, v) => {

    switch(f) {
      case 'provision_percentage':
      case 'provision_amount':
      case 'provision_exchange':
      case 'provision_b2btype':

        setTimeout(() => {
          recountProvisionValues();
        }, 600);

        break;
      case 'provision_generate':

        if(v && v.clicked >= 0) {
          setTimeout(() => {
            generateProvisionNote(v.clicked);
          }, 600);
        }

        break;
      default: break;
    }
  }

  const tabs = {
    zadania: {
      label: 'Zadania',
      children: (
        <ColumnWrapper fullWidth>
          <AddTaskPopup ref={taskPopup} projectId={projectId} />
          <Button
            startIcon={<DoneAllIcon />}
            style={{ color: 'white', alignSelf: 'flex-end', marginBottom: 20 }}
            variant="contained"
            disabled={!projectId}
            onClick={() => taskPopup.current.open()}
            color="secondary">
            Zadanie
          </Button>
          <BasicTable
            headCells={tasksHeadCells}
            rows={tasks}
            onRowClick={(t) => routerHistory.push(`/tasks/edit/${t?.id}`)}
            onChangePage={() => {}}
            recordsCount={tasksCount}
          />
        </ColumnWrapper>
      ),
    },
    wymienniki: {
      label: 'Wymienniki',
      children: (
        <>
          <div className={'uniqueName'}>
            <ExchangersTable
              headCells={tasksHeadCells}
              projectId={projectId}
              rows={heatExchangers}
              onRowClick={(t) =>
                routerHistory.push(
                  `/projects/${projectId}/heat-exchangers/edit/${t?.id}`,
                )
              }
              onChangePage={() => {}}
              recordsCount={tasksCount}
              projectName={project?.name}
            />
          </div>
          <ColumnWrapper fullWidth>
            <DynamicForm avoidTabs small ref={formRef} fields={projectsDetailsForm.wymienniki} initialValues={initialValues} />
          </ColumnWrapper>
        </>
      ),
    },
    warianty: {
      label: 'Warianty',
      children: <div><ProjectVariants variants={info?.variants} projectId={projectId} ref={variantsRef} loading={loading} /></div>,
    },
    kr: {
      label: 'Udział KR',
      children: <div><ProjectKrParticipation krs={info?.krs} projectId={projectId} ref={krRef} /></div>,
    },
    prowizja: {
      label: 'Prowizja',
      children: <div><ProjectProvision projectId={projectId} ref={provisionRef} formRef={formRef} /></div>,
    },
    platnosci: {
      label: 'Płatności i Transport',
      children: <DynamicForm avoidTabs small ref={formRef} fields={projectsDetailsForm.platnosci} initialValues={initialValues} />,
    },
    oferty: {
      label: 'Oferty',
      tabId: 'offerTab',
      children: (
        <ColumnWrapper fullWidth id="offerTable">
          <OffersData offers={offers} projectId={projectId} refreshOffers={refreshOffers} onRowClick={({ id }) => routerHistory.push(`/offers/edit/${id}`)} />
        </ColumnWrapper>
      ),
    },
  };

  const [topOptions, setTopOptions] = useState([
    { label: 'Dane podstawowe', id: 'main', active: true },
    { label: 'Parametry', id: 'parameters', active: false },
    { label: 'Warianty', id: 'variants', active: false },
    { label: 'Oferty', id: 'offers', active: false },
    { label: 'Wymienniki', id: 'wymienniki', active: false },
    { label: 'Pliki', id: 'files', active: false },
  ]);

  const [bottomOptions, setBottomOptions] = useState([
    { label: 'Przetarg', id: 'przetarg', active: false },
    { label: 'Zamówienie', id: 'zamowienie', active: false },
    { label: 'Dostawa', id: 'dostawa', active: false },
    { label: 'Płatności i transport', id: 'platnosci', active: false },
    { label: 'Prowizja', id: 'prowizja', active: false },
    { label: 'Marketing', id: 'marketing', active: false },
  ]);

  let ranges = [];

  useEffect(() => {
    setTimeout(() => {
      ranges = [ { label: 'Dane podstawowe', id: 'main', active: true },
        { label: 'Parametry', id: 'parameters', active: false },
        { label: 'Warianty', id: 'variants', active: false },
        { label: 'Oferty', id: 'offers', active: false },
        { label: 'Wymienniki', id: 'wymienniki', active: false },
        { label: 'Pliki', id: 'files', active: false },
        { label: 'Przetarg', id: 'przetarg', active: false },
        { label: 'Zamówienie', id: 'zamowienie', active: false },
        { label: 'Dostawa', id: 'dostawa', active: false },
        { label: 'Płatności i transport', id: 'platnosci', active: false },
        { label: 'Prowizja', id: 'prowizja', active: false },
        { label: 'Marketing', id: 'marketing', active: false },
      ].map(({id}) => ({id, offset: document.querySelector(`#${id}`)?.offsetTop}))
    }, 5000)

  }, [loading]);

  const [currentSection, setCurrentSection] = useState('main');

  const handleSetSelectedSection = value => {
    if(value !== currentSection && value) {
      setCurrentSection(value)
    }
  };

  useMemo(() => window.addEventListener("scroll", debounce(() => {
    if(ranges?.length) {
      let offset = window.pageYOffset + 50;

      if(offset <= ranges[0]?.offset) {
        setCurrentSection(ranges[0]?.id)
      } else {
        const section = ranges.reduce((currentSection, section, index) => {
          const currentDiff = Number(currentSection.offset) - offset;
          const thisDiff = Number(section.offset) - offset;
          if ((thisDiff > 0 && currentDiff > 0 && thisDiff <= currentDiff) || currentDiff < 0) {
            return section
          }
          return currentSection
        }, ranges[0]);

        handleSetSelectedSection(section?.id)
      }
    }

  }, 20)),[ranges, currentSection]);

  useEffect(() => {
    if(currentSection) {
      setTopOptions(current => current.map(section => ({...section, active: section.id === currentSection})));
      setBottomOptions(current => current.map(section => ({...section, active: section.id === currentSection})))
    }
  }, [currentSection]);

  return (
    <>
      <ColumnWrapper fullWidth>
        <AppBar color="transparent" className={classes.sticky} position="sticky">
          <RowWrapper>
            {topOptions.map(item => (<ColumnWrapper key={item.label} size="auto" style={{flex: '1 1 0', padding: '3px'}}><Button color={item.active ? 'primary' : 'inherit'} onClick={(e) => document.getElementById(item.id).scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{fontSize: '10px', padding: '3px'}}>{item.label}</Button></ColumnWrapper>))}
          </RowWrapper>
          <RowWrapper>
            {bottomOptions.map(item => (<ColumnWrapper key={item.label} size="auto" style={{flex: '1 1 0', padding: '3px'}}><Button color={item.active ? 'primary' : 'inherit'} onClick={(e) => document.getElementById(item.id).scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{fontSize: '10px', padding: '3px'}}>{item.label}</Button></ColumnWrapper>))}
          </RowWrapper>
        </AppBar>
        <PaperContainer title="Dane podstawowe">
          <a id="main" />
          <ColumnWrapper fullWidth>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}>
              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
                <InputLabel style={{ marginTop: 10 }} shrink htmlFor={'name'}>
                  {'Nazwa projektu'}
                </InputLabel>
                <TextField
                  value={project.name || ''}
                  variant="outlined"
                  onChange={({ target }) =>
                    setProject({ ...project, name: target.value })
                  }
                />
              </ColumnWrapper>
              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
              <Select
                data={statuses}
                variant="outlined"
                onSelect={(c) => setProjectStatus(c)}
                value={project.status}
                name="status"
                label="Status"
                style={{ width: '100%' }}
              /></ColumnWrapper>
              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
              <Select
                data={users}
                variant="outlined"
                onSelect={(c) => setSelectedProjectKr(c)}
                value={selectedProjectKr}
                name="projectKr"
                label="Projekt KR"
                style={{ width: '100%' }}
              /></ColumnWrapper>
              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
              <Select
                data={users}
                variant="outlined"
                onSelect={(c) => setSelectedProjectCoord(c)}
                value={selectedProjectCoord}
                name="projectCoord"
                label="Koordynator"
                style={{ width: '100%' }}
              /></ColumnWrapper>
              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
              <Select
                data={companies}
                variant="outlined"
                onSelect={(c) => setSelectedCompany(c)}
                value={selectedCompany}
                name="company"
                label="Firma zgłaszająca projekt"
                style={{ width: '100%' }}
              /></ColumnWrapper>

              <ColumnWrapper
                  style={{ width: '25%', justifyContent: 'flex-end' }}>
                <InputLabel style={{ marginTop: 10 }} shrink htmlFor={'fromWho'}>
                  {'Od kogo'}
                </InputLabel>
                <TextField
                    value={fromWho}
                    onChange={({target: {value}}) => setFromWho(value)}
                    variant="outlined"
                />
              </ColumnWrapper>

              <ColumnWrapper
                style={{ width: '25%', justifyContent: 'flex-end' }}>
              <InputLabel style={{ marginTop: 10 }} shrink htmlFor={'name'}>
                  {'Data dodania'}
              </InputLabel>
              <TextField
                  value={project.dateAdded ? project.dateAdded : 'b/d'}
                  variant="outlined"
                  disabled={true}
                />
              </ColumnWrapper>
            </div>
            <div>
              <DynamicForm avoidTabs small ref={formRef} fields={projectsDetailsForm.ogolne} initialValues={initialValues} />
            </div>
            <div>
              <RowWrapper>
                <ColumnWrapper size="100%" style={{padding: '0px', width: '100%'}}>
                  <PaperContainer title={tabs.kr.label}>
                    {tabs.kr.children}
                  </PaperContainer>
                </ColumnWrapper>
              </RowWrapper>
            </div>
            <div>
              <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.plany} initialValues={initialValues} />
            </div>
          </ColumnWrapper>
        </PaperContainer>
        <a id="parameters" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.parametry} initialValues={initialValues} />
        <a id="variants" />
        <PaperContainer title={tabs.warianty.label}>
          {tabs.warianty.children}
        </PaperContainer>
        <a id="offers" />
        <PaperContainer title={tabs.oferty.label}>
          {tabs.oferty.children}
        </PaperContainer>
        <a id="wymienniki" />
        <PaperContainer title={tabs.wymienniki.label}>
          {tabs.wymienniki.children}
        </PaperContainer>
        <a id="files" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.pliki} initialValues={initialValues} />
        <a id="przetarg" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.przetargi} initialValues={initialValues} />
        <a id="zamowienie" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.zamowienie} initialValues={initialValues} />
        <a id="dostawa" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.dostawa} initialValues={initialValues} />
        <a id="platnosci" />
        <PaperContainer title={tabs.platnosci.label}>
          {tabs.platnosci.children}
        </PaperContainer>
        <a id="prowizja" />
        <PaperContainer title={tabs.prowizja.label}>
          <DynamicForm avoidTabs small ref={formRef} fields={projectsDetailsForm.prowizja} onChangeMiddleware={onChangeProvision} initialValues={initialValues} />
        </PaperContainer>
        <a id="marketing" />
        <DynamicForm avoidTabs containers small ref={formRef} fields={projectsDetailsForm.marketing} initialValues={initialValues} />

      </ColumnWrapper>
      <FloatingSaveBar disabled={!project.name} onClick={submitForm} />
    </>
  );
};

export default BasicData;
