import React, {useEffect, useMemo, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import {
  API_COMPANIES_ROUTE,
  API_PROJECTS_ROUTE,
  API_REPAIRERS_ROUTE,
  API_REPAIRS_ROUTE
} from '../../../../../constants/routes';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall
} from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import FloatingSaveBar from "../../../../../components/FloatingSaveBar";
import repairsFields from './repairsFields';
import {useHistory, useParams} from "react-router-dom";
import { formToRequest } from '../../../../../components/dynamic-forms/field-transformer';
import repairsFieldsWarrantyCard from "./repairsFieldsWarrantyCard";

const BasicData = ({ data, edit, onEditProject, initialValues }) => {
  const formRef = useRef();
  const formRef2 = useRef();
  const [repairer, setRepairer] = useState(null);
  const [repairers, setRepairers] = useState([]);
  const [project, setProject] = useState('');
  const [projectInfo, setProjectInfo] = useState(null);
  const [projects, setProjects] = useState([]);
  const [investor, setInvestor] = useState(null);
  const history = useHistory();

  const { id } = useParams();

  const findProject = searchesId => availableProjects.find(({id}) => id === searchesId);

  const submitForm = async () => {
    const formValues = {...formRef.current.getFormValues(), warrantyCard: formRef2.current.getFormValues()?.warrantyCard};
    const callRepairer = async (fn) => {
      const eObj = {};
      if (edit) eObj.id = data.id;
      const r = await fn(API_REPAIRS_ROUTE, {
        ...eObj,
        project: formValues.value_project?.id,
        repairer: formValues.value_repairer?.id,
        devices: data?.devices || [],
        fields: formToRequest({...(initialValues?.serwis_koszty || []), ...formValues}),
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla przeglądu`);
      }
    };

    if (edit) {
      await callRepairer(putCall);
    } else {
      await callRepairer(postCall);
    }
    // history.push('/service/repairs')
  };

  useEffect(() => {
    const fetchProject = async () => {
      const { data } = await getCall(`${API_PROJECTS_ROUTE}/${project.id || project}/full`);
      if (data) {
        setProjectInfo(data);
      }
    };

    // const fetchProjects = async () => {
    //   const { data } = await getCall(API_PROJECTS_ROUTE);
    //   if (data) {
    //     const d = data?.items?.map((item) => ({
    //       name: item?.name,
    //       id: item?.id,
    //       repair: item?.repair || null,
    //       devices: item?.devices || [],
    //     }));
    //     setProjects(d);
    //   }
    // };

    // const fetchRepairers = async () => {
    //   const { data } = await getCall(API_REPAIRERS_ROUTE);
    //   if (data) {
    //     const d = data?.items?.map((item) => ({
    //       name: item?.name,
    //       id: item?.id,
    //     }));
    //     setRepairers(d);
    //   }
    // };
    project && fetchProject();
    // fetchProjects();
    // fetchRepairers();
  }, [project]);

  useEffect(() => {
    if (projectInfo) {
      setInvestor(projectInfo.project.fields.find(item => item.name === 'inwestor'))
    }
  }, [projectInfo])

  useEffect(() => {
    data?.repairer?.id && setRepairer(data.repairer.id);
    data?.project?.id && setProject(data.project.id)
  }, [data]);

  useEffect(() => onEditProject(findProject(project)), [project]);

  const onChangeForm = (key, value) => {
    const action = {
      value_project: setProject
    }[key];

    typeof action === 'function' && action(value)
  };

  const availableProjects = useMemo(() =>
          projects.filter(({repair}) => repair === null || (repair && repair.id == id)),
      [projects, id]);

  return (
    <>
      <a id="main" />
      <ExpansionContainer title="Dane podstawowe">
        <RowWrapper style={{width: '100%'}}>
          <ColumnWrapper fullwidth>
            <DynamicForm ref={formRef} fields={repairsFields} initialValues={initialValues} avoidTabs={true} onChangeMiddleware={onChangeForm} />
          </ColumnWrapper>
        </RowWrapper>
      </ExpansionContainer>
      <FloatingSaveBar onClick={submitForm} />
      <>
        <a id="warranty-card" />
        <ExpansionContainer title="Karta gwarancyjna">
          <ColumnWrapper fullWidth>
            <DynamicForm ref={formRef2} fields={repairsFieldsWarrantyCard} initialValues={initialValues} avoidTabs={true}  />
          </ColumnWrapper>
        </ExpansionContainer>
      </>
    </>
  );
};

export default BasicData;
