import React, {useEffect, useMemo, useRef, useState} from "react";
import DynamicTable from "../../../../../components/tables/basic/dynamic-table/DynamicTable";
import {getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCT_PRODUCER_ROUTE} from "../../../../../constants/routes";
import Loader from "../../../../../components/loader/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import serializeQuery from "../../../../../tools/serializeQuery";
import {Collapse, TableCell} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CategoryTable from "./CategoryTable";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';
import AddEditPopup from "../../add-edit/producer/AddEditPopup";

export default ({additionalFilters, headerStyles, groupId}) => {
    const [loading, setLoading] = useState(true);
    const [producers, setProducers] = useState([]);
    const [filters, setFilters] = useState({});
    const [editId, setEditId] = useState(null);
    const popupRef = useRef();

    const handleAdd = () => {
        setEditId(null);
        popupRef.current.open();
    };

    const handleEdit = id => {
        setEditId(id);
        popupRef.current.open();
    };

    const columns = [
        {header: 'Producent', key: 'name'},
        {
            header: <div style={{display: 'flex', alignItems: 'center'}} onClick={handleAdd}>
                Akcje
                <Tooltip title="Dodaj producenta">
                    <AddIcon style={{cursor: 'pointer'}} fontSize="small"/>
                </Tooltip>
            </div>,
            key: 'actions',
            sort: false
        },
    ];

    const findProducerIndex = producer => producers.findIndex(({id}) => id === producer?.id);

    const updateProducers = (newProducer, index) => {
        const newProducers = producers.map(producer => ({...producer}));
        newProducers.splice(index, 1, newProducer);
        setProducers(() => newProducers);
    };

    const stringParams = useMemo(
        () => serializeQuery({...additionalFilters, ...filters}),
        [filters, additionalFilters, serializeQuery]
    );

    const fetchProducers = () =>
        getCall(`${API_PRODUCT_PRODUCER_ROUTE}?${stringParams}`)
            .then(({data: {items = []} = {}}) => setProducers(items));

    const appendCategories = producer => {
        const index = findProducerIndex(producer);
        if (~index) {
            const isOpen = !producer?.isOpen;
            
            updateProducers(
                {
                    ...producer,
                    isOpen,
                    attachedData:
                        <TableCell style={{padding: 0}} colSpan={2}>
                            <Collapse in={isOpen} timeout="auto">
                                <Box margin={1} style={{marginRight: 0}}>
                                    <CategoryTable headerStyles={headerStyles}
                                                   additionalFilters={{producer: producer.id} }
                                                   groupId={groupId} 
                                                   />
                                </Box>
                            </Collapse>
                        </TableCell>
                },
                index
            )
        }
    };

    useEffect(() => {
        fetchProducers().then(() => setLoading(false))
    }, []);

    const reload = async () => {
        setLoading(true);
        await fetchProducers();
        setLoading(false)
    };

    const displayName = type =>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {type?.isOpen
                ? <ArrowUpwardIcon fontSize="small"/>
                : <ArrowDownwardIcon fontSize="small"/>
            }
            {type.name}
        </div>;

    const displayActions = producer => <>
        <Tooltip title={`Podgląd ${producer.name}`} placement="top">
            <IconButton onClick={() => appendCategories(producer)}>
                <VisibilityIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
        <Tooltip title={`Edycja ${producer.name}`} placement="top">
            <IconButton onClick={() => handleEdit(producer?.id)}>
                <EditIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    </>;

    return <>
        {
            loading
                ? <Loader/>
                : <>
                    <DynamicTable
                        headerStyles={headerStyles}
                        columns={columns}
                        rows={producers}
                        showEmptyInfo={!producers?.length}
                        onClickRow={appendCategories}
                    >
                        {{
                            name: displayName,
                            actions: displayActions,
                        }}
                    </DynamicTable>
                    <AddEditPopup
                        producerId={editId}
                        ref={popupRef}
                        onSave={reload}
                    />
                </>
        }
    </>
};