import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles, Paper, TextField } from '@material-ui/core';
import { Mail, MeetingRoom, People, Phone, SettingsPhone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import plLocale from 'date-fns/locale/pl';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import commonValues from '../../../../../tools/commonValues';
import EntityField from '../../../../../components/dynamic-forms/form-components/EntityField';
import { OFFER_TYPES, STATUS_TYPES } from '../../../../../constants/types/offerTypes';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

const OfferSearchFilters = (props) => {
    const classes = useStyles();
    const onChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            newFilters[name] = val;
        } else {
            delete newFilters[name];
        }
        setFilters(newFilters);
    }

    const onStatusChange = (val, exists) => {
        const newFilters = filters;
        if(newFilters['statuses'].includes(val) && !exists) {
            newFilters['statuses'] = newFilters['statuses'].filter(item => item != val);
        } else {
            newFilters['statuses'].push(val);
        }
        setFilters(newFilters);
    }

    const onTypesChange = (val, exists) => {
        const newFilters = filters;
        if(newFilters['types'].includes(val) && !exists) {
            newFilters['types'] = newFilters['types'].filter(item => item != val);
        } else {
            newFilters['types'].push(val);
        }
        setFilters(newFilters);
    }

    const onQueryFieldsChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            filters.queryFields[name] = val;
        } else {
            delete filters.queryFields[name];
        }
        setFilters(newFilters);
    }

    const [filters, setFilters] = useState({ 
        queryFields: {},
        statuses: [],
        types: [],
    });

    const [ostatniOd, setOstatniOd] = useState(null);
    const [ostatniDo, setOstatniDo] = useState(null);
    const [uzytkownik, setUzytkownik] = useState(null);
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);

    return (
        <>
        <Paper className={classes.paper}>

        <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={'Numer oferty'}
            margin="dense"
            onChange={({ target }) => onChange('query', target.value)}
            />

        <EntityField
            entityClass={'project'}
            placeholder={'Projekty'}
            multiple={true}
            value={projects}
            label={'Projekty'}
            onSelect={(v) => onChange('projects', v?.map(item => item.id) || null)}
            />

        <EntityField
            entityClass={'company'}
            placeholder={'Firmy'}
            multiple={true}
            value={companies}
            label={'Firmy'}
            onSelect={(v) => onChange('companies', v?.map(item => item.id) || null)}
            />

        
        <EntityField
            entityClass={'user'}
            placeholder={'Użytkownik'}
            label={'Twórcy'}
            value={uzytkownik ? uzytkownik : null}
            onSelect={(v) => onChange('user', v?.id || null)}
            />
        <FormControlLabel
            control={<Checkbox name={'mine'} style={{padding: '0 8px'}} onChange={(e) => onChange('mine', e.target.checked ? true : null)} />}
            label={'Tylko utworzone przeze mnie'}
        />


            <Divider style={{margin: '1em 0px'}} />

        Status:

        <FormGroup>
            {STATUS_TYPES.map(item => (
                <FormControlLabel
                key={item.id+'_sfcl'}
                    control={<Checkbox name={item.name.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onStatusChange(item.id, e.target.checked)} />}
                    label={item.name}
                />
            ))}
        </FormGroup>

            <Divider style={{margin: '1em 0px'}} />

        Typ:

        <FormGroup>
            {OFFER_TYPES.map(item => (
                <FormControlLabel
                key={item+'_tfcl'}
                    control={<Checkbox name={item.name.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onTypesChange(item.id, e.target.checked)} />}
                    label={item.name}
                />
            ))}
        </FormGroup>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                label="Utworzono od"
                name={'status_od'}
                format="dd/MM/yyyy"
                value={ostatniOd || null}
                inputVariant="outlined"
                ampm={false}
                onChange={(val) => {
                    setOstatniOd(val);
                    onChange('status_od', val ? Date.parse(val.toDateString()) : null)
                }}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                onChange={(val) => {
                    setOstatniDo(val);
                    const day = 24 * 60 * 60 * 1000;                    
                    const newTimestamp = Date.parse(val.toDateString()) + day - 1000
                    const newDay = new Date(newTimestamp);
                    onChange('status_do', val ? newDay.getTime() : null)
                }}
                label="Utworzono do"
                name={'status_do'}
                value={ostatniDo || null}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
            />
        </MuiPickersUtilsProvider>

        <PrimaryButton
            style={{ marginLeft: 0, marginTop: 10}}
            color="secondary"
            onClick={() => props?.handleSearch(filters)}>
            Szukaj
        </PrimaryButton>
        </Paper>
        </>
    );
};

export default OfferSearchFilters;
