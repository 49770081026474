import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    TextField,
    Typography
} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';
import {API_DIRECTORY_ROUTE} from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {postFileCall} from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import {toast} from "react-toastify";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {transformDocument} from "../../tools/fetchDirectoryContent";
import {DIR_TYPE} from "../../../../constants/directories/types";
import uniqueId from "../../../../tools/uniqueId";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

export default ({parentId, directory, onSave, buttonText, children, buttonSize = 'small', buttonTooltip = 'Dodaj folder do tego katalogu'}) => {

    const {button: Button} = children || {};
    const [isOpen, setOpen] = useState(false);
    const [name, setName] = useState('');

    const handleClose = () => {
        setOpen(false);
        setName('')
    };
    const handleOpen = () => setOpen(true);

    const handleSave = async () => {
        try {
            const formData = new FormData();

            formData.append('name', name);
            if (directory) formData.append('parent', directory.toString());

            if(parentId) {
                formData.append('parent', parentId);
            }

            const {data: {attachment_directory: newDirectory} = {}} = await postFileCall(
                API_DIRECTORY_ROUTE,
                formData,
                undefined,
                undefined,
                true,
            );

            if (onSave && newDirectory) {
                onSave(transformDocument({type: DIR_TYPE, uuid: uniqueId()})(newDirectory));
            }

            handleClose();
        } catch (error) {
            handleClose();
            toast.error('Wystąpił błąd podczas zapisu.');
        }
    };

    return <>
        {Button
            ? <Button handleOpen={handleOpen}/>
            : <Tooltip title={buttonTooltip} placement="top">
                <IconButton onClick={handleOpen}>
                    <CreateNewFolderIcon fontSize={buttonSize}/>
                    {buttonText}
                </IconButton>
            </Tooltip>
        }
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <Container>
                    <Typography
                        style={{fontWeight: 'bold', margin: '0 0 20px'}}
                        variant="body1">
                        Dodawanie katalogu
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Nazwa"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Container>
            </DialogContent>
            <Divider/>
            <DialogActions style={{padding: 20}}>
                <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
                    Anuluj
                </PrimaryButton>
                <PrimaryButton onClick={handleSave}>Zapisz</PrimaryButton>
            </DialogActions>
        </Dialog>
    </>
}
