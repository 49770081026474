import {makeStyles} from "@material-ui/core";

export const UseActionZoneStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableHead: {
        '& th': {
            border: '1px solid rgba(224, 224, 224, 1)',
            textAlign: 'center',
            fontSize: '10px',
        }
    },
    tableBodyRow: {
        '& td': {
            padding: 1,
        },
        '& input': {
            minWidth: 30
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    rowObject: {
        cursor: 'pointer',
    },
    add: {
        marginTop: '1em'
    }
}));