const initialFormSectionDrivers = {
  ['WS/GS']: '',
  Grzanie: '',
  Jednoczesność: '',
  Chłodzenie: '',
  CWU: '',
  Odbiory: '',
  Szczyt: '',
  Caldaria: '',
  Alternatywa: '',
  ['CWU Wymiennik']: '',
  ['Grzanie Glikol - Woda']: false,
  ['Chłodzenie Glikol - Woda']: false,
  ['Pompa ładowania Bufor grzanie']: '',
  ['Pompa ładowania Bufor chłód']: '',
  ['Pompa ładowania zasobnik CWU']: '',
  ['Siłownik przełączający  ładowania bufora']: '',
  ['Siłownik przełączający  ładowania rozdzielaczy']: '',
  ['Siłownik przełączający CO/CWU']: '',
  ['Siłownik odcinający CWU']: '',
  ['Zasobnik CWU - ilość']: '',
  ['Załączanie szczytu cieplo']: '',
  ['Załączanie szczytu chlod']: '',
  ['Załączanie szczytu CWU']: '',
}

const controlMethods = (wsGs) => {
  if(wsGs?.checked) {
    return [
      { label: 'Pompa wspólna strona pierwotna', name: 'Pompa wspólna strona pierwotna', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą wspólną strony pierwotnej/ zasilanie i sterowanie (wartość z ilości) pompami wspólnymi strony pierwotnej' }, value: '', },
      { label: 'Pompa wspólna strona pierwotna - ilość', name: 'Pompa wspólna strona pierwotna - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      { label: 'Pompa dolne zródło ', name: 'Pompa dolne zródło ', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą dolnego źródła/ zasilanie i sterowanie (wartość z ilości) pompami dolnego źródła' }, value: '', },
      { label: 'Pompa dolne zródło  - ilość', name: 'Pompa dolne zródło  - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
    ];
  } else {
    return [
      { label: 'Pompa wspólna strona pierwotna', name: 'Pompa wspólna strona pierwotna', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą wspólną strony pierwotnej/ zasilanie i sterowanie (wartość z ilości) pompami wspólnymi strony pierwotnej' }, value: '', },
      { label: 'Pompa wspólna strona pierwotna - ilość', name: 'Pompa wspólna strona pierwotna - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      { name: 'Pompa dolne zródło ', type: 'none' },
      { name: 'Pompa dolne zródło  - ilość', type: 'none' },
    ];
  }
}

const controlMethodsCd2 = (wsGs) => {
  if(wsGs?.checked) {
    return [
      { label: 'Pasywne chłodzenie', name: 'Pasywne chłodzenie', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie pasywnym chłodzeniem' }, value: '', },
      { label: 'Pasywne chłodzenie - ilość', name: 'Pasywne chłodzenie - ilość', type: 'text', value: '', },
    ];
  } else {
    return [
      { name: 'Pasywne chłodzenie', type: 'none' },
      { name: 'Pasywne chłodzenie - ilość', type: 'none' },
    ];
  }
}

const controlMethodsCd3_1 = (Grzanie, Jednoczesność) => {
  if(Grzanie?.checked || Jednoczesność?.checked) {
    return { 
      label: 'Grzanie Glikol - Woda', name: 'Grzanie Glikol - Woda', type: 'offerTextCheckbox', value: '', options: { defaultText: 'zapewnienie medium grzewczego', value: ''}
    }
  } else {
    return { name: 'Grzanie Glikol - Woda', type: 'none' };
  }
}

const controlMethodsCd3_2 = (Chłodzenie, Jednoczesność) => {
  if(Chłodzenie?.checked || Jednoczesność?.checked) {
    return { 
      label: 'Chłodzenie Glikol - Woda', name: 'Chłodzenie Glikol - Woda', type: 'offerTextCheckbox', value: '', options: { defaultText: 'zapewnienie medium chłodniczego', value: '' }
    }
  } else {
    return { name: 'Chłodzenie Glikol - Woda', type: 'none' };
  }
}

const controlMethodsCd3_3 = (CWU) => {
  if(CWU?.checked) {
    return { 
      label: 'CWU Wymiennik', name: 'CWU Wymiennik', type: 'offerTextCheckbox', value: '', options: { defaultText: 'zapewnienie medium grzewczego do modułu ciepłej wody użytkowej', value: '' }
    }
  } else {
    return { name: 'CWU Wymiennik', type: 'none' };
  }
}

const controlMethodsCd6 = (grzanieGlikolWoda, Caldaria, pompaŁadowaniaBuforGrzanie) => {
  if(grzanieGlikolWoda?.checked || Caldaria?.checked) {
    if(pompaŁadowaniaBuforGrzanie?.checked) {
      return [
        { label: 'Pompa ładowania Bufor grzanie', name: 'Pompa ładowania Bufor grzanie', type: 'offerTextCheckbox', options: { textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą łądującą zład medium grzewczego/ zasilanie i sterowanie (ilość) pompami łądującymi zład medium grzewczego', width: 5.2 }, value: '', },
        { label: 'Pompa ładowania Bufor grzanie - ilość', name: 'Pompa ładowania Bufor grzanie - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Pompa Ladowania cieplo 0-10V', name: 'Pompa Ladowania cieplo 0-10V', type: 'offerTextCheckboxDouble', options: 
          {   
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie sygnałem analogowym 0-10V pompą łądującą zład medium grzewczego/ zasilanie i sterowanie sygnałem analogowym 0-10V (ilość) pompami łądującymi zład medium grzewczego',
              defaultText2: 'pompa obiegowa ładująca zład medium grzewczego z możliwością sterowania sygnałem analogowym 0-10V/pompy obiegowe ładujące zład medium grzewczego z możliwością sterowania sygnałem analogowym 0-10V',
          }, 
          value: '', 
        },
      { label: 'Pompa Ladowania cieplo 0-10V - ilość', name: 'Pompa Ladowania cieplo 0-10V - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
        return [
          { label: 'Pompa ładowania Bufor grzanie', name: 'Pompa ładowania Bufor grzanie', type: 'offerTextCheckbox', options: { textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą łądującą zład medium grzewczego/ zasilanie i sterowanie (ilość) pompami łądującymi zład medium grzewczego', width: 5.2 }, value: '', },
          { label: 'Pompa ładowania Bufor grzanie - ilość', name: 'Pompa ładowania Bufor grzanie - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
          { name: 'Pompa Ladowania cieplo 0-10V', type: 'none' },
          { name: 'Pompa Ladowania cieplo 0-10V - ilość', type: 'none' },
        ];
      }
  } else {
      return [
        { name: 'Pompa ładowania Bufor grzanie', type: 'none' },
        { name: 'Pompa ładowania Bufor grzanie - ilość', type: 'none' },
        { name: 'Pompa Ladowania cieplo 0-10V', type: 'none' },
        { name: 'Pompa Ladowania cieplo 0-10V - ilość', type: 'none' }
      ];
    }
}

const controlMethodsCd7 = (chlodzenieGlikolWoda, pompaŁadowaniaBuforChlod) => {
  if(chlodzenieGlikolWoda?.checked) {
    if(pompaŁadowaniaBuforChlod?.checked) {
      return [
        { label: 'Pompa ładowania Bufor chłód', name: 'Pompa ładowania Bufor chłód', type: 'offerTextCheckbox', options: { textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą łądującą zład medium chłodniczego/ zasilanie i sterowanie (ilość) pompami łądującymi zład medium chłodniczego', width: 5.2 }, value: '', },
        { label: 'Pompa ładowania Bufor chłód- ilość', name: 'Pompa ładowania Bufor chłód - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Pompa Ladowania chłód 0-10V', name: 'Pompa Ladowania chłód 0-10V 2', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie sygnałem analogowym 0-10V pompą łądującą zład medium chłodniczego/ zasilanie i sterowanie sygnałem analogowym 0-10V (ilość) pompami łądującymi zład medium chłodniczego',
              defaultText2: 'pompa obiegowa ładująca zład medium chłodniczego z możliwością sterowania sygnałem analogowym 0-10V/pompy obiegowe łądujące zład medium chłodniczego z możliwością sterowania sygnałem analogowym 0-10V',
          }, 
          value: '', 
        },
        { label: 'Pompa Ladowania chłód 0-10V - ilość', name: 'Pompa Ladowania chłód 0-10V  2 - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ];
    } else {
      return [
        { label: 'Pompa ładowania Bufor chłód', name: 'Pompa ładowania Bufor chłód', type: 'offerTextCheckbox', options: { textWidth: '450px', width: 5.2, defaultText: 'zasilanie i sterowanie pompą łądującą zład medium chłodniczego/ zasilanie i sterowanie (ilość) pompami łądującymi zład medium chłodniczego' }, value: '', },
        { label: 'Pompa ładowania Bufor chłód- ilość', name: 'Pompa ładowania Bufor chłód - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Pompa Ladowania chłód 0-10V 2', type: 'none' },
        { name: 'Pompa Ladowania chłód 0-10V  2 - ilość', type: 'none' },
      ];
    }
  } else {
    return [
      { name: 'Pompa ładowania Bufor chłód', type: 'none' },
      { name: 'Pompa ładowania Bufor chłód - ilość', type: 'none' },
      { name: 'Pompa Ladowania chłód 0-10V 2', type: 'none' },
      { name: 'Pompa Ladowania chłód 0-10V  2 - ilość', type: 'none' },
    ];
  }
}

const controlMethodsCd8 = (CWUwymiennik, pompaŁadowaniaZasobnikCWU) => {
  if(CWUwymiennik?.checked) {
    if(pompaŁadowaniaZasobnikCWU?.checked) {
      return [
        { label: 'Pompa ładowania zasobnik CWU', name: 'Pompa ładowania zasobnik CWU', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą łądującą zład ciepłej wody użytkowej/ zasilanie i sterowanie (ilość) pompami łądującymi zład zasobnika ciepłej wody użytkowej' }, value: '', },
        { label: 'Pompa ładowania zasobnik CWU - ilość', name: 'Pompa ładowania zasobnik CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Pompa Ladowania CWU 0-10V', name: 'Pompa Ladowania CWU 0-10V', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie sygnałem analogowym 0-10V pompą łądującą zład ciepłej wudy użytkowej/ zasilanie i sterowanie sygnałem analogowym 0-10V (ilość) pompami łądującymi zład ciepłej wody użytkowej',
              defaultText2: 'pompa obiegowa ładująca zład CWU z możliwością sterowania sygnałem analogowym 0-10V/pompy obiegowe łądujące zład CWU z możliwością sterowania sygnałem analogowym 0-10V',
          }, 
          value: '', 
        },
        { label: 'Pompa Ladowania CWU 0-10V - ilość', name: 'Pompa Ladowania CWU 0-10V - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
      return [
        { label: 'Pompa ładowania zasobnik CWU', name: 'Pompa ładowania zasobnik CWU', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą łądującą zład ciepłej wody użytkowej/ zasilanie i sterowanie (ilość) pompami łądującymi zład zasobnika ciepłej wody użytkowej' }, value: '', },
        { label: 'Pompa ładowania zasobnik CWU - ilość', name: 'Pompa ładowania zasobnik CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Pompa Ladowania CWU 0-10V', type: 'none' },
        { name: 'Pompa Ladowania CWU 0-10V - ilość', type: 'none' },
      ]
    }
  } else {
    return [
      { name: 'Pompa ładowania zasobnik CWU', type: 'none' },
      { name: 'Pompa ładowania zasobnik CWU - ilość', type: 'none' },
      { name: 'Pompa Ladowania CWU 0-10V', type: 'none' },
      { name: 'Pompa Ladowania CWU 0-10V - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd9 = (Grzanie, Chłodzenie, Jednoczesność, silownikPrzelaczajacyLadowaniaBufora) => {
  if(Grzanie?.checked && Chłodzenie?.checked || Jednoczesność?.checked) {
    if(silownikPrzelaczajacyLadowaniaBufora?.checked) {
      return [
        { label: 'Siłownik przełączający  ładowania bufora', name: 'Siłownik przełączający  ładowania bufora', type: 'offerTextCheckboxDouble', options: 
            { 
                width: 5.2,
                textWidth: '450px',
                defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób ładowania zbiornika buforowego/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób ładowania zbiornika buforowego',
                defaultText2: 'siłowniki zaworów przełączających sposób ładowania zbiornika buforowego zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
            }, 
            value: '', 
          },
          { label: 'Siłownik przełączający  ładowania bufora - ilość', name: 'Siłownik przełączający  ładowania bufora - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Siłownik + Krancowka', name: 'Siłownik + Krancowka', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób ładowania zbiornika buforowego wraz z sygnałem pomocniczym/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób ładowania zbiornika buforowego wraz z sygnałami pomocniczymi' }, value: '', },
        { label: 'Siłownik + Krancowka - ilość', name: 'Siłownik + Krancowka - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
      return [
        { label: 'Siłownik przełączający  ładowania bufora', name: 'Siłownik przełączający  ładowania bufora', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób ładowania zbiornika buforowego/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób ładowania zbiornika buforowego',
              defaultText2: 'siłowniki zaworów przełączających sposób ładowania zbiornika buforowego zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
          }, 
          value: '', 
        },
        { label: 'Siłownik przełączający  ładowania bufora - ilość', name: 'Siłownik przełączający  ładowania bufora - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Siłownik + Krancowka', type: 'none' },
        { name: 'Siłownik + Krancowka - ilość', type: 'none' },
      ]
    } 
  } else {
    return [
      { name: 'Siłownik przełączający  ładowania bufora', type: 'none' },
      { name: 'Siłownik przełączający  ładowania bufora - ilość', type: 'none' },
      { name: 'Siłownik + Krancowka', type: 'none' },
      { name: 'Siłownik + Krancowka - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd10 = (Grzanie, Chłodzenie, Jednoczesność, silownikPrzelaczajacyLadowaniaRozdielaczy) => {
  if(Grzanie?.checked && Chłodzenie?.checked || Jednoczesność?.checked) {
    if(silownikPrzelaczajacyLadowaniaRozdielaczy?.checked) {
      return [
        { label: 'Siłownik przełączający  ładowania rozdzielaczy', name: 'Siłownik przełączający  ładowania rozdzielaczy', type: 'offerTextCheckboxDouble', options: 
        { 
            width: 5.2,
            textWidth: '450px',
            defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób rozbioru medium/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób rozbioru medium',
            defaultText2: 'siłowniki zaworów przełączających sposób rozbioru medium zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
        }, 
        value: '', 
        },
        { label: 'Siłownik przełączający  ładowania rozdzielaczy - ilość', name: 'Siłownik przełączający  ładowania rozdzielaczy - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Siłownik + Krancowka', name: 'Siłownik + Krancowka 2', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób rozbioru medium wraz z sygnałem pomocniczym/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób rozbioru medium wraz z sygnałami pomocniczymi' }, value: '', },
        { label: 'Siłownik + Krancowka - ilość', name: 'Siłownik + Krancowka 2 - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
      return [
        { label: 'Siłownik przełączający  ładowania rozdzielaczy', name: 'Siłownik przełączający  ładowania rozdzielaczy', type: 'offerTextCheckboxDouble', options: 
        { 
            width: 5.2,
            textWidth: '450px',
            defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego sposób rozbioru medium/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających sposób rozbioru medium',
            defaultText2: 'siłowniki zaworów przełączających sposób rozbioru medium zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
        }, 
        value: '', 
        },
        { label: 'Siłownik przełączający  ładowania rozdzielaczy - ilość', name: 'Siłownik przełączający  ładowania rozdzielaczy - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Siłownik + Krancowka 2', type: 'none' },
        { name: 'Siłownik + Krancowka 2 - ilość', type: 'none' },
      ]
    }
  } else {
    return [
        { name: 'Siłownik przełączający  ładowania rozdzielaczy', type: 'none' },
        { name: 'Siłownik przełączający  ładowania rozdzielaczy - ilość', type: 'none' },
        { name: 'Siłownik + Krancowka 2', type: 'none' },
        { name: 'Siłownik + Krancowka 2 - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd11 = (Grzanie, Chłodzenie, CWU) => {
  if(Grzanie?.checked && Chłodzenie?.checked) {
    if(CWU?.checked) {
      return [
        { label: 'Silownik odcinajacy cieplo/chlod', name: 'Silownik odcinajacy cieplo/chlod', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinającego/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinających',
              defaultText2: 'siłowniki zaworów odcinających zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
          }, 
          value: '', 
        },
        { label: 'Silownik odcinajacy cieplo/chlod - ilość', name: 'Silownik odcinajacy cieplo/chlod - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Siłownik + Krancowka', name: 'Siłownik + Krancowka 3', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinającego wraz z sygnałem pomocniczym/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinających wraz z sygnałami pomocniczymi' }, value: '', },
        { label: 'Siłownik + Krancowka - ilość', name: 'Siłownik + Krancowka 3 - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
      return [
        { label: 'Silownik odcinajacy cieplo/chlod', name: 'Silownik odcinajacy cieplo/chlod', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinającego/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinających',
              defaultText2: 'siłowniki zaworów odcinających zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocnicznym',
          }, 
          value: '', 
        },
        { label: 'Silownik odcinajacy cieplo/chlod - ilość', name: 'Silownik odcinajacy cieplo/chlod - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Siłownik + Krancowka 3', type: 'none' },
        { name: 'Siłownik + Krancowka 3 - ilość', type: 'none' },
      ]
    }
  } else {
    return [
      { name: 'Silownik odcinajacy cieplo/chlod', type: 'none' },
      { name: 'Silownik odcinajacy cieplo/chlod - ilość', type: 'none' },
      { name: 'Siłownik + Krancowka 3', type: 'none' },
      { name: 'Siłownik + Krancowka 3 - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd12 = (silownikPrzelaczajacyCoCWU) => {
  if(silownikPrzelaczajacyCoCWU?.checked) {
    return [
      { label: 'Siłownik przełączający CO/CWU', name: 'Siłownik przełączający CO/CWU', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego CO/CWU/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających CO/CWU',
              defaultText2: 'siłowniki zaworó przełączających CO/CWU zasilane napięciem 230 V i sterowane sygnałem 2 punktowym z sygnałe pomocniczym',
          }, 
          value: '', 
        },
        { label: 'Siłownik przełączający CO/CWU - ilość', name: 'Siłownik przełączający CO/CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      { label: 'Siłownik + Krancowka', name: 'Siłownik + Krancowka 4', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego CO/CWU wraz z sygnałem pomocniczym/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających CO/CWU wraz z sygnałami pomocniczymi' }, value: '', },
      { label: 'Siłownik + Krancowka - ilość', name: 'Siłownik + Krancowka 4 - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
    ]
  } else {
    return [
      { label: 'Siłownik przełączający CO/CWU', name: 'Siłownik przełączający CO/CWU', type: 'offerTextCheckboxDouble', options: 
          { 
              width: 5.2,
              textWidth: '450px',
              defaultText: 'zasilanie i sterowanie siłownikiem zaworu przełączającego CO/CWU/ zasilanie i sterowanie (ilość) siłownikami zaworów przełączających CO/CWU',
              defaultText2: 'siłowniki zaworó przełączających CO/CWU zasilane napięciem 230 V i sterowane sygnałem 2 punktowym z sygnałe pomocniczym',
          }, 
          value: '', 
        },
        { label: 'Siłownik przełączający CO/CWU - ilość', name: 'Siłownik przełączający CO/CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      { name: 'Siłownik + Krancowka 4', type: 'none' },
      { name: 'Siłownik + Krancowka 4 - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd13 = (CWUwymiennik, Jednoczesność, silownikOdcinajacyCWU) => {
  if(CWUwymiennik?.checked && Jednoczesność?.checked) {
    if(silownikOdcinajacyCWU?.checked) {
      return [
        { label: 'Siłownik odcinający CWU', name: 'Siłownik odcinający CWU', type: 'offerTextCheckboxDouble', options: 
            { 
                width: 5.2,
                textWidth: '450px',
                defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinającego CWU/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinających CWU',
                defaultText2: 'siłownik zaworów odcinających CWU zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocniczym',
            }, 
            value: '', 
          },
          { label: 'Siłownik przełączający CO/CWU - ilość', name: 'Siłownik przełączający CO/CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { label: 'Siłownik + Krancowka', name: 'Siłownik + Krancowka 5', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinająćego CWU wraz z sygnałem pomocniczym/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinającyh CWU wraz z sygnałami pomocniczymi' }, value: '', },
        { label: 'Siłownik + Krancowka - ilość', name: 'Siłownik + Krancowka 5 - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      ]
    } else {
      return [
        { label: 'Siłownik odcinający CWU', name: 'Siłownik odcinający CWU', type: 'offerTextCheckboxDouble', options: 
            { 
                width: 5.2,
                textWidth: '450px',
                defaultText: 'zasilanie i sterowanie siłownikiem zaworu odcinającego CWU/ zasilanie i sterowanie (ilość) siłownikami zaworów odcinających CWU',
                defaultText2: 'siłownik zaworów odcinających CWU zasilane napięciem 230V i sterowane sygnałem 2 punktowym z sygnałem pomocniczym',
            }, 
            value: '', 
          },
          { label: 'Siłownik przełączający CO/CWU - ilość', name: 'Siłownik przełączający CO/CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
        { name: 'Siłownik + Krancowka 5', type: 'none' },
        { name: 'Siłownik + Krancowka 5 - ilość', type: 'none' },
      ]
    }
  } else {
    return [
      { name: 'Siłownik odcinający CWU', type: 'none' } ,
      { name: 'Siłownik przełączający CO/CWU - ilość', type: 'none' },
      { name: 'Siłownik + Krancowka 5', type: 'none' },
      { name: 'Siłownik + Krancowka 5 - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd14 = (CWU, zasobnikCWUilosc) => {
  if(CWU?.checked && zasobnikCWUilosc > 1) {
    return [
      { label: 'Pompa cyrkulacyjna', name: 'Pompa cyrkulacyjna', type: 'offerTextCheckbox', options: { defaultText: 'zasilanie i sterowanie pompą cyrkulacyjną' }, value: '', },
      { label: 'Pompa przegrzew drugiego zasobnika', name: 'Pompa przegrzew drugiego zasobnika', type: 'offerTextCheckbox', options: { defaultText: 'zasilanie i sterowanie pompą przegrzewu zasobnika CWU' }, value: '', },
      { label: 'Pompa wstepny podgrzew', name: 'Pompa wstepny podgrzew', type: 'offerTextCheckbox', options: { defaultText: 'zasilanie i sterowanie pompą wstępnego podgrzewu zasobnika CWU/zasilanie i sterowanie (ilość) pompami wstępnego podgrzewu zasobnika CWU' }, value: '', },
      { label: 'Pompa wstepny podgrzew - ilość', name: 'Pompa wstepny podgrzew - ilość', type: 'text', value: '', },
    ]
  }
  if(CWU?.checked && zasobnikCWUilosc <= 1) {
    return [
      { label: 'Pompa cyrkulacyjna', name: 'Pompa cyrkulacyjna', type: 'offerTextCheckbox', options: { defaultText: 'zasilanie i sterowanie pompą cyrkulacyjną' }, value: '', },
      { name: 'Pompa przegrzew drugiego zasobnika', type: 'none' },
      { name: 'Pompa wstepny podgrzew', type: 'none' },
      { name: 'Pompa wstepny podgrzew - ilość', type: 'none' },
    ]
  } else {
    return [
      { name: 'Pompa cyrkulacyjna', type: 'none' },
      { name: 'Pompa przegrzew drugiego zasobnika', type: 'none' },
      { name: 'Pompa wstepny podgrzew', type: 'none' },
      { name: 'Pompa wstepny podgrzew - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd15 = (Odbiory, Grzanie, Jednoczesność) => {
  if(Odbiory?.checked && (Grzanie?.checked || Jednoczesność?.checked)){
    return [
      { label: 'Obieg grzewczy bez mieszacza', name: 'Obieg grzewczy bez mieszacza', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu grzewczego/zasilanie i sterowanie (ilość) pompami obiegów grzewczych' }, value: '', },
      { label: 'Obieg grzewczy bez mieszacza - ilość', name: 'Obieg grzewczy bez mieszacza - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
      { label: 'Obieg grzewczy z mieszaczem', name: 'Obieg grzewczy z mieszaczem', type: 'offerTextCheckboxDouble', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu grzewczego wraz z możiwością regulacji temperatury/zasilanie i sterowanie (ilość) pompami obiegów grzewczych wraz z możliwością regulacji temperatury', defaultText2: 'siłownik zaworów mieszających obiegi grzewcze zasilane napięciem 230V i sterowane sygnałem 3 punktowym' }, value: '', },
      { label: 'Obieg grzewczy z mieszaczem - ilość', name: 'Obieg grzewczy z mieszaczem - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
    ]
  } else {
    return [
      { name: 'Obieg grzewczy bez mieszacza', type: 'none' },
      { name: 'Obieg grzewczy bez mieszacza - ilość', type: 'none' },
      { name: 'Obieg grzewczy z mieszaczem', type: 'none' },
      { name: 'Obieg grzewczy z mieszaczem - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd16 = (Odbiory, Chłodzenie, Jednoczesność) => {
  if(Odbiory?.checked && (Chłodzenie?.checked || Jednoczesność?.checked)) {
    return [
      { label: 'Obieg chlodniczy bez mieszacza', name: 'Obieg chlodniczy bez mieszacza', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu chłodniczego/zasilanie i sterowanie (ilość) pompami obiegów chłodniczych' }, value: '', },
      { label: 'Obieg chlodniczy bez mieszacza - ilość', name: 'Obieg chlodniczy bez mieszacza - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
      { label: 'Obieg chlodniczy z mieszaczem', name: 'Obieg chlodniczy z mieszaczem', type: 'offerTextCheckboxDouble', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu chłodniczego wraz z możiwością regulacji temperatury/zasilanie i sterowanie (ilość) pompami obiegów chłodniczych wraz z możliwością regulacji temperatury', defaultText2: 'siłownik zaworów mieszających obiegi chłodnicze zasilane napięciem 230V i sterowane sygnałem 3 punktowym' }, value: '', },
      { label: 'Obieg chlodniczy z mieszaczem - ilość', name: 'Obieg chlodniczy z mieszaczem - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
    ]
  } else {
    return [
      { name: 'Obieg chlodniczy bez mieszacza', type: 'none', },
      { name: 'Obieg chlodniczy bez mieszacza - ilość', type: 'none' },
      { name: 'Obieg chlodniczy z mieszaczem', type: 'none' },
      { name: 'Obieg chlodniczy z mieszaczem - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd17 = (Odbiory, Grzanie, Chłodzenie, Jednoczesność) => {
  if(Odbiory?.checked && ((Grzanie?.checked && Chłodzenie?.checked) || Jednoczesność)) {
    return [
      { label: 'Obieg grzewczo-chłodniczy bez mieszacza', name: 'Obieg grzewczo-chłodniczy bez mieszacza', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu grzewczo-chłodniczego/zasilanie i sterowanie (ilość) pompami obiegów grzewczo-chłodniczych' }, value: '', },
      { label: 'Obieg grzewczo-chłodniczy bez mieszacza - ilość', name: 'Obieg grzewczo-chłodniczy bez mieszacza - ilość', type: 'text', value: '',options: { width: 5.2, attrs: { style: { width: '50%' }} }},
      { label: 'Obieg grzewczo-chłodniczy z mieszaczem', name: 'Obieg grzewczo-chłodniczy z mieszaczem', type: 'offerTextCheckboxDouble', options: { width: 5.2, textWidth: '450px', defaultText: 'zasilanie i sterowanie pompą obiegu grzewczo-chłodniczego wraz z możiwością regulacji temperatury/zasilanie i sterowanie (ilość) pompami obiegów grzewczo-chłodniczych wraz z możliwością regulacji temperatury', defaultText2: 'siłownik zaworów mieszających obiegi grzewczo-chłodnicze zasilane napięciem 230V i sterowane sygnałem 3 punktowym' }, value: '', },
      { label: 'Obieg grzewczo-chłodniczy z mieszaczem - ilość', name: 'Obieg grzewczo-chłodniczy z mieszaczem - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
    ]
  } else {
    return [
      { name: 'Obieg grzewczo-chłodniczy bez mieszacza', type: 'none' },
      { name: 'Obieg grzewczo-chłodniczy bez mieszacza - ilość', type: 'none' },
      { name: 'Obieg grzewczo-chłodniczy z mieszaczem', type: 'none' },
      { name: 'Obieg grzewczo-chłodniczy z mieszaczem - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd18 = (Szczyt) => {
  if(Szczyt?.checked) {
    return [
      { label: 'Załączanie szczytu cieplo', name: 'Załączanie szczytu cieplo', type: 'offerTextCheckbox', options: { defaultText: '' }, value: '', },
      { label: 'Załączanie szczytu cieplo - ilość', name: 'Załączanie szczytu cieplo - ilość', type: 'text', value: '', },
      { label: 'Załączanie szczytu chlod', name: 'Załączanie szczytu chlod', type: 'offerTextCheckbox', options: { defaultText: '' }, value: '', },
      { label: 'Załączanie szczytu chlod - ilość', name: 'Załączanie szczytu chlod - ilość', type: 'text', value: '', },
      { type: 'none', value: ''},
      { label: 'Załączanie szczytu CWU', name: 'Załączanie szczytu CWU', type: 'offerTextCheckbox', options: { defaultText: '' }, value: '', },
      { label: 'Załączanie szczytu CWU - ilość', name: 'Załączanie szczytu CWU - ilość', type: 'text', value: '', },
    ]
  } else {
    return [
      { name: 'Załączanie szczytu cieplo', type: 'none' },
      { name: 'Załączanie szczytu cieplo - ilość', type: 'none' },
      { name: 'Załączanie szczytu chlod', type: 'none' },
      { name: 'Załączanie szczytu chlod - ilość', type: 'none' },
      { name: 'Załączanie szczytu CWU', type: 'none' },
      { name: 'Załączanie szczytu CWU - ilość', type: 'none' },
    ]
  }
}

const controlMethodsCd19 = (zalaczanieSzczytuCieplo, zalaczanieSzczytuChlod) => {
  if(zalaczanieSzczytuCieplo?.checked && zalaczanieSzczytuChlod?.checked) {
    return [
      { label: 'Szczyt chłod - dodatkowa pompa', name: 'Szczyt chłod - dodatkowa pompa', type: 'offerTextCheckbox', options: { defaultText: 'załączanie dodatkowej pompy jako ładowanie źrółem szczytowym medium chłodniczego CO' }, value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { label: 'Szczyt CO - kocioł', name: 'Szczyt CO - kocioł', type: 'offerTextCheckboxDouble', options: { defaultText: 'załączanie kotła jako źródła szczytowego medium grzewczego CO', defaultText2: 'kocioł szczytowy CO z możliwością załączania sygnałem bezpotencjałowym' }, value: '', },
      { label: 'Szczyt CO - grzałka', name: 'Szczyt CO - grzałka', type: 'offerTextCheckboxDouble', options: { defaultText: 'załączanie grzałki zasilanej (zasilanie) o mocy maksymalnej (moc) jako źródła szczytowego medium grzewczego CO', defaultText2: 'grzałka elektryczna w zbiorniku CO zasilana napięciem (zasilanie) o maksymalnej mocy (moc)' }, value: '', },
      { label: 'Szczyt CO - grzałka - moc', name: 'Szczyt CO - grzałka - moc', type: 'text', value: '', },
      { label: 'Szczyt CO - grzałka - zasilanie', name: 'Szczyt CO - grzałka - zasilanie', type: 'text', value: '', },
      { label: 'Szczyt CO - dodatkowa pompa', name: 'Szczyt CO - dodatkowa pompa', type: 'offerTextCheckbox', options: { defaultText: 'załączanie dodatkowej pompy jako ładowanie źrółem szczytowym medium grzewczego CO' }, value: '', },
    ]
  } else if(!zalaczanieSzczytuCieplo?.checked && zalaczanieSzczytuChlod?.checked) {
    return [
      { label: 'Szczyt chłod - dodatkowa pompa', name: 'Szczyt chłod - dodatkowa pompa', type: 'offerTextCheckbox', options: { defaultText: 'załączanie dodatkowej pompy jako ładowanie źrółem szczytowym medium chłodniczego CO' }, value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { name: 'Szczyt CO - kocioł', type: 'none' },
      { name: 'Szczyt CO - grzałka', type: 'none' },
      { name: 'Szczyt CO - grzałka - moc', type: 'none' },
      { name: 'Szczyt CO - grzałka - zasilanie', type: 'none' },
      { name: 'Szczyt CO - dodatkowa pompa', type: 'none' },
    ]
  } else if(zalaczanieSzczytuCieplo?.checked && !zalaczanieSzczytuChlod?.checked) {
    return [
      { name: 'Szczyt chłod - dodatkowa pompa', type: 'none' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { label: 'Szczyt CO - kocioł', name: 'Szczyt CO - kocioł', type: 'offerTextCheckboxDouble', options: { defaultText: 'załączanie kotła jako źródła szczytowego medium grzewczego CO', defaultText2: 'kocioł szczytowy CO z możliwością załączania sygnałem bezpotencjałowym' }, value: '', },
      { label: 'Szczyt CO - grzałka', name: 'Szczyt CO - grzałka', type: 'offerTextCheckboxDouble', options: { defaultText: 'załączanie grzałki zasilanej (zasilanie) o mocy maksymalnej (moc) jako źródła szczytowego medium grzewczego CO', defaultText2: 'grzałka elektryczna w zbiorniku CO zasilana napięciem (zasilanie) o maksymalnej mocy (moc)' }, value: '', },
      { label: 'Szczyt CO - grzałka - moc', name: 'Szczyt CO - grzałka - moc', type: 'text', value: '', },
      { label: 'Szczyt CO - grzałka - zasilanie', name: 'Szczyt CO - grzałka - zasilanie', type: 'text', value: '', },
      { label: 'Szczyt CO - dodatkowa pompa', name: 'Szczyt CO - dodatkowa pompa', type: 'offerTextCheckbox', options: { defaultText: 'załączanie dodatkowej pompy jako ładowanie źrółem szczytowym medium grzewczego CO' }, value: '', },
    ]
  } else {
    return [
      { name: 'Szczyt chłod - dodatkowa pompa', type: 'none' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { name: 'Szczyt CO - kocioł', type: 'none' },
      { name: 'Szczyt CO - grzałka', type: 'none' },
      { name: 'Szczyt CO - grzałka - moc', type: 'none' },
      { name: 'Szczyt CO - grzałka - zasilanie', type: 'none' },
      { name: 'Szczyt CO - dodatkowa pompa', type: 'none' },
    ]
  }
}

const controlMethodsCd20 = (zalaczanieSzczytuCWU) => {
  if(zalaczanieSzczytuCWU?.checked) {
    return [
      { label: 'Szczyt CWU - kocioł', name: 'Szczyt CWU - kocioł', type: 'offerTextCheckboxDouble', options: { width: 5.2, textWidth: '450px', defaultText: 'załączanie kotła jako źródła szczytowego medium grzewczego CWU', defaultText2: 'kocioł szczytowy CWU z możliwością załączania sygnałem bezpotencjałowym' }, value: '', },
      { label: 'Szczyt CWU - grzałka', name: 'Szczyt CWU - grzałka', type: 'offerTextCheckboxDouble', options: { width: 5.2, textWidth: '450px', defaultText: 'załączanie grzałki zasilanej (zasilanie) o mocy maksymalnej (moc) jako źródła szczytowego medium grzewczego CWU', defaultText2: 'grzałka elektryczna w zasobniku CWU zasilana napięciem (zasilanie) o maksymalnej mocy (moc)' }, value: '', },
      { label: 'Szczyt CWU - grzałka - moc', name: 'Szczyt CWU - grzałka - moc', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
      { label: 'Szczyt CWU - grzałka - zasilanie', name: 'Szczyt CWU - grzałka - zasilanie', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} } },
    ]
  } else {
    return [
      { name: 'Szczyt CWU - kocioł', type: 'none' },
      { name: 'Szczyt CWU - grzałka', type: 'none' },
      { name: 'Szczyt CWU - grzałka - moc', type: 'none', value: '' },
      { name: 'Szczyt CWU - grzałka - zasilanie', type: 'none', value: '' },
    ]
  }
}

const autoOffer = ({ 
  ['WS/GS']: wsGs, 
  Grzanie, 
  Jednoczesność, 
  Chłodzenie, 
  CWU, 
  Caldaria,
  Odbiory,
  Szczyt,
  ['Grzanie Glikol - Woda']: grzanieGlikolWoda,
  ['Chłodzenie Glikol - Woda']: chlodzenieGlikolWoda,
  ['CWU Wymiennik']: CWUwymiennik,
  ['Pompa ładowania Bufor grzanie']: pompaŁadowaniaBuforGrzanie,
  ['Pompa ładowania Bufor chłód']: pompaŁadowaniaBuforChlod,
  ['Pompa ładowania zasobnik CWU']: pompaŁadowaniaZasobnikCWU,
  ['Siłownik przełączający  ładowania bufora']: silownikPrzelaczajacyLadowaniaBufora,
  ['Siłownik przełączający  ładowania rozdzielaczy']: silownikPrzelaczajacyLadowaniaRozdielaczy,
  ['Siłownik przełączający CO/CWU']: silownikPrzelaczajacyCoCWU,
  ['Siłownik odcinający CWU']: silownikOdcinajacyCWU,
  ['Zasobnik CWU - ilość']: zasobnikCWUilosc,
  ['Załączanie szczytu cieplo']: zalaczanieSzczytuCieplo,
  ['Załączanie szczytu chlod']: zalaczanieSzczytuChlod,
  ['Załączanie szczytu CWU']: zalaczanieSzczytuCWU,
}) => ([
  {
    type: 'multipleFields',
    name: 'Wariant automatyki',
    value: [],
    options: { fields: [
        { label: 'Wariant automatyki', name: 'Wariant automatyki', type: 'dictionary', 
        options: { data: [
          { id: 'Automatyka Podstawowa', name: 'Automatyka Podstawowa' },
          { id: 'Automatyka dedykowana', name: 'Automatyka dedykowana' },
          { id: 'Sterowniki', name: 'Sterowniki' },
        ] }, 
        value: '', },
        { label: 'Temat', name: 'Temat', type: 'text', options: { }, value: '', },
        { label: 'Automatyka dedykowana', name: 'Automatyka dedykowana', type: 'multilineInput', options: { }, value: 'opracowanie algorytmu sterowania i implementacja w sterowniku\n        opracowanie interfejsu użytkownika i implementacja na panelu HMI	\n        usługa zdalnego dostępu do parametrów aplikacji z poziomu urządzeń Android, iPad, PC', },
        
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania',
    value: [],
    options: { fields: [
      
      { name: 'Sposób sterowania', type:'header', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
        { label: 'Grzanie', name: 'Grzanie', type: 'offerTextCheckbox', options: { defaultText: 'zapewnienie medium grzewczego' }, value: '', },
        { label: 'Chłodzenie', name: 'Chłodzenie', type: 'offerTextCheckbox', options: { defaultText: 'zapewnienie medium chłodniczego' }, value: '', },
        { label: 'Jednoczesność', name: 'Jednoczesność', type: 'offerTextCheckbox', options: { defaultText: 'zapewnienie medium grzewczego i chłodniczego w jednym czasie' }, value: '', },
        { label: 'CWU', name: 'CWU', type: 'offerTextCheckbox', options: { defaultText: 'zapewnienie medium grzewczego do modułu ciepłej wody użytkowej' }, value: '', },
        { label: 'Odbiory', name: 'Odbiory', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie instalacją odbiorczą' }, value: '', },
        { label: 'Szczyt', name: 'Szczyt', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie źródłem szczytowym' }, value: '', },
        { label: 'Alternatywa', name: 'Alternatywa', type: 'offerTextCheckbox', options: { defaultText: 'zarządzanie instalacją w sposób alternatywny' }, value: '', },
        { label: 'WS/GS', name: 'WS/GS', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie dolnym źródłem' }, value: '', },
        { label: 'Caldaria', name: 'Caldaria', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie urządzeniami Caldaria' }, value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd.',
    value: [],
    options: { fields: controlMethods(wsGs) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 2',
    value: [],
    options: { fields: controlMethodsCd2(wsGs) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 3',
    value: [],
    options: { fields: [
      controlMethodsCd3_1(Grzanie, Jednoczesność, Chłodzenie, CWU), 
      controlMethodsCd3_2(Chłodzenie, Jednoczesność), 
      controlMethodsCd3_3(CWU)
      ], 
    },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 4',
    value: [],
    options: { fields: [
        { label: 'Zbiornik buforowy medium grzewczego', name: 'Zbiornik buforowy medium grzewczego', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'regulacja temperatury medium grzewczego w zbiorniku buforowym/ regulacja temperatury medium grzewczego w (wartośc z ilości) zbiornikach buforowych' }, value: '', },
        { label: 'Zbiornik buforowy medium grzewczego - ilość', name: 'Zbiornik buforowy medium grzewczego - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
        { label: 'Zbiornik buforowy medium chłodniczego', name: 'Zbiornik buforowy medium chłodniczego', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'regulacja temperatury medium chłodniczego w zbiorniku buforowym/ regulacja temperatury medium chłodniczego w (wartośc z ilości) zbiornikach buforowych' }, value: '', },
        { label: 'Zbiornik buforowy medium chłodniczego - ilość', name: 'Zbiornik buforowy medium chłodniczego - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
      ],
      hidden: true,
    },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 5',
    value: [],
    options: { fields: [
        { label: 'Zasobnik CWU', name: 'Zasobnik CWU', type: 'offerTextCheckbox', options: { width: 5.2, textWidth: '450px', defaultText: 'regulacja temperatury medium grzewczego w zasobniku ciepłej wody użytkowej/ regulacja temperatury medium grzewczego w (wartośc z ilości) zasobnikach ciepłej wody użytkowej' }, value: '', },
        { label: 'Zasobnik CWU - ilość', name: 'Zasobnik CWU - ilość', type: 'text', value: '', options: { width: 5.2, attrs: { style: { width: '50%' }} }},
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 6',
    value: [],
    options: { fields: controlMethodsCd6(grzanieGlikolWoda, Caldaria, pompaŁadowaniaBuforGrzanie) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 7',
    value: [],
    options: { fields: controlMethodsCd7(chlodzenieGlikolWoda, pompaŁadowaniaBuforChlod) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 8',
    value: [],
    options: { fields: controlMethodsCd8(CWUwymiennik, pompaŁadowaniaZasobnikCWU) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 9',
    value: [],
    options: { fields: controlMethodsCd9(Grzanie, Chłodzenie, Jednoczesność, silownikPrzelaczajacyLadowaniaBufora) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 10',
    value: [],
    options: { fields: controlMethodsCd10(Grzanie, Chłodzenie, Jednoczesność, silownikPrzelaczajacyLadowaniaRozdielaczy) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 11',
    value: [],
    options: { fields: controlMethodsCd11(Grzanie, Chłodzenie, CWU) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 12',
    value: [],
    options: { fields: controlMethodsCd12(silownikPrzelaczajacyCoCWU) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 13',
    value: [],
    options: { fields: controlMethodsCd13(CWUwymiennik, Jednoczesność, silownikOdcinajacyCWU) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 14',
    value: [],
    options: { fields: controlMethodsCd14(CWU, zasobnikCWUilosc) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 15',
    value: [],
    options: { fields: controlMethodsCd15(Odbiory, Grzanie, Jednoczesność) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 16',
    value: [],
    options: { fields: controlMethodsCd16(Odbiory, Chłodzenie, Jednoczesność) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 17',
    value: [],
    options: { fields: controlMethodsCd17(Odbiory, Grzanie, Chłodzenie, Jednoczesność) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 18',
    value: [],
    options: { fields: controlMethodsCd18(Szczyt) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 19',
    value: [],
    options: { fields: controlMethodsCd19(zalaczanieSzczytuCieplo, zalaczanieSzczytuChlod) },
  },
  {
    type: 'multipleFields',
    name: 'Sposób sterowania cd. 20',
    value: [],
    options: { fields: controlMethodsCd20(zalaczanieSzczytuCWU) }
  },
  {
    type: 'multipleFields',
    name: 'Dodatki',
    value: [],
    options: { fields: [
      { name: 'Dodatki', fullWidth: true, type:'header', value: '', },
        
      { label: 'BMS', name: 'BMS', type: 'offerTextCheckbox', options: { defaultText: 'wystawiene sygnałów do sieci BMS' }, value: '', },
      { label: 'Solary', name: 'Solary', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie instalacją solarną' }, value: '', },
      { label: 'Sygnały pracy i awarii z pomp obiegowych', name: 'Sygnały pracy i awarii z pomp obiegowych', type: 'offerTextCheckbox', options: { defaultText: 'pomiar i wyświetlenie informacji o sygnałąch pracy i awarii wszystkich pomp obiegowych (jeżeli będą dostępne)' }, value: '', },
      { label: 'Cieplomierze', name: 'Cieplomierze', type: 'offerTextCheckbox', options: { defaultText: 'szczytywanie informacji z ciepłomierzy' }, value: '', },
      { label: 'Innova - wizualizacja', name: 'Innova - wizualizacja', type: 'offerTextCheckbox', options: { defaultText: 'wyświetlenie danych na wizualizacji z instalacji Innovy' }, value: '', },
      { label: 'Maxa - wizualizacja', name: 'Maxa - wizualizacja', type: 'offerTextCheckbox', options: { defaultText: 'wyświetlenie danych na wizualizacji z instalacji Maxa' }, value: '', },
      { label: 'Inne', name: 'Inne', type: 'offerTextCheckbox', options: { defaultText: 'sterowanie (inne)' }, value: '', },
      ]
    },
  },
  {
    type: 'multipleFields',
    name: 'Opcje',
    value: [],
    options: { fields: [
      { name: 'Tabela produktów', fullWidth: true, type:'header', value: '', },
      {
        "id": 76,
        "name": "tabela_produktow",
        "label": "Tabela produktów",
        "type": "rowsGroup",
        "value": [],
        "options": {
            "width": 12,
            "data": [],
            "fields": [
                {
                    "id": 69,
                    "name": "tabela_produktow_produkt",
                    "type": "entity",
                    "label": "Produktu",
                    "value": null,
                    "options": {
                        "entityClass": "product"
                    }
                },
                {
                    "id": 69,
                    "name": "tabela_produktow_opis",
                    "type": "text",
                    "label": "Opis",
                    "value": null,
                    "options": null
                },
                {
                    "id": 69,
                    "name": "tabela_produktow_ilosc",
                    "type": "text",
                    "label": "Ilość",
                    "value": null,
                    "options": null
                }
            ],
        },
      },
        { name: 'Warianty produktów', fullWidth: true, type:'header', value: '', },
      {
        "id": 76,
        "name": "tabela_produktow_warianty",
        "type": "rowsGroup",
        "value": [],
        "options": {
            "width": 12,
            "data": [],
            "fields": [
                {
                    "id": 69,
                    "name": "tabela_produktow_w_produkt",
                    "type": "entity",
                    "label": "Wariant",
                    "value": null,
                    "options": {
                        "entityClass": "product"
                    }
                },
                {
                    "id": 69,
                    "name": "tabela_produktow_w_cena",
                    "type": "text",
                    "label": "Cena",
                    "value": null,
                    "options": null
                },
                {
                    "id": 69,
                    "name": "tabela_produktow_w_opis",
                    "type": "text",
                    "label": "Opis",
                    "value": null,
                    "options": null
                }
            ],
        }
      }
    ]
    }
  },
  {
    type: 'multipleFields',
    name: 'Cena',
    value: [],
    options: { fields: [
      { name: 'Cena', type:'header', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      
      { type: 'none', label: 'Cena katalogowa z 2 letnią gwarancją', value: '' },
      { label: 'Cena', name: 'Cena katalogowa z 2 letnią gwarancją - cena', type: 'text', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      
      { type: 'none', label: 'Gwarancja 3 letnia', value: '' },
      { label: 'Mnożnik', name: 'Gwarancja 3 letnia - mnożnik', type: 'text', value: '', },
      { label: 'Cena', name: 'Gwarancja 3 letnia - cena', type: 'text', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      
      { type: 'none', label: 'Gwarancja 4 letnia', value: '' },
      { label: 'Mnożnik', name: 'Gwarancja 4 letnia - mnożnik', type: 'text', value: '', },
      { label: 'Cena', name: 'Gwarancja 4 letnia - cena', type: 'text', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },
      
      { type: 'none', label: 'Gwarancja 5 letnia', value: '' },
      { label: 'Mnożnik', name: 'Gwarancja 5 letnia - mnożnik', type: 'text', value: '', },
      { label: 'Cena', name: 'Gwarancja 5 letnia - cena', type: 'text', value: '', },
      { type: 'none', value: '' },
      { type: 'none', value: '' },

      
      { label: 'Czas realizacji (liczba dni)', name: 'Czas realizacji', fullWidth:true, type: 'text', value: '', },
      { label: 'Przygotował', name: 'Przygotował', fullWidth:true, type: 'text', value: '', },
      ]
    },
  },
]);


export { autoOffer, initialFormSectionDrivers };