import * as PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {useFormik} from "formik";
import {getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCT_PRODUCER_ROUTE, API_PRODUCT_CATEGORIES_ROUTE, API_PRODUCTS_ROUTE} from "../../../../../constants/routes";
import {toast} from "react-toastify";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import BasicTable from "../../../../../components/tables/basic/BasicTable";

export const AddEditParts = ({productId, type, parts, onInput, label}) => {
    const [groups, setGroups] = useState([]);
    const [producers, setProducers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const isGroupSelectionDisabled = type === 'repairs_parts';

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            group: '',
            producer: '',
            category: '',
            product: ''
        },
    });

    const availableParts = useMemo(() =>
            products.filter(({id}) => id !== productId && !parts.find(({id: partId}) => partId === id))
        , [products, parts]);
    
    const allProductsAlreadySelected = useMemo(() => availableParts.length === 1 && availableParts[0].id === '_all', [availableParts]);

    const productPartsTableRows = useMemo(() =>
            parts.map(({id, name}) => ({identity: id, id, name}))
        , [parts]);

    /**
     * Initial group fetch (happens only once)
     */
    useEffect(() => {
        fetchGroups();
    }, []);

    /**
     * Refetch producers whenever group value changes
     */
    useEffect(() => {
        setProducers(() => []);
        formik.setFieldValue('producer', '');
        fetchProducers();
    }, [formik.values.group]);

    /**
     * Refetch categories whenever producer changes
     */
    useEffect(() => {
        setCategories(() => []);
        formik.setFieldValue('category', '');
        fetchCategories();
    }, [formik.values.producer]);

    /**
     * Refetch products whenever category changes
     */
    useEffect(() => {
        setProducts(() => []);
        formik.setFieldValue('product', '');
        fetchProducts();
    }, [formik.values.category]);

    const filterGroupsByType = (groups) => {
        return groups.filter((group) => {
            const name = group.name.toLowerCase();
            switch(type) {
                case 'products':
                    return name !== 'serwis'
                case 'repairs_parts':
                    // should result in only one group after filtering (group='serwis')
                    return name === 'serwis'
            }
        });
    }

    const fetchGroups = async () => {
        const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}/types`);
        if (data?.items) {
            const groups = filterGroupsByType(data.items);
            if (isGroupSelectionDisabled) {
                formik.setFieldValue('group', groups[0].id);
            }
            setGroups(groups);
        }
    }

    const fetchProducers = () => {
        getCall(`${API_PRODUCT_PRODUCER_ROUTE}?type=${formik.values.group}`)
            .then(({data: {items = []} = {}}) => setProducers(items));
    }
    
    const fetchCategories = () => {
        getCall(`${API_PRODUCT_CATEGORIES_ROUTE}?producer=${formik.values.producer}`)
            .then(({data: {items = []} = {}}) => setCategories(items));
    }

    const fetchProducts = async () => {
        const { data } = await getCall(`${API_PRODUCTS_ROUTE}?product_category=${formik.values.category}`);
        if (data) {
            const product_items = data?.items?.map((i) => ({
                id: i?.id,
                name: i?.name,
            }));
            // additional option to select all products
            product_items.push({id: "_all", name: 'wszystkie'});
            setProducts(product_items);
        }
    };

    const handleAddPart = () => {
        const selectedItemId = formik.values.product;
        const toDisplay = availableParts.filter(({id}) => {
            if (selectedItemId === '_all') {
                // in this case return all items except the one with '_all' id (aka 'wszystkie' option)
                return id !== '_all';
            }
            // otherwise just return selected element
            return id === selectedItemId;
        });
        
        formik.setFieldValue('product', "");
        onInput([...parts, ...toDisplay]);
    };

    const handleDeleteParts = (searchesIds) => onInput(parts.filter(({id}) => !searchesIds.includes(id)));

    return <>
        <Typography
            style={{ fontWeight: 'bold', margin: '20px 0' }}
            variant="body1">
            {label ? label : 'Powiązane produkty'}
        </Typography>

        <Grid container justify="center" alignItems="center">
            <Grid item xs>
                {returnSpecificField('dictionary', {data: groups, disabled: isGroupSelectionDisabled}, 'group', formik.values.group, (e, v) => formik.setFieldValue('group', v), 'Grupa' )}
            </Grid>
            <Grid item xs>
                {returnSpecificField('dictionary', {data: producers, disabled: !formik.values.group}, 'producer', formik.values.producer, (e, v) => formik.setFieldValue('producer', v), 'Producent')}
            </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center">
            <Grid item xs>
                {returnSpecificField('dictionary', {data: categories, disabled: !formik.values.producer}, 'category', formik.values.category, (e, v) => formik.setFieldValue('category', v), 'Kategoria' )}
            </Grid>
            <Grid item xs>
                {returnSpecificField('dictionary', {data: availableParts, disabled: !formik.values.category || allProductsAlreadySelected}, 'product', formik.values.product, (e, v) => formik.setFieldValue('product', v), 'Produkt')}
            </Grid>
            <Grid item xs={2} style={{marginTop: 35}}>
                <PrimaryButton
                    disabled={!formik.values.product}
                    onClick={handleAddPart}
                >
                    Dodaj
                </PrimaryButton>
            </Grid>
        </Grid>
        {productPartsTableRows.length > 0 && <BasicTable
            onDelete={handleDeleteParts}
            useDialogOnDelete
            headCells={[
                { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
                { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
              ]}
            rows={productPartsTableRows}
            recordsCount={productPartsTableRows.length}
        />}
    </>
};

AddEditParts.propTypes = {
    productId: PropTypes.number,
    type: PropTypes.string.isRequired,
    parts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        })
    ).isRequired,
    onInput: PropTypes.func.isRequired
};

AddEditParts.defaultProps = {
    productId: null,
};