import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_DICTIONARY_ROUTE,
  API_DICTIONARY_VALUE_ROUTE
} from '../../../../../../constants/routes';
import PrimaryButton from "../../../../../../components/buttons/PrimaryButton";
import {
  deleteCall,
  getCall,
  postCall,
  putCall
} from "../../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../../components/wrappers/RowWrapper";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddEditPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');

  const formik = useFormik({
    initialValues: {
      dirValues: [{ name: '', id: '', value: '' }],
    },
  });

  const { dirId, dirName, onSave } = props;

  useEffect(() => {
    if (dirId && dirName) {
      setName(dirName);
      const fetchDetails = async () => {
        const { data } = await getCall(
          `${API_DICTIONARY_ROUTE}/${dirId}/values`,
        );
        if (data) {
          formik.setFieldValue(
            'dirValues',
            data.map((el) => ({
              name: el?.name,
              value: el?.name,
              id: el?.id,
            })),
          );
        }
      };
      fetchDetails();
    } else {
      setName('');
      formik.setFieldValue('dirValues', []);
    }
    // eslint-disable-next-line
  }, [dirId, dirName]);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const add = async () => {
        const r = await postCall(API_DICTIONARY_ROUTE, {
          name,
        });
        if (r?.data && !r?.data?.error) toast.success('Dodano nowy słownik.');
        else toast.error('Wystąpił błąd.');
      };
      const edit = async () => {
        const r = await putCall(API_DICTIONARY_ROUTE, {
          id: dirId,
          name,
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Pomyślnie zapisano nazwę słownika - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      if (dirId) {
        await edit();
        setOpen(false);
      } else {
        await add();
      }
      await onSave();
    } catch (error) {
      toast.error('Wystąpił błąd przy dodawaniu/edycji słownika.');
    }
  };

  const removeDirValue = async (id, cb) => {
    if (id) await deleteCall(`${API_DICTIONARY_VALUE_ROUTE}/${id}`);
    cb();
  };

  const editOrAdd = async (id, index) => {
    if (id)
      await putCall(API_DICTIONARY_VALUE_ROUTE, {
        id,
        name: formik.values.dirValues[index].value,
      });
    else if (dirId) {
      await postCall(API_DICTIONARY_VALUE_ROUTE, {
        dictionary_id: dirId,
        name: formik.values.dirValues[index].value,
      });
    }
    toast.info('Zapisano wartość słownikową.');
  };

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Container>
            <ColumnWrapper fullWidth>
              <Typography
                style={{ fontWeight: 'bold', margin: '0 0 20px' }}
                variant="body1">
                {dirId ? 'Edycja' : 'Dodawanie'} słownika
              </Typography>
              <TextField
                variant="outlined"
                label="Nazwa"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FieldArray
                name="dirValues"
                render={(aH) => (
                  <>
                    {formik.values.dirValues &&
                    formik.values.dirValues.length > 0
                      ? formik.values.dirValues.map((directory, index) => (
                          <Fragment key={index}>
                            <Fragment>
                              <TextField
                                style={{ marginTop: 20 }}
                                variant="outlined"
                                label="Wartość słownika"
                                name={`dirValues.${index}.value`}
                                value={formik.values.dirValues[index].value}
                                onChange={formik.handleChange}
                              />
                              <RowWrapper
                                style={{
                                  marginTop: 10,
                                  justifyContent: 'flex-end',
                                }}>
                                <Button
                                  style={{
                                    marginRight: 5,
                                  }}
                                  onClick={async () =>
                                    await removeDirValue(
                                      formik.values.dirValues[index].id,
                                      () => aH.remove(index),
                                    )
                                  }>
                                  Usuń
                                </Button>
                                <Button
                                  variant="outlined"
                                  onClick={async () =>
                                    await editOrAdd(
                                      formik.values.dirValues[index].id,
                                      index,
                                    )
                                  }>
                                  Zapisz
                                </Button>
                              </RowWrapper>
                            </Fragment>
                          </Fragment>
                        ))
                      : null}
                    {dirId ? (
                      <Button
                        style={{ alignSelf: 'flex-end', marginTop: 20 }}
                        variant="contained"
                        onClick={() => aH.push({ value: '' })}>
                        + Nowa wartość
                      </Button>
                    ) : null}
                  </>
                )}
              />
            </ColumnWrapper>
          </Container>
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: 20 }}>
          <PrimaryButton
            variant="raised"
            onClick={handleClose}
            color="secondary">
            Anuluj
          </PrimaryButton>
          <PrimaryButton disabled={!name} onClick={handleSave}>
            Zapisz
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
});

AddEditPopup.propTypes = {
  fetchDocuments: PropTypes.func,
};

export default AddEditPopup;
