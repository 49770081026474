import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_DIRECTORY_EXPLORER_ROUTE,
  MARKETING_ACTIONS_ROUTE
} from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import Select from '../../../../components/dynamic-forms/form-components/Select';
import { getCall, postFileCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddAttachmentPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      dirs: [{ id: '', data: [], selected: '' }],
      dir: '0',
      description: '',
      name: '',
    },
  });

  const fileRef = useRef();

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const changeFile = () => {
    fileRef.current.click();
  };

  const uploadFile = async () => {
    try {
      const { name, description, dir } = formik.values;
      const fileToUpload = fileRef.current.files[0];
      const formData = new FormData();
      if (name && description && fileToUpload) {
        formData.append('name', name);
        formData.append('description', description);
        formData.append('directory', dir);
        formData.append('file', fileToUpload);

        const r = await postFileCall(
          `${MARKETING_ACTIONS_ROUTE}/${id}/files`,
          formData,
          undefined,
          undefined,
          true,
        );

        if (r.status === 'ok') {
          setOpen(false);
          props.backToList();
        }
      } else {
        toast.warn('Uzupełnij pole nazwy, dodaj plik i wybierz katalog');
      }
    } catch (error) {
      toast.error('Wystąpił problem z wrzucaniem pliku!');
    }
  };

  useEffect(() => {
    const fetchDirectories = async (index = 0) => {
      const { data } = await getCall(
        `${API_DIRECTORY_EXPLORER_ROUTE}/${index}`,
      );
      if (data?.subdirs?.length) {
        formik.setFieldValue(
          `dirs.${index}.data`,
          data.subdirs.map((i) => ({
            id: i?.id,
            name: i?.name,
          })),
        );
        formik.setFieldValue(`dirs.${index}.id`, index);
      }
    };
    if (formik.values.dir) fetchDirectories(formik.values.dir);
    else fetchDirectories();
    // eslint-disable-next-line
  }, [formik.values.dir]);

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Container>
            <Typography
              style={{ fontWeight: 'bold', margin: '0 0 20px' }}
              variant="body1">
              Dodawanie pliku
            </Typography>
            <TextField
              variant="outlined"
              label="Nazwa"
              name="name"
              style={{ width: '100%' }}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <TextField
              variant="outlined"
              label="Opis"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              style={{ width: '100%', marginTop: 20 }}
            />
            <FieldArray
              name="dirs"
              render={(aH) => (
                <>
                  {formik.values.dirs && formik.values.dirs.length > 0
                    ? formik.values.dirs.map((directory, index) => (
                        <Fragment key={index}>
                          <Fragment>
                            <Select
                              label="Katalog"
                              name={`dirs.${index}.id`}
                              data={formik.values.dirs[index].data}
                              onSelect={(d) => {
                                formik.setFieldValue(
                                  `dirs.${index}.selected`,
                                  d,
                                );
                                if (
                                  index === 0 &&
                                  formik.values.dirs.length > 1
                                ) {
                                  formik.setFieldValue('dirs', [
                                    {
                                      id: formik.values.dirs[index].id,
                                      data: formik.values.dirs[index].data,
                                      selected: d,
                                    },
                                  ]);
                                }
                                formik.setFieldValue('dir', d);
                              }}
                              value={formik.values.dirs[index].selected}
                            />
                          </Fragment>
                        </Fragment>
                      ))
                    : null}
                </>
              )}
            />
            <Button
              style={{ alignSelf: 'flex-end', marginTop: 10 }}
              onClick={() => {
                formik.setFieldValue('dir', '0');
                formik.setFieldValue('dirs', [
                  {
                    id: '',
                    data: [],
                    selected: '',
                  },
                ]);
              }}>
              Główny katalog
            </Button>
            <Typography style={{ margin: '20px 0 5px' }} variant="body2">
              Plik
            </Typography>
            <input style={{ display: 'none' }} ref={fileRef} type="file" />
            <PrimaryButton size="small" onClick={changeFile}>
              Dodaj plik
            </PrimaryButton>
          </Container>
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: 20 }}>
          <PrimaryButton
            variant="raised"
            onClick={handleClose}
            color="secondary">
            Anuluj
          </PrimaryButton>
          <PrimaryButton onClick={uploadFile}>Zapisz</PrimaryButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
});

AddAttachmentPopup.propTypes = {
  fetchAttachments: PropTypes.func,
};

export default AddAttachmentPopup;
