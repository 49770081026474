import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';

const YesNoDialog = ({
  content,
  onAccept,
  onCancel,
  acceptText,
  cancelText,
  isOpen,
  onClose,
  title,
}) => {
  const handleAccept = () => {
    if (onAccept) onAccept();
    onClose();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={e => {handleCancel(); return e.stopPropagation()}}>{cancelText}</PrimaryButton>
          <PrimaryButton onClick={e => {handleAccept(); return e.stopPropagation()}}>{acceptText}</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

YesNoDialog.propTypes = {
  isOpen: PropTypes.bool,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
};

YesNoDialog.defaultProps = {
  isOpen: false,
  acceptText: 'Tak',
  cancelText: 'Anuluj',
  content: '',
  title: '',
  onCancel: () => {},
  onAccept: () => {},
};

export default YesNoDialog;
