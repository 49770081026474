import React, { useEffect, useState } from 'react';
import BasicTable from "../../components/tables/basic/BasicTable";
import ListToolBar from './components/list/ListToolBar';
import routerHistory from "../../constants/settings/routerHistory";
import { getCall, putCall } from '../../components/indicator/Indicator';
import ProjectLabel from '../../components/notes/ProjectLabel';
import { API_OFFERS_ROUTE } from '../../constants/routes';
import { useStoreState } from 'easy-peasy';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import OfferSearchFilters from './components/list/search-filters/OfferSearchFilters';
import serializeQuery from '../../tools/serializeQuery';
import RowWrapper from '../../components/wrappers/RowWrapper';
import Select from '../../components/dynamic-forms/form-components/Select';
import { STATUS_TYPES } from '../../constants/types/offerTypes';

const OffersScreen = () => {
  
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);

  const state = useStoreState((state) => state.auth?.loggedInUser);

  const returnProjectBadge = (name, id) => <ProjectLabel id={id} projectName={name} />;

  const returnStatusSelect = (status, id, item) => {
    return (
    <Select
      name="status"
      data={STATUS_TYPES}
      onSelect={(selectedStatus) => updateStatus(id, selectedStatus, item)}
      value={status || 'Nowa'}
    />
    )
  }

const updateStatus = async (id, selectedStatus, item, url = API_OFFERS_ROUTE + `/${id}`) => {
  const data= {
    ...item,
    company: item.company.id,
    status: selectedStatus,
    projectVariant: item.projectVariant.id,
    project: item.project.id,
    owner: item.owner.id,
  }
  const re = await putCall(url, data)
}

const singleOfferDiscountText = (item) => {
  const discounts = []
  const fields = item?.fields?.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
  if (item?.type==='urzadzenia' || item?.type==='peryferia'){
    item.devices.map(device => device.discount ? discounts.push(`${device.discount}%`) : discounts.push(`0%`))
    return [...new Set(discounts)].join(', ')
  }
  if(item?.type==="automatyka"){
    return fields.Rabat ? `${fields.Rabat}%` : null;
  }
}

const discountFormater = (item, offersList) => {
  let discountText
  if (item.type==='scalona'){
    const discountMessageArray = [];
    item.fields.map((field) => {
      const selectedOfferFromMergedOffer = offersList.find((offer) => field?.value?.id===offer.id)
      const returnedMessage = singleOfferDiscountText(selectedOfferFromMergedOffer)
      if(selectedOfferFromMergedOffer && returnedMessage){
        discountMessageArray.push(`${selectedOfferFromMergedOffer?.type.substring(0,3)}: ${returnedMessage}`)   
      }
    })
    discountText = discountMessageArray.join('\n')
  }else{
    discountText = singleOfferDiscountText(item)
  }
  return discountText
}

  const fetchOffers = async (url = API_OFFERS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => {
        const fields = item?.fields?.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
        const discount = discountFormater(item, data.items)
        return ({
        identity: item.id,
        id: item.id,
        offerNumber: item.offerNumber,
        company: item?.company?.name,
        representative: fields?.representative?.name,
        offerCreator: item.owner.email,
        type: item.type,
        project: returnProjectBadge(item?.project?.name, item?.project?.id),
        projectVariant: item.projectVariant?.name || item.projectVariant?.id || '-',
        createdAt: (new Date(item.createdAt)).toLocaleDateString('pl'),
        warranty: fields.gwarancjaLiczba ? fields.gwarancjaLiczba : fields.okresGwarancji ? fields.okresGwarancji : '-',
        discount,
        status: returnStatusSelect(item.status, item.id, item),
      })});
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  };

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const queryPath = serializeQuery(v);
      const { data } = await getCall(`${API_OFFERS_ROUTE}?${queryPath}`);
      const r = data.items?.map((item) => {
        const fields = item?.fields?.reduce((obj, item) => ({...obj, [item.name]: item.value}) ,{});
        const discount = discountFormater(item, data.items)
        return ({
        identity: item.id,
        id: item.id,
        offerNumber: item.offerNumber,
        company: item?.company?.name,
        representative: fields?.representative?.name,
        offerCreator: item.owner.email,
        type: item.type,
        project: returnProjectBadge(item?.project?.name),
        projectVariant: item.projectVariant?.name || item.projectVariant?.id || '-',
        createdAt: (new Date(item.createdAt)).toLocaleDateString('pl'),
        warranty: fields.gwarancjaLiczba ? fields.gwarancjaLiczba : fields.okresGwarancji ? fields.okresGwarancji : '-',
        discount,
        status: returnStatusSelect(item.status, item.id, item),
      })});
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  useEffect(() => {
    fetchOffers();
    // eslint-disable-next-line
  }, []);

  return (
  <>
    <ListToolBar handleSearch={handleSearch}  /><RowWrapper>
    <ColumnWrapper style={{maxWidth: '350px'}}>
          <OfferSearchFilters handleSearch={handleSearch} />
        </ColumnWrapper>
        <ColumnWrapper fullWidth size="100%">
    <BasicTable
      headCells={[
        { id: 'id', numeric: false, disablePadding: false, label: 'id' },
        { id: 'offerNumber', numeric: false, disablePadding: false, label: 'numer' },
        { id: 'company', numeric: false, disablePadding: false, label: 'firma' },
        { id: 'representative', numeric: false, disablePadding: false, label: 'przedstawiciel firmy' },
        { id: 'offerCreator', numeric: false, disablePadding: false, label: 'twórca oferty' },
        { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
        { id: 'project', numeric: false, disablePadding: false, label: 'projekt' },
        { id: 'projectVariant', numeric: false, disablePadding: false, label: 'wariant' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'data' },
        { id: 'warranty', numeric: false, disablePadding: false, label: 'gwarancja' },
        { id: 'discount', numeric: false, disablePadding: false, label: 'rabat' },
        { id: 'status', numeric: false, disablePadding: false, label: 'status' },

      ]}
      rows={rows.state}
      onChangePage={async (p) =>
        await fetchOffers(`${API_OFFERS_ROUTE}?page=${p}`)
      }
      recordsCount={count}
      onRowClick={({ id }) => routerHistory.push(`/offers/edit/${id}`)}
    />
    </ColumnWrapper>
      </RowWrapper>
  </>
)};

export default OffersScreen;
