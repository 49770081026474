import {TableCell, TableHead, TableRow, TextField} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {LAUNCH, REVIEW, SERVICE} from "../../../../../../constants/RepairActionType";
import {getCall} from "../../../../../../components/indicator/Indicator";
import {API_PRODUCTS_ROUTE} from "../../../../../../constants/routes";
import {toast} from "react-toastify";

export default ({history, onUpdate, devices}) => {

    const findProduct = (products = [], searchesId) => products.find(({id}) => id == searchesId);
    const translateType = type => ({
        [LAUNCH]: 'Uruchomienie',
        [SERVICE]: 'Serwis',
        [REVIEW]: 'Przegląd',
    }[type]);
    const printStandardDevices = useCallback(row =>
        (row?.standard_devices_ids || []).map(id => findProduct(row?.product?.products || [], id)?.name).filter(Boolean).join(', ') || '-',
        [findProduct]);

    const descriptionKey = ({type}) => type === REVIEW
        ? 'vertical_table_description'
        : 'description';


    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Lp</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Czynność</TableCell>
                    <TableCell>Części standardowe</TableCell>
                    <TableCell>Części dodatkowe na gwarancji</TableCell>
                    <TableCell>Części dodatkowe nie na gwarancji</TableCell>
                    <TableCell>Serwisant</TableCell>
                    <TableCell>Notatka</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {history.map((row, index) =>
                        <TableRow key={row.id}>
                            <TableCell>
                                {index + 1}
                            </TableCell>
                            <TableCell>
                                {row?.created_at || '-'}
                            </TableCell>
                            <TableCell>
                                {translateType(row?.type) || '-'}
                            </TableCell>
                            <TableCell>
                                {printStandardDevices(row)}
                            </TableCell>
                            <TableCell>
                                {row?.parts_under_warranty?.map(({name}) => name)?.join(', ')
                                || (row?.fields || [])?.find(({name}) => name === 'parts_under_warranty')?.value?.map(id => findProduct(devices, id)?.name)?.join(', ')
                                || '-'}
                            </TableCell>
                            <TableCell>
                                {row?.parts_not_under_warranty?.map(({name}) => name)?.join(', ')
                                || (row?.fields || [])?.find(({name}) => name === 'parts_not_under_warranty')?.value?.map(id => findProduct(devices, id)?.name)?.join(', ')
                                || '-'}
                            </TableCell>
                            <TableCell>
                                {row?.repairer?.shortcut || '-'}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    placeholder={'Notatka'}
                                    multiline
                                    rows={3}
                                    onChange={({ target: {value: description} }) => onUpdate({
                                        ...row,
                                        [descriptionKey(row)]: description,
                                    })}
                                    value={row[descriptionKey(row)]}
                                    style={{ display: 'flex', minWidth: '50px', width: '100%' }}
                                    variant="outlined"
                                />
                            </TableCell>
                        </TableRow>
                )}
            </TableBody>
        </Table>
    </>
}