import React from 'react';
import MultiTable from "../../components/tables/multi/MultiTable";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import TABLE_DATA_MOCK from '../../constants/multitableMock';

const ReportTableScreen = () => {
  return (
    <ColumnWrapper>
      <MultiTable tables={TABLE_DATA_MOCK} />
    </ColumnWrapper>
  );
};

ReportTableScreen.propTypes = {};

export default ReportTableScreen;
