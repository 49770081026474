import styled from 'styled-components';

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: ${props => props.fullWidth ? '100%' : (props.width ? props.width : 'auto')};
  ${props => props.style ? props.style : null}
`;

export default ColumnWrapper;