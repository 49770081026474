import commonValues from '../../../../../tools/commonValues';

const returnFields = (isAllDay = false, minDate = null) => {
  return [
    { label: '', type: 'multipleFields', options: {
      fields: [
        { label: 'Nazwa', options: { width: 8 }, name: 'title' },
        { label: 'Całodniowe', type: 'checkbox', name: 'allDay', options: { width: 4 }},
        { label: 'Data rozpoczęcia', type: isAllDay ? 'dateonly' : 'date', name: 'start', options: { width: isAllDay ? 6 : 4 } },
        { label: 'Ustaw czas trwania', type: isAllDay ? 'none' : 'time', name: 'duration', options: {}},
        { label: 'Data zakończenia', type: isAllDay ? 'dateonly' : 'date', name: 'end', options: { width: isAllDay ? 6 : 4, attrs: { minDate }} },
        { label: 'Opis', type: 'multilineInput', name: 'description' },
        {
          label: 'Powiązana firma',
          name: 'companies',
          type: 'entity',
          options: {
            width: 4,
            entityClass: 'company'
          },
        },
        {
          label: 'Powiązany kalendarz',
          name: 'calendar',
          type: 'entity',
          options: {
            width: 4,
            entityClass: 'calendar'
          },
        },
        {
          label: 'Priorytet',
          name: 'priority',
          type: 'dictionary',
          options: {
            width: 4,
            data: [{id: null, name: '-'}, ...commonValues.taskPriorities]
          },
        },
        { label: 'Osoby', fullWidth: true, type: 'entity', options: { entityClass: 'user', multiple: true }, name: 'sharedWith' },
        {
          name: "files",
          label: "Pliki",
          type: "rowsGroup",
          value: "[]",
          options: {
              "id": 105,
              "data": [],
              "fields": [
                  {
                      "id": 107,
                      "name": "desc",
                      "type": "text",
                      "label": "Opis",
                      "value": null,
                      "options": { width: 4 }
                  },
                  {
                      "id": 108,
                      "name": "file",
                      "type": "attachment",
                      "label": "Plik",
                      "value": null,
                      "options": { width: 4 }
                  }
              ]
          }
      }
      ]
    }}
    
  ];
};

export default returnFields;
