import { Button, makeStyles, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpansionContainer from '../../../../components/containers/ExpansionContainer';
import Select from '../../../../components/dynamic-forms/form-components/Select';
import ColumnWrapper from '../../../../components/wrappers/ColumnWrapper';
import FloatingSaveBar from '../../../../components/FloatingSaveBar';
import { useParams } from 'react-router-dom';
import { API_HEAT_EXCHANGER_ROUTE } from '../../../../constants/routes';
import { toast } from 'react-toastify';
import { postCall, putCall } from '../../../../components/indicator/Indicator';
import RowWrapper from '../../../../components/wrappers/RowWrapper';
import BackButton from '../../../../components/buttons/back-button/BackButton';
import routerHistory from '../../../../constants/settings/routerHistory';
import styled from 'styled-components';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { Add, Save } from '@material-ui/icons';
const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'grid',
  },
  table: {
    minWidth: 750,
  },
  tableRightBorder: { borderWidth: 0, borderColor: 'red', borderStyle: 'solid' },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
}));

const HeatExchangerEditRow = ({ projectId, initialValues, ...props }) => {
  const [type, setType] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [row, setRow] = useState({
    id: initialValues?.id || null,
    type: initialValues?.type || null,
    purpose: initialValues?.purpose || null,
    primarySideMedium: initialValues?.primarySideMedium || null,
    secondarySideMedium: initialValues?.secondarySideMedium || null,
    maximumPressureDrop: initialValues?.maximumPressureDrop || null,
    heatingPower: initialValues?.heatingPower || null,
    coolingPower: initialValues?.coolingPower || null,
    S1_temperatureDropInExchanger: initialValues?.S1_temperatureDropInExchanger || null,
    D1_deltaSupplyReturn: initialValues?.D1_deltaSupplyReturn || null,
    A1_primarySideTemperatureInput: initialValues?.A1_primarySideTemperatureInput || null,
    A2_primarySideTemperatureOutput: initialValues?.A2_primarySideTemperatureOutput || null,
    A3_secondarySideTemperatureInput: initialValues?.A3_secondarySideTemperatureInput || null,
    A4_secondarySideTemperatureOutput: initialValues?.A4_secondarySideTemperatureOutput || null,
    B1_primarySideTemperatureInput: initialValues?.B1_primarySideTemperatureInput || null,
    B2_primarySideTemperatureOutput: initialValues?.B2_primarySideTemperatureOutput || null,
    B3_secondarySideTemperatureInput: initialValues?.B3_secondarySideTemperatureInput || null,
    B4_secondarySideTemperatureOutput: initialValues?.B4_secondarySideTemperatureOutput || null,
    D2_deltaSupplyReturn: initialValues?.D2_deltaSupplyReturn || null,
    C1_primarySideTemperatureInput: initialValues?.C1_primarySideTemperatureInput || null,
    C2_primarySideTemperatureOutput: initialValues?.C2_primarySideTemperatureOutput || null,
    C3_secondarySideTemperatureInput: initialValues?.C3_secondarySideTemperatureInput || null,
    C4_secondarySideTemperatureOutput: initialValues?.C4_secondarySideTemperatureOutput || null,
  });

  const handleChange = (name, value) => {
    setRow((prevVal) => {
      const newRow = { ...prevVal };
      newRow[name] = value;

      newRow['A2_primarySideTemperatureOutput'] =
        newRow?.A1_primarySideTemperatureInput - newRow?.D1_deltaSupplyReturn;
      newRow['A3_secondarySideTemperatureInput'] =
        newRow?.A1_primarySideTemperatureInput - newRow?.S1_temperatureDropInExchanger;
      newRow['A4_secondarySideTemperatureOutput'] =
        newRow?.A1_primarySideTemperatureInput -
        newRow?.S1_temperatureDropInExchanger -
        newRow?.D1_deltaSupplyReturn;

      newRow['B2_primarySideTemperatureOutput'] =
        newRow?.B1_primarySideTemperatureInput - newRow?.D1_deltaSupplyReturn;
      newRow['B3_secondarySideTemperatureInput'] =
        newRow?.B1_primarySideTemperatureInput - newRow?.S1_temperatureDropInExchanger;
      newRow['B4_secondarySideTemperatureOutput'] =
        newRow?.B1_primarySideTemperatureInput -
        newRow?.S1_temperatureDropInExchanger -
        newRow?.D1_deltaSupplyReturn;

      newRow['C2_primarySideTemperatureOutput'] =
        newRow?.C1_primarySideTemperatureInput - newRow?.D2_deltaSupplyReturn;
      newRow['C3_secondarySideTemperatureInput'] =
        newRow?.coolingPower - newRow?.S1_temperatureDropInExchanger;
      newRow['C4_secondarySideTemperatureOutput'] =
        newRow?.coolingPower - newRow?.D2_deltaSupplyReturn;

      return newRow;
    });
  };

  const submitForm = async () => {
    const callExchanger = async (fn) => {
      const transformedRow = row;

      delete transformedRow['id'];
      delete transformedRow['A2_primarySideTemperatureOutput'];
      delete transformedRow['A3_secondarySideTemperatureInput'];
      delete transformedRow['A4_secondarySideTemperatureOutput'];
      delete transformedRow['B2_primarySideTemperatureOutput'];
      delete transformedRow['B3_secondarySideTemperatureInput'];
      delete transformedRow['B4_secondarySideTemperatureOutput'];
      delete transformedRow['C2_primarySideTemperatureOutput'];
      delete transformedRow['C3_secondarySideTemperatureInput'];
      delete transformedRow['C4_secondarySideTemperatureOutput'];

      transformedRow.project = Number(projectId);
      const r = await fn(`${API_HEAT_EXCHANGER_ROUTE}/${initialValues?.id}`, transformedRow);
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla wymiennika.`);
      }
    };

    await callExchanger(putCall);
  };

  return (
    <TableRow {...props}>
      <TableCell padding="none">
        <Select
          wrapperStyle={{ padding: 0 }}
          data={[
            { id: 'płytowy skręcany', name: 'płytowy skręcany' },
            { id: 'lutowany', name: 'lutowany' },
          ]}
          onSelect={(value) => handleChange('type', value)}
          value={row?.type}
        />
      </TableCell>
      <TableCell padding="none">
        <Select
          wrapperStyle={{ padding: 0 }}
          data={[
            { id: 'c.w.u.', name: 'c.w.u.' },
            { id: 'c.o.', name: 'c.o.' },
            { id: 'c.o./chłód', name: 'c.o./chłód' },
            { id: 'chłód', name: 'chłód' },
            { id: 'basen', name: 'basen' },
          ]}
          onSelect={(value) => handleChange('purpose', value)}
          value={row?.purpose}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.maximumPressureDrop}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) => handleChange('maximumPressureDrop', Number(value))}
        />
      </TableCell>
      <TableCell padding="none">
        <Select
          wrapperStyle={{ padding: 0 }}
          data={[
            { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
            { id: 'woda', name: 'woda' },
            { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
          ]}
          onSelect={(value) => handleChange('primarySideMedium', value)}
          value={row?.primarySideMedium}
        />
      </TableCell>
      <TableCell padding="none">
        <Select
          wrapperStyle={{ padding: 0 }}
          data={[
            { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
            { id: 'woda', name: 'woda' },
            { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
          ]}
          onSelect={(value) => handleChange('secondarySideMedium', value)}
          value={row?.secondarySideMedium}
        />
      </TableCell>
      <TableCell padding="none">
        <Select
          wrapperStyle={{ padding: 0 }}
          data={[
            { id: 2, name: '2' },
            { id: 3, name: '3' },
            { id: 4, name: '4' },
            { id: 5, name: '5' },
          ]}
          onSelect={(value) => handleChange('S1_temperatureDropInExchanger', Number(value))}
          value={row?.S1_temperatureDropInExchanger || null}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.heatingPower}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) => handleChange('heatingPower', Number(value))}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.D1_deltaSupplyReturn}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) => handleChange('D1_deltaSupplyReturn', Number(value))}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.A1_primarySideTemperatureInput || null}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) =>
            handleChange('A1_primarySideTemperatureInput', value)
          }
        />
      </TableCell>

      <TableCell>
        {row?.A1_primarySideTemperatureInput !== '' && row?.A1_primarySideTemperatureInput !== null ?
            row?.A2_primarySideTemperatureOutput
            : '-'}
      </TableCell>
      <TableCell>
        {row?.A1_primarySideTemperatureInput !== '' && row?.A1_primarySideTemperatureInput !== null
            ? row?.A3_secondarySideTemperatureInput
            : '-'}
      </TableCell>
      <TableCell>
        {row?.A1_primarySideTemperatureInput !== '' && row?.A1_primarySideTemperatureInput !== null
          ? row?.A4_secondarySideTemperatureOutput
          : '-'}
      </TableCell>

      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.B1_primarySideTemperatureInput || null}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) =>
            handleChange('B1_primarySideTemperatureInput', value)
          }
        />
      </TableCell>

      <TableCell data={row}>
        {row?.B1_primarySideTemperatureInput !== '' && row?.B1_primarySideTemperatureInput !== null ? row?.B2_primarySideTemperatureOutput : '-'}
      </TableCell>
      <TableCell>
        {row?.B1_primarySideTemperatureInput !== '' && row?.B1_primarySideTemperatureInput !== null ? row?.B3_secondarySideTemperatureInput : '-'}
      </TableCell>
      <TableCell>
        {row?.B1_primarySideTemperatureInput !== '' && row?.B1_primarySideTemperatureInput !== null
          ? row?.B4_secondarySideTemperatureOutput
          : '-'}
      </TableCell>

      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.coolingPower || null}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) => handleChange('coolingPower', value)}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row?.D2_deltaSupplyReturn || null}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) => handleChange('D2_deltaSupplyReturn', value)}
        />
      </TableCell>
      <TableCell padding="none">
        <TextField
          variant="outlined"
          value={row.C1_primarySideTemperatureInput || null}
          type={'number'}
          style={{ margin: 0 }}
          onChange={({ target: { value } }) =>
            handleChange('C1_primarySideTemperatureInput', value)
          }
        />
      </TableCell>

      <TableCell>
        {((row.C1_primarySideTemperatureInput !== null && row.C1_primarySideTemperatureInput !== '') || (row.D2_deltaSupplyReturn !== null && row.D2_deltaSupplyReturn !== '') || (row.coolingPower !== null && row.coolingPower !== ''))
            ? row.C2_primarySideTemperatureOutput
            : '-'}
      </TableCell>
      <TableCell>
        {((row.C1_primarySideTemperatureInput !== null && row.C1_primarySideTemperatureInput !== '') || (row.D2_deltaSupplyReturn !== null && row.D2_deltaSupplyReturn !== '') || (row.coolingPower !== null && row.coolingPower !== ''))
            ? row.C3_secondarySideTemperatureInput
            : '-'}
      </TableCell>
      <TableCell>
        {((row.C1_primarySideTemperatureInput !== null && row.C1_primarySideTemperatureInput !== '') || (row.D2_deltaSupplyReturn !== null && row.D2_deltaSupplyReturn !== '') || (row.coolingPower !== null && row.coolingPower !== ''))
            ? row.C4_secondarySideTemperatureOutput
            : '-'}
      </TableCell>

      <TableCell padding="none">
        <Button color="secondary" onClick={submitForm}>
          <Save />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default HeatExchangerEditRow;
