import React, {useEffect, useMemo, useState} from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
    Chip,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { API_PRODUCTS_ROUTE } from '../../../../../constants/routes';
import { getCall } from '../../../../../components/indicator/Indicator';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import {UseActionZoneStyles} from "./ActionZoneStyles";
import {translateGroup, WITHOUT_GROUP} from "../../../../../constants/RepairDeviceGroup";
import {groupBy} from "lodash";

const ActionLaunchDeviceZone = ({devices, onUpdate, setGroups, groups}) => {
    const classes = UseActionZoneStyles();
    const [rows, setRows] = useState(() => devices.map(device =>
        ({
            ...device,
            repair_device_id: device.id,
            parts_under_warranty: device.parts_under_warranty || [],
            parts_not_under_warranty: device.parts_not_under_warranty || [],
        })
    ));
    const [groupInfo, setGroupInfo] = useState([]);
    const [serviceProductsGroup, setServiceProductsGroup] = useState([]);
    const groupedRows = useMemo(() => {
            const setEmptyGroup = rows => rows.map(row => ({...row, group_name: row?.group_name || WITHOUT_GROUP}));
            const grouped = groupBy(setEmptyGroup(rows), ({group_name, group_serial_no}) => group_name ? `${group_name}${group_serial_no ? '' : ''}` : null);
            return Object.entries(grouped);
        }
        , [rows]);
    
    const handleWarrantyPartsGroup = (e) => {
        setGroups((prev) => ({
            ...prev,
            [e.target.id]: {
                ...prev[e.target.id],
                [e.target.name]: [...e.target.value]
            }
        }))
    }

    const editRow = (name, v, index) => {
        console.log(name, v, index)
        if(typeof index !== 'number' && index) {
            const localRows = [...rows];
            index.forEach((item) => {
                const i = findRow(item);
                console.log("index", i)
                if (~i) {
                    localRows[i][name] = v;
                }
                setRows(localRows);
            })
        } else {
            const localRows = [...rows];
            const i = findRow(index);
            if (~i) {
                localRows[i][name] = v;
            }
            setRows(localRows);
        }
    };
    const findRow = key => rows.findIndex(item => item.id === key);

    const fetchProducts = async () => {
        const { data } = await getCall(`${API_PRODUCTS_ROUTE}?product_producer_type=4`);
        setServiceProductsGroup(() => data?.items.map((item) => ({id: item.id, name: item.name})));
    }

    useEffect(() => {
        onUpdate && onUpdate(rows)
    }, [rows]);

    useEffect(() => {
        if(devices) {
            setGroups(() => {
                const obj = {};
                const filteredDevices = devices.filter((device) => device.group_serial_no)
                const groupSerialNumbers = new Set(filteredDevices.map((device) => device?.group_serial_no));
                
                groupSerialNumbers.forEach(item => {
                    obj[item] = {
                        parts_under_warranty_group: [],
                        parts_not_under_warranty_group: []
                    };
                })
                return obj;
            });

            setGroupInfo(() => {
                const groups = devices.map((device) => {
                    if(device?.group_name) return {
                        group_name: device?.group_name,
                        group_serial_no: device?.group_serial_no,
                    }
                }).filter((v, i, a) => a.findIndex(t => (t?.group_name === v?.group_name)) === i);
                const devicesLength = groups.map((item) => {
                    return devices.filter((i) => i?.group_name === item?.group_name)?.length;
                })
                
                return groups.map((item, i) => ({...item, devicesLength: devicesLength[i]}));;
            })
            
        }
    }, [devices, setGroups])

    useEffect(() => {
        fetchProducts();
    }, [])

    const renderHeader = () => (
        <TableHead>
            <TableRow>
                <TableCell>Lp</TableCell>
                <TableCell>Nr seryjny</TableCell>
                <TableCell>Urządzenie</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Części na gwarancji</TableCell>
                <TableCell>Części nie na gwarancji</TableCell>
            </TableRow>
        </TableHead>
    );

    return (
        <>
            <ExpansionContainer title="Urządzenia">
                <ColumnWrapper fullWidth>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table">
                        {renderHeader()}
                        <TableBody>   
                            {groupedRows.map(([name, items], i) =>
                                <>  
                                    <div style={{marginTop: 15, display: "flex", alignItems: "center"}} key={name}>
                                        <h3>{translateGroup(name)}</h3>
                                    </div>
                                    {name !== 'WITHOUT_GROUP' && (
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <b>{groupInfo[i]?.group_serial_no}</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Ilość urządzeń: {groupInfo[i]?.devicesLength}</b>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell style={{ width: '400px' }}>
                                                <Select
                                                    id={groupInfo[i]?.group_serial_no}
                                                    name="parts_under_warranty_group"
                                                    multiple
                                                    value={groups[groupInfo[i]?.group_serial_no]?.parts_under_warranty_group || []}
                                                    onChange={(e) => {
                                                        e.target.id = groupInfo[i]?.group_serial_no;
                                                        handleWarrantyPartsGroup(e)
                                                    }}
                                                    style={{ backgroundColor: '#f2f2f3', width: '100%' }}
                                                    variant="outlined"
                                                    renderValue={(selected) => (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={serviceProductsGroup.find(i => i.id === value).name}
                                                                    style={{ margin: 5 }}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                >
                                                    {serviceProductsGroup.map((product) => (
                                                        <MenuItem key={product.id} value={product.id}>
                                                            {product.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell style={{ width: '400px' }}>
                                                <Select
                                                    id={groupInfo[i]?.group_serial_no}
                                                    name="parts_not_under_warranty_group"
                                                    multiple
                                                    value={groups[groupInfo[i]?.group_serial_no]?.parts_not_under_warranty_group || []}
                                                    onChange={(e) => {
                                                        e.target.id = groupInfo[i]?.group_serial_no;
                                                        handleWarrantyPartsGroup(e);
                                                    }}
                                                    style={{ backgroundColor: '#f2f2f3', width: '100%' }}
                                                    variant="outlined"
                                                    renderValue={(selected) => (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={serviceProductsGroup.find(i => i.id === value).name}
                                                                    style={{ margin: 5 }}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                >
                                                    {serviceProductsGroup.map((product) => (
                                                        <MenuItem key={product.id} value={product.id}>
                                                            {product.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {items.map((item, index) =>
                                        <RepairDevicesZoneRow i={item} index={item.uuid || item.id}
                                                              key={item.uuid || item.id}
                                                              editRow={editRow}
                                                              rowIndex={index + 1}
                                                              rows={rows}
                                        />)}
                                        </>
                            )}
                        </TableBody>
                    </Table>
                </ColumnWrapper>
            </ExpansionContainer>
        </>
    );
};

const RepairDevicesZoneRow = ({
    i, 
    index, 
    rowIndex, 
    editRow, 
    rows,
}) => {
    
    const products = useMemo(() => i?.product?.products || [], [i]);
    const findProduct = searchesId => products.find(({id}) => id === searchesId);

    return <>
        <TableRow key={i.id + 'row'}>
            <TableCell>
                {rowIndex}
            </TableCell>
            <TableCell>
                {i?.serial_no || '-'}
            </TableCell>
            <TableCell>
                {i?.product?.name || '-'}
            </TableCell>
            <TableCell>
                {i?.identity || '-'}
            </TableCell>
            <TableCell>
                {returnSpecificField(
                    'dictionary_multiple',
                    {data: products || []},
                    'parts_under_warranty',
                    (i?.parts_under_warranty || []).map(({id}) => id),
                    (e, v) => {
                        editRow(e, (v || []).map(id => findProduct(id)), index)
                    }
                )}
            </TableCell>
            <TableCell>
                {returnSpecificField(
                    'dictionary_multiple',
                    {data: products || []},
                    'parts_not_under_warranty',
                    (i?.parts_not_under_warranty || []).map(({id}) => id),
                    (e, v) => {
                        editRow(e, (v || []).map(id => findProduct(id)), index)
                    }
                )}
            </TableCell>
        </TableRow>
    </>
};

export default ActionLaunchDeviceZone;
