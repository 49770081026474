import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  Button,
  InputLabel,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import RowWrapper from "../../../../../../components/wrappers/RowWrapper";
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import PrimaryButton from "../../../../../../components/buttons/PrimaryButton";
import returnSpecificField from "../../../../../../components/dynamic-forms/returnSpecificField";
import StartPriceTableForm from './StartPriceTableForm';
import { getCall } from '../../../../../../components/indicator/Indicator';
import { API_START_PRICE_CALC } from '../../../../../../constants/routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '60%',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const OfferDevicesStartPriceCalculator = ({initRows = [], initialLaunchCost, onLaunchCost, ...props}) => {

  const classes = useStyles();

  const edit = (e, v) => {

  }

  React.useEffect(() => {
    if(initRows) {
      const summed  = initRows.reduce((prev, curr) => prev + Number(curr.amount), 0);
      const costs = initRows.reduce((prev, curr) => prev + (Number(curr.amount) * Number(curr?.product?.net_list_price)), 0);
      setDevicesSum(summed);
      setSumCostProducts(costs);
      setCalculatedCost(costs);
    }
  }, [initRows]);

  const [launchCost, setLaunchCost] = useState(initialLaunchCost || 0);
  const [addition, setAddition] = useState(0);
  const [devicesSum, setDevicesSum] = useState(0);
  const [calculatedCost, setCalculatedCost] = useState(0);
  const [sumCostProducts, setSumCostProducts] = useState(0);

  const saveCost = () => {
    if(calculatedCost) {
      setLaunchCost(calculatedCost);
      onLaunchCost(calculatedCost);
      handleClose();
    }
  }
  const count = async () => {
    const { data: { addition } } = await getCall(`${API_START_PRICE_CALC}${Number(devicesSum)}`);
    setAddition(Number(addition));
  }

  useEffect(() => {

    count();
    // sum changed
  }, [devicesSum]);

  useEffect(() => {
    if(addition) {
      setCalculatedCost(Number(sumCostProducts) + Number(addition));
    }
    // addition changed
  }, [addition]);

  
  const [open, setOpen] = React.useState(false);
  const [openEditTable, setOpenEditTable] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <RowWrapper fullWidth style={{marginTop: '20px'}}>
        <ColumnWrapper width="20%">
          {returnSpecificField('text', {}, 'launchCost', launchCost, (e, v) => {setLaunchCost(v); 
      onLaunchCost(v);} )}
        </ColumnWrapper>
        <ColumnWrapper width="20%" style={{padding: '1.3em 1em'}}>
          <PrimaryButton size="small" onClick={handleOpen}>
            Oblicz koszt uruchomienia
          </PrimaryButton>
          <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="calc-launch-price-modal-title"
          >
            <div className={classes.paper}>
              <h2 id="calc-launch-price-modal-title">Oblicz koszt uruchomienia</h2>
              <RowWrapper style={{flexWrap: 'wrap'}}>
                <ColumnWrapper width="30%">
                  <InputLabel
                    style={{ marginTop: 10 }}
                    shrink
                    htmlFor={'launchCost'}
                    >
                    Suma ilości urządzeń
                  </InputLabel>
                  {returnSpecificField('text', {attrs: {disabled:true}}, 'devicesSum', devicesSum, (e, v) => {setDevicesSum(Number(v) || 0)}, "Suma ilości urządzeń" )}
                </ColumnWrapper>
                <ColumnWrapper width="30%">
                  <InputLabel
                    style={{ marginTop: 10 }}
                    shrink
                    htmlFor={'launchCost'}
                    >
                    Dodatek
                  </InputLabel>
                  {returnSpecificField('text', {}, 'addition', addition, (e, v) => {setAddition(v)}, "Dodatek" )}
                </ColumnWrapper>
                <ColumnWrapper width="10%">
                </ColumnWrapper>
                <ColumnWrapper width="30%">
                  <Button size="small" variant="secondary" color="secondary" onClick={() => setOpenEditTable(true)} style={{marginTop: '10px'}}>
                    Edytuj tabelę
                  </Button>
                </ColumnWrapper>
                
                <ColumnWrapper width="30%">
                  <InputLabel
                    style={{ marginTop: 10 }}
                    shrink
                    htmlFor={'launchCost'}
                    >
                    Koszt całkowity
                  </InputLabel>
                  {returnSpecificField('text', {}, 'calculatedCost', calculatedCost, (e, v) => {edit(e, v)}, "Koszt całkowity" )}
                </ColumnWrapper>
                
                <ColumnWrapper width="30%">
                  <PrimaryButton size="small" onClick={count} style={{marginTop: '10px'}}>
                    Oblicz
                  </PrimaryButton>
                </ColumnWrapper>
                
                <ColumnWrapper width="10%"></ColumnWrapper>
                <ColumnWrapper width="30%">
                <PrimaryButton size="small" onClick={saveCost} style={{marginTop: '10px'}}>
                    Zapisz
                  </PrimaryButton>
                </ColumnWrapper>
              </RowWrapper>
            </div>
          </Modal>
          <Modal
            open={openEditTable}
            onClose={() => setOpenEditTable(false)}
            className={classes.modal}>
              <Paper>
                <StartPriceTableForm displayed={openEditTable} />  
              </Paper>          
          </Modal>
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
};

export default OfferDevicesStartPriceCalculator;
