const producerFields = [
    {
        "id": 30,
        "name": "Szczegóły",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 35,
                    "name": "Dane (adres)",
                    "label": "Dane (adres)",
                    "type": "text",
                    "fullWidth": true,
                    "value": null,
                    "options": null
                },{
                    "id": 35,
                    "name": "Osoba do kontaktu",
                    "label": "Osoba do kontaktu",
                    "type": "text",
                    "fullWidth": true,
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Link strony",
                    "label": "Link strony",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Link strony - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "Narzędzia - linki, hasła, loginy",
                    "label": "Narzędzia - linki, hasła, loginy",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Narzędzia - linki, hasła, loginyy - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "Logo",
                    "label": "Logo",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Logo - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "kolor (paleta real)",
                    "label": "Kolor (paleta real)",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "kolor (paleta real) - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "warunki handlowe",
                    "label": "Warunki handlowe",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "warunki handlowe - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "bonusy",
                    "label": "Bonusy",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "Bonusy - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "czasy dostawy",
                    "label": "Czasy dostawy",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "czasy dostawy - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "warunki gwarancyjne",
                    "label": "Warunki gwarancyjne",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "warunki gwarancyjne - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "umowy współpracy",
                    "label": "Umowy współpracy",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "umowy współpracy - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "notatki",
                    "label": "Notatki",
                    "type": "text",
                    "value": null,
                    options: {
                        attrs: {
                            multiline: true,
                            rows: 5
                        }
                    }
                },
                {
                    "id": 36,
                    "name": "notatki - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "pliki",
                    "label": "Pliki",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "pliki - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 36,
                    "name": "cennik",
                    "label": "Cennik",
                    "type": "text",
                    "value": null,
                    "options": null
                },
                {
                    "id": 36,
                    "name": "cennik - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                {
                    "id": 37,
                    "name": "notatki serwisowe",
                    "label": "Notatki serwisowe",
                    "type": "text",
                    "value": null,
                    options: {
                        attrs: {
                            multiline: true,
                            rows: 5
                        }
                    }
                },
                {
                    "id": 37,
                    "name": "cennik - plik",
                    "label": "Plik",
                    "type": "attachment",
                    "value": null,
                    "options": null
                },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
                { id: 0, type: 'none', value: null },
            ]
        }
    }
];

export default producerFields;