import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { getCall, postCall } from "../../components/indicator/Indicator";
import {
  API_LOGIN_ROUTE,
  API_USER_PROFILE_ROUTE
} from '../../constants/routes';
import { useStoreState, useStoreActions } from 'easy-peasy';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginScreen = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const location = useLocation();

  let history = useHistory();

  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const { setLoggedInUser, setIsAuthenticated } = useStoreActions((actions) => ({
    setLoggedInUser: actions.auth.setLoggedInUser, 
    setIsAuthenticated: actions.auth.setIsAuthenticated
  }));

  React.useEffect(() => {
    if(isAuthenticated) {
      history.push("/main");
    }
  }, [isAuthenticated]);

  const loginUser = async () => {
    const { email, password } = formData;
    const d = await postCall(API_LOGIN_ROUTE, { username: email, password });
    if (d.code !== 401 && d.status === 'ok' && d.data.token) {
      localStorage.setItem('loginToken', d.data.token);

      const userData = await getCall(API_USER_PROFILE_ROUTE);

      if (userData.data?.id) {
        setLoggedInUser(userData.data);
        setIsAuthenticated(true);
      }
    } else toast.warn('Wprowadzone dane są nieprawidłowe.');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && formData.email && formData.password) {
      loginUser();
    }
  };

  return (
    <>
      {isAuthenticated && location.refferer ? (
        <Redirect to={location.refferer} />
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <form className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Logowanie
            </Typography>
            <div className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adres e-mail"
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Hasło"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <PrimaryButton
                disabled={!(formData.email && formData.password)}
                onClick={loginUser}
                style={{ width: '100%', margin: '20px 0' }}>
                Zaloguj
              </PrimaryButton>
            </div>
          </form>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      )}
    </>
  );
};

export default LoginScreen;
