import React from 'react';
import routerHistory from "../../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../../components/buttons/back-button/BackButton";
import RowWrapper from "../../../../../../components/wrappers/RowWrapper";
import ColumnWrapper from '../../../../../../components/wrappers/ColumnWrapper';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';
import {useParams} from "react-router-dom";

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
  position: sticky;
  padding-top: 10px;
  background: #fafafa;
  top: 0px;
  z-index: 10;
`;


export default () => {
  const { id } = useParams();

  return (
      <>
        <Wrapper>
          <RowWrapper style={{alignItems: 'center'}}>
            <BackButton
                text="Wróć"
                onClick={() => routerHistory.goBack()}
            />
            <ColumnWrapper>
              <Typography>Szablon</Typography>
            </ColumnWrapper>
          </RowWrapper>
        </Wrapper>
      </>
  );
};