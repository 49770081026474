import VerticalTable from "../../../../../components/tables/vertical/VerticalTable";
import React, {useCallback, useMemo} from "react";
import TextField from "@material-ui/core/TextField";
import Select from "../../../../../components/dynamic-forms/form-components/Select";
import ActionReviewDeviceVerticalTableColumns from "./ActionReviewDeviceVerticalTableColumns";

export default ({templateFields, rows = []}) => {

    const templateHeaders = useMemo(() =>
            (templateFields || [])?.map(field => {
                const key = Object.keys(field)?.[0];

                return key.includes('szablon_field') ? {
                    header: field?.[key],
                    key: key + '_' + String(field?.[key]).replace(' ', '_').toLocaleLowerCase()
                } : null
            }).filter(Boolean) || []
        , [templateFields]
        )
    ;

    const columns = ActionReviewDeviceVerticalTableColumns(templateHeaders);

    const TextRow = useCallback((options = {}) => (row, {key, header}) =>
            <TextField placeholder={header}
                       disabled={true}
                       variant="outlined"
                       value={row?.fields?.[key] || ''}
                       {...options}
            />
        , []);

    const SelectRow = useCallback((options = {}) => (row, {key, header}) =>
        <Select placeholder={header}
                data={options.data}
                variant="outlined"
                disabled={true}
                value={row?.fields?.[key] || ''}
                {...options}
        />, []);

    const PartsSelectRow = useCallback((row, column) =>
            SelectRow({
                multiple: true,
                data: row?.product?.products,
                value: (row?.fields?.[column.key] || []),
            })(row, column)
        , []);

    return <>
        <VerticalTable rows={rows} columns={columns}>
            {{
                device: (row) => <div>{row?.product?.name || '-'}</div>,
                ...templateHeaders?.reduce((row, {key}) => ({...row, [key]: TextRow()}), {}),
                parts_under_warranty: PartsSelectRow,
                parts_not_under_warranty: PartsSelectRow,
                description: TextRow({multiline: true, rows: 3}),
            }}
        </VerticalTable>
    </>
}