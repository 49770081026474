import React, { useEffect, useState } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import { getCall } from '../../../../components/indicator/Indicator';
import CompanyLabel from '../../../../components/notes/CompanyLabel';
import NoDataPanel from "../../../../components/panels/NoDataPanel";
import BasicTable from '../../../../components/tables/basic/BasicTable';
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { API_PROJECTS_ROUTE } from '../../../../constants/routes';
import routerHistory from '../../../../constants/settings/routerHistory';

const ProjectsBoard = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(null);

  const returnCompanyBadge = (name) => <CompanyLabel companyName={name} />;

  const fetchProjects = async (url = `${API_PROJECTS_ROUTE}/mine`) => {
    const { data } = await getCall(url);
    if (data?.items) {
      setCount(data?.total);
      const r = data?.items.map((item) => ({
        identity: item.id,
        id: item.id,
        name: item.name,
        status: item.status,
        company: returnCompanyBadge(item?.company?.name),
      }));
      setRows(r);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <RowWrapper fullWidth style={{ padding: '0 10px' }}>
        <ExpansionContainer title="Projekty">
          <ColumnWrapper width="100%">
              {!rows?.length && <NoDataPanel text="Brak projektów" />}
              {(rows && rows?.length > 0) && <BasicTable
                headCells={[{ id: 'identity', numeric: false, disablePadding: false, label: 'id' },
                { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
                { id: 'status', numeric: false, disablePadding: false, label: 'status' },
                {
                  id: 'company',
                  numeric: false,
                  disablePadding: false,
                  label: 'firma',
                }]}
                rows={rows}
                minimal={true}
                onChangePage={async (p) =>
                  await fetchProjects(`${API_PROJECTS_ROUTE}/mine?page=${p}`)
                }
                recordsCount={count}
                onRowClick={({ id }) => routerHistory.push(`/projects/edit/${id}`)}
              />}
          </ColumnWrapper>
        </ExpansionContainer>
      </RowWrapper>
    </>
  );
};

export default ProjectsBoard;
