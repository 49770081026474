import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getCall, postCall, putCall } from "../../../components/indicator/Indicator";
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import DevicesZone from "../components/devices/devices-zone/DevicesZone";
import DevicesToolBar from "../components/devices/tool-bar/DevicesToolBar";
import FloatingSaveBar from "../../../components/FloatingSaveBar";
import { API_PROJECT_AUTOMATION_ROUTE, API_PROJECT_DEVICES_ROUTE } from '../../../constants/routes';
import AutomationZone from '../components/automation/automation-zone/AutomationZone';
import AutomationToolbar from '../components/automation/tool-bar/AutomationToolbar';

const ProjectAutomationScreen = () => {
  const [automation, setAutomation] = React.useState({});
  const [variants, setVariants] = React.useState([]);
  const [notes, setNotes] = React.useState({
    notes: []
  });

  const [open, setOpen] = React.useState(true);
  const { id } = useParams();

  const fetchEditData = useCallback(async () => {
    const r = await getCall(`${API_PROJECT_AUTOMATION_ROUTE}/${id}`);
    if (r.data && r.data.item) {
      setAutomation(r.data.item);
      setVariants(r.data?.variants);
      setNotes({
        notes: r.data?.notes || []
      });
      return r.data.item;
    }
    return [];
  }, [id]);

  React.useEffect(() => {
    fetchEditData();
  }, []);

  const fetchNotes = async () => {
    const r = await getCall(`${API_PROJECT_AUTOMATION_ROUTE}/${id}`);
    if (r.data && r.data.item) {
      setNotes({
        notes: r.data?.notes || []
      });
      return r.data.item;
    }
    return [];
  }
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <ColumnWrapper>
        <AutomationToolbar toggleDrawer={toggleDrawer} />
        <AutomationZone automation={automation} fetchNotes={fetchNotes} notes={notes} projectId={id} variants={variants} open={open} />
      </ColumnWrapper>
    </>
  );
};

export default ProjectAutomationScreen;
