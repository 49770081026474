import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_DESC_FIELDS_ROUTE,
  API_DESC_GROUPS_ROUTE,
  API_DICTIONARY_ROUTE,
} from '../../../../../constants/routes';
import FIELD_TYPES from '../../../../../constants/types/fieldTypes';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall,
} from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddEditPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const [dictionaryId, setDictonaryId] = useState(null);
  const [required, setRequired] = useState(false);
  const [type, setType] = useState(null);
  const [allowOther, setAllowOther] = useState(false);
  const [descGroupId, setDescGroupId] = useState(null);
  const [descGroupForRow, setDescGroupForRow] = useState(null);

  const [dictionaries, setDictionaries] = useState([]);
  const [descGroups, setDescGroups] = useState([]);
  const [descGroupForRows, setDescGroupForRows] = useState([]);

  const formik = useFormik({
    initialValues: {
      dirValues: [{ name: '', id: '', value: '' }],
    },
  });

  const { fieldId, onSave } = props;

  useEffect(() => {
    const fetchEditDetails = async () => {
      const { data } = await getCall(`${API_DESC_FIELDS_ROUTE}/${fieldId}`);
      setName(data?.name);
      setType(data?.type);
      setDictonaryId(data?.dictionaryId);
      setRequired(data?.required);
      setAllowOther(data?.allowOther);
      setDescGroupId(data?.descGroupId);
      setDescGroupForRow(data?.descGroupForRows);
    };
    if (fieldId) {
      fetchEditDetails();
    } else {
      setName('');
      setType(null);
      setDictonaryId(null);
      setRequired(false);
      setAllowOther(false);
      setDescGroupId(null);
      setDescGroupForRow(null);
    }
  }, [fieldId]);

  useEffect(() => {
    const fetchDictionaries = async () => {
      const { data } = await getCall(API_DICTIONARY_ROUTE);
      if (data?.items) setDictionaries(data?.items);
    };

    const fetchDescGroups = async () => {
      const { data } = await getCall(`${API_DESC_GROUPS_ROUTE}/all`);
      if (data?.items) {
        const groups = data?.items?.map(({ id, name }) => ({ id, name }));
        setDescGroups(groups);
        setDescGroupForRows(groups);
      }
    };

    fetchDictionaries();
    fetchDescGroups();
  }, []);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const add = async () => {
        const r = await postCall(API_DESC_FIELDS_ROUTE, {
          name,
          dictionary_id: dictionaryId,
          required,
          type,
          allow_other: allowOther,
          desc_group_id: descGroupId,
          desc_group_for_rows: descGroupForRow,
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Dodano nowe pole - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      const edit = async () => {
        const r = await putCall(API_DESC_FIELDS_ROUTE, {
          id: fieldId,
          name,
          dictionary_id: dictionaryId,
          required,
          type,
          allow_other: allowOther,
          desc_group_id: descGroupId,
          desc_group_for_rows: descGroupForRow,
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Pomyślnie zapisano pole - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      if (fieldId) {
        await edit();
      } else {
        await add();
      }
      setOpen(false);
      await onSave();
    } catch (error) {
      toast.error('Wystąpił błąd przy dodawaniu/edycji pola dynamicznego.');
    }
  };

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Container>
            <ColumnWrapper fullWidth>
              <Typography
                style={{ fontWeight: 'bold', margin: '0 0 20px' }}
                variant="body1">
                {fieldId ? 'Edycja' : 'Dodawanie'} dynamicznego pola
              </Typography>
              <TextField
                variant="outlined"
                label="Nazwa"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Select
                label="Słownik"
                name="dictionaryId"
                data={dictionaries}
                onSelect={(i) => setDictonaryId(i)}
                value={dictionaryId}
              />
              <Select
                label="Typ"
                name="type"
                data={FIELD_TYPES}
                onSelect={(i) => setType(i)}
                value={type}
              />
              <Select
                label="Należy do grupy"
                name="descGroupId"
                data={descGroups}
                onSelect={(i) => setDescGroupId(i)}
                value={descGroupId}
              />
              <Select
                label="Powielaj grupę"
                name="descGroupForRows"
                data={descGroupForRows}
                onSelect={(i) => setDescGroupForRow(i)}
                value={descGroupForRow}
              />
              <FormControlLabel
                style={{ margin: '10px 0 0 -10px' }}
                control={
                  <Checkbox
                    name="required"
                    checked={required}
                    onChange={({ target }) => setRequired(target.checked)}
                  />
                }
                label="Czy pole ma być wymagane"
              />
              <FormControlLabel
                style={{ margin: '5px 0 0 -10px' }}
                control={
                  <Checkbox
                    name="allowOther"
                    checked={allowOther}
                    onChange={({ target }) => setAllowOther(target.checked)}
                  />
                }
                label="Zezwalaj na inne wartości"
              />
            </ColumnWrapper>
          </Container>
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: 20 }}>
          <PrimaryButton
            variant="raised"
            onClick={handleClose}
            color="secondary">
            Anuluj
          </PrimaryButton>
          <PrimaryButton disabled={!name} onClick={handleSave}>
            Zapisz
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
});

AddEditPopup.propTypes = {
  fetchDocuments: PropTypes.func,
};

export default AddEditPopup;
