import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getCall } from '../../../../../components/indicator/Indicator';
import { API_PROJECTS_ROUTE } from '../../../../../constants/routes';
import routerHistory from "../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../components/buttons/back-button/BackButton";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { InputLabel, InputWrapper } from "../../../../../components/FormComponents";
import ActiveStatus from '../../list/ActiveStatus';
import { Message } from '@material-ui/icons';
import { useEffect } from 'react';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const AutomationToolbar = ({toggleDrawer}) => {
  const [projectName, setProjectName] = useState('');
  const { id } = useParams();

  useEffect(() => {
    if(id) {
      const fetchProject = async () => {
        const p = await getCall(`${API_PROJECTS_ROUTE}/${id}`);
        setProjectName(p?.data.object.name);
      }
      fetchProject();
    }
  }, [])

  return (
    <>
      <Wrapper style={{ position: 'sticky', top: 0, zIndex: 10, paddingTop: '10px', background: '#fafafa', }}>
        <RowWrapper style={{alignItems: 'center'}}>
          <BackButton
            text="Wróć do projektu"
            onClick={() => routerHistory.push(`/projects/edit/${id}`)}
          />
          <ColumnWrapper>
            <Typography>Automatyka - Projekt: {id} {projectName}</Typography>
          </ColumnWrapper>
          
        </RowWrapper>
        
        <RowWrapper>
          <ButtonWrapper>
            <Button
              onClick={toggleDrawer}
              startIcon={<Message />}
              style={{ color: 'white' }}
              variant="contained"
              color="secondary">
              Notatki
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default AutomationToolbar;
