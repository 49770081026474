import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import BackButton from "../../components/buttons/back-button/BackButton";
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import {getCall} from "../../components/indicator/Indicator";
import {API_REPAIRS_ROUTE} from "../../constants/routes";
import Loader from "../../components/loader/Loader";
import {toast} from "react-toastify";
import routerHistory from "../../constants/settings/routerHistory";
import {LAUNCH, REVIEW, SERVICE} from "../../constants/RepairActionType";
import Table from "@material-ui/core/Table";
import ActionLaunchHistoryTable from "./components/repairs/tables/history/ActionLaunchHistoryTable";
import ActionServiceHistoryTable from "./components/repairs/tables/history/ActionServiceHistoryTable";
import ActionReviewHistoryTable from "./components/repairs/tables/history/ActionReviewHistoryTable";

export default () => {
    const [loading, setLoading] = useState(true);
    const [actions, setActions] = useState([]);
    const [repairId, setRepairId] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        const loadData = async () => {
            await fetchData();
            setLoading(false);
        };
        loadData()
    }, [id]);

    const fetchData = async () => {
        try {
            const {data: {object}} = await getCall(`${API_REPAIRS_ROUTE}/${id}`);
            setActions(() => object?.actions || []);
            setRepairId(() => object?.id)
        } catch {
            toast.error('Wystąpił błąd podczas pobierania danych.');
        }
    };

    const printActionTitle = useCallback(({type, created_at}) => {
        const name = {
            [LAUNCH]: 'Uruchomienie',
            [SERVICE]: 'Serwis',
            [REVIEW]: 'Przegląd',
        }[type] + '';

        return `${name} - ${created_at}`
    }, []);

    const Table = useCallback(({action}) => {
        const tables = {
            [LAUNCH]: <ActionLaunchHistoryTable action={action} repairId={repairId}/>,
            [SERVICE]: <ActionServiceHistoryTable action={action} repairId={repairId}/>,
            [REVIEW]: <ActionReviewHistoryTable action={action} repairId={repairId}/>,
        };
        return tables[action.type] || null
    }, [repairId]);

    return (
        <>
            {loading
                ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}><Loader/></div>
                : <>
                    <ColumnWrapper>
                        <RowWrapper>
                            <BackButton
                                text="Wróć do karty serwisowej"
                                onClick={() => routerHistory.push('/service/repairs/edit/' + id)}
                            />
                        </RowWrapper>
                        <RowWrapper>
                            <ColumnWrapper>
                                <ExpansionContainer title="Historia">
                                    {actions.map(action =>
                                        <ColumnWrapper style={{marginBottom: 30}} key={action.id} fullWidth>
                                            <h3 style={{cursor: 'pointer'}}
                                                onClick={() => routerHistory.push(`/service/repairs-actions/${action.id}/preview`)}
                                            >{printActionTitle(action)}</h3>
                                            <Table action={action}/>
                                        </ColumnWrapper>
                                    )}
                                </ExpansionContainer>
                            </ColumnWrapper>
                        </RowWrapper>
                    </ColumnWrapper>
                </>
            }
        </>
    );
};