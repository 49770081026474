import { Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import Select from '../../components/dynamic-forms/form-components/Select';
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import AdvancedOfferAuto from "./components/forms/AdvancedOfferAuto";
import AdvancedOfferDevices from "./components/forms/AdvancedOfferDevices";
import AdvancedOfferMerged from "./components/forms/AdvancedOfferMerged";
import AdvancedOfferPeripheral from "./components/forms/AdvancedOfferPeripheral";
import AdvancedOfferService from "./components/forms/AdvancedOfferService";
import AdvancedOffersForm from "./components/forms/AdvancedOffersForm";
import CommonOffersForm from "./components/forms/CommonOffersForm";
import FloatingSaveBar from "../../components/FloatingSaveBar";
import { API_OFFERS_ROUTE } from '../../constants/routes';
import { STATUS_TYPES } from '../../constants/types/offerTypes';
import { getCall, postCall, putCall } from '../../components/indicator/Indicator';
import { useParams } from 'react-router';
import { formToRequest, responseToForm } from '../../components/dynamic-forms/field-transformer';
import { toast } from 'react-toastify';
import BackButton from '../../components/buttons/back-button/BackButton';
import routerHistory from '../../constants/settings/routerHistory';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import OfferDevicesDetailedDevicesZone from '../projects/components/variants/variant-screens/devices/OfferDevicesDetailedDevicesZone';
import {useLocation} from "react-router-dom";
import { useStoreState } from 'easy-peasy';

const OffersFormsScreen = (props) => {
  const [offers] = useState([]);
  const [offer, setOffer] = useState({ devices: []});
  const [type, setType] = useState(null);
  const [devices, setDevices] = useState(null);
  const [round, setRound] = useState();
  const [formData, setFormData] = useState();
  const [offerCurrency, setOfferCurrency] = useState();

  const commonOffersFormRef = useRef();
  const advancedOffersFormRef = useRef();
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setRound(node.getFormValues().roundTo2DecimalPlaces)
      setFormData(node.getFormValues())
      if(node.getFormValues().Waluta !== offerCurrency && offerCurrency){
      node.setFormValue('Waluta', offerCurrency)
      }
    }
  }, [offerCurrency]);

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);
  const [advancedInitialValues, setAdvancedInitialValues] = useState(null);

  const [offerNumber, setOfferNumber] = useState(null);
  const [typeProject, setTypeProject] = useState(null);
  const [status, setStatus] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const search = useLocation().search;

  const selectGivenAdvancedForm = (type) => {
    setType(type);
  };

  useEffect(() => {
    const href = window.location.href;
    if (
        href?.includes('offerNumber') &&
        href?.includes('type') &&
        href?.includes('status') &&
        href?.includes('projectName') &&
        href?.includes('projectId')
    ) {
      setOfferNumber(new URLSearchParams(search).get('offerNumber'));
      setTypeProject(new URLSearchParams(search).get('type'));
      setStatus(new URLSearchParams(search).get('status'));
      setProjectName(new URLSearchParams(search).get('projectName'));
      setProjectId(new URLSearchParams(search).get('projectId'));
    }
  }, []);

  useEffect(() => {
    const fetchAndSetOffers = async () => {
      try {
        if(id) {
          const r = await getCall(API_OFFERS_ROUTE + `/${id}`);
          if (r?.data && !r?.data?.error)  {
            setInitialValues(r.data.item);
            setDevices(r.data.item?.devices);
            setOffer(r.data.item);
            setType(r.data.item?.type);
            setAdvancedInitialValues(responseToForm(r.data.item?.fields));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAndSetOffers();
  }, [id]);

  useEffect(() => {
    if(offerNumber !== null && typeProject !== null && status !== null && projectName !== null && projectId !== null)
      setInitialValues({
        company: {},
        createdAt: "",
        fields: [],
        devices: [],
        id: "",
        offerNumber: offerNumber,
        owner: {},
        project: {
          id: projectId,
          name: projectName
        },
        status: parseInt(status),
        type: typeProject
      });
      setType(typeProject);
  }, [offerNumber, typeProject, status, projectName, projectId]);

  const submitForm = async (url = API_OFFERS_ROUTE + `/${id}`, forceRender = false) => {
    let formValues 
    if (type == 'urzadzenia' || type == 'peryferia'){
      formValues = formData? formData : [];
    }else{
      formValues = advancedOffersFormRef.current ? advancedOffersFormRef.current.getFormValues() : [];
    }
    const commonFormValues = commonOffersFormRef.current.getFormValues();
    const body = {
      ...commonFormValues,
      status: commonFormValues?.status || 0,
      owner: commonFormValues?.owner?.id || null,
      project: commonFormValues?.project?.id || null,
      company: commonFormValues?.company?.id || null,
      projectVariant: commonFormValues?.projectVariant?.id || null,
      fields: formToRequest(formValues || {}),
      devices: devices || null
    };

    if(!body.project) {
      toast.error('Uzupełnij powiązany projekt');
    } else if(!body.projectVariant) {
      toast.error('Uzupełnij wariant projektu');
    } else if(!body.company) {
      toast.error('Uzupełnij powiązaną firmę');
    } else if(!body.owner) {
      toast.error('Uzupełnij osobę tworzącą ofertę');
    } else {
      if(forceRender) {
        const r = await postCall(url, body, [], false);
        var file = r.data;
        if(file) {
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      } else {
        const r = id ? await putCall(url, body, false) : await postCall(API_OFFERS_ROUTE, body, false);
        if (r?.data && !r?.data?.error) {
          if (id) {
            toast.success('Zapisano zmiany w ofercie')
            
          }  else {
            toast.success('Dodano nową ofertę')
            routerHistory.push('/offers/edit/' + r?.data?.item?.id)
          }
        } else toast.error('Wystąpił błąd.');
  
      }
    }

    
  };

  const render = async () => {
    const response = await postCall(API_OFFERS_ROUTE + '/1/render', [], [], false);

    var file = response.data;
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const setRootRows = (newRows) => {
    setDevices(newRows);
  }

  return (
    <>
      <RowWrapper style={{'alignItems': 'center'}}>
        <BackButton
          text="Wróć do listy"
          onClick={() => routerHistory.push('/offers')}
        />
        <ColumnWrapper >
          <Typography>{id ? `${(initialValues?.offerNumber) ? `Oferta ${initialValues?.offerNumber}` : `Edycja oferty`}` : 'Nowa oferta'}</Typography>
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper>
        <ColumnWrapper width="100%">
          <CommonOffersForm
            initialValues={initialValues}
            ref={commonOffersFormRef}
            onTypeChange={selectGivenAdvancedForm}
          />
          
        </ColumnWrapper>
      </RowWrapper>
      <AdvancedOffersForm >
        {type == 'automatyka' && <AdvancedOfferAuto
            initialValues={advancedInitialValues} ref={advancedOffersFormRef} projectVariant={initialValues?.projectVariant?.id} />}
        {type == 'serwis' && <AdvancedOfferService id={id} submitForm={submitForm} commonOffersFormRef={commonOffersFormRef}
            initialValues={advancedInitialValues} ref={advancedOffersFormRef} />}
        {type == 'urzadzenia' && 
          <>
            <OfferDevicesDetailedDevicesZone vid={initialValues?.projectVariant?.id} offer={offer} setRootRows={setRootRows} rounded={round} setOfferCurrency={setOfferCurrency} />
            <AdvancedOfferDevices  
            initialValues={advancedInitialValues} ref={measuredRef}  />
          </>}
        {type == 'peryferia' && <>
            <OfferDevicesDetailedDevicesZone vid={initialValues?.projectVariant?.id} offer={offer} setRootRows={setRootRows} rounded={round} setOfferCurrency={setOfferCurrency}/>
            <AdvancedOfferPeripheral  
            initialValues={advancedInitialValues} ref={measuredRef} />
            </>}
        {type == 'scalona' && <AdvancedOfferMerged  
            initialValues={advancedInitialValues} ref={advancedOffersFormRef} offer={offer} setRootRows={setRootRows} />}
      </AdvancedOffersForm>
      <FloatingSaveBar onClick={() => submitForm()} disabled={!id && !type} name={"Zapisz"}>
        {id && type != 'serwis' && <PrimaryButton style={{marginLeft: '20px'}} disabled={!id} onClick={() => submitForm(API_OFFERS_ROUTE + `/${id}/render`, true)}>
          Wygeneruj PDF
        </PrimaryButton>}
      </FloatingSaveBar>
    </>
  );
};

OffersFormsScreen.propTypes = {};

export default OffersFormsScreen;
