import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { API_DESC_GROUPS_ROUTE } from '../../../../../constants/routes';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import {
  getCall,
  postCall,
  putCall,
} from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddEditPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  const [type, setType] = useState('');

  const { groupId, onSave } = props;

  useEffect(() => {
    const fetchEditDetails = async () => {
      const { data } = await getCall(`${API_DESC_GROUPS_ROUTE}/${groupId}`);
      setName(data?.name);
      setType(data?.type);
    };
    if (groupId) {
      fetchEditDetails();
    } else {
      setName('');
      setType('');
    }
  }, [groupId]);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      const add = async () => {
        const r = await postCall(API_DESC_GROUPS_ROUTE, {
          name,
          type,
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Dodano nowe grupę - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      const edit = async () => {
        const r = await putCall(API_DESC_GROUPS_ROUTE, {
          id: groupId,
          name,
          type,
        });
        if (r?.data && !r?.data?.error)
          toast.success(`Pomyślnie zapisano grupę - ${name}.`);
        else toast.error('Wystąpił błąd.');
      };
      if (groupId) {
        await edit();
      } else {
        await add();
      }
      setOpen(false);
      await onSave();
    } catch (error) {
      toast.error('Wystąpił błąd przy dodawaniu/edycji grupy.');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <ColumnWrapper fullWidth>
            <Typography
              style={{ fontWeight: 'bold', margin: '0 0 20px' }}
              variant="body1">
              {groupId ? 'Edycja' : 'Dodawanie'} grupy
            </Typography>
            <TextField
              style={{ marginBottom: 10 }}
              variant="outlined"
              label="Nazwa"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              label="Typ"
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </ColumnWrapper>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton disabled={!name} onClick={handleSave}>
          Zapisz
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

AddEditPopup.propTypes = {
  fetchDocuments: PropTypes.func,
};

export default AddEditPopup;
