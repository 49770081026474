import React from 'react';
import { Typography } from '@material-ui/core';
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import ActionContainer from "../../../components/action-container/ActionContainer";
import routerHistory from "../../../constants/settings/routerHistory";

const ImportMailListScreen = () => (
  <>
    <ColumnWrapper>
      <Typography variant="body2">Wybierz narzędzie do importu kontaktów</Typography>
      <ActionContainer
        onClick={() => routerHistory.push('/marketing/email-list/import/list')}
        label="Nazwa narzędzia"
        buttonText="Importuj"
      />
    </ColumnWrapper>
  </>
);

export default ImportMailListScreen;
