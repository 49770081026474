import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Button as MaterialButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import plLocale from 'date-fns/locale/pl';
import React, {useState} from 'react';
import { API_DOCUMENTS_ROUTE, SERVER_ROUTE } from '../../constants/routes';
import { deleteCall, postFileCall } from '../indicator/Indicator';
import RowWrapper from '../wrappers/RowWrapper';
import EntityField from './form-components/EntityField';
import MultipleFields from './form-components/MultipleFields';
import OfferTextCheckbox from './form-components/OfferTextCheckbox';
import RowsGroup from './form-components/RowsGroup';
import Select from './form-components/Select';
import { ColorPicker } from 'material-ui-color';
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DynamicThermalFields from "./form-components/DynamicThermalFields";
import DynamicCompanyFields from './form-components/DynamicCompanyFields';
import DynamicPeopleField from './form-components/DynamicPeopleField';
import TableGroup from './form-components/TableGroup';
import ProjectOrderRowsGroup from "./form-components/ProjectOrderRowsGroup";
import ProjectTransportRowsGroup from "./form-components/ProjectTransportRowsGroup";
import DynamicProjectsField from './form-components/DynamicProjectsField';
import RepairerWithPreview from './form-components/RepairerWithPreview';
import Tooltip from "@material-ui/core/Tooltip";
import DynamicWonOffer from "./form-components/DynamicWonOffer";
import DynamicSelectCurrency from "./form-components/DynamicSelectCurrency";
import DynamicFieldWithOptions from './form-components/DynamicFieldWithOptions';

const postAttachment = async (attachment) => {
  try {
    const formData = new FormData();
    formData.append('name', attachment.name);
    formData.append('description', '');
    formData.append('file', attachment);

    const { data } = await postFileCall(
      API_DOCUMENTS_ROUTE,
      formData,
      undefined,
      undefined,
      true,
    );
    if (data?.file?.id) {
      return {
        id: data?.file?.id,
        path: data?.file?.path,
        name: data?.file?.name,
      };
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const postAttachments = async (attachments) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < attachments.length; i++) {
      formData.append(`file${i+1}`, attachments[i]);
    }

    formData.append('name', attachments[0].name);
    formData.append('description', '');

    const { data } = await postFileCall(
      `${API_DOCUMENTS_ROUTE}/bulk`,
      formData,
      undefined,
      undefined,
      true,
    );

    // if (data?.file?.id) {
    //   return {
    //     id: data?.file?.id,
    //     path: data?.file?.path,
    //   };
    // }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const removeAttachment = async (attachmentId, callback) => {
  try {
    deleteCall(`${API_DOCUMENTS_ROUTE}/${attachmentId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

const openAttachment = (attachmentPath) => {
  window.open(`${SERVER_ROUTE}/${attachmentPath}`);
};

const returnSpecificField = (
  type,
  options,
  name,
  value,
  onChange,
  label = '',
  currentRowGroupsUrl = null,
  isTab = false,
  onChangeInner = null,
  values = {},
  addProps = {}
) => {

const wordEndings= (value) => {
  if (value==1){
    return 'miesiąc'
  } else if (value < 5){
    return 'miesiące'
  } else if (value >= 5){
    return 'miesięcy'
  }
}

  const returnWarrantyText = (value) => {
      return `Gwarancja ${value} ${wordEndings(value)}, po każdym roku obowiązkowy przegląd wykonany przez Autoryzowany Serwis.`
  }

  switch (type) {
    case 'dictionary':
      return (
        <Select
          label={label}
          name={name}
          data={options.data}
          disabled={options.disabled}
          value={value ? value : null}
          onSelect={(v) => onChange(name, v)}
          {...options?.attrs}
          {...(addProps[name]|| {})}
          wrapperStyle={options.wrapperStyle}          
        />
      );

    case 'dictionary_multiple':
      return (
          <Select
              multiple={true}
              label={label}
              name={name}
              data={options.data}
              value={value ? value : null}
              onSelect={(v) => onChange(name, v)}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'date':
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <KeyboardDateTimePicker
            autoOk={true}
            clearable="true"
            variant="inline"
            name={name}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            minDateMessage="Wybrana data jest zbyt wcześnie"
            value={value ? value : null}
            onChange={(d) => onChange(name, d)}
            ampm={false}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
        </MuiPickersUtilsProvider>
      );

    case 'dateonly':
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <KeyboardDatePicker
            autoOk={true}
            clearable="true"
            variant="inline"
            name={name}
            format="dd.MM.yyyy"
            inputVariant="outlined"
            minDateMessage="Wybrana data jest zbyt wcześnie"
            value={value ? value : null}
            onChange={(d) => onChange(name, d)}
            ampm={false}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
        </MuiPickersUtilsProvider>
      );

    case 'dateNow':
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <KeyboardDateTimePicker
            variant="inline"
            autoOk={true}
            name={name}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            value={value ? value : null}
            onChange={(d) => onChange(name, d)}
            ampm={false}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
          <MaterialButton
            variant="outlined"
            style={{ margin: '10px 0 0 5px' }}
            onClick={() => onChange(name, Date.now())}>
            Dzisiejsza data
          </MaterialButton>
        </MuiPickersUtilsProvider>
      );

    case 'time':
      return (
          <TextField
              id="time"
              type="time"
              variant="outlined"
              defaultValue="00:00"
              value={value ? value : null}
              onChange={(v) => onChange(name, v.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              {...options?.attrs}
          />
      );

    case 'button':
      return <Button size="small" name={name} onClick={() => {
        if(onChangeInner) {
            onChangeInner(name, {
            clicked: options?.index || 0
          })
        } 
      }} 
      {...options?.attrs}
      {...(addProps[name]|| {})}>{options?.text || ''}</Button>;

    case 'last_contact_button':
      return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <RowWrapper><KeyboardDateTimePicker
            variant="inline"
            autoOk={true}
            name={name}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            value={value ? value : null}
            onChange={(d) => {onChange(name, d)}}
            ampm={false}
            {...options?.attrs}
            {...(addProps[name]|| {})}
        />
          <MaterialButton
              variant="outlined"
              style={{ margin: '10px 0 0 5px' }}
              onClick={() => onChange(name, value || Date.now(), true)}>
            {options.icon}
          </MaterialButton></RowWrapper>
      </MuiPickersUtilsProvider>);

    case 'checkbox':
      return (
        label ? (
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                checked={value ? true : false}
                value={value ? value : ''}
                style={{width: 'max-content'}}
                onChange={({ target }) => onChange(name, target.checked)}
                {...options?.attrs}
                {...(addProps[name]|| {})}
              />
            }
            label={label}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
        
        
        ) : <Checkbox
          name={name}
          checked={value ? true : false}
          value={value ? value : ''}
          style={{width: 'max-content'}}
          onChange={({ target }) => onChange(name, target.checked)}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

      case 'productTypeRadio':

      return (
        <RadioGroup
          row
          value={value}
          name={name}
          onChange={({ target }) => onChange(name, target.value)}
          style={{ flexWrap: 'nowrap' }}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        >
          <FormControlLabel value="is_group" control={<Radio />} label="Grupa urządzeń" />
          <FormControlLabel value="is_single" control={<Radio />} label="Pojedyncze urządzenie" />
        </RadioGroup>
      )

      case 'radio':
        return(
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={({ target }) => {onChange(name, target.value); console.log(target)}}
          {...options?.attrs}
          {...(addProps[name]|| {})}
          >
        <FormControlLabel value="tak" control={<Radio />} label="Tak" />
        <FormControlLabel value="nie" control={<Radio />} label="Nie" />
      </RadioGroup>
        ) 

    case 'multipleFields':
      return (
        <MultipleFields
          value={value ? value : null}
          name={name}
          isTab={isTab}
          fields={options.fields}
          onChange={onChange}
          onChangeInner={onChangeInner}
          values={values}
          addProps={addProps}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'rowsGroup':
      return (
        <RowsGroup
          value={value ? value : null}
          name={name}
          fields={options.fields}
          onChange={onChange}
          onChangeInner={onChangeInner}
          addProps={addProps}
          values={values}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'projectOrderRowsGroup':
      return (
          <ProjectOrderRowsGroup
              value={value ? value : null}
              name={name}
              fields={options.fields}
              onChange={onChange}
              onChangeInner={onChangeInner}
              addProps={addProps}
              values={values}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'projectTransportRowsGroup':
      return (
          <ProjectTransportRowsGroup
              value={value ? value : null}
              name={name}
              fields={options.fields}
              onChange={onChange}
              onChangeInner={onChangeInner}
              addProps={addProps}
              values={values}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'dynamicThermalSettings':
      return (
          <DynamicThermalFields
              value={value ? value : null}
              name={name}
              fields={options.fields}
              onChange={onChange}
              onChangeInner={onChangeInner}
              addProps={addProps}
              values={values}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'multilineInput':
      return (
        <TextField
          fullWidth
          multiline
          variant="outlined"
          name={name}
          value={value ? value : ''}
          onChange={({ target }) => onChange(name, target.value)}
          {...options?.attrs}
          {...(addProps[name] || {})}
        />
      );

    case 'offerTextCheckbox':
      return (
        <>
          <OfferTextCheckbox
            name={name}
            value={value ? value : ''}
            fields={options.fields}
            onChange={onChange}
            textWidth={options.textWidth || ''}
            defaultText={options.defaultText || ''}
            valueText={options.valueText || options.defaultText || ''}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
        </>
      );

    case 'offerTextCheckboxDouble':
      return (
        <>
          <OfferTextCheckbox
            name={name}
            value={value ? value : ''}
            fields={options.fields}
            onChange={onChange}
            textWidth={options.textWidth || ''}
            defaultText={options.defaultText || ''}
            defaultText2={options.defaultText2 || ''}
            valueText={options.valueText || options.defaultText || ''}
            valueText2={options.valueText2 || options.defaultText2 || ''}
            {...options?.attrs}
            {...(addProps[name]|| {})}
          />
        </>
      );

    case 'money':
      return (
        <TextField
          name={name}
          type="number"
          onChange={({ target }) => onChange(name, target.value)}
          value={value ? value : ''}
          style={{ margin: '4px 0', display: 'flex' }}
          variant="outlined"
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'price':
      return (
          <TextField
              name={name}
              type="text"
              onKeyDown={($event) => {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
                if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105) && keyCode !== 8) {
                  $event.preventDefault();
                }
                onChange(name, $event.target.value);
              }}
              onBlur={(e) => {
                let x = value.toString().split('.');
                let x1 = x[0];
                let x2 = x.length > 1 ? '.' + x[1] : '';
                let rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                  x1 = x1.replace(rgx, '$1' + ' ' + '$2').replace(/,/g, '.');
                }
                let newVal = x1 + x2;
                onChange(name, newVal);
              }}
              onChange={({ target }) => onChange(name, target.value)}
              value={value ? value : ''}
              style={{ margin: '4px 0', display: 'flex' }}
              variant="outlined"
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'header':
      return (
        <>
          <h3 
          {...options?.attrs}
          {...(addProps[name]|| {})}>{name}</h3>
        </>
      );

    case 'none':
      return (
        <>
        </>
      );

    case 'attachment':
      return (
        <>
          {value?.id ? (
            <RowWrapper autoWidth 
            {...(addProps[name]|| {})}>
              <MaterialButton
                onClick={() => {
                  removeAttachment(value?.id, () => onChange(name, null))
                }}
                style={{ margin: '0 10px', padding: '6px'}}
                variant="outlined">
                <DeleteIcon />
              </MaterialButton>
              <Tooltip title={value?.name}>
              <MaterialButton
                onClick={() => openAttachment(value?.path)}
                variant="outlined">
                <SearchIcon />
              </MaterialButton>
              </Tooltip>
            </RowWrapper>
          ) : (
            <TextField
              variant="outlined"
              name={name}
              onChange={async (event) => {
                // const fileInBase64 = await toBase64(event.currentTarget.files[0]);
                const file = await postAttachment(event.currentTarget.files[0]);
                onChange(name, file);
              }}
              type="file"
              {...(addProps[name]|| {})}
            />
          )}
        </>
      );

    case 'attachments':
      return (
        <>
          {value?.id ? (
            <RowWrapper autoWidth>
              <MaterialButton
                onClick={() =>
                  removeAttachment(value?.id, () => onChange(name, null), true)
                }
                style={{ margin: '0 10px' }}>
                Usuń
              </MaterialButton>
              <MaterialButton
                onClick={() => openAttachment(value?.path)}
                variant="outlined">
                Podgląd
              </MaterialButton>
            </RowWrapper>
          ) : (
            <TextField
              variant="outlined"
              name={name}
              onChange={async (event) => {
                const files = await postAttachments(event.currentTarget.files);
                onChange(name, files);
              }}
              type="file"
              inputProps={{ multiple: true }}
            />
          )}
        </>
      );

    
    case 'entity':
      return (
        <EntityField
          multiple={options?.multiple || false}
          addLabel={options?.addLabel || null}
          label={label}
          name={name}
          values={values}
          entityClass={options.entityClass}
          value={value ? value : null}
          onSelect={(v) => onChange(name, v)}
          freeSolo={options?.freeSolo || false}
          disabled={options?.disabled === undefined ? false : options.disabled}
          additionalFilters={options?.additionalFilters || {}}
          preview={options?.preview || false}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'color':
      return (
        <ColorPicker defaultValue="#f39911" disableAlpha 
          variant="outlined" 
          style={{ display: 'flex', minWidth: '50px', width: '100%' }} 
          value={value ? value : ''} 
          name={name}
          onChange={(v) => onChange(name, v)} 
          {...options?.attrs}
          {...(addProps[name]|| {})} />
      );

    case 'input':
      return (
          <OutlinedInput
              name={name}
              onChange={({ target }) => onChange(name, target.value)}
              value={value ? value : ''}
              style={{ display: 'flex', minWidth: '50px', width: '100%' }}
              variant="outlined"
              endAdornment={options?.attrs?.endAdornment}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );
    
    case 'company':
      return (
        <DynamicCompanyFields
          name={name}
          label={label}
          onChange={(v) => onChange(name, v)}
          value={value ? value : null}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'user':
      return (
        <DynamicPeopleField
          name={name}
          label={label}
          onSelect={(v) => onChange(name, v)}
          value={value ? value : null}
          values={values}
          rowIndex={options?.index}
          dependency={options?.dependency || null}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );
    
    case 'projectAutocomplete':
      return (
        <DynamicProjectsField
          name={name}
          label={label}
          onChange={(v) => onChange(name, v)}
          value={value ? value : null}
          {...options?.attrs}
          {...(addProps[name] || {})}
        />
      )

      case 'autocompleteWithOptions':
        return (
          <DynamicFieldWithOptions
            name={name}
            label={label}
            onChange={(v) => onChange(name, v)}
            value={value ? value : null}
            addOptions={options}
            {...options?.attrs}
            {...(addProps[name] || {})}
          />
        )      

    case 'repairer':
      return (
        <RepairerWithPreview
          name={name}
          label={label}
          onChange={(v) => onChange(name, v)}
          value={value ? value : null}
          {...options?.attrs}
          {...(addProps[name] || {})}
        />
      )

    case 'phone':
      return (
        <TextField
          name={name}
          onChange={({ target }) => {
            let str;
            if(target.value.charAt(0) === '+'){
              str = target.value.slice(0, 12)
            }else{
              str = target.value.slice(0, 9)
            }
            onChange(name, str)}}
          value={value ? value : ''}
          style={{ display: 'flex', minWidth: '50px', width: '100%' }}
          variant="outlined"
          endAdornment={options?.attrs?.endAdornment}
          type="text"
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'wonOffer':
      return (
          <DynamicWonOffer
              name={name}
              label={label}
              onChange={(v) => onChange(name, v)}
              value={value ? value : null}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'selectCurrency':
      return (
          <DynamicSelectCurrency
              name={name}
              label={label}
              onChange={(name, v) => onChange(name, v)}
              value={value ? value : null}
              {...options?.attrs}
              {...(addProps[name]|| {})}
          />
      );

    case 'number':
      return (
        <TextField
          name={name}
          type='number'
          onChange={({ target }) => {onChange(name, parseInt(target.value))}}
          value={value ? value : null}
          style={{ display: 'flex', minWidth: '50px', width: '100%' }}
          variant={options?.attrs?.variant || "outlined"} 
          endAdornment={options?.attrs?.endAdornment}
          inputProps={{
            readOnly: options?.attrs?.readOnly || false,
            min: 0,
          }}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    case 'warranty':
    return (
      <TextField
        name={name}
        type='number'
        onChange={({ target }) => {onChange(name, parseInt(target.value)); onChange('Okres gwarancji - tekst', returnWarrantyText(target.value))}}
        value={value ? value : null}
        style={{ display: 'flex', minWidth: '50px', width: '100%' }}
        variant={options?.attrs?.variant || "outlined"} 
        endAdornment={options?.attrs?.endAdornment}
        inputProps={{
          readOnly: options?.attrs?.readOnly || false,
          min: 0,
        }}
        {...options?.attrs}
        {...(addProps[name]|| {})}
      />
    );

    case 'tableGroup':
      return (
        <TableGroup
          value={value ? value : null}
          name={name}
          fields={options.fields}
          onChange={onChange}
          onChangeInner={onChangeInner}
          addProps={addProps}
          values={values}
          producerId={options?.attrs?.producerId}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );
    
    case 'singleProductLaunch':
      if (values?.product_type === 'is_single') {
        return (
          <Select
            label="Grupa serwisowa (uruchomienie)"
            name={name}
            data={options.data}
            disabled={options.disabled}
            value={value ? value : null}
            disabled={options?.disabled === undefined ? false : options.disabled}
            onSelect={(v) => onChange(name, v)}
            {...options?.attrs}
            {...(addProps[name]|| {})}
            wrapperStyle={options.wrapperStyle}
          />
        );
      }
      break;

    case 'singleProductOverview':
      if (values?.product_type === 'is_single') {
        return (
          <Select
            label="Grupa serwisowa (przegląd)"
            name={name}
            data={options.data}
            disabled={options.disabled}
            value={value ? value : null}
            disabled={options?.disabled === undefined ? false : options.disabled}
            onSelect={(v) => onChange(name, v)}
            {...options?.attrs}
            {...(addProps[name]|| {})}
            wrapperStyle={options.wrapperStyle}
          />
        );
      }
      break;
      
      case "inputWithComas": 
      return (
        <TextField
          name={name}
          onChange={({ target }) => {onChange(name, target.value)}}
          value={value ? value.toString().replaceAll('.' , ',') : ''}
          style={{ display: 'flex', minWidth: '50px', width: '100%' }}
          variant={options?.attrs?.variant || "outlined"} 
          endAdornment={options?.attrs?.endAdornment}
          disabled={options?.disabled === undefined ? false : options.disabled}
          inputProps={{
            readOnly: options?.attrs?.readOnly || false
          }}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );

    default:
      return (
        <TextField
          name={name}
          onChange={({ target }) => {onChange(name, target.value)}}
          value={value ? value : ''}
          style={{ display: 'flex', minWidth: '50px', width: '100%' }}
          variant={options?.attrs?.variant || "outlined"} 
          endAdornment={options?.attrs?.endAdornment}
          inputProps={{
            readOnly: options?.attrs?.readOnly || false
          }}
          {...options?.attrs}
          {...(addProps[name]|| {})}
        />
      );
  }
};

export default returnSpecificField;
