import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import Select from '../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall
} from "../../components/indicator/Indicator";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import FloatingSaveBar from "../../components/FloatingSaveBar";
import DetailsToolBar from './components/details/DetailsToolBar';
import {
  API_COMPANIES_ROUTE,
  API_PROJECTS_ROUTE,
  API_TASKS_ROUTE,
  API_USERS_ROUTE
} from '../../constants/routes';
import { Autocomplete } from '@material-ui/lab';
import TaskDetailsForm from './components/details/TaskDetailsForm';

const TaskDetailsScreen = () => {
  
  const { id } = useParams();
  const [taskObject, setTaskObject] = useState({});
  const [currentDesc, setCurrentDesc] = useState('');

  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar taskObject={taskObject} currentDesc={currentDesc} onUpdateParams={values => setTaskObject(oldProps => ({...oldProps, ...values}))} />
        <TaskDetailsForm taskObject={taskObject} id={id} setTaskObject={setTaskObject} setCurrentDesc={setCurrentDesc} onChangeWaitOnFeedback={waitOnFeedback => setTaskObject(oldProps => ({...oldProps, waitOnFeedback}))} />
      </ColumnWrapper>
    </>
  );
};

export default TaskDetailsScreen;
