import returnSpecificField from "../../../../components/dynamic-forms/returnSpecificField";
import React, {useEffect, useMemo, useState} from "react";
import BasicTable from "../../../../components/tables/basic/BasicTable";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import {DialogActions, Divider, TextField} from "@material-ui/core";

export default ({open, products = [], onClose, onSubmit}) => {

    const [product, setProduct] = useState();
    const [parts, setParts] = useState([]);
    const [serial, setSerial] = useState(null);
    const findProduct = searchesId => products.find(({id}) => id === searchesId);

    const rows = useMemo(() =>
            parts.map(({id, name}) => ({identity: id, id, name})),
        [parts]);

    useEffect(() => setParts(() =>
        [
            ...(product?.products || []),
            ...(product ? [product] : [])
        ]
    ), [product]);

    useEffect(() => {
        setParts(() => []);
        setProduct(() => null);
        setSerial(() => null);
    }, [open]);

    const handleDeletePart = searchesIds => setParts(parts.filter(({id}) => !searchesIds.includes(id)));

    return <>
        <Dialog open={open}
                onClose={onClose}
        >
            <DialogContent>
                <Container>
                    <h2 id="calc-launch-price-modal-title">Dodaj grupę urządzeń</h2>
                    <RowWrapper style={{flexWrap: 'wrap'}}>
                        {returnSpecificField(
                            'dictionary',
                            {data: products},
                            'product',
                            product?.id,
                            (e, v) => {
                                setProduct(() => findProduct(v))
                            },
                            'Produkt'
                        )}
                        <TextField
                            onChange={({ target: {value} }) => setSerial(() => value)}
                            value={serial}
                            style={{ display: 'flex', minWidth: '50px', width: '100%' }}
                            variant="outlined"
                            label={'Numer seryjny grupy'}
                        />
                        <h3 style={{marginTop: 15}}>Powiązane urządzenia</h3>
                        {rows.length > 0 && <BasicTable
                            onDelete={handleDeletePart}
                            useDialogOnDelete
                            headCells={[
                                {id: 'identy', numeric: false, disablePadding: false, label: 'id'},
                                {id: 'name', numeric: false, disablePadding: false, label: 'nazwa'},
                            ]}
                            rows={rows}
                            recordsCount={rows.length}
                        />}
                    </RowWrapper>
                </Container>
            </DialogContent>
            <Divider/>
            <DialogActions style={{padding: 20}}>
                <PrimaryButton variant="raised" onClick={onClose} color="secondary">
                    Anuluj
                </PrimaryButton>
                <PrimaryButton disabled={(!product || !serial)} onClick={() => {
                    onClose();
                    onSubmit(parts.map(part =>
                        ({
                            ...part,
                            id: null,
                            product: findProduct(part.id),
                            group_name: product?.name || null,
                            group_serial_no: serial,
                            uuid: Math.random().toString(36).substring(7)
                        }))
                    );
                }}>
                    Zapisz
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    </>
}