
const serviceActionService = ({repairersOptions = []} = {}) => [
    {
      type: 'multipleFields',
      name: 'Informacje',
      value: [],
      options: { fields: [
            {
              "id": 61,
              "name": "value_project",
              "type": "entity",
              "label": "Nazwa projektu",
              "value": null,
              "options": { width: 4, entityClass: 'project', freeSolo:  true }
              
          },
          { name: 'Puste', type: 'none',  value: '', },
          { name: 'Puste', type: 'none',  value: '', },
          { name: 'Puste', type: 'none',  value: '', },

          { name: 'Ulica', label: 'Ulica', type: 'text', fullWidth: false, value: '', options: { width: 4 }},
          { name: 'Kod', label: 'Kod', type: 'text', fullWidth: false, value: '', options: { width: 4 }},
          { name: 'Miasto', label: 'Miasto', type: 'text', fullWidth: false, value: '', options: { width: 4 }},


          {
            name: "serwis_last_contact",
            type: "text",
            label: "Osoba do kontaktu",
            value: null,
            options: {
                width: 4
            }
        },

        {
            name: "serwis_phone",
            type: "phone",
            label: "Telefon",
            value: null,
            options: { width: 4, type: 'number' }
        },
        {
            name: "serwis_mail",
            type: "text",
            label: "E-mail",
            value: null,
            options: { width: 4 }
        },

        { name: 'Serwisant', label: 'Serwisant', type: 'dictionary', options: {
          data: repairersOptions, width: 4
        }, value: ''},

        { name: 'Puste', type: 'none',  value: '', options: { width: 4 }},
        { name: 'Klient/inwestor', label: 'Klient/inwestor', type: 'text',value: '', options: { width: 4 } },

        { name: 'Data serwisu', label: 'Data serwisu', type: 'date', value: '', options: { width: 4 } },
        { name: 'Numer zlecenia', label: 'Numer zlecenia', type: 'text', value: '', options: { width: 4 } },
        { name: 'Gwarancja', label: 'Gwarancja', type: 'dictionary', options: {
          width: 4,
          data: [
            { id: 'gwarancyjna', name: 'Gwarancyjna'},
            { id: 'pogwarancyjna', name: 'Pogwarancyjna'},
            { id: 'inna', name: 'Inna'},
          ]
        }, value: ''},
        
        { label: 'Tabela urządzeń', name: 'Tabela', type: 'none', fullWidth: true, value: '' },
        { name: 'Tabela', type: 'none', fullWidth: true, value: '' },
        ]
      },
    },
];


export { serviceActionService };