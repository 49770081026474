import commonValues from '../../../../tools/commonValues';

const automationForm = [
    {
        "name": "Dane instalacji",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "id": 75,
                    "name": "Dane instalacji",
                    "type": "header",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_nazwa_obiektu",
                    "type": "text",
                    "label": "Nazwa obiektu",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 76,
                    "name": "a_adres",
                    "type": "text",
                    "label": "Adres",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
            ]
        }
    },
    {
        "name": "Zamówienie",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "id": 75,
                    "name": "Zamówienie",
                    "type": "header",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_oferta_autom",
                    "type": "attachment",
                    "label": "Oferta automatyki",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_type",
                    "type": "dictionary",
                    "label": "Rodzaj automatyki",
                    "value": null,
                    "options": {
                        "width": 6,
                        "data": [
                            {"id": "APG", "name": "APG"},
                            {"id": "ADG", "name": "ADG"},
                            {"id": "AXI", "name": "AXI"},
                            {"id": "DDC", "name": "DDC"},
                            {"id": "iSAP", "name": "iSAP"},
                        ]
                    }
                },
                {
                    "id": 75,
                    "name": "a_gwar",
                    "type": "text",
                    "label": "Gwarancja",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_gwar_data",
                    "type": "dateonly",
                    "label": "Data upływu gwarancji",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_zam_data",
                    "type": "dateonly",
                    "label": "Data zamówienia",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_zam_wart",
                    "type": "text",
                    "label": "Wartość zamówienia",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_czas_realizacji",
                    "type": "text",
                    "label": "Czas realizacji",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_ost_termin_dostawy",
                    "type": "dateonly",
                    "label": "Ostateczny termin dostawy",
                    "value": null,
                    "options": {
                        "width": 6
                    }
                },
                {
                    "id": 75,
                    "name": "a_ost_schem",
                    "type": "attachment",
                    "label": "Ostateczny schemat",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_zestaw_armat_exists",
                    "type": "checkbox",
                    "label": "Zestawienie armatury",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_zestaw_armat",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_zestaw_armat_data",
                    "type": "dateonly",
                    "label": "Data otrzymania zestawienia",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_notatki",
                    "type": "multilineInput",
                    "label": "Notatki",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
            ]
        }
    },
    {
        "name": "CAN BUS",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "id": 75,
                    "name": "CAN BUS",
                    "type": "header",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_canbus_needed",
                    "type": "checkbox",
                    "label": "Zapotrzebowanie",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_canbus_amount",
                    "type": "text",
                    "label": "Ilość",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_canbus_sent",
                    "type": "checkbox",
                    "label": "Wysłany",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_canbus_datesent",
                    "type": "dateonly",
                    "label": "Data wysłania",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
            ]
        }
    },
    {
        "name": "Szafa",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "id": 75,
                    "name": "Szafa",
                    "type": "header",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_szafa_zaprojsent",
                    "type": "checkbox",
                    "label": "Wysłane do zaprojektowania",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_zaprojsent_date",
                    "type": "dateonly",
                    "label": "Data",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_projekt_exists",
                    "type": "checkbox",
                    "label": "Projekt szafy",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_projekt",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_projekt_date",
                    "type": "dateonly",
                    "label": "Data",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_szafa_oslonysent",
                    "type": "checkbox",
                    "label": "Wysłane osłony",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_oslonysent_date",
                    "type": "dateonly",
                    "label": "Data",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_products",
                    "label": "Tabela produktów",
                    "type": "rowsGroup",
                    "value": [],
                    "options": {
                        "width": 12,
                        "data": [],
                        "fields": [
                            {
                                "id": 69,
                                "name": "a_szafa_products_produkt",
                                "type": "entity",
                                "label": "Produkt",
                                "value": null,
                                "options": {
                                    "entityClass": "product"
                                }
                            },
                            {
                                "id": 69,
                                "name": "a_szafa_products_amount",
                                "type": "text",
                                "label": "Ilość",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 69,
                                "name": "a_szafa_products_firmware",
                                "type": "text",
                                "label": "Firmware",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 69,
                                "name": "a_szafa_products_serial",
                                "type": "text",
                                "label": "Numer seryjny",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 69,
                                "name": "a_szafa_products_uwagi",
                                "type": "text",
                                "label": "Uwagi",
                                "value": null,
                                "options": null
                            },
                        ],
                    }
                },
                {
                    "id": 75,
                    "name": "a_szafa_odbr",
                    "type": "checkbox",
                    "label": "Odebranie szafy",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_odbr_date",
                    "type": "dateonly",
                    "label": "Data",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 75,
                    "name": "a_szafa_wsent",
                    "type": "checkbox",
                    "label": "Wysłanie szafy",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 76,
                    "name": "a_szafa_wsent_date",
                    "type": "dateonly",
                    "label": "Data",
                    "value": null,
                    "options": {
                        "width": 3
                    }
                },
                {
                    "id": 75,
                    "name": "a_att_przewody_exists",
                    "type": "checkbox",
                    "label": "Zestawienie przewodów",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_att_przewody",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_przewody_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                {
                    "id": 75,
                    "name": "a_att_instrkonf_exists",
                    "type": "checkbox",
                    "label": "Instrukcja konfiguracji",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_att_instrkonf",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_instrkonf_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                {
                    "id": 75,
                    "name": "a_att_zdjszafy_exists",
                    "type": "checkbox",
                    "label": "Zdjęcie szafy",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },                
                {
                    "id": 75,
                    "name": "a_att_zdjszafy",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_zdjszafy_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                {
                    "id": 75,
                    "name": "a_att_instszafy_exists",
                    "type": "checkbox",
                    "label": "Instrukcja obsługi szafy",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                {
                    "id": 75,
                    "name": "a_att_instszafy",
                    "type": "attachment",
                    "label": "Instrukcja obsługi szafy",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_instszafy_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                {
                    "id": 75,
                    "name": "a_att_wizual_exists",
                    "type": "checkbox",
                    "label": "Wizualizacja",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },                
                {
                    "id": 75,
                    "name": "a_att_wizual",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_wizual_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                
                
                {
                    "id": 75,
                    "name": "a_att_projektster_exists",
                    "type": "checkbox",
                    "label": "Projekt sterowania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },   
                {
                    "id": 75,
                    "name": "a_att_projektster",
                    "type": "attachment",
                    "label": "Plik",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 76,
                    "name": "a_att_projektster_date",
                    "type": "dateonly",
                    "label": "Data dodania",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
            ]
        }
    },
    {
        "name": "Koszt automatyki",
        "type": "multipleFields",
        "value": null,
        "options": {
            "fields": [
                {
                    "id": 75,
                    "name": "Koszt automatyki",
                    "type": "header",
                    "value": null,
                    "options": {
                        "width": 12
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_ksa",
                    "type": "text",
                    "label": "Koszt szafy automatyki",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_kes",
                    "type": "text",
                    "label": "Koszt elementów automatyki",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_ku",
                    "type": "text",
                    "label": "Koszt uruchomienia",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_urser",
                    "type": "text",
                    "label": "Uruchomienie serwisant",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_uraxi",
                    "type": "text",
                    "label": "Uruchomienie AXI",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_trans",
                    "type": "text",
                    "label": "Koszt transportu",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_calk",
                    "type": "text",
                    "label": "Koszt całkowity",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_zysk",
                    "type": "text",
                    "label": "Zysk",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
                {
                    "id": 75,
                    "name": "a_ka_marza",
                    "type": "text",
                    "label": "Marża",
                    "value": null,
                    "options": {
                        "width": 4
                    }
                },
            ]
        }
    },
];


const automationFormStatuses = [
    { id: 'Nie zamowione', name: 'Nie zamówione'},
    { id: 'Brak zestawienia', name: 'Brak zestawienia'},
    { id: 'W realizacji', name: 'W realizacji'},
    { id: 'Jest projekt', name: 'Jest projekt'},
    { id: 'Wysłane', name: 'Wysłane'},
    { id: 'Dostarczone', name: 'Dostarczone'},
    { id: 'Uruchomienie', name: 'Uruchomione'},
    { id: 'Po gwarancji', name: 'Po gwarancji'},
];


export { automationForm, automationFormStatuses };