import React, {useEffect, useState} from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

const DynamicSelectCurrency = ({ name, label, onChange, getOptionLabel, renderInput, value, ...props }) => {
    const [selected, setSelected] = useState('');
    const [newVal, setNewVal] = useState([]);
    const [options, setOptions] = useState(['']);
    const [open, setOpen] = useState(false);
    const loading = open && options.length === 0;

    const handleSelect = (newInputValue) => {
        if(newInputValue?.length) setNewVal([newInputValue.toString().toUpperCase()])
        else setNewVal([])
        setSelected(newInputValue);
        onChange('cena_zakupu_waluta', newInputValue);
    }

    const setOption = () => {
        if(newVal.length){
            const mergeResult = [...new Set([...options, ...newVal])]
            setOptions(mergeResult)
        }
    }

    useEffect(() => {
        setOptions(['PLN', 'EUR'])
    }, [])

    useEffect(() => {
        if(value){
            setSelected(() => value)
        }
    }, [value])

    return (
        <Autocomplete
            name={name}
            options={options}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
                handleSelect(newInputValue);
            }}
            clearOnBlur={false}
            onBlur={(event => setOption())}
            onChange={(event, newValue) => handleSelect(newValue)}
            value={selected || []}
            getOptionLabel={(option) => option || ''}
            loadingText={'Wczytuję...'}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    value={selected || ''}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )
            }
            {...props}
        />
    )
};

export default DynamicSelectCurrency;