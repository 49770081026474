import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  API_KR_USERS_ROUTE,
  API_PROJECTS_ROUTE,
} from '../../../../constants/routes';
import KR_TYPES from '../../../../constants/types/krTypes';
import Select from '../../../../components/dynamic-forms/form-components/Select';
import returnSpecificField from "../../../../components/dynamic-forms/returnSpecificField";
import { getCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";

const ProjectProvision = forwardRef((props, ref) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    
  }, []);

  const formik = useFormik({
    initialValues: {
      provision: [
      ],
    },
  });

  const { projectId } = props;

  useImperativeHandle(ref, () => ({
    getFormValues() {
      return formik.values;
    },
  }));

  useEffect(() => {
    
    // eslint-disable-next-line
  }, [projectId]);

  /*const handleTypeChange = (index, type) => {
    const getpercentFromType = (t) => {
      switch (t) {
        case '1':
          return '20%';
        case '2':
          return '30%';
        case '3':
          return '50%';
        default:
          return '0%';
      }
    };
    formik.setFieldValue(
      `provision.${index}.percent`,
      getpercentFromType(type),
    );
    formik.setFieldValue(`provision.${index}.type`, type);
  };*/

  return (
    <RowWrapper style={{overflowX: 'auto', paddingBottom: '20px'}}>
      <FormikProvider value={formik}>
        <Table>
          <TableHead>
            <TableCell>Rodzaj prowizji</TableCell>
            <TableCell>Firma</TableCell>
            <TableCell>Osoba</TableCell>
            <TableCell>Wysokość prowizji [%]</TableCell>
            <TableCell>Rodzaj działalności</TableCell>
            <TableCell>Wysokość prowizji [netto]</TableCell>
            <TableCell>Wysokość prowizji [brutto]</TableCell>
            <TableCell></TableCell>
            <TableCell>Data wysłania do klienta</TableCell>
            <TableCell>Data otrzymania faktury od klienta</TableCell>
            <TableCell>Kwota do przelewu</TableCell>
            <TableCell>Data wprowadzenia do banku</TableCell>
            <TableCell>Rozliczona</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
        <FieldArray
          name="provision"
          render={(aH) => (
            <>
              {formik.values.provision &&
              formik.values.provision.length > 0
                ? formik.values.provision.map((v, index) => {
                  
                  return (
                    <Fragment key={index}>
                      <TableRow
                        style={{
                          marginTop: 10,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-end',
                        }}
                        fullWidth>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'dictionary',
                            {data: [
                              {id: 'audytowa', name: 'audytowa'},
                              {id: 'projektowa', name: 'projektowa'},
                              {id: 'inna', name: 'inna'},
                            ]},
                            'provisionType',
                            formik.values.provision[index].provisionType,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'entity',
                            {
                              "entityClass": "company",
                              "attrs": {
                                "freeSolo": true
                              }
                            },
                            'company',
                            formik.values.provision[index].company,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'entity',
                            {
                              "entityClass": "user"
                            },
                            'person',
                            formik.values.provision[index].person,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'text',
                            [],
                            'amountPercentage',
                            formik.values.provision[index].amountPercentage,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'dictionary',
                            {"data": []},
                            'businessType',
                            formik.values.provision[index].businessType,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'text',
                            [],
                            'amountNetto',
                            formik.values.provision[index].amountNetto,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'text',
                            [],
                            'amountBrutto',
                            formik.values.provision[index].amountBrutto,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          <Button>Wygeneruj notatkę</Button>
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'dateonly',
                            [],
                            'dateSent',
                            formik.values.provision[index].dateSent,
                            () => {},
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'dateonly',
                            [],
                            'dateInvoice',
                            formik.values.provision[index].dateInvoice,
                            () => {},
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'text',
                            {attrs: {
                              disabled: true
                            }},
                            'amountInvoice',
                            formik.values.provision[index].amountInvoice,
                            () => {}
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'dateonly',
                            [],
                            'dateBank',
                            formik.values.provision[index].dateBank,
                            () => {},
                          )}
                        </TableCell>
                        <TableCell
                          fullWidth
                          style={{ minWidth:200, marginRight: 20 }}>
                          {returnSpecificField(
                            'checkbox',
                            [],
                            'finished',
                            formik.values.provision[index].finished,
                            () => {},
                          )}
                        </TableCell>
                        <TableCell>
                        <Button
                          style={{ marginRight: 5 }}
                          onClick={() => aH.remove(index)}>
                          - Usuń
                        </Button>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  )})
                : null}
                <TableRow>
                  <Button
                    variant="contained"
                    style={{ marginTop: 50, alignSelf: 'flex-start' }}
                    onClick={() =>
                      aH.push({
                        userEntity: '',
                        type: '',
                        percent: '0%',
                      })
                    }>
                    + Dodaj
                  </Button>
              </TableRow>
            </>
          )}
        />
          </TableBody>
        </Table>
      </FormikProvider>
    </RowWrapper>
  );
});

ProjectProvision.propTypes = {};

export default ProjectProvision;
