import { Avatar, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";

const notes = [
];

const Notes = () => {
  return (
    <ExpansionContainer title="Notatki">
      <ColumnWrapper fullWidth>
        <TextField
          placeholder="Wpisz notatkę ..."
          fullWidth
          multiline
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
        <PrimaryButton style={{ alignSelf: 'flex-end' }}>
          Dodaj notatkę
        </PrimaryButton>
        {notes.map((note, i) => (
          <RowWrapper key={`note${i}`}>
            <Paper
              style={{ padding: 10, margin: '10px 0 20px' }}
              variant="outlined">
              <RowWrapper>
                <RowWrapper>
                  <Typography style={{ margin: '0 10px' }} variant="body2">
                    {note.user.name}
                  </Typography>
                </RowWrapper>
                <Typography variant="body2">{note.date}</Typography>
              </RowWrapper>
              <Typography variant="body2">{note.content}</Typography>
            </Paper>
          </RowWrapper>
        ))}
      </ColumnWrapper>
    </ExpansionContainer>
  );
};

export default Notes;
