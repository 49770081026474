const TABLE_DATA_MOCK = [
{
    id: '1',
    name: 'Tabela I',
    headers: [
    {
        key: 'A1',
        label: 'A1',
        colSpan: '6',
    },
    {
        key: 'levelB',
        rowItems: [
        {
            colSpan: '2',
            key: 'B1',
            label: 'B1',
        },
        {
            colSpan: '3',
            key: 'B2',
            label: 'B2',
        },
        {
            colSpan: '1',
            key: 'B3',
            label: 'B3',
        },
        ],
    },
    {
        key: 'levelC',
        rowItems: [
        {
            colSpan: '1',
            key: 'C1',
            label: 'C1',
        },
        {
            colSpan: '1',
            key: 'C2',
            label: 'C2',
        },
        {
            colSpan: '1',
            key: 'C3',
            label: 'C3',
        },
        {
            colSpan: '1',
            key: 'C4',
            label: 'C4',
        },
        {
            colSpan: '1',
            key: 'C5',
            label: 'C5',
        },
        {
            colSpan: '1',
            key: 'C6',
            label: 'C6',
        },
        ],
    },
    ],
    content: [
    {
        C1: 'wartość 1 C1',
        C2: 'wartość 1 C2',
        C3: 'wartość 1 C3',
        C4: 'wartość 1 C4',
        C5: 'wartość 1 C5',
        C6: 'wartość 1 C6',
    },
    {
        C1: 'wartość 2 C1',
        C2: 'wartość 2 C2',
        C3: 'wartość 2 C3',
        C4: 'wartość 2 C4',
        C5: 'wartość 2 C5',
        C6: 'wartość 2 C6',
    },
    {
        C1: 'wartość 3 C1',
        C2: 'wartość 3 C2',
        C3: 'wartość 3 C3',
        C4: 'wartość 3 C4',
        C5: 'wartość 3 C5',
        C6: 'wartość 3 C6',
    },
    ],
},
{
    id: '2',
    name: 'Tabela II',
    headers: [
    {
        key: 'A1',
        label: 'A1',
        colSpan: '6',
    },
    {
        key: 'levelB',
        rowItems: [
        {
            colSpan: '2',
            key: 'B1',
            label: 'B1',
        },
        {
            colSpan: '3',
            key: 'B2',
            label: 'B2',
        },
        {
            colSpan: '1',
            key: 'B3',
            label: 'B3',
        },
        ],
    },
    {
        key: 'levelC',
        rowItems: [
        {
            colSpan: '1',
            key: 'C1',
            label: 'C1',
        },
        {
            colSpan: '1',
            key: 'C2',
            label: 'C2',
        },
        {
            colSpan: '1',
            key: 'C3',
            label: 'C3',
        },
        {
            colSpan: '1',
            key: 'C4',
            label: 'C4',
        },
        {
            colSpan: '1',
            key: 'C5',
            label: 'C5',
        },
        {
            colSpan: '1',
            key: 'C6',
            label: 'C6',
        },
        ],
    },
    ],
    content: [
    {
        C1: 'wartość 1 C1',
        C2: 'wartość 1 C2',
        C3: 'wartość 1 C3',
        C4: 'wartość 1 C4',
        C5: 'wartość 1 C5',
        C6: 'wartość 1 C6',
    },
    {
        C1: 'wartość 2 C1',
        C2: 'wartość 2 C2',
        C3: 'wartość 2 C3',
        C4: 'wartość 2 C4',
        C5: 'wartość 2 C5',
        C6: 'wartość 2 C6',
    },
    {
        C1: 'wartość 3 C1',
        C2: 'wartość 3 C2',
        C3: 'wartość 3 C3',
        C4: 'wartość 3 C4',
        C5: 'wartość 3 C5',
        C6: 'wartość 3 C6',
    },
    ],
},
];

export default TABLE_DATA_MOCK;
