import React, { useState } from 'react';
import routerHistory from "../../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { InputWrapper, Wrapper } from "../../../../../components/ListToolBarComponents";
import BackButton from '../../../../../components/buttons/back-button/BackButton';

const ListToolBar = ({ handleSearch }) => {
  const [searchVal, setSearchVal] = useState('');
  return (
    <>
      <Wrapper style={{ justifyContent: 'space-between' }}>
        <BackButton
            text="Wróć"
            onClick={() => routerHistory.push('/service/templates')}
        />
        <InputWrapper>
          <RowWrapper>
            <SearchField
              value={searchVal}
              onChange={({ target }) => setSearchVal(target.value)}
            />
            <PrimaryButton
              style={{ marginLeft: 10 }}
              color="secondary"
              onClick={() => handleSearch({query: searchVal})}>
              Szukaj
            </PrimaryButton>
            <InputWrapper></InputWrapper>
            <InputWrapper></InputWrapper>
            <PrimaryButton
              onClick={() => routerHistory.push('/service/templates/reviews/new')}>
              Dodaj
            </PrimaryButton>
          </RowWrapper>
        </InputWrapper>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
