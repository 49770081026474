import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import OfferMergedGrouping from './merged/OfferMergedGrouping';
import { mergedOffer } from './offer-merged';


const AdvancedOfferMerged = forwardRef(({initialValues, setRootRows, offer, ...props}, ref) => {


  const [showGrouping, setShowGrouping] = useState(false);

  const onChange = (f, v) => {
    if( f == 'Podział na grupy') {
      setShowGrouping(v == 1);
    }
  }

  useEffect(() => {
    if( initialValues && initialValues['Podział na grupy'] == 1) {
      setShowGrouping(true);
    }
  }, [initialValues]);
  
  const groupsOnChange = (f, v) => {
    ref.current.setFormValue(f, v);
  }

  return (
    <>
      <RowWrapper fullWidth>
        <ColumnWrapper fullWidth>
          <DynamicForm initialValues={initialValues} fields={mergedOffer} ref={ref} small={true} avoidTabs={true} onChangeMiddleware={onChange} />
          {showGrouping && <OfferMergedGrouping offer={offer} formRef={ref} initialValues={initialValues} onChangeMiddleware={groupsOnChange} setRootRows={setRootRows} />}
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
});

AdvancedOfferMerged.propTypes = {
};

export default AdvancedOfferMerged;