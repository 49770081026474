import { action, createStore } from 'easy-peasy';

const authStore = {
    isAuthenticated: true,
    loggedInUser: null ,
    setIsAuthenticated: action((state, payload) => {
        state.isAuthenticated = payload;
    }),
    setLoggedInUser: action((state, payload) => {
        state.loggedInUser = payload;
    }),
};

export default authStore;