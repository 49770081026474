import { getCall } from "../components/indicator/Indicator";
import { API_TOKEN_VALIDATION_ROUTE } from '../constants/routes';

async function checkTokenValidation() {
  try {
    if (localStorage.getItem('loginToken')) {
      const r = await getCall(API_TOKEN_VALIDATION_ROUTE, undefined, false);
      return r.data.code === 401 ? false : true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default checkTokenValidation;
