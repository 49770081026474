import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Message from '@material-ui/icons/Message';
import React, {useMemo, useState} from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import routerHistory from "../../../../constants/settings/routerHistory";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import ActiveStatus from '../list/ActiveStatus';
import { Settings } from '@material-ui/icons';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
  position: sticky;
  padding-top: 10px;
  background: #fafafa;
  top: 0px;
  z-index: 10;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const DetailsToolBar = ({ status, name, companyName = null, ...props }) => {
  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToService = id =>
    routerHistory.push({
      pathname: `/service/repairs/edit/${id}`,
      state: { projectId: id },
    });

  const title = useMemo(() => {
    const projectName = id ? (name || '') : 'Nowy projekt';

    return `${companyName ? (companyName + ' - ') : ' '}${projectName}`
  }, [name, id, companyName]);


  return (
    <>
      <Wrapper style={{alignItems: 'center'}}>
        <RowWrapper style={{alignItems: 'center'}}>
          <BackButton
            text="Wróć do listy"
            onClick={() => routerHistory.push('/projects')}
          />
          <ColumnWrapper>
            <Typography>{title}</Typography>
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper style={{alignItems: 'center'}}>
          <ButtonWrapper>
            <Button
                disabled={!props.repairId}
                startIcon={<BuildIcon />}
                style={{ color: 'white' }}
                variant="contained"
                color="secondary"
                onClick={() => redirectToService(props.repairId)}>
              Serwis
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
                disabled={!id}
                startIcon={<ShoppingCartIcon />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push(`/projects/${id}/devices`)}
              color="secondary">
              Urządzenia
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
                disabled={!id}
                startIcon={<Settings />}
              style={{ color: 'white' }}
              variant="contained"
              onClick={() => routerHistory.push(`/projects/${id}/automation`)}
              color="secondary">
              Automatyka
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
                disabled={!id}
                onClick={props.toggleDrawer}
              startIcon={<Message />}
              style={{ color: 'white' }}
              variant="contained"
              color="secondary">
              Notatki
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
