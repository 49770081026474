import { TextField } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { API_NOTES_ROUTE } from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import { postCall, putCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import NoteItem from "../../../../components/notes/NoteItem";

const TextFieldsWrapper = styled(RowWrapper)`
  margin-top: ${(props) => (props.small ? '10px' : '25px')};
`;

const Notes = ({ data, fetchNotes, repairId, repairerId }) => {
  const [notesData, setNotesData] = useState([]);
  const [note, setNote] = useState('');

  useEffect(() => {
    setNotesData(data);
  }, [data]);

  const addNote = async () => {
    let data;
    if (repairId) {
      data = await postCall(API_NOTES_ROUTE, {
        body: note,
        repair_id: repairId,
      });
    } else if (repairerId) {
      data = await postCall(API_NOTES_ROUTE, {
        body: note,
        repairer_id: repairerId,
      });
    }
    if (data) {
      if (fetchNotes) setNotesData(await fetchNotes());
      toast.success('Dodano notatkę.');
    }
  };

  const editNote = async (id, note) => {
    const r = await putCall(`${API_NOTES_ROUTE}/${id}`, {
      body: note,
    })
  }

  const notes = useMemo(() => {
    if(notesData && notesData.notes && notesData.notes.length) {
      return notesData?.notes?.sort((a, b) => b?.id - a?.id)
    }
    return  []
  }, [notesData])

  return (
    <>
      <ExpansionContainer title="Notatki">
        <ColumnWrapper fullWidth>
          <ColumnWrapper fullWidth>
            {notesData && notesData.notes && notesData.notes.length
              ? notes.map((i, x) => (
                  <NoteItem
                    key={`${i.body}[${x}]`}
                    id={i.id}
                    user={i.creator}
                    note={i.body}
                    projectLabel={i.repairer?.name}
                    companyLabel={i.repair?.name}
                    date={i.created?.date}
                    editNote={editNote}
                  />
                ))
              : null}
          </ColumnWrapper>
          <TextFieldsWrapper fullWidth>
            <TextField
              value={note}
              onChange={({ target }) => setNote(target.value)}
              style={{ margin: 8 }}
              placeholder="Wpisz notatkę"
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </TextFieldsWrapper>
          <PrimaryButton
            disabled={!note}
            style={{ alignSelf: 'flex-end', marginTop: 20 }}
            onClick={addNote}>
            Dodaj notatkę
          </PrimaryButton>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default Notes;
