import React, {useEffect, useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { partition } from "lodash"
import { getCall } from "../../../../../../components/indicator/Indicator";
import { API_PRODUCTS_ROUTE, API_PRODUCT_CATEGORIES_ROUTE, API_PRODUCT_PRODUCER_ROUTE } from '../../../../../../constants/routes';
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import returnSpecificField from "../../../../../../components/dynamic-forms/returnSpecificField";
import truncate from "../../../../../../tools/truncate";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em',
    marginBottom: '1em'
  }
}));

const OfferDevicesDevicesZone = ({setRootRows, initRows = []}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedRepairsParts, setRelatedRepairsParts] = useState([])
  const [relatedLoading, setRelatedLoading] = useState(true);
  const [rows, setRows] = useState(
      initRows.map(row => ({
            ...row,
            uuid: row?.id,
            producer: row?.product?.product_producer?.id,
            category: row?.product?.product_category?.id,
            totalPrice: Number((Number(row?.amount) * Number(row?.product?.net_list_price)).toFixed(2)) || 0,
          })
      ));
  const query = useQuery();

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };

  const getUniqueCategories = (data) => {
    const categories = data.map(item => item.product_category)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setCategories(categories);
  }

  const getUniqueProducers = (data) => {
    const producers = data.map(item => item.product_producer)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setProducers(producers);
  }

  const selectedProducts = useMemo(() =>
          rows.map(({product} = {}) => product || {})
      ,[rows]);

  const availableProducts = useMemo(() =>
          products.filter(({id}) => !selectedProducts.find(({id: searchesId}) => searchesId === id))
      ,[products, selectedProducts]);

  const fetchDevices = async () => {

    try {
      const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
      if (data) {
        setProducts(data.items);
        getUniqueCategories(data.items);
        getUniqueProducers(data.items);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
    }

  };

  useEffect(() => {
    fetchDevices().then(() => setLoading(false))
  }, []);

  /**
   * Refetch related products whenever rows change (which means that selected products changed)
   */
  useEffect(() => {
    setRelatedLoading(true);
    fetchRelatedProducts().then(setRelatedLoading(false));
  }, [selectedProducts]);

  /**
   * Fetches related products [related products (group=1 || 2 || 3) & related repairs parts (group=4)]
   */
  const fetchRelatedProducts = async () => {
    // fetch related products
    // extract ids of already selected products (we about to fetch products related to these)
    const idsOfSelectedProducts = selectedProducts.reduce((acc, selected) => {
      if (selected?.id) {
        return [...acc, selected.id];
      }
      return acc;
    }, [])
    // THIS COULD BE OPTIMIZED (create additional backend enpoint that returns these related products based on the selected ones)
    const requests = idsOfSelectedProducts.map((productId) => getCall(`${API_PRODUCTS_ROUTE}/${productId}`));
    const data = await Promise.all(requests);
    const extractedRelated = data.reduce((acc, { data }) => {
      // extract related products
      return [...acc, ...data?.item?.products];
    }, []).filter(({id}) =>
      // filter extracted, so that products that are alerady selected are not in this array!
      ![...selectedProducts].find(({id: searchesId}) => searchesId === id)
    );
    // remove redundancy (some products might have overlapping dependencies!!!)
    const uniqueIds = [];
    const related = extractedRelated.filter(({id}) => {
      const redundant = uniqueIds.includes(id);
      if (!redundant) {
        uniqueIds.push(id);
        return true;
      }
      return false;
    });
    // split related products into two groups -> related products (group=1 || 2 || 3) and related repairs parts (group=4)
    const [relatedProds, relatedRepParts] = partition(related, (prod) => prod.group !== 4);
    setRelatedProducts(relatedProds);
    setRelatedRepairsParts(relatedRepParts);
  }

  useEffect(() => {
    setRootRows(
        rows.map(({id, amount, product}) => (({id, amount, product})))
            .filter(({id, amount, product}) => amount && product)
    )
  }, [rows]);

  const addNewRow = (newRow) => {
    setRows([...rows, Object.assign({
      id: null,
      producer: null,
      category: null,
      product: null,
      amount: 1,
      totalPrice: 0,
      uuid: Math.random().toString(36).substring(7)
    }, newRow)]);
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    if(i >= 0) {
      localRows[i][name] = v;
      if(['producer'].includes(name)) {
        localRows[i]['category'] = null;
      }
      if(['producer', 'category'].includes(name)) {
        localRows[i]['product'] = null;
        localRows[i].totalPrice = 0;
      }
      if(['amount', 'product'].includes(name)) {
        localRows[i].totalPrice = calculateRowTotalPrice(localRows[i]);
      }
    }
    setRows(localRows);
  };

  const calculateRowTotalPrice = ({product: {net_list_price = 0} = {}, amount}) => {
    if(net_list_price) {
      const price = Number(net_list_price) * Number(amount);
      return Number((price).toFixed(2)) || 0;
    }
    return 0;
  }

  const renderHeader = () => (
      <TableHead>
        <TableRow>
          <TableCell>Producent</TableCell>
          <TableCell>Kategoria</TableCell>
          <TableCell>Urządzenie</TableCell>
          <TableCell>Indeks</TableCell>
          <TableCell>Opis</TableCell>
          <TableCell>Ilość</TableCell>
          <TableCell>Cena katalogowa netto</TableCell>
          <TableCell>Wartość katalogowa netto</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
  );

  return (
      <>
        <ColumnWrapper fullWidth>
          <h3 style={{marginBottom: '30px'}}>Urządzenia</h3>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table">
            {renderHeader()}
            <TableBody>
              {!loading && rows.map(item =>
                  <OfferDevicesDevicesZoneRow
                      i={item}
                      index={item.uuid}
                      data={{
                          producers,
                          categories,
                          products: item?.product?.id ? [...availableProducts, item.product] : availableProducts
                      }}
                      key={item.uuid}
                      editRow={editRow}
                      removeRow={removeRow} 
                      preview={query.get("preview") ? true : false}/>)}
              <TableRow>
                <TableCell colSpan="100%">
                  {!loading && <Button className={classes.add} variant="contained" disabled={query.get("preview") ? true : false} onClick={(e) => addNewRow()}>
                    <AddIcon /> Dodaj kolejne urządzenie
                  </Button>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="7"/>
                <TableCell colSpan="1">
                  Suma: {rows.reduce((n, {totalPrice}) => n + totalPrice, 0)}
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell colSpan="100%">
                Powiązane produkty: ({relatedProducts.length})
              </TableCell>
            </TableRow>
            {relatedProducts.map((item, i) => <OfferRelatedDevicesDevicesZoneRow i={item} addRow={addNewRow} preview={query.get("preview") || false} adding={relatedLoading}/>)}
            <TableRow>
              <TableCell colSpan="100%">
                Powiązane części serwisowe: ({relatedRepairsParts.length})
                {relatedRepairsParts.map((item, i) => <OfferRelatedDevicesDevicesZoneRow i={item} addRow={addNewRow} preview={query.get("preview") || false} adding={relatedLoading}/>)}
              </TableCell>
            </TableRow>
            </TableBody>
          </Table>
        </ColumnWrapper>
      </>
  );
};

const OfferDevicesDevicesZoneRow = ({i, index, data, editRow, removeRow, preview, ...props}) => {
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  // this whole file refers to 'urządzenia' <=> group=1 <=> product_producer.type=1
  // meaning if we select item that is not from group 'urządzenia'
  // we should not be able to modify the record!
  const isAssociated = !i.product || i.product.product_producer.type === 1;
  const [producerValue, categoryValue, productValue] = 
    isAssociated 
    ? [i.producer, i.category, i.product?.id]
    : [i.product.product_producer.name, i.product.product_category.name , i.product.name];

  /**
   * Initial producers fetch
   */
  useEffect(() => {
    if (!i.fromRelated && isAssociated) {
      fetchProducers();
    }
  }, []);

  /**
   * Refetch categories whenever producer changes
   */
  useEffect(() => {
    if (i.producer && isAssociated) {
      fetchCategories();
    }
  }, [i.producer]);

  /**
   * Refetch products whenever category changes
   */
  useEffect(() => {
    if (i.category && isAssociated) {
      fetchProducts();
    }
  }, [i.category]);

  const fetchProducers = () => {
    // type=1 means groups='urządzenia'
    getCall(`${API_PRODUCT_PRODUCER_ROUTE}?type=1`)
        .then(({data: {items = []} = {}}) => setProducers(items));
  }

  const fetchCategories = () => {
    getCall(`${API_PRODUCT_CATEGORIES_ROUTE}?producer=${i.producer}`)
        .then(({data: {items = []} = {}}) => setCategories(items));
  }

  const fetchProducts = async () => {
    const { data } = await getCall(`${API_PRODUCTS_ROUTE}?product_category=${i.category}`);
    if (data) {
        setProducts(data.items);
    }
};

  const findProductAsObject = (searchedId) => {
    const res = products.find(({ id }) => id === searchedId);
    return res;
  }

  return (
      <TableRow key={i.uuid+'row'}>
        <TableCell>
          {returnSpecificField('dictionary', {data: producers, attrs: {readOnly: preview}}, 'producer', producerValue, (e, v) => {editRow(e, v, index);} )}
        </TableCell>
        <TableCell>
          {returnSpecificField('dictionary', {data: categories, attrs: {readOnly: preview}, disabled: !i.producer}, 'category', categoryValue, (e, v) => {editRow(e, v, index); } )}
        </TableCell>
        <TableCell>
          {returnSpecificField('dictionary', {data: products, attrs: {readOnly: preview}, disabled: !i.category}, 'product', productValue, (e, v) => {editRow(e, findProductAsObject(v), index);} )}
        </TableCell>
        <TableCell>
            {(i.product?.fields || []).find(({name})=> name === 'index')?.value || '-'}
        </TableCell>
        <TableCell title={i.product?.description || ''}>
          {truncate(i.product?.description || '-')}
        </TableCell>
        <TableCell>
          {returnSpecificField('text', {attrs: {readOnly: preview}}, 'amount', i.amount, (e, v) => {editRow(e, v, index)} )}
        </TableCell>
        <TableCell>
          {i.product?.net_list_price || 0}
        </TableCell>
        <TableCell>
          {i.totalPrice || 0}
        </TableCell>
        <TableCell>
          <Button disabled={preview ? true : false} variant="contained" onClick={(e) => removeRow(index)}>
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
  );
}

const OfferRelatedDevicesDevicesZoneRow = ({i, addRow, preview, adding, ...props}) => {
  return (
      <TableRow key={i.id+'row'}>
        <TableCell>
          {i.product_producer?.name || '-' }
        </TableCell>
        <TableCell>
          {i.product_category?.name || '-' }
        </TableCell>
        <TableCell>
          {i.name || '-' }
        </TableCell>
        <TableCell>
            {(i?.fields || []).find(({name})=> name === 'index')?.value || '-'}
        </TableCell>
        <TableCell title={i?.description || ''}>
          {truncate(i?.description || '-')}
        </TableCell>
        <TableCell>
          -
        </TableCell>
        <TableCell>
          {i?.net_list_price || 0}
        </TableCell>
        <TableCell>
          -
        </TableCell>
        <TableCell>
          <Button disabled={preview || adding || false} variant="contained" onClick={() => addRow({
            product: i,
            producer: i.product_producer?.id,
            category: i.product_category?.id,
            totalPrice: i.net_list_price
          })}>
            <AddShoppingCartIcon />
          </Button>
        </TableCell>
      </TableRow>
  );
}

export default OfferDevicesDevicesZone;
