import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import AddEditPopup from './components/add-edit/category/AddEditPopup';
import ListToolBar from './components/list/categories/ListToolBar';
import { API_PRODUCT_CATEGORIES_ROUTE } from '../../constants/routes'; 

const ProductsCategoryScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const state = useStoreState((state) => state.auth?.loggedInUser);

  const popupRef = useRef();

  const fetchCategories = async (url = API_PRODUCT_CATEGORIES_ROUTE) => {
    const { data } = await getCall(url);
    if (data) {
      setCount(data?.total);
      setRows({
        initialState: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          producer: i?.producer || '',
          name: (<strong>{i?.name}</strong>),
        })),
        state: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          producer: i?.producer || '',
          name: (<strong>{i?.name}</strong>),
        })),
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(
        `${API_PRODUCT_CATEGORIES_ROUTE}?query=${v}&order_key=name`,
      );
      if (data) {
        setRows({
          ...rows,
          state: data?.items?.map((i) => ({
            identity: i?.id,
            id: i?.id,
            producer: i?.producer || '',
            name: (<strong>{i?.name}</strong>),
          })),
        });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleDelete = async (numSelected) => {
    const isAdmin = state?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (isAdmin) {
      const { data } = await deleteCall(API_PRODUCT_CATEGORIES_ROUTE, {
        ids: numSelected.join(),
      });
      const [status] = data;
      if (status?.success) {
        toast.success('Pomyślnie usunięto wybrane kategorie.');
      } else {
        toast.error('Wystąpił błąd podczas usuwania wybranych kategorii.');
      }
    } else {
      toast.warn(
        'Wybrany użytkownik nie posiada uprawnień do usuwania kategorii.',
      );
    }
  };

  const handleAddCategory = () => {
    setCategoryId(null);
    popupRef.current.open();
  };

  const handleEditCategory = (id) => {
    setCategoryId(id);
    popupRef.current.open();
  };

  return (
    <>
      <ListToolBar handleSearch={handleSearch} onAdd={handleAddCategory} />
      <BasicTable
        onDelete={handleDelete}
        useDialogOnDelete
        headCells={[
          { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
          { id: 'producer', numeric: false, disablePadding: false, label: 'producent' },
          { id: 'name', numeric: false, disablePadding: false, label: 'kategoria' },
        ]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchCategories(`${API_PRODUCT_CATEGORIES_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) => {
          handleEditCategory(id);
        }}
      />
      <AddEditPopup
        categoryId={categoryId}
        ref={popupRef}
        onSave={async () => await fetchCategories(API_PRODUCT_CATEGORIES_ROUTE)}
      />
    </>
  );
};

export default ProductsCategoryScreen;
