import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryButton = ({
  onClick,
  children,
  icon,
  size,
  color,
  style,
  disabled,
  type,
}) => (
  <Button
    startIcon={icon}
    onClick={onClick}
    variant="contained"
    style={{ ...style, color: 'white' }}
    size={size}
    type={type}
    disabled={disabled}
    color={color}>
    {children}
  </Button>
);

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]),
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  children: <></>,
  icon: null,
  size: 'medium',
  color: 'primary',
  style: {},
  disabled: false,
  type: 'button',
};

export default PrimaryButton;
