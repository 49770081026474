export const formToRequest = (formValues = {}) =>
    Object.entries(formValues).map(([name,value]) => ({name, value}));

export const responseToForm = (fields = []) =>
    fields.reduce((obj, {name, value}) => Object.assign(obj, {[name]: value }), {});

export const turnBooleansToArray = (accepted = [], fields = []) => 
    {
        const arr = [];

        const res = fields
        .filter((item) => accepted.includes(item.name) && item.value)
        .map(({name, value}) => {
            arr.push(name);
        });

        return arr;
    
    
    }
        
export const convertArrayToObject = (array, key) => {
    if(!Array.isArray(array)) {
        return array;
    }
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
        ...obj,
        [item[key]]: item,
        };
    }, initialValue);
};