import {Paper, TextField} from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
import React, {useEffect, useMemo} from 'react';
import {TileLayer, Marker, Popup, MapContainer} from 'react-leaflet'
import PrimaryButton from "../../components/buttons/PrimaryButton";
import Select from '../../components/dynamic-forms/form-components/Select';
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import "../../assets/styles/map.css";
import L, { divIcon } from 'leaflet'
import {getCall} from "../../components/indicator/Indicator";
import {
  API_COMPANIES_ROUTE, API_PRODUCTS_ROUTE,
  API_PROJECTS_ROUTE,
  API_REPAIRERS_ROUTE,
  API_REPAIRS_ROUTE,
  API_USERS_ROUTE
} from "../../constants/routes";
import serializeQuery from "../../tools/serializeQuery";
import MarkerClusterGroup from "react-leaflet-markercluster/src/react-leaflet-markercluster";
import commonValues from "../../tools/commonValues";
import RoomIcon from '@material-ui/icons/Room';
import {renderToStaticMarkup} from "react-dom/server";

delete L.Icon.Default.prototype._getIconUrl;


// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// });

const config = {
  count: 0,
  maxZoom: 18,
  maxBounds: [
    [-90, -180],
    [90, 180],
  ],
  bounds: [
    {
      lat: 49.29899,
      lng: 14.24712,
    },
    {
      lat: 49.29899,
      lng: 23.89251,
    },
    {
      lat: 54.79086,
      lng: 23.89251,
    },
    {
      lat: 54.79086,
      lng: 14.24712,
    },
  ],
};


const marker = ({name, geometry, address, link, color = 'green'}) => ({color, name, address: address || 'Link Google Map', link, geometry: [geometry?.lat, geometry?.lng]});

const MapScreen = () => {

  const [open, setOpen] = React.useState(true);
  const [showOption, setShowOption] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [devices, setDevices] = React.useState([]);

  const showOptions = [
    { name: 'Firmy', id: 'companies' },
    { name: 'Projekty', id: 'projects' },
    { name: 'Urządzenia', id: 'devices' },
    { name: 'Serwis', id: 'service' },
    { name: 'Marketing', id: 'marketing' },
  ];

  const showOptionsCompanies = [
    { id: 'status_arch' , name: 'Architekt' },
    { id: 'status_dyst' , name: 'Dystrybutor' },
    { id: 'status_inv' , name: 'Inwestor' },
    { id: 'status_serv' , name: 'Serwisant' },
    { id: 'status_aud' , name: 'Audytor' },
    { id: 'status_mar' , name: 'Hurtownia' },
    { id: 'status_pro' , name: 'Projektant' },
    { id: 'status_do' , name: 'Wykonawca' },
    { id: 'status_hbh' , name: 'HBH' },
    { id: 'status_oth' , name: 'Inni' },
  ];

  const showOptionsProjects = [
    'temat', 'audyt', 'projekt', 'inwestycja', 'zamówienie', 'dostarczony', 'uruchomiony', 'anulowany', 'zamrożony'
  ].map((item) => ({name: item, id: item}));

  const showOptionsMarketing = [
    'zdjęcia referencyjne', 'wywiad referencyjny', 'materiały marketingowe z inwestycją', 'zgoda na wykorzystanie jako referencja'
  ].map((item) => ({name: item, id: item}));

  const showOptionsService = [
    'instalacje (raport)', 'serwisanci', 'przeglądy'
  ].map((item) => ({name: item, id: item}));

  const emptyList = [
    'wszystkie'
  ].map((item) => ({name: item, id: item}));

  const chooseOptionsList = useMemo(() => {
    switch(showOption) {
      case 'companies': return showOptionsCompanies;
      case 'projects': return showOptionsProjects;
      case 'devices': return showOptionsProjects;
      case 'service': return showOptionsService;
      case 'marketing': return showOptionsMarketing;
      default: return [];
    }
  }, [showOption, showOptionsCompanies, showOptionsProjects, showOptionsProjects, showOptionsService, showOptionsMarketing]);

  useEffect(() => {
    const fetchUsers = async () => {
      const r = await getCall(API_USERS_ROUTE);
      if (r?.data?.items) {
        setUsers(
            r?.data?.items?.map((el) => ({
              id: el?.id,
              name: el?.email,
            })),
        );
      }
    };

    const fetchDevices = async () => {
        const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
        if (data) {
          setDevices(data?.items?.map(item => ({...item, name: `${item?.name} - ${item?.product_category?.name} - ${item?.product_producer?.name}`})));
        }
    };
    fetchUsers();
    fetchDevices();
    }, []);


  const handleChangeSpecification = value => {
    setFilters(filters => ({...filters, specification: value}))
  };

  const handleSearch = async () => {

    switch(showOption) {
      case 'companies': {

        const specificationKey = `queryFields[${filters?.specification}]`;
        const voivodeshipsKey = `queryFields[wojewodztwo]`;
        const cityKey = `queryFields[miasto]`;

        const queryPath = serializeQuery({
          results_on_page: -1,
          ...(filters?.specification ? {[specificationKey]: true} : {}),
          ...(filters?.voivodeships ? {[voivodeshipsKey]: filters?.voivodeships} : {}),
          ...(filters?.city ? {[cityKey]: filters?.city} : {}),
          ...(filters?.projectKr ? {projectKr: filters?.projectKr} : {})
        });

        const { data } = await getCall(`${API_COMPANIES_ROUTE}?${queryPath}`);
        if (data) {
          const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
            ...item,
            address: [
                item?.fields?.find(field => field?.name === 'miasto')?.value,
                item?.fields?.find(field => field?.name === 'adres')?.value
            ].filter(Boolean).join(' '),
            link: `/companies/edit/${item.id}`,
            color: (ranking => {
              if(!ranking || ranking > 10) {
                return 'blue'
              }
              if(ranking >= 7 && ranking <= 10) {
                return 'green'
              }
              if(ranking >= 4 && ranking <= 6) {
                return 'orange'
              }

              return 'red'
            })(item?.fields?.find(field => field?.name === 'ranking')?.value)
          }));

          setMarkers(() => newMarkers)
        }
        break;
      }
      case 'projects': {

        const specificationKey = `statuses[]`;
        const voivodeshipsKey = `voivodeship[]`;
        const cityKey = `queryFields[city_proj]`;

        const queryPath = serializeQuery({
          results_on_page: -1,
          ...(filters?.specification ? {[specificationKey]: filters?.specification} : {}),
          ...(filters?.voivodeships ? {[voivodeshipsKey]: filters?.voivodeships} : {}),
          ...(filters?.city ? {[cityKey]: filters?.city} : {}),
          ...(filters?.projectKr ? {'projectKr[]': filters?.projectKr} : {})
        });

        const { data } = await getCall(`${API_PROJECTS_ROUTE}?${queryPath}`);
        if (data) {
          const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
            ...item,
            address: [
              item?.address,
              item?.city
            ].filter(Boolean).join(' '),
            link: `/projects/edit/${item.id}`
          }));

          setMarkers(() => newMarkers)
        }
        break;
      }
      case 'devices': {

        const specificationKey = `statuses[]`;
        const voivodeshipsKey = `voivodeship[]`;
        const cityKey = `queryFields[city_proj]`;

        const queryPath = serializeQuery({
          results_on_page: -1,
          ...(filters?.specification ? {[specificationKey]: filters?.specification} : {}),
          ...(filters?.voivodeships ? {[voivodeshipsKey]: filters?.voivodeships} : {}),
          ...(filters?.city ? {[cityKey]: filters?.city} : {}),
          ...(filters?.projectKr ? {'projectKr[]': filters?.projectKr} : {}),
          ...(filters?.device ? {device: filters?.device} : {}),
        });

        const { data } = await getCall(`${API_PROJECTS_ROUTE}?${queryPath}`);
        if (data) {
          const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
            ...item,
            address: [
              item?.address,
              item?.city
            ].filter(Boolean).join(' '),
            link: `/projects/edit/${item.id}`
          }));

          setMarkers(() => newMarkers)
        }
        break;
      }
      case 'service': {

        switch (filters.specification) {
          case "serwisanci": {
            const queryPath = serializeQuery({
              results_on_page: -1,
              ...(filters?.voivodeships ? {voivodeships: filters?.voivodeships} : {}),
              ...(filters?.city ? {city: filters?.city} : {}),
              ...(filters?.projectKr ? {projectKr: filters?.projectKr} : {})
            });

            const { data } = await getCall(`${API_REPAIRERS_ROUTE}?${queryPath}`);
            if (data) {
              const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
                ...item,
                address: [
                  item?.fields?.find(field => field?.name === 'serwisant_adres')?.value,
                ].filter(Boolean).join(' '),
                link: `/service/repairers/edit/${item.id}`
              }));

              setMarkers(() => newMarkers)
            }
            break;
          }
          case "przeglądy": {
            const queryPath = serializeQuery({
              results_on_page: -1,
              ...(filters?.voivodeships ? {voivodeships: filters?.voivodeships} : {}),
              ...(filters?.city ? {city: filters?.city} : {}),
              ...(filters?.projectKr ? {projectKr: filters?.projectKr} : {})
            });

            const { data } = await getCall(`${API_REPAIRS_ROUTE}?${queryPath}`);
            if (data) {
              const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
                ...item,
                name: item?.project?.name,
                address: [
                  item?.fields?.find(field => field?.name === 'serwis_ul')?.value,
                  item?.fields?.find(field => field?.name === 'serwis_city')?.value,
                ].filter(Boolean).join(' '),
                link: `/service/repairs/edit/${item.id}`,
                color: (months => {
                  if(months >= 10 && months <= 12) {
                    return 'orange'
                  }
                  else if(months > 12) {
                    return 'red'
                  }
                  else {
                    return 'green'
                  }
                })(Number(item?.numberMonthsFromLastInspection))
              }));

              setMarkers(() => newMarkers)
            }
            break;
          }
          default:
            return
        }
        break;
      }
      case 'marketing': {

        const voivodeshipsKey = `voivodeship[]`;
        const cityKey = `queryFields[city_proj]`;

        const queryPath = serializeQuery({
          results_on_page: -1,
          ...(filters?.specification ? {marketing: filters?.specification} : {}),
          ...(filters?.voivodeships ? {[voivodeshipsKey]: filters?.voivodeships} : {}),
          ...(filters?.city ? {[cityKey]: filters?.city} : {}),
          ...(filters?.projectKr ? {'projectKr[]': filters?.projectKr} : {}),
          ...(filters?.device ? {device: filters?.device} : {}),
        });

        const { data } = await getCall(`${API_PROJECTS_ROUTE}?${queryPath}`);
        if (data) {
          const newMarkers = data?.items?.filter(item => !!item?.geometry).map(item => marker({
            ...item,
            address: [
              item?.address,
              item?.city
            ].filter(Boolean).join(' '),
            link: `/projects/edit/${item.id}`
          }));

          setMarkers(() => newMarkers)
        }
        break;
      }
      default: return;
    }
  };

  const selectedSpecification = useMemo(() =>
      chooseOptionsList?.find(option => option?.id === filters?.specification)?.name || null
  , [filters, showOption, chooseOptionsList]);


  return (
    <RowWrapper style={{ alignItems: 'flex-start' }}>
      <ColumnWrapper width={open ? '75%' : '100%'}>
          <MapContainer
              className="map markercluster-map"
              bounds={config.bounds}
              maxZoom={config.maxZoom}
              maxBounds={config.maxBounds}
          >
            <MarkerClusterGroup>
            {markers.map(marker =>
                <Marker position={marker.geometry} icon={divIcon({
                  html: renderToStaticMarkup(<RoomIcon fontSize="large" style={{color: marker.color}} />)
                })}>
                <Popup>
                  <a style={{display: 'block'}} target="_blank" href={marker?.link || '#'}>{marker?.name}</a>
                  <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${marker.geometry.join(',')}`} style={{display: 'block', margin: '5px 0'}}>{marker?.address}</a>
                </Popup>
                </Marker>
            )}
            </MarkerClusterGroup>
          <TileLayer
            noWrap={true}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <PrimaryButton
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              zIndex: '10000'
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? 'Ukryj panel' : 'Pokaż panel'}
          </PrimaryButton>
          </MapContainer>
      </ColumnWrapper>
        {open && <ColumnWrapper width="25%">
          <Paper style={{padding: '10px', height: '100%'}}>
            <RowWrapper>
              <Select
                data={showOptions}
                variant="outlined"
                value={showOptions?.find(option => option.id === showOption)?.name || ''}
                onSelect={(c) => {
                  setShowOption(c || '');
                  setFilters(() => {});
                  if(c === 'service') {
                    setFilters(() => ({specification: 'serwisanci'}))
                  } else if(c === 'przeglądy') {
                    setFilters(() => ({specification: 'przeglądy'}))
                  }
                }}
                name="showOption"
                placeholder="Wyświetlaj"
                style={{ width: '100%' }}
              />
            </RowWrapper>
            <RowWrapper>
              <Select
                data={chooseOptionsList}
                value={selectedSpecification}
                variant="outlined"
                name="details"
                placeholder="Specyfikacja"
                style={{ width: '100%' }}
                onSelect={handleChangeSpecification}
              />
            </RowWrapper>
            {
              showOption === 'devices'
              && <RowWrapper>
                <Select
                    data={[{id: null, name: 'Wszystkie'}, ...devices]}
                    value={filters?.device ? (devices?.find(device => device?.id === filters?.device)?.name || null) : null}
                    variant="outlined"
                    name="details"
                    placeholder="Urządzenie"
                    style={{ width: '100%' }}
                    onSelect={device => setFilters(filters => ({...filters, device}))}
                />
              </RowWrapper>
            }
            <RowWrapper>
              <Select
                data={[{id: null, name: 'Wszyscy'}, ...users]}
                variant="outlined"
                name="kr"
                placeholder="KR"
                value={users?.find(user => user?.id === filters?.projectKr)?.name || null}
                style={{ width: '100%' }}
                onSelect={projectKr => setFilters(filters => ({...filters, projectKr}))}
              />
            </RowWrapper>
            <RowWrapper>
              <Select
                variant="outlined"
                name="region"
                placeholder="Województwo"
                data={[{id: null, name: 'Wszystkie'}, ...commonValues.voivodeships]}
                style={{ width: '100%' }}
                onSelect={(voivodeships) => setFilters(filters => ({...filters, voivodeships}))}
              />
            </RowWrapper>
            <RowWrapper>
              <TextField
                data={emptyList}
                variant="outlined"
                name="city"
                label="Miasto"
                style={{ width: '100%', padding: '0 5px' }}
                onChange={({target: {value: city}}) => setFilters(filters => ({...filters, city}))}
              />
            </RowWrapper>
            <RowWrapper>
              <PrimaryButton style={{width: '100%', margin: '5px', marginTop: '20px'}} onClick={() => handleSearch()}>
                Wyszukaj
              </PrimaryButton>
            </RowWrapper>
          </Paper>
        </ColumnWrapper>}
    </RowWrapper>

  );
};
export default MapScreen;
