import { Button, TextField } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  API_KR_USERS_ROUTE,
  API_PROJECTS_ROUTE,
} from '../../../../constants/routes';
import KR_TYPES from '../../../../constants/types/krTypes';
import Select from '../../../../components/dynamic-forms/form-components/Select';
import { getCall } from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";

const ProjectKrParticipation = forwardRef((props, ref) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await getCall(API_KR_USERS_ROUTE);
      if (data?.items) {
        setUsers(
          data?.items?.map((el) => ({
            id: el?.id,
            name: el?.email,
          })),
        );
      }
    };
    fetchUsers();
  }, []);

  const formik = useFormik({
    initialValues: {
      participations: [
        {
          userEntity: '',
          id: '',
          type: '',
          percent: '0%',
        },
      ],
    },
  });

  const { projectId } = props;

  useImperativeHandle(ref, () => ({
    getFormValues() {
      return formik.values;
    },
  }));

  useEffect(() => {
    const fetchParticipationsForProject = async () => {
      if (props.krs?.length) {
        formik.setFieldValue(
          'participations',
          props.krs.map((v) => ({
            id: v?.id,
            percent: `${v?.percent}%`,
            userEntity: v?.userEntity,
            type: v?.type,
          })),
        );
      }
    };
    fetchParticipationsForProject();
    // eslint-disable-next-line
  }, [props.krs]);

  const handleTypeChange = (index, type) => {
    const getpercentFromType = (t) => {
      switch (t) {
        case '1':
          return '20%';
        case '2':
          return '30%';
        case '3':
          return '50%';
        default:
          return '0%';
      }
    };
    formik.setFieldValue(
      `participations.${index}.percent`,
      getpercentFromType(type),
    );
    formik.setFieldValue(`participations.${index}.type`, type);
  };

  return (
    <RowWrapper style={{ overflowX: 'auto' }}>
      <FormikProvider value={formik}>
        <FieldArray
          name="participations"
          render={(aH) => (
            <ColumnWrapper fullWidth>
              {formik.values.participations &&
              formik.values.participations.length > 0
                ? formik.values.participations.map((v, index) => (
                    <Fragment key={index}>
                      <RowWrapper
                        style={{
                          marginTop: 10,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-end',
                        }}
                        fullWidth>
                        <ColumnWrapper
                          fullWidth
                          style={{ maxWidth: 200, marginRight: 20 }}>
                          <Select
                            label="Użytkownicy"
                            data={users}
                            value={
                              formik.values.participations[index].userEntity
                            }
                            name={`participations.${index}.userEntity`}
                            onSelect={(v) =>
                              formik.setFieldValue(
                                `participations.${index}.userEntity`,
                                v,
                              )
                            }
                          />
                        </ColumnWrapper>
                        <ColumnWrapper
                          fullWidth
                          style={{ maxWidth: 200, marginRight: 20 }}>
                          <Select
                            label="Typy"
                            data={KR_TYPES}
                            value={formik.values.participations[index].type}
                            name={`participations.${index}.type`}
                            onSelect={(v) => handleTypeChange(index, v)}
                          />
                        </ColumnWrapper>
                        <TextField
                          variant="outlined"
                          placeholder="Udział"
                          label="Udział"
                          InputLabelProps={{ shrink: true }}
                          name={`participations.${index}.percent`}
                          value={formik.values.participations[index].percent}
                          onChange={formik.handleChange}
                          style={{ marginBottom: 10, minWidth: 50 }}
                        />
                        <Button
                          style={{ marginRight: 5, lineHeight: '50px' }}
                          onClick={() => aH.remove(index)}>
                          - Usuń
                        </Button>
                      </RowWrapper>
                    </Fragment>
                  ))
                : null}
              <Button
                variant="contained"
                style={{ marginTop: 50, alignSelf: 'flex-start' }}
                onClick={() =>
                  aH.push({
                    userEntity: '',
                    type: '',
                    percent: '0%',
                  })
                }>
                + Dodaj Następny
              </Button>
            </ColumnWrapper>
          )}
        />
      </FormikProvider>
    </RowWrapper>
  );
});

ProjectKrParticipation.propTypes = {};

export default ProjectKrParticipation;
