import {
    CircularProgress,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select as MaterialSelect,
    TextField,
    Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { API_ENTITY_ROUTE } from '../../../constants/routes';
import { getCall } from '../../indicator/Indicator';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import serializeQuery from '../../../tools/serializeQuery';
import RowWrapper from '../../wrappers/RowWrapper';
import UserImage from '../../user-image/UserImage';
import RepairersDetailsScreen from '../../../screens/service/RepairersDetailsScreen';
import { useStoreState } from 'easy-peasy';

const EntityField = ({
    disabled,
    entityClass,
    onSelect,
    name,
    value,
    label,
    addLabel,
    style = {},
    wrapperStyle = {},
    error,
    helperText,
    values,
    multiple = false,
    freeSolo = false,
    additionalFilters = {},
    preview,
    ...props
}) => {
    const username = useStoreState((state) => state.auth?.loggedInUser?.username);
    const [selected, setSelected] = useState(() => multiple ? [] : '');
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [isPreview, setIsPreview] = React.useState(false);
    const loading = open && options.length === 0;

    const params = useParams();
  
    useEffect(() => {
        let newValue = value
        if(value && entityClass === 'user' && !value.hasOwnProperty('name')){
            newValue = {...value, name: value.email}
        }
        setSelected(() => newValue);
    }, [value]);

    useEffect(()=> {
        if (entityClass === 'user'){
            (async () => {
                const response = await fetchEntityClass();
                const user = response.find(user => user.name===username)
                handleSelect(user)
                setSelected(() => user);
                }
            )();
        }
    },[])

    const handleSelect = (newValue) => {
        setSelected(newValue);
        onSelect(newValue);
    };

    React.useEffect(() => {
        let active = true;
    
        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await fetchEntityClass();

            if (active) {
                setOptions(response);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

        React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        let active = true;

        if(open) {

            (async () => {
                const response = await fetchEntityClass();
    
                if (active) {
                    setOptions(response);
                }
            })();

        }

        return () => {
            active = false;
        };
    }, [inputValue]);

    const fetchEntityClass = async () => {

        const query = additionalFilters || {};

        if(inputValue) {
            query['query'] = inputValue;
        }

        if(props?.isInMultiple && props?.multipleIndex >= 0 && props?.forceParamId) {
            const vals = JSON.parse(values[props?.isInMultiple] || '[]') || [];
            if(vals && vals.length) {
                query['paramid'] = values[props?.isInMultiple] ? (vals[props?.multipleIndex][props?.forceParamId] ? vals[props?.multipleIndex][props?.forceParamId]?.id || vals[props?.multipleIndex][props?.forceParamId] : params.id) : params.id;

            }
        } else if(params?.id || props?.forceParamId) {
            query['paramid'] = props?.forceParamId 
            ? (values[props?.forceParamId] ? values[props?.forceParamId]?.id || values[props?.forceParamId] : props?.forceParamId) 
            : params.id;
        }

        const queryPath = query ? serializeQuery(query) : '';

        const { data } = await getCall(`${API_ENTITY_ROUTE}/${entityClass}?${queryPath}`);
        if (data) {
            return data;
        } else {
            return []
        }

    }

    return (
        <ColumnWrapper fullWidth style={{...wrapperStyle}}>
            {addLabel && <InputLabel style={{ margin: '10px 0' }} shrink htmlFor={name}>
                {addLabel}
            </InputLabel>}
            <Autocomplete
                style={{ minWidth: 150 }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                freeSolo={freeSolo}
                getOptionSelected={(option, value) => option?.name === value?.name}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(option) => (
                    <RowWrapper>
                        {entityClass == 'user' && <UserImage size={20} src={option?.image} name={option?.name} />}
                        {option.name}
                    </RowWrapper>
                )}
                options={options}
                multiple={multiple}
                onChange={(event, newValue) => handleSelect(newValue)}
                loadingText={'Wczytuję...'}
                loading={loading}
                disabled={disabled}
                value={selected || (multiple ? [] : '')}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label={label || ''}
                    variant="outlined"
                    style={{ backgroundColor: '#f2f2f3', width: '100%', fontSize: '10px', ...style }}
                    disabled={disabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {(preview && selected)  && (
                                <Tooltip title="Podgląd">
                                    <IconButton
                                        onClick={() => setIsPreview(true)}
                                        style={{ padding: '0' }}
                                    >
                                        <VisibilityOutlinedIcon style={{ color: 'gray', cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
            />
            <Modal
                open={isPreview}
                onClose={() => setIsPreview(false)}
                style={{ overflow: 'auto' }}
            >
                <RepairersDetailsScreen 
                    preview={preview} 
                    repairerId={selected?.id} 
                    onClose={() => setIsPreview(false)} 
                />
            </Modal>
            {/*<MaterialSelect
                multiple={multiple}
                error={error}
                name={name}
                label={label}
                disabled={disabled}
                style={{ backgroundColor: '#f2f2f3', width: '100%', ...style }}
                variant="outlined"
                onChange={(event) => handleSelect(event)}
                value={selected || ''}>
                {data.map((dataItem) => (
                <MenuItem key={dataItem.id} value={dataItem.id}>
                    {dataItem.name}
                </MenuItem>
                ))}
                </MaterialSelect>*/}
        </ColumnWrapper>
    );
};

export default EntityField;
