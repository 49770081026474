import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";

const serviceActionReview = [
    {
      type: 'multipleFields',
      name: 'Informację ogólne',
      value: [],
      options: { fields: [
          { name: 'Informację ogólne', type: 'header', fullWidth: false, value: '', options: {attrs: {style: {marginBottom: 25}}} },

          { name: 'Data przeglądu', label: 'Data i godzina', type: 'date', fullWidth: false, value: '', },
          { name: 'Klient/inwestor', label: 'Klient/inwestor', type: 'text', fullWidth: true, value: '', },
          {
            name: "value_project",
            type: "entity",
            label: "Nazwa inwestycji",
            options: { width: 4, entityClass: 'project', freeSolo:  true }
        },
          { name: 'Ulica', label: 'Ulica', type: 'text', fullWidth: false, value: '', },
          { name: 'Kod', label: 'Kod', type: 'text', fullWidth: false, value: '', },
          { name: 'Miasto', label: 'Miasto', type: 'text', fullWidth: false, value: '', },
          { name: 'Przegląd po', label: 'Przegląd po', type: 'input', value: '', options: {attrs: {width: 4, style: {marginBottom: 8, marginTop: 12}, endAdornment: <InputAdornment position="end">Roku użytkowania</InputAdornment>}} },
          {
              name: "value_repairer",
              type: "entity",
              label: "Serwisant",
              value: null,
              options: { width: 4, entityClass: 'repairer' }
          },
              { name: 'Gwarancja', label: 'Gwarancja', type: 'checkbox', value: '', },
          ]
      },
    },
  ];
const serviceActionReviewCd = [
    {
      type: 'multipleFields',
      name: 'Instalacja Hydrauliczna',
      value: [],
      options: { fields: [
        { name: 'Instalacja hydrauliczna', type: 'header', fullWidth: true, value: '', },

        { name: 'Sprawdzenie filtrów czynnika grzewczego', label: 'Sprawdzenie filtrów czynnika grzewczego', type: 'text', fullWidth: true, value: '', },
        { name: 'Odpowietrzenie instalacji', label: 'Odpowietrzenie instalacji', type: 'text', fullWidth: true, value: '', },
        { name: 'Przepływ medium po stronie wtórnej instalacji', label: 'Przepływ medium po stronie wtórnej instalacji', type: 'text', fullWidth: true, value: '', },



        { name: 'Nastawy - termiczne', type: 'header', fullWidth: true, value: '', },



        { name: 'WŁACZANIE/WYŁĄCZANIE W ZALEŻNOŚCI OD TEMPERATURY WODY', label: 'WŁACZANIE/WYŁĄCZANIE W ZALEŻNOŚCI OD TEMPERATURY WODY', type: 'dictionary', options: {
          data: [
            { id: 'zasilanie', name: 'Zasilanie'},
            { id: 'powrot', name: 'Powrót'},
          ]
        }, value: ''},
        { name: 'Punkt nastawczy *C 1', label: 'Punkt nastawczy [*C]', type: 'text', value: '', },
        { name: 'Różnica (delta T) *C 1', label: 'Różnica (delta T) [*C]', type: 'text', value: '', },
        { name: 'Dostępne', label: '', type: 'none', value: '', },


        { name: 'Puste', type: 'none',  value: '', },

        { name: 'USTAWIENIA CWU', label: 'USTAWIENIA CWU', type: 'dictionary', options: {
          data: [
            { id: 'zasilanie', name: 'Zasilanie'},
            { id: 'powrot', name: 'Powrót'},
          ]
        }, value: ''},
        { name: 'Punkt nastawczy *C 2', label: 'Punkt nastawczy [*C]', type: 'text', value: '', },
        { name: 'Różnica (delta T) *C 2', label: 'Różnica (delta T) [*C]', type: 'text', value: '', },



        { name: 'Puste', type: 'none',  value: '', },
        { name: 'Puste', type: 'none',  value: '', },

        { name: 'USTAWIENIA PARAMETRÓW CHŁODZENIA', label: 'USTAWIENIA PARAMETRÓW CHŁODZENIA', type: 'dictionary', options: {
          data: [
            { id: 'zasilanie', name: 'Zasilanie'},
            { id: 'powrot', name: 'Powrót'},
          ]
        }, value: ''},
        { name: 'Punkt nastawczy *C 3', label: 'Punkt nastawczy [*C]', type: 'text', value: '', },
        { name: 'Różnica (delta T) *C 3', label: 'Różnica (delta T) [*C]', type: 'text', value: '', },
              { name: 'Puste', type: 'none',  value: '', },
              { name: 'Puste', type: 'none',  value: '', },

              { name: 'dynamicThermalSettings', type: 'dynamicThermalSettings',   "label": "", "value": [], fullWidth: true,
                  "options": {
                      "id": 3,
                      "data": [],
                      "fields": [
                          { name: 'USTAWIENIA CWU', label: 'USTAWIENIA CWU', type: 'dictionary', options: {
                                  data: [
                                      { id: 'zasilanie', name: 'Zasilanie'},
                                      { id: 'powrot', name: 'Powrót'},
                                  ]
                              }, value: ''},
                          { name: 'Punkt nastawczy *C', label: 'Punkt nastawczy [*C]', type: 'text', value: ''},
                          { name: 'Różnica (delta T) *C', label: 'Różnica (delta T) [*C]', type: 'text', value: '', },
                      ],
                  }},


              { name: 'Puste', type: 'none',  value: '', },
        { name: 'Puste', type: 'none',  value: '', },


        { name: 'Wyniki', type: 'header', fullWidth: true, value: '', },
        { name: 'INSTALACJA ZOSTAŁA SPRAWDZONA I JEST W PEŁNI SPRAWNA', label: 'INSTALACJA ZOSTAŁA SPRAWDZONA I JEST W PEŁNI SPRAWNA', type: 'radio', fullWidth: true},

        ]
      },
    },
    {
      type: 'multipleFields',
      name: 'Przegląd cd',
      value: [],
      options: { fields: [

        { name: 'Uwagi', type: 'header', fullWidth: true, value: '', },
        { label: 'Notatki/Uwagi', name: 'Notatki', type: 'multilineInput', fullWidth: true, value: '', },
        ]
      },
    },
  ];


  export { serviceActionReview, serviceActionReviewCd };