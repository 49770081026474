import React from 'react';
import routerHistory from "../../constants/settings/routerHistory";
import UserDetails from "./components/user-details/UserDetails";
import BackButton from "../../components/buttons/back-button/BackButton";

const AdministrationUserDetailsScreen = () => (
  <>
    <BackButton text="Wróć" onClick={() => routerHistory.push('/administration/users')} />
    <UserDetails />
  </>
);

export default AdministrationUserDetailsScreen;
