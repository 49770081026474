import { Button } from '@material-ui/core';
import React, { useRef } from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import { Wrapper } from "../../../../components/ListToolBarComponents";
import AddAttachmentPopup from './AddAttachmentPopup';

const DetailsToolBar = () => {
  const addPopupRef = useRef();

  const backToList = () => routerHistory.push('/marketing/email-list');

  return (
    <>
      <Wrapper style={{ justifyContent: 'space-between' }}>
        <BackButton text="Wróć do listy" onClick={backToList} />
        <Button variant="contained" onClick={() => addPopupRef.current.open()}>
          Dodaj plik
        </Button>
      </Wrapper>
      <AddAttachmentPopup ref={addPopupRef} backToList={backToList} />
    </>
  );
};

export default DetailsToolBar;
