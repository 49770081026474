import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from '@material-ui/core';
import { getCall } from '../../../components/indicator/Indicator';
import { API_COMPANIES_ROUTE } from "../../../constants/routes";
import serializeQuery from "../../../tools/serializeQuery";

const DynamicPeopleField = ({ name, label, onSelect, getOptionLabel, renderInput, value, values, rowIndex, dependency, ...props }) => {
  const [selected, setSelected] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('');
  const loading = open && options.length === 0;
  const dependencyValue = getDependencyValue();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleSelect = (newValue) => {
    setSelected(newValue);
    onSelect(newValue);
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
        return undefined;
    }

    (async () => {
        const response = await fetchPeople();

        if (active) {
            setOptions(response);
        }
    })();

    return () => {
        active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
        setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    let active = true;

    if(open) {

        (async () => {
            const response = await fetchPeople();

            if (active) {
                setOptions(response);
            }
        })();

    }

    return () => {
        active = false;
    };
  }, [inputValue]);

  function getDependencyValue() {
    const dependencyArr = dependency;
    let dep = values[dependencyArr[0]];
    if (!Array.isArray(dep) || typeof dep === 'string') {
     dep = JSON.parse(dep);
    }
    if (!dep || (Array.isArray(dep) && !dep.length) || !dep[rowIndex] || !dep[rowIndex][dependencyArr[1]]) {
      return null;
    }
    // extract dependency value from appropriate row
    return dep[rowIndex][dependencyArr[1]][dependencyArr[2]];
  }

  

  const fetchPeople = async () => {
    if (!dependencyValue) {
      return [];
    }

    const query = inputValue ? { query: inputValue } : {};
    const queryPath = query ? serializeQuery(query): '';
    // otherwise fetch people based on dependency (and optionally inputValue)
    const response = await getCall(`${API_COMPANIES_ROUTE}/${dependencyValue}/contact-list?${queryPath}`);
    const items = response?.data?.items;
    if (Array.isArray(items)) {
      return response?.data?.items?.map((user) => user.osoba);
    } else if (items && typeof items === 'object' && Object.keys(items)) {
      const res = [items[Object.keys(items)[0]]?.osoba];
      return res;
    }
    return [];
  }

  const getLoadingText = () => {
    if (dependencyValue && inputValue) {
      return 'Brak podpowiedzi dla wprowadzonej frazy';
    } else if (!dependencyValue) {
      return 'Wybierz firmę aby widzieć podpowiedzi';
    }
    return 'Wczytuję...';
  }

  return (
    <Autocomplete
      freeSolo={true}
      style={{ minWidth: 150 }}
      name={name}
      value={selected || inputValue}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        handleSelect(newInputValue);
      }}
      onChange={(event, newValue) => handleSelect(newValue)}
      loadingText={getLoadingText()}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: '100%' }}
          label={label || ''}
          variant="outlined"
          InputProps={{
              ...params.InputProps,
              endAdornment: (
              <React.Fragment>
                  {params.InputProps.endAdornment}
              </React.Fragment>
              ),
          }}
          />
      )}
  />)
}

export default DynamicPeopleField;