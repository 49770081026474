import React from "react";
import Table from "@material-ui/core/Table";
import {TableCell, TableHead, TableRow, TableBody} from "@material-ui/core";
import {LAUNCH, REVIEW, SERVICE} from "../../../../../constants/RepairActionType";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import ReviewLabel from "../../../../../components/notes/RepairActions/ReviewLabel";
import LaunchLabel from "../../../../../components/notes/RepairActions/LaunchLabel";
import ServiceLabel from "../../../../../components/notes/RepairActions/ServiceLabel";
import {useHistory} from "react-router-dom";

export default ({actions = {}}) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const history = useHistory();

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, actions?.length - page * rowsPerPage);

    const displayType = type => {
        switch (type) {
            case REVIEW: {
                return <ReviewLabel/>
            }
            case LAUNCH: {
                return <LaunchLabel/>
            }
            case SERVICE: {
                return <ServiceLabel/>
            }
            default: {
                return type
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Czynność</TableCell>
                    <TableCell>Data</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0
                        ? actions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : actions
                ).map(({id, type, created_at}) => (
                    <TableRow key={id} style={{cursor: "pointer"}}
                              onClick={() => history.push(`/service/repairs-actions/${id}/preview`)}>
                        <TableCell>{displayType(type)}</TableCell>
                        <TableCell>{created_at}</TableCell>
                    </TableRow>
                ))}
                {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}>
                        <TableCell colSpan={6}/>
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                        colSpan={3}
                        count={actions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {'aria-label': 'pokaż na stronie'},
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </>
}