import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {useState} from 'react';
import YesNoDialog from "../yes-no-dialog/YesNoDialog";
import Tooltip from "@material-ui/core/Tooltip";

export default ({fontSize, selected, tooltipInfo = 'Usuń', titleModal = 'Usuwanie wybranych elementów.', question = 'Czy na pewno chcesz usunąć wybrane elementy?', onDelete}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleDelete = () => setIsDialogOpen(true);

    const onAccept = () => onDelete(selected);

    return (
        <>
            <Tooltip title={tooltipInfo} placement="top">
                <IconButton
                    onClick={handleDelete}
                    aria-label="delete">
                    <DeleteIcon fontSize={fontSize}/>
                </IconButton>
            </Tooltip>
            <YesNoDialog
                title={titleModal}
                content={question}
                onCancel={() => setIsDialogOpen(false)}
                onAccept={onAccept}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};
