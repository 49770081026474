import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FolderIcon from '@material-ui/icons/Folder';
import BuildIcon from '@material-ui/icons/Build';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import MapIcon from '@material-ui/icons/Map';
import React from 'react';

const menuItems = [
  {
    routeName: '/projects',
    label: 'Projekty',
    icon: () => <AssignmentIcon fontSize="small" />,
    id: 1,
  },
  {
    routeName: '/companies',
    label: 'Firma',
    icon: () => <BusinessCenterIcon fontSize="small" />,
    id: 2,
  },
  {
    routeName: '/map',
    label: 'Mapa',
    icon: () => <MapIcon fontSize="small" />,
    id: 3,
  },
  {
    routeName: '/projects-automation',
    label: 'Automatyka',
    icon: () => <AssignmentLateIcon fontSize="small" />,
    id: 4,
  },
  {
    routeName: '/tasks',
    label: 'Zadania',
    icon: () => <AssignmentTurnedInIcon fontSize="small" />,
    id: 5,
  },
  {
    routeName: '/service/repairers',
    label: 'Serwis',
    icon: () => <BuildIcon fontSize="small" />,
    id: 6,
    subRoutes: [
      {
        routeName: '/service/templates',
        label: 'Szablony',
      },
      {
        routeName: '/service/repairers',
        label: 'Serwisanci',
      },
      {
        routeName: '/service/repairs',
        label: 'Karty serwisowe',
      },
      {
        routeName: '/service/raport',
        label: 'Raport',
      },
    ],
  },
  {
    routeName: '/offers',
    label: 'Oferty',
    icon: () => <FindInPageIcon fontSize="small" />,
    id: 7
  },
  {
    routeName: '/products-tree',
    label: 'Produkty',
    icon: () => <ShoppingCartIcon fontSize="small" />,
    subRoutes: [
      {
        routeName: '/products-tree',
        label: 'Produkty',
      },
      {
        routeName: '/producers',
        label: 'Producenci',
      },
      {
        routeName: '/categories',
        label: 'Kategorie',
      },
      {
        routeName: '/products',
        label: 'Urządzenia',
      },
    ],
    id: 8,
  },
  {
    routeName: '/dir',
    label: 'Dokumenty',
    icon: () => <FolderIcon fontSize="small" />,
    id: 9,
  },
  {
    routeName: '/calendar',
    label: 'Kalendarz',
    icon: () => <DateRangeIcon fontSize="small" />,
    id: 10,
  },
  {
    routeName: '/marketing/contacts',
    label: 'Marketing',
    icon: () => <AssessmentIcon fontSize="small" />,
    id: 11,
    isOpen: false,
    subRoutes: [
      {
        routeName: '/marketing/contacts',
        label: 'Kontakty',
      },
      {
        routeName: '/marketing/tags',
        label: 'Zainteresowania',
      },
      {
        routeName: '/marketing/email-list',
        label: 'Okresy promocyjne',
      },
    ],
  },
  {
    routeName: '/reports',
    label: 'Raporty',
    icon: () => <PieChartIcon fontSize="small" />,
    id: 12,
  },
  {
    routeName: '/administration/users',
    label: 'Administracja',
    icon: () => <SettingsIcon fontSize="small" />,
    id: 13,
    isOpen: false,
    subRoutes: [
      {
        routeName: '/administration/users',
        label: 'Użytkownicy',
      },
      {
        routeName: '/administration/configuration',
        label: 'Konfiguracja',
      },
    ],
  },
];

export default menuItems;
