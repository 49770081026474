import { Mail, People, SettingsPhone } from '@material-ui/icons';
import commonValues from '../../../../../tools/commonValues';
import React from 'react';

export const companyStatuses = [
  { name: 'status_arch', type: 'checkbox', label: 'Architekt', value: false },
  { name: 'status_dyst', type: 'checkbox', label: 'Dystrybutor', value: false },
  { name: 'status_inv', type: 'checkbox', label: 'Inwestor', value: false },
  { name: 'status_serv', type: 'checkbox', label: 'Serwisant', value: false },
  { name: 'status_aud', type: 'checkbox', label: 'Audytor', value: false },
  { name: 'status_mar', type: 'checkbox', label: 'Hurtownia', value: false },
  { name: 'status_pro', type: 'checkbox', label: 'Projektant', value: false },
  { name: 'status_do', type: 'checkbox', label: 'Wykonawca', value: false },
  { name: 'status_hbh', type: 'checkbox', label: 'HBH', value: false },
  { name: 'status_ins', type: 'checkbox', label: 'Instalator', value: false },
  { name: 'status_oth', type: 'checkbox', label: 'Inni', value: false },
];

const companyFields = [
  {
    "id": 1,
    "name": "Informacje ogólne",
    "type": "multipleFields",
    "options": {
      "fields": [

        {
          "id": 1,
          "name": "adres",
          "type": "text",
          "label": "Adres",
          "value": null,
          "options": {
            "width": 4
          }
        },
        {
          "id": 2,
          "name": "miasto",
          "type": "text",
          "label": "Miasto",
          "value": null,
          "options": {
            "width": 4
          }
        },
        {
          "id": 3,
          "name": "kod",
          "type": "text",
          "label": "Kod",
          "value": null,
          "options": {
            "width": 4
          }
        },
        {
          "id": 4,
          "name": "wojewodztwo",
          "type": "dictionary",
          "label": "Województwo",
          "value": null,
          "options": {
            "id": 1,
            "width": 4,
            "data": commonValues.voivodeships,
            "fields": [],
            "name": "województwa"
          },
        },
        {
          "id": 6,
          "name": "telefon",
          "type": "phone",
          "label": "Telefon",
          "value": null,
          "options": {
            "width": 4
          }
        },
        {
          "id": 7,
          "name": "www",
          "type": "text",
          "label": "Strona WWW",
          "value": null,
          "options": {
            "width": 4
          }
        },
        {
          "id": 8,
          "name": "ustalenia",
          "type": "multilineInput",
          "label": "Ustalenia handlowe",
          "value": null,
          "options": {
            "width": 4
          }
        },
      ]
    },
  },
  {
    "id": 4,
    "name": "Kontakty",
    "type": "multipleFields",
    "options": {
      "fields": [
        {
          "id": 9,
          "name": "contactslist",
          "type": "rowsGroup",
          "label": "Lista kontaktów",
          "value": "[]",
          "options": {
            "id": 3,
            "data": [],
            "fields": [
              {
                "id": 10,
                "name": "osoba",
                "type": "text",
                "label": "Osoba",
                "value": null,
                "options": null
              },
              {
                "id": 11,
                "name": "stanowisko",
                "type": "text",
                "label": "Stanowisko",
                "value": null,
                "options": null
              },
              {
                "id": 12,
                "name": "telefon",
                "type": "phone",
                "label": "Telefon",
                "value": null,
                "options": null
              },
              {
                "id": 13,
                "name": "email",
                "type": "text",
                "label": "E-mail",
                "value": null,
                "options": null
              },
              {
                "id": 14,
                "name": "mailing",
                "type": "checkbox",
                "label": "Mailing",
                "value": null,
                "options": null
              }
            ],
            "name": "Kontakty"
          }
        }
      ]
    }
  },
  {
    "id": 1,
    "name": "Status",
    "type": "multipleFields",
    "options": {
      "fields": [
        { name: 'status', type: 'none', label: true, fullWidth:true, value: false },
        ...companyStatuses,
        { name: 'status', type: 'none', fullWidth:true, value: false }
      ]
    }
  },
  {
    "id": 2,
    "name": "Ostatni kontakt",
    "type": "multipleFields",
    "options": {
      "fields": [
        {
          "id": 16,
          "name": "ostatni_tel",
          "type": "last_contact_button",
          "label": "Telefon",
          "value": null,
          "options": {
            "icon": (<SettingsPhone />),
            "width": 4
          }
        },
        {
          "id": 17,
          "name": "ostatni_mail",
          "type": "last_contact_button",
          "label": "Mail",
          "value": null,
          "width": 4,
          "options": {
            "icon": (<Mail />),
            "width": 4
          }
        },
        {
          "id": 18,
          "name": "ostatni_spotkanie",
          "type": "last_contact_button",
          "label": "Spotkanie",
          "value": null,
          "width": 4,
          "options": {
            "icon": (<People />),
            "width": 4
          }
        }
      ]
    }
  },
  {
    "id": 5,
    "name": "Inne",
    "type": "multipleFields",
    "options": {
      "fields": [
        {
          "id": 19,
          "name": "zainteresowany_szkoleniem",
          "type": "checkbox",
          "label": "Zainteresowany szkoleniem",
          "value": null,
          "options": null
        },
        {
          "id": 19,
          "name": "zgoda_marketing",
          "type": "checkbox",
          "label": "Zgoda na marketing",
          "value": null,
          "options": null
        },
        {
          "id": 20,
          "name": "pochodzenie_kontaktu",
          "type": "text",
          "label": "Pochodzenie kontaktu",
          "value": null,
          "options": null
        },
        {
          "id": 21,
          "name": "ranking",
          "type": "text",
          "label": "Ranking",
          "value": null,
          "options": null
        }
      ]
    }
  },
  
  
];

export default companyFields;
