import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ColumnWrapper from '../wrappers/ColumnWrapper';

const Container = styled(Paper)`
  margin: 0px 0px 0px;
`;

const PaperContainer = ({ children, style, title }) => (
  <Container style={style}>
    <ColumnWrapper fullWidth>
      <Typography
        style={{ margin: '10px 0 0 10px', fontWeight: 'bold' }}
        color={'secondary'}
        variant="body1">
        {title}
      </Typography>
      <ColumnWrapper fullWidth>{children}</ColumnWrapper>
    </ColumnWrapper>
  </Container>
);

PaperContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  title: PropTypes.string,
};

PaperContainer.defaultProps = {
  style: {},
  title: '',
};

export default PaperContainer;
