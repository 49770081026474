
const serviceActionLaunch = ({repairersOptions = []} = {}) => [
    {
      type: 'multipleFields',
      name: 'Informacje',
      value: [],
      options: { fields: [
          { name: 'Data uruchomienia', label: 'Data uruchomienia', type: 'date', value: '', },
          { name: 'Serwisant', label: 'Serwisant', type: 'dictionary', options: {
            data: repairersOptions
          }, value: ''},
          { label: 'Tabela urządzeń', name: 'Tabela', type: 'none', fullWidth: true, value: '' },
          { name: 'Tabela', type: 'none', fullWidth: true, value: '' },
        { label: 'Notatka', name: 'Notatka', type: 'multilineInput', fullWidth: true, value: '', },
        { label: 'Uwagi z protokołu', name: 'Uwagi z protokołu', type: 'multilineInput', fullWidth: true, value: '', },
        {
          "id": 9,
          "name": "Pliki",
          "type": "rowsGroup",
          "label": "Załącz pliki",
          "value": "[]",
          "options": {
            "id": 3,
            "data": [],
            "name": "plik",
            "fields": [
              {
                "id": 10,
                "name": "plik",
                "type": "attachment",
                "label": "Plik",
                "value": null,
                "options": null
              }
            ],
          }
        },
        { label: 'Opis pliku', name: 'Opis pliku', type: 'multilineInput',  value: '', },


        ]
      },
    },
  ];


  export { serviceActionLaunch };