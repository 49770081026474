import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Wrapper } from '../../components/FormComponents';
import BackButton from '../../components/buttons/back-button/BackButton';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { Typography, Button, Modal, TextField, InputLabel, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import RowWrapper from "../../components/wrappers/RowWrapper";
import routerHistory from '../../constants/settings/routerHistory';
import ExpansionContainer from '../../components/containers/ExpansionContainer';
import { getCall, postCall, putCall } from '../../components/indicator/Indicator';
import { 
  API_PRODUCT_PRODUCER_ROUTE,
  API_TEMPLATES_LAUNCH_ROUTE,
  API_TEMPLATES_LAUNCH_CREATE_ROUTE,
  API_TEMPLATES_LAUNCH_UPDATE_ROUTE,
} from '../../constants/routes';
import { toast } from 'react-toastify';

const TemplatesLaunchDetailsScreen = () => {
  const [producers, setProducers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [serviceGroup, setServiceGroup] = useState('');
  const [serviceGroups, setServiceGroups] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  const [isTemplateCreated, setIsTemplateCreated] = useState(false);
  const [editGroupName, setEditGroupName] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);

  const [open, setOpen] = useState(false);

  const onRowChange = (e, name, index) => {
    const tmp = [...serviceGroups];
    const columnIndex = serviceGroups.findIndex((item) => item.name === name);
    tmp[columnIndex].rows[index] = e.target.value;
    setServiceGroups(tmp);
  }

  const onGroupNameChange = (name) => {
    const tmp = [...serviceGroups];
    const columnIndex = serviceGroups.findIndex((item) => item.name === name);
    tmp[columnIndex].name = newGroupName;
    setNewGroupName(tmp);
  }

  const handleOpen = (id) => {
    setOpen(true);
    setSelected(producers.find(item => item.id === id));
  }

  const fetchProducers = async () => {
    const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}?type=1`);
    setProducers(data?.items);
  }

  const fetchLaunchTemplates = async () => {
    const { data: { item } } = await getCall(API_TEMPLATES_LAUNCH_ROUTE);
    setTemplates(item);
  }

  const fetchServiceGroups = async () => {
    const { data: { item } } = await getCall(`${API_TEMPLATES_LAUNCH_ROUTE}?product_producer=${selected.id}`);
    if (!Array.isArray(item)) {
      const convertedColumns = item?.columns?.map((item) => ({
        name: item.name,
        rows: item?.values?.map((row) => row.value)
      }))
      setServiceGroups(convertedColumns);
      setIsTemplateCreated(true);
      setTemplateId(item?.id);
    } else {
      setIsTemplateCreated(false);
    }
  }

  const addServiceGroup = () => {
    if (serviceGroups.some((item) => item.name === serviceGroup)) {
      toast.error(`Grupa serwisowa ${serviceGroup} już istnieje.`);
      return;
    }

    if (!serviceGroup.length) {
      toast.error("Brak nazwy grupy serwisowej.");
      return;
    }

    setServiceGroups((prev) => ([
      ...prev,
      { name: serviceGroup, rows: Array(rowsCount).fill('')}
    ]));
  }

  const addNewRow = () => {
    setRowsCount(serviceGroups[0].rows.length + 1);
    const newServiceGroupRow = serviceGroups.map((item) => {
      item.rows.push('');
      return ({ ...item })
    });
    setServiceGroups(newServiceGroupRow);
  };

  const deleteGroup = (name) => {
    const newServiceGroups = [...serviceGroups];
    const index = newServiceGroups.findIndex(item => item.name === name);
    newServiceGroups.splice(index, 1);
    setServiceGroups(newServiceGroups);
  }

  const onSubmit = () => {
    const body = {
      product_producer: selected.id,
      launch_template: templateId,
      columns: {
      ...serviceGroups
      }
    }

    if (isTemplateCreated) {
      putCall(API_TEMPLATES_LAUNCH_UPDATE_ROUTE, body)
        .then((result) => {
          if (result.status === 'ok') {
            toast.success("Szablon został zaktualizowany");
            fetchLaunchTemplates();
          }
        })
        .catch(() => {
          toast.error("Wystąpił problem z aktualizacją szablonu");
        });
    
    } else {
      postCall(API_TEMPLATES_LAUNCH_CREATE_ROUTE, body)
        .then((result) => {
          if (result.status === 'ok') {
            toast.success("Szablon został utworzony");
            fetchLaunchTemplates();
          }
        })
        .catch(() => {
          toast.error("Wystąpił problem z utworzeniem szablonu");
        })
    }
  }

  useEffect(() => {
    if (!isTemplateCreated) {
      setServiceGroups([]);
    }
  }, [isTemplateCreated])

  useEffect(() => {
    fetchProducers();
    fetchLaunchTemplates();
  }, [])

  useEffect(() => {
    if (selected && open) {
      fetchServiceGroups();
    }
  }, [selected, open])

  return <>
    <Wrapper>
      <RowWrapper style={{alignItems: 'center'}}>
        <BackButton
            text="Wróć"
            onClick={() => routerHistory.goBack()}
        />
        <ColumnWrapper>
          <Typography>Koszty uruchomienia</Typography>
        </ColumnWrapper>
      </RowWrapper>
    </Wrapper>
    <RowWrapper>
      <ColumnWrapper width='100%'>
        <ExpansionContainer title="Koszty uruchomienia">
          <RowWrapper>
            <ColumnWrapper>
              <h3>Producent</h3>
              {producers.map(({name}) => (
                <RowWrapper
                  width='100%'
                  style={{ height: '60px', fontSize: '14px', alignItems: 'center' }}
                >
                  {name}
                </RowWrapper>
              ))}
            </ColumnWrapper>
            <ColumnWrapper style={{ marginLeft: '50px' }}>
              <h3>Szablon</h3>
              {producers.map(({id}) => (
                <RowWrapper width='100%' style={{ height: '60px', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleOpen(id)}
                    style={{ color: 'white' }}
                    variant="contained"
                    color="primary"
                  >
                  +
                  </Button>
                </RowWrapper>
              ))}
            </ColumnWrapper>
            <ColumnWrapper style={{ marginLeft: '50px', height: '100%' }}>
              <h3>Data utworzenia/aktualizacji</h3>
              {producers.map(({id}) => (
                templates.find((item) => item?.productProducer?.id === id) ? (
                  <RowWrapper style={{
                      width: 'fit-content',
                      height: '40px', 
                      alignItems: 'center', 
                      border: '1px solid', 
                      margin: '10px', 
                      padding: '10px',
                      fontWeight: 'bold'
                    }}
                  >
                    {moment(templates.find((item) => item?.productProducer?.id === id)?.updatedAt || 
                        templates.find((item) => item?.productProducer?.id === id)?.createdAt)
                        .format("DD-MM-YY - HH:mm")}
                  </RowWrapper>
                ) : (
                  <RowWrapper style={{ height: '40px', margin: '10px', padding: '10px' }}/>
                ) 
              ))}
            </ColumnWrapper>
          </RowWrapper>
        </ExpansionContainer>
      </ColumnWrapper>
    </RowWrapper>
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setServiceGroups([]);
        setServiceGroup('');
      }}
      style={{ overflow: 'auto' }}
    >
      <ColumnWrapper
        width='80%'
        style={{
          position: 'absolute',
          height: '80%',
          overflow: 'auto',
          padding: '25px',
          margin: '0 auto',
          background: '#fff',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <RowWrapper align='flex-end'>
          <ColumnWrapper>
            <InputLabel style={{margin: '6px 0'}} shrink htmlFor='producer'>Producent</InputLabel>
            <TextField
              name='producer'
              value={selected?.name}
              variant='outlined'
              disabled
            />
          </ColumnWrapper>
          <ColumnWrapper>
            <InputLabel style={{margin: '6px 0'}} shrink htmlFor='serviceGroup'>Grupa serwisowa</InputLabel>
            <TextField
              name='serviceGroup'
              value={serviceGroup}
              onChange={(e) => setServiceGroup(e.target.value)}
              variant='outlined'
            />
          </ColumnWrapper>
          <ColumnWrapper style={{ marginLeft: '20px' }}>
            <Button
              onClick={addServiceGroup}
              style={{ color: 'white', margin: '5px', padding: '5px 10px' }}
              variant="contained"
              color="primary"
            >
              +
            </Button>
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper style={{ flexDirection: 'column' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Grupa serwisowa</TableCell>
                    {serviceGroups.map(({name}) => (
                      <TableCell>
                        <Button onClick={() => deleteGroup(name)}>
                          Usuń grupę
                        </Button>
                      </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                  <TableCell>Lp.</TableCell>
                    {serviceGroups.map(({name}) => (
                      <TableCell style={{ minWidth: '200px' }}>
                        <RowWrapper style={{ alignItems: 'center' }}>
                          {editGroupName.length && editGroupName === name ? (
                            <>
                              <ColumnWrapper>
                                <TextField 
                                  name={name}
                                  value={newGroupName}
                                  onChange={(e) => setNewGroupName(e.target.value)}
                                />
                              </ColumnWrapper>
                              <ColumnWrapper style={{ flexDirection: 'row' }}>
                                <IconButton onClick={() => onGroupNameChange(name)} style={{ padding: 0 }}>
                                  <DoneIcon fontSize='small'/>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setEditGroupName('');
                                    setNewGroupName('');
                                  }}
                                  style={{ padding: 0 }}
                                >
                                  <CloseIcon fontSize='small'/>
                                </IconButton>
                              </ColumnWrapper>
                            </>
                          ) : (
                            <>
                              <ColumnWrapper>{name}</ColumnWrapper>
                              <ColumnWrapper>
                                <IconButton
                                  onClick={() => {
                                    setEditGroupName(name);
                                    setNewGroupName(name);
                                  }}
                                  style={{ padding: 0 }}
                                >
                                  <EditIcon fontSize='small'/>
                                </IconButton>
                              </ColumnWrapper>
                            </>
                          )}
                        </RowWrapper>
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceGroups[0]?.rows.map((value, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    {serviceGroups.map(({name, rows}, i) => (
                      <TableCell>
                        <TextField
                          name={`${name}-${i}`}
                          value={rows[index]}
                          variant='outlined'
                          onChange={(e) => onRowChange(e, name, index)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}    
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={addNewRow}
            style={{ color: 'white', marginTop: '10px' }}
            variant="contained"
            color="primary"
          >
            +
          </Button>
        </RowWrapper>
        <RowWrapper style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={onSubmit}
            style={{ color: 'white' }}
            variant="contained"
            color="primary"
          >
              Zapisz
          </Button>
        </RowWrapper>
      </ColumnWrapper>
    </Modal>
  </>
}

export default TemplatesLaunchDetailsScreen;