import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import React, { useState } from 'react';
import YesNoDialog from '../../yes-no-dialog/YesNoDialog';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const Bar = (props) => {
  const classes = useToolbarStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { numSelected, onDelete, useDialogOnDelete, customActions } = props;

  const handleDelete = () => {
    if (useDialogOnDelete) {
      setIsDialogOpen(true);
    } else onDelete(numSelected);
  };

  const onAccept = () => {
    onDelete(numSelected);
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected.length > 0,
        })}>
        {numSelected.length > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div">
            Liczba zaznaczonych wierszy: {numSelected.length}
          </Typography>
        ) : null}
        {numSelected.length > 0 && onDelete ? (
          <Tooltip title="Usuń">
            <IconButton
              onClick={onDelete ? handleDelete : null}
              aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {numSelected.length > 0 && customActions && customActions.map(action => (
          <>
          <Tooltip title={action.title}>
            <IconButton
              onClick={() => action.handler(numSelected)}
              aria-label={action.title}
              label={action.label}
              >
              {action.icon}
            </IconButton>
          </Tooltip>
          {action.children(numSelected)}
          </>
        ))}
      </Toolbar>
      {useDialogOnDelete ? (
        <YesNoDialog
          title={'Usuwanie wybranych elementów.'}
          content={'Czy na pewno chcesz usunąć wybrane elementy?'}
          onCancel={() => setIsDialogOpen(false)}
          onAccept={onAccept}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      ) : null}
    </>
  );
};

export default Bar;
