import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "boring-avatars";
import {Avatar as MaterialAvatar} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        '&:hover': {
        backgroundColor: 'transparent',
        },
        marginRight: '5px',
        padding: '0px'
    }
});

// Inspired by blueprintjs
const UserImage = ({
    src,
    size = 36,
    alt = "Avatar",
    name = "u",
    styles = {},
    ...props
}) => {
    const classes = useStyles();

    const firstLetter = name?.toUpperCase()?.charAt(0) || '';

    return (
        <>
        {src ? (
            <MaterialAvatar
                style={{width: size+'px', height: size+'px', ...props.styles}}
                src={src}
                alt={name}
            />
        ) : (
            <div style={{width: size+'px', height: size+'px', display: 'flex', justifyContent: 'center', alignItems: 'center', ...props.styles}}>
                <span style={{zIndex: '2', fontSize: size/2, opacity: .9, color: '#3c3c3c'}}>{firstLetter}</span>
                <Avatar
                    size={size}
                    name={name}
                    style={{position: 'absolute'}}
                    variant="marble"
                    colors={['#f5bb64','#e7bb34','#f59828','#ffc55f','#f7b540']}
                />
            </div>
        )}
            
        </>
    );
}

export default UserImage;