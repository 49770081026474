const projectsHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
  { id: 'status', numeric: false, disablePadding: false, label: 'status' },
];

const tasksHeadCells = [
  { id: 'desc', numeric: false, disablePadding: false, label: 'zadanie' },
  { id: 'project', numeric: false, disablePadding: false, label: 'projekt' },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'data utworzenia',
  },
  {
    id: 'creator',
    numeric: false,
    disablePadding: false,
    label: 'utworzono przez',
  },
  {
    id: 'for',
    numeric: false,
    disablePadding: false,
    label: 'przypisano do',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'typ',
  },
];

export { projectsHeadCells, tasksHeadCells };
