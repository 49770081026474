import React, { useState } from 'react';
import { useParams } from 'react-router';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import {UseActionZoneStyles} from "./ActionZoneStyles";
import routerHistory from "../../../../../constants/settings/routerHistory";
import moment from "moment";

import {
  API_PRODUCTS_ROUTE,
  API_PROJECT_DEVICES_ROUTE,
  API_OFFERS_ROUTE
} from "../../../../../constants/routes"
import {
  getCall,
  postCall,
  putCall,
} from "../../../../../components/indicator/Indicator";
import { toast } from 'react-toastify';
import {responseToForm} from "../../../../../components/dynamic-forms/field-transformer";

const RepairOffersZone = ({setRootRows, pid, project}) => {
  const classes = UseActionZoneStyles();

  const { id } = useParams();

  const [devices, setDevices] = useState([]);
  const [rows, setRows] = useState([]);
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };
  //
  // const turnToRows = (data) => {
  //   setRows(data.map((item) => Array(item.amount).fill(1).map(i => ({
  //     product: item.product,
  //     number: i,
  //     id: '',
  //     serial: '',
  //     uuid: Math.random().toString(36).substring(7)
  //   }))))
  // };

  // const fetchFields = async () => {
  //     const {data} = await getCall(`${API_OFFERS_ROUTE}`);
  //     console.log(API_OFFERS_ROUTE)
  //     console.log(data)
  //
  //     if (data) {
  //
  //     }
  // };

  /* const fetchProjectDevices = async () => {
    try {
      const { data } = await getCall(`${API_PROJECT_DEVICES_ROUTE(pid)}`);
      if (data) {
        const rows = [];
        turnToRows(data);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania listy urządzeń w projekcie.');
    }
  }

  React.useEffect(() => {
    if(products.length && pid) {
      fetchProjectDevices();
    }
  }, [products, pid]);

  const fetchDevices = async () => {
    
    try {
      const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
      if (data) {
        setProducts(data.items);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
    }


    
  };*/

  // React.useEffect(() => {
    // fetchFields();
    // fetchDevices();
  // }, []);
  const offerNumber = `se/${moment().locale('pl').format('YY/MM/DD')}`;

  const addNewRow = () => {
    setRows([...rows, {
      uuid: Math.random().toString(36).substring(7)
    }]);
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    if(i >= 0) {
      localRows[i][name] = v;
    }
    setRows(localRows);
  };

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Data wystawienia</TableCell>
        <TableCell>Numer oferty</TableCell>
        <TableCell>Inwestor/klient</TableCell>
        <TableCell>Serwisant</TableCell>
        <TableCell>Dojazd</TableCell>
        <TableCell>Notatka</TableCell>
        <TableCell>Data zlecenia</TableCell>
        <TableCell>Data realizacji</TableCell>
        <TableCell>Rozliczenie serwis</TableCell>
        <TableCell>Rozliczenie klient</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Podgląd</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <a id="offers"/>
      <ExpansionContainer title="Oferty">
        <ColumnWrapper fullWidth className={classes.overflow} style={{overflow: "scroll"}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table">
            {renderHeader()}
            <TableBody>{rows.map((item, i) => <RepairOffersZoneRow i={item} index={item.uuid} key={item.uuid} editRow={editRow} removeRow={removeRow} />)}</TableBody>
            <RowWrapper fullWidth>
              <ColumnWrapper width="20%">
                <Button
                    onClick={() => routerHistory.push(`/offers/new?offerNumber=${offerNumber}&type=serwis&status=0&projectName=${project.name}&projectId=${project.id}`)}
                    variant="contained"
                >
                  Generuj ofertę
                </Button>
              </ColumnWrapper>
            </RowWrapper>
          </Table>
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

const RepairOffersZoneRow = ({i, index, editRow, removeRow, ...props}) => {
    const classes = UseActionZoneStyles();
    return (
    <TableRow key={i.uuid+'row'}>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('dateonly', {}, 'dateoffer', i.number, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'offer', i.product, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'inwestor', i.id, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'serwisant', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'dojazd', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'notatka', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('dateonly', {}, 'data_zlec', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('dateonly', {}, 'data_realizacji', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'rozlicz_serwis', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'rozlicz_klient', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        {returnSpecificField('text', {}, 'status', i.amount, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell className={classes.rowInput}>
        <Button variant="solid">Podgląd</Button>
      </TableCell>
    </TableRow>
    );
}

export default RepairOffersZone;
