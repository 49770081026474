import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {getCall} from "../../components/indicator/Indicator";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import BasicData from './components/repairs/details/BasicData';
import DetailsToolBar from './components/repairs/details/DetailsToolBar';
import RepairActionsZone from "./components/repairs/details/RepairActionsZone";
import {API_REPAIRS_ROUTE} from '../../constants/routes';
import RepairOffersZone from './components/repairs/details/RepairOffersZone';
import Loader from "../../components/loader/Loader";
import RepairDevicesZone from "./components/repairs/details/RepairDevicesZone";
import {responseToForm} from '../../components/dynamic-forms/field-transformer';
import RowWrapper from '../../components/wrappers/RowWrapper';
import RepairCostsZone from "./components/repairs/details/RepairCostsZone";
import {AppBar, Button, makeStyles} from "@material-ui/core";
import NotesPanel from "../../components/notes/NotePanel";

const useStyles = makeStyles((theme) => ({
    sticky: {
        background: '#fafafa',
        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
        display: 'flex',
        top: '54px'
    }
}));

const RepairsDetailsScreen = () => {
    const formRef = useRef();
    const classes = useStyles();
    const {id} = useParams();
    const [editData, setEditData] = useState({});
    const [initialValues, setInitialValues] = useState({serwis_koszty: []});
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [notesData, setNotesData] = useState({ notes: [] });

    const fetchEditData = useCallback(async () => {
        const r = await getCall(`${API_REPAIRS_ROUTE}/${id}/full`);
        if (r?.data?.project) {
          return r.data;
        }
        return [];
      }, [id]);

    const fetchNotes = async () => {
        const r = await getCall(`${API_REPAIRS_ROUTE}/${id}`);
        console.log(r)
        if (r.data && r.data.object && r.data.object) {
          setNotesData(r.data.object);
          return r.data.object;
        }
        return [];
      };

    const fetchFields = async () => {
        if (id !== 'new' && id !== undefined) {
            const {data} = await getCall(`${API_REPAIRS_ROUTE}/${id}`);
            const editData = data?.object;
            if (editData) {
                setProjectId(editData?.project?.id);
                setEditData(editData);
                setNotesData(editData);
                setInitialValues({
                    value_project: editData?.project,
                    value_repairer: editData?.repairer,
                    ...responseToForm(editData?.fields)
                });
                setInitialValues((prev) => ({...prev, value_project: editData?.project}))
            }
        }
    };

    useEffect(() => {
        fetchFields().then(() => setLoading(false));
    }, [id]);

    const separateProjectDevices = devices => devices.reduce((state, {amount, product}) =>
        [...state, ...Array(amount).fill({product})], []
    );

    useEffect(() => {
        !id && setEditData(data => ({
            ...data,
            devices: separateProjectDevices(project?.devices || [])
        }))
    }, [id, project]);

    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const topOptions = [
        { label: 'Dane podstawowe', id: 'main' },
        { label: 'Karta gwarancyjna', id: 'warranty-card' },
        { label: 'Urządzenia', id: 'devices' },
        { label: 'Czynności serwisowe', id: 'services' },
        { label: 'Tabela kosztów', id: 'costs' },
        { label: 'Oferty', id: 'offers' },
    ];

    return (
        <>
            <DetailsToolBar toggleDrawer={toggleDrawer} projectName={editData?.project?.name || ''} projectId={projectId}/>
            <RowWrapper style={{alignItems: 'flex-start'}}>
                <ColumnWrapper width={open ? '60%' : '100%'}>
                    <AppBar color="transparent" className={classes.sticky} position="sticky">
                        <RowWrapper>
                            {topOptions.map(item => (<ColumnWrapper key={item.label} size="auto" style={{flex: '1 1 0', padding: '3px'}}>
                                <Button color="inherit" onClick={(e) => document.getElementById(item.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' })} style={{fontSize: '10px', padding: '3px'}}>{item.label}</Button>
                            </ColumnWrapper>))}
                        </RowWrapper>
                    </AppBar>
                    {loading
                        ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}>
                            <Loader/>
                        </div>
                        : <>
                            <BasicData
                                fetchNotes={fetchNotes} 
                                edit={id !== 'new' ? id : null}
                                data={editData}
                                initialValues={initialValues}
                                onEditProject={project => setProject(() => project || null)}
                            />
                            <RepairDevicesZone devices={editData?.devices} onUpdate={devices => setEditData(data => ({...data, devices}))}/>
                            <RepairActionsZone data={editData}/>
                            <RepairCostsZone costs={initialValues?.serwis_koszty || []} actions={editData?.actions || []} onUpdate={serwis_koszty => setInitialValues(values => ({...values, serwis_koszty}))}/>
                            <RepairOffersZone pid={editData?.project?.id} project={editData?.project} />
                        </>
                    }
                </ColumnWrapper>
                {open && !loading && id && <ColumnWrapper>
              <NotesPanel 
                fetchNotes={fetchNotes} data={notesData}
                selectedRepair={{id, name: id}}
              />
            </ColumnWrapper>}
            </RowWrapper>
        </>
    );
};

export default RepairsDetailsScreen;
