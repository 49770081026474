import React from 'react';
import styled from 'styled-components';
import routerHistory from "../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../components/buttons/back-button/BackButton";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { Button, Typography } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import ColumnWrapper from '../../../../../components/wrappers/ColumnWrapper';
import {useParams} from "react-router-dom";

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const SlotToolBar = ({id, title, toggleDrawer}) => {
  const { eventId } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper style={{alignItems: 'center'}}>
          <BackButton
            text="Wróć do kalendarza"
            onClick={() => routerHistory.goBack()} 
          />
          <ColumnWrapper>
            <Typography>Wydarzenie #{id}: {title}</Typography>
          </ColumnWrapper>
          
        </RowWrapper>

        {
          eventId && <RowWrapper>
            <ButtonWrapper>
              <Button
                  onClick={toggleDrawer}
                  startIcon={<Message />}
                  style={{ color: 'white' }}
                  variant="contained"
                  color="secondary">
                Notatki
              </Button>
            </ButtonWrapper>
          </RowWrapper>
        }
      </Wrapper>
    </>
  );
};

export default SlotToolBar;
