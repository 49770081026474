import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import PrimaryButton from "./buttons/PrimaryButton";

export const Bar = styled.div`
  display: flex;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 1100;
  left: 0;
  background-color: #fff;
  padding: 10px 25px;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const FloatingSaveBar = ({ onClick, handlePreview, disabled, name = "Zapisz", children }) => {
  return (
    <>
      <Bar>
        {handlePreview && (
          <PrimaryButton
            onClick={handlePreview}
            style={{ marginRight: '20px' }}
          >
            Podgląd
          </PrimaryButton>
        )}
        <PrimaryButton disabled={disabled} onClick={onClick}>
          {name}
        </PrimaryButton>
        {children}
      </Bar>
    </>
  );
};

export default FloatingSaveBar;

FloatingSaveBar.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

FloatingSaveBar.defaultProps = {
  onClick: () => {},
  disabled: false,
};
