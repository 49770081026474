import React, {useMemo} from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import {Wrapper} from "../../../../components/ListToolBarComponents";
import AddDirectoryModal from "../add/AddDirectoryModal";
import AddFileModal from "../add/AddFileModal";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {Button} from "@material-ui/core";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {useParams} from "react-router-dom";

export default ({onSave, isLoading, parent = [], currentName = ''}) => {
    const {id} = useParams();

    const parents = useMemo(() => {
        const parents = [];
        const getParent = dir => {
            if(dir?.parent) {
                parents.push(dir.parent);
                getParent(dir.parent)
            }
        };
        getParent(parent);

        return parents.reverse()
    }, [parent]);

    return (
        <>
            <Wrapper style={{justifyContent: 'space-between'}}>
                <RowWrapper style={{alignItems: "center"}}>
                    <BackButton
                        text="Wróć"
                        onClick={() => parent?.parent?.id ? routerHistory.push(`/dir/${parent?.parent?.id}`) : routerHistory.push('/dir')
                        }
                    />
                    {!isLoading &&
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color={parent?.id ? 'inherit' : 'textPrimary'} href="/dir">
                            Dokumenty
                        </Link>
                        {
                            parents.map(({id, name}) =>
                                <Link color="inherit" href={`/dir/${id}`}>
                                    { name }
                                </Link>
                            )
                        }
                        <Link
                            color="textPrimary"
                            href={`/dir/${id}`}
                            aria-current="page"
                        >
                            { currentName }
                        </Link>
                    </Breadcrumbs>
                    }
                </RowWrapper>
                <RowWrapper>
                    <AddDirectoryModal parentId={parent?.id} directory={0} onSave={onSave}>
                        {{
                            button: ({handleOpen}) =>
                                <Button disabled={isLoading} style={{marginRight: 20}} onClick={handleOpen}>
                                    <CreateNewFolderIcon/>katalog
                                </Button>
                        }}
                    </AddDirectoryModal>
                    <AddFileModal parentId={parent?.id} directory={0} onSave={onSave}>
                        {{
                            button: ({handleOpen}) =>
                                <PrimaryButton disabled={isLoading} onClick={handleOpen}>
                                    <NoteAddIcon/>plik
                                </PrimaryButton>
                        }}
                    </AddFileModal>
                </RowWrapper>
            </Wrapper>
        </>
    );
};
