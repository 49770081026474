import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCall } from '../../../components/indicator/Indicator';
import ColumnWrapper from '../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../components/wrappers/RowWrapper';
import { MARKETING_CONTACT_ROUTE } from '../../../constants/routes';
import MarketingCompanyForm from './components/add/MarketingCompanyForm';
import Notes from './components/add/Notes';
import TagsTimeline from './components/add/TagsTimeline';
import ListToolBar from './components/list/ListToolBar';

const MarketingContactInfoScreen = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({})
  const [tags, setTags] = useState([])

  useEffect(() => {
    const fetchEditData = async () => {
      const { data } = await getCall(`${MARKETING_CONTACT_ROUTE}/single/${id}`);
      if (data) {
        setInitialValues(data);
        setTags(data?.tags);
      }
      return [];
    };
    if (id) {
      fetchEditData();
    }
    // eslint-disable-next-line
  }, [id]);

  return (
  <>
    <ListToolBar />
    <RowWrapper fullWidth width="100%">
      <ColumnWrapper width="60%">
        <MarketingCompanyForm initialValues={initialValues} />
        <Notes />
      </ColumnWrapper>
      <ColumnWrapper width="40%">
        <TagsTimeline tags={tags} item={initialValues} />
      </ColumnWrapper>
    </RowWrapper>
  </>
)}

export default MarketingContactInfoScreen;
