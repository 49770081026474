import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ColumnWrapper from "./wrappers/ColumnWrapper";
import RowWrapper from "./wrappers/RowWrapper";

const InputWrapper = styled(RowWrapper)`
  margin: 15px 0;
  align-items: flex-end;
  width: 100%;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

const InputLabel = styled(Typography)`
  margin-right: 10px;
  min-width: 100px;
  @media (max-width: 1400px) {
    min-width: 80px;
  }
`;

const Wrapper = styled(RowWrapper)`
  align-items: flex-start;
`;

const FormWrapper = styled(ColumnWrapper)`
  padding: 25px;
`;

const ColumnWrapperWithRightSpace = styled(ColumnWrapper)`
  margin-right: 10px;
`;

const RowWrapperWithRightSpace = styled(RowWrapper)`
  margin-right: 10px;
`;

const HorizontalSpaceDivider = styled.div`
  margin: 0 10px;
`;

const StrictColWrapper = styled(ColumnWrapper)`
  padding: 10px 0;
`;

export {
  InputLabel,
  InputWrapper,
  Wrapper,
  FormWrapper,
  ColumnWrapperWithRightSpace,
  HorizontalSpaceDivider,
  RowWrapperWithRightSpace,
  StrictColWrapper,
};
