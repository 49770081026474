import DateFnsUtils from '@date-io/date-fns';
import { InputLabel, TextField } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExpansionContainer from '../../../components/containers/ExpansionContainer';
import { getCall, postCall, putCall } from '../../../components/indicator/Indicator';
import BasicTable from '../../../components/tables/basic/BasicTable';
import ColumnWrapper from '../../../components/wrappers/ColumnWrapper';
import DetailsToolBar from '.././components/actions/DetailsToolBar';
import FloatingSaveBar from '../../../components/FloatingSaveBar';
import { MARKETING_ACTIONS_ROUTE, API_USERS_ROUTE } from '../../../constants/routes';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MarketingActionDetailsScreen = () => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState({
    selectedUsers: [],
    data: [],
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      shortcut: '',
      team: [],
      description: '',
      startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    },
  });

  useEffect(() => {
    const fetchEditData = async () => {
      const { data } = await getCall(`${MARKETING_ACTIONS_ROUTE}/${id}`);
      if (data?.item) {
        setFiles(
          data.item?.files?.map((el) => ({
            id: el?.id,
            name: el?.name,
            description: el?.description,
            created: moment(el?.created?.date).format('YYYY-MM-DD HH:mm:ss'),
            creator: el?.creator?.username,
          })),
        );
        formik.setFieldValue('name', data?.item?.name);
        formik.setFieldValue('shortcut', data?.item?.shortCRM);
        formik.setFieldValue('description', data?.item?.description);
        formik.setFieldValue('startDate', data?.item?.dateStart?.date);
        formik.setFieldValue('endDate', data?.item?.dateEnd?.date);
        formik.setFieldValue('team', data?.item?.team);
      }
      return [];
    };

    if (id) {
      fetchEditData();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await getCall(API_USERS_ROUTE);
      if (data?.items) {
        setUsers({
          ...users,
          data: data?.items.map((i) => ({
            ...i,
            name: i.email,
          })),
        });
      }
    };
    fetchUsers();
    
  }, []);

  const submitForm = async () => {
    const callTask = async (fn) => {
      const eObj = {};
      if (id) eObj.id = id;
      const r = await fn(MARKETING_ACTIONS_ROUTE, {
        ...eObj,
        name: formik.values.name,
        shortCRM: formik.values.shortcut,
        description: formik.values.description,
        dateStart: formik.values.startDate,
        dateEnd: formik.values.endDate,
        team: formik.values.team,
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla akcji marketingowej.`);
      }
    };
    if (id) {
      await callTask(putCall);
    } else {
      await callTask(postCall);
    }
  };

  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar />
        <ExpansionContainer title="Szczegóły">
          <ColumnWrapper fullWidth>
            <TextField
              label="Nazwa"
              variant="outlined"
              style={{ marginBottom: 20 }}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <TextField
              label="Skrót"
              variant="outlined"
              style={{ marginBottom: 20 }}
              name="shortcut"
              onChange={formik.handleChange}
              value={formik.values.shortcut}
            />
            <Autocomplete
              multiple
              options={users.data}
              variant="outlined"
              getOptionLabel={(option) => option.email || option?.name || ''}
              renderOption={(option) => option.value || option?.name}
              style={{ marginBottom: 20, width: '100%' }}
              onChange={(e, value) => formik.setFieldValue('team', value)}
              defaultValue={formik.values.team || []}
              value={formik.values.team || []}
              renderInput={(params) => <TextField {...params} label="Zespół" variant="outlined" />}
            />
            <TextField
              multiline
              rows={4}
              label="Opis"
              variant="outlined"
              style={{ marginBottom: 20 }}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <InputLabel shrink htmlFor="startDate">
              Data rozpoczęcia
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
              <KeyboardDateTimePicker
                variant="inline"
                name="startDate"
                format="dd/MM/yyyy HH:mm"
                inputVariant="outlined"
                value={formik.values.startDate}
                onChange={(d) => formik.setFieldValue('startDate', d)}
                ampm={false}
              />
            </MuiPickersUtilsProvider>
            <div style={{ marginTop: 20 }}></div>
            <InputLabel shrink htmlFor="endDate">
              Data zakończenia
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
              <KeyboardDateTimePicker
                variant="inline"
                name="endDate"
                format="dd/MM/yyyy HH:mm"
                inputVariant="outlined"
                value={formik.values.endDate}
                onChange={(d) => formik.setFieldValue('endDate', d)}
                ampm={false}
              />
            </MuiPickersUtilsProvider>
          </ColumnWrapper>
        </ExpansionContainer>
        <ExpansionContainer title="Pliki">
          <BasicTable
            headCells={[
              { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
              { id: 'description', numeric: false, disablePadding: false, label: 'opis' },
              {
                id: 'created',
                numeric: false,
                disablePadding: false,
                label: 'data utworzenia',
              },
              {
                id: 'creator',
                numeric: false,
                disablePadding: false,
                label: 'utworzono przez',
              },
            ]}
            rows={files}
            onChangePage={() => {}}
            recordsCount={files?.length}
            onRowClick={() => {}}
          />
        </ExpansionContainer>
      </ColumnWrapper>
      <FloatingSaveBar disabled={!formik.values.name} onClick={submitForm} />
    </>
  );
};

export default MarketingActionDetailsScreen;
