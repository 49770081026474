import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../../components/indicator/Indicator";
import BasicTable from "../../../components/tables/basic/BasicTable";
import { MARKETING_ACTIONS_ROUTE, MARKETING_TAGS_ROUTE } from '../../../constants/routes';
import routerHistory from "../../../constants/settings/routerHistory";
import MarketingChip from './../components/MarketingChip';
import MarketingTagsListToolBar from './list/MarketingTagsListToolBar';
import MarketingTagModal from './MarketingTagModal';

const MarketingTagsScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const fetchMarketingActions = async (url = MARKETING_TAGS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        id: item?.id,
        chip: (<MarketingChip tag={item} />),
        name: item?.name,
        campaignId: item?.campaignId,
        description: item?.description,
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  }; 

  useEffect(() => {
    fetchMarketingActions();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${MARKETING_TAGS_ROUTE}?query=${v}`);
      const r = data.items?.map((item) => ({
        id: item?.id,
        chip: (<MarketingChip tag={item} />),
        name: item?.name,
        campaignId: item?.campaignId,
        description: item?.description,
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleImport = async (url = MARKETING_TAGS_ROUTE + '/update') => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        id: item?.id,
        chip: (<MarketingChip tag={item} />),
        name: item?.name,
        campaignId: item?.campaignId,
        description: item?.description,
        createdOn: moment(item?.createdOn?.date).format('YYYY-MM-DD HH:mm:ss'),
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  };

  return (
    <>
      <MarketingTagsListToolBar handleSearch={handleSearch} handleImport={handleImport} />
      <BasicTable
        headCells={[
          { id: 'chip', numeric: false, disablePadding: false, label: ' ' },
          { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
          { id: 'campaignId', numeric: false, disablePadding: false, label: 'identyfikator' },
          { id: 'description', numeric: false, disablePadding: false, label: 'opis' },
          { id: 'createdOn', numeric: false, disablePadding: false, label: 'utworzono' },
        ]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchMarketingActions(`${MARKETING_TAGS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={(item) =>
          setSelectedTag(item)
        }
      />
      <MarketingTagModal tag={selectedTag} onClose={() => {
        setSelectedTag(null); 
        fetchMarketingActions();
      }} />
    </>
  );
};

export default MarketingTagsScreen;
