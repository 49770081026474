import React from 'react';
import TableToolBar from "./components/list/tree/TableToolBar";
import ProducerGroupTable from "./components/list/tree/ProducerGroupTable";

export default () => {

    return <>
        <TableToolBar />
        <ProducerGroupTable/>
    </>

};