import { makeStyles, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import Select from '../../../../components/dynamic-forms/form-components/Select';
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import FloatingSaveBar from "../../../../components/FloatingSaveBar";
import { useParams } from 'react-router-dom';
import { API_HEAT_EXCHANGER_ROUTE } from '../../../../constants/routes';
import { toast } from 'react-toastify';
import {
  postCall
} from "../../../../components/indicator/Indicator";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import routerHistory from "../../../../constants/settings/routerHistory";
import styled from 'styled-components';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { Add } from '@material-ui/icons';
const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'grid',
  },
  table: {
    minWidth: 750,
  },
  tableRightBorder : { borderWidth: 0, borderColor: 'red',borderStyle: 'solid'},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
}));

const HeatExchangerNewRow = ({projectId, onSave}) => {
  const [type, setType] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [primarySideMedium, setPrimarySideMedium] = useState(null);
  const [secondarySideMedium, setSecondarySideMedium] = useState(null);
  const [maximumPressureDrop, setMaximumPressureDrop] = useState(null);
  const [heatingPower, setHeatingPower] = useState(null);
  const [coolingPower, setCoolingPower] = useState(null);
  const [S1_temperatureDropInExchanger, setS1_TemperatureDropInExchanger] = useState(null);
  const [D1_deltaSupplyReturn, setD1_DeltaSupplyReturn] = useState(null);
  const [D2_deltaSupplyReturn, setD2_DeltaSupplyReturn] = useState(null);
  const [A1_primarySideTemperatureInput, setA1_PrimarySideTemperatureInput] = useState(null);
  const [B1_primarySideTemperatureInput, setB1_PrimarySideTemperatureInput] = useState(null);
  const [C1_primarySideTemperatureInput, setC1_PrimarySideTemperatureInput] = useState(null);

  const submitForm = async () => {
    const callExchanger = async (fn) => {
      const r = await fn(API_HEAT_EXCHANGER_ROUTE, {
        type: type,
        purpose: purpose,
        primarySideMedium: primarySideMedium,
        secondarySideMedium: secondarySideMedium,
        maximumPressureDrop: Number(maximumPressureDrop),
        heatingPower: Number(heatingPower),
        coolingPower: Number(coolingPower),
        S1_temperatureDropInExchanger: Number(S1_temperatureDropInExchanger),
        D1_deltaSupplyReturn: Number(D1_deltaSupplyReturn),
        D2_deltaSupplyReturn: Number(D2_deltaSupplyReturn),
        A1_primarySideTemperatureInput: Number(A1_primarySideTemperatureInput),
        B1_primarySideTemperatureInput: Number(B1_primarySideTemperatureInput),
        C1_primarySideTemperatureInput: Number(C1_primarySideTemperatureInput),
        project: Number(projectId),
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla wymiennika.`);
        console.log(r.data.item);
          if(onSave && r.data.item) onSave(r.data.item)
      }
    };

    await callExchanger(postCall);
    clearStates();
  };

  const clearStates = () => {
    setType(null);
    setPurpose(null);
    setPrimarySideMedium(null);
    setSecondarySideMedium(null);
    setMaximumPressureDrop(0);
    setHeatingPower(0);
    setCoolingPower(0);
    setS1_TemperatureDropInExchanger(null);
    setD1_DeltaSupplyReturn(0);
    setD2_DeltaSupplyReturn(0);
    setA1_PrimarySideTemperatureInput(0);
    setB1_PrimarySideTemperatureInput(0);
    setC1_PrimarySideTemperatureInput(0);
  }

  return (
      <TableRow style={{opacity: 0.6}}>
        <TableCell padding="none" >
            <Select
            wrapperStyle={{padding: 0}}
              data={[
                { id: 'płytowy skręcany', name: 'płytowy skręcany' },
                { id: 'lutowany', name: 'lutowany' },
              ]}
              onSelect={(value) => setType(value)}
              value={type}
            />
            </TableCell>
            <TableCell padding="none"  >
            <Select
            wrapperStyle={{padding: 0}}
              data={[
                { id: 'c.w.u.', name: 'c.w.u.' },
                { id: 'c.o.', name: 'c.o.' },
                { id: 'c.o./chłód', name: 'c.o./chłód' },
                { id: 'chłód', name: 'chłód' },
                { id: 'basen', name: 'basen' },
              ]}
              onSelect={(value) => setPurpose(value)}
              value={purpose}
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={maximumPressureDrop}
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setMaximumPressureDrop(value)
              }
            />
            </TableCell>
            <TableCell padding="none" >
            <Select
            wrapperStyle={{padding: 0}}
              data={[
                { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
                { id: 'woda', name: 'woda' },
                { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
              ]}
              onSelect={(value) => setPrimarySideMedium(value)}
              value={primarySideMedium}
            />
            </TableCell>
            <TableCell padding="none" >
            <Select
            wrapperStyle={{padding: 0}}
              data={[
                { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
                { id: 'woda', name: 'woda' },
                { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
              ]}
              onSelect={(value) => setSecondarySideMedium(value)}
              value={secondarySideMedium}
            />
            </TableCell>
            <TableCell padding="none" >
            <Select
            wrapperStyle={{padding: 0}}
              data={[
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
                { id: '5', name: '5' },
              ]}
              onSelect={(value) => setS1_TemperatureDropInExchanger(value)}
              value={S1_temperatureDropInExchanger}
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={heatingPower}
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setHeatingPower(value)
              }
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={D1_deltaSupplyReturn}
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setD1_DeltaSupplyReturn(value)
              }
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={A1_primarySideTemperatureInput}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setA1_PrimarySideTemperatureInput(value)
              }
            />
            </TableCell>

            <TableCell>{A1_primarySideTemperatureInput ? (A1_primarySideTemperatureInput - D1_deltaSupplyReturn) : '-'}</TableCell>
            <TableCell>{ A1_primarySideTemperatureInput ? (A1_primarySideTemperatureInput - S1_temperatureDropInExchanger) : '-'}</TableCell>
            <TableCell>{A1_primarySideTemperatureInput ? (A1_primarySideTemperatureInput - S1_temperatureDropInExchanger - D1_deltaSupplyReturn) : '-'}</TableCell>
            
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={B1_primarySideTemperatureInput}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setB1_PrimarySideTemperatureInput(value)
              }
            />
            </TableCell>

            <TableCell>{ B1_primarySideTemperatureInput ? (B1_primarySideTemperatureInput - D1_deltaSupplyReturn) : '-'}</TableCell>
            <TableCell>{ B1_primarySideTemperatureInput ? (B1_primarySideTemperatureInput - S1_temperatureDropInExchanger) : '-'}</TableCell>
            <TableCell>{ B1_primarySideTemperatureInput ? (B1_primarySideTemperatureInput - S1_temperatureDropInExchanger - D1_deltaSupplyReturn) : '-'}</TableCell>

            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={coolingPower}
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setCoolingPower(value)
              }
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={D2_deltaSupplyReturn}
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setD2_DeltaSupplyReturn(value)
              }
            />
            </TableCell>
            <TableCell padding="none" >
            <TextField
              variant="outlined"
              value={C1_primarySideTemperatureInput}
              type={'number'}
              style={{ margin: 0 }}
              onChange={({ target: { value } }) =>
                setC1_PrimarySideTemperatureInput(value)
              }
            />
            </TableCell>
            
            <TableCell>{ C1_primarySideTemperatureInput ? (C1_primarySideTemperatureInput - D2_deltaSupplyReturn) : '-'}</TableCell>
            <TableCell>{ C1_primarySideTemperatureInput ? (coolingPower - S1_temperatureDropInExchanger) : '-'}</TableCell>
            <TableCell>{ C1_primarySideTemperatureInput ? (coolingPower - D2_deltaSupplyReturn) : '-'}</TableCell>

            <TableCell padding="none" >
            
              <PrimaryButton onClick={submitForm}><Add /></PrimaryButton>
            </TableCell>
          </TableRow>
  );
};

export default HeatExchangerNewRow;
