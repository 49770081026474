import { Button, Collapse } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import MenuIcon from '@material-ui/icons/Menu';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from "../../assets/logo/gazuno_logo.svg";
import mediaQueries from "../../constants/mediaQueries";
import menuItems from "../../constants/menu/menuItems";
import ColumnWrapper from '../wrappers/ColumnWrapper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import UserImage from '../user-image/UserImage';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const SideMenu = ({ children }) => {


  const [showText, setShowText] = useState(false);

  const drawerWidth= useMemo(() => !showText ? 50 : 200,[showText]);

  const StyledMobileMenu = styled(ColumnWrapper)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  transition: 0.25s ease-in-out all;
  transform: ${(props) =>
      props.isMobileMenuHidden ? 'translateX(-100vw)' : 'translateX(0)'};
  opacity: ${(props) => (props.isMobileMenuHidden ? '0' : '1')};
`;

  const CloseButton = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`;

  const OpenButton = styled.div`
  padding: 24px 12px;
  transition: 0.45s ease-in-out opacity;
  opacity: ${(props) => (props.isMobileMenuHidden ? '1' : '0')};
`;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      borderRight: '1px solid rgba(0, 0, 0, 0.04)'
    },
    toolbar: theme.mixins.toolbar,
    avatarWrapper: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      padding: '10px',
    },
    avatarText: {
      margin: '0 10px',
    },
    logoStyle: {
      margin: '0px auto 40px',
      maxWidth: '150px',
    },
    content: {
      flexGrow: 1,
      padding: '20px 10px',
    },
  }));

  const mql = window.matchMedia(mediaQueries.sm);

  const styles = useStyles();
  const [list, setList] = useState(menuItems);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(mql.matches);
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(true);
  const [selectedId, setSelectedId] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const filteredList = list.map((item) => {
      let isOpen = false;
      if (item.subRoutes)
        item.subRoutes.map((i) => {
          if (location.pathname.includes(i.routeName)) {
            setIsCollapsed(false);
            isOpen = true;
          }
          return i;
        });
      return { ...item, isOpen };
    });
    setList(filteredList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const listenerFunc = (e) => {
      setIsMobile(e?.matches);
    };
    mql.addListener(listenerFunc);
  }, []);

  useEffect(() => {
    if(showText) {
      selectedId && handleCollapse(selectedId)
    } else{
      closeAllLists()
    }
  }, [showText]);

  const [user] = useState({
    name: 'Użytkownik',
    image: '',
  });

  const localState = useStoreState((state) => state.auth?.loggedInUser);

  useEffect(() => {
    const isAdmin = localState?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (!isAdmin) setList(list.filter((i) => i.id !== 11));
    else setList(menuItems);
    // eslint-disable-next-line
  }, [localState]);

  const { drawer, drawerPaper, toolbar, logoStyle, content } = useStyles();

  const closeAllLists = () => {
    if (!isCollapsed) {
      const filteredList = list.map((item) => {
        return { ...item, isOpen: false };
      });
      setList(filteredList);
    }
  };

  const handleCollapse = (id) => {
    setSelectedId(id);
    const filteredList = list.map((item) => {
      if (item.id === id) {
        return { ...item, isOpen: !item.isOpen };
      }
      return item;
    });
    setList(filteredList);
  };

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      {!(location.pathname === '/') ? (
        <>
          {!isMobile ? (
            <>
              <Drawer
                className={drawer}
                variant="permanent"
                classes={{
                  paper: drawerPaper,
                }}
                anchor="left">
                <div className={toolbar} />
                {showText && <img className={logoStyle} src={logo} alt="logo" />}
                <ListItem>
                  <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      style={{marginTop: showText ? 0 : 102}}
                      sx={{ mr: 2 }}
                      onClick={() => setShowText(!showText)}
                  >
                    <MenuIcon />
                  </IconButton>
                </ListItem>
                <ListItem
                  dense={true}
                  activeStyle={{
                    background: 'rgba(187, 187, 187, 0.12)',
                  }}
                  button
                  component={RouterLink}
                  to={'/main'}
                  onClick={() => {closeAllLists(); setSelectedId(null)}}>
                  <ListItemIcon>
                    <UserImage src={localState?.image || null} name={localState?.username} size={22} />
                  </ListItemIcon>
                  {showText && <ListItemText primary={'Mój profil'} />}
                </ListItem>
                
                <List dense={true}>
                  {list.map(
                    ({ id, icon, label, routeName, subRoutes, isOpen }) => (
                      <Fragment key={id}>
                        <Tooltip disableHoverListener={showText} title={label} placement="right">
                        <ListItem
                          activeStyle={{
                            background: 'rgba(187, 187, 187, 0.12)',
                          }}
                          button
                          component={RouterLink}
                          to={routeName}
                          onClick={() => {
                            if(subRoutes) {
                              setShowText(true);
                              setTimeout(() => handleCollapse(id),500);
                            } else {
                              closeAllLists();
                              setSelectedId(null)
                            }
                          }

                          }>
                          <ListItemIcon>{icon()}</ListItemIcon>
                          {showText && <ListItemText primary={label} />}
                        </ListItem>
                        </Tooltip>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding style={{marginLeft: '20px'}}>
                            <ColumnWrapper>
                              {subRoutes
                                ? subRoutes.map((subRoute) => (
                                    <ListItem
                                      activeStyle={{
                                        fontWeight: '700 !important',
                                      }}
                                      button
                                      component={RouterLink}
                                      to={subRoute.routeName}
                                      key={subRoute.routeName}>
                                      <Typography variant="body2">
                                        {subRoute.label}
                                      </Typography>
                                    </ListItem>
                                  ))
                                : null}
                            </ColumnWrapper>
                          </List>
                        </Collapse>
                      </Fragment>
                    ),
                  )}
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <OpenButton isMobileMenuHidden={isMobileMenuHidden}>
                <Button onClick={() => setIsMobileMenuHidden(false)}>
                  <MenuIcon />
                </Button>
              </OpenButton>
              <StyledMobileMenu isMobileMenuHidden={isMobileMenuHidden}>
                <CloseButton>
                  <Button onClick={() => setIsMobileMenuHidden(true)}>
                    <CloseIcon />
                  </Button>
                </CloseButton>
                <ListItem
                  style={{ marginTop: 80 }}
                  activeStyle={{
                    background: '#e2e2e2',
                  }}
                  button
                  component={RouterLink}
                  to={'/main'}
                  onClick={() => closeAllLists()}>
                  <ListItemIcon>
                    <Avatar alt="User image" src={user.image} />
                  </ListItemIcon>
                  <ListItemText primary={'Mój profil'} />
                </ListItem>
                <Divider />
                <List style={{ width: '100%' }}>
                  {list.map(
                    ({ id, icon, label, routeName, subRoutes, isOpen }) => (
                      <Fragment key={id}>
                        <ListItem
                          activeStyle={{
                            background: '#e2e2e2',
                          }}
                          button
                          component={RouterLink}
                          to={routeName}
                          onClick={() =>
                            subRoutes ? handleCollapse(id) : closeAllLists()
                          }>
                          <ListItemIcon>{icon()}</ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ColumnWrapper>
                              {subRoutes
                                ? subRoutes.map((subRoute) => (
                                    <ListItem
                                      activeStyle={{
                                        background: '#b1b1b1',
                                      }}
                                      button
                                      component={RouterLink}
                                      to={subRoute.routeName}
                                      key={subRoute.routeName}>
                                      <Typography variant="body2">
                                        {subRoute.label}
                                      </Typography>
                                    </ListItem>
                                  ))
                                : null}
                            </ColumnWrapper>
                          </List>
                        </Collapse>
                      </Fragment>
                    ),
                  )}
                </List>
              </StyledMobileMenu>
            </>
          )}
        </>
      ) : null}
      <main style={{maxWidth: `calc(100% - ${showText ? 200 : 50}px)`}} className={content}>{children}</main>
    </div>
  );
};

export default SideMenu;
