import { Button, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../components/dynamic-forms/DynamicForm";
import { getCall } from '../../../../components/indicator/Indicator';
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { API_OFFERS_ROUTE, API_PROJECT_DEVICES_ROUTE, API_USERS_ROUTE, API_USER_PROFILE_ROUTE } from '../../../../constants/routes';
import {serviceOffer} from './offer-service';

const AdvancedOfferService = forwardRef(({commonOffersFormRef, submitForm, ...props}, ref) => {

  
  const formRef = useRef();

  const turnToRows = (data, variant) => {
    return (data.map(item => {
        let koszt = 0;

        if(variant == 1) {
          koszt = item.amount * (item.info?.koszt1 || 0);
        }
        if(variant == 2) {
          koszt = item.amount * (item.info?.koszt2 || 0);

        }
        if(variant == 3) {
          koszt = item.amount * (item.info?.koszt5 || 0);
        }

        return ({
          producent: item.info?.producent?.name,
          rodzaj: item.info?.rodzaj?.name,
          urzadzenie: item.info?.urzadzenie,
          ilosc: item.amount,
          koszt, 
          uuid: Math.random().toString(36).substring(7)
        });
      }
    ))
  }

  const countDodatek = (data) => {
    const ilosc = parseFloat(data['Ilość urządzeń']?.replace(/,/g, '.')) || 0;

    if(ilosc >= 5 && ilosc <= 10) {
      ref.current.setFormValue('Koszty dodatkowe', data['dodatek1'] || 0);

    } else if(ilosc >= 11 && ilosc <= 15) {
      ref.current.setFormValue('Koszty dodatkowe', data['dodatek2'] || 0);

    } else if(ilosc >= 16) {
      ref.current.setFormValue('Koszty dodatkowe', data['dodatek3'] || 0);

    }
  }

  const countOverall = (data) => {
    const ilosc1 = parseFloat(data['Koszty czynności przeglądowych']?.toString()?.replace(/,/g, '.')) || 0;
    const ilosc2 = parseFloat(data['Koszty wymiany elementów eksploatacyjnych']?.toString()?.replace(/,/g, '.')) || 0;
    const ilosc3 = parseFloat(data['koszt dojazdu']?.toString()?.replace(/,/g, '.')) || 0;
    const ilosc4 = parseFloat(data['Koszty dodatkowe']?.toString()?.replace(/,/g, '.')) || 0;
    ref.current.setFormValue('Koszt całościowy', (ilosc1+ilosc2+ilosc3+ilosc4).toString() || '0');

  }

  const generateForCustomer = () => {
    submitForm(API_OFFERS_ROUTE + `/${props?.id}/render?type=customer`, true)
  }

  const generateForService = () => {
    submitForm(API_OFFERS_ROUTE + `/${props?.id}/render?type=service`, true)
  }

  const onChangeMiddleware = async (f, v) => {
    const newData = {...ref.current.getFormValues()};

    newData[f] = v;

    switch(f) {
      case 'Przedstawiciel': 

        if(v?.id) {
          const { data } = await getCall(API_USERS_ROUTE + '/profile-contact/' + v?.id);

          if(data && data.phone) {
            ref.current.setFormValue('Telefon', data.phone);
          }

        }

      
        break;
      case 'ilość km':
      case 'w jedną/dwie strony':
      case 'cena za 1km':

        let countCost = 0;

        const iloscKm = parseFloat(newData['ilość km']?.replace(/,/g, '.')) || 0;
        const sides = parseFloat(newData['w jedną/dwie strony']?.replace(/,/g, '.')) || 1;
        const perKm = parseFloat(newData['cena za 1km']?.replace(/,/g, '.')) || 0;

        if(iloscKm <= 25) {
          countCost = 0;
        } else if(iloscKm > 100) {
          if(perKm === 1.2) {
            countCost = 560;
          } else {
            countCost = 860;
          }
        } else {
          countCost = Math.ceil(iloscKm * sides * perKm / 10)*10;
        }

        ref.current.setFormValue('koszt dojazdu', countCost.toString() );
        break;
      case 'devices':

        const currentRows = JSON.parse(v);

        ref.current.setFormValue('Ilość urządzeń', currentRows.length.toString() );
        ref.current.setFormValue('Koszt czynności serwisowych', currentRows.reduce((prev, curr) => prev ? prev + curr.koszt : curr?.koszt, 0).toString() );
        break;
      case 'Ilość urządzeń':
      case 'Średni kurs €':
        const ilosc = parseFloat(newData['Ilość urządzeń']?.toString()?.replace(/,/g, '.')) || 0;
        const kurs = parseFloat(newData['Średni kurs €']?.toString()?.replace(/,/g, '.')) || 1;


        if(f === 'Ilość urządzeń') {
          countDodatek(newData);

          if(ilosc > 5) {
            
            ref.current.setFormValue('Koszty dodatkowe opis', '** Koszty dodatkowe wynikają z konieczności oddelegowania kolejnych serwisantów w ramach realizacji przeglądu dużej liczby urządzeń. ');
          } else {
            
            ref.current.setFormValue('Koszty dodatkowe opis', '** Koszty dodatkowe nie zostały naliczone. ');
            
          }
        }
        
        ref.current.setFormValue('Koszty wymiany elementów eksploatacyjnych', Math.ceil(ilosc * kurs).toString() );
        
        break;
      case 'Wariant przeglądu':
        if(v ===1) {
          ref.current.setFormValue('Koszty wymiany elementów eksploatacyjnych opis', '* W ofercie uwzględniono koszty wymiany podstawowych elementów eksploatacyjnych według zaleceń producenta dla określonych interwałów przeglądowych (np. uszczelki, itp.)\r\nW przypadku konieczności wymiany części eksploatacyjnych, których zużycie nie zostało przewidziane przez producenta dla określonych interwałów przeglądowych, serwisant zobowiązany jest poinformować o tym fakcie klienta i przedstawić szacunkowy koszt wymiany elementów nieuwzględnionych w ofercie.');

        } else {
          ref.current.setFormValue('Koszty wymiany elementów eksploatacyjnych opis', 'W ofercie nie uwzględniono kosztów wymiany elementów eksploatacyjnych (np. uszczelki, elektrody, pasy napędowe i koła pasowe, itp.)\r\nW przypadku konieczności wymiany części eksploatacyjnych, których zużycie nie zostało przewidziane przez producenta dla określonych interwałów przeglądowych, serwisant zobowiązany jest poinformować o tym fakcie klienta i przedstawić szacunkowy koszt wymiany elementów nieuwzględnionych w ofercie.');

        }

        break;
      case 'dodatek1':
      case 'dodatek2':
      case 'dodatek3':
        countDodatek(newData);

        break;
      case 'Koszty czynności przeglądowych':
      case 'koszt dojazdu':
      case 'Koszty wymiany elementów eksploatacyjnych':
      case 'Koszty dodatkowe':
        countOverall(newData);

        break;
      case 'Pobierz urządzenia': 
      const formVals = commonOffersFormRef?.current?.getFormValues();

        if(formVals?.project?.id) {
          const { data } = await getCall(`${API_PROJECT_DEVICES_ROUTE(formVals?.project?.id)}`);
          if (data) {
            const rows = [];
            const variant = newData['Wariant przeglądu'];

            const newRows = turnToRows(data, variant);
            
            ref.current.setFormValue('devices', JSON.stringify(newRows));

            ref.current.setFormValue('Ilość urządzeń', newRows.length);
            ref.current.setFormValue('Koszt czynności serwisowych', newRows.reduce((prev, curr) => prev ? prev + curr.koszt : curr?.koszt, 0));
          }

        }
        break;
    }
  }

  return (
    <>
      <RowWrapper fullWidth>
        <ColumnWrapper fullWidth>
          <DynamicForm initialValues={props?.initialValues} fields={serviceOffer} ref={ref} small={true} avoidTabs={true} onChangeMiddleware={onChangeMiddleware} />
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper fullWidth>
        <ColumnWrapper width="20%">
          <PrimaryButton onClick={() => generateForCustomer()}>Generuj ofertę dla klienta</PrimaryButton>
        </ColumnWrapper>
        <ColumnWrapper width="20%">
          <PrimaryButton onClick={() => generateForService()}>Generuj ofertę dla serwisanta</PrimaryButton>
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper fullWidth style={{padding: '30px'}}>
        <ColumnWrapper width="20%">
          <Divider />
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
});

AdvancedOfferService.propTypes = {
};

export default AdvancedOfferService;
