import {TextField} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  API_COMPANIES_ROUTE,
  API_COMPANIES_ROWS_GROUP_ROUTE,
  API_NOTES_ROUTE,
} from '../../../../../constants/routes';
import routerHistory from "../../../../../constants/settings/routerHistory";
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import { postCall, putCall } from "../../../../../components/indicator/Indicator";
import BasicTable from "../../../../../components/tables/basic/BasicTable";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import FloatingSaveBar from "../../../../../components/FloatingSaveBar";
import { projectsHeadCells } from './headCells';
import { formToRequest, responseToForm } from '../../../../../components/dynamic-forms/field-transformer';
import TaskDetailsForm from '../../../../tasks/components/details/TaskDetailsForm';
import TaskTick from '../../../../../components/task-tick/TaskTick';
import Select from "../../../../../components/dynamic-forms/form-components/Select";
import CheckIcon from "@material-ui/icons/Check";
import {green, red} from "@material-ui/core/colors";
import ClearIcon from "@material-ui/icons/Clear";

const StyledRowWrapper = styled(RowWrapper)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 60px;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

const StyledColumnWrapper = styled(ColumnWrapper)`
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

const BasicData = ({ companyId, data, fields, edit, fetchData, fetchNotes }) => {
  const formRef = useRef();
  const [projects, setProjects] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([0]);
  const [tasks, setTasks] = useState([]);
  const [projectsCount, setProjectsCount] = useState(0);
  const [tasksCount, setTasksCount] = useState(0);

  const [company, setCompany] = useState({ name: '', status: '' });

  const [formInitialValues, setFormInitial] = useState(null);

  const selectedTasks = useMemo(() => tasks.filter(({status}) => taskStatuses.includes(status)),[tasks, taskStatuses]);

  useEffect(() => {
    if(data?.fields) {
      setFormInitial(responseToForm(data?.fields));
    }
    setCompany({ name: data.name });
    if (data?.projects) {
      setProjects(
        data?.projects?.map((i) => ({
          id: i?.id,
          name: i?.name,
          status: i?.status,
        })),
      );
      setProjectsCount(data?.projects?.length);
    }

    if (data?.tasks) {
      setTasks(
        data?.tasks?.map((i) => ({
          id: i.id,
          done: (<TaskTick id={i.id} status={i?.status} waitOnFeedback={!!i?.waitOnFeedback} feedbackComment={i?.feedbackComment} />),
          desc: (<u onClick={() => routerHistory.push(`/tasks/edit/${i.id}`)}>{i?.description}</u>),
          isFeedback: i?.isFeedback === null ? '-' : (i?.isFeedback ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />),
          start_date: (i?.startDate ? moment(i?.startDate?.date).format('YYYY-MM-DD') : '-'),
          deadline: (i?.deadline ? moment(i?.deadline?.date).format('YYYY-MM-DD') : '-'),
          project: i?.project?.name,
          for: i?.for?.username,
          principal: i?.principal?.username,
          type: i?.type,
          updated_at: (i?.updatedAt ? moment(i?.updatedAt?.date).format('YYYY-MM-DD HH:mm') : '-'),
          status: i?.status,
        })),
      );
      setTasksCount(data?.tasks?.length);
    }
  }, [data]);

  const companesTasksHeadCells = [
    { id: 'done', numeric: false, disablePadding: false, label: ' ' },
    { id: 'desc', numeric: false, disablePadding: false, label: 'zadanie' },
    { id: 'isFeedback', numeric: false, disablePadding: false, label: 'Feedback' },
    { id: 'start_date', numeric: false, disablePadding: false, label: 'rozpoczęcie' },
    { id: 'deadline', numeric: false, disablePadding: false, label: 'deadline' },
    { id: 'project', numeric: false, disablePadding: false, label: 'projekt' },
    { id: 'for', numeric: false, disablePadding: false, label: 'przypisano do' },
    { id: 'principal', numeric: false, disablePadding: false, label: 'przypisane przez' },
    { id: 'type', numeric: false, disablePadding: false, label: 'typ' },
    { id: 'updatedAt', numeric: false, disablePadding: false, label: 'data zmiany statusu' },
  ];

  const fetchTasks = () => {
    fetchData();
  }

  const currentRowGroupsUrl = data.id
    ? API_COMPANIES_ROWS_GROUP_ROUTE(data.id)
    : null;

  const submitForm = async () => {
    const formValues = formRef.current.getFormValues();
    
    const callCompany = async (fn) => {
      const eObj = {};
      if (edit) eObj.id = data.id;
      const r = await fn(API_COMPANIES_ROUTE, {
        ...eObj,
        name: company.name,
        status: company.status,
        fields: formToRequest(formValues)
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla firmy ${company.name}`);
      }
    };

    if (edit) {
      await callCompany(putCall);
    } else {
      await callCompany(postCall);
    }
  };

  const addNote = async (message, value) => {
    const { data } = await postCall(API_NOTES_ROUTE, {
      body: message,
      company_id: companyId,
      created: moment(value).format('YYYY-MM-DD HH:mm:ss'),
    });
    if (data) {
      fetchNotes();
      toast.success('Dodano notatkę.');
    }
  }

  const onChange = (f, v, clickButton)  => {
      if(clickButton) {
          switch(f) {
              case 'ostatni_tel':
                  addNote('Ostatni kontakt - telefon', v);
                  break;
              case 'ostatni_mail':
                  addNote('Ostatni kontakt - mail', v);
                  break;
              case 'ostatni_spotkanie':
                  addNote('Ostatni kontakt - spotkanie', v);
                  break;
              default:
                  break;
          }
      }
  }

  return (
    <>
      <ExpansionContainer title="Dane podstawowe">
        <ColumnWrapper width="100%">
            <TextField
              label="Nazwa firmy"
              variant="outlined"
              value={company.name  || ''}
              style={{ marginBottom: 20 }}
              onChange={({ target }) =>
                setCompany({ ...company, name: target.value })
              }
            />
          </ColumnWrapper>
            
          <ColumnWrapper width="100%">
          <DynamicForm
        ref={formRef}
        fields={fields}
        initialValues={formInitialValues}
        avoidTabs={true}
        currentRowGroupsUrl={currentRowGroupsUrl}
        onChangeMiddleware={onChange}
      />
        </ColumnWrapper>
      </ExpansionContainer>
      
      <StyledRowWrapper  style={{
          flexWrap: 'wrap'
        }}>
        {companyId && <><StyledColumnWrapper style={{width: '100%', minWidth: '400px'}}>
          <ExpansionContainer
            style={{ height: '100%' }}
            childWrapperStyle={{ overflowX: 'auto' }}
            title="Zadania">
            <ColumnWrapper width="100%">
              <TaskDetailsForm
                companyId={companyId}
                onSuccess={async () => {
                  const saved = await submitForm();
                  fetchTasks();
                } }
              />
            </ColumnWrapper>
            <ColumnWrapper width="30%">
              <Select
                  multiple
                  fullWidth
                  size="small"
                  variant="outlined"
                  label={"Status"}
                  value={taskStatuses}
                  data={[
                    {id: 0, name: 'Do zrobienia'},
                    {id: 1, name: 'Zrobione'},
                    {id: 2, name: 'Anulowane'},
                    {id: 3, name: 'Odrzucone'},
                  ]}
                  onSelect={(option) => setTaskStatuses(option || [])
                  }
              />
            </ColumnWrapper>
            <ColumnWrapper width="100%">
              <BasicTable
                minimal={true}
                headCells={companesTasksHeadCells}
                rows={selectedTasks}
                hideKeys={['status']}
                onChangePage={() => {}}
                recordsCount={tasksCount}
              />
            </ColumnWrapper>
          </ExpansionContainer>
        </StyledColumnWrapper>
        <StyledColumnWrapper style={{ width: '100%', minWidth: '400px' }}>
          <ExpansionContainer
            style={{ height: '100%' }}
            childWrapperStyle={{ overflowX: 'auto' }}
            title="Projekty">
            <ColumnWrapper fullWidth>
              <BasicTable
                headCells={projectsHeadCells}
                rows={projects}
                onRowClick={(p) =>
                  routerHistory.push(`/projects/edit/${p?.id}`)
                }
                onChangePage={() => {}}
                recordsCount={projectsCount}
              />
            </ColumnWrapper>
          </ExpansionContainer>
        </StyledColumnWrapper></>}
      </StyledRowWrapper>
      <FloatingSaveBar disabled={!company.name} onClick={submitForm} />
    </>
  );
};

export default BasicData;
