import React, {useEffect, useMemo, useState} from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { getCall } from "../../../../../../components/indicator/Indicator";
import { API_PRODUCTS_ROUTE, API_PRODUCT_CATEGORIES_ROUTE, API_PRODUCT_PRODUCER_ROUTE } from '../../../../../../constants/routes';
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import returnSpecificField from "../../../../../../components/dynamic-forms/returnSpecificField";
import truncate from "../../../../../../tools/truncate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em',
    marginBottom: '1em'
  }
}));

const OfferPeripheralDevicesZone = ({setRootRows, initRows = []}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [rows, setRows] = useState(
      initRows.map(row => ({
            ...row,
            uuid: row?.id,
            producer: row?.product?.product_producer?.id,
            category: row?.product?.product_category?.id,
            totalPrice: Number((Number(row?.amount) * Number(row?.product?.net_list_price)).toFixed(2)) || 0,
          })
      ));

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };

  const getUniqueCategories = (data) => {
    const categories = data.map(item => item.product_category)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setCategories(categories);
  }

  const getUniqueProducers = (data) => {
    const producers = data.map(item => item.product_producer)
        .filter((value, index, self) => self.map(item=>item.id).indexOf(value.id) === index);

    setProducers(producers);
  }

  const selectedProducts = useMemo(() =>
          rows.map(({product} = {}) => product || {})
      ,[rows]);

  const availableProducts = useMemo(() =>
          products.filter(({id}) => !selectedProducts.find(({id: searchesId}) => searchesId === id))
      ,[products, selectedProducts]);

  const relatedProducts = useMemo(() => {
      const allRelated = selectedProducts
          .reduce((all, {products: parts = []}) =>
                  [...all || [], ...parts]
              , [])
          .filter(({id}) =>
              ![...selectedProducts].find(({id: searchesId}) => searchesId === id)
          );

      return [...new Set(allRelated.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
  }, [selectedProducts]);

  const fetchDevices = async () => {

    try {
      const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
      if (data) {
        setProducts(data.items);
        getUniqueCategories(data.items);
        getUniqueProducers(data.items);
      }
    } catch {
      toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
    }

  };

  useEffect(() => {
    fetchDevices().then(() => setLoading(false))
  }, []);

  useEffect(() => {
    setRootRows(
        rows.map(({id, amount, product}) => (({id, amount, product})))
            .filter(({id, amount, product}) => amount && product)
    )
  }, [rows]);

  const addNewRow = (newRow) => {
    setRows([...rows, Object.assign({
      id: null,
      producer: null,
      category: null,
      product: null,
      amount: 1,
      totalPrice: 0,
      uuid: Math.random().toString(36).substring(7)
    }, newRow)]);
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    if(i >= 0) {
      localRows[i][name] = v;
      if(['producer'].includes(name)) {
        localRows[i]['category'] = null;
      }
      if(['producer', 'category'].includes(name)) {
        localRows[i]['product'] = null;
        localRows[i].totalPrice = 0;
      }
      if(['amount', 'product'].includes(name)) {
        localRows[i].totalPrice = localRows[i]?.product ? calculateRowTotalPrice(localRows[i]) : 0;
      }
    }
    setRows(localRows);
  };

  const calculateRowTotalPrice = ({product: {net_list_price = 0} = {}, amount}) => {
    if(net_list_price) {
      const price = Number(net_list_price) * Number(amount);
      return Number((price).toFixed(2)) || 0;
    }
    return 0;
  }

  const renderHeader = () => (
      <TableHead>
        <TableRow>
          <TableCell>Producent</TableCell>
          <TableCell>Kategoria</TableCell>
          <TableCell>Urządzenie</TableCell>
          <TableCell>Indeks</TableCell>
          <TableCell>Opis</TableCell>
          <TableCell>Ilość</TableCell>
          <TableCell>Cena katalogowa netto</TableCell>
          <TableCell>Wartość katalogowa netto</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
  );

  return (
      <>
        <ColumnWrapper fullWidth>
          <h3 style={{marginBottom: '30px'}}>Urządzenia</h3>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table">
            {renderHeader()}
            <TableBody>
              {!loading && rows.map(item =>
                  <PeripheralDevicesZoneRow
                      i={item}
                      index={item.uuid}
                      data={{
                          producers,
                          categories,
                          products: item?.product?.id ? [...availableProducts, item.product] : availableProducts
                      }}
                      key={item.uuid}
                      editRow={editRow}
                      removeRow={removeRow} />)}
              <TableRow>
                <TableCell colSpan="100%">
                  {!loading && <Button className={classes.add} variant="contained" onClick={(e) => addNewRow()}>
                    <AddIcon /> Dodaj kolejne urządzenie
                  </Button>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="7"/>
                <TableCell colSpan="1">
                  Suma: {rows.reduce((n, {totalPrice}) => n + totalPrice, 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="100%">
                  Produkty powiązane:
                </TableCell>
              </TableRow>
              {!loading && relatedProducts.map((item, i) => <PeripheralRelatedDevicesZoneRow i={item} addRow={addNewRow} />)}
            </TableBody>
          </Table>
        </ColumnWrapper>
    </>
  );
};

const PeripheralDevicesZoneRow = ({i, index, data, editRow, removeRow, ...props}) => {
  const [producers, setProducers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  /**
   * Initial producers fetch
   */
  useEffect(() => {
    fetchProducers();
  }, []);

  /**
   * Refetch categories whenever producer changes
   */
  useEffect(() => {
    if (i.producer) {
      fetchCategories();
    }
  }, [i.producer]);

  /**
   * Refetch products whenever category changes
   */
  useEffect(() => {
    if (i.category) {
      fetchProducts();
    }
  }, [i.category]);

  const fetchProducers = () => {
    // type=2 means groups='peryferia'
    getCall(`${API_PRODUCT_PRODUCER_ROUTE}?type=2`)
        .then(({data: {items = []} = {}}) => setProducers(items));
  }

  const fetchCategories = () => {
    getCall(`${API_PRODUCT_CATEGORIES_ROUTE}?producer=${i.producer}`)
        .then(({data: {items = []} = {}}) => setCategories(items));
  }

  const fetchProducts = async () => {
    const { data } = await getCall(`${API_PRODUCTS_ROUTE}?product_category=${i.category}`);
    if (data) {
        setProducts(data.items);
    }
};

  const findProductAsObject = (searchedId) => {
    const res = products.find(({ id }) => id === searchedId);
    return res;
  }

    return (
      <TableRow key={i.uuid+'row'}>
        <TableCell>
          {returnSpecificField('dictionary', {data: producers}, 'producer', i.producer, (e, v) => {editRow(e, v, index);} )}
        </TableCell>
        <TableCell>
          {returnSpecificField('dictionary', {data: categories, disabled: !i.producer}, 'category', i.category, (e, v) => {editRow(e, v, index); } )}
        </TableCell>
        <TableCell>
          {returnSpecificField('dictionary', {data: products, disabled: !i.category}, 'product', i.product?.id || '', (e, v) => {editRow(e, findProductAsObject(v), index);} )}
        </TableCell>
        <TableCell>
          b/d
        </TableCell>
        <TableCell title={i.product?.description || ''}>
          {truncate(i.product?.description || '-')}
        </TableCell>
        <TableCell>
          {returnSpecificField('text', {}, 'amount', i.amount, (e, v) => {editRow(e, v, index)} )}
        </TableCell>
        <TableCell>
          {i.product?.net_list_price || 0}
        </TableCell>
        <TableCell>
          {i.totalPrice || 0}
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={(e) => removeRow(index)}>
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
  );
}

const PeripheralRelatedDevicesZoneRow = ({i, addRow, ...props}) => {
  return (
      <TableRow key={i.id+'row'}>
        <TableCell>
          {i.product_producer?.name || '-' }
        </TableCell>
        <TableCell>
          {i.product_category?.name || '-' }
        </TableCell>
        <TableCell>
          {i.name || '-' }
        </TableCell>
        <TableCell>
          b/d
        </TableCell>
        <TableCell title={i?.description || ''}>
          {truncate(i?.description || '-')}
        </TableCell>
        <TableCell>
          -
        </TableCell>
        <TableCell>
          {i?.net_list_price || 0}
        </TableCell>
        <TableCell>
          -
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => addRow({
            product: i,
            producer: i.product_producer?.id,
            category: i.product_category?.id,
            totalPrice: i.net_list_price
          })}>
            <AddShoppingCartIcon />
          </Button>
        </TableCell>
      </TableRow>
  );
}

export default OfferPeripheralDevicesZone;
