import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const ExchangersHeader = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  let headCells = [
    {
      id: 'type',
      label: '[-]',
    },
    {
      id: 'purpose',
      label: '[-]',
    },
    {
      id: 'maximumPressureDrop',
      label: '[kPa]',
    },
    {
      id: 'primarySideMedium',
      label: '[-]',
    },
    {
      id: 'secondarySideMedium',
      label: '[-]',
    },
    {
      id: 'S1_temperatureDropInExchanger',
      label: '[-]',
    },
    {
      id: 'heatingPower',
      label: '[kW]',
    },
    {
      id: 'D1_deltaSupplyReturn',
      label: '[-]',
    },
    {
      id: 'A1_primarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'A2_primarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: 'A3_secondarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'A4_secondarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: 'B1_primarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'B2_primarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: 'B3_secondarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'B4_secondarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: 'coolingPower',
      label: '[kW]',
    },
    {
      id: 'D2_deltaSupplyReturn',
      label: '[-]',
    },
    {
      id: 'C1_primarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'C2_primarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: 'C3_secondarySideTemperatureInput',
      label: '[°C]',
    },
    {
      id: 'C4_secondarySideTemperatureOutput',
      label: '[°C]',
    },
    {
      id: '',
      label: '',
    },
  ];
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>rodzaj wymiennika</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>przeznaczenie</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>maksymalny spadek ciśnienia</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>czynnik po stronie pierwotnej</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>czynnik po stronie wtórnej</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>spadek temperatury na wymienniku</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>moc grzewcza</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>delta zasilanie/powrót</TableCell>
        <TableCell style={{ textAlign: 'center' }} colSpan={4}>1 punkt - projektowe parametry</TableCell>
        <TableCell style={{ textAlign: 'center' }} colSpan={4}>2 punkt - sprawdzenie</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>moc chłodnicza</TableCell>
        <TableCell style={{ textAlign: 'center' }} rowSpan={3}>delta zasilanie/powrót</TableCell>
        <TableCell style={{ textAlign: 'center' }} colSpan={4}>1 punkt - projektowe parametry</TableCell>
        <TableCell rowSpan={3}></TableCell>
      </TableRow>
      <TableRow>
        {/* __A__ */}
        <TableCell  style={{ textAlign: 'center' }} colSpan={2}>temp. po stronie pierwotnej</TableCell>
        <TableCell colSpan={2}>temp. po stronie wtórnej</TableCell>
        {/* __B__ */}
        <TableCell style={{ textAlign: 'center' }} colSpan={2}>temp. po stronie pierwotnej</TableCell>
        <TableCell style={{ textAlign: 'center' }} colSpan={2}>temp. po stronie wtórnej</TableCell>
        {/* __C__ */}
        <TableCell style={{ textAlign: 'center' }} colSpan={2}>temp. po stronie pierwotnej</TableCell>
        <TableCell style={{ textAlign: 'center' }} colSpan={2}>temp. po stronie wtórnej</TableCell>
      </TableRow>
      <TableRow>
        {/* __A__ */}
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
        {/* __B__ */}
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
        {/* __C__ */}
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wyjście</TableCell>
        <TableCell style={{ textAlign: 'center' }}>wejście</TableCell>
      </TableRow>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ExchangersHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default ExchangersHeader;
