import React from 'react';
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import EventsBoard from "./components/events-board/EventsBoard";
import ProjectsBoard from "./components/projects-board/ProjectsBoard";
import TaskBoard from "./components/task-board/TaskBoard";
import ToolBar from "./components/tool-bar/ToolBar";

const MainScreen = () => (
  <>
    <ToolBar />
    <RowWrapper style={{ alignItems: 'flex-start' }} fullWidth>
      <ColumnWrapper width="60%">
        <TaskBoard />
        <ProjectsBoard />
      </ColumnWrapper>
      <ColumnWrapper width="40%">
        <EventsBoard />
      </ColumnWrapper>
    </RowWrapper>
  </>
);

export default MainScreen;
