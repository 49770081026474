import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { API_CALENDAR_ROUTE } from '../../../../constants/routes';
import { deleteCall, getCall } from "../../../../components/indicator/Indicator";
import AddCalendarPopup from './AddCalendarPopup';
import returnSpecificField from '../../../../components/dynamic-forms/returnSpecificField';

const drawerWidth = 240;

const useStyles = makeStyles({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    justifyContent: 'space-between',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
});

const MyCalendars = forwardRef((props, ref) => {
  const { onCalendarChange, onHide } = props;
  const [calendars, setCalendars] = useState(JSON.parse(localStorage.getItem('cal_calendars')) || []);
  const [selected, setSelected] = useState(JSON.parse(localStorage.getItem('cal_selected')) || []);
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('cal_company')) || []);
  const [project, setProject] = useState(JSON.parse(localStorage.getItem('cal_project')) || []);
  const addCalendarRef = useRef();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const tasksCalendar = [{
    id: 'tasks',
    name: 'Zadania',
    color: 'rgb(160, 26, 95)',
    delete: false,
  }, {
    id: 'shared',
    name: 'Udostępnione',
    color: '#3174ad',
    delete: false,
  }, {
    id: 'company_tasks',
    name: 'Zadania',
    group: 'company',
    color: 'rgb(160, 26, 95)',
    delete: false,
  }, {
    id: 'company_notes',
    name: 'Notatki',
    group: 'company',
    color: 'rgb(123, 132, 140)',
    delete: false,
  }, {
    id: 'company_ostatni',
    name: 'Ostatni kontakt',
    group: 'company',
    color: 'rgb(119, 117, 109)',
    delete: false,
  }, {
    id: 'project_tasks',
    name: 'Zadania',
    group: 'project',
    color: 'rgb(160, 26, 95)',
    delete: false,
  }, {
    id: 'project_notes',
    name: 'Notatki',
    group: 'project',
    color: 'rgb(123, 132, 140)',
    delete: false,
  }, {
    id: 'project_status',
    name: 'Zmiana statusu',
    group: 'project',
    color: 'rgb(119, 117, 109)',
    delete: false,
  }, {
    id: 'project_dates',
    name: 'Wprowadzone daty',
    group: 'project',
    color: 'rgb(86, 116, 78)',
    delete: false,
  }];

  const fetchCalendars = async () => {
    const { data } = await getCall(API_CALENDAR_ROUTE);
    if (data?.items) {
      setCalendars([...tasksCalendar, ...data?.items]);
      const [defaultCalendar] = data?.items;
      if(!selected?.length) {
        selectCheckbox(tasksCalendar[0]);
      }
    }
  };

  useEffect(() => {
    fetchCalendars();
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    show() {
      return setIsOpen(true);
    },
  }));

  const isChecked = (id) => selected.find((i) => i.id === id);

  const selectCheckbox = (calendar) => {
    const s = isChecked(calendar?.id);
    if (s) {
      setSelected(selected.filter((i) => i.id !== calendar.id));
    } else {
      setSelected([...selected, calendar]);
    }
  };

  const removeCalendar = async (calendarId) => {
    try {
      deleteCall(`${API_CALENDAR_ROUTE}/${calendarId}`);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        fetchCalendars();
      }, 1500)
    }
  }

  useEffect(() => {
    onCalendarChange(selected, project?.map(item => item?.id), company?.map(item => item?.id));
    localStorage.setItem('cal_selected', JSON.stringify(selected));
    localStorage.setItem('cal_project', JSON.stringify(project));
    localStorage.setItem('cal_company', JSON.stringify(company));
  }, [selected, onCalendarChange, project, company]);

  const singleCalendar = (calendar) => (
    <ListItem dense={true} key={calendar.id}>
      <Checkbox
        style={{color: calendar?.color || '#3174ad'}}
        onChange={() => selectCheckbox(calendar)}
        checked={isChecked(calendar.id) ? true : false}
      />
      <ListItemText primary={calendar.name} />
      {calendar.delete !== false && (
        <IconButton onClick={() => removeCalendar(calendar.id)} edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      )}
    </ListItem>
  )

  const onCalendarAdd = async () => await fetchCalendars();

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={() => { setIsOpen(false); onHide(); }}>
        <div className={classes.drawerHeader}>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
            Kalendarze
          </Typography>
        </div>
        <Divider />
        <List dense={true} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          {calendars && calendars.length ? (
            <>
            {calendars.filter(cal => !cal?.isPublic && !cal?.group).map(singleCalendar)}
            <Divider />
            <ListSubheader dense={true} disableSticky={true}>Publiczne</ListSubheader>
            {calendars.filter(cal => cal?.isPublic && !cal?.group).map(singleCalendar)}
            <Divider />
            <ListSubheader dense={true} disableSticky={true}>Firma</ListSubheader>
            <ListItem dense={true}>{returnSpecificField('entity', {'entityClass': 'company', 'multiple': true}, 'company_picker', company, (f, v) => { setCompany(v) }, null)}</ListItem>
            {calendars.filter(cal => cal?.group === 'company').map(singleCalendar)}
            <Divider />
            <ListSubheader dense={true} disableSticky={true}>Projekt</ListSubheader>
            <ListItem dense={true}>{returnSpecificField('entity', {'entityClass': 'project', 'multiple': true}, 'project_picker', project, (f, v) => { setProject(v) }, null)}</ListItem>
            {calendars.filter(cal => cal?.group === 'project').map(singleCalendar)}
            </>
          ) : (
            <ListItem dense={true}>
              <Typography variant="body1">Brak kalendarzy</Typography>
            </ListItem>
          )}
        </List>
        <Divider />
        <Button
          style={{ marginTop: 10 }}
          onClick={() => addCalendarRef.current.open()}>
          Dodaj kalendarz
        </Button>
      </Drawer>
      <AddCalendarPopup onSave={onCalendarAdd} ref={addCalendarRef} />
    </>
  );
});

MyCalendars.propTypes = {};

export default MyCalendars;
