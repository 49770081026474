import React, {useEffect, useState} from "react";
import {DIR_TYPE, FILE_TYPE} from "../../../../constants/directories/types";
import uniqueId from "../../../../tools/uniqueId";
import {FolderOpenOutlined, DescriptionOutlined} from "@material-ui/icons";
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import truncate from "../../../../tools/truncate";
import {TableCell, Collapse} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import DynamicTable from "../../../../components/tables/basic/dynamic-table/DynamicTable";
import fetchDirectoryContent from "../../tools/fetchDirectoryContent";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import AddFileModal from "../add/AddFileModal";
import AddDirectoryModal from "../add/AddDirectoryModal";
import EditFileModal from "../edit/EditFileModal";
import {toast} from "react-toastify";
import {deleteCall} from "../../../../components/indicator/Indicator";
import {
    API_DIRECTORY_ROUTE,
    API_DOCUMENTS_ROUTE,
    SERVER_ROUTE
} from "../../../../constants/routes";
import IconButton from "@material-ui/core/IconButton";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from '@material-ui/icons/Visibility';
import routerHistory from "../../../../constants/settings/routerHistory";

export const DocumentDirectoryTable = ({documents, isLoading, showColumns = true, showEmptyInfo = true}) => {

    const [rows, setRows] = useState([]);
    const columns = [
        {header: 'Nazwa', key: 'name'},
        {header: 'Opis', key: 'description'},
        {header: 'Autor', key: 'creator'},
        {header: 'Akcje', key: 'actions'},
    ];

    useEffect(() => {
        setRows(documents)
    }, [documents]);

    const updateRow = (newRow, index) => {
        const newRows = rows.map(row => ({...row}));
        newRows.splice(index, 1, newRow);
        setRows(() => newRows);
    };

    const displayCreator = ({creator: {firstName, lastName} = {}}) => [firstName, lastName].filter(Boolean).join(' ') || '-';
    const displayDescription = ({description = '-'}) => <Tooltip title={description}><div>{truncate(description)}</div></Tooltip>;
    const displayName = ({name, type, extension}) => {
        let icon = <DescriptionOutlined color="secondary" size={14}/>;
        if (type === DIR_TYPE) {
            icon = <FolderOpenOutlined color="primary" size={14}/>
        } else if (['jpeg', 'png', 'jpg'].includes(extension)) {
            icon = <InsertPhotoOutlinedIcon color="inherit" size={14}/>
        }
        return <RowWrapper style={{alignItems: 'center', gap: '12px'}}>{icon}{name}</RowWrapper>;
    };
    const displayActions = (row) => {
        return <>
            <Tooltip title={`Podgląd ${row.name}`} placement="top">
                <IconButton onClick={() => handleClickRow(row)}>
                    <VisibilityIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            {row.type === FILE_TYPE && <EditFileModal document={row} onSave={handleAddEditDocument}/>}
            {row.type === DIR_TYPE &&
            <>
                <Tooltip title={`Przejdź do widoku ${row.name}`} placement="top">
                    <IconButton onClick={() => routerHistory.push(`/dir/${row.id}`)}>
                        <AccountTreeIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
                <AddDirectoryModal directory={row.id} onSave={dir => handleAddEditDocument(dir, row)}/>
                <AddFileModal directory={row.id} onSave={file => handleAddEditDocument(file, row)}/>
            </>}
            <DeleteButton
                tooltipInfo={row.type === DIR_TYPE ? `Usuń katalog ${row.name} z jego zawartością` : `Usuń plik ${row.name}`}
                question={row.type === DIR_TYPE ? `Czy chcesz usunąć katalog ${row.name} z jego zawartością?` : `Czy chcesz usunąć plik ${row.name}?`}
                titleModal={row.type === DIR_TYPE ? `Usuwanie katalogu ${row.name}` : `Usuwanie pliku ${row.name}`}
                fontSize="small"
                selected={[row]}
                onDelete={handleDelete}
            />
        </>
    };

    const handleAddEditDocument = (document, parent = null) => {
        if (!document) {
            return
        }

        const parentWitchNewChild = parent => ({
            ...parent,
            isOpen: false,
            children: parent.type === DIR_TYPE
                ? [...parent.children || [], document]
                : [document, ...parent.children]
        });

        const newRow = parent ? parentWitchNewChild(parent) : document;

        const index = findRowIndex(newRow);
        if (~index) {
            if(parent) {
                appendDirectoryContent(newRow)
            } else {
                updateRow(newRow, index)
            }
        } else {
            setRows(rows =>
                newRow.type === DIR_TYPE ? [...rows, newRow] : [newRow, ...rows]
            )
        }

    };

    const handleDelete = (selected) => {
        const promises = [];
        selected.forEach(({id, type}) => {
            if (type === FILE_TYPE)
                promises.push(
                    deleteCall(`${API_DOCUMENTS_ROUTE}/${id}`),
                );
            else
                promises.push(
                    deleteCall(`${API_DIRECTORY_ROUTE}/${id}`),
                );
        });


        Promise.all(promises)
            .then(() => {
                toast.success('Pomyślnie usunięto wybrane elementy.');
                const removedUuids = selected.map(({uuid}) => uuid);
                setRows(rows => rows.filter(({uuid}) => !removedUuids.includes(uuid)))
            })
            .catch(() => {
                toast.error('Wystąpił błąd podczas usuwania wybranych elementów.');
            });
    };

    const handleClickRow = async (row, {key} = {}) => {
        if (key === 'actions') {
            return
        }
        if (row.type === DIR_TYPE) {
            await appendDirectoryContent(row);
        }
        if(row.type === FILE_TYPE && row.path) {
            window.open(`${SERVER_ROUTE}/${row.path}`, '_blank');
        }
    };

    const appendDirectoryContent = async directory => {
        const isOpen = !directory?.isOpen;
        const {rows: children} = !directory?.isLoaded ? await fetchDirectoryContent(directory.id) : directory.children;
        if(children.length) {
            const attachedData = <DirContentNode rows={children} columns={columns} isOpen={isOpen} />;
            const newDir = {
                ...directory,
                ...{
                    isOpen,
                    children,
                    attachedData,
                    isLoaded: true
                }
            };

            const index = findRowIndex(directory);
            if (~index) {
                updateRow(newDir, index)
            }
        } else {
            toast.success(`Katalog ${directory.name} jest pusty.`);
        }

    };

    const findRowIndex = ({uuid: searchesUuid}) => rows.findIndex(({uuid}) => uuid === searchesUuid);

    return <>
        <DynamicTable key={uniqueId()} columns={columns} rows={rows} isLoading={isLoading} showColumns={showColumns} showEmptyInfo={showEmptyInfo} onClickRow={handleClickRow}>
            {{
                name: displayName,
                description: displayDescription,
                creator: displayCreator,
                actions: displayActions,
            }}
        </DynamicTable>
    </>
};

const DirContentNode = ({isOpen, columns, rows}) => {

    return <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={columns.length}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
                <DocumentDirectoryTable documents={rows} showColumns={false} showEmptyInfo={false}/>
            </Box>
        </Collapse>
    </TableCell>;

}