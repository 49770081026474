import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { getCall } from '../../../components/indicator/Indicator';
import { API_PROJECTS_ROUTE } from '../../../constants/routes';
import { CircularProgress, TextField } from '@material-ui/core';

const DynamicProjectsField = ({ name, label, onChange, getOptionLabel, renderInput, value, ...props }) => {
  const [selected, setSelected] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  const handleSelect = (newInputValue) => {
    setSelected(newInputValue);
    onChange(newInputValue);
  }

  const fetchProjects = async () => {
    const r = await getCall(API_PROJECTS_ROUTE);
    if (r?.data) {
        return r?.data?.items.map((i) => ({
          id: i.id,
          name: i.name,
        }))
    }
  };

  useEffect(() => {
    setSelected(() => value)
  }, [value])

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetchProjects();

      if (active) {
        setOptions([...response.map(company => company.name)]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      name={name}
      options={options}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        handleSelect(newInputValue);
      }}
      freeSolo={true}
      clearOnBlur={false}
      onChange={(event, newValue) => handleSelect(newValue)}
      value={selected || ''}
      getOptionLabel={(option) => option || ''}
      loadingText={'Wczytuję...'}
      loading={loading}
      renderInput={(params) => (
        <TextField 
          {...params} 
          name={name} 
          value={selected || ''} 
          variant="outlined" 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
        )
      }
      {...props}
    />
  );
};

export default DynamicProjectsField;
