import { InputLabel } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import RowWrapper from '../../wrappers/RowWrapper';
import returnSpecificField from '../returnSpecificField';

const MultipleFields = ({ fields, value, onChange, name, values, onChangeInner, addProps = {}, ...props }) => {

  return (
    <>
      <RowWrapper style={{ alignItems: 'flex-end', flexWrap: 'wrap'  }} fullWidth>
        {fields.map((item, i) => (
          (!item?.options?.hidden) && <ColumnWrapper
            key={`item_${i}_${item.name}_${name}`}
            {...(item.type === 'multilineInput' || item.type === 'rowsGroup' || item?.fullWidth
              ? { fullWidth: true }
              : { width: item?.options?.width ? (item?.options?.width/ 12 * 100)+'%' : '20%' })}>
            <Fragment>
              {item?.label && item?.type !== 'dictionary' && item?.type !== 'checkbox' ? (
                <InputLabel
                  style={{ marginTop: 10 }}
                  shrink
                  htmlFor={item?.name}
                  {...(addProps[item?.name])}
                  >
                  {item.label}
                </InputLabel>
              ) : null}
              {returnSpecificField(
                item.type,
                  {...item.options, valueText: value[item.name + '_text'] || '', valueText2: value[item.name + '_text2'] || ''},
                item.name,
                value[item.name] || '',
                onChange,
                item.label,
                false,
                false,
                onChangeInner,
                values,
                addProps
              )}
            </Fragment>
          </ColumnWrapper>
        ))}
      </RowWrapper>
    </>
  );
};

export default MultipleFields;
