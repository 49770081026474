import { Avatar, Chip } from '@material-ui/core';
import React from 'react';

const MarketingChip = ({tag, ...props}) => {

    const getFirstLetter = (text) => (text && text.length) ? text.charAt(0) : '-';

    return (
        <Chip size={props.size || "small"} avatar={<Avatar color="light">{getFirstLetter(tag?.name)}</Avatar>} style={{margin: '10px 10px 10px 0px'}} color={tag?.color || 'light'} label={tag?.name} />
    )
}

export default MarketingChip;