import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import React, {useCallback} from "react";
import TableCell from "@material-ui/core/TableCell";

export default ({columns = [], rows = [], children}) => {

    const print = useCallback((index, column) => {
        const row = rows[index] || {},
            {key} = column,
            child = children ? children[key] : null;

        if (typeof child === 'function') {
            return child(row, column)
        } else if (child) {
            return child
        } else if (key === 'index') {
            return index + 1
        }
        return (row[key] || '-') + ''
    }, [rows, columns, children]);

    return <>
        <Table>
            {columns.map(column =>
                <TableRow key={column.header}>
                    <TableCell variant="head">{column.header}</TableCell>
                    {rows.map((row, index) =>
                        <TableCell key={row?.id || row?.uuid}>{print(index, column)}</TableCell>)
                    }
                </TableRow>
            )}
        </Table>
    </>
}