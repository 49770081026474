import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";

const AdvancedOffersForm = forwardRef(({ children }, ref) => {
  useImperativeHandle(ref, () => ({
    getFormValues() {
      return null;
    },
  }));

  return (
    <ExpansionContainer disabled={!children} title="Informacje">
      {children}
    </ExpansionContainer>
  );
});

AdvancedOffersForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AdvancedOffersForm;
