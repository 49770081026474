import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall,
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import ListToolBar from './components/list/ListToolBar';
import CompanyLabel from "../../components/notes/CompanyLabel";
import { API_PROJECTS_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import ProjectsSearchFilters from './components/search-filters/ProjectsSearchFilters';
import RowWrapper from '../../components/wrappers/RowWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import serializeQuery from '../../tools/serializeQuery';
import { useParams } from 'react-router';
import commonValues from "../../tools/commonValues";

const ProjectsScreen = () => {
  const { type = null } = useParams();

  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const state = useStoreState((state) => state.auth?.loggedInUser);

  const returnCompanyBadge = (name) => name ? <CompanyLabel companyName={name} /> : null;

  const fetchProjects = async (url = API_PROJECTS_ROUTE) => {
    const { data } = await getCall(url);
    if (data?.items) {
      const r = data.items?.map((item) => ({
        identity: item.id,
        id: item.id,
        name: item.name,
        status: item.status,
        company: returnCompanyBadge(item?.company?.name),
        projectKr: item?.projectKr?.username,
        voivodeship: commonValues.voivodeships?.find(elem => elem?.id == item?.voivodeship)?.name,
        city: item?.city,
        address: item?.address,
        lastContact: item?.lastContact,
        statusChangeDate: item?.statusChangeDate,
        dateAdded: item?.dateAdded,
      }));
      setCount(data?.total);
      setRows({ initialState: r, state: r });
    }
  };

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const queryPath = serializeQuery(v);
      const { data } = await getCall(`${API_PROJECTS_ROUTE}?${queryPath}`);
      const r = data.items?.map((item) => ({
        identity: item.id,
        id: item.id,
        name: item.name,
        status: item.status,
        company: returnCompanyBadge(item?.company?.name),
        projectKr: item?.projectKr?.username,
        voivodeship: commonValues.voivodeships?.find(elem => elem?.id == item?.voivodeship)?.name,
        city: item?.city,
        address: item?.address,
        lastContact: item?.lastContact,
        statusChangeDate: item?.statusChangeDate,
        dateAdded: item?.dateAdded,
      }));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleDelete = async (numSelected) => {
    const isAdmin = state?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (isAdmin) {
      const { data } = await deleteCall(API_PROJECTS_ROUTE, {
        ids: numSelected.join(),
      });
      const [status] = data;
      if (status?.success) {
        toast.success('Pomyślnie usunięto wybrane projekty.');
      } else {
        toast.error('Wystąpił błąd podczas usuwania wybranych projektów.');
      }
    } else {
      toast.warn(
        'Wybrany użytkownik nie posiada uprawnień do usuwania projektów.',
      );
    }
  };

  const handleAdded = () => {
    fetchProjects();
  }

  return (
    <>
      <ListToolBar handleAdded={handleAdded} />
      <RowWrapper>
        <ColumnWrapper style={{maxWidth: '350px'}}>
          <ProjectsSearchFilters handleSearch={handleSearch} />
        </ColumnWrapper>
        <ColumnWrapper fullWidth size="100%">
          <BasicTable
            onDelete={handleDelete}
            useDialogOnDelete
            headCells={[{ id: 'identity', numeric: false, disablePadding: false, label: 'id' },
            { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' },
            { id: 'status', numeric: false, disablePadding: false, label: 'status' },
            {
              id: 'company',
              numeric: false,
              disablePadding: false,
              label: 'powiązana firma',
            },
              { id: 'projectKr', numeric: false, disablePadding: false, label: 'KR' },
              { id: 'voivodeship', numeric: false, disablePadding: false, label: 'województwo' },
              { id: 'city', numeric: false, disablePadding: false, label: 'miasto' },
              { id: 'address', numeric: false, disablePadding: false, label: 'adres' },
              { id: 'lastContact', numeric: false, disablePadding: false, label: 'ostatni kontakt' },
              { id: 'statusChangeDate', numeric: false, disablePadding: false, label: 'data zmiany statusu' },
              { id: 'dateAdded', numeric: false, disablePadding: false, label: 'data dodania' },
            ]}
            rows={rows.state}
            onChangePage={async (p) =>
              await fetchProjects(`${API_PROJECTS_ROUTE}?page=${p}`)
            }
            recordsCount={count}
            onRowClick={({ id }) => routerHistory.push(`/projects/edit/${id}`)}
          />
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
};

export default ProjectsScreen;
