import { InputLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { forwardRef, useImperativeHandle } from 'react';
import {
  InputWrapper,
  StrictColWrapper,
} from "../FormComponents";
import PaperContainer from '../containers/PaperContainer';
import MultiTabs from '../tabs/MultiTabs';
import returnSpecificField from './returnSpecificField';

const DynamicForm = forwardRef(
  (
    {
      fields,
      topChildren,
      bottomChildren,
      currentRowGroupsUrl = null,
      small = false,
      avoidTabs = false,
      onChangeMiddleware = null,
      initialValues = {},
      containers = false,
      children,
      ...props
    },
    ref,
  ) => {
    const getValuesFromChildFields = (fields, values) => {
      fields.forEach((item) => {
        if (item.type === 'multipleFields') {
          getValuesFromChildFields(item.options.fields, values);
        } else if (item.type === 'date') {
          if(!values[item.name]) {
            values[item.name] = item.value ? new Date(item.value) : null;
          }
        } else {
          if(!values[item.name]) {
            values[item.name] = item.value || null;
          }
        }
      });
    };

    const createFormikInitialValues = () => {
      let values = {};

      getValuesFromChildFields(fields, values);

      return {...values, ...initialValues};
    };

    const formik = useFormik({
      initialValues: createFormikInitialValues(),
      enableReinitialize: true,
    });

    useImperativeHandle(ref, () => ({
      getFormValues() {
        return formik.values;
      },
      setFormValue(f, v) {
        return formik.setFieldValue(f, v);
      },
    }));

    const middleOnChange = (f, v, s) => {
      formik.setFieldValue(f, v, s);
      if(onChangeMiddleware) {
        onChangeMiddleware(f, v, s);
      }
    }

    const onChangeInner = (f, v, s) => {
      if(onChangeMiddleware) {
        onChangeMiddleware(f, v);
      }
    }

    const chechNoneType = (field) => {
      const foundNone = field.options.fields.some(item => item.type !== 'none');
      if(!foundNone) {
        return '0';
      } else {
        return null;
      }
    }

    const tabs = fields.map((field, index) => ({
      label: field.name,
      children: (
        <InputWrapper
          style={small ? { overflowX: 'auto' } : null, { pointerEvents: props?.readOnly ? 'none' : 'auto', margin: chechNoneType(field)}}
          key={`${field.label}_${index}`}>
          {(field?.label && field?.type !== 'dictionary' ) ? (
            <InputLabel style={{ marginTop: 10 }} shrink htmlFor={field?.name}>
              {field?.type !== 'checkbox' ? field.label : ' '}
            </InputLabel>
          ) : null}
          {returnSpecificField(
            field.type,
            field.options,
            field.name,
            field.type === 'multipleFields'
              ? formik.values
              : formik.values[field.name],
            middleOnChange,
            field.label,
            currentRowGroupsUrl,
            false,
            onChangeInner,
            formik?.values,
            props?.addProps,
            props?.readOnly
          )}
        </InputWrapper>
      ),
    }));

    return (
      <StrictColWrapper>
        {topChildren}
        {!avoidTabs ? <MultiTabs
          small={small}
          loadngLabel="Wczytywanie"
          tabs={tabs}
        /> : containers ? <>{tabs.map(item => (<PaperContainer key={item.label+'_tabp'} title={item.label}>{item.children}</PaperContainer>))}</> : <>{tabs.map(item => item.children)}</>}
        {bottomChildren}
      </StrictColWrapper>
    );
  },
);

export default DynamicForm;
