import {
  Button,
  InputLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Select from './Select';
import AddIcon from '@material-ui/icons/Add';
import { getCall } from '../../indicator/Indicator';
import { API_TEMPLATES_LAUNCH_ROUTE, API_PRODUCTS_ROUTE, API_TEMPLATES_REVIEW_ROUTE } from '../../../constants/routes';
import ColumnWrapper from '../../wrappers/ColumnWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '10px'
  }
}));

const initValues = {
  type: null,
  launchCost: null,
  overviewCost: null,
}

const TableGroup = ({ fields, value, onChange, name, values, onChangeInner = () => {}, addProps = {}, producerId, ...props }) => {
  const [launchTemplates, setLaunchTemplates] = useState(null);
  const [overviewTemplates, setOverviewTemplates] = useState(null);
  const [producerProducts, setProducerProducts] = useState(null);
  const [tables, setTables] = useState([{
    rows: [{ ...initValues }],
  }]);

  const classes = useStyles();

  const onLocalChange = (value, tableIndex, rowIndex, field) => {
    const newTables = [...tables];
    newTables[tableIndex].rows[rowIndex][field] = value;
    setTables(newTables);
    onChange(name, newTables);
  }

  const addRow = (tableIndex) => {
    const newTables = [...tables];
    console.log(newTables, tableIndex)
    newTables[tableIndex].rows.push(({
      type: null,
      launchCost: null,
      overviewCost: null
    }));
    setTables(newTables);
    onChange(name, newTables);
  }

  useEffect(() => {
    try {
      if(value) {
        setTables(() => Array.isArray(value) ? value : JSON.parse(value || []));
      } else {
        setTables([]);
      }
    } catch (e) {
      setTables([]);
    }
  }, [value]);

  useEffect(() => {
    if (producerId) {
      
      const fetchProducerProducts = async () => {
        const data = await getCall(API_PRODUCTS_ROUTE);
        setProducerProducts(() => {
          const products = data.data.items.filter((item) => item.product_producer.id === producerId);
          const filterProducts = products.filter((product) => product.fields.find((item) => item.value === 'is_single'));
          return filterProducts;
        })
      };
      const fetchLaunchTemplates = async () => {
        const { data: { item } } = await getCall(`${API_TEMPLATES_LAUNCH_ROUTE}?product_producer=${producerId}`);
        setLaunchTemplates(item);
      }
      const fetchOverviewTemplates = async () => {
        const { data: { item } } = await getCall(`${API_TEMPLATES_REVIEW_ROUTE}?product_producer=${producerId}`);
        setOverviewTemplates(item);
      }
      fetchProducerProducts();
      fetchLaunchTemplates();
      fetchOverviewTemplates();
    }
  }, [producerId])

  if (values?.product_type !== 'is_group') {
    return <></>;
  }

  return (
    <>
      <InputLabel
        style={{ marginBottom: '25px' }}
      >
        Pary rur
      </InputLabel>
      {tables.map((table, tableIndex) => (
        <>
          <InputLabel style={{ marginTop: '10px' }} shrink>{tableIndex + 1}.</InputLabel>
          <Table
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>L.p</TableCell>
                <TableCell style={{ minWidth: '230px' }}>Rodzaj</TableCell>
                <TableCell style={{ minWidth: '230px' }}>Grupa serwisowa (uruchomienie)</TableCell>
                <TableCell style={{ minWidth: '230px' }}>Grupa serwisowa (przegląd)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table?.rows?.map((row, rowIndex) => (
                <TableRow>
                  <TableCell>{rowIndex + 1}</TableCell>
                  <TableCell>
                    <Select
                      name='pipes_type'
                      onSelect={(e) => onLocalChange(e, tableIndex, rowIndex, 'type')}
                      value={row?.type}
                      data={producerProducts?.map((item) => ({ id: item.id, name: item.name }))}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                        name='pipes_launch'
                        onSelect={(e) => onLocalChange(e, tableIndex, rowIndex, 'launchCost')}
                        value={row?.launchCost}
                        data={launchTemplates?.columns.map((item) => ({ id: item.id, name: item.name }))}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                        name='pipes_overview'
                        onSelect={(e) => onLocalChange(e, tableIndex, rowIndex, 'overviewCost')}
                        value={row?.overviewCost}
                        data={overviewTemplates?.tables.map((item) => ({ id: item.id, name: item.name }))}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
              <ColumnWrapper>
                <Button className={classes.add} size="small" variant="contained" onClick={() => addRow(tableIndex)}>
                  <AddIcon fontSize="small" />
                </Button>
              </ColumnWrapper>
          </Table>
        </>
      ))}
      
      <ColumnWrapper>
        <Button className={classes.add} size="small" variant="contained" onClick={() => setTables([...tables, { rows: [{ ...initValues }]}])}>
          Dodaj parę rur
        </Button>
      </ColumnWrapper>
    </>
  )
}

export default TableGroup;