import {
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core';
import React, {
    forwardRef, useMemo
} from 'react';
import routerHistory from '../../../../constants/settings/routerHistory';

const VariantTypeButtons = forwardRef((props, ref) => {

    const [projId] = React.useState(props?.projId);
    const [variantId] = React.useState(props?.variantId);
    const [variants] = React.useState(props?.variants);

    const randomUid = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);

    const [anchorElAut, setAnchorElAut] = React.useState(null);
    const [anchorElDev, setAnchorElDev] = React.useState(null);
    const [anchorElPer, setAnchorElPer] = React.useState(null);

    const disabled =  React.useMemo(() => !variantId || !projId, [projId, variantId]);

    return <>
        <Button
            disabled={disabled}
            variant="contained"
            color={variants.devices?.length ? "secondary" : "default"}
            style={{marginLeft: 10, fontSize: '8px' }}
            aria-controls={"ur-" + randomUid}
            aria-haspopup="true"
            onClick={(event) => {
                setAnchorElDev(event.currentTarget)
            }}
        >
            Urządzenia
        </Button>
        <Menu
            id={"ur-" + randomUid}
            anchorEl={anchorElDev}
            keepMounted
            open={Boolean(anchorElDev)}
            onClose={() => setAnchorElDev(null)}
        >
            <MenuItem onClick={() => {
                setAnchorElDev(null);
                routerHistory.push(`/projects/${projId}/variant/${variantId}/dev`);
            }}>Uzupełnij wzór</MenuItem>
        </Menu>
        <Button
            disabled={disabled}
            variant="contained" 
            color={variants.peripherals?.length ? "secondary" : "default"}
            style={{marginLeft: 10, fontSize: '8px' }}
            aria-controls={"p-" + randomUid}
            aria-haspopup="true"
            onClick={(event) => {
                setAnchorElPer(event.currentTarget)
            }}
        >
            Peryferia
        </Button>
        <Menu
            id={"p-" + randomUid}
            anchorEl={anchorElPer}
            keepMounted
            open={Boolean(anchorElPer)}
            onClose={() => setAnchorElPer(null)}
        >
            <MenuItem onClick={() => {
                setAnchorElPer(null);
                routerHistory.push(`/projects/${projId}/variant/${variantId}/per`);
            }}>Uzupełnij wzór</MenuItem>
        </Menu>
        <Button
            disabled={disabled}
            variant="contained" 
            color={variants.automation?.id ? "secondary" : "default"}
            style={{marginLeft: 10, fontSize: '8px' }}
            aria-controls={"a-" + randomUid}
            aria-haspopup="true"
            onClick={(event) => {
                setAnchorElAut(event.currentTarget)
            }}
        >
            Automatyka
        </Button>
        <Menu
            id={"a-" + randomUid}
            anchorEl={anchorElAut}
            keepMounted
            open={Boolean(anchorElAut)}
            onClose={() => setAnchorElAut(null)}
        >
            {
                props?.requestForAutomationSend
                ? <MenuItem
                        disabled={true}
                        onClick={() => {
                        setAnchorElAut(null);
                            props.onClickAutomation()
                        // routerHistory.push(`/projects/${projId}/variant/${variantId}/aut`);
                    }}>Prośba o uzupełnienie została wysłana</MenuItem>
                    :  <MenuItem onClick={() => {
                        setAnchorElAut(null);
                        props.onClickAutomation()
                        // routerHistory.push(`/projects/${projId}/variant/${variantId}/aut`);
                    }}>poproś o uzupełnienienie</MenuItem>
            }
        </Menu>
    </>
});

export default VariantTypeButtons;