const offerGeneratorFields = [{
        "id": 28,
        "name": "Generuj ofertę",
        "type": "multipleFields",
        "options": {
            "fields": [
                {
                    "id": 29,
                    "name": "offer_variant",
                    "type": "entity",
                    "label": "Wariant",
                    "value": null,
                    "options": {
                        "entityClass": "variant",
                        "width": 4
                    }
                },
                {
                    "id": 29,
                    "name": "offer_company",
                    "type": "entity",
                    "label": "Firma",
                    "value": null,
                    "options": {
                        "entityClass": "company",
                        "width": 4
                    }
                },
                {
                    "id": 29,
                    "name": "company_person",
                    "type": "entity",
                    "label": "Przedstawiciel firmy",
                    "value": null,
                    "options": {
                        "entityClass": "company_person",
                        "width": 4,
                        "freeSolo": true,
                        "attrs": {
                            "forceParamId": "offer_company",
                        }
                    }
                },
                {
                    "id": 40,
                    "name": "offer_generate_devices",
                    "type": "button",
                    "value": null,
                    "options": {
                        "text": "Generuj ofertę na urządzenia",
                        "attrs": {
                            "color": "secondary"
                        },
                        "width": 3
                    }
                },
                {
                    "id": 40,
                    "name": "offer_generate_per",
                    "type": "button",
                    "value": null,
                    "options": {
                        "text": "Generuj ofertę na peryferia",
                        "attrs": {
                            "color": "secondary"
                        },
                        "width": 3
                    }
                },
                {
                    "id": 40,
                    "name": "offer_generate_autom",
                    "type": "button",
                    "value": null,
                    "options": {
                        "text": "Generuj ofertę na automatykę",
                        "attrs": {
                            "color": "secondary"
                        },
                        "width": 3
                    }
                },
                {
                    "id": 40,
                    "name": "offer_generate_merged",
                    "type": "button",
                    "value": null,
                    "options": {
                        "text": "Generuj scaloną ofertę",
                        "attrs": {
                            "color": "secondary"
                        },
                        "width": 3
                    }
                },
            ]
        }
    }];


export { offerGeneratorFields };