const productCardFieldsAutomatics = [{
    "id": 10,
    "name": "Informacje o produkcie (typ: automatyka)",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "index",
                "type": "text",
                "label": "Index",
                "value": null,
                "options": null
            },
            {
                "id": 61,
                "type": "none",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "cena_gazuno",
                "type": "price",
                "label": "Cena zakupu przez Gazuno",
                "value": null,
                "options": null
            },
            { label: 'Waluta - Cena zakupu', name: 'cena_zakupu_waluta', type: 'selectCurrency',
                // options: {
                //     data: [
                //         {id: 'pln', name: 'PLN'},
                //         {id: 'eur', name: 'EUR'},
                //     ]
                // }, value: 'pln',
            },
            {
                "id": 61,
                "name": "cena_flowair",
                "type": "price",
                "label": "Cena dla Flowair",
                "value": null,
                "options": null
            },
            { label: 'Waluta - Flowair', name: 'cena_flowair_waluta', type: 'selectCurrency',
            //     options: {
            //     data: [
            //     {id: 'pln', name: 'PLN'},
            //     {id: 'eur', name: 'EUR'},
            //     ]
            // }, value: 'pln',
            },
            {
                "id": 33,
                "name": "desc_ext",
                "type": "text",
                "label": "Opis rozszerzony",
                "value": null,
                options: {
                    attrs: {
                        multiline: true,
                        rows: 5
                    }
                },
                "fullWidth": true
            },
        ]
    }
},
{
    "id": 16,
    "name": "Tabela kosztów",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 68,
                "name": "produkt_zalaczniki",
                "type": "rowsGroup",
                "label": "Załączniki",
                "value": "[]",
                "options": {
                    "id": 17,
                    "data": [],
                    "fields": [
                        {
                            "id": 69,
                            "name": "zalacznik_nazwa",
                            "type": "text",
                            "label": "Nazwa",
                            "value": null,
                            "options": null
                        },
                        {
                            "id": 70,
                            "name": "zalacznik_plik",
                            "type": "attachment",
                            "label": "Plik",
                            "value": null,
                            "options": null
                        },
                    ],
                    "name": "zalaczniki"
                }
            }
        ]
    }
}];

export default productCardFieldsAutomatics;