let API_MAIN_ROUTE = '';
let SERVER_ROUTE = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_MAIN_ROUTE = 'http://localhost/api';
  SERVER_ROUTE = 'http://localhost';
  // API_MAIN_ROUTE = 'https://dev.panel.gazuno.pl/api';
} else {
  SERVER_ROUTE = '';
  API_MAIN_ROUTE = '/api';
}
const API_REGISTER_ROUTE = `${API_MAIN_ROUTE}/register`;
const API_LOGIN_ROUTE = `${API_MAIN_ROUTE}/login_check`;
const API_USER_PROFILE_ROUTE = `${API_MAIN_ROUTE}/user/profile`;
const API_TOKEN_VALIDATION_ROUTE = `${API_MAIN_ROUTE}/token_check`;
const API_DYNAMIC_FIELDS_ROUTE = `${API_MAIN_ROUTE}/desc_groups`;
const API_COMPANIES_ROUTE = `${API_MAIN_ROUTE}/companies`;
const API_COMPANIES_ROWS_GROUP_ROUTE = (id) =>
  `${API_MAIN_ROUTE}/companies/${id}/rows_group`;
const API_NOTES_ROUTE = `${API_MAIN_ROUTE}/notes`;
const API_PROJECTS_ROUTE = `${API_MAIN_ROUTE}/projects`;
const API_PROJECT_DEVICES_ROUTE = (id) =>
  `${API_MAIN_ROUTE}/projects/${id}/devices`;
const API_USERS_ROUTE = `${API_MAIN_ROUTE}/user`;
const API_KR_USERS_ROUTE = `${API_USERS_ROUTE}/kr-only`;
const API_TASKS_ROUTE = `${API_MAIN_ROUTE}/tasks`;
const API_CALENDAR_ROUTE = `${API_MAIN_ROUTE}/calendar`;
const API_CALENDAR_EVENT_ROUTE = `${API_MAIN_ROUTE}/calendar_event`;
const API_CALENDARS_MULTIPLE_EVENTS_ROUTE = `${API_MAIN_ROUTE}/calendars/multiple/events`;
const API_DOCUMENTS_ROUTE = `${API_MAIN_ROUTE}/attachment`;
const API_ATTACHMENT_DOCUMENTS_ROUTE = `${API_MAIN_ROUTE}/attachment_directory`;
const API_TEMPLATES_ROUTE = `${API_MAIN_ROUTE}/templates`;
const API_TEMPLATES_LAUNCH_ROUTE = `${API_MAIN_ROUTE}/templates/launch`;
const API_TEMPLATES_LAUNCH_CREATE_ROUTE = `${API_MAIN_ROUTE}/templates/launch/create`;
const API_TEMPLATES_LAUNCH_UPDATE_ROUTE = `${API_MAIN_ROUTE}/templates/launch/update`;
const API_TEMPLATES_REVIEW_ROUTE = `${API_MAIN_ROUTE}/templates/overview`;
const API_TEMPLATES_REVIEW_CREATE_ROUTE = `${API_MAIN_ROUTE}/templates/overview/create`;
const API_TEMPLATES_REVIEW_UPDATE_ROUTE = `${API_MAIN_ROUTE}/templates/overview/update`;
const API_REPAIRERS_ROUTE = `${API_MAIN_ROUTE}/repairers`;
const API_REPAIRS_ROUTE = `${API_MAIN_ROUTE}/repairs`;
const API_REPAIRS_ACTIONS_ROUTE = `${API_MAIN_ROUTE}/repairs-actions`;
const API_REPAIRS_ACTIONS_DEVICES_ROUTE = `${API_MAIN_ROUTE}/action-devices`;
const API_DIRECTORY_EXPLORER_ROUTE = `${API_MAIN_ROUTE}/attachment_directory/explorer`;
const API_DIRECTORY_ROUTE = `${API_MAIN_ROUTE}/attachment_directory`;
const MARKETING_ACTIONS_ROUTE = `${API_MAIN_ROUTE}/marketing_actions`;
const MARKETING_TAGS_ROUTE = `${API_MAIN_ROUTE}/marketing_tags`;
const MARKETING_CONTACT_ROUTE = `${API_MAIN_ROUTE}/marketing_contact`;
const API_PRODUCTS_ROUTE = `${API_MAIN_ROUTE}/products`;
const API_START_PRICE_CALC = `${API_MAIN_ROUTE}/product-startprice/`;
const API_PRODUCT_PRODUCER_ROUTE = `${API_MAIN_ROUTE}/product-producers`;
const API_PRODUCT_VARIANTS_ROUTE = `${API_MAIN_ROUTE}/product-variants`;
const API_PRODUCT_FINAL_VARIANTS_ROUTE = (project) => `${API_MAIN_ROUTE}/${project}/final-project-variants`;
const API_PRODUCT_CATEGORIES_ROUTE = `${API_MAIN_ROUTE}/product-categories`;
const API_DICTIONARY_ROUTE = `${API_MAIN_ROUTE}/dictionary`;
const API_ENTITY_ROUTE = `${API_MAIN_ROUTE}/entity`;
const API_DICTIONARY_VALUE_ROUTE = `${API_MAIN_ROUTE}/dictionary_value`;
const API_PROJECT_VARIANTS_ROUTE = `${API_MAIN_ROUTE}/project-variants`;
const API_PROJECT_VARIANTS_PREVIEW_ROUTE = `${API_MAIN_ROUTE}/project-variant/fields-preview/list`;
const API_SETTINGS_CONTAINER_ROUTE = `${API_MAIN_ROUTE}/settings-container`;
const API_PROJECT_AUTOMATION_ROUTE = `${API_MAIN_ROUTE}/project-automation`;
const API_DESC_FIELDS_ROUTE = `${API_MAIN_ROUTE}/desc_fields`;
const API_DESC_GROUPS_ROUTE = `${API_MAIN_ROUTE}/desc_groups`;
const API_HEAT_EXCHANGER_ROUTE = `${API_MAIN_ROUTE}/heat-exchangers`;
const API_HEAT_EXCHANGER_PER_PROJECT_ROUTE = (projectId) =>
  `${API_MAIN_ROUTE}/heat-exchangers/project/${projectId}`;
const API_REPLACEMENT_INFO_ROUTE = `${API_MAIN_ROUTE}/replacement-info`;
const API_REPLACEMENT_INFO_PER_PROJECT_ROUTE = (projectId) =>
  `${API_MAIN_ROUTE}/replacement-info/project/${projectId}`;
const API_OFFERS_ROUTE = `${API_MAIN_ROUTE}/offers`;
const API_COMMENTS_ROUTE = `${API_MAIN_ROUTE}/product/comment/keys`;


export {
  API_MAIN_ROUTE,
  API_REGISTER_ROUTE,
  API_LOGIN_ROUTE,
  API_USER_PROFILE_ROUTE,
  API_TOKEN_VALIDATION_ROUTE,
  API_DYNAMIC_FIELDS_ROUTE,
  API_COMPANIES_ROUTE,
  API_NOTES_ROUTE,
  API_PROJECTS_ROUTE,
  API_USERS_ROUTE,
  API_TASKS_ROUTE,
  API_CALENDAR_ROUTE,
  API_CALENDAR_EVENT_ROUTE,
  API_CALENDARS_MULTIPLE_EVENTS_ROUTE,
  API_DOCUMENTS_ROUTE,
  API_ATTACHMENT_DOCUMENTS_ROUTE,
  API_COMPANIES_ROWS_GROUP_ROUTE,
  API_TEMPLATES_ROUTE,
  API_TEMPLATES_LAUNCH_ROUTE,
  API_TEMPLATES_LAUNCH_CREATE_ROUTE,
  API_TEMPLATES_LAUNCH_UPDATE_ROUTE,
  API_TEMPLATES_REVIEW_ROUTE,
  API_TEMPLATES_REVIEW_CREATE_ROUTE,
  API_TEMPLATES_REVIEW_UPDATE_ROUTE,
  API_REPAIRERS_ROUTE,
  API_REPAIRS_ROUTE,
  API_DIRECTORY_EXPLORER_ROUTE,
  API_DIRECTORY_ROUTE,
  MARKETING_ACTIONS_ROUTE,
  API_PRODUCTS_ROUTE,
  API_PRODUCT_PRODUCER_ROUTE,
  API_PRODUCT_VARIANTS_ROUTE,
  API_PRODUCT_CATEGORIES_ROUTE,
  API_DICTIONARY_ROUTE,
  API_DICTIONARY_VALUE_ROUTE,
  API_PROJECT_VARIANTS_ROUTE,
  API_PROJECT_VARIANTS_PREVIEW_ROUTE,
  API_DESC_FIELDS_ROUTE,
  API_DESC_GROUPS_ROUTE,
  SERVER_ROUTE,
  API_PROJECT_DEVICES_ROUTE,
  API_HEAT_EXCHANGER_ROUTE,
  API_HEAT_EXCHANGER_PER_PROJECT_ROUTE,
  API_REPLACEMENT_INFO_ROUTE,
  API_REPLACEMENT_INFO_PER_PROJECT_ROUTE,
  API_KR_USERS_ROUTE,
  API_REPAIRS_ACTIONS_ROUTE,
  API_REPAIRS_ACTIONS_DEVICES_ROUTE,
  API_OFFERS_ROUTE,
  API_PROJECT_AUTOMATION_ROUTE,
  API_ENTITY_ROUTE,
  MARKETING_TAGS_ROUTE,
  MARKETING_CONTACT_ROUTE,
  API_SETTINGS_CONTAINER_ROUTE,
  API_START_PRICE_CALC,
  API_PRODUCT_FINAL_VARIANTS_ROUTE,
  API_COMMENTS_ROUTE
};
