import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import routerHistory from "../../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import { InputWrapper, Wrapper } from "../../../../../components/ListToolBarComponents";

const ListToolBar = ({ handleSearch, handleImport }) => {
  const [searchVal, setSearchVal] = useState('');

  return (
    <>
      <Wrapper>
        <InputWrapper>
          <SearchField
            value={searchVal}
            onChange={({ target }) => setSearchVal(target.value)}
          />
          <PrimaryButton
            style={{ marginLeft: 10 }}
            color="secondary"
            onClick={() => handleSearch(searchVal)}>
            Szukaj
          </PrimaryButton>
        </InputWrapper>
        <Button
          onClick={() => handleImport()}
          variant="outlined">
          Importuj
        </Button>
      </Wrapper>
    </>
  );
};

ListToolBar.propTypes = {
  handleSearch: PropTypes.func,
};

ListToolBar.defaultProps = {
  handleSearch: () => {},
};

export default ListToolBar;
