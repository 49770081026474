const controlMethodsOverview = (check) => {
    if(check) {
        return {
            "fields": [
                {
                    "id": 61,
                    "name": "koszt_przeglad_1",
                    "type": "text",
                    "label": "Koszt przeglądu rocznego",
                    "value": null,
                    "options": null
                },
                { label: 'Waluta', name: 'koszt_przeglad_1_waluta', type: 'dictionary', options: {
                        data: [
                            {id: 'pln', name: 'PLN'},
                            {id: 'eur', name: 'EUR'},
                        ]
                    }, value: 'pln', },
                { "id": 11, "type": "none", "value" : null, fullWidth: true },
                {
                    "id": 61,
                    "name": "koszt_przeglad_2",
                    "type": "text",
                    "label": "Koszt przeglądu 2-letniego",
                    "value": null,
                    "options": null
                },
                { label: 'Waluta', name: 'koszt_przeglad_2_waluta', type: 'dictionary', options: {
                        data: [
                            {id: 'pln', name: 'PLN'},
                            {id: 'eur', name: 'EUR'},
                        ]
                    }, value: 'pln', },
                { "id": 11, "type": "none", "value" : null, fullWidth: true },
                {
                    "id": 61,
                    "name": "koszt_przeglad_5",
                    "type": "text",
                    "label": "Koszt przeglądu 5-letniego",
                    "value": null,
                    "options": null
                },
                { label: 'Waluta', name: 'koszt_przeglad_5_waluta', type: 'dictionary', options: {
                        data: [
                            {id: 'pln', name: 'PLN'},
                            {id: 'eur', name: 'EUR'},
                        ]
                    }, value: 'pln', },
                { "id": 11, "type": "none", "value" : null, fullWidth: true },

            ]
        }
    } else {
        return {
            "fields": [
                {"type": "none", }
            ]
        };
    }
};

const controlMethodsActivation = (check) => {
    if(check){
        return {
            "fields": [
                {
                    "id": 68,
                    "name": "koszt_uruchomienia",
                    "type": "rowsGroup",
                    "label": "Koszty uruchomienia",
                    "value": "[]",
                    "options": {
                        "id": 17,
                        "data": [],
                        "fields": [
                            {
                                "id": 69,
                                "name": "device_count",
                                "type": "text",
                                "label": "Liczba urządzeń",
                                "value": null,
                                "options": null
                            },
                            {
                                "id": 70,
                                "name": "price",
                                "type": "text",
                                "label": "Cena",
                                "value": null,
                                "options": null
                            },
                        ],
                        "name": "koszt_uruchomienia"
                    }
                }
            ]
        }
    }else {
        return {
            "fields": [
                {"type": "none", }
            ]
        };
    }
}

const productCardFieldsDevices = ({ producerId }, deviceProductType, launchTemplates, overviewTemplates) => {
return [{
    "id": 10,
    "name": "Informacje o produkcie (typ: urządzenia)",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "index",
                "type": "text",
                "label": "Index",
                "value": null,
                "options": null
            },
            {
                "id": 61,
                "name": "product_type",
                "type": "productTypeRadio",
                "label": "",
                "value": null,
                "options": null
            },
            {
                "id": 61,
                "type": "none",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "cena_gazuno",
                "type": "price",
                "label": "Cena zakupu przez Gazuno",
                "value": null,
                "options": null,
            },
            { label: 'Waluta - Cena zakupu', name: 'cena_zakupu_waluta', type: 'selectCurrency',
                // options: {
                //     data: [
                //         {id: 'pln', name: 'PLN'},
                //         {id: 'eur', name: 'EUR'},
                //     ]
                // },
                // value: 'pln',
            }
            ,
            {
                "id": 61,
                "name": "cena_flowair",
                "type": "price",
                "label": "Cena dla Flowair",
                "value": null,
                "options": null
            },
            { label: 'Waluta - Flowair', name: 'cena_flowair_waluta', type: 'selectCurrency',
            //     options: {
            //     data: [
            //     {id: 'pln', name: 'PLN'},
            //     {id: 'eur', name: 'EUR'},
            //     ]
            // }, value: 'pln',
            },
            {
                "id": 33,
                "name": "desc_ext",
                "type": "text",
                "label": "Opis rozszerzony",
                "value": null,
                options: {
                    attrs: {
                        multiline: true,
                        rows: 5
                    }
                },
                "fullWidth": true
            },
            {
                "id": 33,
                "name": "terms_gazuno_prod",
                "type": "text",
                "label": "Warunki handlowe Gazuno - producent",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 33,
                "name": " ",
                "label": "Warunki handlowe Gazuno-klient",
                "type": "none",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_arch",
                "type": "text",
                "label": "Architekt",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_dst",
                "type": "text",
                "label": "Dystrybutor",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_inw",
                "type": "text",
                "label": "Inwestor",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_srws",
                "type": "text",
                "label": "Serwisant",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_hbh",
                "type": "text",
                "label": "HBH",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_audyt",
                "type": "text",
                "label": "Audytor",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_handel",
                "type": "text",
                "label": "Hurtownia",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_prjkt",
                "type": "text",
                "label": "Projektant",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_wykon",
                "type": "text",
                "label": "Wykonawca",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_instalator",
                "type": "text",
                "label": "Instalator",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "terms_gazuno_klient_inne",
                "type": "text",
                "label": "Inne",
                "value": null,
                "options": null
            },
            {
                "id": 33,
                "name": "usage",
                "type": "text",
                "label": "Ważne informacje/gdzie zastosować",
                "value": null,
                "options": {
                    "width": 9.6
                }
            },
        ]
    }
},
{
    "id": 16,
    "name": "Rury",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 16,
                "name": "pipes_tables",
                "type": "tableGroup",
                "value": "[]",
                "options": {
                    "attrs": {
                        "producerId": producerId,
                    },
                    "id": 17,
                    "data": [],
                    "name": "pipes_table",
                    "fields": []
                }
        }]
    }
},
{
    "id": 16,
    "name": "Tabela kosztów",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 69,
                "name": "launchGroup",
                "type": "singleProductLaunch",
                "label": "",
                "value": null,
                "options": {
                    "id": 1,
                    "data": launchTemplates,
                  },
            },
            {
                "id": 69,
                "name": "overviewGroup",
                "type": "singleProductOverview",
                "label": "",
                "value": null,
                "options": {
                    "id": 1,
                    "data": overviewTemplates,
                  },
            },
        ]
    }
},
{
    "id": 16,
    "name": "Tabela kosztów",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 68,
                "name": "produkt_zalaczniki",
                "type": "rowsGroup",
                "label": "Załączniki",
                "value": "[]",
                "options": {
                    "id": 17,
                    "data": [],
                    "fields": [
                        {
                            "id": 69,
                            "name": "zalacznik_nazwa",
                            "type": "text",
                            "label": "Nazwa",
                            "value": null,
                            "options": null
                        },
                        {
                            "id": 70,
                            "name": "zalacznik_plik",
                            "type": "attachment",
                            "label": "Plik",
                            "value": null,
                            "options": null
                        },
                    ],
                    "name": "zalaczniki"
                }
            }
        ]
    }
},
{
    "id": 16,
    "name": deviceProductType === 'is_single' ? "Koszty przeglądu" : "",
    "label": deviceProductType === 'is_single' ? "Koszty przeglądu" : "",
    "type": "multipleFields",
    "options": controlMethodsOverview(deviceProductType === 'is_single'),
},
{
    "id": 16,
    "name": deviceProductType === 'is_single' ? "Koszty uruchomienia" : "",
    "label": deviceProductType === 'is_single' ? "Koszty uruchomienia" : "",
    "type": "multipleFields",
    "options": controlMethodsActivation(deviceProductType === 'is_single')
},
{
    "id": 16,
    "name": "Załączona dokumentacja",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "karta_doboru",
                "type": "attachment",
                "label": "Karta doboru",
                "value": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "rys",
                "type": "attachment",
                "label": "Wizualizacja",
                "value": null,
                "fullWidth": true
            },
        ]
    }
}]};

export default productCardFieldsDevices;
