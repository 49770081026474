import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { Wrapper } from "../../../../../components/ListToolBarComponents";

const ListToolBar = ({ handleSearch, onAdd }) => {
  const [searchVal, setSearchVal] = useState('');
  return (
    <>
      <Wrapper>
        <RowWrapper>
          <SearchField
            value={searchVal}
            onBlur={() => handleSearch(searchVal)}
            onChange={({ target }) => setSearchVal(target.value)}
          />
          <PrimaryButton
            style={{ marginLeft: 10 }}
            color="secondary"
            onClick={() => handleSearch(searchVal)}>
            Szukaj
          </PrimaryButton>
        </RowWrapper>
        <Button
          style={{ color: 'white', marginLeft: 10 }}
          variant="contained"
          onClick={onAdd}
          color="primary">
          Dodaj
        </Button>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
