// import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getCall } from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import AddEditPopup from './components/dictionaries/add-edit/details/AddEditPopup';
import ListToolBar from './components/dictionaries/list/ListToolBar';
import { API_DICTIONARY_ROUTE, API_TASKS_ROUTE } from '../../constants/routes';

const DictionariesScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const [dirId, setDirId] = useState(null);
  const [dirName, setDirName] = useState('');

  const popupRef = useRef();

  const fetchDictionaries = async (
    url = API_DICTIONARY_ROUTE,
    needToSetDirId = false,
  ) => {
    const { data } = await getCall(url);
    if (data?.items) {
      setCount(data?.total);
      const r = data?.items.map((item) => ({
        id: item.id,
        identy: item.id,
        name: item?.name,
      }));
      setRows({ initialState: r, state: r });
      if (needToSetDirId) {
        setDirId(r[0]?.id);
        setDirName(r[0]?.name);
      }
    }
  };

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${API_DICTIONARY_ROUTE}?query=${v}`);
      const r = data?.items.map((item) => ({
        id: item.id,
        identy: item.id,
        name: item?.name,
      }));
      if (data) {
        setRows({ ...rows, state: r });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  useEffect(() => {
    fetchDictionaries();
  }, []);

  const handleAddDir = () => {
    setDirId(null);
    popupRef.current.open();
  };

  const handleEditDir = (id, name) => {
    setDirId(id);
    setDirName(name);
    popupRef.current.open();
  };

  return (
    <>
      <ListToolBar handleSearch={handleSearch} onAdd={handleAddDir} />
      <BasicTable
        headCells={[  { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
        { id: 'name', numeric: false, disablePadding: false, label: 'nazwa' }]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchDictionaries(`${API_TASKS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id, name }) => handleEditDir(id, name)}
      />
      <AddEditPopup
        dirName={dirName}
        dirId={dirId}
        ref={popupRef}
        onSave={() => fetchDictionaries(undefined, true)}
      />
    </>
  );
};

export default DictionariesScreen;
