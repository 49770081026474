import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { devicesOffer } from './offer-devices';


const AdvancedOfferDevices = forwardRef((props, ref) => {

  
  const formRef = useRef();

  return (
    <>
      <RowWrapper fullWidth>
        <ColumnWrapper fullWidth>
          <DynamicForm initialValues={props?.initialValues} fields={devicesOffer} ref={ref} small={true} avoidTabs={true} />
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper fullWidth>
        <ColumnWrapper width="20%">
          <Divider />
        </ColumnWrapper>
      </RowWrapper>
    </>
  );
});

AdvancedOfferDevices.propTypes = {
};

export default AdvancedOfferDevices;