import React from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { postCall } from "../../../components/indicator/Indicator";
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import DevicesZone from "../components/devices/devices-zone/DevicesZone";
import DevicesToolBar from "../components/devices/tool-bar/DevicesToolBar";
import FloatingSaveBar from "../../../components/FloatingSaveBar";
import { API_PROJECT_DEVICES_ROUTE } from '../../../constants/routes';

const ProjectDevicesScreen = () => {
  const [rows, setRows] = React.useState([]);

  const { id } = useParams();

  const transformRows = () => {
    return rows.map((item) => ({
      project: id,
      product: item.product,
      amount: item.amount,
    }));
  };

  const saveRows = async () => {
    try {
      const { data } = await postCall(
        `${API_PROJECT_DEVICES_ROUTE(id)}`,
        transformRows(rows),
      );
      if (data) {
        toast.success('Zapisano urządzenia');
      }
    } catch {
      toast.error('Wystąpił błąd podczas zapisywania listy urządzeń.');
    }
  };

  return (
    <>
      <ColumnWrapper>
        <DevicesToolBar />
        <DevicesZone setRootRows={setRows} />
      </ColumnWrapper>
      <FloatingSaveBar onClick={saveRows} />
    </>
  );
};

export default ProjectDevicesScreen;
