import React from 'react';
import routerHistory from "../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../components/buttons/back-button/BackButton";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import ColumnWrapper from '../../../../../components/wrappers/ColumnWrapper';
import { Button, Typography } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const DetailsToolBar = ({ serviceManName, toggleDrawer }) => {
  const { id } = useParams();

  return (
    <>
      <Wrapper style={{ position: 'fixed', width: 'calc(100% - 90px)', background: '#fafafa', zIndex: '999' }}>
        <RowWrapper style={{alignItems: 'center'}}>
          <BackButton
            text="Wróć"
            onClick={() => routerHistory.goBack()}
          />
          <ColumnWrapper>
            <Typography>Karta serwisanta: {serviceManName}</Typography>
          </ColumnWrapper>
          
        </RowWrapper>
        
        <RowWrapper>
          <ButtonWrapper>
            <Button
              onClick={toggleDrawer}
              startIcon={<Message />}
              style={{ color: 'white' }}
              variant="contained"
              color="secondary">
              Notatki
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};


export default DetailsToolBar;
