import { DialogActions, Divider
} from '@material-ui/core';
import React, {useState} from 'react';
import returnSpecificField from '../returnSpecificField';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import RowWrapper from "../../wrappers/RowWrapper";
import PrimaryButton from "../../buttons/PrimaryButton";

const DynamicThermalFieldsAddModal = ({open, onClose, onSubmit}) => {

    const [name, setName] = useState('');

    return <>
        <Dialog open={open} onClose={onClose}>
                    <DialogContent>
                        <Container>
                            <h2 id="calc-launch-price-modal-title">Nazwa nastawy</h2>
                            <RowWrapper style={{flexWrap: 'wrap'}}>
                                {returnSpecificField(
                                    'text',
                                    {},
                                    'thermal_name',
                                    name,
                                    (e, v) => {
                                        setName(v)
                                    },
                                    'Nazwa nastawy'
                                )}
                            </RowWrapper>
                        </Container>
                    </DialogContent>
                    <Divider/>
                    <DialogActions style={{padding: 20}}>
                        <PrimaryButton variant="raised" onClick={() => {onClose(); setName('')}} color="secondary">
                            Anuluj
                        </PrimaryButton>
                        <PrimaryButton disabled={!name}
                                       onClick={() => {
                                           onClose();
                                           onSubmit(name);
                                           setName('')
                                       }}>
                            Zapisz
                        </PrimaryButton>
                    </DialogActions>
        </Dialog>
    </>
}

export default DynamicThermalFieldsAddModal;
