import React, { useState } from 'react';
import routerHistory from "../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../components/inputs/search-field/SearchField";
import { InputWrapper, Wrapper } from "../../../../components/ListToolBarComponents";

const ListToolBar = ({handleSearch}) => {
  const [searchVal, setSearchVal] = useState('');
  return (
    <>
      <Wrapper>
        <PrimaryButton
          onClick={() => routerHistory.push('/offers/new')}>
          Dodaj
        </PrimaryButton>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
