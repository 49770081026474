import {
  Button,
  Button as MaterialButton,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, {
  forwardRef,
  Fragment, useCallback,
  useEffect,
  useImperativeHandle, useState,
} from 'react';
import {API_PROJECT_VARIANTS_ROUTE, API_DOCUMENTS_ROUTE, SERVER_ROUTE} from '../../../../constants/routes';
import toBase64 from '../../../../tools/toBase64';
import {deleteCall, postCall} from "../../../../components/indicator/Indicator";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import VariantTypeButtons from './VariantTypeButtons';
import {toast} from "react-toastify";
import {debounce} from 'lodash'
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import Loader from "../../../../components/loader/Loader";

const ProjectVariants = forwardRef(({ projectId, variants = [], loading: parentLoading, ...props }, ref) => {
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      variants: [],
    },
  });

  const removeAttachment = async (attachmentId, callback) => {
    try {
      deleteCall(`${API_DOCUMENTS_ROUTE}/${attachmentId}`);
      callback();
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = ({path, downloadUrl} = {}) => {
    if(path) {
      window.open(`${SERVER_ROUTE}/${path}`, '_blank');
    } else if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    } else {
      toast.error(`Błąd podczas pobierania pliku`);
    }
  };

  useImperativeHandle(ref, () => ({
    getFormValues() {
      return formik.values;
    },
  }));

  const updateVariants = async (project_variants) => {
    const {data} = await postCall(`${API_PROJECT_VARIANTS_ROUTE}/${projectId}/bulk`, {project_variants});
    setLoading(true);
    setVariants(data?.items || []);
    setLoading(false);
  };

  const debounceUpdateVariants = useCallback(debounce(updateVariants, 900), []);

  useEffect(() => {
    if(!loading && projectId) {
        debounceUpdateVariants(formik.values.variants);
    }
  }, [formik.values]);

  useEffect(() => {
    projectId && variants?.length &&  setVariants(variants);
  }, [variants]);

  const setVariants = variants => {
      formik.setFieldValue(
          'variants',
          variants.map((v) => ({
            ...v
          })),
      );
  };

  useEffect(() => {
    setLoading(() => parentLoading)
  }, [parentLoading]);

  const removeVariant = async (variant, cb) => {
    if (variant?.id) {
      await deleteCall(`${API_PROJECT_VARIANTS_ROUTE}/${variant.id}`);
    }
    setLoading(true);
    cb();
    setLoading(false)
  };

  return (
    <RowWrapper style={{ overflowX: 'auto' }}>
      {loading
      && <Loader />
      || <FormikProvider value={formik}>
        <FieldArray
            name="variants"
            render={(aH) => (
                <ColumnWrapper>
                  {formik.values.variants && formik.values.variants.length > 0
                      ? formik.values.variants.map((v, index) => (
                          <Fragment key={index}>
                            <RowWrapper style={{ marginTop: 20, alignItems: 'center' }} fullWidth>
                              <TextField
                                  variant="outlined"
                                  placeholder="Nazwa"
                                  label="Nazwa"
                                  InputLabelProps={{ shrink: true }}
                                  name={`variants.${index}.name`}
                                  value={formik.values.variants[index].name}
                                  onChange={formik.handleChange}
                              />
                              <TextField
                                  variant="outlined"
                                  label="Opis"
                                  placeholder="Opis"
                                  multiline
                                  InputLabelProps={{ shrink: true }}
                                  name={`variants.${index}.description`}
                                  value={formik.values.variants[index].description}
                                  onChange={formik.handleChange}
                                  title={formik.values.variants[index].description}
                                  style={{marginLeft: 15}}
                              />
                              {Object.keys(formik.values.variants[index].schema).length > 0 ? (
                                <RowWrapper autoWidth>
                                  <MaterialButton
                                    onClick={() =>
                                      removeAttachment(formik.values.variants[index].schema.id, () => {
                                        formik.setFieldValue(`variants.${index}.schema`, []);
                                      })
                                    }
                                    style={{ margin: '0 10px' }}>
                                    Usuń
                                  </MaterialButton>
                                  <Tooltip
                                    title={
                                      formik.values.variants[index].schema.original_name ? 
                                      formik.values.variants[index].schema.original_name : fileName
                                    }
                                  >
                                    <MaterialButton
                                      onClick={() => downloadFile(formik.values.variants[index].schema)}
                                      variant="outlined">
                                      Podgląd
                                    </MaterialButton>
                                  </Tooltip>
                                </RowWrapper>
                              ) : (
                                <TextField
                                  variant="outlined"
                                  name={`variants.${index}.schema`}
                                  placeholder="Schemat"
                                  label="Schemat"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ multiple: true }}
                                  onChange={async (event) => {
                                    const file = event.currentTarget.files[0] || {};
                                    setFileName(file.name)
                                    const fileInBase64 = await toBase64(
                                        file,
                                    );
                                    formik.setFieldValue(
                                        `variants.${index}.schema`,
                                        {
                                          data: fileInBase64,
                                          downloadUrl: URL.createObjectURL(file),
                                          name: file.name || ''
                                        },
                                    );
                                  }}
                                  type="file"
                                  style={{ marginLeft: 10 }}
                                />
                              )}
                              {Object.keys(formik.values.variants[index].analysis).length ? (
                                <RowWrapper autoWidth>
                                  <MaterialButton
                                    onClick={() =>
                                      removeAttachment(formik.values.variants[index].analysis.id, () => {
                                        formik.setFieldValue(`variants.${index}.analysis`, {});
                                      })
                                    }
                                    style={{ margin: '0 10px' }}>
                                    Usuń
                                  </MaterialButton>
                                  <MaterialButton
                                    onClick={() => downloadFile(formik.values.variants[index].analysis)}
                                    variant="outlined">
                                    Podgląd
                                  </MaterialButton>
                                </RowWrapper>
                              ) : (
                                <TextField
                                  variant="outlined"
                                  placeholder="Analiza"
                                  name={`variants.${index}.analysis`}
                                  label="Analiza"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ multiple: true }}
                                  onChange={async (event) => {
                                    const file = event.currentTarget.files[0] || {};
                                    const fileInBase64 = await toBase64(
                                        file,
                                    );
                                    formik.setFieldValue(
                                        `variants.${index}.analysis`,
                                        {
                                          data: fileInBase64,
                                          downloadUrl: URL.createObjectURL(file),
                                          name: file.name || ''
                                        },
                                    );
                                  }}
                                  type="file"
                                  style={{ marginLeft: 10 }}
                                />
                              )}
                              {projectId && <VariantTypeButtons
                                  requestForAutomationSend={formik.values.variants[index]?.requestForAutomation}
                                  variants={formik.values.variants[index]}
                                  variantId={formik.values.variants[index]?.id}
                                  onClickAutomation={() => formik.setFieldValue(`variants.${index}.requestForAutomation`, true)}
                                  projId={projectId}
                              />}
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                        name={`variants.${index}.is_finale`}
                                        checked={Boolean(formik.values.variants[index].is_finale)}
                                        value={formik.values.variants[index].is_finale}
                                        onChange={({ target }) =>
                                            formik.setFieldValue(
                                                `variants.${index}.is_finale`,
                                                target.checked,
                                            )
                                        }
                                    />
                                  }
                                  style={{marginLeft: '10px'}}
                                  label="Wariant ostateczny"
                              />
                            </RowWrapper>
                            <RowWrapper
                                style={{ justifyContent: 'flex-end', marginTop: 10 }}>
                              <Button
                                  style={{ marginRight: 5 }}
                                  onClick={() =>
                                      removeVariant(formik.values.variants[index], () =>
                                          aH.remove(index),
                                      )
                                  }>
                                - Usuń wariant
                              </Button>
                            </RowWrapper>
                              <Divider />
                          </Fragment>
                      ))
                      : null}
                  <Button
                      variant="contained"
                      style={{ marginTop: 50, alignSelf: 'flex-end' }}
                      onClick={() =>
                          aH.push({
                            name: '',
                            description: '',
                            schema: {},
                            analysis: {},
                            is_finale: false,
                            requestForAutomation: false
                          })
                      }>
                    + Następny wariant
                  </Button>
                </ColumnWrapper>
            )}
        />
      </FormikProvider>}
    </RowWrapper>
  );
});

ProjectVariants.propTypes = {};

export default ProjectVariants;
