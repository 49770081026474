import Button from '@material-ui/core/Button';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useRef, useState } from 'react';
// import {
//   [],
//   PROJECT_USERS
// } from '../../../../mocks/projectsToolBarMock';
import routerHistory from "../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../components/inputs/search-field/SearchField";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { InputWrapper, Wrapper } from "../../../../components/ListToolBarComponents";
import { Paper, Popper, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { postCall } from '../../../../components/indicator/Indicator';
import { API_COMPANIES_ROUTE } from '../../../../constants/routes';
import { toast } from 'react-toastify';

const ListToolBar = ({ handleAdded }) => {
  const [name, setName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'company-new-pop' : undefined;

  const buttonRef = useRef();

  const createCompany = async () => {
    
    const callCompany = async () => {
      const r = await postCall(API_COMPANIES_ROUTE, {
        name,
        status: '',
        fields: []
      });
      if (r.data && !r.data.status) {
        toast.success(`Utworzono firmę ${name}`);      
        setAnchorEl(null);
        setName('');
        handleAdded();
      }
    };

    await callCompany();
  };


  return (
    <>
      <Wrapper>
        <Button
          onClick={(e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          style={{ color: 'white' }}
          variant="contained"
          color={open ? "secondary" : "primary"}
          aria-describedby={id}
          ref={buttonRef}>
          Dodaj nową
        </Button>
        <Popper
          id={id}
          placement="bottom-end"
          open={open}
          anchorEl={anchorEl}
          modifiers={{
            flip: {
              enabled: true,
            }
          }}
        >
          <Paper style={{padding: '2em'}}>
            <RowWrapper style={{alignItems: 'center'}}>

            <TextField
            onChange={({ target }) => setName(target.value)}
            value={name}
            placeholder="Wpisz nazwę..."
            style={{ display: 'flex', minWidth: '50px', width: '100%' }}
            variant="outlined"
          />
          <PrimaryButton style={{marginLeft: '10px', marginTop: '2px'}}
          onClick={async (e) => createCompany()}>
            <Add size={14} /> Dodaj 
          </PrimaryButton>
            </RowWrapper>
          </Paper>
        </Popper>
      </Wrapper>
    </>
  );
};

export default ListToolBar;
