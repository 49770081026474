import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  API_PRODUCT_CATEGORIES_ROUTE,
  API_PRODUCT_PRODUCER_ROUTE
} from '../../../../../constants/routes';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import { getCall } from "../../../../../components/indicator/Indicator";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { Wrapper } from "../../../../../components/ListToolBarComponents";

const ListToolBar = ({ handleSearch, onFilter, onAdd }) => {
  const [searchVal, setSearchVal] = useState('');
  const [category, setCategory] = useState('');
  const [producer, setProdcuer] = useState('');
  const [categories, setCategories] = useState([]);
  const [producers, setProdcuers] = useState([]);

  const handleFilter = async (v, name, callback = () => {}) => {
    await onFilter(v, name);
    callback();
  };

  useEffect(() => {
    const fetchProducers = async () => {
      const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}`);
      if (data) {
        setProdcuers(
          [{'id': null, 'name': '-'}, ...data?.items?.map((i) => ({
            id: i?.id,
            name: i?.name,
          })),
          ]);
      }
    };
    const fetchCategories = async () => {
      const { data } = await getCall(`${API_PRODUCT_CATEGORIES_ROUTE}`);
      if (data) {
        setCategories(
          [{'id': null, 'name': '-'}, ...data?.items?.map((i) => ({
            id: i?.id,
            name: i?.name,
          })),
          ]);
      }
    };
    fetchProducers();
    fetchCategories();
  }, []);

  return (
    <>
      <Wrapper
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <RowWrapper fullWidth style={{ maxWidth: 640, alignItems: 'center' }}>
          <ColumnWrapper fullWidth>
            <Select
              placeholder="Kategorie"
              data={categories}
              onSelect={(f) =>
                handleFilter(f, 'categories', () => setCategory(f))
              }
              value={category}
              name="categories"
            />
          </ColumnWrapper>
          <div style={{ margin: '0 20px' }}></div>
          <ColumnWrapper fullWidth>
            <Select
              placeholder="Producenci"
              data={producers}
              onSelect={(f) =>
                handleFilter(f, 'producers', () => setProdcuer(f))
              }
              value={producer}
              name="producers"
            />
          </ColumnWrapper>
          <Button
            disabled={!(producer || category)}
            onClick={() => {
              setProdcuer('');
              setCategory('');
              handleFilter();
            }}
            style={{ margin: '0 10px', minWidth: 120 }}>
            Usuń filtry
          </Button>
        </RowWrapper>
        <RowWrapper style={{ marginRight: 10 }}>
          <SearchField
            value={searchVal}
            onChange={({ target }) => setSearchVal(target.value)}
          />
          <PrimaryButton
            style={{ marginLeft: 10 }}
            color="secondary"
            onClick={() => handleSearch(searchVal)}>
            Szukaj
          </PrimaryButton>
          <Button
            onClick={onAdd}
            style={{ color: 'white', marginLeft: 10 }}
            variant="contained"
            color="primary">
            Dodaj
          </Button>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

ListToolBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default ListToolBar;
