import React from 'react';
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import {Wrapper} from "../../../../../components/ListToolBarComponents";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import routerHistory from "../../../../../constants/settings/routerHistory";
import BackButton from "../../../../../components/buttons/back-button/BackButton";

export default () => {

    return (
        <>
            <Wrapper style={{justifyContent: 'space-between'}}>
                <RowWrapper style={{alignItems: "center"}}>
                    <BackButton
                        text="Wróć"
                        onClick={() => routerHistory.goBack()}
                    />
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit">
                            Produkty
                        </Link>
                    </Breadcrumbs>
                </RowWrapper>
            </Wrapper>
        </>
    );
};
