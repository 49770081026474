import moment from 'moment';
import 'moment/locale/pl';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  API_CALENDAR_EVENT_ROUTE,
  API_CALENDAR_ROUTE,
  API_PROJECTS_ROUTE
} from '../../../../../constants/routes';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import { getCall, postCall, putCall } from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import FloatingSaveBar from "../../../../../components/FloatingSaveBar";
import Notes from '../notes/Notes';
import WorkersList from '../workers/WorkersList';
import returnFields from './fields';
import { useLocation, useParams } from 'react-router-dom';
import RowWrapper from '../../../../../components/wrappers/RowWrapper';
import NotesPanel from '../../../../../components/notes/NotePanel';
import PaperContainer from '../../../../../components/containers/PaperContainer';
import SlotToolBar from '../tool-bar/SlotToolBar';
import routerHistory from "../../../../../constants/settings/routerHistory";

const EventForm = ({ onSubmit }) => {
  const { eventId } = useParams();

  const [fields, setFields] = useState([]);
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(true);
  const [openedNotes, setOpenedNotes] = useState(true);
  const [opts, setOpts] = useState({});
  const [initialValues, setInitialValues] = useState([]);
  const [notes, setNotes] = React.useState({
    notes: []
  });
  const location = useLocation();


  const fetchItems = async (url) => {
    const { data } = await getCall(url);
    if (data) {
      const items = data?.items.map((item, index) => ({
        key: index,
        value: item.name,
        name: item.name,
        id: item.id,
      }));
      return items;
    }
  };

  const formRef = useRef();

  useEffect(() => {
    const init = async () => {
      
      let allDay = false;
      if(eventId) {
        
        const r = await getCall(API_CALENDAR_EVENT_ROUTE + '/' + eventId);
        if(r && r?.data?.calendar_event) {
          const data = r?.data?.calendar_event;
          setInitialValues({
            start: data?.start * 1000,
            end: data?.stop * 1000,
            allDay: data?.allDay,
            calendar: data?.calendar,
            project: data?.project,
            priority: data?.priority,
            duration: data?.duration,
            description: data?.description,
            title: data?.title,
            files: data?.filesData,
            sharedWith: data?.sharedWith?.map((item) => ({id: item?.id, name: item?.name}))
          });
          setNotes({notes: data?.notes || []});

          allDay = data?.allDay;
          setFields(returnFields(allDay, data?.start || null));
        }
      } else {
        if(location?.state?.start && location?.state?.end) {
          setInitialValues({
            ...initialValues,
            start: location?.state?.start,
            end: location?.state?.end
          })
        }
        setFields(returnFields(allDay, null));
      }

    };
    init();
  }, []);

  const onSave = async () => {
    const data = formRef.current.getFormValues();

    const body = {
      start: moment(data.start).unix(),
      stop: moment(data.end).unix(),
      allDay: data?.allDay,
      calendar_id: data.calendar?.id,
      project_id: data.project?.id,
      duration: data.duration,
      description: data.description,
      priority: data.priority,
      title: data.title,
      filesData: data.files,
      sharedWith: data?.sharedWith?.map((item) => item?.id)
    };

    if(eventId) {
      const r = await putCall(API_CALENDAR_EVENT_ROUTE + '/' + eventId, body);
      if (r.data && !r.data.status) {
        toast.success(`Edytowano wydarzenie`);
      } else {
        toast.success(`Nie można edytować wydarzenia.`);
      }
    } else {
      const r = await postCall(API_CALENDAR_EVENT_ROUTE, body);
      const id = r?.data?.calendar_event?.id
      if (!r.data.status && id) {
        toast.success(`Dodano nowe wydarzenie do kalendarza.`);
        routerHistory.push(`/calendar`)
      } else {
        toast.success(`Nie można dodać wydarzenia.`);
      }
    }

  };

  const countDisabled = (f, v) => {
    const data = {...formRef.current.getFormValues()};
    data[f] = v;

    if(f === 'duration' || (f === 'start' && data['duration'])) {

      const change = (f === 'duration') ? v : data['duration'];

      switch(change) {
        case '30min': {
            formRef.current.setFormValue('end', moment(data?.start).add(30, 'minutes').toDate());
            console.log(moment(data?.start).add(30, 'minutes').toDate());
          break;
        }
        default: {
          formRef.current.setFormValue('end', moment(data?.start).add(change, 'hours').toDate());
          console.log(moment(data?.start).add(change, 'hours').toDate());

          break;
        }
      }
    }

    setDisabled(!(data?.start && data?.end && data?.title && data?.start <= data?.end && data?.calendar?.id));

    if(data?.allDay) {
      setFields(returnFields(true, data?.start));
    } else {
      setFields(returnFields(false, data?.start));
    }
  }

  const fetchNotes = async () => {
    const r = await getCall(API_CALENDAR_EVENT_ROUTE + '/' + eventId);

    if(r && r?.data?.calendar_event) {

      const data = r?.data?.calendar_event;
      setNotes({notes: data?.notes || []});
    }
  }

  return (
    <>    
      <SlotToolBar id={eventId} title={initialValues.title} toggleDrawer={() => setOpenedNotes(!openedNotes)} />
      <RowWrapper style={{ alignItems: 'flex-start'}}>
        <ColumnWrapper width={openedNotes && eventId ? '60%' : '100%'}>
          <PaperContainer title="Wydarzenie">
          <DynamicForm ref={formRef} initialValues={initialValues} fields={fields} small avoidTabs={true} containers={false} onChangeMiddleware={(f,v) => countDisabled(f, v)} />
          </PaperContainer>
        </ColumnWrapper>
        {
          eventId &&  <ColumnWrapper width={openedNotes && eventId ? '35%' : '0%'} style={{ overflowX: (openedNotes ? 'auto' : 'hidden' ) }}>
            <NotesPanel fetchNotes={fetchNotes} data={notes} hideAdditional={true} calendar_event_id={eventId} />
          </ColumnWrapper>
        }
      </RowWrapper>
      <FloatingSaveBar onClick={onSave} disabled={disabled} />
    </>
  );
};

export default EventForm;
