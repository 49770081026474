import { Checkbox, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import ColumnWrapper from '../../wrappers/ColumnWrapper';
import RowWrapper from '../../wrappers/RowWrapper';

const OfferTextCheckbox = ({
    defaultText,
    textWidth,
    name,
    value,
    onChange,
    defaultText2 = null,
    valueText,
    valueText2,
}) => {

    const [first, setFirst] = useState(false);

    const [currState, setCurrState] = useState({
        checked: value?.checked || false,
        text: value?.text || defaultText,
        secondText: value?.secondText || defaultText2
    });

    /*React.useEffect(() => {
        onChange(name, currState);
    }, [currState]);*/

    React.useEffect(() => {
        setCurrState({
            checked: value?.checked,
            text: value?.text || defaultText,
            secondText: value?.secondText || defaultText2
        });
    }, [value]);

    return (
        <>
        <RowWrapper>
            <Checkbox
                name={name}
                checked={currState?.checked}
                value={currState?.checked}
                onChange={({ target }) => onChange(name, ({...currState, checked: target.checked }))}
                />
            {value?.checked && <ColumnWrapper>
                <TextField multiline variant="outlined" name={name+'_text'} value={currState?.text}
                defaultValue={defaultText} style={{ minWidth: textWidth }}
                onChange={({ target }) => onChange(name, ({...currState, text: target.value }))} />
                {defaultText2 && <TextField multiline variant="outlined" name={name+'_text2'} value={currState?.secondText}
                defaultValue={defaultText2} style={{ minWidth: textWidth }}
                onChange={({ target }) => onChange(name, ({...currState, secondText: target.value }))} />}
            </ColumnWrapper>}
            
        </RowWrapper>
        </>
    );
};

export default OfferTextCheckbox;
