const productCardFieldsPeripherals = [{
    "id": 10,
    "name": "Informacje o produkcie (typ: peryferia)",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "index",
                "type": "text",
                "label": "Index",
                "value": null,
                "options": null
            },
            {
                "id": 61,
                "type": "none",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "cena_gazuno",
                "type": "price",
                "label": "Cena zakupu przez Gazuno",
                "value": null,
                "options": null
            },
            { label: 'Waluta - Cena zakupu', name: 'cena_zakupu_waluta', type: 'selectCurrency',
                // options: {
                //     data: [
                //         {id: 'pln', name: 'PLN'},
                //         {id: 'eur', name: 'EUR'},
                //     ]
                // }, value: 'pln',
            },
            {
                "id": 33,
                "name": "desc_ext",
                "type": "text",
                "label": "Opis rozszerzony",
                "value": null,
                options: {
                    attrs: {
                        multiline: true,
                        rows: 5
                    }
                },
                "fullWidth": true,
            },
            {
                "id": 33,
                "name": "terms_gazuno_prod",
                "type": "text",
                "label": "Warunki handlowe Gazuno - producent",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 33,
                "name": " ",
                "label": "Warunki handlowe Gazuno-klient",
                "type": "text",
                "value": null,
                "options": null,
                "fullWidth": true
            },
            {
                "id": 33,
                "name": "usage",
                "type": "text",
                "label": "Ważne informacje/gdzie zastosować",
                "value": null,
                "options": null,
                "fullWidth": true
            },
        ]
    }
},
{
    "id": 16,
    "name": "Izolacja",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "izolacja_nazwa",
                "type": "text",
                "label": "Izolacja - nazwa",
                "value": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "izolacja_cena_netto",
                "type": "text",
                "label": "Cena netto",
                "value": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "taca_ociekowa",
                "type": "text",
                "label": "Taca ociekowa",
                "value": null,
                "fullWidth": true
            },
            {
                "id": 61,
                "name": "taca_ociekowa_netto",
                "type": "text",
                "label": "Cena netto",
                "value": null,
                "fullWidth": true
            },
        ]
    }
},
{
    "id": 16,
    "name": "Tabela kosztów",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 68,
                "name": "produkt_zalaczniki",
                "type": "rowsGroup",
                "label": "Załączniki",
                "value": "[]",
                "options": {
                    "id": 17,
                    "data": [],
                    "fields": [
                        {
                            "id": 69,
                            "name": "zalacznik_nazwa",
                            "type": "text",
                            "label": "Nazwa",
                            "value": null,
                            "options": null
                        },
                        {
                            "id": 70,
                            "name": "zalacznik_plik",
                            "type": "attachment",
                            "label": "Plik",
                            "value": null,
                            "options": null
                        },
                    ],
                    "name": "zalaczniki"
                }
            }
        ]
    }
}];

export default productCardFieldsPeripherals;