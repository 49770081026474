import React, {useRef, useState} from 'react';
import Loader from "../../../../../../components/loader/Loader";
import ExpansionContainer from "../../../../../../components/containers/ExpansionContainer";
import FloatingSaveBar from "../../../../../../components/FloatingSaveBar";
import RowWrapper from "../../../../../../components/wrappers/RowWrapper";
import ColumnWrapper from "../../../../../../components/wrappers/ColumnWrapper";
import DynamicForm from "../../../../../../components/dynamic-forms/DynamicForm";
import templateFields from "./TemplateFields";
import TextField from "@material-ui/core/TextField";
import {formToRequest} from "../../../../../../components/dynamic-forms/field-transformer";
import {API_TEMPLATES_ROUTE} from "../../../../../../constants/routes";
import {toast} from "react-toastify";
import routerHistory from "../../../../../../constants/settings/routerHistory";
import {postCall, putCall} from "../../../../../../components/indicator/Indicator";
import {useParams} from "react-router-dom";


export default ({data, fields}) => {

    const {id} = useParams();
    const [name, setName] = useState(data?.name || '');
    const [description, setDescription] = useState(data?.description || '');
    const formRef = useRef();

    const onSubmit = async () => {
        const callRepairer = async (fn) => {
            const formVals = formRef.current.getFormValues();
            const r = await fn(`${API_TEMPLATES_ROUTE}${id ? ('/' + id) : ''}`, {
                id,
                name,
                description,
                fields: formToRequest(formVals),
            });
            if (r.data && !r.data.status) {
                toast.success(`Zapisano szablon ${name}`);
                routerHistory.push('/service/templates/reviews')
            }
        };

        if (id) {
            await callRepairer(putCall);
        } else {
            await callRepairer(postCall);
        }
    };

    return <>
        <ExpansionContainer title="Szablon">
            <RowWrapper style={{width: '100%', marginBottom: '30px'}}>
                <ColumnWrapper fullwidth>
                    <TextField
                        label="Nazwa"
                        value={name}
                        type="text"
                        variant="outlined"
                        onChange={({target: {value}}) => setName(value)}
                    />
                    <TextField
                        label="Notatka"
                        value={description}
                        type="text"
                        multiline
                        variant="outlined"
                        rows={5}
                        onChange={({target: {value}}) => setDescription(value)}
                    />
                    <DynamicForm ref={formRef} fields={templateFields} initialValues={fields} avoidTabs={true}/>
                    <TextField
                        label="Dodatkowe stałe pole"
                        value="Części na gwarancji"
                        type="text"
                        variant="outlined"
                        disabled={true}
                    />
                    <TextField
                        label="Dodatkowe stałe pole"
                        value="Części nie na gwarancji"
                        type="text"
                        variant="outlined"
                        disabled={true}
                    />
                    <TextField
                        label="Dodatkowe stałe pole"
                        value="Notatka"
                        type="text"
                        variant="outlined"
                        disabled={true}
                    />
                </ColumnWrapper>
            </RowWrapper>
        </ExpansionContainer>
        <FloatingSaveBar disabled={!name} onClick={onSubmit}/>
    </>
};