import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1.1rem',
    },
    subtitle1: {
      fontSize: '1.05rem',
    },
    subtitle2: {
      fontSize: '.85rem',
    },
    body1: {
      fontSize: '0.8rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
    button: {
      fontSize: '0.75rem',
    },
    caption: {
      fontSize: '0.7rem',
    },
    overline: {
      fontSize: '0.7rem',
    },
  },
  props: {
    MuiButton: {
      size: 'small',
      style: {
        padding: '6px',
      },
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiButtonBase: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiStepButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiInputBase: {
      margin: 'dense',
      style: {fontSize: 12}
    },
    MuiTableCell: {
      size: 'small',
    },
    MuiInputLabel: {
      margin: 'dense',
      style: {fontSize: 12}
    },
    MuiListItem: {
      dense: false,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
  },
  palette: {
    primary: {
      light: '#7b848c',
      //main: '#5b6670',
      main: '#f39911',
      dark: '#3f474e',
      contrastText: '#000',
    },
    secondary: {
      main: '#78909c',
      contrastText: '#000',
    },
  },
  shadows: [
    'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    ...Array(22).fill('rgba(33, 35, 38, 0.1) 0px 10px 10px -10px')
  ]
});

export default theme;
