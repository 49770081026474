import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import SearchField from "../../../../../components/inputs/search-field/SearchField";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { Wrapper } from "../../../../../components/ListToolBarComponents";

const ListToolBar = ({ handleSearch, onAdd }) => {
  const [searchVal, setSearchVal] = useState('');
  return (
    <>
      <Wrapper>
        <RowWrapper style={{ marginRight: 10 }}>
          <SearchField
            value={searchVal}
            onChange={({ target }) => setSearchVal(target.value)}
          />
          <PrimaryButton
            style={{ marginLeft: 10 }}
            color="secondary"
            onClick={() => handleSearch(searchVal)}>
            Szukaj
          </PrimaryButton>
        </RowWrapper>
        <Button
          onClick={onAdd}
          style={{ color: 'white' }}
          variant="contained"
          color="primary">
          Dodaj
        </Button>
      </Wrapper>
    </>
  );
};

ListToolBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default ListToolBar;
