import { Avatar, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { useStoreState, useStoreActions } from 'easy-peasy';
import UserImage from '../../../../components/user-image/UserImage';

const ToolBar = () => {

  const { setLoggedInUser, setIsAuthenticated } = useStoreActions((actions) => ({
    setLoggedInUser: actions.auth.setLoggedInUser, 
    setIsAuthenticated: actions.auth.setIsAuthenticated
  }));

  const handleLogOut = () => {
    setIsAuthenticated(false);
    setLoggedInUser(null);
    localStorage.removeItem('loginToken');
  };

  const username = useStoreState((state) => state.auth?.loggedInUser?.username);

  return (
    <>
      <RowWrapper style={{ justifyContent: 'space-between' }}>
        <RowWrapper style={{ alignItems: 'center', paddingLeft: '25px', paddingRight: '25px' }}>
          <UserImage
            name={username}
          />        
          <Typography style={{ margin: '0 0 0 15px' }} paragraph>
            {username}
          </Typography>
        </RowWrapper>
        <PrimaryButton
          onClick={handleLogOut}
          color="secondary"
          icon={<ExitToAppIcon />}>
          Wyloguj
        </PrimaryButton>
      </RowWrapper>
    </>
  );
};

export default ToolBar;
