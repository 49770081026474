import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    TextField,
    Typography
} from '@material-ui/core';
import React, {
    useRef,
    useState
} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {API_DOCUMENTS_ROUTE} from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {postFileCall} from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {transformDocument} from "../../tools/fetchDirectoryContent";
import {FILE_TYPE} from "../../../../constants/directories/types";
import uniqueId from "../../../../tools/uniqueId";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

export default ({parentId, directory, onSave, children, buttonSize = 'small', buttonTooltip = 'Dodaj plik do katalogu'}) => {
    const {button: Button} = children || {};
    const [isOpen, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const fileRef = useRef();

    const handleClose = () => {
        setOpen(false);
        setName('')
        setDescription('')
    };
    const handleOpen = () => setOpen(true);
    const changeFile = () => fileRef.current.click();

    const uploadFile = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            formData.append('file', fileRef.current.files[0]);
            if(parentId) {
                formData.append('parent', parentId);
            }

            if (directory) formData.append('directory', directory);

            const {data: {file} = {}} = await postFileCall(
                API_DOCUMENTS_ROUTE,
                formData,
                undefined,
                undefined,
                true,
            );

            if (onSave && file) {
                onSave(transformDocument({type: FILE_TYPE, uuid: uniqueId()})(file))
            }

            handleClose()
        } catch (error) {
            toast.error('Wystąpił problem z wrzucaniem pliku!');
            onSave && onSave(false)
        }
    };

    return <>
        {Button
            ? <Button handleOpen={handleOpen}/>
            : <Tooltip title={buttonTooltip} placement="top">
                <IconButton onClick={handleOpen}>
                    <NoteAddIcon fontSize={buttonSize}/>
                </IconButton>
            </Tooltip>
        }
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <Container>
                    <Typography
                        style={{fontWeight: 'bold', margin: '0 0 20px'}}
                        variant="body1">
                        Dodawanie pliku
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Nazwa"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        label="Opis"
                        multiline
                        rows={5}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{marginTop: 20}}
                    />
                    <Typography style={{margin: '20px 0 5px'}} variant="body2">
                        Plik
                    </Typography>
                    <input style={{display: 'none'}} ref={fileRef} type="file"/>
                    <PrimaryButton size="small" onClick={changeFile}>
                        Dodaj plik
                    </PrimaryButton>
                </Container>
            </DialogContent>
            <Divider/>
            <DialogActions style={{padding: 20}}>
                <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
                    Anuluj
                </PrimaryButton>
                <PrimaryButton onClick={uploadFile}>Zapisz</PrimaryButton>
            </DialogActions>
        </Dialog>
    </>;
}