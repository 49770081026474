import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { Typography } from '@material-ui/core';

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) => (props.active ? '#a9ee91' : '#78909c')};
  margin-right: 6px;
`;

const ActiveStatus = ({ isActive, status = null }) => {
  return (
    <>
      <RowWrapper>
        <Circle active={isActive} />
        <Typography variant="body2">{status ? status : (isActive ? 'aktywny' : 'nieaktywny')}</Typography>
      </RowWrapper>
    </>
  );
};

ActiveStatus.propTypes = {
  isActive: PropTypes.bool,
  status: PropTypes.string,
};

ActiveStatus.defaultProps = {
  isActive: true,
  status: '',
};

export default ActiveStatus;
