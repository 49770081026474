const STATUS_TYPES = [
  {
    id: 0,
    name: 'Nowa',
  },
  {
    id: 1,
    name: 'Wysłana',
  },
  {
    id: 2,
    name: 'Do poprawy',
  },
  {
    id: 3,
    name: 'Błędna',
  },
  {
    id: 4,
    name: 'Zamówiona',
  },
];

const OFFER_TYPES = [
  {
    id: 'automatyka',
    name: 'Automatyka',
  },
  {
    id: 'urzadzenia',
    name: 'Urządzenia',
  },
  {
    id: 'peryferia',
    name: 'Peryferia',
  },
  {
    id: 'scalona',
    name: 'Oferta scalona',
  },
  {
    id: 'serwis',
    name: 'Serwis',
  },
];

export { STATUS_TYPES, OFFER_TYPES };
