import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import BackButton from "../../components/buttons/back-button/BackButton";
import ExpansionContainer from "../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import {getCall, putCall} from "../../components/indicator/Indicator";
import Loader from "../../components/loader/Loader";
import {toast} from "react-toastify";
import routerHistory from "../../constants/settings/routerHistory";
import {API_PRODUCTS_ROUTE, API_REPAIRS_ACTIONS_DEVICES_ROUTE, API_REPAIRS_ROUTE} from "../../constants/routes";
import ActionDeviceHistoryTable from "./components/repairs/tables/history/ActionDeviceHistoryTable";
import FloatingSaveBar from "../../components/FloatingSaveBar";

export default () => {

    const [loading, setLoading] = useState(true);
    const [device, setDevice] = useState({});
    const [repair, setRepair] = useState({});
    const [history, setHistory] = useState([]);
    const {id, deviceId} = useParams();
    const [devices, setDevices] = useState(([]));

    useEffect(() => {
        const loadData = async () => {
            await fetchData();
            await fetchDevices()
            setLoading(false);
        };
        loadData()
    }, [id]);

    const deviceInfo = useMemo(() => {
        const {product: {name} = {}, serial_no} = device;
        if (name && serial_no) {
            return `${name} (${serial_no})`
        }
        return name || '-'
    }, [device]);

    const groupInfo = useMemo(() => {
        const {group_name, group_serial_no} = device;
        if (group_name && group_serial_no) {
            return `${group_name} (${group_serial_no})`
        }
        return group_name || '-'
    }, [device]);

    const findDeviceIndex = useCallback(
        ({id: searchesId}) => history.findIndex(({id}) => id === searchesId),
        [history]);

    const onUpdateHistory = useCallback(row => {
        const index = findDeviceIndex(row);
        if (~index) {
            const newHistory = [...history];
            newHistory.splice(index, 1, row);
            setHistory(() => newHistory)
        }
    }, [findDeviceIndex, history]);


    const fetchData = async () => {
        try {
            const {data: {device, repair, history}} = await getCall(`${API_REPAIRS_ROUTE}/${id}/devices/${deviceId}/history`);
            setDevice(() => device || {});
            setRepair(() => repair || {});
            setHistory(() => history || []);
        } catch {
            toast.error('Wystąpił błąd podczas pobierania danych.');
        }
    };

    const fetchDevices = async () => {

        try {
            const { data } = await getCall(`${API_PRODUCTS_ROUTE}?limit=-1`);
            if (data) {
                setDevices(data.items);
            }
        } catch {
            toast.error('Wystąpił błąd podczas pobierania możliwych urządzeń.');
        }

    };

    const save = async () => {
        try {
            await putCall(API_REPAIRS_ACTIONS_DEVICES_ROUTE,{devices: history});
            toast.success('Zapisano akcję.');
            routerHistory.push(`/service/repairs/edit/${id}`)
        } catch {
            toast.error('Wystąpił błąd podczas zapisu.');
        }
    };

    return (
        <>
            {loading
                ? <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0}}><Loader/></div>
                : <>
                    <ColumnWrapper>
                        <RowWrapper>
                            <BackButton
                                text="Wróć do karty serwisowej"
                                onClick={() => routerHistory.push(`/service/repairs/edit/${id}`)}
                            />
                        </RowWrapper>
                        <RowWrapper>
                            <ColumnWrapper>
                                <ExpansionContainer title="Pojedyńcze urządzenie - historia serwisowa">
                                    <ColumnWrapper width="100%">
                                        <div>Nazwa Projektu: {repair?.projectName || '-'}</div>
                                        <div>Adres: {repair?.address || '-'}</div>
                                        <div>Urządzenie: {deviceInfo}</div>
                                        <div>Link: {groupInfo}</div>
                                        <ActionDeviceHistoryTable devices={devices} history={history} onUpdate={onUpdateHistory}/>
                                    </ColumnWrapper>
                                </ExpansionContainer>
                            </ColumnWrapper>
                        </RowWrapper>
                    </ColumnWrapper>
                    <FloatingSaveBar onClick={save} />
                </>
            }
        </>
    );
};