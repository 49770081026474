import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import { API_OFFERS_ROUTE, API_PRODUCTS_ROUTE, API_PROJECT_DEVICES_ROUTE } from '../../../../../constants/routes';
import { getCall, postCall } from "../../../../../components/indicator/Indicator";
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import { mergedOfferGroups } from '../offer-merged';
import DynamicForm from '../../../../../components/dynamic-forms/DynamicForm';
import { formToRequest } from '../../../../../components/dynamic-forms/field-transformer';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
  add: {
    marginTop: '1em',
    marginBottom: '1em'
  }
}));

const OfferMergedGrouping = ({setRootRows, formRef, offer, initialValues, onChangeMiddleware}) => {
  const classes = useStyles();

  const { id } = useParams();

  const ref = useRef();

  const [rows, setRows] = useState([]);

  const removeRow = (uuid) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == uuid);
    if(i >= 0) {
      localRows.splice(i, 1);
      setRows(localRows);
    }
  };

  React.useEffect(() => {
    setRootRows(rows);
  }, [rows]);

  React.useEffect(() => {
    setRows(offer.devices?.map(row => ({
      ...row,
      mergedGroup: row.mergedGroup || 0,
      mergedLp: row.mergedLp || '',
      customProductName: row.customProductName || '',
      customModelName: row.customModelName || '',
      amount: row.amount || 1,
      customProductDesc: row.customProductDesc || '',
      uuid: row?.id,
        })) || []);
  }, [initialValues]);

  const refreshDevices = async () => {
    const formValues = formRef.current ? formRef.current.getFormValues() : [];

    const body = {
      fields: formToRequest(formValues || {}),
    }
    const { data } = await postCall(`${API_OFFERS_ROUTE}/${id}/prepare-merged-devices`, body, false);
    
    setRows(Object.values(data?.item?.devices)?.map(row => ({
      ...row,
      mergedGroup: row.mergedGroup || 0,
      mergedLp: row.mergedLp || '',
      customProductName: row.customProductName || '',
      customModelName: row.customModelName || '',
      amount: 1,
      customProductDesc: row.customProductDesc || '',
      uuid: row?.id,
        })) || []);
  }

  const editRow = (name, v, index) => {
    let localRows = [...rows];
    const i = localRows.findIndex(item => item.uuid == index);
    if(i >= 0) {
      localRows[i][name] = v;
    }
    setRows(localRows);
  }

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Grupowanie</TableCell>
        <TableCell>LP.</TableCell>
        <TableCell>Nazwa urządzenia</TableCell>
        <TableCell>Model</TableCell>
        <TableCell>Liczba sztuk</TableCell>
        <TableCell>Opis</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
        <ColumnWrapper fullWidth>
          <Box py={3} style={{textAlign: 'right'}}>
            <PrimaryButton variant="primary" onClick={() => refreshDevices()}><RefreshIcon /> Odśwież urządzenia</PrimaryButton>
          </Box>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            style={{marginBottom: '30px'}}>
            {renderHeader()}
            <TableBody>
              {rows.map((item, i) => <OfferMergedGroupingRow i={item} index={item.uuid}  key={item.uuid} editRow={editRow} removeRow={removeRow} />)}
            </TableBody>
          </Table>
          <DynamicForm initialValues={initialValues} fields={mergedOfferGroups} onChangeMiddleware={onChangeMiddleware} ref={ref} small={true} avoidTabs={true} />
        </ColumnWrapper>
    </>
  );
};

const OfferMergedGroupingRow = ({i, index, data, editRow, removeRow, ...props}) => {

    return (
    <TableRow key={i.uuid+'row'}>
      <TableCell>
        {returnSpecificField('text', { attrs: { type: 'number'}}, 'mergedGroup', i.mergedGroup, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell>
        {returnSpecificField('text', {}, 'mergedLp', i.mergedLp, (e, v) => {editRow(e, v, index)} )}
      </TableCell>
      <TableCell>
        {i.customProductName || i.product?.product_category?.name || '-'}
      </TableCell>
      <TableCell>
        {i.customModelName || i.product?.name || '-'}
      </TableCell>
      <TableCell>
        {i.amount || 'b/d'}
      </TableCell>
      <TableCell>
        {i.customProductDesc || i.product?.description || '-'}
      </TableCell>
    </TableRow>
    );
}

export default OfferMergedGrouping;
