import { Typography } from '@material-ui/core';
import React from 'react';
import ActionContainer from "../../components/action-container/ActionContainer";
import routerHistory from "../../constants/settings/routerHistory";

const AdministrationConfigurationScreeen = () => (
  <>
    <Typography>Ustawienia systemu CRM</Typography>
    <ActionContainer
      header="Edytuj słowniki"
      buttonText="Edytuj"
      onClick={() =>
        routerHistory.push('/administration/configuration/dictionaries')
      }
      label="Wprowadź wartości, którymi można uzupełnić formularze"
    />
    <ActionContainer
      header="Pola dynamiczne"
      buttonText="Zarządzaj"
      onClick={() =>
        routerHistory.push('/administration/configuration/dynamic-fields')
      }
      label="Dodawaj pola do istniejących formularzy"
    />
    <ActionContainer
      header="Grupy pól dynamicznych"
      buttonText="Zarządzaj"
      onClick={() =>
        routerHistory.push('/administration/configuration/dynamic-groups')
      }
      label="Grupuj pola w formularze"
    />
    <ActionContainer
      header="Ustawienia importu (Marketing)"
      buttonText="Zobacz"
      label="Wprowadź dane do zintegrowania usług Marketing Automation"
    />
  </>
);

export default AdministrationConfigurationScreeen;
