import uniqid from 'uniqid'

function createData(lp, company, user, date, offer, discount, warranty, use, status) {
  return { lp, company, user, date, offer, discount, warranty, use, status, id: uniqid() };
}

const rows = [
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
  createData('1', 'Frima A', 'Piotr', '21.02.2020', '3a32aB', '30%', '2 lata', '-', 'w trakcie'),
];

const headCells = [
  { id: 'lp', numeric: false, disablePadding: false, label: 'L.P.' },
  { id: 'company', numeric: false, disablePadding: false, label: 'Nazwa firmy' },
  { id: 'user', numeric: false, disablePadding: false, label: 'Użytkownik' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Data dodania' },
  { id: 'offer', numeric: false, disablePadding: false, label: 'Nr oferty' },
  { id: 'discount', numeric: false, disablePadding: false, label: 'Rabat' },
  { id: 'warranty', numeric: false, disablePadding: false, label: 'Gwarancja' },
  { id: 'use', numeric: false, disablePadding: false, label: 'Użyj/Podgląd' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

export { rows, headCells };