import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getCall, postCall } from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import ListToolBar from './components/list/ListToolBar';
import { API_TASKS_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import TaskTick from '../../components/task-tick/TaskTick';
import TaskSearchFilters from './components/list/search-filters/TaskSearchFilters';
import RowWrapper from '../../components/wrappers/RowWrapper';
import serializeQuery from '../../tools/serializeQuery';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import { Update } from '@material-ui/icons';
import { Dialog, Paper, Popper, TextField } from '@material-ui/core';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Add from '@material-ui/icons/Add';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';
import { toast } from 'react-toastify';
import CompanyLabel from '../../components/notes/CompanyLabel';
import ProjectLabel from '../../components/notes/ProjectLabel';
import { green, red } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const TYPES = ['oferta', 'spotkanie', 'telefon', 'cykliczny kontakt', 'pilne'];

const TasksScreen = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(null);
  const [types, setTypes] = useState([]);
  const [showDateChangePopper, setShowDateChangePopper] = useState(false);
  const [changeDateAnchorEl, setChangeDateAnchorEl] = useState(null);
  const [newDate, setNewDate] = useState(new Date());

  const returnProjectBadge = (name, id) => name ? <ProjectLabel projectName={name} id={id} /> : null;

  const returnCompanyBadge = (name, id) => name ? <CompanyLabel companyName={name} id={id} /> : null;

  const fetchTasks = async (url = `${API_TASKS_ROUTE}?statuses[]=0`) => {
    const { data } = await getCall(`${url}&results_on_page=20`);
    const d = await getCall(API_TASKS_ROUTE);
    if(d.data) {
      let customTypes = [];
      customTypes = d.data.items?.map(item => item?.type);
      customTypes.push(...TYPES);
      setTypes([...new Set(customTypes)])
    }
    if (data?.items) {
      setCount(data?.total);
      const r = data?.items.map(item => {
        return ({
        id: item.id,
        done: (<TaskTick id={item.id} status={item?.status} waitOnFeedback={item?.waitOnFeedback} feedbackComment={item?.feedbackComment} />),
        desc: (<u onClick={() => routerHistory.push(`/tasks/edit/${item.id}`)}>{item?.description}</u>),
        priority: ['niski', 'średni', 'pilne']?.[item?.priority - 1] || '-',
        isFeedback: item?.isFeedback === null ? '-' : (item?.isFeedback ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />),
        for: item?.for?.username,
        principal: item?.principal?.username,
        start_date: (item?.startDate ? moment(item?.startDate?.date).format('YYYY-MM-DD') : '-'),
        deadline: (item?.deadline ? moment(item?.deadline?.date).format('YYYY-MM-DD') : '-'),
        updated_at: (item?.updatedAt ? moment(item?.updatedAt?.date).format('YYYY-MM-DD HH:mm') : '-'),
        created: (item?.created ? moment(item?.created?.date).format('YYYY-MM-DD HH:mm') : '-'),
        company: item?.company?.name ? returnCompanyBadge(item?.company?.name, item?.company?.id) : '-',
        project: item?.project?.name ? returnProjectBadge(item?.project?.name, item?.project?.id) : '-',
        type: item?.type,
        status: item?.status
      })});
      setRows(r);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const queryPath = serializeQuery(v);
      const { data } = await getCall(`${API_TASKS_ROUTE}?${queryPath}`);
      if (data) {
        setCount(data?.total);
        const r = data?.items.map((item) => ({
          id: item.id,
          done: (<TaskTick id={item.id} status={item?.status} waitOnFeedback={!!item?.waitOnFeedback} feedbackComment={item?.feedbackComment} />),
          desc: (<u onClick={() => routerHistory.push(`/tasks/edit/${item.id}`)}>{item?.description}</u>),
          priority: ['niski', 'średni', 'pilne']?.[item?.priority - 1] || '-',
          isFeedback: item?.isFeedback === null ? '-' : (item?.isFeedback ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />),
          for: item?.for?.username,
          principal: item?.principal?.username,
          start_date: (item?.startDate ? moment(item?.startDate?.date).format('YYYY-MM-DD') : '-'),
          deadline: (item?.deadline ? moment(item?.deadline?.date).format('YYYY-MM-DD') : '-'),
          updated_at: (item?.updatedAt ? moment(item?.updatedAt?.date).format('YYYY-MM-DD HH:mm') : '-'),
          created: (item?.created ? moment(item?.created?.date).format('YYYY-MM-DD HH:mm') : '-'),
          company: item?.company?.name ? returnCompanyBadge(item?.company?.name, item?.company?.id) : '-',
          project: item?.project?.name ? returnProjectBadge(item?.project?.name, item?.project?.id) : '-',
          type: item?.type,
          status: item?.status
        }));
        setRows(r);
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const changeDates = async (numSelected, date) => {

    if(date) {
      const { data } = await postCall(`${API_TASKS_ROUTE}/new-dates`, {
        selected: numSelected,
        newDate: date
      });

      if(data.success) {
        const newRows = [...rows].map((row => (numSelected.includes(row.id) ? {...row, deadline: moment(newDate).format('YYYY-MM-DD')} : row)));
    
        setRows(newRows);
  
      } else {
        
        toast.error(`Nie mogę zmienić dat`);
      }
    }

    

    setNewDate(new Date());
    setShowDateChangePopper(false);
  }

  const customActions = [
    {
      label: 'Zmień daty',
      title: 'Zmień daty',
      handler: (e, numSelected) => {
        console.log('wybrane', numSelected);
        setShowDateChangePopper(true);
      },
      icon: <Update />,
      children: (numSelected) => (
        <>
          <Dialog
            open={showDateChangePopper}
            onBackdropClick={() => setShowDateChangePopper(false)}
          >
            <Paper style={{padding: '2em'}}>
              <RowWrapper style={{alignItems: 'center'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                name={'newDate'}
                format="dd.MM.yyyy"
                inputVariant="outlined"
                value={newDate}
                onChange={(d) => setNewDate(d)}
                ampm={false}
              />
            </MuiPickersUtilsProvider>
            <PrimaryButton style={{marginLeft: '10px', marginTop: '2px'}}
            onClick={async (e) => changeDates(numSelected, newDate)} disabled={!newDate}>
              <Add size={14} /> Zapisz 
            </PrimaryButton>
              </RowWrapper>
            </Paper>
          </Dialog>
        </>
      )
    }
  ]

  return (
    <>
      <ListToolBar /><RowWrapper>
        <ColumnWrapper style={{maxWidth: '350px'}}>
          <TaskSearchFilters types={types} handleSearch={handleSearch} />
        </ColumnWrapper>
        <ColumnWrapper fullWidth size="100%">
      <BasicTable
        headCells={[
        { id: 'done', numeric: false, disablePadding: false, label: ' ', align: 'center' },
          { id: 'desc', numeric: false, disablePadding: false, label: 'zadanie', align: 'center' },
          { id: 'priority', numeric: false, disablePadding: false, label: 'Piorytet', align: 'center' },
          { id: 'isFeedback', numeric: false, disablePadding: false, label: 'Feedback', align: 'center' },
        {
          id: 'for',
          numeric: false,
          disablePadding: false,
          label: 'przypisano do',
          align: 'center'
        },
          {
            id: 'principal',
            numeric: false,
            disablePadding: false,
            label: 'przypisane przez',
            align: 'center'

          },
        {
          id: 'startDate',
          numeric: false,
          disablePadding: false,
          label: 'rozpoczęcie',
          align: 'center'

        },
        {
          id: 'deadline',
          numeric: false,
          disablePadding: false,
          label: 'deadline',
          align: 'center'

        },
          {
            id: 'updatedAt',
            numeric: false,
            disablePadding: false,
            label: 'data zmiany statusu',
            align: 'center'

          },
          {
            id: 'created',
            numeric: false,
            disablePadding: false,
            label: 'data dodania',
            align: 'center'

          },
        { id: 'company', numeric: false, disablePadding: false, label: 'firma', align: 'center'
        },
        { id: 'project', numeric: false, disablePadding: false, label: 'projekt', align: 'center'
        },
        {
          id: 'type',
          numeric: false,
          disablePadding: false,
          label: 'typ',
          align: 'center'
        }]}
        rows={rows}
        hideKeys={['status']}
        highlightCondition={(row) => moment(row.deadline) < moment() && row.status === 0}
        onChangePage={async (p) =>
          await fetchTasks(`${API_TASKS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        customActions={customActions}
        rowsPerPageLenth={20}
        size="medium"
      />
      </ColumnWrapper>
      </RowWrapper>
    </>
  );
};

export default TasksScreen;
