import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCall } from "../../components/indicator/Indicator";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import BasicData from './components/repiarers/details/BasicData';
import DetailsToolBar from './components/repiarers/details/DetailsToolBar';
import Notes from './components/shared/Notes';
import {
  API_DYNAMIC_FIELDS_ROUTE,
  API_REPAIRERS_ROUTE
} from '../../constants/routes';
import {responseToForm} from "../../components/dynamic-forms/field-transformer";
import RowWrapper from '../../components/wrappers/RowWrapper';

const RepairersDetailsScreen = ({ preview = false, repairerId = null, onClose }) => {
  const [editData, setEditData] = useState();
  const { id } = useParams();
  const [fields, setFields] = useState([]);

  const fetchEditData = useCallback(async () => {
    const {data: {object} = {}} = await getCall(`${API_REPAIRERS_ROUTE}/${repairerId ? repairerId : id}`);
    return {
      ...object,
      fields: object && responseToForm(object.fields) || []
    } || [];
  }, [id]);

  useEffect(() => {
    async function fetchFields() {
      if (id && id !== 'new') {
        const editData = await fetchEditData();
        if (editData) {
          setEditData(editData);
          setFields([...(Object.values(editData.fields)), {value: editData?.name, name: 'serwisant_name'}]);
        }
      }
    }
    fetchFields();
  }, [id, fetchEditData]);

  const [open, setOpen] = React.useState(true);
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  if(preview) {
    return (
      <ColumnWrapper width={open ? '60%' : '100%'} style={{ margin: preview ? '0 auto' : 'unset'}}>
        <BasicData
          edit={id !== 'new' ? id : null}
          data={editData}
          fields={fields}
          preview={preview}
          onClose={onClose}
        />
      </ColumnWrapper>
    )
  }

  return (
    <>
    <DetailsToolBar serviceManName={editData?.name} toggleDrawer={toggleDrawer}  />

    <RowWrapper style={{ alignItems: 'flex-start', marginTop: '50px'}}>
      <ColumnWrapper width={open ? '60%' : '100%'}>
        <BasicData
          edit={id !== 'new' ? id : null}
          data={editData}
          fields={fields}
        />
        </ColumnWrapper>
      
      <ColumnWrapper width={open ? '35%' : '0%'} style={{ overflowX: (open ? 'auto' : 'hidden' ), display: (open ? 'block' : 'none' ), position: 'fixed', right: '25px' }}>   
        <Notes repairerId={id} fetchNotes={fetchEditData} data={editData} />
      </ColumnWrapper>

    </RowWrapper>
    </>
  );
};

export default RepairersDetailsScreen;
