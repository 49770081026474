import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Bar from './Bar';
import Header from './Header';
import { getComparator, stableSort } from './helpers';
import ExchangersHeader from './ExchangersHeader';
import HeatExchangerNewRow from '../../../screens/projects/details/heat-exchangers/HeatExchangerNewRow';
import HeatExchangerEditRow from '../../../screens/projects/details/heat-exchangers/HeatExchangerEditRow';
import {postCall} from "../../indicator/Indicator";
import {API_MAIN_ROUTE} from "../../../constants/routes";
import PrimaryButton from "../../buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'grid',
  },
  table: {
    minWidth: 750,
  },
  tableRightBorder : { borderWidth: 0, borderColor: 'red',borderStyle: 'solid'},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowObject: {
    cursor: 'pointer',
  },
}));

const ExchangersTable = ({
  headCells,
  rows,
  onRowClick,
  onChangePage,
  recordsCount,
  onDelete,
  useDialogOnDelete,
  projectId,
  projectName,
}) => {
  const classes = useStyles();
  const [newRows, setNewRows] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setSelected([]);
  }, [rows]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleSelect = (e, row) => {
    e.stopPropagation();
    const { id } = row;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    await onChangePage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - 0 * rowsPerPage);

  const getCells = (row) =>
    Object.keys(row).map((key) =>
      (key !== 'id' && key !== 'project') ? (
        <TableCell key={key} align="left">
          {row[key] ? row[key] : '-'}
        </TableCell>
      ) : null,
    );

  const exportTable = async (format = 'pdf') => {
    const file = await postCall(`${API_MAIN_ROUTE}/export`, {
      format,
      filename: `Wymienniki ${projectName ? ' - ' + projectName.substring(0, 17) : ''}`,
      title: `Wymienniki ${projectName ? ' - ' + projectName.substring(0, 17) : ''}`,
      size: format === 'pdf' ? 'A2' : 'A4',
      orientation: 'landscape',
      columns: exportColumns,
      rows: [...(rows || []), ...newRows].map((row, index) => ({
        "LP": index + 1,
        "rodzaj wymiennika": row?.type || '-',
        "przeznaczenie": row?.purpose || '-',
        "maksymalny spadek ciśnienia": row?.maximumPressureDrop || '-',
        "czynnik po stronie pierwotnej": row?.primarySideMedium || '-',
        "czynnik po stronie wtórnej": row?.secondarySideMedium || '-',
        "spadek temperatury na wymienniku": row?.S1_temperatureDropInExchanger || '-',
        "moc grzewcza": row?.heatingPower || '-',
        "delta zasilanie/powrót": row?.D1_deltaSupplyReturn || '-',
        "1 punkt - projektowe parametry - temp. po stronie pierwotnej - wejście": row?.A1_primarySideTemperatureInput || '-',
        "1 punkt - projektowe parametry - temp. po stronie pierwotnej - wyjście": row?.A1_primarySideTemperatureInput ? row?.A2_primarySideTemperatureOutput : '-',
        "1 punkt - projektowe parametry - temp. po stronie wtórnej - wejście": row?.A1_primarySideTemperatureInput ? row?.A3_secondarySideTemperatureInput : '-',
        "1 punkt - projektowe parametry - temp. po stronie wtórnej - wyjście": row?.A1_primarySideTemperatureInput ? row?.A4_secondarySideTemperatureOutput : '-',
        "2 punkt - sprawdzenie - temp. po stronie pierwotnej - wejście": row?.B1_primarySideTemperatureInput || '-',
        "2 punkt - sprawdzenie - temp. po stronie pierwotnej - wyjście": row?.B1_primarySideTemperatureInput ? row?.B2_primarySideTemperatureOutput : '-',
        "2 punkt - sprawdzenie - temp. po stronie wtórnej - wejście": row?.B1_primarySideTemperatureInput ? row?.B3_secondarySideTemperatureInput : '-',
        "2 punkt - sprawdzenie - temp. po stronie wtórnej - wyjście": row?.B1_primarySideTemperatureInput ? row?.B4_secondarySideTemperatureOutput : '-',
        "moc chłodnicza": row?.coolingPower || '-',
        "delta zasilanie/powrót ": row?.D2_deltaSupplyReturn || '-',
        "1 punkt - projektowe parametry - temp. po stronie pierwotnej - wejście ": row?.C1_primarySideTemperatureInput || '-',
        "1 punkt - projektowe parametry - temp. po stronie pierwotnej - wyjście ": row?.C1_primarySideTemperatureInput ? row?.C2_primarySideTemperatureOutput : '-',
        "1 punkt - projektowe parametry - temp. po stronie wtórnej - wejście ": row?.C1_primarySideTemperatureInput ? row?.C3_secondarySideTemperatureInput : '-',
        "1 punkt - projektowe parametry - temp. po stronie wtórnej - wyjście ": row?.C1_primarySideTemperatureInput ? row?.C4_secondarySideTemperatureOutput : '-',
      })),
    }, [], false);

    if(file.data) {
      const url = URL.createObjectURL(file.data);

      const a = document.createElement('a');
      a.href = url;
      a.download = `Wymienniki ${projectName ? ' - ' + projectName.substring(0, 17) : ''}.${format}` || `download.${format}`;

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
    }

  };

  const exportColumns = [
      'LP',
      'rodzaj wymiennika',
      'przeznaczenie',
      'maksymalny spadek ciśnienia',
      'czynnik po stronie pierwotnej',
      'czynnik po stronie wtórnej',
      'spadek temperatury na wymienniku',
      'moc grzewcza',
      'delta zasilanie/powrót',
      '1 punkt - projektowe parametry - temp. po stronie pierwotnej - wejście',
      '1 punkt - projektowe parametry - temp. po stronie pierwotnej - wyjście',
      '1 punkt - projektowe parametry - temp. po stronie wtórnej - wejście',
      '1 punkt - projektowe parametry - temp. po stronie wtórnej - wyjście',
      '2 punkt - sprawdzenie - temp. po stronie pierwotnej - wejście',
      '2 punkt - sprawdzenie - temp. po stronie pierwotnej - wyjście',
      '2 punkt - sprawdzenie - temp. po stronie wtórnej - wejście',
      '2 punkt - sprawdzenie - temp. po stronie wtórnej - wyjście',
      'moc chłodnicza',
      'delta zasilanie/powrót ',
      '1 punkt - projektowe parametry - temp. po stronie pierwotnej - wejście ',
      '1 punkt - projektowe parametry - temp. po stronie pierwotnej - wyjście ',
      '1 punkt - projektowe parametry - temp. po stronie wtornej - wejście ',
      '1 punkt - projektowe parametry - temp. po stronie wtornej - wyjście ',
  ];

  return (
    <div className={classes.root}>
      <PrimaryButton style={{marginRight: 15}} onClick={() => exportTable('pdf')}>Export pdf</PrimaryButton>
      <PrimaryButton onClick={() => exportTable('xlsx')}>Export xlsx</PrimaryButton>
      <Paper className={classes.paper}>
        <Bar
          onDelete={onDelete}
          useDialogOnDelete={useDialogOnDelete}
          numSelected={selected}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table">
            <ExchangersHeader
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={recordsCount ? recordsCount : rows.length}
            />
            <TableBody>
              {stableSort([...(rows || []), ...newRows], getComparator(order, orderBy))
                .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                      <HeatExchangerEditRow projectId={projectId} initialValues={row} hover
                      className={classes.rowObject}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected} />
                  );
                })}
                <HeatExchangerNewRow projectId={projectId} onSave={row => setNewRows(rows => [...rows, row])}/>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

ExchangersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.node,
      ]),
    ),
  ).isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ).isRequired,
  onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  useDialogOnDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  projectId: PropTypes.number,
};

ExchangersTable.defaultProps = {
  onRowClick: null,
  useDialogOnDelete: false,
  onDelete: null,
};

export default ExchangersTable;
