const fields = [
  {
    "id": 23,
    "name": "Informacje o kontakcie",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 101,
                "name": "name",
                "type": "text",
                "label": "Imię i nazwisko",
                "value": null,
                "options": null,
                fullWidth: true
            },
            {
                "id": 101,
                "name": "email",
                "type": "text",
                "label": "Adres e-mail",
                "value": null,
                "options": null
            },
            {
                "id": 101,
                "name": "phone",
                "type": "text",
                "label": "Telefon",
                "value": null,
                "options": null
            },
            {
                "id": 101,
                "name": "whois",
                "type": "text",
                "label": "Kim jest?",
                "value": null,
                "options": null
            },
            {
                "id": 101,
                "name": "voivodeship",
                "type": "text",
                "label": "Województwo",
                "value": null,
                "options": null
            },
            {
                "id": 101,
                "name": "source",
                "type": "text",
                "label": "Źródło pochodzenia",
                "value": null,
                "options": null
            }
        ]
    }
}
];

export default fields;