import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle, 
  useMemo,
  useRef,
  useState
} from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {partition} from "lodash";
import {
  API_PRODUCTS_ROUTE,
  API_PRODUCT_CATEGORIES_ROUTE,
  API_COMMENTS_ROUTE,
  API_PRODUCT_PRODUCER_ROUTE,
  API_TEMPLATES_LAUNCH_ROUTE,
  API_TEMPLATES_REVIEW_ROUTE,
} from '../../../../../constants/routes';
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall
} from "../../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import {useFormik} from "formik";
import {AddEditParts} from "./AddEditParts";
import RowWrapper from '../../../../../components/wrappers/RowWrapper';
import DynamicForm from '../../../../../components/dynamic-forms/DynamicForm';
import { formToRequest, responseToForm } from '../../../../../components/dynamic-forms/field-transformer';
import productCardFieldsDevices from './productCardFieldsDevices';
import productCardFieldsPeripherals from './productCardFieldsPeripherals';
import productCardFieldsAutomatics from './productCardFieldsAutomatics';
import productCardFieldsService from './productCardFieldsService';
import {Autocomplete} from "@material-ui/lab";

const Container = styled(ColumnWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

const AddEditPopup = forwardRef(({ productId, producer, categoryId, groupId, producerId, reload, newBareProduct = false }, ref) => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [initVals, setInitVals] = useState([]);
  const [formType, setFormType] = useState([]);
  const [formTypeNumber, setFormTypeNumber] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentsGet, setCommentsGet] = useState([]);
  const [selected, setSelected] = useState([]);
  const [groups, setGroups] = useState([]);
  const [producers, setProducers] = useState([]);
  const [devicesDriversFields, setDevicesDriversFields] = useState({
    single: false,
    group: false,
    producerId: producer.id || producerId,
  });
  const [deviceProductType, setDeviceProductType] = useState('');
  const [launchTemplates, setLaunchTemplates] = useState(null);
  const [overviewTemplates, setOverviewTemplates] = useState(null);
  const [saving, setSaving] = useState(true);
  const [localProductId, setLocalProductId] = useState();

  const formRef = useRef();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      product_category_id: '',
      product_group_id: '',
      product_producer_id: '',
      is_group: true,
      description: '',
      net_list_price: 0,
      type: '',
      products: [], // RELATED PRODUCTS
      repairs_parts: [], // RELATED REPAIRS PARTS
      comments: [],

    },
  });

  useEffect(() => {
    productId ? setLocalProductId(productId) : setLocalProductId(null)
  }, [productId])

  React.useEffect(() => {
    const foundCategory = categories.find(item => item.id === formik?.values?.product_category_id);
    if(foundCategory) {
      chooseFields(foundCategory.type)
    }
  }, [formik?.values?.product_category_id, categories]);

  useEffect(() => {
    if (productId && open) {
      const fetchDetails = async () => {
        const {
          data: { item },
        } = await getCall(`${API_PRODUCTS_ROUTE}/${productId}`);
        const isSingle = item?.fields.find(element => element.name === 'is_single');
        const isGroup = item?.fields.find(element => element.name === 'is_group');
        const deviceProductType = item?.fields.find(element => element.name === 'product_type')?.value
        const [products, repairs_parts] = partitionProducts(item?.products);
        item && formik.setValues({...item, products, repairs_parts, net_list_price: changePrice('', item?.net_list_price), product_category_id: item?.product_category?.id || '', product_group_id: item?.product_producer?.type || '', product_producer_id: item?.product_producer?.id || ''});
        setInitVals(responseToForm(item?.fields || []));
        setDeviceProductType(deviceProductType);
        setDevicesDriversFields((prev) => ({
          ...prev,
          single: isSingle?.value || false,
          group: isGroup?.value || false
        }));
        // chooseFields(item?.product_producer?.type)
        setCommentsGet(item?.productComments || [])
      };
      fetchDetails();
    }
    !open && setInitVals([])
    !open && formik.resetForm()
  }, [productId, open]);

  const partitionProducts = (products) => {
    // divides products into the ones from groups: 'urządzenia'(1), 'peryferia'(2), 'automatyka'(3) [aka 'powiązane produkty']
    // and the one from group called 'serwis'(4) [aka 'powiązane części serwisowe']
    // returns two partitions (two arrays of products): 'powiązane produkty' and 'powiązane części serwisowe')
    return partition(products, (prod) => prod.group !== 4) // 4 - group='serwis'
  }

  useEffect(() => {
    if (formTypeNumber === 1) {
      setFormType(productCardFieldsDevices(devicesDriversFields, deviceProductType, launchTemplates, overviewTemplates));
    }
  }, [initVals, launchTemplates, overviewTemplates]);

  useEffect(() => {
    if (producer) {
      setDevicesDriversFields((prev) => ({
        ...prev,
        producerId: producer.id
      }))
    }
  }, [producer])

  useEffect(() => {
    if (producerId) {
      setDevicesDriversFields((prev) => ({
        ...prev,
        producerId: producerId
      }))
    }
  }, [producerId])

  useEffect(() => {
    if (producer.id || producerId) {
      const fetchServiceGroups = async () => {
        const { data: launch } = await getCall(`${API_TEMPLATES_LAUNCH_ROUTE}/?product_producer=${producer.id || producerId}`)
        const { data: overview } = await getCall(`${API_TEMPLATES_REVIEW_ROUTE}/?product_producer=${producer.id || producerId}`)
        setLaunchTemplates(() => {
          return launch?.item?.columns?.map((item) => ({
            id: item.id,
            name: item.name
          }))
        })
        setOverviewTemplates(() => {
          return overview?.item?.tables?.map((item) => ({
            id: item.id,
            name: item.name
          }))
        })
      }
      fetchServiceGroups();
    }
  }, [producer, producerId])

  useEffect(() => {
    if(initVals?.pipes?.length > 0) {
      formRef.current.setFormValue("pipes_count", initVals.pipes.length.toString());
    }
  }, [initVals])

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await getCall(API_PRODUCT_CATEGORIES_ROUTE);
      if (data) {
        setCategories(
            data?.items?.map((i) => ({
              id: i?.id,
              name: i?.name,
              type: i?.type,
              producer: i?.producer
            })),
        );
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}/types`);
      if (data) {
        setGroups(
          data?.items?.map((i) => ({
            id: i?.id,
            name: i?.name,
          })),
      );
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchProducers = async () => {
      const { data } = await getCall(`${API_PRODUCT_PRODUCER_ROUTE}`);
      if (data) {
      setProducers(
        data?.items?.map((i) => ({
          id: i?.id,
          name: i?.name,
          type: i?.type
        })),
        );
      }
    };
    fetchProducers();
  }, []);

  const handleSelect = (newInputValue) => {
    setSelected(newInputValue);
  };

  useEffect(() => {
    if(selected)
      formik.setFieldValue('comments', selected.map(i => i.id));
  }, [selected]);

  useEffect(() => {
    if(commentsGet.length && comments){
      const comparing = comments.filter(obj => {
        return commentsGet.some(obj2 => {
          return obj.key === obj2.key
        })
      });

      setSelected(comparing)
    }
  }, [commentsGet, comments]);

  useEffect(() => {
    const fetchComments = async () => {
      const { data } = await getCall(API_COMMENTS_ROUTE);
      if(data)
        setComments(data.map(({dictionary_id, id, key, value}) => ({
          dictionary_id,
          id,
          key,
          value: `${key} - ${value}`
        })))
    };
    fetchComments();
  }, []);

  useEffect(() => {
    const setCategoryId = () => {
      if (categoryId) {
        formik.setFieldValue('product_category_id', categoryId);
      }
    };
    setCategoryId();
  }, []);


  useEffect(() => {
    const setGroupId = () => {
      if (groupId) {
        formik.setFieldValue('product_group_id', groupId);
      }
    };
    setGroupId();
  }, [productId]);

  useEffect(() => {
    const setProducerId = () => {
      if (producerId) {
        formik.setFieldValue('product_producer_id', producerId);
      }
    };
    setProducerId();
  }, [productId]);

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setLocalProductId(null)
    setOpen(false);
    reload && reload();
  };

  const handleSave = async () => {
    try {
      setSaving(false);
      const addProducer = async () => {
        const formValues = formRef?.current?.getFormValues();
        const r = await postCall(API_PRODUCTS_ROUTE, {
          ...formik.values,
          net_list_price: Number(formik.values.net_list_price.replace(/ /g, '')),
          products_ids: [...formik.values.products, ...formik.values.repairs_parts].map(({id}) => id),
          // products_ids: formik.values.products.map(({id}) => id),
          // repairs_parts_ids: formik.values.repairs_parts.map(({id}) => id),
          fields: formToRequest(formValues)
      });
        if (r?.data && !r?.data?.error) {
          const newProductId = r?.data?.item?.id
          newProductId && setLocalProductId(newProductId)
          toast.success('Dodano nowy produkt.');
          setSaving(true);
        }
        else toast.error('Wystąpił błąd podczas dodawania produktu.');
      };
      const editProducer = async () => {
        // formik.setFieldValue('net_list_price', parseInt(formik.values.net_list_price))
        const formValues = formRef?.current?.getFormValues();
        // console.log(formik.values)
        const r = await putCall(`${API_PRODUCTS_ROUTE}/${localProductId}`, {
          ...formik.values,
          net_list_price: Number(formik.values.net_list_price.replace(/ /g, '')),
          products_ids: [...formik.values.products, ...formik.values.repairs_parts].map(({id}) => id),
          // products_ids: formik.values.products.map(({id}) => id),
          // repairs_parts_ids: formik.values.repairs_parts.map(({id}) => id),
          fields: formToRequest(formValues)
        });
        if (r?.data && !r?.data?.error) {
          toast.success(`Pomyślnie zapisano wartości dla produktu - ${formik.values.name}.`);
          setSaving(true);
        } else {
          toast.error('Wystąpił błąd podczas edycji produktu.');
        }
      };
      if (localProductId) {
        // console.log(formik.values)
        // return;
        await editProducer();
      } else {
        await addProducer();
      }
    } catch (error) {
      toast.error('Wystąpił błąd przy dodawaniu/edycji produktu.');
      setSaving(true);
    }
  };

  const chooseFields = (type) => {

    /** 
     * DEVICES = 1
     * PERIPHERALS = 2
     * AUTOMATICS = 3
     * SERVICE = 4
     */

    switch(type) {
      case 1: setFormType(productCardFieldsDevices(devicesDriversFields, deviceProductType, launchTemplates, overviewTemplates));
        break;
      case 2: setFormType(productCardFieldsPeripherals);
        break;
      case 3: setFormType(productCardFieldsAutomatics);
        break;
      case 4: setFormType(productCardFieldsService);
        break;
      default: setFormType([]); break;
    }
    setFormTypeNumber(type);
  }

  const changePrice = ($event, number) => {
    if($event !== ''){
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
        $event.preventDefault();
      }
    }

    let x = number.toString().split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ' ' + '$2').replace(/,/g, '.');
    }

    return x1 + x2;
  };

  const onlyNumber = ($event) => {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
    if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105) && keyCode !== 8) {
      $event.preventDefault();
    }
  };

  const onChangeMiddleware = (f, v) => {
    if (formTypeNumber === 1) {
      switch (f) {
        case 'product_type':
          setDeviceProductType(v);
          formRef.current.setFormValue('product_type', v);
          break;
      }
    }
  };

  const onSelectProducer = value => {
    formik.setFieldValue('product_producer_id', value);
    formik.setFieldValue('product_category_id', '')
  }

  const onSelectGroup = value => {
    formik.setFieldValue('product_group_id', value);
    formik.setFieldValue('product_producer_id', '');
    formik.setFieldValue('product_category_id', '');
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xl'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <Container>
          <RowWrapper style={{width: '100%'}}>
            <ColumnWrapper width="33%">
              <Typography
                style={{ fontWeight: 'bold', margin: '0 0 20px' }}
                variant="body1">
                {localProductId ? 'Edycja' : 'Dodawanie'} produktu
              </Typography>
              <TextField
                variant="outlined"
                label="Nazwa"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <Select
                label="Grupa"
                name="product_group_id"
                data={groups}
                value={groups?.find(option => option?.id === formik?.values?.product_group_id)?.name || null}
                onSelect={value => onSelectGroup(value)}
                disabled={!newBareProduct || productId}
              />

              <Select
                label="Producent"
                name="product_producer_id"
                data={producers.filter(i => i?.type === formik?.values?.product_group_id )}
                value={producers?.find(option => option?.id === formik?.values?.product_producer_id)?.name || null}
                onSelect={value => onSelectProducer(value)}
                disabled={!newBareProduct || productId}
              />

              <Select
                label="Kategoria"
                name="product_category_id"
                data={categories.filter((i) => i?.producer === producers?.find(option => option?.id === formik?.values?.product_producer_id)?.name)}
                value={categories?.find(option => option?.id === formik?.values?.product_category_id)?.name || null}
                onSelect={value => {formik.setFieldValue('product_category_id', value);}}
              />
              <TextField
                  type="text"
                  label="Cena katalogowa netto"
                  variant="outlined"
                  name="net_list_price"
                  value={formik.values.net_list_price}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onKeyDown={(e) => onlyNumber(e)}
                  onBlur={(e) => formik.setFieldValue('net_list_price', changePrice(e, formik.values.net_list_price))}
              />
              <Select
                  label="Waluta"
                  name="currency"
                  data={[
                    {id: 'pln', name: 'PLN'},
                    {id: 'eur', name: 'EUR'},
                  ]}
                  value={formik?.values?.currency ? formik?.values?.currency.toUpperCase() : null}
                  onSelect={value => formik.setFieldValue('currency', value)}
              />
              <TextField
                  multiline
                  label="Opis"
                  variant="outlined"
                  name="description"
                  rows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
              />

              <Autocomplete
                  multiple={true}
                  name={"Uwagi"}
                  options={comments}
                  noOptionsText={"Brak podpowiedzi"}
                  clearOnBlur={false}
                  onChange={(event, newValue) => {handleSelect(newValue);}}
                  value={selected || []}
                  getOptionLabel={(option) => option.value || []}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Uwagi"
                          name={"Uwagi"}
                          value={selected || ''}
                          variant="outlined"
                      />
                  )
                  }
              />

              {/*<Autocomplete*/}
              {/*    multiple={true}*/}
              {/*    name={"comments"}*/}
              {/*    options={comments}*/}
              {/*    onOpen={() => {*/}
              {/*      setOpen(true);*/}
              {/*    }}*/}
              {/*    onClose={() => {*/}
              {/*      setOpen(false);*/}
              {/*    }}*/}
              {/*    clearOnBlur={false}*/}
              {/*    onChange={value => {formik.setFieldValue('comments', value);}}*/}
              {/*    value={formik.values.comments || []}*/}
              {/*    getOptionLabel={(option) => option.key || []}*/}
              {/*    renderInput={(params) => (*/}
              {/*        <TextField*/}
              {/*            name={"comments"}*/}
              {/*            value={formik.values.comments || []}*/}
              {/*            variant="outlined"*/}
              {/*        />*/}
              {/*    )*/}
              {/*    }*/}
              {/*/>*/}

              <AddEditParts
                  productId={productId}
                  type='products'
                  parts={formik.values.products}
                  label={'Powiązane produkty'}
                  onInput={parts => { formik.setFieldValue('products', parts) }}
              />
              {formTypeNumber !== 4 &&
                // don't display when created/edited product comes from group='serwis'
                <AddEditParts
                    productId={productId}
                    type='repairs_parts'
                    parts={formik.values.repairs_parts}
                    label={'Powiązane części serwisowe'}
                    onInput={parts => formik.setFieldValue('repairs_parts', parts)}
                />
              }
            </ColumnWrapper>
            <ColumnWrapper width="66%" style={{paddingLeft: '20px'}}>
              <DynamicForm fields={formType} initialValues={initVals} avoidTabs={true} ref={formRef} onChangeMiddleware={onChangeMiddleware} />
            </ColumnWrapper>
          </RowWrapper>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions style={{ padding: 20 }}>
        <PrimaryButton variant="raised" onClick={handleClose} color="secondary">
          Anuluj
        </PrimaryButton>
        <PrimaryButton disabled={!(formik.values.name.length && formik.values.product_category_id && formik.values.net_list_price && saving)} onClick={handleSave}>
          Zapisz
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

AddEditPopup.propTypes = {
  fetchDocuments: PropTypes.func,
};

export default AddEditPopup;
