import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
`;

const BackButton = ({ text, onClick }) => {
  return (
    <>
      <Wrapper onClick={onClick}>
        <ArrowBackIosIcon />
        <Typography variant="body2">{text}</Typography>
      </Wrapper>
    </>
  );
};

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
