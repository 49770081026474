import React, {useEffect, useState} from 'react';
import TableToolBar from "./components/list/TableToolBar";
import {useParams} from "react-router-dom";
import fetchDirectoryContent from "./tools/fetchDirectoryContent";
import {DIR_TYPE, FILE_TYPE} from "../../constants/directories/types";
import {DocumentDirectoryTable} from "./components/list/DocumentDirectoryTable";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import DocumentSearchFilters from "./components/search-filters/DocumentSearchFilters";
import serializeQuery from "../../tools/serializeQuery";
import {getCall} from "../../components/indicator/Indicator";
import {API_ATTACHMENT_DOCUMENTS_ROUTE, API_DOCUMENTS_ROUTE, SERVER_ROUTE} from "../../constants/routes";
import BasicTable from "../../components/tables/basic/BasicTable";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {toast} from "react-toastify";
import _ from 'lodash';
import routerHistory from "../../constants/settings/routerHistory";

const DirectoriesScreen = () => {
    const {id} = useParams();

    const [addedDocument, setAddedDocument] = useState(null);
    const [rows, setRows] = useState([]);
    const [searchRows, setSearchRows] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [parent, setParent] = useState([]);
    const [name, setName] = useState('');
    const [searchTablePage, setSearchTablePage] = useState(0);
    const [searchTableFilters, setSearchTableFilters] = useState({});
    const [searchTableTotalCount, setSearchTableTotalCount] = useState(0);

    useEffect(() => {
        fetchDirectoryContent(id).then(({rows = [], parent = [], name = ''} = {}) => {
            setRows(rows);
            setLoading(false);
            setParent(parent || []);
            setName(name)
        })
    }, [id]);

    useEffect(() => {
        addedDocument && setRows(rows =>
            addedDocument.type === DIR_TYPE ? [...rows, addedDocument] : [addedDocument, ...rows]
        )
    }, [addedDocument]);

    const flatParents = (document) => {
        const parents = [];
        const getParent = document => {
            if (document?.parent) {
                parents.push(document.parent);
                getParent(document.parent)
            }
        };
        getParent(document);
        return parents.reverse()
    };

    const handleSearch = async () => {
        if (_.isEmpty(searchTableFilters)) {
            setSearchRows([]);
        } else {
            const queryPath = serializeQuery({
                ...searchTableFilters,
                results_on_page: searchTableFilters?.results_on_page || 10,
                page: searchTablePage
            });
            const {data} = await getCall(`${searchTableFilters?.type === FILE_TYPE ? API_DOCUMENTS_ROUTE : API_ATTACHMENT_DOCUMENTS_ROUTE}?${queryPath}`);
            if (data) {
                if(!data?.items.length) {
                    toast.error('Nie znaleziono dokumentów spełniających dane kryteria.');
                }

                const r = data?.items.map((item) => ({
                    id: item?.id,
                    name: item?.name,
                    description: item?.description,
                    created: item?.created?.date,
                    creator: item?.creator?.username,
                    parent: <Breadcrumbs key={item?.id} aria-label="breadcrumb">
                        <Link color="textPrimary" href="/dir">
                            Dokumenty
                        </Link>
                        {
                            flatParents(item || {}).map(({id, name}) =>
                                <Link color="textPrimary" href={`/dir/${id}`}>
                                    {name}
                                </Link>
                            )
                        }
                    </Breadcrumbs>,
                    path: item?.path,
                }));
                setSearchTableTotalCount(data?.total || 0);
                setSearchRows(r);
            }
        }
    };

    useEffect(() => {
        handleSearch().then()
    }, [searchTableFilters, searchTablePage]);

    const handleClickRow = async (row) => {
        console.log(searchTableFilters, row)
        if (searchTableFilters?.type === 'Katalog') {
            routerHistory.push(`/dir/${row.id}`)
        }
        if(searchTableFilters?.type === 'Plik' && row.path) {
            window.open(`${SERVER_ROUTE}/${row.path}`, '_blank');
        }
    };

    return <>
        <TableToolBar currentName={name} parent={parent} onSave={document => document && setAddedDocument(document)}
                      isLoading={isLoading}/>
        <RowWrapper>
            <ColumnWrapper style={{maxWidth: '350px'}}>
                <DocumentSearchFilters handleSearch={(f) => {setSearchTablePage(0); setSearchTableFilters(() => ({...f}))}}/>
            </ColumnWrapper>
            <ColumnWrapper fullWidth size="100%">
                {
                    searchRows?.length ?
                        <>
                            <BasicTable
                                rows={searchRows}
                                recordsCount={searchTableTotalCount}
                                onRowClick={handleClickRow}
                                hideKeys={['path']}
                                headCells={[
                                    {id: 'name', numeric: false, disablePadding: false, label: 'Nazwa'},
                                    {id: 'description', numeric: false, disablePadding: false, label: 'Opis'},
                                    {id: 'created', numeric: false, disablePadding: false, label: 'Data dodania'},
                                    {id: 'creator', numeric: false, disablePadding: false, label: 'Autor'},
                                    {id: 'parent', numeric: false, disablePadding: false, label: 'Ścieżka'},
                                ]}
                                onChangePage={setSearchTablePage}
                                showCheckboxes={false}
                                title="Wyniki wyszukiwania"
                                heightAuto={false}
                            />
                        </>
                        : ''
                }
                <DocumentDirectoryTable documents={rows} isLoading={isLoading}/>
            </ColumnWrapper>
        </RowWrapper>
    </>
};

export default DirectoriesScreen;
