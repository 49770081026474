import { Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import routerHistory from "../../../../constants/settings/routerHistory";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import TaskTick from '../../../../components/task-tick/TaskTick';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const DetailsToolBar = (props) => {
  const { id } = useParams();

  return (
    <>
      <Wrapper>
        <RowWrapper style={{alignItems: 'center'}}>
          <BackButton
            text="Wróć"
            onClick={() => routerHistory.goBack()}
          />
          <ColumnWrapper>
            <RowWrapper style={{alignItems: 'center'}}>
              {props.taskObject && <TaskTick id={id} status={props.taskObject?.status} waitOnFeedback={!!props.taskObject?.waitOnFeedback} feedbackComment={props.taskObject?.feedbackComment} onUpdateParams={(values) => props?.onUpdateParams && props?.onUpdateParams(values)} />}
              {id ? <Typography style={{marginLeft: '20px'}}>#{id} {props?.currentDesc ? `- ${props.currentDesc}` : ''}</Typography> : null}
            </RowWrapper>
          </ColumnWrapper>
        </RowWrapper>
      </Wrapper>
    </>
  );
};

export default DetailsToolBar;
