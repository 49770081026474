import { Button, Checkbox, TextField, Typography } from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import DynamicForm from "../../../../../components/dynamic-forms/DynamicForm";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import { FormWrapper } from "../../../../../components/FormComponents";
import fields from './fields';

const inputMargin = { marginRight: 10 };

const personFieldWrapperStyle = {
  padding: '10px 0',
  display: 'flex',
  alignItems: 'center',
};

const MarketingCompanyForm = ({initialValues, id}) => {

  return (
    <>
      <ExpansionContainer title="Informacje podstawowe">
        <ColumnWrapper fullWidth>
          <DynamicForm fields={fields} initialValues={initialValues} avoidTabs small />
        </ColumnWrapper>
      </ExpansionContainer>
    </>
  );
};

export default MarketingCompanyForm;
