import React from 'react';
import EventForm from "./components/slot/event-form/EventForm";
import SlotToolBar from "./components/slot/tool-bar/SlotToolBar";

const SlotScreen = () => {
  return (
    <>
      <EventForm />
    </>
  );
};

export default SlotScreen;
