import {Checkbox, DialogContent, FormControlLabel, IconButton, TextField} from '@material-ui/core';
import plLocale from 'date-fns/locale/pl';
import DateFnsUtils from '@date-io/date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from '../../../../components/dynamic-forms/form-components/Select';
import {
  getCall,
  postCall,
  putCall
} from "../../../../components/indicator/Indicator";
import {
  API_COMPANIES_ROUTE,
  API_PROJECTS_ROUTE,
  API_TASKS_ROUTE,
  API_USERS_ROUTE
} from '../../../../constants/routes';
import { Autocomplete } from '@material-ui/lab';
import PaperContainer from '../../../../components/containers/PaperContainer';
import FloatingSaveBar from '../../../../components/FloatingSaveBar';
import ColumnWrapper from '../../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../../components/wrappers/RowWrapper';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import commonValues from '../../../../tools/commonValues';
import CheckIcon from "@material-ui/icons/Check";
import {green, red} from "@material-ui/core/colors";
import ClearIcon from "@material-ui/icons/Clear";
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import routerHistory from "../../../../constants/settings/routerHistory";
import { useStoreState } from 'easy-peasy';

const TYPES = ['', 'oferta', 'spotkanie', 'telefon', 'cykliczny kontakt', 'pilne'];

const TaskDetailsForm = ({id, onChangeWaitOnFeedback = null, taskObject = null, ...props}) => {
  const [project, setProject] = useState(null);
  const [owner, setOwner] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [allDay, setAllDay] = useState(false);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('');
  const [waitOnFeedback, setWaitOnFeedback] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [company, setCompany] = useState(props?.companyId || null);
  const [companies, setCompanies] = useState([]);
  const [disabledSave, setDisabledSave] = useState(false);
  const [dateError, setDateError] = useState(false)
  const [isCurrentUserTask, setIsCurrentUserTask] = useState(false);
  const [isDislike, setIsDislike] = useState(false);
  const [isLike, setIsLike] = useState(false);

  const state = useStoreState((state) => state.auth?.loggedInUser);
  const sentFeedback = async (feedbackType) => {
    const eObj = {};
    let feedbackDescription = "";
    switch (feedbackType) {
      case 'LIKE':
        feedbackDescription = `Feedback pozytywny\nOpis zadania:\n${description}\nKomentarz do feedbacku:\n${feedbackComment}`;
        const r = await postCall(API_TASKS_ROUTE, {
          ...eObj,
          type: type.trim().length === 0 ? '' : type,
          project_id: project,
          for: principal,
          isFeedback: true,
          principal: owner,
          company_id: company,
          deadline,
          start_date: startDate,
          description: feedbackDescription,
          priority,
          waitOnFeedback,
          feedbackComment,
        });
        if (id) eObj.id = id;
        const d = await putCall(API_TASKS_ROUTE, {
          ...eObj,
          type: type.trim().length === 0 ? '' : type,
          project_id: project,
          for: owner,
          isFeedback: true,
          principal,
          company_id: company,
          deadline,
          start_date: startDate,
          description,
          priority,
          waitOnFeedback: false,
          feedbackComment,
        })
        if (r.data && !r.data.status && d.data) {
          toast.success(`Feedback został wysłany`);
          if(props?.onSuccess) {
            props.onSuccess();
            setDescription('')
          }
          setProject(null);
          setCompany(null);
          setPrincipal(null);
          setOwner(null);
          setType(null);
          setDeadline(null);
          setStartDate(null);
          setDescription('');
          setPriority('');
          setWaitOnFeedback(null);
          setIsFeedback(null);
          setFeedbackComment(null);
  
          if(!props.companyId) {
            routerHistory.goBack() || routerHistory.push('/tasks')
          }
        }

        break;
      case 'DISLIKE':
        feedbackDescription = `Feedback negatywny\nOpis zadania:\n${description}Komentarz do feedbacku:\n${feedbackComment}`;
        const e = await postCall(API_TASKS_ROUTE, {
          ...eObj,
          type: type.trim().length === 0 ? '' : type,
          project_id: project,
          for: principal,
          isFeedback: true,
          principal: owner,
          company_id: company,
          deadline,
          start_date: startDate,
          description: feedbackDescription,
          priority,
          waitOnFeedback,
          feedbackComment,
        });
        if (id) eObj.id = id;
        const k = await putCall(API_TASKS_ROUTE, {
          ...eObj,
          type: type.trim().length === 0 ? '' : type,
          project_id: project,
          for: owner,
          isFeedback: true,
          principal,
          company_id: company,
          deadline,
          start_date: startDate,
          description,
          priority,
          waitOnFeedback: false,
          feedbackComment,
        })

        if (e.data && !e.data.status && k.data) {
          toast.success(`Feedback został wysłany`);
          if(props?.onSuccess) {
            props.onSuccess();
            setDescription('')
          }
          setProject(null);
          setCompany(null);
          setPrincipal(null);
          setOwner(null);
          setType(null);
          setDeadline(null);
          setStartDate(null);
          setDescription('');
          setPriority('');
          setWaitOnFeedback(null);
          setIsFeedback(null);
          setFeedbackComment(null);
  
          if(!props.companyId) {
            routerHistory.goBack() || routerHistory.push('/tasks')
          }
        }
        break;
    }
  }

  useEffect(() => {
    const fetchEditData = async () => {
      const { data } = await getCall(`${API_TASKS_ROUTE}/${id}`);
      if (data?.object) {
        if(props?.setTaskObject) {
          props.setTaskObject(data?.object);
        }
        setProject(data?.object?.project?.id);
        setCompany(data?.object?.company?.id);
        setPrincipal(data?.object?.principal?.id);
        setOwner(data?.object?.for?.id);
        setType(data?.object?.type);
        setDeadline(data?.object?.deadline?.date);
        setStartDate(data?.object?.startDate?.date);
        setDescription(data?.object?.description);
        setPriority(data?.object?.priority);
        setWaitOnFeedback(data?.object?.waitOnFeedback);
        setIsFeedback(data?.object?.isFeedback);
        setFeedbackComment(data?.object?.feedbackComment);
      }
      return [];
    };
    if(id) {
      fetchEditData();
    }
  }, [props?.taskObject]);

  useEffect(() => {
    if(taskObject) {
      setIsFeedback(taskObject?.isFeedback);
      setFeedbackComment(taskObject?.feedbackComment);
    }
  }, [taskObject]);

  useEffect(() => {
    if(props?.setCurrentDesc) {
      props.setCurrentDesc(description);
    }
  }, [description])

  const submitForm = async () => {
    setDisabledSave(true)
    if(isLike){
      sentFeedback("LIKE")
    } else if(isDislike){
      sentFeedback("DISLIKE")
    }else {
    const callTask = async (fn) => {
      const eObj = {};
      if (id) eObj.id = id;
      const r = await fn(API_TASKS_ROUTE, {
        ...eObj,
        type: type.trim().length === 0 ? '' : type,
        project_id: project,
        for: owner,
        principal,
        company_id: company,
        deadline,
        start_date: startDate,
        description,
        priority,
        waitOnFeedback,
        feedbackComment,
        isFeedback: !waitOnFeedback && null
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla zadania.`);
        if(props?.onSuccess) {
          props.onSuccess();
          setDescription('')
        }
        setProject(null);
        setCompany(null);
        setPrincipal(null);
        setOwner(null);
        setType(null);
        setDeadline(null);
        setStartDate(null);
        setDescription('');
        setPriority('');
        setWaitOnFeedback(null);
        setIsFeedback(null);
        setFeedbackComment(null);

        if(!props.companyId) {
          routerHistory.goBack() || routerHistory.push('/tasks')
        }
      }
    };

    if (id) {
      await callTask(putCall);
    } else {
      await callTask(postCall);
    }
    setDisabledSave(false)
    }
  };

  useEffect(() => {
    const fetchProjectsAndCompanies = async () => {
      const p = await getCall(API_PROJECTS_ROUTE);
      if (p.data) {
        const d = p.data.items?.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setProjects(d);
      }

      const c = await getCall(API_COMPANIES_ROUTE);
      if (c.data) {
        const d = c.data.items?.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setCompanies(d);
      }

      const { data } = await getCall(API_USERS_ROUTE);
      if (data?.items) {
        setUsers(
          data?.items.map((i) => ({
            ...i,
            name: i.email,
          })),
        );
      }

      const t = await getCall(API_TASKS_ROUTE);
      if (t.data) {
        TYPES.push(...t.data.items?.map(item => item?.type));
        const uniqueTypes = [...new Set(TYPES)];
        setTypes(uniqueTypes);
      }

    };
    fetchProjectsAndCompanies();
    // if (data?.company?.id) {
    //   setCompany(data?.company.id);
    // }
  }, []);

  useEffect(() => {
    if(state?.id === owner) {
      setIsCurrentUserTask(true);
    }
  }, [state, owner])

  return (
    <>
        <PaperContainer style={{margin: '0px'}}>
          <RowWrapper  style={{flexWrap: 'wrap'}}>

          <ColumnWrapper width="25%">
            <Select
              label="Osoba"
              data={[{name: 'Wybierz', id: null}, ...users]}
              onSelect={(p) => setOwner(p)}
              value={owner}
            />
          
          </ColumnWrapper>
          <ColumnWrapper width="25%">
            <Select
              label="Powiązany projekt"
              data={[{name: 'Wybierz', id: null}, ...projects]}
              onSelect={(p) => setProject(p)}
              value={project}
            />
          
          </ColumnWrapper>
          <ColumnWrapper width="25%">
            <Select
              label="Powiązana firma"
              data={[{name: 'Wybierz', id: null}, ...companies]}
              onSelect={(p) => setCompany(p)}
              value={company}
            />
          
          </ColumnWrapper>
          <ColumnWrapper width="25%">
            <Select
              label="Priorytet"
              data={[{name: 'Wybierz', id: null}, ...commonValues.taskPriorities]}
              onSelect={(p) => setPriority(p)}
              value={priority}
            />
          </ColumnWrapper>
            <ColumnWrapper width="25%"
                           style={{padding: '0px 10px'}}>
              <Autocomplete
                  options={types}
                  freeSolo
                  getOptionLabel={(option) => option}
                  inputValue={type || ''}  onInputChange={(event, newInputValue) => setType(newInputValue)}
                  renderInput={(params) => <TextField {...params} label="Typ" variant="outlined" />}
              />
            </ColumnWrapper>
            <ColumnWrapper width="25%">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                {allDay ? <KeyboardDatePicker
                    style={{padding: '0px 5px', marginTop: 3}}
                    autoOk={true}
                    clearable="true"
                    variant="inline"
                    name={'startDate'}
                    placeholder="Data rozpoczęcia"
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    value={startDate || null}
                    onChange={(d) => setStartDate(d)}
                /> : <KeyboardDateTimePicker
                    style={{padding: '0px 5px', marginTop: 3}}
                    autoOk={true}
                    clearable="true"
                    variant="inline"
                    name={'startDate'}
                    placeholder="Data rozpoczęcia"
                    format="dd.MM.yyyy, HH:mm"
                    inputVariant="outlined"
                    value={startDate || null}
                    onChange={(d) => setStartDate(d)}
                    ampm={false}
                />}
              </MuiPickersUtilsProvider>
            </ColumnWrapper>
            <ColumnWrapper width="25%">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                {allDay ?
                    <KeyboardDatePicker
                        style={{padding: '0px 5px', marginTop: 3}}
                        minDate={startDate}
                        minDateMessage="Data nie powinna być wcześniejsza niż data rozpoczęcia"
                        autoOk={true}
                        onError={(error) => error !== '' ? setDateError(true) : setDateError(false)}
                        clearable="true"
                        variant="inline"
                        name={'deadline'}
                        placeholder="Deadline"
                        format="dd.MM.yyyy"
                        inputVariant="outlined"
                        value={deadline || null}
                        onChange={(d) => setDeadline(d)}
                        disablePast
                    />
                    : <KeyboardDateTimePicker
                        style={{padding: '0px 5px', marginTop: 3}}
                        minDate={startDate}
                        minDateMessage="Data nie powinna być wcześniejsza niż data rozpoczęcia"
                        autoOk={true}
                        onError={(error) => error !== '' ? setDateError(true) : setDateError(false)}
                        clearable="true"
                        variant="inline"
                        name={'deadline'}
                        placeholder="Deadline"
                        format="dd.MM.yyyy, HH:mm"
                        inputVariant="outlined"
                        value={deadline || null}
                        onChange={(d) => setDeadline(d)}
                        ampm={false}
                        disablePast
                    />}
              </MuiPickersUtilsProvider>
            </ColumnWrapper>
            <ColumnWrapper width="25%"
                           style={{padding: '0px 10px'}}>
              <TextField
                  label="Opis"
                  variant="outlined"
                  multiline
                  rows={5}
                  value={description}
                  onChange={({ target: { value } }) => setDescription(value)}
              />
            </ColumnWrapper>
          <ColumnWrapper width="25%">
            <FormControlLabel
              control={
                <Checkbox
                  name={'allDay'}
                  checked={allDay ? true : false}
                  value={allDay ? allDay : ''}
                  style={{width: 'max-content'}}
                  onChange={({ target }) => setAllDay(target.checked)}
                />
              }
              label={'Całodniowe'}
            />
          </ColumnWrapper>
            <ColumnWrapper width="25%">
              <FormControlLabel
                  control={
                    <Checkbox
                        name={'waitOnFeedback'}
                        checked={waitOnFeedback ? true : false}
                        value={waitOnFeedback ? waitOnFeedback : false}
                        style={{width: 'max-content'}}
                        onChange={({ target }) => {
                          onChangeWaitOnFeedback && onChangeWaitOnFeedback(target.checked);
                          setWaitOnFeedback(target.checked)
                        }}
                    />
                  }
                  label={'Oczekuj na feedback'}
              />       
            </ColumnWrapper>
            <ColumnWrapper width="25%">
            {isCurrentUserTask && waitOnFeedback && (
              <RowWrapper width="25%">
                <IconButton onClick={() => {setIsLike(true); setIsDislike(false)}} style={{backgroundColor: isLike ? '#DFDFDE' : 'white' }}>
                  <ThumbUpAltIcon fontSize='large' style={{ color: '#f39911'}} />
                </IconButton>
                <IconButton onClick={() => {setIsDislike(true); setIsLike(false)}} style={{ marginLeft: '20px', backgroundColor: isDislike ? '#DFDFDE' : 'white' }}>
                  <ThumbDownIcon fontSize='large' style={{ color: '#f39911'}} />
                </IconButton>
              </RowWrapper>
            )}
            </ColumnWrapper>
            {/* {id && waitOnFeedback && feedbackComment && <>
              <ColumnWrapper width="25%">
                <FormControlLabel
                    style={{marginLeft: 15, marginTop: 8}}
                    control={
                      isFeedback ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />
                    }
                    label={'Feedback'}
                />
              </ColumnWrapper>
              <ColumnWrapper width="25%">
                <TextField
                    disabled={true}
                    multiline
                    rows={5}
                    label="Komentarz Feedbaack'u"
                    variant="outlined"
                    value={feedbackComment}
                    InputProps={{
                      readOnly: true,
                    }}
                />
              </ColumnWrapper>
            </>} */}
             { waitOnFeedback && (isLike || isDislike) && (
              <ColumnWrapper width="25%" style={{padding: '0px 10px'}}>
                  <TextField
                    label="Komentarz do feedbacku"
                    variant="outlined"
                    multiline
                    rows={5}
                    value={feedbackComment}
                    onChange={({ target: { value } }) => setFeedbackComment(value)}
                />
              </ColumnWrapper>
             )
            }
          </RowWrapper>
          
          {props?.companyId && <RowWrapper><ColumnWrapper 
              style={{padding: '0px 10px'}}><PrimaryButton disabled={disabledSave || !owner || !deadline || !description} onClick={submitForm}>
          Dodaj nowe zadanie
        </PrimaryButton></ColumnWrapper></RowWrapper>}
        </PaperContainer>
      {!props?.companyId && 
      <FloatingSaveBar disabled={!owner || !deadline || !description || disabledSave || dateError || (waitOnFeedback && (isLike || isDislike) && !feedbackComment) } onClick={submitForm} />}
    </>
  );
}

export default TaskDetailsForm;