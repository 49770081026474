const FIELD_TYPES = [
  { name: 'Tekstowe', id: 'text' },
  { name: 'Walutowe', id: 'money' },
  { name: 'Pole zaznaczenia', id: 'checkbox' },
  { name: 'Data i czas', id: 'datetime' },
  { name: 'Data', id: 'date' },
  { name: 'Dłuższy opis', id: 'multilineInput' },
  { name: 'Grupa powtarzalna', id: 'rowsGroup' },
  { name: 'Wybór ze słownika', id: 'dictionary' },
  { name: 'Załącznik', id: 'attachment' },
  { name: 'Wiele załączników', id: 'attachments' },
];

export default FIELD_TYPES;
