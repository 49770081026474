import React from 'react';
import { Box, Typography } from '@material-ui/core';
import routerHistory from "../../constants/settings/routerHistory";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import ActionContainer from "../../components/action-container/ActionContainer";

export default () => {
    return <>
        <ColumnWrapper>
            <Box my={5}>
                <Typography variant="subtitle1">Szablony</Typography>
                <ActionContainer
                    label="Przeglądy"
                    buttonText='Przejdź'
                    onClick={() => routerHistory.push('/service/templates/reviews')}
                />
                <ActionContainer
                    label="Koszty uruchomienia"
                    buttonText='Przejdź'
                    onClick={() => routerHistory.push('/service/templates/launch')}
                />
                <ActionContainer
                    label="Koszty przeglądu"
                    buttonText='Przejdź'
                    onClick={() => routerHistory.push('/service/templates/reviewCost')}
                />
            </Box>
        </ColumnWrapper>
    </>
};