import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCall } from "../../../components/indicator/Indicator";
import ColumnWrapper from "../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../components/wrappers/RowWrapper";
import BasicData from '.././components/details/basic-data/BasicData';
import DetailsToolBar from '.././components/details/DetailsToolBar';
import NotesPanel from "../../../components/notes/NotePanel";
import {
  API_PROJECTS_ROUTE,
} from '../../../constants/routes';
import { responseToForm } from '../../../components/dynamic-forms/field-transformer';
import Loader from '../../../components/loader/Loader';

const ProjectDetailsScreen = (props) => {
  const [editData, setEditData] = useState({ notes: [] });
  const [notesData, setNotesData] = useState({ notes: [] });
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState([]);
  const [krs, setKrs] = useState([]);
  const [variants, setVariants] = useState([]);
  const [he, setHe] = useState([]);
  const [ri, setRi] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEditData = useCallback(async () => {
    const r = await getCall(`${API_PROJECTS_ROUTE}/${id}/full`);
    if (r?.data?.project) {
      return r.data;
    }
    return [];
  }, [id]);

  const fetchNotes = async () => {
    const r = await getCall(`${API_PROJECTS_ROUTE}/${id}`);
    if (r.data && r.data.object && r.data.object) {
      setNotesData(r.data.object);
      return r.data.object;
    }
    return [];
  };

  const refreshOffers = async () => {
    setLoading(true)
    const editData = await fetchEditData();
    if (editData) {
      
      setOffers(editData?.offers?.items);

      setTimeout(() => {
        setLoading(false)
      }, 500);

    }
  }

  useEffect(() => {
    const  fetchFields = async () => {
      if (id) {
        const editData = await fetchEditData();
        if (editData) {
          setEditData(editData.project);
          setNotesData(editData.project);
          setInitialValues(responseToForm(editData?.project?.fields || []));
          setKrs(editData.krs);
          setOffers(editData?.offers?.items);
          setVariants(editData.variants);
          setHe(editData.heatExchangers);
          setRi(editData.replacementInfo);

          setTimeout(() => {
            setLoading(false)
          }, 500);

        }
      } else {
        setLoading(false)
      }
    }
    fetchFields();
  }, [id, fetchEditData]);

  const localStorageDrawer = localStorage.getItem('notes-drawer');
  const [open, setOpen] = React.useState((localStorageDrawer && localStorageDrawer === 'false') ? false : true);

  const toggleDrawer = () => {
    localStorage.setItem('notes-drawer', open ? 'false' : 'true');
    setOpen(!open);
  };

  return (
    <>
      <ColumnWrapper fullWidth>
        <DetailsToolBar status={editData?.status} companyName={editData?.company?.name} name={editData?.name} repairId={editData?.repair?.id} toggleDrawer={toggleDrawer} />
        <RowWrapper style={{ alignItems: 'flex-start' }}>
          <ColumnWrapper width={open && id ? '60%' : '100%'}>
            {loading
              && <Loader />
              || <BasicData fetchNotes={fetchNotes} loading={loading} edit={id} data={editData} initialValues={initialValues} refreshOffers={refreshOffers} info={{he, ri, krs, variants, offers}} />
            }
          </ColumnWrapper>
            {open && !loading && id && <ColumnWrapper>
              <NotesPanel fetchNotes={fetchNotes} data={notesData}
                selectedProject={{id, name: id}} />
            </ColumnWrapper>}
        </RowWrapper>
      </ColumnWrapper>
    </>
  );
};

export default ProjectDetailsScreen;
