import { Box, Grid, Typography } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import React from 'react';

const NoDataPanel = ({ text = 'Brak danych' }) => (
  <Box p={5}>
    <Grid container spacing={3}>
      <Grid item size="auto">
        <CropFreeIcon />
      </Grid>
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  </Box>
);

NoDataPanel.propTypes = {};

NoDataPanel.defaultProps = {};

export default NoDataPanel;
