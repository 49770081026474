import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, {
  forwardRef,
  Fragment, useCallback,
  useEffect,
  useImperativeHandle, useState,
} from 'react';
import { GetApp } from '@material-ui/icons';
import {toast} from "react-toastify";
import {debounce} from 'lodash'
import { makeStyles } from '@material-ui/styles';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API_PROJECT_VARIANTS_ROUTE, SERVER_ROUTE } from '../../../../../constants/routes';
import { deleteCall, postCall } from '../../../../../components/indicator/Indicator';
import ColumnWrapper from '../../../../../components/wrappers/ColumnWrapper';
import RowWrapper from '../../../../../components/wrappers/RowWrapper';
import Loader from '../../../../../components/loader/Loader';
import toBase64 from '../../../../../tools/toBase64';
import routerHistory from '../../../../../constants/settings/routerHistory';

const useStyles = makeStyles(() => ({
  offer: {
    textDecoration: 'underline',
    cursor: 'pointer',
  }
}))

const AutomationVariants = forwardRef(({ projectId, variants = [], loading: parentLoading, ...props }, ref) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      variants: [],
    },
  });

  

  const randomUid = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);

  const downloadFile = ({path, downloadUrl} = {}) => {
    if(path) {
      window.open(`${SERVER_ROUTE}/${path}`, '_blank');
    } else if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    } else {
      toast.error(`Błąd podczas pobierania pliku`);
    }
  };

  useImperativeHandle(ref, () => ({
    getFormValues() {
      return formik.values;
    },
  }));

  const updateVariants = async (project_variants) => {
    const {data: {items = []} = {}} = await postCall(`${API_PROJECT_VARIANTS_ROUTE}/${projectId}/bulk`, {project_variants});
    setLoading(true);
    setVariants(items);
    setLoading(false);
  };

  const debounceUpdateVariants = useCallback(debounce(updateVariants, 2000), []);

  useEffect(() => {
    if(!loading && projectId) {
        debounceUpdateVariants(formik.values.variants)
    }
  }, [formik.values]);

  useEffect(() => {
    projectId && variants?.length &&  setVariants(variants);
  }, [variants]);

  const setVariants = variants => {
      formik.setFieldValue(
          'variants',
          variants.map((v) => ({
            ...v
          })),
      );
  };

  useEffect(() => {
    setLoading(() => parentLoading)
  }, [parentLoading]);

  const getDevices = (index) => formik.values.variants[index].devices;
  const getVariantId = (index) => formik.values.variants[index].id;
  const getAutomation = (index) => formik.values.variants[index].automation;
  const getOfferByType = (index, type) => formik.values.variants[index].offers?.find(item => item.type === type);
  const getOfferId = (index, offerNumber) => formik.values.variants[index].offers?.find(item => item.offerNumber === offerNumber);

  const prepareOffer = async (index, type) => {
      setLoading(true);
      const {data: {items = []} = {}} = await postCall(`${API_PROJECT_VARIANTS_ROUTE}/${projectId}/prepare-offer/${getVariantId(index)}/${type}`, {});
      setVariants(items);
      setLoading(false);
  }

  const prepareVariant = (index, type) => {
    routerHistory.push(`/projects/${projectId}/variant/${getVariantId(index)}/aut`)
  };

  const getOfferNumber = (offers) => {
    const filtered = offers.filter(offer => offer.type === 'automatyka').map(offer => offer.offerNumber);
    return filtered;
  } 

  const goToOffer = (index, offerNumber) => {
    routerHistory.push(`/offers/edit/${getOfferId(index, offerNumber).id}`);
  }

  return (
    <RowWrapper>
      {loading
      && <Loader />
      || <FormikProvider value={formik}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell rowspan={2}>nazwa</TableCell>
                    <TableCell rowspan={2}>opis wariantu</TableCell>
                    <TableCell rowspan={2}>schemat</TableCell>
                    <TableCell colspan={1} style={{ textAlign: 'center' }}>urządzenia</TableCell>
                    <TableCell style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)'}}></TableCell>
                    <TableCell colspan={3} style={{ textAlign: 'center' }}>automatyka</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>wzór</TableCell>
                    <TableCell style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)'}}></TableCell>
                    <TableCell style={{ textAlign: 'center' }}>wzór</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>oferta</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

              {formik.values.variants && formik.values.variants.length > 0
                      ? formik.values.variants.map((v, index) => (
                          <TableRow key={index}>
                            <TableCell>
                            {formik.values.variants[index].name || '-'}
                            </TableCell>
                            <TableCell>
                            {formik.values.variants[index].description || '-'}
                            </TableCell>
                            <TableCell>
                            {formik.values.variants[index]?.schema && <IconButton
                                title={'Pobierz ' + formik.values.variants[index]?.schema?.name || ''}
                                disabled={!Object.keys(formik.values.variants[index]?.schema).length}
                                size="small"
                                style={{fontSize: '8px'}}
                                onClick={() => downloadFile(formik.values.variants[index].schema)}>
                              <GetApp />
                            </IconButton>}
                            </TableCell>
                            <TableCell>
                              {
                              getOfferByType(index, 'urzadzenia')
                                ? <><Button
                                variant="contained"
                                color={getDevices(index)?.length ? "primary" : "light"}
                                style={{marginLeft: 10, fontSize: '8px' }}
                                aria-controls={"ur-" + randomUid}
                                aria-haspopup="true"
                                onClick={(e) => routerHistory.push(`/projects/${projectId}/variant/${getVariantId(index)}/dev?preview=true`)}
                            >
                                nr {getOfferByType(index, 'urzadzenia')?.offerNumber}
                            </Button>
                                
                                </> :
                              <Button
                                  variant="contained"
                                  color={formik.values.variants[index].devices?.length ? "default" : "light"}
                                  style={{marginLeft: 10, fontSize: '8px' }}
                                  aria-controls={"ur-" + randomUid}
                                  aria-haspopup="true"
                                  onClick={(e) => prepareOffer(index, 'urzadzenia')}
                              >
                                  Przygotuj ofertę
                              </Button>}
                            </TableCell>
                            <TableCell style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)'}}></TableCell>
                            <TableCell>
                              <Button
                                  variant="contained"
                                  color={getAutomation(index)?.id ? "light" : "primary"}
                                  style={{marginLeft: 10, fontSize: '8px' }}
                                  aria-controls={"ur-" + randomUid}
                                  aria-haspopup="true"
                                  onClick={(e) => prepareVariant(index, 'automatyka')}
                              >
                                { getAutomation(index)?.id ? "Edytuj" : "Przygotuj wariant" }
                              </Button>
                            {/*{*/}
                            {/*  getOfferByType(index, 'automatyka')*/}
                            {/*    ? <><Button*/}
                            {/*    variant="contained"*/}
                            {/*    color={getAutomation(index)?.id ? "primary" : "light"}*/}
                            {/*    style={{marginLeft: 10, fontSize: '8px' }}*/}
                            {/*    aria-controls={"ur-" + randomUid}*/}
                            {/*    aria-haspopup="true"*/}
                            {/*    onClick={(e) => routerHistory.push('/offers/edit/'+getOfferByType(index, 'automatyka')?.id)}*/}
                            {/*>*/}
                            {/*    nr {getOfferByType(index, 'automatyka')?.offerNumber}*/}
                            {/*</Button>*/}
                            {/*    */}
                            {/*    </> : */}
                            {/*  <Button*/}
                            {/*      variant="contained"*/}
                            {/*      color={getAutomation(index)?.id ? "default" : "light"}*/}
                            {/*      style={{marginLeft: 10, fontSize: '8px' }}*/}
                            {/*      aria-controls={"ur-" + randomUid}*/}
                            {/*      aria-haspopup="true"*/}
                            {/*      onClick={(e) => prepareOffer(index, 'automatyka')}*/}
                            {/*  >*/}
                            {/*      Przygotuj ofertę*/}
                            {/*  </Button>}*/}

                            </TableCell>
                            <TableCell>
                                {getOfferNumber(formik.values.variants[index]?.offers).length > 0 ? (
                                  getOfferNumber(formik.values.variants[index]?.offers).map((offer, i) => (
                                    <div className={classes.offer} onClick={() => goToOffer(index, offer)}>
                                      {offer}
                                    </div>
                                  ))
                                ) : (
                                  '-'
                                )}
                            </TableCell>
                          </TableRow>
                      ))
                      : null}
            </TableBody>
        </Table>
      </FormikProvider>}
    </RowWrapper>
  );
});

AutomationVariants.propTypes = {};

export default AutomationVariants;
