import React, {useCallback, useEffect, useMemo} from 'react';
import uniqueId from "../../../../../tools/uniqueId";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import {UseActionZoneStyles} from "./ActionZoneStyles";
import returnSpecificField from "../../../../../components/dynamic-forms/returnSpecificField";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import {LAUNCH, REVIEW, SERVICE} from "../../../../../constants/RepairActionType";
import DeleteIcon from "@material-ui/icons/Delete";

export default ({costs, actions, onUpdate}) => {
    const classes = UseActionZoneStyles();

    const editRow = (name, v, index) => {
        const localRows = [...costs];
        const i = findRow(index);
        if (~i) {
            localRows[i][name] = v;
        }
        onUpdate(localRows);
    };

    const removeRow = index => {
        const localRows = [...costs];
        const i = findRow(index);
        if (~i) {
            localRows.splice(i, 1);
            onUpdate(localRows);
        }
    };

    const printActionTitle = useCallback((action) => {
        const name = {
            [LAUNCH]: 'Uruchomienie',
            [SERVICE]: 'Serwis',
            [REVIEW]: 'Przegląd',
        }[action.type] + '';

        const fullDate = getDateFromAction(action);
        const timeAction = fullDate ? moment(fullDate).format('hh:mm') : '';
        return `${name} - godz. ${timeAction}`
    }, []);

    const getDateFromAction = (action) => {
        let fullDate = null;
        if (action.type === SERVICE) {
            fullDate = findField('Data serwisu', action?.fields)?.value;
        } else if (action.type === REVIEW) {
            fullDate = findField('Data przeglądu', action?.fields)?.value;
        } else if (action.type === LAUNCH) {
            fullDate = findField('Data uruchomienia', action?.fields)?.value;
        }
        return fullDate
    };
    const findField = (key, fields) => (fields || []).find(({name}) => name === key);

    const actionsByDate = useMemo(() => actions.reduce((actions, action) => {

        const fullDate = getDateFromAction(action);
        if (fullDate) {
            const actionDate = moment(fullDate).format('YYYY-MM-DD');
            const groupIndex = actions.findIndex(({date}) => date === actionDate);

            if (~groupIndex) {
                actions[groupIndex] = {
                    ...actions[groupIndex],
                    actions: [...actions[groupIndex].actions, {...action, name: printActionTitle(action)}]
                }
            } else {
                actions.push({
                    name: actionDate,
                    id: actionDate,
                    actions: [{...action, name: printActionTitle(action)}]
                })
            }
        }

        return actions
    }, []), [actions]);

    const findRow = key => costs.findIndex(item => item.uuid === key || item.id === key);

    const emptyRow = () => ({
        serwis_koszty_data: '',
        serwis_koszty_czynnosc: '',
        serwis_koszty_kwota_sprzedazy_uslug: '',
        serwis_koszty_dodatkowy_zysk: '',
        serwis_koszty_serwisanta: '',
        serwis_koszty_dojazd_gazuno: '',
        serwis_koszty_serwisant_rabat: '',
        serwis_koszty_serwisant_koszt_czesci: '',
        serwis_koszty_klient_rabat: '',
        serwis_koszty_klient_koszt_czesci: '',
        serwis_koszty_czesci_gazuno: false,
        serwis_koszty_dodatkowe: '',
        serwis_koszty_podsumowanie: '',
        uuid: uniqueId()
    });

    const addRow = () => {
        onUpdate([...costs, emptyRow()]);
    }

    const renderHeader = () => (
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell rowSpan={3}>Data</TableCell>
                <TableCell rowSpan={3}>Czynność</TableCell>
                <TableCell rowSpan={3}>Kwota sprzedaży usługi</TableCell>
                <TableCell rowSpan={3}>Dodatkowy zysk</TableCell>
                <TableCell rowSpan={3}>Koszt serisanta</TableCell>
                <TableCell rowSpan={3}>Koszt dojazdu Gazuno</TableCell>
                <TableCell colSpan={9}>Części</TableCell>
                <TableCell rowSpan={3}>Koszty dodatkowe</TableCell>
                <TableCell rowSpan={3}>Zysk/Strata</TableCell>
                <TableCell rowSpan={3}>Akcję</TableCell>
            </TableRow>
            <TableRow>
                <TableCell rowSpan={2}>Lista</TableCell>
                <TableCell rowSpan={2}>Gwarancja</TableCell>
                <TableCell rowSpan={2}>Nie na gwarancji</TableCell>
                <TableCell rowSpan={2}>Cena producenta</TableCell>
                <TableCell rowSpan={2}>Cena katalogowa</TableCell>
                <TableCell colSpan={2}>Serwisant</TableCell>
                <TableCell colSpan={2}>Klient</TableCell>
                <TableCell>Gazuno</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Rabat</TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}>Koszt części</TableCell>
                <TableCell>Rabat</TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}>Koszt części</TableCell>
                <TableCell style={{whiteSpace: "nowrap"}}>Części po stronie Gazuno</TableCell>
            </TableRow>
        </TableHead>
    );

    return <>
        <a id="costs"/>
        <ExpansionContainer title="Tabela kosztów">
            <ColumnWrapper fullWidth style={{overflow: "scroll"}}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table">
                    {renderHeader()}
                    <TableBody>
                        {costs.map((cost, index) =>
                            <RepairCostZoneRow
                                i={cost}
                                index={cost.uuid || cost.id}
                                key={cost.uuid || cost.id}
                                actions={actionsByDate}
                                editRow={editRow}
                                costs={costs}
                                rowIndex={index + 1}
                                removeRow={removeRow}
                            />
                        )}
                    </TableBody>
                    <RowWrapper>
                        <ColumnWrapper width="auto">
                            <Button className={classes.add} size="small" variant="contained" onClick={addRow}>
                                <AddIcon fontSize="small"/>
                            </Button>
                        </ColumnWrapper>
                    </RowWrapper>
                </Table>
            </ColumnWrapper>
        </ExpansionContainer>
    </>
};

const RepairCostZoneRow = ({i, index, editRow, actions, removeRow}) => {

    const classes = UseActionZoneStyles();

    const onChangeRow = (e, v) => editRow(e, v, index);

    const actionsInSelectedDay = useMemo(() =>
            ((actions || []).find(({name}) => name === i.serwis_koszty_data) || {actions: []}).actions,
        [i.serwis_koszty_data, actions]
    );

    const selectedAction = useMemo(() =>
            (actionsInSelectedDay || []).find(({id}) => id === i.serwis_koszty_czynnosc),
        [actionsInSelectedDay, i.serwis_koszty_czynnosc]
    );

    const devicesProps = useMemo(() =>
            (selectedAction?.devices || [])
                .reduce((devices,
                         {
                             product: {name: productName, net_list_price: productPrice} = {},
                             parts_not_under_warranty,
                             parts_under_warranty
                         }) =>
                        ({
                            notWarrantyPartNames: [
                                ...devices.notWarrantyPartNames,
                                parts_not_under_warranty.map(({name}) => name)
                            ]
                                .filter(name => Array.isArray(name) ? name.length : Boolean(name)),
                            warrantyPartNames: [
                                ...devices.warrantyPartNames,
                                parts_under_warranty.map(({name}) => name)
                            ].filter(name => Array.isArray(name) ? name.length : Boolean(name)),
                            productNames: [...devices.productNames, productName],
                            fullPrice: devices.fullPrice + productPrice,
                            producerPrice: devices.producerPrice + productPrice,
                        }),
                    {
                        notWarrantyPartNames: [],
                        warrantyPartNames: [],
                        productNames: [],
                        fullPrice: 0,
                        producerPrice: 0,
                    }),
        [selectedAction]
    );

    return <>
        <TableRow className={classes.tableBodyRow} key={i.uuid + 'row'}>
            <TableCell>
                {returnSpecificField('dictionary', {data: actions}, 'serwis_koszty_data', i.serwis_koszty_data, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('dictionary', {data: actionsInSelectedDay}, 'serwis_koszty_czynnosc', i.serwis_koszty_czynnosc, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_kwota_sprzedazy_uslug', i.serwis_koszty_kwota_sprzedazy_uslug, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_dodatkowy_zysk', i.serwis_koszty_dodatkowy_zysk, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_serwisanta', i.serwis_koszty_serwisanta, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_dojazd_gazuno', i.serwis_koszty_dojazd_gazuno, onChangeRow)}
            </TableCell>
            <TableCell>
                {devicesProps.productNames.join(', ') || '-'}
            </TableCell>
            <TableCell>
                {selectedAction ? (devicesProps.warrantyPartNames.join(', ') || '-') : '-'}
            </TableCell>
            <TableCell>
                {selectedAction ? (devicesProps.notWarrantyPartNames.join(', ') || '-') : '-'}
            </TableCell>
            <TableCell align="center">
                {selectedAction ? (devicesProps.fullPrice + ' zł') : '-'}
            </TableCell>
            <TableCell align="center">
                {selectedAction ? (devicesProps.producerPrice + ' zł') : '-'}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_serwisant_rabat', i.serwis_koszty_serwisant_rabat, onChangeRow)}
            </TableCell>
            <TableCell align="center">
                {selectedAction ? devicesProps.fullPrice * (i.serwis_koszty_serwisant_rabat || 1) + ' zł' : '-'}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_klient_rabat', i.serwis_koszty_klient_rabat, onChangeRow)}
            </TableCell>
            <TableCell align="center">
                {selectedAction ? (devicesProps.fullPrice * (i.serwis_koszty_klient_rabat || 1)) + ' zł' : '-'}
            </TableCell>
            <TableCell align="center">
                {returnSpecificField('checkbox', {}, 'serwis_koszty_czesci_gazuno', i.serwis_koszty_czesci_gazuno, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('money', {}, 'serwis_koszty_dodatkowe', i.serwis_koszty_dodatkowe, onChangeRow)}
            </TableCell>
            <TableCell>
                {returnSpecificField('dictionary', {data: [{name: 'Zysk', id: 'Zysk'}, {name: 'Strata', id: 'Strata'}]}, 'serwis_koszty_podsumowanie', i.serwis_koszty_podsumowanie, onChangeRow)}
            </TableCell>
            <TableCell>
                <Button style={{marginLeft: 15}} variant="contained" onClick={() => removeRow(index)}>
                    <DeleteIcon/>
                </Button>
            </TableCell>
        </TableRow>
    </>
};
