import React, {useState} from 'react';
import routerHistory from "../../../../../constants/settings/routerHistory";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import ExpansionContainer from "../../../../../components/containers/ExpansionContainer";
import ColumnWrapper from "../../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../../components/wrappers/RowWrapper";
import {History} from '@material-ui/icons';
import {Button} from '@material-ui/core';
import ActionsHistoryTable from "./ActionsHistoryTable";

const RepairActionsZone = ({data}) => {

    return (
        <>
            <a id="services"/>
            <ExpansionContainer title="Czynności serwisowe">
                <RowWrapper style={{width: '100%', alignItems: 'center'}}>
                    <ColumnWrapper width="25%">
                        <PrimaryButton
                            disabled={!(data?.id)}
                            onClick={() => {
                                routerHistory.push(`/service/repairs/${data?.id}/actions/launch`);
                            }}
                        >
                            Uruchomienie
                        </PrimaryButton>
                    </ColumnWrapper>
                    <ColumnWrapper width="25%">
                        <PrimaryButton
                            disabled={!(data?.id)}
                            onClick={() => {
                                routerHistory.push(`/service/repairs/${data?.id}/actions/service`);
                            }}
                        >
                            Serwis
                        </PrimaryButton>
                    </ColumnWrapper>
                    <ColumnWrapper width="25%">
                        <PrimaryButton
                            disabled={!(data?.id)}
                            onClick={() => {
                                routerHistory.push(`/service/repairs/${data?.id}/actions/review`);
                            }}
                        >
                            Przegląd
                        </PrimaryButton>
                    </ColumnWrapper>
                    <ColumnWrapper width="25%">
                        <Button variant="contained"
                                disabled={!(data?.id)}
                                onClick={() => {
                                    routerHistory.push(`/service/repairs/${data?.id}/history`);
                                }}
                        >
                            <History fontSize="small" style={{marginRight: '10px'}}/> Historia inwestycji
                        </Button>
                    </ColumnWrapper>
                </RowWrapper>
                {!!data?.actions?.length
                && <>
                    <ActionsHistoryTable actions={data?.actions || []}/>
                </>
                }
            </ExpansionContainer>
        </>
    );
};

export default RepairActionsZone;
