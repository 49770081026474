import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import headCells from './components/repiarers/list/headCells';
import ListToolBar from './components/repiarers/list/ListToolBar';
import { API_REPAIRERS_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";

const RepairersScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const state = useStoreState((state) => state.auth?.loggedInUser);

  const fetchRepairers = async (url = API_REPAIRERS_ROUTE) => {
    const { data } = await getCall(url);
    if (data) {
      setCount(data?.total);
      setRows({
        initialState: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          name: i?.name,
          shortcut: i?.shortcut,
          address: i?.fields?.find((i) => i.name === 'serwisant_adres')?.value || '-'
        })),
        state: data?.items?.map((i) => ({
          identity: i?.id,
          id: i?.id,
          name: i?.name,
          shortcut: i?.shortcut,
          address: i?.fields?.find((i) => i.name === 'serwisant_adres')?.value || '-'
        })),
      });
    }
  };

  useEffect(() => {
    fetchRepairers();
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const { data } = await getCall(`${API_REPAIRERS_ROUTE}?query=${v}`);
      if (data) {
        setRows({
          ...rows,
          state: data?.items?.map((i) => ({
            identity: i?.id,
            id: i?.id,
            name: i?.name,
            shortcut: i?.shortcut,
            address: i?.fields?.find((i) => i.name === 'serwisant_adres')?.value || '-'
          })),
        });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleDelete = async (numSelected) => {
    const isAdmin = state?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (isAdmin) {
      const { data } = await deleteCall(API_REPAIRERS_ROUTE, {
        ids: numSelected.join(),
      });
      const [status] = data;
      if (status?.success) {
        toast.success('Pomyślnie usunięto wybranych serwisantów.');
      } else {
        toast.error('Wystąpił błąd podczas usuwania wybranych serwisantów.');
      }
    } else {
      toast.warn(
        'Wybrany użytkownik nie posiada uprawnień do usuwania serwisantów.',
      );
    }
  };

  return (
    <>
      <ListToolBar handleSearch={handleSearch} />
      <BasicTable
        onDelete={handleDelete}
        useDialogOnDelete
        headCells={headCells}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchRepairers(`${API_REPAIRERS_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) =>
          routerHistory.push(`/service/repairers/edit/${id}`)
        }
      />
    </>
  );
};

export default RepairersScreen;
