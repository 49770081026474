import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import {
  deleteCall,
  getCall
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import ListToolBar from './components/list/ListToolBar';
import { API_COMPANIES_ROUTE } from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import RowWrapper from '../../components/wrappers/RowWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';
import CompaniesSearchFilters from './components/list/search-filters/CompaniesSearchFilters';
import { responseToForm, turnBooleansToArray } from '../../components/dynamic-forms/field-transformer';
import { companyStatuses } from './components/details/basic-data/companyFields';
import serializeQuery from '../../tools/serializeQuery';
import Tooltip from "@material-ui/core/Tooltip";

const CompaniesScreen = () => {
  const [rows, setRows] = useState({ initialState: [], state: [] });
  const [count, setCount] = useState(null);
  const state = useStoreState((state) => state.auth?.loggedInUser);

  const statuses = companyStatuses.map((item) => item.name);
  const statusTranslation = companyStatuses.reduce((arr, {name, label}) => Object.assign(arr, {[name]: label }), {});

  const fetchCompanies = async (url = API_COMPANIES_ROUTE) => {
    const query = url.includes('page') ? url.concat('&results_on_page=20') : url.concat('?results_on_page=20');

    const { data } = await getCall(query);
    if (data) {
      setCount(data?.total);
      
      setRows({
        initialState: data?.items?.map((i) => {
            const fields = responseToForm(i?.fields);
            const status = turnBooleansToArray(statuses, i?.fields).map((item) => statusTranslation[item]).join(',');
            return {
            identity: i?.id,
            id: i?.id,
            name: i?.name,
            status: status || '',
            miasto: fields?.miasto || '',
            wojewodztwo: fields?.wojewodztwo || '',
            adres: fields?.adres || '',
            uzytkownik: fields?.uzytkownik || '',
            ranking: fields?.ranking || '',
          }
        }),
        state: data?.items?.map((i) => {
          const fields = responseToForm(i?.fields);
          const status = turnBooleansToArray(statuses, i?.fields)
            .map((item) => 
              statusTranslation[item]
            ).join(',');
          return {
          identity: i?.id,
          id: i?.id,
          name: i?.name,
          status: status || '',
          osoba: fields?.contactslist?.length
              ? <Tooltip title={(fields?.contactslist || []).map(({osoba}) => osoba).join(', ')} placement="top">
                    <p>{`${fields?.contactslist?.[0]?.osoba} ${fields?.contactslist?.[1]?.osoba ? '...' : ''}`}</p>
                </Tooltip>
              : '-',
          miasto: fields?.miasto || '',
          wojewodztwo: fields?.wojewodztwo || '',
          adres: fields?.adres || '',
          uzytkownik: fields?.uzytkownik || '',
          ranking: fields?.ranking || '',
        }
      }),
      });
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSearch = async (v) => {
    if (!v) {
      setRows({ ...rows, state: rows.initialState });
    } else {
      const queryPath = serializeQuery(v);
      const { data } = await getCall(`${API_COMPANIES_ROUTE}?${queryPath}`);
      if (data) {
        setRows({
          ...rows,
          state: data?.items?.map((i) => {
            const fields = responseToForm(i?.fields);
            const status = turnBooleansToArray(statuses, i?.fields)
              .map((item) => {
                return statusTranslation[item];
              }
              ).join(',');
            return {
            identity: i?.id,
            id: i?.id,
            name: i?.name,
            status: status || '',
            osoba: fields?.contactslist?.length
                ? <Tooltip title={(fields?.contactslist || []).map(({osoba}) => osoba).join(', ')} placement="top">
                    <p>{`${fields?.contactslist?.[0]?.osoba || '-'} ${fields?.contactslist?.[1]?.osoba ? '...' : ''}`}</p>
                  </Tooltip>
                      : '-',
            miasto: fields?.miasto || '',
            wojewodztwo: fields?.wojewodztwo || '',
            adres: fields?.adres || '',
            uzytkownik: fields?.uzytkownik || '',
            ranking: fields?.ranking || '',
          }
        }),
        });
      } else {
        setRows({ ...rows, state: [] });
      }
    }
  };

  const handleDelete = async (numSelected) => {
    const isAdmin = state?.roles.filter((role) => role).find(
      (role) => role.toString() === 'ROLE_ADMIN',
    );
    if (isAdmin) {
      const { data } = await deleteCall(API_COMPANIES_ROUTE, {
        ids: numSelected.join(),
      });
      const [status] = data;
      if (status?.success) {
        toast.success('Pomyślnie usunięto wybrane firmy.');
      } else {
        toast.error('Wystąpił błąd podczas usuwania wybranych firm.');
      }
    } else {
      toast.warn('Wybrany użytkownik nie posiada uprawnień do usuwania firm.');
    }
  };

  const handleAdded = () => {
    fetchCompanies();
  }

  return (
    <>
      <ListToolBar handleAdded={handleAdded} />
      <RowWrapper>
        <ColumnWrapper style={{maxWidth: '350px'}}>
          <CompaniesSearchFilters handleSearch={handleSearch} />
        </ColumnWrapper>
        <ColumnWrapper fullWidth size="100%">
      <BasicTable
        onDelete={handleDelete}
        useDialogOnDelete
        headCells={[  { id: 'identy', numeric: false, disablePadding: false, label: 'id' },
        { id: 'company', numeric: false, disablePadding: false, label: 'nazwa' },
        { id: 'status', numeric: false, disablePadding: false, label: 'status' },
        { id: 'osoba', numeric: false, disablePadding: false, label: 'osoby' },
        { id: 'miasto', numeric: false, disablePadding: false, label: 'miasto' },
        { id: 'wojewodztwo', numeric: false, disablePadding: false, label: 'województwo' },
        { id: 'adres', numeric: false, disablePadding: false, label: 'adres' },
        { id: 'uzytkownik', numeric: false, disablePadding: false, label: 'KR' },
        { id: 'ranking', numeric: false, disablePadding: false, label: 'ranking' }]}
        rows={rows.state}
        onChangePage={async (p) =>
          await fetchCompanies(`${API_COMPANIES_ROUTE}?page=${p}`)
        }
        recordsCount={count}
        onRowClick={({ id }) => routerHistory.push(`/companies/edit/${id}`)}
        rowsPerPageLenth={20}
      />
      </ColumnWrapper>
      </RowWrapper>
    </>
  );
};

export default CompaniesScreen;
