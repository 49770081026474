const autoOffer = [
  {
    type: 'multipleFields',
    name: 'Informacje na ofercie',
    value: [],
    options: { fields: [
      { name: 'Informacje na ofercie', type:'header', fullWidth: true, value: '', },
      { label: 'Funkcjonalność układu automatyki', name: 'Funkcjonalność układu automatyki', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Obiekty sterowane', name: 'Obiekty sterowane', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Właściwości elektryczne elementów wykonawczych przyjęte do wyceny', name: 'Właściwości elektryczne elementów wykonawczych przyjęte do wyceny', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Układ automatyki węzła zawiera', name: 'Układ automatyki węzła zawiera', type: 'multilineInput', fullWidth: true, value: '', },
      { label: 'Wymagane prace do wykonania przez instalatorów na obiekcie przed uruchomieniem', name: 'Wymagane prace do wykonania przez instalatorów na obiekcie przed uruchomieniem', type: 'multilineInput', fullWidth: true, value: '- instalacja szafy automatyki w miejscu przeznaczenia (w budynku);\r\n- montaż dostarczonych czujników oraz osłon;\r\n- doprowadzenie przewodów miedzianych pomiędzy szafą automatyki, a miejscami instalacji poszczególnych czujników oraz elementów sterujących (pompy obiegowe, siłownik) zgodnie z zestawieniem; \r\n- doprowadzenie magistrali CAN pomiędzy zestawem urządzeń Robur a szafą automatyki;\r\n- doprowadzenie napięcia zasilania do szafy automatyki;\r\n- gotowość układu hydraulicznego do rozruchu – wypełnienie instalacji glikolem i wodą, zainstalowanie odbiorów ciepła;\r\n- doprowadzenie do szafy sterującej przewodu sieci komputerowej, zakończonego obustronnie wtykiem RJ45, zapewniającego dostęp do Internetu.', },
      
      
      { label: 'Warunki płatności - wybór', name: 'Warunki płatności - wybór', type: 'dictionary', options: { data: [
        {id: 0, name: 'Płatność: 100% przedpłaty w dniu złożenia zamówienia.'},
        {id: 1, name: 'Płatność zgodnie z zawartą umową handlową: 30 dni dostawie.'},
        {id: 2, name: 'Płatność zgodnie z zawartą umową handlową: 100% przedpłaty w dniu złożenia zamówienia.'},
        {id: 3, name: 'Płatność zgodnie z zawartą umową handlową: 50% przedpłaty w dniu złożenia zamówienia, pozostałe 50% 30 dni po dostawie.'},
        {id: 4, name: 'Własne warunki'},
      ] }, value: '', },
      { label: 'Warunki płatności', name: 'Warunki płatności', type: 'multilineInput', fullWidth: true, value: '1. Przedpłata w wysokości 100 % łącznej ceny automatyki na podstawie faktury proforma w dniu złożenia zamówienia. Po zaksięgowaniu wpłaty zostanie wystawiona faktura zaliczkowa cząstkowa odnosząca się do danej płatności.\r\n2. Po dostarczeniu szafy automatyki Zleceniobiorca zobowiązuje się do wystawienia i przesłania Zamawiającemu faktury zaliczkowej końcowej uwzględniającej rozliczenie płatności wymienionej w punkcie powyżej.', },
      { label: 'Podatek VAT', name: 'Podatek VAT', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Do ceny należy doliczyć 23% podatku VAT.' }, value: true, },
      { label: 'Ważność oferty', name: 'Ważność oferty', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Ważność oferty: 30 dni.' }, value: true, },
      { label: 'Wymagany przewód komunikacyjny', name: 'Wymagany przewód komunikacyjny', type: 'offerTextCheckbox', fullWidth: true, options: { defaultText: 'Przy zastosowaniu trzech lub więcej urządzeń Robur wymagany jest przewód komunikacyjny ROBUR NETBUS, do położenia instalacji między zestawami urządzeń a miejscem instalacji szafy automatyki (cena netto: 7 EUR za mb).' }, value: true, },
      { label: 'Okres gwarancji', name: 'okresGwarancji', type: 'dictionary', options: { data: [
        {id: '2 lata', name: '2 lata'},
        {id: '3 lata', name: '3 lata'},
        {id: '4 lata', name: '4 lata'},
        {id: '5 lat', name: '5 lat'},
      ] }, value: '', },
      { label: 'Cena', name: 'Cena', type: 'text', value: '', },
      { label: 'Rabat (w %)', name: 'Rabat', type: 'text', value: '', },
      { label: 'Cena transakcyjna', name: 'Cena transakcyjna', type: 'text', value: '', },
      ]
    },
  },
];


export { autoOffer};