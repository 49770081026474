import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCall } from "../../components/indicator/Indicator";
import ColumnWrapper from "../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../components/wrappers/RowWrapper";
import BasicData from './components/details/basic-data/BasicData';
import DetailsToolBar from './components/details/DetailsToolBar';
import NotesPanel from "../../components/notes/NotePanel";
import {
  API_COMPANIES_ROUTE,
} from '../../constants/routes';
import companyFields from './components/details/basic-data/companyFields';

const CompaniesDetailsScreen = () => {
  const [editData, setEditData] = useState({ notes: [] });
  const [notesData, setNotesData] = useState({ notes: [] });
  const { id } = useParams();

  const fetchEditData = useCallback(async () => {
    const r = await getCall(`${API_COMPANIES_ROUTE}/${id}`);
    if (r?.data?.object) {
      setNotesData(r?.data?.object);
      return r?.data?.object;
    }
    return [];
  }, [id]);

  async function fetchFields() {
    if (id && id !== 'new') {
      const editData = await fetchEditData();
      if (editData) {
        setEditData(editData);
        setNotesData(editData);
      }
    }
  }

  async function fetchNotes() {
      const editData = await fetchEditData();
      if (editData) {
        setNotesData({...editData, notes: editData?.notes});
      }
  }

  useEffect(() => {
    fetchFields();
  }, [id, fetchEditData]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <ColumnWrapper>
        <DetailsToolBar id={id} toggleDrawer={toggleDrawer} />
        <RowWrapper style={{ alignItems: 'flex-start' }}>
          <ColumnWrapper width={open ? '60%' : '100%'}>
          <BasicData
          edit={id !== 'new' ? id : null}
          data={editData}
          fields={companyFields}
          companyId={id}
          fetchData={() => fetchFields()}
          fetchNotes={() => fetchNotes()}
        />
          </ColumnWrapper>
            {open && <ColumnWrapper>
              <NotesPanel 
                fetchNotes={fetchEditData} data={notesData}
                selectedCompany={{id, name: id}}
              />
            </ColumnWrapper>}
        </RowWrapper>
      </ColumnWrapper>
    </>
  );
};

export default CompaniesDetailsScreen;
