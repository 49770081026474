import {Button, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useMemo} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {translateGroup, WITHOUT_GROUP} from "../../../../../../constants/RepairDeviceGroup";
import {groupBy} from "lodash";
import routerHistory from "../../../../../../constants/settings/routerHistory";
import HistoryIcon from "@material-ui/icons/History";

export default ({action, repairId}) => {

    const yesNoInfo = value => !!value ? 'TAK' : 'NIE';

    const groupedRows = useMemo(() => {
        const setEmptyGroup = rows => rows.map(row => ({...row, group_name: row?.group_name || WITHOUT_GROUP}));
        const grouped = groupBy(setEmptyGroup(action.devices), ({group_name, group_serial_no}) => group_name ? `${group_name}${group_serial_no ? ' - ' + group_serial_no : ''}` : null);
        return Object.entries(grouped);
    }, [action]);

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Lp</TableCell>
                    <TableCell>Grupa urządzeń</TableCell>
                    <TableCell>Urządzenie</TableCell>
                    <TableCell>Części dodatkowe na gwarancji</TableCell>
                    <TableCell>Części dodatkowe nie na gwarancji</TableCell>
                    <TableCell>Notatka z serwisu</TableCell>
                    <TableCell>Akcje</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {groupedRows.map(([, rows]) =>
                    rows.map((row, index) =>
                        <TableRow key={row.id}>
                            <TableCell>
                                {index + 1}
                            </TableCell>
                            <TableCell>
                                {translateGroup(row.group_name)}
                            </TableCell>
                            <TableCell>
                                {row?.product?.name || '-'}
                            </TableCell>
                            <TableCell>
                                {yesNoInfo(row?.parts_under_warranty?.length)}
                            </TableCell>
                            <TableCell>
                                {yesNoInfo(row?.parts_not_under_warranty?.length)}
                            </TableCell>
                            <TableCell>
                                {row?.description || '-'}
                            </TableCell>
                            <TableCell>
                                <Button
                                    disabled={!row?.repair_device_id || !repairId}
                                    variant="contained"
                                    onClick={() => routerHistory.push(`/service/repairs/${repairId}/devices/${row?.repair_device_id}/history`)}
                                >
                                    <HistoryIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>)
                )}
            </TableBody>
        </Table>
    </>
}