import styled from 'styled-components'
import RowWrapper from './wrappers/RowWrapper';

const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export { Wrapper, ButtonWrapper };