import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import {getCall, postCall, putCall} from '../indicator/Indicator';
import { API_TASKS_ROUTE } from '../../constants/routes';
import { MoreHoriz } from '@material-ui/icons';
import RowWrapper from '../wrappers/RowWrapper';
import { MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import FeedbackDialog from "./FeedbackDialog";

const useStyles = makeStyles({
    root: {
        '&:hover': {
        backgroundColor: 'transparent',
        },
        marginRight: '5px',
        padding: '0px'
    },
    icon: {
        borderRadius: '100%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
        },
        'input:hover ~ &': {
        backgroundColor: '#d4e9e0',
        },
        'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#414141',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
        },
        'input:hover ~ &': {
        backgroundColor: '#222222',
        },
    },
    status0: {
        width: 16,
        height: 16,
        
    },
    status1: {
        width: 16,
        height: 16,

    },
    status2: {
        width: 16,
        height: 16,
        backgroundColor: '#c5c02a',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,0%,.2),hsla(0,0%,0%,0))',
    },
    status3: {
        width: 16,
        height: 16,
        backgroundColor: '#941e38',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,0%,.2),hsla(0,0%,0%,0))',
    },
    wrap: {
        display: 'flex',
        flexDirection: 'row'
    },
    clearButton: {
        borderRadius: '100%',
        border: 'none',
        width: 18,
        height: 18,
        cursor: 'pointer',
        opacity: 0.5,
        fontSize: '16px',
        backgroundColor: '#f5f8fa',
        '&:hover': {
            backgroundColor: '#d0d0d0',
            opacity: 1
        },

    }
});

// Inspired by blueprintjs
const TaskTick = (props) => {
    const classes = useStyles();
    const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
    const [checked, setChecked] = React.useState(props?.status === 1 ? true : false);
    const [loading, setLoading] = React.useState(false);
    const [statusClass, setStatusClass] = React.useState(classes.root);

    React.useEffect(() => {
        if(props?.status) {
            if(props?.status === 1) {
                setChecked(true);
            }
            const className = 'status' + props.status;
            setStatusClass(classes.root + ' ' + classes[className]);
        }
    }, [props?.status]);

    const handleChange = async (event) => {
        if(props?.id) {
            const newChecked = event.target.checked;
            setLoading(true);
            const result = await getCall(`${API_TASKS_ROUTE}/${props.id}/solve/${newChecked ? '1' : '0'}`);
            if(result) {
                setChecked(newChecked);
                const className = 'status' + newChecked;
                setStatusClass(classes.root + ' ' + classes[className]);
                setLoading(false);
            }
        }
    };

    const forceStatus = async (status, params) => {
        if(props?.id) {
            setLoading(true);
            const result = await postCall(`${API_TASKS_ROUTE}/${props.id}/solve/${status}`, params);
            if(result) {
                if(status === 1) {
                    setChecked(true);
                    props.onUpdateParams && props.onUpdateParams(params)
                } else {
                    setChecked(false);
                }
                setLoading(false);
                const className = 'status' + status;
                setStatusClass(classes.root + ' ' + classes[className]);
                setAnchorEl(null);
            }
        }
    };

    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const pid = open ? 'task-more-pop' : undefined;

    return (
        <div className={classes.wrap}>
            <Checkbox
                className={classes.root}
                color="default"
                checked={checked}
                disabled={loading}
                onChange={(e) => props?.waitOnFeedback && e.target.checked ? setOpenFeedbackModal(true) : handleChange(e)}
                checkedIcon={<span className={classes.icon + ' ' + classes.checkedIcon + ' ' + statusClass} />}
                icon={<span className={classes.icon + ' ' + statusClass} />}
            />
            <button 
                onClick={(e) => {
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                }} 
                aria-describedby={pid}
                className={classes.clearButton}>
                <MoreHoriz fontSize="inherit" />
            </button>
            <Popper
                id={pid}
                placement="bottom-start"
                open={open}
                anchorEl={anchorEl}
                modifiers={{
                    flip: {
                    enabled: true,
                    }
                }}
            >
                <Paper>
                    <RowWrapper style={{alignItems: 'center'}}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                            {<MenuItem onClick={(e) => forceStatus(0)} disabled={props.status === 0}>Do zrobienia</MenuItem>}
                            {<MenuItem onClick={(e) => props?.waitOnFeedback ? setOpenFeedbackModal(true) : forceStatus(1)} disabled={props.status === 1 || ( props.waitOnFeedback && !props.feedbackComment)}>Zrobione</MenuItem>}
                            {<MenuItem onClick={(e) => forceStatus(2)} disabled={props.status === 2}>Odrzucone</MenuItem>}
                            {<MenuItem onClick={(e) => forceStatus(3)} disabled={props.status === 3}>Anulowane</MenuItem>}
                        </MenuList>
                    </RowWrapper>
                </Paper>
            </Popper>
            <FeedbackDialog
                onClose={() => setOpenFeedbackModal(false)}
                isOpen={openFeedbackModal}
                onAccept={(feedbackComment) => forceStatus(1, {feedbackComment, isFeedback: true})}
                onCancel={(feedbackComment) => forceStatus(1, {feedbackComment, isFeedback: false})}
                initialDescription={props?.feedbackComment || ''}
            />
        </div>
    );
}

export default TaskTick;