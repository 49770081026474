import React, {useEffect, useMemo, useRef, useState} from "react";
import DynamicTable from "../../../../../components/tables/basic/dynamic-table/DynamicTable";
import {getCall} from "../../../../../components/indicator/Indicator";
import {API_PRODUCT_CATEGORIES_ROUTE} from "../../../../../constants/routes";
import Loader from "../../../../../components/loader/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import serializeQuery from "../../../../../tools/serializeQuery";
import {Collapse, TableCell} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ProductTable from "./ProductTable";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddEditPopup from "../../add-edit/category/AddEditPopup";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

export default ({additionalFilters, headerStyles, groupId}) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({});
    const [editId, setEditId] = useState(null);
    const popupRef = useRef();

    const handleAdd = () => {
        setEditId(null);
        popupRef.current.open();
    };

    const handleEdit = id => {
        setEditId(id);
        popupRef.current.open();
    };

    const columns = [
        {header: 'Kategoria', key: 'name'},
        {
            header: <div style={{display: 'flex', alignItems: 'center'}} onClick={handleAdd}>
                Akcje
                <Tooltip title="Dodaj kategorię">
                    <AddIcon style={{cursor: 'pointer'}} fontSize="small"/>
                </Tooltip>
            </div>,
            key: 'actions',
            sort: false
        },
    ];

    const findCategoryIndex = category => categories.findIndex(({id}) => id === category?.id);

    const updateCategories = (newCategory, index) => {
        const newCategories = categories.map(category => ({...category}));
        newCategories.splice(index, 1, newCategory);
        setCategories(() => newCategories);
    };

    const stringParams = useMemo(
        () => serializeQuery({...additionalFilters, ...filters}),
        [filters, additionalFilters, serializeQuery]
    );

    const fetchCategories = () =>
        getCall(`${API_PRODUCT_CATEGORIES_ROUTE}?${stringParams}`)
            .then(({data: {items = []} = {}}) => setCategories(items));

    const appendProducts = category => {
        const index = findCategoryIndex(category);
        if (~index) {
            const isOpen = !category?.isOpen;
            updateCategories(
                {
                    ...category,
                    isOpen,
                    attachedData:
                        <TableCell style={{padding: 0}} colSpan={2}>
                            <Collapse in={isOpen} timeout="auto">
                                <Box margin={1} style={{marginRight: 0}}>
                                    <ProductTable headerStyles={headerStyles}
                                                  additionalFilters={{category: category.id}}
                                                  groupId={groupId}
                                                  producerId={additionalFilters.producer}/>
                                </Box>
                            </Collapse>
                        </TableCell>
                },
                index
            )
        }
    };

    useEffect(() => {
        fetchCategories().then(() => setLoading(false))
    }, []);

    const reload = async () => {
        setLoading(true);
        await fetchCategories();
        setLoading(false)
    };

    const displayName = type =>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {type?.isOpen
                ? <ArrowUpwardIcon fontSize="small"/>
                : <ArrowDownwardIcon fontSize="small"/>
            }
            {type.name}
        </div>;

    const displayActions = category => <>
        <Tooltip title={`Podgląd ${category.name}`} placement="top">
            <IconButton onClick={() => appendProducts(category)}>
                <VisibilityIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
        <Tooltip title={`Edycja ${category.name}`} placement="top">
            <IconButton onClick={() => handleEdit(category?.id)}>
                <EditIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    </>;

    return <>
        {
            loading
                ? <Loader/>
                : <>
                    <DynamicTable
                        headerStyles={headerStyles}
                        columns={columns}
                        rows={categories}
                        showEmptyInfo={!categories?.length}
                        onClickRow={appendProducts}
                    >
                        {{
                            name: displayName,
                            actions: displayActions,
                        }}
                    </DynamicTable>
                    <AddEditPopup
                        categoryId={editId}
                        ref={popupRef}
                        onSave={reload}
                    />
                </>
        }
    </>
};