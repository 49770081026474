import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Redirect, useLocation } from 'react-router-dom';

const ProtectedRouteWrapper = ({ children }) => {
  const isAuthenticated = useStoreState((s) => s.auth.isAuthenticated);
  const [firstLocation, setFirstLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') setFirstLocation(location.pathname);
    else setFirstLocation('/main');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isAuthenticated && firstLocation !== null ? (
        <Redirect to={{ pathname: '/', refferer: firstLocation }} />
      ) : (
        children
      )}
    </>
  );
};

ProtectedRouteWrapper.propTypes = {};

export default ProtectedRouteWrapper;
