import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import { getCall } from '../../../../components/indicator/Indicator';
import NoDataPanel from "../../../../components/panels/NoDataPanel";
import BasicTable from '../../../../components/tables/basic/BasicTable';
import TaskTick from '../../../../components/task-tick/TaskTick';
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import { API_TASKS_ROUTE } from '../../../../constants/routes';
import routerHistory from '../../../../constants/settings/routerHistory';

const TaskBoard = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(null);

  const fetchTasks = async (url = `${API_TASKS_ROUTE}/upcoming`) => {
    const { data } = await getCall(url);
    if (data?.items) {
      setCount(data?.total);
      const r = data?.items.map((item) => ({
        id: item.id,
        done: (<TaskTick id={item.id} status={item?.status} />),
        desc: (<u onClick={() => routerHistory.push(`/tasks/edit/${item.id}`)}>{item?.description}</u>),
        startDate: (item?.startDate ? moment(item?.startDate?.date).format('YYYY-MM-DD') : '-'),
        deadline: (item?.deadline ? moment(item?.deadline?.date).format('YYYY-MM-DD') : '-'),
        type: item?.type,
      }));
      setRows(r);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <>
      <RowWrapper fullWidth style={{ padding: '0 10px' }}>
        <ExpansionContainer title="Nadchodzące zadania">
          <ColumnWrapper width="100%">
              {!rows?.length && <NoDataPanel text="Brak zadań" />}
              {rows && <BasicTable
                minimal={true}
                headCells={[
                { id: 'done', numeric: false, disablePadding: false, label: ' ' },
                { id: 'desc', numeric: false, disablePadding: false, label: ' ' },
                {
                  id: 'startDate',
                  numeric: false,
                  disablePadding: false,
                  label: ' ',
                },
                {
                  id: 'deadline',
                  numeric: false,
                  disablePadding: false,
                  label: ' ',
                },
                {
                  id: 'type',
                  numeric: false,
                  disablePadding: false,
                  label: ' ',
                }]}
                rows={rows}
                recordsCount={count}
                
              />}
          </ColumnWrapper>
        </ExpansionContainer>
      </RowWrapper>
    </>
  );
};

export default TaskBoard;
