import React from 'react';
import commonValues from "../../../../../tools/commonValues";

const repairsFields = [{
    "id": 10,
    "name": "Karta przeglądu",
    "type": "multipleFields",
    "options": {
        "fields": [
            {
                "id": 61,
                "name": "value_project",
                "type": "entity",
                "label": "Nazwa inwestycji",
                "value": null,
                "options": { width: 4, entityClass: 'project', freeSolo:  true }
            },
            {
                "id": 61,
                "name": "serwis_schema",
                "type": "attachment",
                "label": "Schemat",
                "value": null,
                "options": { width: 4}
            },
            {
                "id": 61,
                "name": "serwis_offer",
                "type": "wonOffer",
                "label": "Wygrana oferta",
                "value": null,
                "options": { width: 4, entityClass: 'offer', forceParamId: '', multiple: true }
            },
            {
                "id": 61,
                "name": "serwis_ul",
                "type": "text",
                "label": "Ulica",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 61,
                "name": "serwis_city",
                "type": "text",
                "label": "Miasto",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 61,
                "name": "serwis_kod",
                "type": "text",
                "label": "Kod",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 62,
                "name": "serwis_voivodeship",
                "type": "dictionary",
                "label": "Województwo",
                "value": null,
                "options": {
                    "id": 1,
                    "width": 4,
                    "data": commonValues.voivodeships,
                    "fields": [],
                    "name": "województwo",
                },
            },
            { name: '', type: 'none', fullWidth: true, value: null },
            {
                "id": 57,
                "name": "serwis_last_contact",
                "type": "text",
                "label": "Osoba do kontaktu",
                "value": null,
                "options": {
                    width: 4
                }
            },
            {
                "id": 61,
                "name": "serwis_phone",
                "type": "phone",
                "label": "Telefon",
                "value": null,
                "options": { width: 4, type: 'number' }
            },
            {
                "id": 61,
                "name": "serwis_mail",
                "type": "text",
                "label": "E-mail",
                "value": null,
                "options": { width: 4 }
            },
            
            {
                "id": 61,
                "name": "value_repairer",
                "type": "entity",
                "label": "Serwisant",
                "value": null,
                "options": { width: 4, entityClass: 'repairer', preview: true }
            },
            {
                "id": 61,
                "name": "serwis_kr",
                "type": "entity",
                "label": "KR",
                "value": null,
                "options": { width: 4, entityClass: 'user' }
            },
            {
                "id": 61,
                "name": "serwis_inwestor",
                "type": "company",
                "label": "Inwestor",
                "value": null,
                "options": {
                    width: 4,
                }
            },
            { name: '', type: 'none', fullWidth: true, value: null },
            {
                "id": 58,
                "name": "serwis_data_uruchomienia",
                "type": "dateonly",
                "label": "Data uruchomienia",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 59,
                "name": "serwis_data_start",
                "type": "dateonly",
                "label": "Data startu gwarancji",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 61,
                "name": "serwis_gwarancja",
                "type": "text",
                "label": "Gwarancja",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 33,
                "name": "serwis_data",
                "type": "dateonly",
                "label": "Data ostatniego przeglądu",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 57,
                "name": "serwis_data_next",
                "type": "dateonly",
                "label": "Data następnego przeglądu",
                "value": null,
                "options": { width: 4 }
            },
            {
                "id": 60,
                "name": "serwis_kiedy",
                "type": "text",
                "label": "Przegląd po...",
                "value": null,
                "options": { width: 4 }
            },
                    ]
                }
}];

export default repairsFields;