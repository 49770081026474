import { TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpansionContainer from "../../../../components/containers/ExpansionContainer";
import Select from '../../../../components/dynamic-forms/form-components/Select';
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import FloatingSaveBar from "../../../../components/FloatingSaveBar";
import { useParams } from 'react-router-dom';
import { API_HEAT_EXCHANGER_ROUTE } from '../../../../constants/routes';
import { toast } from 'react-toastify';
import {
  postCall
} from "../../../../components/indicator/Indicator";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import BackButton from "../../../../components/buttons/back-button/BackButton";
import routerHistory from "../../../../constants/settings/routerHistory";
import styled from 'styled-components';
const Wrapper = styled(RowWrapper)`
  justify-content: space-between;
`;

const HeatExchangerNewScreen = () => {
  const [type, setType] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [primarySideMedium, setPrimarySideMedium] = useState(null);
  const [secondarySideMedium, setSecondarySideMedium] = useState(null);
  const [maximumPressureDrop, setMaximumPressureDrop] = useState(null);
  const [heatingPower, setHeatingPower] = useState(null);
  const [coolingPower, setCoolingPower] = useState(null);
  const [S1_temperatureDropInExchanger, setS1_TemperatureDropInExchanger] = useState(null);
  const [D1_deltaSupplyReturn, setD1_DeltaSupplyReturn] = useState(10);
  const [D2_deltaSupplyReturn, setD2_DeltaSupplyReturn] = useState(10);
  const [A1_primarySideTemperatureInput, setA1_PrimarySideTemperatureInput] = useState(null);
  const [B1_primarySideTemperatureInput, setB1_PrimarySideTemperatureInput] = useState(null);
  const [C1_primarySideTemperatureInput, setC1_PrimarySideTemperatureInput] = useState(null);
  const { projectId } = useParams();

  const submitForm = async () => {
    const callExchanger = async (fn) => {
      const r = await fn(API_HEAT_EXCHANGER_ROUTE, {
        type: type,
        purpose: purpose,
        primarySideMedium: primarySideMedium,
        secondarySideMedium: secondarySideMedium,
        maximumPressureDrop: Number(maximumPressureDrop),
        heatingPower: Number(heatingPower),
        coolingPower: Number(coolingPower),
        S1_temperatureDropInExchanger: Number(S1_temperatureDropInExchanger),
        D1_deltaSupplyReturn: Number(D1_deltaSupplyReturn),
        D2_deltaSupplyReturn: Number(D2_deltaSupplyReturn),
        A1_primarySideTemperatureInput: Number(A1_primarySideTemperatureInput),
        B1_primarySideTemperatureInput: Number(B1_primarySideTemperatureInput),
        C1_primarySideTemperatureInput: Number(C1_primarySideTemperatureInput),
        project: Number(projectId),
      });
      if (r.data && !r.data.status) {
        toast.success(`Zapisano wartości dla wymiennika.`);
      }
    };

    await callExchanger(postCall);
  };

  return (
    <>
      <ColumnWrapper>
        <>
          <Wrapper>
            <RowWrapper>
              <BackButton
                text="Wróć do projektu"
                onClick={() => routerHistory.push(`/projects/edit/${projectId}`)}
              />
              <ColumnWrapper>
                <Typography>Wymiennik: Dodaj</Typography>
              </ColumnWrapper>
            </RowWrapper>
          </Wrapper>
        </>
        <ExpansionContainer title="Dane podstawowe">
          <ColumnWrapper fullWidth>
            <Select
              label="Typ wymiennika"
              data={[
                { id: 'płytowy skręcany', name: 'płytowy skręcany' },
                { id: 'lutowany', name: 'lutowany' },
              ]}
              onSelect={(value) => setType(value)}
              value={type}
            />
            <Select
              label="Przeznaczenie wymiennika"
              data={[
                { id: 'c.w.u.', name: 'c.w.u.' },
                { id: 'c.o.', name: 'c.o.' },
                { id: 'c.o./chłód', name: 'c.o./chłód' },
                { id: 'chłód', name: 'chłód' },
                { id: 'basen', name: 'basen' },
              ]}
              onSelect={(value) => setPurpose(value)}
              value={purpose}
            />
            <div style={{ height: 20, width: 1 }}></div>
            <Select
              label="czynnik po stronie pierwotnej"
              data={[
                { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
                { id: 'woda', name: 'woda' },
                { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
              ]}
              onSelect={(value) => setPrimarySideMedium(value)}
              value={primarySideMedium}
            />
            <Select
              label="czynnik po stronie wtórnej"
              data={[
                { id: 'glikol propylenowy 40%', name: 'glikol propylenowy 40%' },
                { id: 'woda', name: 'woda' },
                { id: 'glikol propylenowy 35%', name: 'glikol propylenowy 35%' },
              ]}
              onSelect={(value) => setSecondarySideMedium(value)}
              value={secondarySideMedium}
            />
            <Select
              label="S1 spadek temperatury na wymienniku"
              data={[
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
                { id: '5', name: '5' },
              ]}
              onSelect={(value) => setS1_TemperatureDropInExchanger(value)}
              value={S1_temperatureDropInExchanger}
            />
            <div style={{ height: 35, width: 1 }}></div>
            <TextField
              label="Maksymalny spadek ciśnienia [kPa]"
              variant="outlined"
              value={maximumPressureDrop}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setMaximumPressureDrop(value)
              }
            />
            <TextField
              label="Moc grzewcza [kW]"
              variant="outlined"
              value={heatingPower}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setHeatingPower(value)
              }
            />
            <TextField
              label="Moc chłodząca [kW]"
              variant="outlined"
              value={coolingPower}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setCoolingPower(value)
              }
            />
          </ColumnWrapper>
        </ExpansionContainer>
        <ExpansionContainer title="Parametry">
          <ColumnWrapper fullWidth>
            <TextField
              label="D1 delta zasilanie/powrót"
              variant="outlined"
              value={D1_deltaSupplyReturn}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setD1_DeltaSupplyReturn(value)
              }
            />
            <TextField
              label="A1 temp. po stronie pierwotnej wejście [°C]"
              variant="outlined"
              value={A1_primarySideTemperatureInput}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setA1_PrimarySideTemperatureInput(value)
              }
            />
            <div style={{ height: 20, width: 1 }}></div>
            <TextField
              label="B1 temp. po stronie pierwotnej wejście [°C]"
              variant="outlined"
              value={B1_primarySideTemperatureInput}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setB1_PrimarySideTemperatureInput(value)
              }
            />
            <div style={{ height: 20, width: 1 }}></div>
            <TextField
              label="D2 delta zasilanie/powrót"
              variant="outlined"
              value={D2_deltaSupplyReturn}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setD2_DeltaSupplyReturn(value)
              }
            />
            <TextField
              label="C1 temp. po stronie pierwotnej wejście [°C]"
              variant="outlined"
              value={C1_primarySideTemperatureInput}
              type={'number'}
              style={{ marginBottom: 20 }}
              onChange={({ target: { value } }) =>
                setC1_PrimarySideTemperatureInput(value)
              }
            />
          </ColumnWrapper>
        </ExpansionContainer>
      </ColumnWrapper>
      <FloatingSaveBar onClick={submitForm} />
    </>
  );
};

export default HeatExchangerNewScreen;
