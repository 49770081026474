import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles, Paper, TextField } from '@material-ui/core';
import { Mail, MeetingRoom, People, Phone, SettingsPhone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Select from '../../../../../components/dynamic-forms/form-components/Select';
import plLocale from 'date-fns/locale/pl';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton';
import commonValues from '../../../../../tools/commonValues';
import EntityField from '../../../../../components/dynamic-forms/form-components/EntityField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    }
}));

const TaskSearchFilters = (props) => {
    const classes = useStyles();
    const onChange = (name, val) => {
        const newFilters = filters;

        if(val) {
            newFilters[name] = val;
        } else {
            delete newFilters[name];
        }
        setFilters(newFilters);
    }

    const onStatusChange = (val, exists) => {
        const newFilters = filters;
        if(newFilters['statuses'].includes(val) && !exists) {
            newFilters['statuses'] = newFilters['statuses'].filter(item => item != val);
        } else {
            newFilters['statuses'].push(val);
        }
        setFilters(newFilters);
    }

    const onTypesChange = (val, exists) => {
        const newFilters = filters;
        if(newFilters['types'].includes(val) && !exists) {
            newFilters['types'] = newFilters['types'].filter(item => item != val);
        } else {
            newFilters['types'].push(val);
        }
        setFilters(newFilters);
    }

    const onQueryFieldsChange = (name, val) => {
        const newFilters = filters;
        if(val) {
            filters.queryFields[name] = val;
        } else {
            delete filters.queryFields[name];
        }
        setFilters(newFilters);
    }

    const [filters, setFilters] = useState({ 
        queryFields: {},
        statuses: [0],
        types: [],
    });

    const [ostatniOd, setOstatniOd] = useState(null);
    const [ostatniDo, setOstatniDo] = useState(null);

    const [createdOd, setCreatedOd] = useState(null);
    const [createdDo, setCreatedDo] = useState(null);

    const [uzytkownik, setUzytkownik] = useState(null);
    const [principal, setPrincipal] = useState(null);
    const [project, setProject] = useState(null);
    const [company, setCompany] = useState(null);
    const [creator, setCreator] = useState(null);
    const [isFeedback, setIsFeedback] = useState(null);

    return (
        <>
        <Paper className={classes.paper}>

        <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={'Treść'}
            margin="dense"
            onChange={({ target }) => onChange('query', target.value)}
            />


        <Select
            fullWidth
            size="small"
            variant="outlined"
            label={"Priorytet"}
            data={[{id: null, name: '-'}, ...commonValues.taskPriorities]}
            onSelect={(option) => {
                onChange('priorytet', option || null)
            }}
            />

            <Select
                fullWidth
                size="small"
                variant="outlined"
                label={"Feedback"}
                data={[{id: null, name: 'Wybierz'}, {id: 'yes', name: 'TAK'}, {id: 'no', name: 'NIE'}]}
                onSelect={(option) => {
                    onChange('isFeedback', option)
                }}
            />

            <EntityField
                entityClass={'company'}
                placeholder={'Firma'}
                label={"Firma"}
                value={company ? company : null}
                onSelect={(v) => onChange('company', v?.id || null)}
            />
            <EntityField
                entityClass={'project'}
                placeholder={'Projekt'}
                label={"Projekt"}
                value={project ? project : null}
                onSelect={(v) => onChange('project', v?.id || null)}
            />
        <EntityField
            entityClass={'user'}
            placeholder={'Przypisane do'}
            label={"Przypisane do"}
            value={uzytkownik ? uzytkownik : null}
            onSelect={(v) => onChange('user', v?.id || null)}
            />
        <EntityField
            entityClass={'user'}
            placeholder={'Twórca zadania'}
            label={"Twórca zadania"}
            value={creator ? creator : null}
            onSelect={(v) => onChange('creator', v?.id || null)}
            />

        <FormControlLabel
            control={<Checkbox name={'mine'} style={{padding: '0 8px'}} onChange={(e) => onChange('mine', e.target.checked ? true : null)} />}
            label={'Tylko moje zadania'}
        />


            <Divider style={{margin: '1em 0px'}} />

        Status:

        <FormGroup>
            {[
                {id: 0, label: 'Do zrobienia'},
                {id: 1, label: 'Zrobione'},
                {id: 2, label: 'Anulowane'},
                {id: 3, label: 'Odrzucone'},
            ].map(item => (
                <FormControlLabel
                key={item.id+'_sfcl'}
                    control={<Checkbox name={item.label.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onStatusChange(item.id, e.target.checked)} defaultChecked={item.id === 0 ? true : false} />}
                    label={item.label}
                />
            ))}
        </FormGroup>

            <Divider style={{margin: '1em 0px'}} />

        Typ:

        <FormGroup>
            {props.types?.sort().map(item => (
                <FormControlLabel
                key={item+'_tfcl'}
                    control={<Checkbox name={item.toLowerCase()} style={{padding: '0 8px'}} onChange={(e) => onTypesChange(item, e.target.checked)} />}
                    label={item}
                />
            ))}
        </FormGroup>

        <FormGroup>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                label="Deadline od"
                name={'ostatni_od'}
                format="dd/MM/yyyy"
                value={ostatniOd || null}
                inputVariant="outlined"
                ampm={false}
                onChange={(val) => {
                    setOstatniOd(val);
                    onChange('ostatni_od', val ? val.getTime() : null)
                }}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                onChange={(val) => {
                    setOstatniDo(val);
                    onChange('ostatni_do', val ? val.getTime() : null)
                }}
                label="Deadline do"
                name={'ostatni_do'}
                value={ostatniDo || null}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
            />
        </MuiPickersUtilsProvider>
</FormGroup>
        <FormGroup>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                label="Data utworzenia od"
                name={'created_od'}
                format="dd/MM/yyyy"
                value={createdOd || null}
                inputVariant="outlined"
                ampm={false}
                onChange={(val) => {
                    setCreatedOd(val);
                    onChange('created_od', val ? val.getTime() : null)
                }}
            />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
                autoOk={true}
                clearable="true"
                variant="inline"
                onChange={(val) => {
                    setCreatedDo(val);
                    onChange('created_do', val ? val.getTime() : null)
                }}
                label="Data utworzenia do"
                name={'created_do'}
                value={createdDo || null}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                ampm={false}
            />
        </MuiPickersUtilsProvider>
</FormGroup>
        <br />
        <PrimaryButton
            style={{ marginLeft: 0, marginTop: 10}}
            color="secondary"
            onClick={() => props?.handleSearch(filters)}>
            Szukaj
        </PrimaryButton>
        </Paper>
        </>
    );
};

export default TaskSearchFilters;
