import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import NoteLabel from "../NoteLabel";
import RowWrapper from "../../wrappers/RowWrapper";


export default () => {
  const theme = useTheme();
  return (
    <NoteLabel color={theme.palette.secondary.contrastText}>
      <RowWrapper autoWidth>
        <Typography
          style={{ marginLeft: '6px' }}
          color="inherit"
          variant="body2">
          Serwis
        </Typography>
      </RowWrapper>
    </NoteLabel>
  );
};
