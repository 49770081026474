import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {
    deleteCall,
    getCall
} from "../../components/indicator/Indicator";
import BasicTable from "../../components/tables/basic/BasicTable";
import headCells from './components/templates/list/headCells';
import ListToolBar from './components/templates/list/ListToolBar';
import {API_TEMPLATES_ROUTE} from '../../constants/routes';
import routerHistory from "../../constants/settings/routerHistory";
import serializeQuery from "../../tools/serializeQuery";

const TemplatesReviews = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(null);

    const search = async (filters = null) => {
        const query = filters ? serializeQuery(filters) : '';
        const {data} = await getCall(`${API_TEMPLATES_ROUTE}?${query}`);
        if (data) {
            const rows = data?.items?.map((i) => ({
                identity: i?.id,
                id: i?.id,
                name: i?.name,
                description: i?.description,
            }));

            setCount(data?.total);
            setRows(rows);
        }
    };

    useEffect(() => {
        search();
    }, []);

    const handleDelete = async (numSelected) => {
        const {data} = await deleteCall(API_TEMPLATES_ROUTE, {
            ids: numSelected.join(),
        });
        const [status] = data;
        if (status?.success) {
            toast.success('Pomyślnie usunięto wybrane szablony.');
            search();
        } else {
            toast.error('Wystąpił błąd podczas usuwania wybranych szablonów.');
        }
    };

  return <>
    <ListToolBar handleSearch={search}/>
    <BasicTable
      onDelete={handleDelete}
      useDialogOnDelete
      headCells={headCells}
      rows={rows}
      onChangePage={() => search()}
      recordsCount={count}
      onRowClick={({id}) =>
          routerHistory.push(`/service/templates/reviews/edit/${id}`)
      }
    />
  </>
}

export default TemplatesReviews;