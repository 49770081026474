import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    TextField,
    Typography
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {API_DOCUMENTS_ROUTE, SERVER_ROUTE} from '../../../../constants/routes';
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {postFileCall} from "../../../../components/indicator/Indicator";
import ColumnWrapper from "../../../../components/wrappers/ColumnWrapper";
import RowWrapper from "../../../../components/wrappers/RowWrapper";
import UserImage from '../../../../components/user-image/UserImage';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import {transformDocument} from "../../tools/fetchDirectoryContent";
import {FILE_TYPE} from "../../../../constants/directories/types";

const ItemWrapper = styled(RowWrapper)`
  margin: 10px 0;
`;

const Container = styled(RowWrapper)`
  padding: 25px;
  min-width: 400px;
  align-items: flex-start;
`;

export default ({document, onSave}) => {
    const [isOpen, setOpen] = useState(false);
    const [name, setName] = useState(document?.name);

    useEffect(() => {
        setName(document?.name);
    }, [document]);

    const handleClose = () => {
        setOpen(false);
        setName('')
    };
    const handleOpen = () => setOpen(true);

    const downloadFile = () => {
        if (document.path) window.open(`${SERVER_ROUTE}/${document.path}`, '_blank');
    };

    const updateDocument = async () => {
        try {
            const formData = new FormData();
            formData.append('name', name);

            const {data: {file} = {}} = await postFileCall(
                `${API_DOCUMENTS_ROUTE}/${document.id}`,
                formData
            );

            if (onSave && file) {
                onSave(transformDocument({type: FILE_TYPE, uuid: document.uuid})(file))
            }

            handleClose();
        } catch (error) {
            toast.error('Wystąpił błąd podczas aktualizacji pliku.');
        }
    };

    return <>
        <Tooltip title={`Edytuj plik ${document.name}`} placement="top">
            <IconButton onClick={handleOpen}>
                <EditIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                <Container>
                    <ColumnWrapper style={{marginRight: 20}}>
                        <Typography style={{fontWeight: 'bold', marginBottom: 10}} variant="body1">
                            {document?.name ?? '-'}
                        </Typography>
                        <Typography variant="body2">
                            Opis: {document?.description || '-'}
                        </Typography>
                        <Typography variant="body2">
                            Dodał/a:
                        </Typography>
                        <ItemWrapper style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <UserImage src={document?.creator?.image} name={document?.creator?.username}/>
                            <Typography style={{margin: '0 0 0 5px'}} variant="body2">
                                {document?.creator?.username ?? '-'}
                            </Typography>
                        </ItemWrapper>
                        <ItemWrapper style={{marginBottom: 10}}>
                            <Typography variant="body2">
                                Dodano: {document?.created?.date ?? '-'}
                            </Typography>
                        </ItemWrapper>
                        <PrimaryButton
                            size="small"
                            disabled={!document?.path}
                            onClick={downloadFile}>
                            Pobierz
                        </PrimaryButton>
                    </ColumnWrapper>
                    <ColumnWrapper style={{marginLeft: 20}}>
                        <Typography style={{fontWeight: 'bold'}} variant="body1">
                            Edycja pliku
                        </Typography>
                        <ItemWrapper>
                            <TextField
                                variant="outlined"
                                label="Nazwa"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </ItemWrapper>
                    </ColumnWrapper>
                </Container>
            </DialogContent>
            <Divider/>
            <DialogActions style={{padding: 20}}>
                <PrimaryButton onClick={handleClose} color="secondary">
                    Anuluj
                </PrimaryButton>
                <PrimaryButton onClick={updateDocument}>Zapisz</PrimaryButton>
            </DialogActions>
        </Dialog>
    </>
}
